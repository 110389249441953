import React, { useEffect } from 'react';
import CollectionSheetsReportBody from '../CollectionSheetsReportBody';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCollectionSheetsOwnerStatus } from '../../../../../../reducers/reportsCollectionSheets';

const CollectionSheetsReportWrap = ({ collectionSheetsOwner, getCollectionSheetsOwnerStatus, ownerId, statusesPrefer, timeFrom, timeTo, collectionSheetSuccess }) => {
    useEffect(() => {
        getCollectionSheetsOwnerStatus(ownerId, statusesPrefer, { timeFrom: timeFrom, timeTo: timeTo });
    }, [ownerId, statusesPrefer, getCollectionSheetsOwnerStatus, timeFrom, timeTo]);

    return (
        <CollectionSheetsReportBody collectionSheetsOwner={collectionSheetsOwner} loading={collectionSheetSuccess} />
    );
};

CollectionSheetsReportWrap.propTypes = {
    collectionSheetsOwner: PropTypes.array,
    getCollectionSheetsOwnerStatus: PropTypes.func,
    ownerId: PropTypes.number,
    statusesPrefer: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    collectionSheetSuccess: PropTypes.bool
};

const mapStateToProps = ({ reportsCollectionSheets: { collectionSheetsOwner, ownerId, statusesPrefer, timeFrom, timeTo, collectionSheetSuccess } }) => {
    return {
        collectionSheetsOwner,
        ownerId,
        statusesPrefer,
        timeFrom,
        timeTo,
        collectionSheetSuccess
    };
};

export default connect(mapStateToProps, { getCollectionSheetsOwnerStatus })(CollectionSheetsReportWrap);
