import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose, withApiService } from 'Hoc';
import { setCarImages } from 'actions';
import SvPhotoViewerModal from './SvPhotoViewerModal';
import Loader from 'components/Loader';

import style from './SvPhotoViewer.module.scss';
import arrows from 'components/App/App.module.scss';

const IMAGE_WIDTH_WITH_MARGIN = 120;

const SvPhotoViewer = ({ imagesCar, carId, setCarImages, carChangingImagesIds, carSettingImagesCount }) => {
    const [activePhotoData, setActivePhotoData] = useState(null);
    const [newImages, setNewImages] = useState([]);
    const [imagesLeft, setImagesLeft] = useState(0);

    useEffect(() => {
        if (activePhotoData && !_.some(imagesCar, ['id', activePhotoData.id])) {
            setActivePhotoData(null);
        }
    }, [imagesCar, activePhotoData]);

    useEffect(() => {
        setNewImages(prevState => {
            const result = [...prevState];

            if (result.length > carSettingImagesCount) {
                result.splice(0, result.length - carSettingImagesCount);
            }

            return result;
        });
    }, [carSettingImagesCount]);

    const handleAddPhoto = (e) => {
        const formData = new FormData();
        const files = _.filter(e.target.files, file => /\.(jpe?g|png|gif)$/i.test(file.name));

        _.each(files, (file, key) => {
            formData.append(`file${key + 1}`, file);

            const reader = new FileReader();
            reader.onload = function(e) {
                setNewImages(prevState => [...prevState, e.target.result]);
            };
            reader.readAsDataURL(files[key]);
        });

        setCarImages(formData, carId, files.length);
    };

    const handleShowPhoto = (photo, index) => {
        const activePhotoData = { index: index, id: photo.id };
        setActivePhotoData(activePhotoData);
    };

    const handleHidePhoto = () => {
        setActivePhotoData(null);
    };

    const handleShiftLeft = () => {
        const index = activePhotoData.index + 1;

        handleShift(index);
    };

    const handleShiftRight = () => {
        const index = activePhotoData.index - 1;

        handleShift(index);
    };

    const handleShift = (index) => {
        const activePhotoData = { index: index, id: imagesCar[index].id };
        setActivePhotoData(activePhotoData);
    };

    const handleSlideImageLeft = () => {
        if (imagesLeft < 0) {
            setImagesLeft(imagesLeft + IMAGE_WIDTH_WITH_MARGIN);
        }
    };

    const handleSlideImageRight = () => {
        const imagesContainerWidth = document.getElementById('svCarImagesContainer').offsetWidth;
        const imagesWidth = document.getElementById('svCarImages').offsetWidth;

        if (imagesContainerWidth < imagesWidth + imagesLeft) {
            setImagesLeft(imagesLeft - IMAGE_WIDTH_WITH_MARGIN);
        }
    };

    const imgMap = imagesCar.map((item, index) => {
        const src = _.get(item, 'thumbnails.carGallery', item.url);
        return (
            <figure key={index} className={style.imgItem} onClick={() => handleShowPhoto(item, index)}>
                <img src={src} alt={item.description ? item.description : `фото авто ${index}`} />
                <Loader active={_.includes(carChangingImagesIds, item.id)} classes={['small']} />
            </figure>);
    });

    const newImgMap = newImages.map((item, index) => {
        return (
            <figure key={index} className={style.imgItem}>
                <img src={item} alt={`фото авто ${index}`} />
                <Loader active classes={['small']} />
            </figure>);
    });

    const addPhoto = (
        <div key='inputPhoto' className={style.inputsAdd}>
            <figure key='addPhoto' className={style.imgItemAdd}>
                <input type='file' name='inputFile' id='inputFile' multiple accept='image/*,image/jpeg' onChange={handleAddPhoto} className={style.inputHidden} />
                <label htmlFor='inputFile' />
            </figure>
        </div>
    );

    let activePhoto;

    if (activePhotoData) {
        activePhoto = _.find(imagesCar, ['id', activePhotoData.id]);
    }

    let svPhotoViewerModal;

    if (activePhoto) {
        svPhotoViewerModal = (
            <SvPhotoViewerModal
                activePhoto={activePhoto}
                leftShiftVisible={activePhotoData.index < imagesCar.length - 1}
                rightShiftVisible={activePhotoData.index > 0}
                handleHidePhoto={handleHidePhoto}
                handleShiftLeft={handleShiftLeft}
                handleShiftRight={handleShiftRight}
            />
        );
    }

    return (
        <div className={style.carBasicInfoBottom}>
            <div className={style.imgArray}>
                <div className={`${style.side} ${style.left}`}>
                    <div className={style.arrow} onClick={handleSlideImageLeft}>
                        <div className={arrows.arrowLeftMiddle} />
                    </div>
                    { addPhoto }
                </div>
                <div className={style.imagesContainer} id='svCarImagesContainer'>
                    <div className={style.images} style={{ left: imagesLeft + 'px' }} id='svCarImages'>
                        { newImgMap.reverse() }
                        { imgMap.reverse() }
                    </div>
                </div>
                <div className={`${style.side} ${style.right}`}>
                    <div className={`${style.arrow} ${style.end}`} onClick={handleSlideImageRight}>
                        <div className={arrows.arrowRightMiddle} />
                    </div>
                </div>
            </div>
            { svPhotoViewerModal }
        </div>
    );
};

SvPhotoViewer.defaultProps = {
    imagesCar: []
};

SvPhotoViewer.propTypes = {
    imagesCar: PropTypes.array.isRequired,
    carId: PropTypes.number.isRequired,
    setCarImages: PropTypes.func.isRequired,
    carChangingImagesIds: PropTypes.array.isRequired,
    carSettingImagesCount: PropTypes.number.isRequired
};

const mapStateToProps = ({ carDetailsState: { carId, carChangingImagesIds, carSettingImagesCount } }) => {
    return {
        carId,
        carChangingImagesIds,
        carSettingImagesCount
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        setCarImages: setCarImages(apiService, dispatch)
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SvPhotoViewer);
