import CollectInfoReportRow from '../CollectInfoReportRow';
import style from '../CollectInfoReportBody/CollectInfoReportBody.module.scss';
import arrowDown from '../../../../../../assets/arrow-down-reports.png';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingIndicatorBlock from '../../../../../../components/LoadingIndicator/LoadingIndicatorBlock';

const CollectInfoReportTable = ({ ownersStatistic, loading }) => {
    const ownersRows = ownersStatistic && ownersStatistic.length > 0 ? ownersStatistic.map((item) => {
        return (
            <CollectInfoReportRow key={item.id} ownerReport={item} />
        );
    }) : (<div className={style.rowOwner} />);

    return (
        <>
            {
                loading
                    ? <LoadingIndicatorBlock reasonLoader={loading} />
                    : ''
            }
            <div className={style.collectInfoReportBody}>
                <div className={style.tableHeader}>
                    <div className={style.rowOwner + ' ' + style.headerRowOwner} key='header'>
                        <div className={style.name}>Эксперт <img src={arrowDown} alt='arrow' className={style.arrow} /></div>
                        <div>Листов открыто</div>
                        <div>Заполнено всего</div>
                        <div>Заполнено полностью</div>
                        <div>Заполнено частично</div>
                        <div />
                    </div>
                </div>
                <div className={style.tableBody}>
                    {
                        ownersRows
                    }
                </div>
            </div>
        </>
    );
};

CollectInfoReportTable.propTypes = {
    ownersStatistic: PropTypes.array,
    loading: PropTypes.bool
};

export default CollectInfoReportTable;
