function putPostSeasonalPreferences(carIdCollect, clientIdCollect, fetchPostCarSeasonalPreference, periodId, seasonId, preferencesId = null) {
    const dataPost = {
        car_id: carIdCollect,
        client_id: clientIdCollect,
        season_id: seasonId,
        period_id: periodId
    };

    if (preferencesId) {
        dataPost.id = preferencesId;
    }

    return carIdCollect && clientIdCollect && seasonId && fetchPostCarSeasonalPreference(dataPost);
}

export default putPostSeasonalPreferences;
