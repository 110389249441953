import style from '../../VisitorsBody.module.scss';
import styleApp from '../../../../../components/App/App.module.scss';
import Loader from '../../../../../components/Loader';
import TableRowVisitors from '../../TableRowVisitors';
import React from 'react';
import PropTypes from 'prop-types';

const TableVisitors = ({ fields, columnHeader, columnsSizes, isSuccess, items, isActionError, isOrder }) => {
    let rowsEmpty = (<div className={style.tableVisitorsBodyRow} key='loadingVisitors'><div className={style.col2}>Данные отсутствуют</div></div>);

    if (isActionError) {
        rowsEmpty = (<div className={style.tableVisitorsBodyRow + ' ' + styleApp.colorTextRed} key='loadingVisitors'><div className={style.col2}>Ошибка получения данных</div></div>);
    }

    return (
        <section className={style.tableVisitors} id='tableVisitors'>
            <div className={style.tableVisitorsHead}>
                {
                    columnHeader.length > 0 ? columnHeader.map((item, key) => {
                        return (<div key={key} className={style['col' + columnsSizes[key].toString().split('.').join('')]}>{ item }</div>);
                    }) : <div className={style.col1}>Заголовок</div>
                }
            </div>
            {
                isSuccess
                    ? <div className={style.btnLoad}><Loader active={isSuccess} classes={['loaderTop', 'gray']} /></div>
                    : ''
            }
            {
                items && items.length > 0 ? items.map((item) => {
                    return (<TableRowVisitors key={item.id} visitor={item} fields={fields} isOrder={isOrder} />);
                }) : rowsEmpty
            }
        </section>
    );
};

TableVisitors.propTypes = {
    fields: PropTypes.array,
    columnHeader: PropTypes.array,
    columnsSizes: PropTypes.array,
    isSuccess: PropTypes.bool,
    items: PropTypes.array,
    isActionError: PropTypes.bool,
    isOrder: PropTypes.bool
};

export default TableVisitors;
