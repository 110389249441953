import React from 'react';
import CarAndVisitor from '../CarAndVisitor';

import style from './AboutAutoText.module.scss';

const AboutAutoText = () => {
    return (
        <div className={style.aboutAutoText}>
            <div className={style.guest}>ГОСТЬ</div>
            <CarAndVisitor />
        </div>
    );
};

export default AboutAutoText;
