import React from 'react';
import HeaderNodeToggle from '../HeaderNodeToggle';
import SheetInspectCategoryNodes from '../SheetInspectCategoryNodes';
import PropTypes from 'prop-types';

import style from './SheetInspectCard.module.scss';

// создание одной карточки листа осмотра
const SheetInspectCard = ({ siHeaderOne, imagesHeader, nodeFlue }) => {
    return (
        <div className={style.cardSheetInspections}>
            <HeaderNodeToggle siHeadField={siHeaderOne} imageHeader={imagesHeader} />
            <SheetInspectCategoryNodes
                nodes={nodeFlue}
            />
        </div>
    );
};

SheetInspectCard.propTypes = {
    siHeaderOne: PropTypes.string.isRequired,
    nodeFlue: PropTypes.array,
    imagesHeader: PropTypes.node
};

export default SheetInspectCard;
