import React from 'react';
import style from './RowsKeyValue.module.scss';
import BtnActionsRow from '../../routes/Settings/components/BtnActionsRow';
import PropTypes from 'prop-types';

const RowsKeyValue = ({ left, right, handleClickTrash, handleUpdate, del = false, upd = false, classes = [] }) => {
    return (
        <>
            <div className={style.rowsKeyValue + ' ' + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}>
                <div className={style.field}>
                    <div className={style.fieldItem}>{ left }</div>
                    <div>{ right }</div>
                </div>
                {
                    del || upd
                        ? <div className={style.showHoverBtn}><BtnActionsRow handleClickTrash={handleClickTrash} handleUpdate={handleUpdate} del={del} upd={upd} /></div>
                        : <div className={`${style.showHoverBtn} ${style.withoutBtn}`}> </div>
                }
            </div>
        </>
    );
};

RowsKeyValue.propTypes = {
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.string,
    handleClickTrash: PropTypes.func,
    handleUpdate: PropTypes.func,
    del: PropTypes.bool,
    upd: PropTypes.bool,
    classes: PropTypes.array
};

export default RowsKeyValue;
