import style from '../../../../SheetCollectBody.module.scss';
import MessageForForm from '../../../../../../../components/MessageFoForm';
import { OilsIsForTime } from '../index';
import { SetCarInterest } from '../../../../../../../components/Requests';
import AverageDailyMileageCollect from '../../AverageDailyMileageCollect';
import PreReserve from '../../../../../../../components/PreReserve';
import React from 'react';
import PropTypes from 'prop-types';
import BlueSpanLink from '../../../../../../../components/BlueSpanLink';
import Text from '../../../../../../../components/Text';
import OilChangeMonthsWrap from '../OIlChangeMonths';
import OilsEveryMileage from '../OilsEveryMileage';
import ButtonsCommon from '../../../../../../../components/Buttons/ButtonsCommon';
import {
    OIL_IS_LAST_MILEAGE,
    OIL_MILEAGE,
    OIL_MILEAGE_NEXT_CHANGE
} from '../../../../../../../constants/OilReferencesLabels';

const OilLastNext = ({ carDetailsInfo, fetchPostCarOil, clientIdCollect, isLoad }) => {
    const handleOnClick = (data) => {
        const dataPush = {
            car_id: carDetailsInfo.id,
            [OIL_IS_LAST_MILEAGE]: +data,
            [OIL_MILEAGE]: null
        };

        carDetailsInfo.id && clientIdCollect && fetchPostCarOil(dataPush, clientIdCollect);
    };

    return (
        <ButtonsCommon
            key='oil_is_exactly_next_last_mileage'
            id='oil_is_exactly_next_last_mileage'
            handleClick={handleOnClick}
            btnSelected={!!carDetailsInfo[OIL_IS_LAST_MILEAGE]}
            classes={['btnStretchCycle', 'margin5', 'bgWhite', 'height40']}
            countButtons={2}
            buttonsName={['Прошлая', 'Следующая']}
            dataSuccess={isLoad}
        />
    );
};

OilLastNext.propTypes = {
    fetchPostCarOil: PropTypes.func,
    carDetailsInfo: PropTypes.object,
    clientIdCollect: PropTypes.number,
    isLoad: PropTypes.bool
};

const OilWrap = ({
    id,
    isProlongation,
    isValidationOn,
    isValid,
    carDetailsInfo,
    interestsReasons,
    preReservesExist,
    isOpenAverageDailyMileage,
    packetId,
    setAverageDailyMileage,
    setMonthForChangeOil,
    forSvBook,
    handleValidationTurnOn,
    setIsOpenAverageDailyMileage,
    handleSetFocusMileage,
    fetchPostCarOil,
    clientIdCollect,
    handleUpdateProlongation,
    carIsExactlyOilLastMileageSuccess
}) => (
    <div className={style.wrapBodySubCollect}>
        <div className={style.rowGrow + ' ' + style.borderGrey}>
            <div className={style.column + ' ' + style.paddings + ' ' + style.half + ' ' + style.maxWidth512}>
                <div className={style.row + ' ' + style.around + ' ' + style.paddingRight35 + ' ' + style.center + ' ' + style.margin15}>
                    <div className={style.column + ' ' + style.center}>
                        <Text text='Цикличность' classes={['h3', 'maxWidth200']} />
                    </div>
                    <div className={style.column}>
                        <OilsIsForTime
                            blockId={id}
                            isValid={!(isValidationOn && !isValid && ((!carDetailsInfo.is_for_time && !carDetailsInfo.mileage_next_change) || (carDetailsInfo.is_for_time && !carDetailsInfo.car_change_oil_months)))}
                        />
                    </div>
                </div>
                <div className={style.row + ' ' + style.around + ' ' + style.paddingTop20}>
                    <div className={style.column + ' ' + style.margin8}>
                        <Text text='Замена каждые' classes={['maxWidth200']} />
                    </div>
                    <div className={style.column + ' ' + style.paddingTop20}>
                        {
                            (carDetailsInfo.is_for_time)
                                ? (
                                    <OilChangeMonthsWrap
                                        isValid={!!(!(isValidationOn && !isValid && ((!carDetailsInfo.is_for_time && !carDetailsInfo[OIL_MILEAGE_NEXT_CHANGE]) || (carDetailsInfo.is_for_time && !carDetailsInfo.car_change_oil_months))) && carDetailsInfo.is_for_time)}
                                    />
                                )
                                : (
                                    <OilsEveryMileage
                                        key='OilsEveryMileage_mileage_next_change'
                                        fieldName={OIL_MILEAGE_NEXT_CHANGE}
                                        isValid={!(isValidationOn && !isValid && ((!carDetailsInfo.is_for_time && !carDetailsInfo[OIL_MILEAGE_NEXT_CHANGE]) || (carDetailsInfo.is_for_time && !carDetailsInfo.car_change_oil_months))) && !carDetailsInfo.is_for_time}
                                    />
                                )
                        }
                        {
                            isValidationOn &&
                            !isValid &&
                            ((!carDetailsInfo.is_for_time && !carDetailsInfo[OIL_MILEAGE_NEXT_CHANGE]) || (carDetailsInfo.is_for_time && !carDetailsInfo.car_change_oil_months))
                                ? (
                                    <MessageForForm
                                        message='Укажите цикличность'
                                        classes={['maxWidthStretch', 'height40', 'paddingRight35', 'flexEnd', (carDetailsInfo.is_for_time ? 'paddingRight55' : 'paddingRight35')]}
                                    />
                                )
                                : ''
                        }
                    </div>
                </div>
            </div>
            {
                !isProlongation && (
                    <div className={style.column + ' ' + style.borderGrey + ' ' + style.paddings + ' ' + style.half}>
                        <div className={style.row + ' ' + style.around + ' ' + style.center + ' ' + style.margin15 + ' ' + style.margin15}>
                            <div className={style.column}>
                                <Text text='Замена масла' classes={['h3', 'maxWidth200']} />
                            </div>
                            <div className={style.column}>
                                <OilLastNext clientIdCollect={clientIdCollect} carDetailsInfo={carDetailsInfo} fetchPostCarOil={fetchPostCarOil} isLoad={carIsExactlyOilLastMileageSuccess} />
                            </div>
                        </div>
                        <div className={style.row + ' ' + style.around + ' ' + style.paddingTop20}>
                            <div className={style.column + ' ' + style.justifyCenter}>
                                <Text text={`Пробег на ${carDetailsInfo[OIL_IS_LAST_MILEAGE] ? 'прошл.' : 'след.'} замену масла`} classes={['maxWidth200']} />
                            </div>
                            <div className={style.column}>
                                <OilsEveryMileage
                                    key={`OilsEveryMileage_${OIL_MILEAGE}`}
                                    fieldName={OIL_MILEAGE}
                                    isValid
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
        {
            !isProlongation && <SetCarInterest nameBlock='Интерес к услуге' interestsReasons={interestsReasons} id={id} />
        }
        {
            !carDetailsInfo.is_for_time &&
            !carDetailsInfo.average_daily_mileage &&
            (!preReservesExist[packetId] || isOpenAverageDailyMileage)
                ? (
                    <AverageDailyMileageCollect
                        headerText='Дополнительная информация для расчета предварительной записи'
                        cycleMileage={carDetailsInfo?.mileage_next_change || 0}
                        handleSetAverageDailyMileage={setAverageDailyMileage}
                        handleSetMonthForChangeOil={(data) => setMonthForChangeOil(data ? { ...data, packetId: packetId } : null)}
                    />
                )
                : ''
        }
        {
            forSvBook
                ? ''
                : (
                    <PreReserve
                        packetId={packetId}
                        type='month'
                        serviceName='замену масла в ДВС'
                        id={'pre_reserve_oil' + packetId}
                        key={'pre_reserve_oil' + packetId}
                        isNotValid={!isValid}
                        isValidationOn={isValidationOn}
                        handleValidationTurnOn={handleValidationTurnOn}
                        isProlongation={isProlongation}
                        handleOpenAverageDailyMileage={setIsOpenAverageDailyMileage}
                        handleUpdateProlongation={handleUpdateProlongation}
                        noMileageDataMessage={
                            !carDetailsInfo.is_for_time &&
                            isValidationOn &&
                            !carDetailsInfo.mileage &&
                            <MessageForForm
                                message={
                                    <div className={style.noMileageDataMessage}>
                                        Укажите<BlueSpanLink handleClick={() => handleSetFocusMileage(true)} textSpan='пробег' classes={['navLinks', 'redColor']} />для расчета предварительной записи
                                    </div>
                                }
                                classes={['maxWidthStretch']}
                            />
                        }
                    />
                )
        }
    </div>
);

OilWrap.propTypes = {
    carDetailsInfo: PropTypes.object,
    id: PropTypes.number,
    forSvBook: PropTypes.bool,
    setAverageDailyMileage: PropTypes.func,
    setMonthForChangeOil: PropTypes.func,
    preReservesExist: PropTypes.object,
    isProlongation: PropTypes.bool,
    isValidationOn: PropTypes.bool,
    isValid: PropTypes.bool,
    interestsReasons: PropTypes.object,
    isOpenAverageDailyMileage: PropTypes.bool,
    packetId: PropTypes.number,
    handleValidationTurnOn: PropTypes.func,
    setIsOpenAverageDailyMileage: PropTypes.func,
    handleSetFocusMileage: PropTypes.func,
    fetchPostCarOil: PropTypes.func,
    clientIdCollect: PropTypes.number,
    carIsExactlyOilLastMileageSuccess: PropTypes.bool,
    handleUpdateProlongation: PropTypes.func
};

export default OilWrap;
