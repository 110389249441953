import {
    differenceInCalendarDays,
    endOfDay,
    format,
    formatISO,
    startOfDay,
    subDays
} from 'date-fns';

// берем промежуток и вычисляем какого он формата
/*
* 1) если меньше 14 дней то берем понедельник от from и востресенье от to  // day
* 2) если меньше 58 дней то берем понедельник от from и воскресенье от to  // week
* 3) если меньше 366-365 дней то берем начало месяца от from и до to  // month
* 4) если больше 366 дней то берем начало года от from и до to  // year
* */

export function getRangeAndChunk(
    from,
    to,
    fullFormatDate = false,
    boundaries = {
        day: { less: 14, chunk: 'day' },
        week: { less: 58, chunk: 'week' },
        month: { less: 366, chunk: 'month' },
        year: { less: 99999, chunk: 'year' }
    }
) {
    let chunk;
    const dateToDefault = new Date(subDays(new Date(), 1));
    const countDaysBetween = Math.abs(differenceInCalendarDays(
        new Date(from),
        to ? new Date(to) : dateToDefault
    ));

    if (countDaysBetween < boundaries.day.less) {
        chunk = boundaries.day.chunk;
    } else if (countDaysBetween < boundaries.week.less) {
        chunk = boundaries.week.chunk;
    } else if (countDaysBetween < boundaries.month.less) {
        chunk = boundaries.month.chunk;
    } else if (countDaysBetween < boundaries.year.less) {
        chunk = boundaries.year.chunk;
    }

    const newDateFrom = format(startOfDay(new Date(from)), 'yyyy-MM-dd');
    const newDateTo = format(endOfDay(new Date(to)), 'yyyy-MM-dd');

    return {
        fromDateLocal: fullFormatDate ? formatISO(startOfDay(new Date(from))) : newDateFrom,
        toDateLocal: fullFormatDate ? formatISO(endOfDay(new Date(to))) : newDateTo,
        chunkDateLocal: chunk
    };
}
