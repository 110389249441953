import React, { useEffect, useState } from 'react';
import SubHeaderCollect from 'components/SubHeaderCollect';
import PropTypes from 'prop-types';
import { compose, withApiService } from 'Hoc';
import { connect } from 'react-redux';
import { IsReShoe, IsDoWheelAlignment, SeasonalPreferences } from 'routes/SheetCollect/SheetCollectBody/components/Anchors/TiresFields';
import { fetchSeasonsPeriods, seasonsPeriodsLoaded } from '../../../../../reducers/settingsSeasons';
import { SetCarInterest } from 'components/Requests';
import style from '../../SheetCollectBody.module.scss';
import { BLOCK_LABEL_TIRES } from '../../../../../constants/carCollectBlockLabels';
import putPostSeasonalPreferences from '../../../../../utils/putPostSeasonalPreferences';
import { fetchPostCarSeasonalPreference } from '../../../../../actions';
import getBlockIdByLabel from '../../../../../utils/getBlockIdByLabel';
import { BLOCKS_LABELS } from '../../../../../constants/CarCollectBlocks';
import PreReserve from '../../../../../components/PreReserve/PreReserve';
import MessageForForm from '../../../../../components/MessageFoForm';
import InviteFrom1C from '../InviteFrom1C';
import { ButtonsProlongation } from '../../../../../components/Buttons/ButtonsCommon';
import ToggleRadio from '../../../../../components/CheckToggle/ToggleRadio';
import { CHANGE_MODE } from '../../../../../constants/prolongationMode';

function searchInDatesPeriods(dateInvite, foundSeasonPeriod) {
    return dateInvite && foundSeasonPeriod ? foundSeasonPeriod.find(item => {
        return (new Date(item.date_start) < new Date(dateInvite)) && (new Date(item.date_end) > new Date(dateInvite));
    }) : null;
}

function searchIdPreferences(foundSeasonPeriod, seasonalPreferencesClient, carId) {
    let res = null;
    if (foundSeasonPeriod) {
        if (seasonalPreferencesClient && Array.isArray(seasonalPreferencesClient) && seasonalPreferencesClient.length > 0) {
            res = seasonalPreferencesClient.find(item =>
                item.season_id === foundSeasonPeriod.season_id &&
                item.period_id === foundSeasonPeriod.season_period_id &&
                item.car_id === carId);
        }
    }

    return res && res.id;
}

const Tires = ({
    textSubHeaders,
    fetchSeasonsPeriods,
    seasonsPeriodsLoaded,
    carCollectBlocks,
    carDetailsInfo,
    anchors,
    forSvBook,
    clientIdCollect,
    invitations1C,
    fetchPostCarSeasonalPreference,
    seasonsAndPeriods,
    isProlongation,
    handleUpdateProlongation
}) => {
    const [isHidden, setIsHidden] = useState(false);
    const [packetId, setPacketId] = useState(0);
    const [inviteDate, setInviteDate] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isValidationOn, setValidationOn] = useState(false);

    const handleValidationTurnOn = (turnOn) => {
        setValidationOn(turnOn);
    };

    useEffect(() => {
        return () => setValidationOn(false);
    }, [carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        const resValidate = carDetailsInfo.car_client_seasonal_preference?.length > 0 && (isProlongation !== null);
        setIsValid(resValidate);
    }, [carDetailsInfo, isProlongation]);

    useEffect(() => {
        const inviteDate = invitations1C && invitations1C.length > 0 && invitations1C.find(item => item.packetId === packetId) && invitations1C.find(item => item.packetId === packetId).inviteDate;
        setInviteDate(inviteDate || '');
    }, [invitations1C, packetId]);

    useEffect(() => {
        const block = carCollectBlocks && carCollectBlocks.blocks && carCollectBlocks.blocks.find(item => item.label === BLOCK_LABEL_TIRES);
        block && block.packet_id && setPacketId(block.packet_id);
    }, [carCollectBlocks]);

    const interestsReasonsTires = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[5] : null) : null;

    const interestsReasonsStorage = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[6] : null) : null;

    const [blocks, setBlocks] = useState(null);
    useEffect(() => {
        setBlocks(carCollectBlocks ? carCollectBlocks.blocks : null);
    }, [carCollectBlocks]);

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };

    useEffect(() => {
        fetchSeasonsPeriods();
    }, [fetchSeasonsPeriods]);

    useEffect(() => {
        return () => seasonsPeriodsLoaded({});
    }, [seasonsPeriodsLoaded]);

    const tires = (
        <div className={style.wrapBodySubCollect}>
            <div className={style.oilInf}>
                <SeasonalPreferences messageNotice={
                    isValidationOn &&
                    !isValid &&
                    <MessageForForm message={'Не указан период сезона' + (isProlongation === null ? ' или режим предзаписи' : '')} classes={['maxWidthStretch', 'height40']} />
                }
                />
                <div className={style.tireSection}>
                    <h3 className={style.labelBtnTire}>Дополнительная информация</h3>
                    <IsReShoe />
                    <IsDoWheelAlignment />
                </div>
            </div>
            {
                isProlongation
                    ? ''
                    : [
                        <SetCarInterest
                            key={'SetCarInterest_tires_service' + getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService)}
                            nameBlock='Интерес к шиномонтажу'
                            interestsReasons={interestsReasonsTires}
                            id={getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService)}
                        />,
                        <SetCarInterest
                            key={'SetCarInterest_tires_storage' + getBlockIdByLabel(blocks, BLOCKS_LABELS.tireStorage)}
                            nameBlock='Интерес к хранению'
                            interestsReasons={interestsReasonsStorage}
                            id={getBlockIdByLabel(blocks, BLOCKS_LABELS.tireStorage)} storageTires
                        />
                    ]
            }
            { forSvBook
                ? ''
                : (
                    <PreReserve
                        packetId={packetId}
                        type='range'
                        serviceName='шиномонтаж'
                        id='pre_reserve_tires'
                        isNotValid={!isValid}
                        isValidationOn={isValidationOn}
                        handleValidationTurnOn={handleValidationTurnOn}
                        isProlongation={isProlongation}
                        handleUpdateProlongation={handleUpdateProlongation}
                    />
                ) }
        </div>
    );

    const handleUpdateTiresFrom1C = (dateValue) => {
        const foundSeasonPeriod = seasonsAndPeriods && searchInDatesPeriods(dateValue, seasonsAndPeriods.period_times);
        const idPreferences = foundSeasonPeriod
            ? searchIdPreferences(foundSeasonPeriod, carDetailsInfo.car_client_seasonal_preference, carDetailsInfo.id)
            : window.alert('Дата из 1С не входит в периоды по намерениям шиномонтажа, либо даты сезонов и периодов не установлены.');

        clientIdCollect && carDetailsInfo?.id && foundSeasonPeriod?.season_period_id && foundSeasonPeriod?.season_id && putPostSeasonalPreferences(
            carDetailsInfo.id,
            clientIdCollect,
            fetchPostCarSeasonalPreference,
            foundSeasonPeriod.season_period_id,
            foundSeasonPeriod.season_id,
            idPreferences
        );
    };

    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationTires'
            id='prolongationTires'
            handleClick={(res) => handleUpdateProlongation(res)}
            btnSelected={CHANGE_MODE[isProlongation]}
            classes={['btnStretchCycle', 'btnTires', 'margin5', 'bgWhite', (isProlongation === null ? (isValidationOn ? 'redBorder' : 'notCheck') : '')]}
        />
    );

    const idService = getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService);
    const idStorage = getBlockIdByLabel(blocks, BLOCKS_LABELS.tireStorage);

    return (
        <div className={style.blockAnchors} id={idService}>
            <SubHeaderCollect
                title={getBlockIdByLabel(blocks, BLOCKS_LABELS.tireService, true).title || textSubHeaders[3]}
                handleSetHidden={handleSetHidden}
                blockId={idService}
                stateBool={isHidden}
                blockId2={idStorage}
                anchors={anchors}
                actions={forSvBook ? [] : [prolongationButton]}
                classes={['withoutPadding']}
            >
                {
                    forSvBook
                        ? ''
                        : (
                            <ToggleRadio
                                text='Не собрано'
                                isActive={false}
                                key='isCollectTiresInfo'
                                id='isCollectTiresInfo'
                                handleClickActive={(res) => console.log('prolo nggggg', res)}
                            />
                        )
                }
            </SubHeaderCollect>
            {
                inviteDate && !(carDetailsInfo?.car_client_seasonal_preference && carDetailsInfo?.car_client_seasonal_preference?.length > 0)
                    ? (
                        <InviteFrom1C
                            key='InviteFrom1C_tires'
                            text='дата шиномонтажа'
                            inviteDate={inviteDate}
                            buttonText='Применить'
                            handleUpdateNextOil={() => handleUpdateTiresFrom1C(inviteDate)}
                        />
                    )
                    : ''
            }
            {
                isHidden
                    ? ''
                    : tires
            }
        </div>
    );
};

Tires.propTypes = {
    textSubHeaders: PropTypes.array,
    fetchSeasonsPeriods: PropTypes.func,
    carCollectBlocks: PropTypes.object,
    carDetailsInfo: PropTypes.object,
    anchors: PropTypes.bool,
    seasonsPeriodsLoaded: PropTypes.func,
    clientIdCollect: PropTypes.number,
    invitations1C: PropTypes.array,
    forSvBook: PropTypes.bool,
    fetchPostCarSeasonalPreference: PropTypes.func,
    handleUpdateProlongation: PropTypes.func,
    isProlongation: PropTypes.bool,
    seasonsAndPeriods: PropTypes.object
};

const mapStateToProps = ({
    templatesOfFrameState: { textSubHeaders, carCollectBlocks },
    carDetailsState: { carDetailsInfo, clientIdCollect },
    anchorsFrom1C: { invitations1C },
    settingsSeasons: { seasonsAndPeriods }
}) => {
    return {
        textSubHeaders,
        carCollectBlocks,
        carDetailsInfo,
        clientIdCollect,
        invitations1C,
        seasonsAndPeriods
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, { fetchSeasonsPeriods, seasonsPeriodsLoaded, fetchPostCarSeasonalPreference })
)(Tires);
