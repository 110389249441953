import React from 'react';
import PropTypes from 'prop-types';
import style from './PairColumn.module.scss';

const PairColumn = ({ children }) => {
    return (
        <div className={style.pairColumn}>
            { children }
        </div>
    );
};

PairColumn.propTypes = {
    children: PropTypes.node
};

export default PairColumn;
