import React from 'react';
import SvMarkModelYear from '../SvMarkModelYear';
import CarBasicNumber from '../CarBasicNumber';
import SvBasicMileage from '../SvBasicMileage';
import SvBasicHeader from '../SvBasicHeader';
import PropTypes from 'prop-types';

import style from './SvBasicInfoCard.module.scss';
import { connect } from 'react-redux';

const SvBasicInfoCard = ({ carDetailsInfo }) => {
    const { licensePlate, region } = carDetailsInfo;
    return (
        <div className={style.carBasicInfoCard}>
            <SvBasicHeader header='Основная информация' />
            <SvMarkModelYear />
            <div className={style.carBasicNumberMileage}>
                <CarBasicNumber
                    regNumberCar={licensePlate}
                    regNumberCarEnd={region ? String(region) : '  '}
                />
                <SvBasicMileage />
            </div>
        </div>
    );
};

SvBasicInfoCard.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(SvBasicInfoCard);
