import { buildSeasonsRangeObj } from './index';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

export default function stringBuildDateRange(arrWithDateStartEnd = [], seasonId, start = 'date_start', end = 'date_end') {
    if (!arrWithDateStartEnd.find(item => item.season_id === seasonId)) {
        return '';
    }

    const initialAccum = { firstRange: '', lastRange: '' };
    const resObj = buildSeasonsRangeObj(arrWithDateStartEnd.filter(item => item.season_id === seasonId), start, end, initialAccum);
    if (resObj.firstRange !== '' && resObj.lastRange !== '') {
        return `(${format(new Date(resObj.firstRange), 'dd MMM', { locale: ru })} - ${format(new Date(resObj.lastRange), 'dd MMM', { locale: ru })})`;
    }

    return '';
}
