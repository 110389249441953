import React from 'react';
import PropTypes from 'prop-types';
import classes from './mileage.module.scss';

const AverageMileage = (props) => (
    <div className={classes.averageMileage}>
        <div className={classes.title}>{ props.title }</div>
        <div className={classes.value}>{ props.value }</div>
    </div>
);

AverageMileage.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default AverageMileage;
