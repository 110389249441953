import React, { useState, useEffect } from 'react';

import style from './SelectBoxCommon.module.scss';
import PropTypes from 'prop-types';

const SelectBoxCommon = ({ handleChangeSelect, selectValueFather, options, fieldName = '', classes = [] }) => {
    const [selectValue, setSelectValue] = useState(0);

    useEffect(() => {
        setSelectValue(selectValueFather || 0);
        return () => setSelectValue(0);
    }, [selectValueFather]);

    const updateStatusValue = (event) => {
        setSelectValue(event.target.value);
        handleChangeSelect(+event.target.value === 0 ? null : +event.target.value);
    };

    return (
        <div className={style.selectBoxContainer + classes.map(item => ' ' + style[item]).join('')}>
            <select className={style.selectField} value={selectValue} onChange={updateStatusValue}>
                {
                    options && options.map((item) => {
                        return <option key={item.id} value={item.id}>{ Object.prototype.hasOwnProperty.call(item, fieldName) ? item[fieldName] : 'id' }</option>;
                    })
                }
            </select>
        </div>
    );
};

SelectBoxCommon.propTypes = {
    handleChangeSelect: PropTypes.func,
    selectValueFather: PropTypes.number,
    options: PropTypes.array,
    fieldName: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['backgroundGray', 'modalSelect', 'maxWidth257']))
};

export default SelectBoxCommon;
