import PropTypes from 'prop-types';
import React from 'react';
import style from './CustomOptionSelect.module.scss';

const CustomOptionSelect = ({
    option,
    selectedValue,
    handleSelectValue,
    isDisable,
    keysOptions,
    classesOption = []
}) => {
    const handleSelect = () => {
        if (selectedValue !== option[keysOptions.value] && !isDisable) {
            handleSelectValue();
        }
    };

    return (
        <div
            onClick={handleSelect}
            className={style.itemsOptions + ' ' + ((selectedValue === option[keysOptions.value] || isDisable) ? style.selected : '') + classesOption.map(item => ' ' + style[item]).join('')}
        >
            {
                option[keysOptions.title]
            }
        </div>
    );
};

CustomOptionSelect.propTypes = {
    option: PropTypes.object,
    selectedValue: PropTypes.number,
    handleSelectValue: PropTypes.func,
    isDisable: PropTypes.bool,
    keysOptions: PropTypes.object,
    classesOption: PropTypes.array
};

export default CustomOptionSelect;
