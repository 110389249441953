import {
    typeFetchError,
    typeFetchError404,
    typeSaveEntryPoint,
    typeFetchError500,
    typeFetchError422,
    typeClearErrorsCommon,
    typeSetCompanyInfo
} from '../actionsTypes';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const FETCH_ERROR = typeFetchError;
const FETCH_ERROR_404 = typeFetchError404;
const SAVE_ENTRY_POINT = typeSaveEntryPoint;
const FETCH_ERROR_500 = typeFetchError500;
const FETCH_ERROR_422 = typeFetchError422;
const CLEAR_ERRORS_COMMON = typeClearErrorsCommon;
const SET_COMPANY_INFO = typeSetCompanyInfo;

// ------------------------------------
// Actions
// ------------------------------------

export const setErrorCommon = (payload) => {
    return {
        type: FETCH_ERROR,
        payload: payload
    };
};

export const setError404Redirect = (isError) => {
    return {
        type: FETCH_ERROR_404,
        payload: isError
    };
};

export const setError500 = (isError) => {
    return {
        type: FETCH_ERROR_500,
        payload: isError
    };
};

export const setError422 = (error) => {
    return {
        type: FETCH_ERROR_422,
        payload: error
    };
};

export const saveEntryPoint = (entryPoint) => {
    return {
        type: SAVE_ENTRY_POINT,
        payload: entryPoint
    };
};

export const setEntryPoint = (entryPointPayload = {}) => (dispatch) => {
    if ((entryPointPayload.login === true) && (entryPointPayload.url === '')) {
        return;
    }
    return dispatch(saveEntryPoint(entryPointPayload.url));
};

export const clearErrorsCommon = () => {
    return {
        type: CLEAR_ERRORS_COMMON,
        payload: []
    };
};

export const setCompanyInfo = (data) => {
    return {
        type: SET_COMPANY_INFO,
        payload: data
    };
};

export const fetchCompany = (id, expand) => (dispatch, getState, { apiService }) => {
    return id && apiService.getCompanyInfo(id, expand)
        .then((data) => {
            return dispatch(setCompanyInfo(data));
        })
        .catch(() => {
            return false;
        });
};

export const actions = {
    setErrorCommon,
    setError404Redirect,
    saveEntryPoint,
    setEntryPoint,
    setError500,
    setError422,
    clearErrorsCommon
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [FETCH_ERROR_422]: (state, action) => {
        return ({ ...state, error422: action.payload });
    },

    [FETCH_ERROR_500]: (state, action) => {
        return ({ ...state, error500andMore: action.payload });
    },

    [FETCH_ERROR]: (state, action) => {
        const errors = _.cloneDeep(state.errors);
        return ({ ...state, errors: [...errors, action.payload] });
    },

    [FETCH_ERROR_404]: (state, action) => {
        return ({ ...state, error404: action.payload });
    },

    [SAVE_ENTRY_POINT]: (state, action) => {
        return ({ ...state, entryPoint: action.payload });
    },

    [CLEAR_ERRORS_COMMON]: (state) => {
        return ({
            ...state,
            error500andMore: false,
            error422: {}
        });
    },

    [SET_COMPANY_INFO]: (state, action) => {
        return ({
            ...state,
            companyInfo: action.payload,
            timezone: action.payload?.company?.city?.timezone || ''
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            entryPoint: '',
            errors: [],
            error404: false,
            error500andMore: false,
            error422: {},
            companyInfo: {},
            timezone: ''
        };
    }
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state.common, action) : state.common;
};

export default commonReducer;
