import React from 'react';
import { endOfDay, format, isEqual, startOfDay } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { HOW_SEASON } from '../constants/seasonTimeStartCollect';
import selectSeasonTires from './selectSeasonTires';

export function getSeasonPeriodByDate(dateStart, dateEnd, periods) {
    let existPeriod;
    if (dateStart && dateEnd && periods && typeof periods === 'object' && periods?.period_times) {
        periods.period_times.forEach((item) => {
            if (isEqual(startOfDay(new Date(item.date_start)), startOfDay(new Date(dateStart))) && isEqual(endOfDay(new Date(item.date_end)), endOfDay(new Date(dateEnd)))) {
                existPeriod = item?.period?.title;
            }
        });
    }
    return existPeriod || '';
}

export function getStringPeriodSeasonOrPeriodTimes(dateStart, dateEnd, periods) {
    const existPeriod = getSeasonPeriodByDate(dateStart, dateEnd, periods);
    let result;
    if (existPeriod) {
        result = <span>{ existPeriod }, <br />{ HOW_SEASON[selectSeasonTires(new Date(dateStart))] } { format(new Date(dateStart), 'yyyy', { locale: ru }) }</span>;
    } else {
        result = getStringPeriodTimes(dateStart, dateEnd);
    }
    return result;
}

export function getStringPeriodTimes(dateStart, dateEnd) {
    if (dateStart && dateEnd && typeof dateStart === 'string' && typeof dateEnd === 'string') {
        return <span>{ format(new Date(dateStart), 'dd MMMM yyyy', { locale: ru }) } - <br />{ format(new Date(dateEnd), 'dd MMMM yyyy', { locale: ru }) }</span>;
    } else {
        return '';
    }
}
