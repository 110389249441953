import React from 'react';
import PropTypes from 'prop-types';

import style from './RowThree.module.scss';

const RowThree = ({ left, middle, right }) => {
    return (
        <div className={style.mainInfo}>
            <div className={style.listInspect}>
                { /* { left } */ }
            </div>
            <div className={style.workArea}>
                { middle }
            </div>
            <div className={style.listCollect}>
                { right }
            </div>
        </div>
    );
};

RowThree.propTypes = {
    left: PropTypes.node,
    middle: PropTypes.node,
    right: PropTypes.node
};

export default RowThree;
