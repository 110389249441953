import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import { ApiServiceProvider } from './Contexts';
import store, { apiService } from './store';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { YMInitializer } from 'react-yandex-metrika';

import './index.css';

import './fonts/fonts/open-sans/300.woff2';
import './fonts/fonts/open-sans/400.woff2';
import './fonts/fonts/open-sans/600.woff2';
import './fonts/fonts/open-sans/700.woff2';
import './fonts/fonts/open-sans/800.woff2';
import './fonts/fonts/open-sans/300.woff';
import './fonts/fonts/open-sans/400.woff';
import './fonts/fonts/open-sans/600.woff';
import './fonts/fonts/open-sans/700.woff';
import './fonts/fonts/open-sans/800.woff';

Sentry.init({
    dsn: 'https://2478034e3147434ebb5975f6245e807f@sentry.ortus.ru/17',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
});

const metrics = process.env.REACT_APP_ENV === 'production'
    ? (
        <YMInitializer
            accounts={[86603537]}
            options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            }}
        />
    )
    : '';

ReactDOM.render(
    <Provider store={store}>
        { metrics }
        <ErrorBoundary>
            <ApiServiceProvider value={apiService}>
                <Router>
                    <App />
                </Router>
            </ApiServiceProvider>
        </ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);
