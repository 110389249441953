import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
    fetchAllResources,
    setToggleResourceFilter
} from '../../actions';
import { filterResource } from '../../reducers/sheetCollectInfo';
import MultiSelect from '../MultiSelect/MultiSelect';
import _ from 'lodash';

const SelectPostFilter = ({ fetchAllResources, resources, filterResource, listCollect, withCheckBox, toggleResourceSelect, setToggleResourceFilter, id, selectedResource }) => {
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);

    useEffect(() => {
        (selectedFilter.length > 0) ? setSelectedResources(resources.filter((item) => selectedFilter.includes(item.id))) : setSelectedResources([]);
    }, [resources, selectedFilter]);

    useEffect(() => {
        listCollect && fetchAllResources();
    }, [fetchAllResources, listCollect]);

    /* useEffect(() => {
        listCollect && filterResource([]); // hidden get collection sheets
    }, [filterResource, listCollect]); */

    const handleChange = (value, isChecked) => {
        setSelectedFilter(s => {
            return isChecked
                ? (s.filter((item) => item === value).length > 0)
                    ? [...s]
                    : [...s, value]
                : [...s.filter((item) => item !== value)];
        });
    };

    const handleDefault = () => {
        setSelectedFilter([]);
        setSelectedResources([]);
    };

    const handleUpdateFilter = () => {
        if (!_.isEqual(selectedFilter, selectedResource)) {
            // listCollect && filterResource(selectedFilter);  // hidden get collection sheets
        }
    };

    return (
        <div key={`postFilterSelect${id}`}>
            <MultiSelect
                id={id}
                resources={resources}
                selectedResources={selectedResources}
                handleChange={handleChange}
                showAllResource={selectedFilter.length === 0}
                handleDefault={handleDefault}
                handleUpdateFilter={handleUpdateFilter}
                withCheckBox={withCheckBox}
                toggleResourceSelect={(id === 'collectPostFilter') ? toggleResourceSelect : false}
                setToggleResourceFilter={(id === 'collectPostFilter') ? setToggleResourceFilter : () => {}}
            />
        </div>
    );
};

SelectPostFilter.propTypes = {
    resources: PropTypes.array,
    selectedResource: PropTypes.array,
    fetchAllResources: PropTypes.func,
    filterResource: PropTypes.func,
    listCollect: PropTypes.bool,
    withCheckBox: PropTypes.bool,
    toggleResourceSelect: PropTypes.bool,
    setToggleResourceFilter: PropTypes.func,
    id: PropTypes.string
};

const mapStateToProps = ({ carDetailsState: { resources, toggleResourceSelect, selectedResource } }) => {
    return {
        resources,
        toggleResourceSelect,
        selectedResource
    };
};

export default connect(mapStateToProps, { fetchAllResources, filterResource, setToggleResourceFilter })(SelectPostFilter);
