import React, { useEffect, useMemo, useState } from 'react';
import DaysOfWeekForm from '../DaysOfWeekForm';
import PropTypes from 'prop-types';

import style from './WeekSecretary.module.scss';

const WeekSecretary = ({ handlePostDays, weekState }) => {
    const initialWeekGroups = useMemo(() => ({ week: true, weekend: false }), []);
    const [checkedGroups, setCheckedGroups] = useState(initialWeekGroups);

    const [dayWeekState, setDayWeekState] = useState([]);

    const [dayWeekendState, setDayWeekendState] = useState([]);

    useEffect(() => {
        setCheckedGroups(s => ({ ...s, weekend: (dayWeekendState.find(i => i.isChecked === false) === undefined) }));
    }, [dayWeekendState]);

    useEffect(() => {
        setCheckedGroups(s => ({ ...s, week: (dayWeekState.find(i => i.isChecked === false) === undefined) }));
    }, [dayWeekState]);

    useEffect(() => {
        setDayWeekState(weekState.week);
    }, [weekState.week]);

    useEffect(() => {
        setDayWeekendState(weekState.weekend);
    }, [weekState.weekend]);

    const handleCheckDay = (day, newState, state) => {
        if (state === 'dayWeekState') {
            (newState === false) && (checkedGroups.week === true) && setCheckedGroups(s => ({ ...s, week: false }));
            setDayWeekState(s => {
                const idx = s.findIndex(item => item.day === day);
                if (~idx) {
                    return [
                        ...s.slice(0, idx),
                        { ...s[idx], isChecked: newState },
                        ...s.slice(idx + 1)
                    ];
                }
            });
        }
        if (state === 'dayWeekendState') {
            (newState === false) && (checkedGroups.weekend === true) && setCheckedGroups(s => ({ ...s, weekend: false }));
            setDayWeekendState(s => {
                const idx = s.findIndex(item => item.day === day);
                if (~idx) {
                    return [
                        ...s.slice(0, idx),
                        { ...s[idx], isChecked: newState },
                        ...s.slice(idx + 1)
                    ];
                }
            });
        }

        day && handlePostDays({ day: day, isChecked: newState }, day);
    };

    const handlerCheck = (isTrue, formName) => {
        setCheckedGroups(s => ({ ...s, [formName]: isTrue }));
        if (formName === 'week') {
            setDayWeekState(s => {
                const newData = s.map(item => ({ ...item, isChecked: isTrue }));
                handlePostDays(newData, formName);
                return newData;
            });
        }
        if (formName === 'weekend') {
            setDayWeekendState(s => {
                const newData = s.map(item => ({ ...item, isChecked: isTrue }));
                handlePostDays(newData, formName);
                return newData;
            });
        }
    };

    return (
        <div className={style.week}>
            <DaysOfWeekForm handleCheckDay={(day, stateToChange) => handleCheckDay(day, stateToChange, 'dayWeekState')} key='DayTimesBox&week' days={dayWeekState} handlerCheck={(id, s) => handlerCheck(s, 'week')} stateWeek={checkedGroups.week} classes={['week', (checkedGroups.week === true ? 'checked' : ''), (checkedGroups.weekend === true ? 'fridayNot' : '')]} />
            <DaysOfWeekForm handleCheckDay={(day, stateToChange) => handleCheckDay(day, stateToChange, 'dayWeekendState')} key='DayTimesBox&weekend' days={dayWeekendState} handlerCheck={(id, s) => handlerCheck(s, 'weekend')} stateWeek={checkedGroups.weekend} classes={['weekend', (checkedGroups.weekend === true ? 'checked' : '')]} />
        </div>
    );
};

WeekSecretary.propTypes = {
    handlePostDays: PropTypes.func,
    weekState: PropTypes.object
};

export default WeekSecretary;
