import {
    typeCarsLoaded,
    typeClearFoundCars,
    typeFoundCarsLoaded,
    typeTextSearchCarLoaded,
    typeCarsSearchFetching,
    typeSearchCarsError,
    typeListCollectResourceFilterSuccess,
    typeListCollectResourceFilterError
} from '../actionsTypes';
import _ from 'lodash';

const carsList = (state, action) => {
    let idsOldFoundCars,
        newFoundCars;

    if (state === undefined) {
        return {
            cars: [],
            textSearch: {
                search: '',
                limit: 25,
                offset: 0
            },
            foundCars: [],
            fetchingSearchCars: false,
            totalCountFoundCars: 0,
            searchCarsError: false,
            carsListCollectFilterLoading: false,
            carsListCollectFilterError: false
        };
    }

    const { carsListState } = state;
    const textSearch = _.cloneDeep(carsListState.textSearch);
    let foundCars = _.cloneDeep(carsListState.foundCars);
    let textSearchNew = {};

    switch (action.type) {
    case typeCarsLoaded:
        return {
            ...carsListState,
            cars: action.payload
        };

    case typeTextSearchCarLoaded:
        textSearchNew = { ...textSearch, ...action.payload };

        return {
            ...carsListState,
            textSearch: textSearchNew
        };

    case typeCarsSearchFetching:
        return {
            ...carsListState,
            fetchingSearchCars: action.payload
        };

    case typeSearchCarsError:
        return {
            ...carsListState,
            searchCarsError: action.payload
        };

    case typeClearFoundCars:
        textSearch.offset = 0;
        textSearch.search = '';

        return {
            ...carsListState,
            textSearch: textSearch,
            foundCars: [],
            totalCountFoundCars: 0
        };

    case typeFoundCarsLoaded:
        idsOldFoundCars = foundCars.map(item => item.id);
        newFoundCars = [
            ...action.payload.cars.filter(item => idsOldFoundCars.indexOf(item.id) === -1)
        ];

        foundCars = [...foundCars, ...newFoundCars];

        return {
            ...carsListState,
            foundCars: foundCars,
            totalCountFoundCars: action.payload.total
        };

    case typeListCollectResourceFilterSuccess:
        return {
            ...carsListState,
            carsListCollectFilterLoading: action.payload
        };

    case typeListCollectResourceFilterError:
        return {
            ...carsListState,
            carsListCollectFilterError: action.payload
        };

    default:
        return carsListState;
    }
};

export default carsList;
