import React, { useState, useEffect } from 'react';
import style from '../../SheetCollectBody.module.scss';
import SubHeaderCollect from 'components/SubHeaderCollect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SetCarDetailsSelectCollect, SetCarInsuranceDate, SetCarInterest } from 'components/Requests';
import { fetchPostCarInsurance } from 'actions';
import ToggleRadio from 'components/CheckToggle/ToggleRadio';
import { BLOCK_LABEL_CASCO } from '../../../../../constants/carCollectBlockLabels';
import dateFromDateField from '../../../../../utils/dateFromDateField';
import cost from 'assets/cost.png';
import PreReserve from '../../../../../components/PreReserve/PreReserve';
import { isBefore } from 'date-fns';
import MessageForForm from '../../../../../components/MessageFoForm';
import InviteFrom1C from '../InviteFrom1C';
import { ButtonsProlongation } from '../../../../../components/Buttons/ButtonsCommon';
import { CHANGE_MODE } from '../../../../../constants/prolongationMode';

const CostCasco = ({ label, value, handleClick }) => {
    const [newValue, setNewValue] = useState(0);
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
        value && setNewValue(value);
        return () => {
            setNewValue(0);
            setIsFocus(false);
        };
    }, [value]);

    useEffect(() => {
        return () => setNewValue(0);
    }, [value]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFocus(false);
            value !== newValue && handleClick(newValue);
        }, 2000);
        return () => clearTimeout(timer);
    }, [value, handleClick, newValue]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsFocus(false);
        value !== newValue && handleClick(newValue);
    };

    const handleFocus = () => {
        setIsFocus(true);
    };

    return (
        <div className={style.cost}>
            <div className={style.label}>{ label }</div>
            <div className={style.inputCost + (isFocus ? ' ' + style.focusSet : '')}>
                <form className={style.wrapInput} onSubmit={handleSubmit} onBlur={handleSubmit} onFocus={handleFocus}>
                    <input className={style.cost} type='text' value={newValue} onChange={(e) => setNewValue(+e.target.value)} />
                </form>
                <div className={style.icon}>
                    <img src={cost} alt='P' />
                </div>
            </div>
        </div>
    );
};

CostCasco.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    handleClick: PropTypes.func
};

const Casco = ({
    textSubHeaders,
    id,
    carCollectBlocks,
    fetchPostCarInsurance,
    clientIdCollect,
    anchors,
    invitations1C,
    forSvBook,
    isProlongation,
    handleUpdateProlongation,
    carDetailsInfo = {}
}) => {
    const [isHidden, setIsHidden] = useState(false);
    const [packetId, setPacketId] = useState(0);
    const [inviteDate, setInviteDate] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isValidationOn, setValidationOn] = useState(false);

    const handleValidationTurnOn = (turnOn) => {
        setValidationOn(turnOn);
    };

    useEffect(() => {
        return () => setValidationOn(false);
    }, [carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        const resValidate = (carDetailsInfo?.has_casco ? (carDetailsInfo?.date_end_casco && isBefore(new Date(), new Date(carDetailsInfo?.date_end_casco))) : true) && (isProlongation !== null);
        setIsValid(!!resValidate);
    }, [carDetailsInfo, isProlongation]);

    useEffect(() => {
        const inviteDate = invitations1C && invitations1C.length > 0 && invitations1C.find(item => item.packetId === packetId) && invitations1C.find(item => item.packetId === packetId).inviteDate;
        setInviteDate(inviteDate || '');
    }, [invitations1C, packetId]);

    useEffect(() => {
        const block = carCollectBlocks && carCollectBlocks.blocks && carCollectBlocks.blocks.find(item => item.label === BLOCK_LABEL_CASCO);
        block && block.packet_id && setPacketId(block.packet_id);
    }, [carCollectBlocks]);

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };

    const interestsReasons = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[3] : null) : null;

    const handleSave = (value, propName) => {
        fetchPostCarInsurance({ car_id: carDetailsInfo.id, [propName]: value }, clientIdCollect);
    };

    const casco = (
        <div className={style.wrapBodySubCollect}>
            <div className={style.fields}>
                <div className={style.hasCasco}>
                    <label>Оформлен</label>
                    <ToggleRadio
                        isActive={!!carDetailsInfo.has_casco}
                        id='cascoToggleRadioId'
                        handleClickActive={(value) => handleSave(value, 'has_casco')}
                    />
                </div>
                <CostCasco key='cost_casco' value={carDetailsInfo.cost_casco} label='Стоимость полиса' handleClick={(value) => handleSave(value, 'cost_casco')} />
                <div className={style.dateEnd}>
                    <SetCarInsuranceDate
                        idName='dateEndCasco'
                        nameForm='Дата окончания'
                        propCar='date_end_casco'
                        key='date_end_casco'
                        valueField={carDetailsInfo.date_end_casco}
                        blockId={id}
                        classes={!isValid && (!carDetailsInfo.date_end_casco || isBefore(new Date(carDetailsInfo.date_end_casco), new Date()))
                            ? (isValidationOn ? ['redBorder'] : (carDetailsInfo?.date_end_casco ? ['warningColor'] : []))
                            : []}
                    />
                    {
                        isValidationOn && !carDetailsInfo.date_end_casco
                            ? (isValid ? '' : <MessageForForm message='Укажите дату окончания' classes={['maxWidthStretch', 'height40']} />)
                            : (
                                carDetailsInfo.date_end_casco && isBefore(new Date(carDetailsInfo.date_end_casco), new Date())
                                    ? <MessageForForm key='is_date_casco_over' message='Просрочен' classes={['warningColor', 'maxWidthStretch', 'height40']} />
                                    : ''
                            )
                    }
                </div>
                <SetCarDetailsSelectCollect idName='insuranceCompanyCASCO' propCar='company_casco_id' key='company_casco_id' valueField={carDetailsInfo.company_casco_id} blockId={id} />
            </div>
            {
                isProlongation
                    ? ''
                    : <SetCarInterest nameBlock='Интерес к услуге' interestsReasons={interestsReasons} id={id} />
            }
            { forSvBook
                ? ''
                : (
                    <PreReserve
                        packetId={packetId}
                        type='standard'
                        serviceName='КАСКО'
                        isNotValid={!isValid}
                        isValidationOn={isValidationOn}
                        handleValidationTurnOn={handleValidationTurnOn}
                        isProlongation={isProlongation}
                        handleUpdateProlongation={handleUpdateProlongation}
                        id='pre_reserve_kacko'
                    />
                ) }
        </div>
    );

    const handleUpdateCascoDeadline = (newValue, oldValue) => {
        const payload = {
            car_id: carDetailsInfo.id,
            date_end_casco: dateFromDateField(inviteDate)
        };
        clientIdCollect && carDetailsInfo.id && (payload.date_end_casco !== oldValue) && fetchPostCarInsurance(payload, clientIdCollect);
    };

    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationCasco'
            id='prolongationCasco'
            handleClick={(res) => handleUpdateProlongation(res)}
            btnSelected={CHANGE_MODE[isProlongation]}
            classes={['btnStretchCycle', 'btnTires', 'margin5', 'bgWhite', (isProlongation === null ? (isValidationOn ? 'redBorder' : 'notCheck') : '')]}
        />
    );

    return (
        <div className={style.blockAnchors} id={id}>
            <SubHeaderCollect
                title={textSubHeaders[1].toUpperCase()}
                handleSetHidden={handleSetHidden}
                blockId={id} stateBool={isHidden}
                anchors={anchors}
                actions={forSvBook ? [] : [prolongationButton]}
                classes={['withoutPadding']}
            >
                {
                    forSvBook
                        ? ''
                        : (
                            <ToggleRadio
                                text='Не собрано'
                                isActive={false}
                                key='isCollectCascoInfo'
                                id='isCollectCascoInfo'
                                handleClickActive={(res) => console.log('prolo nggggg', res)}
                            />
                        )
                }
            </SubHeaderCollect>
            {
                inviteDate && !carDetailsInfo.date_end_casco
                    ? (
                        <InviteFrom1C
                            key='InviteFrom1C_casco'
                            text='дата КАСКО'
                            inviteDate={inviteDate}
                            buttonText='Применить'
                            handleUpdateNextOil={() => handleUpdateCascoDeadline(inviteDate, carDetailsInfo.date_end_casco)}
                        />
                    )
                    : ''
            }
            {
                isHidden
                    ? ''
                    : casco
            }
        </div>
    );
};

Casco.propTypes = {
    textSubHeaders: PropTypes.array,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    id: PropTypes.number,
    carCollectBlocks: PropTypes.object,
    anchors: PropTypes.bool,
    forSvBook: PropTypes.bool,
    fetchPostCarInsurance: PropTypes.func,
    handleUpdateProlongation: PropTypes.func,
    isProlongation: PropTypes.bool,
    invitations1C: PropTypes.array
};

const mapStateToProps = ({
    carDetailsState: { carDetailsInfo, clientIdCollect },
    templatesOfFrameState: { textSubHeaders, carCollectBlocks },
    anchorsFrom1C: { invitations1C }
}) => {
    return {
        textSubHeaders,
        carDetailsInfo,
        carCollectBlocks,
        clientIdCollect,
        invitations1C
    };
};

export default connect(mapStateToProps, { fetchPostCarInsurance })(Casco);
