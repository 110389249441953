import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateEndInsurance from '../DateEndInsurance/DateEndInsurance';
import { createDateForSaveFromDatePicker } from '../../utils';
import { fetchPostCarOil } from '../../actions';

const SetCarOilDate = ({
    carIdCollect,
    clientIdCollect,
    valueField,
    carDetailsInfo,
    propCar,
    nameForm,
    fetchPostCarOil,
    carOilUpdateError,
    withDelButton,
    classes
}) => {
    const [valueForm, setValueForm] = useState(valueField);

    useEffect(() => {
        setValueForm(valueField);
    }, [valueField, carDetailsInfo]);

    if (carOilUpdateError) {
        return <div>Something is wrong</div>;
    }

    const handleUpdate = (valueUp) => {
        if (carDetailsInfo[propCar] !== createDateForSaveFromDatePicker(valueUp)) {
            const post = {
                car_id: carIdCollect,
                [propCar]: valueUp ? createDateForSaveFromDatePicker(valueUp) : valueUp
            };
            carIdCollect && clientIdCollect && fetchPostCarOil(post, clientIdCollect);
        }
    };

    return (
        <DateEndInsurance
            nameForm={nameForm}
            valueField={valueForm}
            forUpdateField={handleUpdate}
            labelName={nameForm}
            idName={propCar}
            id={propCar}
            classes={classes}
            withDelButton={withDelButton}
            withoutLabel
        />
    );
};

SetCarOilDate.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    valueField: PropTypes.any,
    propCar: PropTypes.string,
    nameForm: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    withDelButton: PropTypes.bool,
    classes: PropTypes.array
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect, carDetailsInfo, carOilUpdateError } }) => {
    return {
        carIdCollect,
        clientIdCollect,
        carDetailsInfo,
        carOilUpdateError
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(SetCarOilDate);
