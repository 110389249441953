import {
    typeSetInviteFrom1C,
    typeGetInviteFrom1CSuccess,
    typeGetInviteFrom1CError,
    typeClearInviteFrom1C
} from '../actionsTypes';

// ------------------------------------
// Constants
// ------------------------------------
const SET_INVITE_FROM_1C = typeSetInviteFrom1C;
const GET_INVITE_FROM_1C_SUCCESS = typeGetInviteFrom1CSuccess;
const GET_INVITE_FROM_1C_ERROR = typeGetInviteFrom1CError;
const CLEAR_INVITE_FROM_1C = typeClearInviteFrom1C;

// ------------------------------------
// Actions
// ------------------------------------

export const setInviteFrom1C = (payload) => {
    return {
        type: SET_INVITE_FROM_1C,
        payload: payload
    };
};
export const getDateFrom1CSuccess = (payload) => {
    return {
        type: GET_INVITE_FROM_1C_SUCCESS,
        payload: payload
    };
};
export const getDateFrom1CError = (payload) => {
    return {
        type: GET_INVITE_FROM_1C_ERROR,
        payload: payload
    };
};
export const clearInvitationsFrom1C = () => {
    return {
        type: CLEAR_INVITE_FROM_1C,
        payload: true
    };
};

export const getAnchorsFrom1C = (carId) => (dispatch, getState, { apiService }) => {
    if (carId) {
        dispatch(getDateFrom1CSuccess(true));
        dispatch(getDateFrom1CError(false));
    }
    return carId && apiService.getDateFrom1C(carId)
        .then((data) => {
            dispatch(setInviteFrom1C(data));
            dispatch(getDateFrom1CSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(getDateFrom1CSuccess(false));
            dispatch(getDateFrom1CError(true));
            return err;
        });
};

export const actions = {
    getAnchorsFrom1C,
    clearInvitationsFrom1C
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_INVITE_FROM_1C]: (state, action) => {
        return ({
            ...state,
            invitations1C: action.payload
        });
    },

    [GET_INVITE_FROM_1C_SUCCESS]: (state, action) => {
        return ({
            ...state,
            invitations1CSuccess: action.payload
        });
    },

    [GET_INVITE_FROM_1C_ERROR]: (state, action) => {
        return ({
            ...state,
            invitations1CError: action.payload
        });
    },

    [CLEAR_INVITE_FROM_1C]: (state, action) => {
        if (action.payload) {
            return ({
                ...state,
                invitations1C: [],
                invitations1CSuccess: false,
                invitations1CError: false
            });
        } else {
            return ({
                ...state
            });
        }
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            invitations1C: [],
            invitations1CSuccess: false,
            invitations1CError: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.anchorsFrom1C, action) : state.anchorsFrom1C;
};

export default commonReducer;
