export const OIL_REFERENCES = {
    mark: 'mark_motor_oil',
    type: 'type_oil',
    viscosity: 'viscosity_oil',
    expendableMaterials: 'expendable_materials_oil',
    washing: 'washing_oil',
    protection: 'protection_oil'
};

export const OIL_IS_LAST_MILEAGE = 'is_last_mileage';
export const OIL_MILEAGE_NEXT_CHANGE = 'mileage_next_change';
export const OIL_MILEAGE = 'oil_mileage';
