import React, { useState } from 'react';
import { RowsKeyValue } from '../Row';
import PropTypes from 'prop-types';
import HandbookActionModal from '../../routes/Settings/Handbooks/components/HandbookActionModal';

import style from './IterableItemsTable.module.scss';

const IterableItemsTable = ({
    name,
    handleClickTrash,
    addBtn,
    handleAddItem,
    fields = [],
    pairHeadersWithBtnAddName = [],
    iterableItems = [],
    representations = [],
    delBtn = false,
    updBtn = false
}) => {
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const handleOpenModal = (isShow, id) => {
        if ((isShow === true) && id) {
            (iterableItems.length > 0) && setSelectedItem(...iterableItems.filter(item => item.id === id));
        } else {
            setSelectedItem({});
        }
        setIsShowModal(isShow);
    };
    const resultItems = iterableItems && iterableItems.length > 0
        ? iterableItems.map((item, i) => {
            const res = (
                <RowsKeyValue
                    key={'row' + name + '&' + item.id + '&' + i}
                    left={item.id}
                    right={fields.length > 1 ? (Object.prototype.hasOwnProperty.call(item, fields[1])
                        ? item[fields[1]] : '') : ''}
                    classes={['borderDashed']}
                    handleClickTrash={() => handleClickTrash(item.id)}
                    handleUpdate={() => handleOpenModal(true, item.id)}
                    del={delBtn}
                    upd={updBtn}
                />);
            return res;
        })
        : <RowsKeyValue key='interestInitial' left='Result: ' right='Empty' />;

    const modal = (
        <HandbookActionModal
            name={name}
            handleAddItem={handleAddItem}
            initialItem={selectedItem}
            handleCloseModal={() => handleOpenModal(false)}
            fields={fields}
            representations={representations}
        />
    );

    return (
        <>
            <div className={style.paddingBefore} />
            <RowsKeyValue
                key={'headerInitial' + name}
                left={pairHeadersWithBtnAddName.length > 0 ? pairHeadersWithBtnAddName[0] : ''}
                right={pairHeadersWithBtnAddName.length > 1 ? pairHeadersWithBtnAddName[1] : ''}
                classes={['header', 'borderDashed']}
            />
            { resultItems }
            {
                addBtn
                    ? (
                        <button
                            className={style.btnAdd}
                            onClick={() => handleOpenModal(true)}
                        >+ { pairHeadersWithBtnAddName.length > 2 ? pairHeadersWithBtnAddName[2] : 'Создать' }
                        </button>
                    )
                    : ''
            }
            {
                isShowModal
                    ? modal
                    : ''
            }
        </>
    );
};

IterableItemsTable.propTypes = {
    name: PropTypes.string,
    pairHeadersWithBtnAddName: PropTypes.array,
    handleClickTrash: PropTypes.func,
    addBtn: PropTypes.bool,
    handleAddItem: PropTypes.func,
    fields: PropTypes.array,
    iterableItems: PropTypes.array,
    delBtn: PropTypes.bool,
    updBtn: PropTypes.bool,
    representations: PropTypes.array
};

export default IterableItemsTable;
