import React, { useState } from 'react';
import style from './GuestStatuses.module.scss';
import CheckBoxCommon from '../../CheckBoxes/CheckBoxesCommon';
import PropTypes from 'prop-types';
import ClientNamePhoneRow from '../../ClientNamePhoneRow';
import ModalCommon from '../../ModalCommon';
import AddSearchClientForm from '../../AddSearchClientForm';
import { STATUS_OWNER } from '../../../constants/statusesCarClient';

const GuestStatuses = ({
    guest,
    guestStatuses,
    statusesConfig,
    handleUpdateStatuses,
    searchNewClient,
    foundClients,
    searchInfo,
    isLoadSearchClient,
    carId
}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [newGuest, setNewGuest] = useState({});

    const handleUpdateClientStatuses = (status, isChecked) => {
        let newStatuses = [];
        if (isChecked) {
            if (!guestStatuses.includes(status)) {
                newStatuses = [...guestStatuses, status];
            }
        } else {
            if (guestStatuses.includes(status)) {
                newStatuses = [...guestStatuses.filter(item => item !== status)];
            }
        }

        handleUpdateStatuses({ clientId: guest.id, clientStatuses: newStatuses });
    };

    const handleSetBundleStatuses = (statuses, isChecked) => {
        if (isChecked) {
            handleUpdateStatuses({ clientId: guest.id, clientStatuses: [...guestStatuses, ...statuses] });
        } else {
            handleUpdateStatuses({ clientId: guest.id, clientStatuses: [...guestStatuses.filter(item => !statuses.includes(item))] });
        }
    };

    const modal = (
        <ModalCommon
            id='MultiSelectStatuses&ModalCommon'
            handleConfirm={() => console.log('confirm', newGuest)}
            nameButtons={['Отменить', 'Изменить гостя']}
            classesBody={['bgOrange', 'boldFirst']}
            classesModal={['bigModal', 'fontSize22', 'labelText2pad32']}
            labelFields='Изменение гостя в заказ-наряде'
            classesButtonsArea={['statusOwner']}
            disabledButtonConfirm
            handelClose={() => setIsOpenModal(false)}
        >
            <AddSearchClientForm
                id='newOwner'
                handlePushNewClient={(data) => setNewGuest(() => {
                    delete data.statuses;
                    return data;
                })}
                carId={carId}
                foundClients={foundClients}
                searchInfo={searchInfo}
                handleSearchNewClient={searchNewClient}
                isLoadSearchClient={isLoadSearchClient}
                classesInputs={['minHeight38']}
                placeholderText='Имя гостя'
            />
        </ModalCommon>
    );

    return (
        <div className={style.guestWrap}>
            <div className={style.titleGuest}>
                <h2>Гость</h2>
                <div className={style.checkBoxesStatuses}>
                    <CheckBoxCommon
                        key='all_statuses'
                        blockInterestReasonId='all_statuses_id'
                        stateChecked={!statusesConfig.find((item) => !guestStatuses.includes(item.label))}
                        handleChecked={(id, isChecked) => handleSetBundleStatuses(statusesConfig.map(item => item.label), isChecked)}
                        textLabel='Все статусы'
                        classes={['blueLabel', 'paddingEmpty', 'paddingRight16', 'alignItemsEnd', 'selectBgBlue']}
                        withCheckBox
                        withStringId
                        darkMode
                    />
                    {
                        statusesConfig.filter(status => status.label === STATUS_OWNER).map(item => {
                            return (
                                <CheckBoxCommon
                                    key={item.label}
                                    blockInterestReasonId={item.label}
                                    stateChecked={guestStatuses.includes(item.label)}
                                    handleChecked={handleUpdateClientStatuses}
                                    textLabel={item.short_title}
                                    classes={['blueLabel', 'paddingEmpty', 'paddingRight16', 'alignItemsEnd']}
                                    withCheckBox
                                    withStringId
                                />
                            );
                        })
                    }
                    <div className={style.statusesWithUpCheckBox}>
                        <div className={style.unionCheckBox}>
                            <div className={style.unionCheckBoxBg}>
                                {
                                    statusesConfig && statusesConfig.length === 0
                                        ? ''
                                        : (
                                            <CheckBoxCommon
                                                key='unionCheckBox_statuses'
                                                blockInterestReasonId='unionCheckBox_statuses'
                                                stateChecked={!statusesConfig.filter(item => item.label !== STATUS_OWNER).find((item) => !(guestStatuses.includes(item.label)))}
                                                handleChecked={(id, isChecked) => handleSetBundleStatuses(statusesConfig.map(item => item.label).filter(item => item !== STATUS_OWNER), isChecked)}
                                                textLabel=''
                                                classes={['blueLabel', 'paddingEmpty', 'margin10']}
                                                withCheckBox
                                                withStringId
                                            />
                                        )
                                }
                            </div>
                        </div>
                        <div className={style.forUnionCheckBox}>
                            {
                                statusesConfig.filter(status => status.label !== STATUS_OWNER).map(item => {
                                    return (
                                        <div className={style.wrapCheckBox + (!statusesConfig.filter(item => item.label !== STATUS_OWNER).find((item) => !(guestStatuses.includes(item.label))) ? ' ' + style.groupChecked : '')} key={item.label}>
                                            <div className={style.pipeUp}><div className={style.inLine1} /><div className={style.inLine2} /></div>
                                            <CheckBoxCommon
                                                key={item.label}
                                                blockInterestReasonId={item.label}
                                                stateChecked={guestStatuses.includes(item.label)}
                                                handleChecked={handleUpdateClientStatuses}
                                                textLabel={item.short_title}
                                                classes={['blueLabel', 'paddingEmpty', 'paddingRight16']}
                                                withCheckBox
                                                withStringId
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.rowGuest}>
                <ClientNamePhoneRow
                    name={guest.nick || ''}
                    phone={guest.phone || ''}
                    handleChangeClient={() => {}}
                    handleDeleteClient={() => {}}
                    searchNewClient={searchNewClient}
                    id={guest.id}
                    classes={['disabled2']}
                    disabled
                />
                {
                    isOpenModal
                        ? modal
                        : ''
                }
                { /* <GrayButton text='Изменить' handlerClick={() => setIsOpenModal(true)} classes={['showMore', 'minWidth', 'marginRight16', 'marginLeft8']} /> */ }
            </div>
        </div>
    );
};

GuestStatuses.propTypes = {
    handleUpdateStatuses: PropTypes.func,
    guest: PropTypes.object,
    guestStatuses: PropTypes.array,
    statusesConfig: PropTypes.array,
    searchNewClient: PropTypes.func,
    carId: PropTypes.number,
    foundClients: PropTypes.array,
    searchInfo: PropTypes.object,
    isLoadSearchClient: PropTypes.bool
};

export default GuestStatuses;
