function reduceEmployee(array, field) {
    return array.reduce((accum, item) => {
        if (item[field] > 0) {
            accum.full = accum.full ? [...accum.full, item] : [item];
        } else {
            accum.empty = accum.empty ? [...accum.empty, item] : [item];
        }
        return accum;
    }, {});
}

function sortReduceResult({ full, empty }, field) {
    return [
        ...(full && Array.isArray(full) ? full.sort((a, b) => sortEm(a, b, field)) : []),
        ...(empty && Array.isArray(empty) ? empty.sort((a, b) => sortEm(a, b, field)) : [])
    ];
}

function sortEm(a, b, field) {
    return a[field].localeCompare(b[field], 'ru');
}

export default function sortEmployeeByField(arrEmployee, fieldForSort, fieldForSeparate) {
    if (Array.isArray(arrEmployee) && arrEmployee.length > 0) {
        return sortReduceResult(reduceEmployee(arrEmployee, fieldForSeparate), fieldForSort);
    } else {
        return arrEmployee;
    }
}
