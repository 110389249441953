import {
    typeSeasonPeriodTimes,
    typeSeasonPeriodTimesError,
    typeSeasonPeriodTimesSuccess,
    typeSeasonPeriodTimesStore,
    typeSeasonPeriodTimesDelete
} from '../actionsTypes';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const SEASON_PERIOD_TIMES = typeSeasonPeriodTimes;
const SEASON_PERIOD_TIMES_ERROR = typeSeasonPeriodTimesError;
const SEASON_PERIOD_TIMES_SUCCESS = typeSeasonPeriodTimesSuccess;
const SEASON_PERIOD_TIMES_STORE = typeSeasonPeriodTimesStore;
const SEASON_PERIOD_TIMES_DELETE = typeSeasonPeriodTimesDelete;

// ------------------------------------
// Actions
// ------------------------------------

export const setSeasonPeriodTimes = (data) => {
    return {
        type: SEASON_PERIOD_TIMES,
        payload: data
    };
};

export const seasonPeriodTimesError = (isError) => {
    return {
        type: SEASON_PERIOD_TIMES_ERROR,
        payload: isError
    };
};

export const seasonPeriodTimesSuccess = (isSuccess) => {
    return {
        type: SEASON_PERIOD_TIMES_SUCCESS,
        payload: isSuccess
    };
};

export const seasonPeriodTimesStore = (data) => {
    return {
        type: SEASON_PERIOD_TIMES_STORE,
        payload: data
    };
};

export const seasonPeriodTimesDelete = (data) => {
    return {
        type: SEASON_PERIOD_TIMES_DELETE,
        payload: data
    };
};

export const getSeasonPeriodTimes = () => (dispatch, getState, { apiService }) => {
    dispatch(seasonPeriodTimesError(false));
    dispatch(seasonPeriodTimesSuccess(true));
    return apiService.getSeasonPeriodTimesTires()
        .then((res) => {
            dispatch(setSeasonPeriodTimes(res));
            dispatch(seasonPeriodTimesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(seasonPeriodTimesError(true));
            return err;
        });
};

export const storeSeasonPeriodTimes = (nameTable, data) => (dispatch, getState, { apiService }) => {
    dispatch(seasonPeriodTimesError(false));
    dispatch(seasonPeriodTimesSuccess(true));
    return apiService.postSeasonsPeriodTimesTires(data)
        .then((res) => {
            res && dispatch(seasonPeriodTimesStore(res));
            dispatch(seasonPeriodTimesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(seasonPeriodTimesError(true));
            return err;
        });
};

export const delSeasonPeriodTimes = (id) => (dispatch, getState, { apiService }) => {
    dispatch(seasonPeriodTimesError(false));
    dispatch(seasonPeriodTimesSuccess(true));
    return apiService.deleteSeasonsPeriodTimesTires(id)
        .then((res) => {
            res && dispatch(seasonPeriodTimesDelete(id));
            dispatch(seasonPeriodTimesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(seasonPeriodTimesError(true));
            return err;
        });
};

export const actions = {
    getSeasonPeriodTimes,
    storeSeasonPeriodTimes,
    delSeasonPeriodTimes,
    seasonPeriodTimesStore
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SEASON_PERIOD_TIMES]: (state, action) => {
        return ({
            ...state,
            seasonPeriodTimes: action.payload
        });
    },

    [SEASON_PERIOD_TIMES_ERROR]: (state, action) => {
        return ({
            ...state,
            seasonPeriodTimesError: action.payload
        });
    },

    [SEASON_PERIOD_TIMES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            seasonPeriodTimesSuccess: action.payload
        });
    },

    [SEASON_PERIOD_TIMES_STORE]: (state, action) => {
        if ((Array.isArray(action.payload) && action.payload.length === 0)) {
            return {
                ...state,
                seasonPeriodTimes: []
            };
        }

        if ((Array.isArray(action.payload) && action.payload.length > 0) || !Object.hasOwnProperty.call(action.payload, 'id')) {
            return {
                ...state
            };
        }

        const oldItems = _.cloneDeep(state.seasonPeriodTimes);

        const idx = oldItems.findIndex((item) => item.id === action.payload.id);
        if (idx !== -1) {
            return {
                ...state,
                seasonPeriodTimes: [
                    ...oldItems.slice(0, idx),
                    action.payload,
                    ...oldItems.slice(idx + 1)
                ]
            };
        } else {
            return {
                ...state,
                seasonPeriodTimes: [
                    ...oldItems,
                    action.payload
                ]
            };
        }
    },

    [SEASON_PERIOD_TIMES_DELETE]: (state, action) => {
        const oldItems = _.cloneDeep(state.seasonPeriodTimes);
        const idx = oldItems.findIndex((item) => item.id === action.payload);
        if (idx !== -1) {
            return {
                ...state,
                seasonPeriodTimes: [
                    ...oldItems.slice(0, idx),
                    ...oldItems.slice(idx + 1)
                ]
            };
        } else {
            return {
                ...state
            };
        }
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            seasonPeriodTimes: [],
            seasonPeriodTimesError: false,
            seasonPeriodTimesSuccess: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.settingsTires, action) : state.settingsTires;
};

export default commonReducer;
