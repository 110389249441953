import React from 'react';
import style from './CarMarkNumber.module.scss';
import CarNumber from '../CarNumber';
import PropTypes from 'prop-types';

const CarMarkNumber = ({ carInfo, classes = [] }) => {
    return (
        <div className={style.carWrap + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.carMark}>
                { carInfo.carMark } { carInfo.carModel }
            </div>
            <CarNumber number={carInfo?.carNumber} region={carInfo?.carNumberRegion} classes={classes} />
        </div>
    );
};

CarMarkNumber.propTypes = {
    carInfo: PropTypes.object,
    classes: PropTypes.array
};

export default CarMarkNumber;
