import React, { useEffect, useRef, useState } from 'react';
import style from './ScrollCustom.module.scss';
import PropTypes from 'prop-types';

function onWheel(e, position, handleSetValueScroll, setPosition, maxScroll) {
    const event = e || window.event;
    let delta = event.deltaY || event.detail || event.wheelDelta;
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);

    if (Math.abs(delta) < 10) {
        delta = delta * 10;
    }

    const newPosition = position - delta;
    setPosition(newPosition);
    handleSetValueScroll(-newPosition * 2 < maxScroll ? (newPosition > 0 ? 0 : newPosition * 2) : -maxScroll);
}

const ScrollCustom = ({ handleSetValueScroll, valueTranslateX, maxScroll }) => {
    const [position, setPosition] = useState(0);
    const [thumbWidth, setThumbWidth] = useState(0);
    const scrollElement = useRef(null);
    const moveElement = useRef(null);

    useEffect(() => {
        setThumbWidth(scrollElement && scrollElement.current ? (scrollElement.current.offsetWidth - maxScroll - 20) : 100);
    }, [scrollElement, maxScroll]);

    const handleCatchElement = (e, moveElement) => {
        if (moveElement) {
            const pointStartLocal = e.pageX;
            document.onmousemove = function(e) {
                const newPosition = e.pageX - pointStartLocal;
                handleSetValueScroll(newPosition < maxScroll ? (newPosition < 0 ? 0 : -newPosition) : -maxScroll);

                // document.onmousedown = null;
            };

            document.onmouseup = function(e) {
                const newPosition = e.pageX - pointStartLocal;
                handleSetValueScroll(newPosition < maxScroll ? (newPosition < 0 ? 0 : -newPosition) : -maxScroll);
                document.onmousemove = null;
                // document.onmousedown = null;
                setTimeout(function() {
                    document.onmouseup = null;
                }, 50);
            };
        }
    };

    useEffect(() => {
        setPosition(Math.abs(position) > Math.abs(valueTranslateX) ? valueTranslateX : position);
        const elem = scrollElement.current;

        const handleUnderWithScroll = (e) => {
            onWheel(e, position, handleSetValueScroll, setPosition, maxScroll);
        };

        if (scrollElement && scrollElement.current) {
            elem.addEventListener('wheel', handleUnderWithScroll, { passive: false });
            return () => {
                elem.removeEventListener('wheel', handleUnderWithScroll, { passive: false });
            };
        }
    }, [scrollElement, position, valueTranslateX, handleSetValueScroll, maxScroll]);

    if (maxScroll <= 0) {
        return null;
    }

    return (
        <div className={style.wrapScroll}>
            <div
                className={style.trackScroll}
                ref={scrollElement}
            >
                <div
                    className={style.thumbScroll}
                    onMouseDown={(e) => handleCatchElement(e, moveElement)}
                    ref={moveElement}
                    style={{
                        transform: `translateX(${-valueTranslateX}px)`,
                        width: `${thumbWidth}px`
                    }}
                />
            </div>
        </div>
    );
};

ScrollCustom.propTypes = {
    handleSetValueScroll: PropTypes.func,
    valueTranslateX: PropTypes.number,
    maxScroll: PropTypes.number
};

export default ScrollCustom;
