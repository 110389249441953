import React from 'react';
import PropTypes from 'prop-types';
import style from './OwnerSettingsHeader.module.scss';

const HeaderSettingsInBody = ({ text }) => {
    return (
        <div className={style.subHeader}>
            <h2>{ text }</h2>
        </div>
    );
};

HeaderSettingsInBody.propTypes = {
    text: PropTypes.string
};

export default HeaderSettingsInBody;
