import React, { useEffect } from 'react';
import ReportsHeader from '../../../ReportsHeader';
import PairColumn from '../../../../../components/PairColumn';
import CollectionSheetsReportWrap from './CollectionSheetsReportWrap';
import FiltersForSheets from './FiltersForSheets';
import PropTypes from 'prop-types';
import { setOwnerIdSheets, setStatusesPreferSheets } from '../../../../../reducers/reportsCollectionSheets';
import { connect } from 'react-redux';

const CollectionSheetsReport = ({ ownerId, statusesPrefer, setOwnerIdSheets, setStatusesPreferSheets }) => {
    useEffect(() => {
        ownerId && setOwnerIdSheets(ownerId);
    }, [ownerId, setOwnerIdSheets]);

    useEffect(() => {
        statusesPrefer && setStatusesPreferSheets(statusesPrefer);
    }, [statusesPrefer, setStatusesPreferSheets]);

    return (
        <PairColumn>
            <ReportsHeader textHeader='' classes={['inReport']} withDataFilter>
                <FiltersForSheets />
            </ReportsHeader>
            <CollectionSheetsReportWrap />
        </PairColumn>
    );
};

CollectionSheetsReport.propTypes = {
    ownerId: PropTypes.number,
    statusesPrefer: PropTypes.string,
    setOwnerIdSheets: PropTypes.func,
    setStatusesPreferSheets: PropTypes.func
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    setOwnerIdSheets,
    setStatusesPreferSheets
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionSheetsReport);
