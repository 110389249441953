export default function checkStatusExist(carStatuses, allStatuses, neededStatus, clients = []) {
    if (!allStatuses || !allStatuses.find(item => item.label === neededStatus)) {
        return false;
    }

    const res = carStatuses?.length > 0
        ? carStatuses.find(status => status?.client_status?.label === neededStatus)
        : false;

    if (clients.length > 0 && res) {
        return clients.find(client => client.id === res.client_id);
    }

    return !!res;
}
