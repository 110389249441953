export const STATUS_OWNER = 'owner';
export const STATUS_MAIN_USER = 'main_user';
export const STATUS_UNKNOWN = 'unknown';
export const OBJECT_STATUS_UNKNOWN = { label: STATUS_UNKNOWN, title: '', short_title: '', description: 'статус не выбран' };
export const STATUSES_NEED_CONFIRM = [STATUS_OWNER, STATUS_MAIN_USER];

export const MODAL_DATA_STATUSES = {
    [STATUS_OWNER]: {
        labelFields: 'Изменение собственника',
        textBody: 'У данного автомобиля уже назначен собственник. Вы уверены, что хотите сменить текущего собственника на нового?'
    },
    [STATUS_MAIN_USER]: {
        labelFields: 'Изменение основного пользователя',
        textBody: 'У данного автомобиля уже назначен основной пользователь. Вы уверены, что хотите сменить текущего основного пользователя на нового?'
    },
    [STATUS_OWNER + '_' + STATUS_MAIN_USER]: {
        labelFields: 'Изменение собственника и основного пользователя',
        textBody: 'У данного автомобиля уже назначены собственник и основной пользователь. Вы уверены, что хотите сменить текущих собственника и пользователя на нового?'
    }
};

export const STATUS_LPR = 'decider';
export const STATUS_DELIVER = 'deliver';
