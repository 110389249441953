import React from 'react';
import PropTypes from 'prop-types';
import style from './PreReserveContainer.module.scss';

const PreReserveContainer = ({ children, noticeNode, id, back, classes = [] }) => {
    if (noticeNode) {
        return (
            <div className={style.preReserveContainer + classes.map(item => ' ' + style[item]).join('') + ' ' + style.wrapped}>
                <div className={style.preReserveCol}>
                    { children }
                </div>
                <div className={noticeNode ? style.notice : ''}>{ noticeNode }</div> { back }
            </div>
        );
    }

    return (
        <div className={style.preReserveContainer + classes.map(item => ' ' + style[item]).join('')} id={id}>
            {
                children
            }
            {
                back
            }
        </div>
    );
};

PreReserveContainer.propTypes = {
    classes: PropTypes.array,
    children: PropTypes.node,
    noticeNode: PropTypes.node,
    back: PropTypes.node,
    id: PropTypes.string
};

export default PreReserveContainer;
