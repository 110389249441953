import React, { Component } from 'react';
import Header from '../Header';
import MainInfo from '../MainInfo';
import Login from '../../routes/Login';
import RemindPassword from '../../routes/RemindPassword';
import AuthLayout from '../../layouts/AuthLayout';
import { withApiService } from '../../Hoc';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setEntryPoint } from '../../reducers/common';
import _ from 'lodash';

import PropTypes from 'prop-types';
import ErrorIndicator from '../ErrorIndicator';
import MainInfoSettings from '../MainInfo/MainInfoSettings';
import { PARTNER_EXTEND, PARTNER_SIMPLIFIED } from '../../constants/owners';
import Forbidden from '../Forbidden/Forbidden';
import style from './App.module.scss';

const FREE_PATHS_NAMES = ['/login', '/remind-password'];

class App extends Component {
    componentDidMount() {
        if ((!this.props.partner || !this.props.token) && !(_.includes(FREE_PATHS_NAMES, this.props.location.pathname))) {
            this.props.setEntryPoint({ url: this.props.location.pathname, login: true });
            this.props.history.push('/login');
        }

        if (this.props.partner && this.props.partner.role && (this.props.partner.role !== PARTNER_EXTEND && this.props.partner.role !== PARTNER_SIMPLIFIED)) {
            this.props.location.pathname !== '/forbidden' && this.props.history.push('/forbidden');
        }
    }

    componentDidUpdate() {
        if ((!this.props.partner || !this.props.token) && !(_.includes(FREE_PATHS_NAMES, this.props.location.pathname))) {
            this.props.history.push('/login');
        }

        if (this.props.error404 && this.props.history.location.pathname !== '/error-car-404') {
            this.props.history.push('/error-car-404');
        }

        if (this.props.partner && this.props.partner.role && (this.props.partner.role !== PARTNER_EXTEND && this.props.partner.role !== PARTNER_SIMPLIFIED)) {
            this.props.location.pathname !== '/forbidden' && this.props.history.push('/forbidden');
        }
    }

    render() {
        return (
            <div className={style.app}>
                <Switch>
                    <Route path={['/login', '/remind-password']}>
                        <AuthLayout>
                            <Switch>
                                <Route path='/login' component={Login} />
                                <Route path='/remind-password' component={RemindPassword} />
                            </Switch>
                        </AuthLayout>
                    </Route>
                    { this.props.partner && this.props.partner.role && (this.props.partner.role !== PARTNER_EXTEND && this.props.partner.role !== PARTNER_SIMPLIFIED)
                        ? <Route path='/forbidden' render={() => <Forbidden />} />
                        : null }

                    { this.props.error404
                        ? <Route path='/error-car-404' render={() => <ErrorIndicator errorNumber='404' text='Страницы не существует' />} />
                        : null }
                    { this.props.partner && this.props.partner.role && (this.props.partner.role === PARTNER_EXTEND)
                        ? <Route path='/settings' render={() => ([<Header key='header' />, <MainInfoSettings key='mainInfoSettings' />])} />
                        : null }
                    { this.props.partner && this.props.partner.role && (this.props.partner.role === PARTNER_EXTEND)
                        ? <Route path='/reports' render={() => ([<Header key='header' />, <MainInfoSettings key='mainInfoSettings' />])} />
                        : null }
                    { this.props.partner && this.props.token
                        ? <Route render={() => [<Header key='header' />, <MainInfo key='mainInfo' />]} />
                        : null }

                </Switch>
            </div>
        );
    }
}

App.propTypes = {
    location: PropTypes.object,
    partner: PropTypes.object,
    token: PropTypes.string,
    history: PropTypes.object,
    error404: PropTypes.bool,
    setEntryPoint: PropTypes.func
};

const mapStateToProps = ({ auth: { partner, token }, common: { error404 } }) => {
    return {
        partner,
        token,
        error404
    };
};

export default withApiService()(withRouter(connect(mapStateToProps, { setEntryPoint })(App)));
