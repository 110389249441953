import React, { useState, useEffect } from 'react';
import ClientsFoundTemplate from '../ClientsFoundTemplate';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchNewClient } from '../../reducers/statusesClientCar';
import FoundedOption from '../FoundedOption';
import handleSetFoundClient from '../../utils/handleSetFoundClient';
import Loader from '../Loader';
import newClientImg from '../../assets/newClient.svg';
import style from './ModalInputWrap.module.scss';

const LoaderForSearch = ({ isActive }) => {
    return (
        <div className={style.loadArea} onClick={e => e.stopPropagation()}>
            <div><Loader active={isActive} classes={['small', 'withoutBackground', 'light', 'posStart']} /></div>
            <div>Поиск клиента</div>
        </div>

    );
};

LoaderForSearch.propTypes = {
    isActive: PropTypes.bool
};

const ItIsNewClient = ({ handleClose }) => {
    return (
        <div className={style.loadArea} onClick={handleClose}>
            <div><img src={newClientImg} alt='новый пользователь' /></div>
            <div>Новый клиент</div>
        </div>

    );
};

ItIsNewClient.propTypes = {
    handleClose: PropTypes.func
};

const ModalInputWrap = ({
    isOpen,
    handleClose,
    foundClients,
    isLoadSearchClient,
    searchInfo,
    initialClient,
    newClient,
    searchNewClient,
    setIsOpenSearchClient,
    setNewClient,
    handlePushNewClient,
    children
}) => {
    const [optionsFounded, setOptionsFounded] = useState([]);

    useEffect(() => {
        setOptionsFounded(foundClients && (foundClients.length > 0)
            ? (foundClients.map(item => {
                return (
                    <FoundedOption
                        key={'FoundedOption_' + (Object.hasOwnProperty.call(item, 'id') ? item.id : '')}
                        item={item} handleSelect={(id, client) => handleSetFoundClient(id, client, newClient, setIsOpenSearchClient, searchNewClient, setNewClient, initialClient, handlePushNewClient)}
                    />
                );
            }))
            : ((searchInfo.search.length > 3) ? (isLoadSearchClient ? <LoaderForSearch isActive={isLoadSearchClient} /> : <ItIsNewClient handleClose={handleClose} />) : ''));
    }, [
        foundClients,
        isLoadSearchClient,
        searchInfo.search,
        initialClient,
        searchNewClient,
        handlePushNewClient,
        handleClose,
        setNewClient,
        setIsOpenSearchClient,
        newClient
    ]);

    return (
        <div className={style.modalSearchInput}>
            {
                children && children
            }
            <ClientsFoundTemplate
                isOpen={isOpen}
                optionsFounded={optionsFounded}
                handleClose={handleClose}
            />
        </div>
    );
};

ModalInputWrap.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    foundClients: PropTypes.array,
    isLoadSearchClient: PropTypes.bool,
    searchInfo: PropTypes.object,
    initialClient: PropTypes.object,
    newClient: PropTypes.object,
    searchNewClient: PropTypes.func,
    setIsOpenSearchClient: PropTypes.func,
    setNewClient: PropTypes.func,
    handlePushNewClient: PropTypes.func,
    children: PropTypes.node
};

const mapStateToProps = ({ statusesClientCar: { foundClients, searchInfo, isLoadSearchClient } }) => {
    return {
        foundClients,
        searchInfo,
        isLoadSearchClient
    };
};

export default connect(mapStateToProps, { searchNewClient })(ModalInputWrap);
