import React, { useEffect, useState } from 'react';
import {
    clearPreReservesReportForMetric,
    getPreReservesReportForMetric,
    setPreReservesLimitOffset
} from '../../../../../reducers/reportsProlongationInAction';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './PreReservesReport.module.scss';
import { format } from 'date-fns';
import { OPTIONS_PACKETS_PACKET_NAME } from '../../../../../constants/packets';
import LoadingIndicatorBlock from '../../../../../components/LoadingIndicator/LoadingIndicatorBlock';
import { GrayButton } from '../../../../../components/Buttons';
import {
    PROLONGATION_MODE_PROLONGATION,
    PROLONGATION_MODE_TITLES
} from '../../../../../constants/prolongationMode';
import { fetchSeasonsPeriods } from '../../../../../reducers/settingsSeasons';
import { isEmpty } from '../../../../../utils';
import qs from 'query-string';
import ru from 'date-fns/locale/ru';
import getQueryVariable from '../../../../../utils/getQueryVariable';
import { DATA_LABELS_MAP_KEYS_OLD } from '../../../../../constants/labelsTableProlongation';
import { Link, useHistory } from 'react-router-dom';
import HeaderSticky from '../../../../../components/Header/HeaderSticky';
import arrowDown from 'assets/minus_red.svg';
import arrowUp from 'assets/plus_green.svg';
import PreReserveReportTableHeader from '../PreReserveReportTableHeader';
import PreReserveReportItem from './PreReserveReportItem';
import addedParamToUrl from '../../../../../utils/addedParamToUrl';
import { OFFSET_DEFAULT_PRE_RESERVES } from '../../../../../constants/limitsDefault';

const HeaderNumberWithText = ({ numberPrefix, children, number = 0 }) => {
    return (
        <div className={style.headerText}>
            <div className={style.count}>
                <h1>{ number }</h1>
                {
                    numberPrefix !== 0 && (
                        <div className={style.row}>
                            <img alt='arrow' src={numberPrefix > 0 ? arrowUp : arrowDown} />
                            <h2 className={numberPrefix > 0 ? style.green : style.red}>
                                { Math.abs(numberPrefix) }
                            </h2>
                        </div>
                    )
                }
            </div>
            {
                children
            }
        </div>
    );
};

HeaderNumberWithText.propTypes = {
    number: PropTypes.node,
    children: PropTypes.node,
    numberPrefix: PropTypes.node
};

export const InfoInHeader = ({ title, subHeaders = [], classes = [] }) => {
    return (
        <div className={style.description}>
            <div className={style.spanHeader1}>{ title }</div>
            <div className={style.subHeader}>
                <div className={style.columnSubHeader + classes.map(item => ' ' + style[item]).join('')}>
                    {
                        subHeaders.length > 0 && subHeaders.map(subHeader => {
                            return <span key={subHeader.label}>{ subHeader.label }</span>;
                        })
                    }
                </div>
                <div className={style.columnSubHeader}>
                    {
                        subHeaders.length > 0 && subHeaders.map(subHeader => {
                            return (
                                typeof subHeader.title === 'string'
                                    ? <span key={subHeader.label}>{ subHeader.title }</span>
                                    : <div key={subHeader.label}>{ subHeader.title }</div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

InfoInHeader.propTypes = {
    title: PropTypes.string,
    subHeaders: PropTypes.array,
    classes: PropTypes.array
};

const PreReserveReportHeader = ({ count, countPreviousPeriod, title, subTitle, period, packetTitle }) => {
    const data = [
        { label: 'Уровень:', title: subTitle[0]?.toUpperCase() + subTitle?.slice(1)?.toLowerCase() },
        { label: 'Период:', title: `c ${period.from} по ${period.to}` }
    ];

    return (
        <HeaderNumberWithText
            number={count}
            numberPrefix={countPreviousPeriod && count >= 0 ? count - countPreviousPeriod : 0}
        >
            <InfoInHeader
                title={title}
                subHeaders={packetTitle ? [...data, { label: 'Услуга:', title: packetTitle }] : data}
            />
        </HeaderNumberWithText>
    );
};

PreReserveReportHeader.propTypes = {
    count: PropTypes.number,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    period: PropTypes.object,
    packetTitle: PropTypes.string,
    countPreviousPeriod: PropTypes.number
};

const PreReservesReport = ({
    getPreReservesReportForMetric,
    clearPreReservesReportForMetric,
    setPreReservesLimitOffset,
    preReservesReport,
    searchParams,
    offset,
    limit,
    preReservesReportCount,
    prolongationSuccess,
    seasonsAndPeriods,
    fetchSeasonsPeriods,
    preReservesTotalCountPreviousPeriod,
    timezone
}) => {
    const [mode, setMode] = useState(PROLONGATION_MODE_PROLONGATION);
    const [packets, setPackets] = useState([]);
    const history = useHistory();

    useEffect(() => {
        isEmpty(seasonsAndPeriods) && fetchSeasonsPeriods();
    }, [seasonsAndPeriods, fetchSeasonsPeriods]);

    useEffect(() => {
        return () => clearPreReservesReportForMetric([]);
    }, [clearPreReservesReportForMetric]);

    useEffect(() => {
        const data = qs.parse(searchParams);
        setMode((s) => data.mode || s);
        setPackets(data.packets.split(','));
        getPreReservesReportForMetric({ ...data, packets: data.packets.split(',') });
    }, [offset, getPreReservesReportForMetric, searchParams]);

    const handleSearchMore = () => {
        setPreReservesLimitOffset({ offset: (offset + limit) > preReservesReportCount ? preReservesReportCount : (offset + limit) });
    };

    const handleClearSort = () => {
        setPreReservesLimitOffset({ offset: OFFSET_DEFAULT_PRE_RESERVES });
        addedParamToUrl(
            ['sort'],
            [''],
            history,
            window.location?.pathname,
            window.location?.search
        );
    };

    const handleSort = (sort) => {
        setPreReservesLimitOffset({ offset: OFFSET_DEFAULT_PRE_RESERVES });
        addedParamToUrl(
            ['sort'],
            [sort],
            history,
            window.location?.pathname,
            window.location?.search
        );
    };

    return (
        <div className={style.report}>
            {
                prolongationSuccess
                    ? <LoadingIndicatorBlock reasonLoader={prolongationSuccess} />
                    : ''
            }
            <HeaderSticky
                header1={
                    <PreReserveReportHeader
                        count={preReservesReportCount}
                        title={PROLONGATION_MODE_TITLES[mode]}
                        subTitle={DATA_LABELS_MAP_KEYS_OLD[getQueryVariable(searchParams, 'activity_metric')]}
                        period={{
                            from: format(new Date(getQueryVariable(searchParams, 'from')), 'dd MMMM yyyy', { locale: ru, timeZone: timezone }),
                            to: format(new Date(getQueryVariable(searchParams, 'to')), 'dd MMMM yyyy', { locale: ru, timeZone: timezone })
                        }}
                        packetTitle={packets.length === 1 ? OPTIONS_PACKETS_PACKET_NAME[packets[0]] : undefined}
                        countPreviousPeriod={preReservesTotalCountPreviousPeriod}
                    />
                }
                header2={
                    <PreReserveReportTableHeader
                        mode={mode}
                        withLabel={packets.length > 1}
                        sortField={qs.parse(searchParams).sort || ''}
                        handleSort={(field) => handleSort(field)}
                        handleClearSort={handleClearSort}
                    />
                }
                classes={['withShadow']}
            />
            <div>
                {
                    preReservesReport.length > 0
                        ? preReservesReport.map((item, key) => {
                            return (
                                <Link
                                    to={`/reports/cars/${item?.car?.id}/pre-reserves/${item.id}`}
                                    key={item.id || key}
                                    className={style.link}
                                >
                                    <PreReserveReportItem
                                        key={item.id || key}
                                        prereserve={item}
                                        mode={mode}
                                        seasonsAndPeriods={seasonsAndPeriods}
                                        timezone={timezone}
                                        withLabel={packets.length > 1}
                                    />
                                </Link>
                            );
                        })
                        : <div className={style.emptyText}>Нет данных</div>
                }
            </div>
            {
                preReservesReport.length > 0 &&
                ((offset + limit) < preReservesReportCount) &&
                <div className={style.buttonMore}>
                    <GrayButton text='Посмотреть еще' handlerClick={handleSearchMore} classes={['showMore']} />
                </div>
            }
        </div>
    );
};

PreReservesReport.propTypes = {
    seasonsAndPeriods: PropTypes.object,
    getPreReservesReportForMetric: PropTypes.func,
    clearPreReservesReportForMetric: PropTypes.func,
    setPreReservesLimitOffset: PropTypes.func,
    fetchSeasonsPeriods: PropTypes.func,
    preReservesReport: PropTypes.array,
    searchParams: PropTypes.string,
    prolongationSuccess: PropTypes.bool,
    preReservesReportCount: PropTypes.number,
    preReservesTotalCountPreviousPeriod: PropTypes.number,
    offset: PropTypes.number,
    limit: PropTypes.number,
    timezone: PropTypes.string
};

const mapStateToProps = ({
    reportsProlongationInAction: { preReservesReport, offset, limit, preReservesReportCount, prolongationSuccess, preReservesTotalCountPreviousPeriod },
    settingsSeasons: { seasonsAndPeriods },
    common: { timezone }
}) => {
    return {
        preReservesReport,
        preReservesReportCount,
        prolongationSuccess,
        offset,
        limit,
        seasonsAndPeriods,
        timezone,
        preReservesTotalCountPreviousPeriod
    };
};

export default connect(mapStateToProps, {
    getPreReservesReportForMetric,
    clearPreReservesReportForMetric,
    setPreReservesLimitOffset,
    fetchSeasonsPeriods
})(PreReservesReport);
