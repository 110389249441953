import React from 'react';
import style from './FilterConfigure.module.scss';
import PropTypes from 'prop-types';
import MultiSelectReact from '@yana4961/react-multiselect';
import arrow from '../../assets/arrow-down-icon.svg';
import checked from 'assets/checked.svg';
import './Multiselect.css';

const FilterConfigure = ({ id, handleSetData, initialData, options, fields, textChoose }) => {
    return (
        <div className={style.filterConfigure}>
            <MultiSelectReact
                id={id}
                fetchAllResources={() => {}}
                resources={options}
                filterResource={handleSetData}
                selectedResource={initialData}
                arrowDown={arrow}
                textChoose={textChoose}
                numShowPreview={5}
                checkMark={checked}
                classes={['hoverBg', 'checkedBg', 'bgWithoutPadding', 'withoutShadow', 'withBorder', 'borderRadius3']}
                fields={fields}
                withCheckBox
            />
        </div>
    );
};

FilterConfigure.propTypes = {
    id: PropTypes.string,
    handleSetData: PropTypes.func,
    initialData: PropTypes.array,
    fields: PropTypes.object,
    textChoose: PropTypes.string,
    options: PropTypes.array
};

export default FilterConfigure;
