import { typeSetInsurance, typeChangeInsurance, typeCreateInsurance, typeDeleteInsurance } from '../actionsTypes';
import _ from 'lodash';
// ------------------------------------
// Constants
// ------------------------------------

const SET_INSURANCE = typeSetInsurance;
const UPDATE_INSURANCE = typeChangeInsurance;
const CREATE_INSURANCE = typeCreateInsurance;
const DELETE_INSURANCE = typeDeleteInsurance;

// ------------------------------------
// Actions
// ------------------------------------

export const fetchInsurance = () => (dispatch, getState, { apiService }) => {
    return apiService.getInsurance()
        .then((res) => {
            dispatch(setInsurance(res));
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const createOrChangeInsurance = (name, data) => (dispatch, getState, { apiService }) => {
    if (data.id) {
        return apiService.updateInsurance(data)
            .then((res) => {
                dispatch(changeInsurance(res));
                return res;
            })
            .catch((err) => {
                return err;
            });
    } else {
        return apiService.createInsurance(data)
            .then((res) => {
                dispatch(createInsurance(res));
                return res;
            })
            .catch((err) => {
                return err;
            });
    }
};

export const deleteInsurance = (id) => (dispatch, getState, { apiService }) => {
    return apiService.delInsurance(id)
        .then((res) => {
            dispatch(removeInsurance(res));
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const setInsurance = (data) => {
    return {
        type: SET_INSURANCE,
        payload: data
    };
};

export const changeInsurance = (data) => {
    return {
        type: UPDATE_INSURANCE,
        payload: data
    };
};

export const createInsurance = (data) => {
    return {
        type: CREATE_INSURANCE,
        payload: data
    };
};

export const removeInsurance = (data) => {
    return {
        type: DELETE_INSURANCE,
        payload: data
    };
};

export const actions = {
    fetchInsurance,
    createOrChangeInsurance,
    deleteInsurance
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {

    [SET_INSURANCE]: (state, action) => {
        return ({
            ...state,
            insurance: action.payload.items
        });
    },
    [UPDATE_INSURANCE]: (state, action) => {
        const insurance = _.clone(state.insurance);
        const index = _.findIndex(insurance, ['id', action.payload.id]);
        if (index !== -1) {
            insurance[index] = action.payload;
        }
        return ({
            ...state,
            insurance: insurance
        });
    },
    [CREATE_INSURANCE]: (state, action) => {
        const insurance = _.clone(state.insurance);
        insurance.push(action.payload);
        return ({
            ...state,
            insurance: insurance
        });
    },
    [DELETE_INSURANCE]: (state, action) => {
        const insurance = _.clone(state.insurance);
        const index = _.findIndex(insurance, ['id', action.payload.id]);
        if (index !== -1) {
            insurance.splice(index, 1);
        }
        return ({
            ...state,
            insurance: insurance
        });
    }
};

const autoInsuranceReducer = (state, action) => {
    if (state === undefined) {
        return {
            insurance: []
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.autoInsuranceReducer, action) : state.autoInsuranceReducer;
};

export default autoInsuranceReducer;
