import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxReasons from '../CheckBoxWithLabel/CheckBoxReasons';

import style from './CheckBoxes.module.scss';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

const CheckBoxes = ({ arrNameCheckBoxes, handleChecked, blockId, carDetailsInfo }) => {
    const [resultCheckBoxes, setResultCheckBoxes] = useState(null);
    const renderCheckBoxes = useMemo(() => (arrNameCheckBoxes ? arrNameCheckBoxes.length > 0 ? arrNameCheckBoxes.map((item) => {
        const stateCB = carDetailsInfo.car_block_reasons ? carDetailsInfo.car_block_reasons.filter((carBlockReason) => {
            return carBlockReason.block_interest_reasons_id === item.id;
        }) : false;

        const res = item.reason
            ? (
                <div key={`${item.id}&${item.reason.id}`}>
                    <CheckBoxReasons stateChecked={stateCB && (stateCB.length > 0) && !!stateCB[0].is_reason_checked} blockInterestReasonId={item.id} textLabel={item.reason.title} idReason={item.reason.id} handleChecked={handleChecked} blockId={item.car_collect_block_id} />
                </div>)
            : null;
        return res;
    }) : null : null), [arrNameCheckBoxes, carDetailsInfo, handleChecked]);

    useEffect(() => {
        setResultCheckBoxes(renderCheckBoxes);
    }, [renderCheckBoxes]);

    return (
        <div className={style.checkBoxes} key={`checkBoxes${blockId}`}>
            {
                resultCheckBoxes
            }
        </div>
    );
};

CheckBoxes.defaultProps = {
    arrNameCheckBoxes: [
        'Высокие цены',
        'Плохой сервис',
        'Привык к страховой',
        'Не говорит'
    ]
};

CheckBoxes.propTypes = {
    arrNameCheckBoxes: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    blockId: PropTypes.number,
    handleChecked: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(CheckBoxes);
