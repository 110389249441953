import React, { useMemo } from 'react';
import style from './SheetCollectMinHeader.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CollectionSheetName from '../../../components/CollectionSheetName';
import { getMainClient } from '../../../utils';

const SheetCollectMinHeader = ({ carDetailsInfo, clientIdCollect }) => {
    const mainClient = useMemo(() => {
        return getMainClient(clientIdCollect, carDetailsInfo);
    }, [clientIdCollect, carDetailsInfo]);

    return (
        <div className={style.minHeader}>
            <CollectionSheetName
                collectionSheetDetails={{
                    clientName: mainClient?.companyNick,
                    clientPhone: mainClient?.phone,
                    carMark: carDetailsInfo?.mark,
                    carModel: carDetailsInfo?.model,
                    carNumber: carDetailsInfo?.licensePlate,
                    carNumberRegion: carDetailsInfo?.region || '_',
                    carId: carDetailsInfo?.id,
                    clientId: mainClient?.id
                }}
                classes={['bgTransparent', 'textColorWhite', 'textBold']}
                minHeader
            />
        </div>
    );
};

SheetCollectMinHeader.propTypes = {
    carDetailsInfo: PropTypes.object,
    clientIdCollect: PropTypes.number
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, clientIdCollect } }) => {
    return {
        carDetailsInfo,
        clientIdCollect
    };
};

export default connect(mapStateToProps)(SheetCollectMinHeader);
