import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './PreReserveCards.module.scss';
import CardInfo from '../../../../components/Cards/CardInfo';
import { compareAsc, format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { PROLONGATION_STATUS_CANCELED } from '../../../../constants/prolongationMode';

const CardWrap = ({ children, text }) => {
    return (
        <div className={style.cardWrap}>
            <div className={style.text}>{ text }{ text && ':' }</div>
            { children }
        </div>
    );
};

CardWrap.propTypes = {
    children: PropTypes.node,
    text: PropTypes.node
};

const PreReserveCards = ({ preReserve, timezone }) => {
    const [nextSteps, setNextSteps] = useState([]);

    useEffect(() => {
        setNextSteps(preReserve?.bpmnStep?.nextSteps || []);
    }, [preReserve]);

    return (
        <div className={style.preReserveCards}>
            <CardWrap text='Якорь'>
                <CardInfo
                    key='preReserveCard_1'
                    classes={['marginEmpty', 'font16', 'minHeight']}
                >
                    <div className={style.cardInfo}>
                        {
                            preReserve?.scheduledTo
                                ? format(new Date(preReserve?.scheduledTo), 'dd.MM.yyyy', { locale: ru, timeZone: timezone })
                                : ''
                        }
                    </div>
                </CardInfo>
            </CardWrap>
            {
                preReserve?.interest && (
                    <CardWrap text='Интерес'>
                        <CardInfo
                            key='preReserveCard_2'
                            classes={['marginEmpty', 'font16', 'minHeight']}
                        >
                            <div className={style.cardInfo}>
                                { preReserve.interest }
                            </div>
                        </CardInfo>
                    </CardWrap>
                )
            }
            {
                preReserve?.bpmnStep && (
                    <CardWrap text='Этап'>
                        <CardInfo
                            key={'preReserveCard_2_step' + preReserve?.bpmnStep.id}
                            textHeader={preReserve?.bpmnStep?.title || preReserve?.bpmnStep?.name}
                            classes={['marginEmpty', 'font16', 'maxWidth', 'minLineHeight', 'nowStep', 'minHeight', preReserve?.color?.toLowerCase()]}
                        >
                            <div className={style.cardInfo + ' ' + (preReserve?.color?.toLowerCase() === 'red' ? style.yellowText : '')}>
                                {
                                    preReserve?.bpmnStep?.createdAt
                                        ? format(new Date(preReserve?.bpmnStep?.createdAt), 'dd.MM.yyyy', { locale: ru, timeZone: timezone })
                                        : ''
                                }
                            </div>
                        </CardInfo>
                    </CardWrap>
                )
            }
            {
                nextSteps?.length > 0 && preReserve?.status.toUpperCase() !== PROLONGATION_STATUS_CANCELED && nextSteps?.map((step) => {
                    if (step?.scheduledTo) {
                        if (~compareAsc(new Date(step?.scheduledTo), new Date())) {
                            return (
                                <CardWrap text='Следующий шаг' key={'preReserveCard_2_step' + step.id}>
                                    <CardInfo
                                        textHeader={step?.title || step?.name}
                                        classes={['marginEmpty', 'font16', 'maxWidth', 'minLineHeight', 'nextStep', 'minHeight']}
                                    >
                                        <div className={style.cardInfo}>
                                            {
                                                step?.scheduledTo
                                                    ? format(new Date(step?.scheduledTo), 'dd.MM.yyyy', { locale: ru, timeZone: timezone })
                                                    : ''
                                            }
                                        </div>
                                    </CardInfo>
                                </CardWrap>
                            );
                        } else {
                            return '';
                        }
                    } else {
                        return (
                            <CardWrap text='Следующий шаг' key={'preReserveCard_2_step' + step.id}>
                                <CardInfo
                                    textHeader={step?.title || step?.name}
                                    classes={['marginEmpty', 'font16', 'maxWidth', 'minLineHeight', 'nextStep', 'minHeight']}
                                />
                            </CardWrap>
                        );
                    }
                })
            }
        </div>
    );
};

PreReserveCards.propTypes = {
    preReserve: PropTypes.object,
    timezone: PropTypes.string
};

export default PreReserveCards;
