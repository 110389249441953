import { STANDARD_COMPLETELY_CONTAINED_IN_DATA, ONLY_LETTERS } from '../constants/validationRules';
function tgtrimm(str) { const ars = str.replace(/[^a-zA-ZА-Яа-яЁё ]/gi, ''); return ars; }

const validateBlock = (dataForCheck, standardData, validationRule = '') => {
    let result = false;
    let dataForCorrection = [];
    // Проверяем каких статусов не хватает
    if (validationRule === STANDARD_COMPLETELY_CONTAINED_IN_DATA) {
        if (Array.isArray(standardData) && Array.isArray(dataForCheck) && standardData.length > 0) {
            dataForCorrection = standardData.filter(item => !dataForCheck.includes(item));
            result = !dataForCorrection.length > 0;
        }
    }
    if (validationRule === ONLY_LETTERS) {
        if (typeof dataForCheck === 'string') {
            result = /^[a-zA-ZА-Яа-яЁё ]+$/.test(dataForCheck.replace(/\s/g, ''));
            dataForCorrection = tgtrimm(dataForCheck);
        }
    }
    // Возвращаем незаполненные статусы
    return {
        isValid: result,
        dataForCorrection: dataForCorrection
    };
};

export default validateBlock;
