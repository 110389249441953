import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputElement from 'react-input-mask';
import { compose, withApiService } from '../../Hoc';
import { beforeMaskedPhoneChange } from '../../utils';
import { passwordRestore, passwordConfirm, resetError, changeRemindStep } from '../../reducers/auth';
import Loader from 'components/Loader';

import classes from 'styles/templates/auth-block.module.scss';
import PropTypes from 'prop-types';

const SEND_CODE_BLOCKED_TIME = 60;
class RemindPassword extends Component {
    state = {
        phone: '',
        activationCode: '',
        timerId: null,
        sendCodeBlockedInSeconds: 0
    };

    componentDidMount() {
        this.setState({ phone: this.props.phone });
        this.props.resetError();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.remindStep === 'sendPhone' && this.props.remindStep === 'sendCode') {
            this.decreaseAllowInterval(SEND_CODE_BLOCKED_TIME);
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    clearTimer() {
        if (this.state.timerId) {
            clearTimeout(this.state.timerId);
            this.setState({ timerId: null, sendCodeBlockedInSeconds: 0 });
        }
    }

    handleChangeValue = (e) => {
        let value = e.target.value;

        if (e.target.name === 'phone') {
            value = value.trim().replace(/([^0-9+]*)/ig, '');
        }
        this.setState({ [e.target.name]: value });
        this.props.resetError();
    };

    passwordRestore = () => {
        if (!this.props.fetchingAuth) {
            this.props.passwordRestore({ phone: this.state.phone });
        }
    };

    handlePasswordConfirm = () => {
        if (!this.props.fetchingAuth) {
            this.props.passwordConfirm({
                ownerId: this.props.ownerId,
                activationCode: this.state.activationCode,
                activationKey: this.props.activationKey
            });
        }
    };

    handleGoBack = () => {
        if (this.props.remindStep === 'sendCode') {
            this.setState({ activationCode: '' });
            this.clearTimer();
            this.props.changeRemindStep('sendPhone');
        } else {
            this.props.history.goBack();
        }
    };

    decreaseAllowInterval(seconds) {
        let timerId;

        if (seconds > 0) {
            timerId = setTimeout(() => this.decreaseAllowInterval(seconds - 1), 1000);
        } else {
            timerId = null;
        }

        this.setState({ sendCodeBlockedInSeconds: seconds, timerId: timerId });
    }

    handleRepeatPasswordRestore = () => {
        if (!this.state.sendCodeBlockedInSeconds && !this.props.fetchingAuth) {
            this.passwordRestore();
            this.decreaseAllowInterval(SEND_CODE_BLOCKED_TIME);
        }
    };

    render() {
        const { error, remindStep, fetchingAuth } = this.props;
        const { phone, activationCode, sendCodeBlockedInSeconds } = this.state;

        const phoneReady = phone.length === 12;
        const activationCodeReady = activationCode;

        let content;

        switch (remindStep) {
        case 'sendCode':
            content = (
                <div className={classes.authBlock}>
                    <div className={classes.title}>
                        Восстановление пароля
                    </div>
                    <div className={classes.goBack} onClick={this.handleGoBack} />
                    <div className={classes.inputBlock}>
                        <label htmlFor='phone'>Телефон</label>
                        <InputElement
                            className={classes.disabled}
                            type='tel'
                            id='phone'
                            name='phone'
                            disabled
                            placeholder='+7 (ХХХ) ХХХ-ХХ-ХХ'
                            value={phone}
                            mask='+7 (999) 999-99-99'
                        />
                    </div>
                    <div className={classes.inputBlock}>
                        <label htmlFor='activationCode'>Код</label>
                        <input
                            className={(error ? classes.error : '')}
                            type='text'
                            id='activationCode'
                            name='activationCode'
                            placeholder='код из СМС'
                            value={activationCode}
                            autoFocus
                            onChange={this.handleChangeValue}
                            onKeyPress={e => { if (e.key === 'Enter') this.handlePasswordConfirm(); }}
                        />
                        <div
                            className={classes.dashedText}
                            onClick={this.handleRepeatPasswordRestore}
                        >
                            { sendCodeBlockedInSeconds
                                ? `выслать повторно через ${sendCodeBlockedInSeconds} секунд`
                                : 'выслать еще раз' }
                        </div>
                    </div>
                    <div
                        className={classes.confirmButton + (!activationCodeReady || fetchingAuth ? ' ' + classes.inactive : '') + (fetchingAuth ? ' ' + classes.transparentText : '')}
                        onClick={activationCodeReady ? this.handlePasswordConfirm : null}
                    >
                        Войти
                        <Loader active={fetchingAuth} classes={['small', 'withoutBackground']} />
                    </div>
                    { error
                        ? <div className={classes.authError}>Неверный код</div>
                        : null }
                </div>
            );
            break;
        case 'sendPhone':
        default:
            content = (
                <div className={classes.authBlock}>
                    <div className={classes.title}>
                        Восстановление пароля
                    </div>
                    <div className={classes.goBack} onClick={this.handleGoBack} />
                    <div className={classes.inputBlock}>
                        <label htmlFor='phone'>Телефон</label>
                        <InputElement
                            className={(error ? classes.error : '')}
                            type='tel'
                            id='phone'
                            name='phone'
                            placeholder='+7 (ХХХ) ХХХ-ХХ-ХХ'
                            value={phone}
                            onChange={this.handleChangeValue}
                            beforeMaskedValueChange={beforeMaskedPhoneChange}
                            mask='+7 (999) 999-99-99'
                            onKeyPress={e => { if (e.key === 'Enter') this.passwordRestore(); }}
                        />
                    </div>
                    <div
                        className={classes.confirmButton + (!phoneReady || fetchingAuth ? ' ' + classes.inactive : '') + (fetchingAuth ? ' ' + classes.transparentText : '')}
                        onClick={phoneReady ? this.passwordRestore : null}
                    >
                        Выслать СМС с кодом
                        <Loader active={fetchingAuth} classes={['small', 'withoutBackground']} />
                    </div>
                    { error
                        ? <div className={classes.authError}>Указанный пользователь не найден</div>
                        : null }
                </div>
            );
        }

        return content;
    }
}

RemindPassword.propTypes = {
    resetError: PropTypes.func,
    fetchingAuth: PropTypes.bool,
    phone: PropTypes.string,
    error: PropTypes.object,
    remindStep: PropTypes.string,
    ownerId: PropTypes.number,
    activationKey: PropTypes.string,
    changeRemindStep: PropTypes.func,
    passwordConfirm: PropTypes.func,
    passwordRestore: PropTypes.func,
    history: PropTypes.object
};

const mapStateToProps = ({ auth: { phone, error, ownerId, activationKey, remindStep, fetchingAuth } }) => {
    return {
        phone,
        error,
        ownerId,
        activationKey,
        remindStep,
        fetchingAuth
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        passwordRestore: passwordRestore(apiService, dispatch),
        passwordConfirm: passwordConfirm(apiService, dispatch),
        resetError: (name, value) => dispatch(resetError(name, value)),
        changeRemindStep: (name, value) => dispatch(changeRemindStep(name, value))
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(RemindPassword);
