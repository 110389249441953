import React, { useState, useEffect, useMemo } from 'react';
import { isNotEmpty } from '../../../../../../../../utils';
import WeekSecretary from '../../../../../../../../components/WeekSecretary';
import TimeSecretary from '../TimeSecretary';
import PropTypes from 'prop-types';
import style from './DayTimeBoxWrapper.module.scss';

const dayWeek = [
    { value: 'Пн', day: 'monday', isChecked: false },
    { value: 'Вт', day: 'tuesday', isChecked: false },
    { value: 'Ср', day: 'wednesday', isChecked: false },
    { value: 'Чт', day: 'thursday', isChecked: false },
    { value: 'Пт', day: 'friday', isChecked: false }
];
const dayWeekend = [{ value: 'Сб', day: 'saturday', isChecked: false }, { value: 'Вс', day: 'sunday', isChecked: false }];

const DayTimeBoxWrapper = ({ clientId, postSecretaryInfo, typeSecretary, dataSecretary, timeRangeMileageSecretary }) => {
    const secretary = useMemo(() => ({ secretaryType: typeSecretary }), [typeSecretary]);

    const initialTimeRange = useMemo(() => (timeRangeMileageSecretary), [timeRangeMileageSecretary]);

    const [timeRange, setTimeRange] = useState(initialTimeRange);
    const [weekDays, setWeekDays] = useState([]);

    useEffect(() => {
        dataSecretary && isNotEmpty(dataSecretary) && setTimeRange({ startTime: dataSecretary.startTime, endTime: dataSecretary.endTime });
    }, [clientId, dataSecretary]);

    useEffect(() => {
        return () => setTimeRange(initialTimeRange);
    }, [clientId, initialTimeRange]);

    useEffect(() => {
        return () => setWeekDays([]);
    }, [clientId]);

    const handleChangeDays = (newDays, fields) => {
        if (Array.isArray(newDays)) {
            if (fields === 'week') {
                setWeekDays(s => ([...s.filter(item => (item.day === 'saturday') || (item.day === 'sunday')), ...newDays]));
            }
            if (fields === 'weekend') {
                setWeekDays(s => ([...s.filter(item => (item.day !== 'saturday') && (item.day !== 'sunday')), ...newDays]));
            }
        } else {
            setWeekDays(s => ([...s.filter(item => item.day !== fields), newDays]));
        }
    };

    const handleSetTimeRange = (data) => {
        setTimeRange(s => ({ ...s, ...data }));
    };

    const handleCollectPostData = (clientId) => {
        const dataForPut = {
            ...secretary,
            timeRange: timeRange
        };

        if (dataSecretary && (dataSecretary.id > 0) && Number.isInteger(dataSecretary.id)) {
            dataForPut.id = dataSecretary.id;
        }

        if (weekDays.length > 0) {
            const newWeekDays = weekDays.reduce((accum, item) => {
                return { ...accum, [item.day]: item.isChecked };
            }, {});

            dataForPut.days = newWeekDays;
        }
        postSecretaryInfo(clientId, dataForPut);
    };

    return (
        <div className={style.secretaryGroupWeekTimes} onMouseLeave={() => handleCollectPostData(clientId)}>
            <WeekSecretary key={'mileageWeek_' + typeSecretary} handlePostDays={(post, fields) => handleChangeDays(post, fields)} weekState={{ week: (dataSecretary ? dataSecretary.dayWeek : dayWeek), weekend: (dataSecretary ? dataSecretary.dayWeekend : dayWeekend) }} />
            <TimeSecretary handleUpdateTimesRange={data => handleSetTimeRange(data)} objectRange={timeRange} />
        </div>
    );
};

DayTimeBoxWrapper.propTypes = {
    clientId: PropTypes.number,
    typeSecretary: PropTypes.string,
    postSecretaryInfo: PropTypes.func,
    timeRangeMileageSecretary: PropTypes.object,
    dataSecretary: PropTypes.object
};

export default DayTimeBoxWrapper;
