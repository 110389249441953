import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCarsOrders } from '../../reducers/carOrders';
import SearchResultCars from '../SearchResultCars';
import PropTypes from 'prop-types';

import style from '../ServiceBooks/ServiceBooks.module.scss';

const GetServiceBooksCars = ({ sBooksHeader, getCarsOrders, carsOrdersForToday, carsOrdersForTomorrow }) => {
    useEffect(() => {
        getCarsOrders({ for_time: 'today' });
        getCarsOrders({ for_time: 'tomorrow' });
    }, [getCarsOrders]);

    return (
        <>
            <SearchResultCars sBooksHeader={sBooksHeader[0]} cars={carsOrdersForToday} />
            <SearchResultCars sBooksHeader={sBooksHeader[1]} cars={carsOrdersForTomorrow} bgClassName={style.bg2} />
        </>
    );
};

GetServiceBooksCars.propTypes = {
    carsOrdersForToday: PropTypes.array,
    carsOrdersForTomorrow: PropTypes.array,
    sBooksHeader: PropTypes.array,
    getCarsOrders: PropTypes.func
};

const mapStateToProps = ({ templatesOfFrameState: { sBooksHeader }, carOrders: { carsOrdersForToday, carsOrdersForTomorrow } }) => {
    return {
        sBooksHeader,
        carsOrdersForToday,
        carsOrdersForTomorrow
    };
};

export default connect(mapStateToProps, { getCarsOrders })(GetServiceBooksCars);
