import React, { useState, useEffect, useMemo } from 'react';
import Statuses from './Statuses/Statuses';
import PropTypes from 'prop-types';
import { STANDARD_COMPLETELY_CONTAINED_IN_DATA } from '../../constants/validationRules';
import { getMainClient, isEmpty, validateBlock } from '../../utils';
import GuestStatuses from './GuestStatuses';
import style from './StatusesWithValidate.module.scss';
import Loader from '../Loader';

const getAllStatusesLabels = (clientsStatuses) => {
    const statuses = clientsStatuses?.length > 0 ? clientsStatuses.reduce((accum, item) => {
        return [...accum, ...item.statuses];
    }, []) : [];

    return statuses.length > 0
        ? [...new Set(statuses.map(item => item.label))]
        : [];
};

const StatusesWithValidate = ({
    carDetailsInfo,
    clientIdCollect,
    handleUpdateStatuses,
    handleAddClient,
    handleUpdateClient,
    isError,
    isLoading,
    statusesConfig,
    searchNewClient,
    foundClients,
    searchInfo,
    isLoadSearchClient
}) => {
    const [validObj, setValidObj] = useState({});

    const mainClient = useMemo(() => {
        return getMainClient(clientIdCollect, carDetailsInfo);
    }, [clientIdCollect, carDetailsInfo]);

    const otherCarClients = useMemo(() => {
        return carDetailsInfo?.clients?.filter(item => item.id !== mainClient.id) || [];
    }, [carDetailsInfo, mainClient]);

    const updateStatuses = (dataPost, isAddNew = false) => {
        if (isAddNew) {
            handleAddClient({ ...dataPost, car_id: carDetailsInfo.id });
        } else {
            handleUpdateStatuses({ ...dataPost, carId: carDetailsInfo.id });
        }
    };

    useEffect(() => {
        const carClientsStatuses = carDetailsInfo.clients;
        if (carClientsStatuses) {
            const statusesNeeded = statusesConfig.length > 0 ? statusesConfig.map(item => item.label) : [];
            const res = statusesNeeded.length > 0 ? validateBlock(getAllStatusesLabels(carClientsStatuses), statusesNeeded, STANDARD_COMPLETELY_CONTAINED_IN_DATA) : {};
            !isEmpty(res) && setValidObj(res);
        }
    }, [carDetailsInfo, statusesConfig]);

    return (
        <div className={style.wrapStatusesWithValidate}>
            {
                isLoading
                    ? <div className={style.btnLoad}><Loader active={isLoading} classes={['small', 'withoutBackground', 'grayLight']} /></div>
                    : ''
            }
            <GuestStatuses
                guest={mainClient}
                guestStatuses={mainClient?.statuses?.map(item => item.label) || []}
                statusesConfig={statusesConfig}
                handleAddClient={handleAddClient}
                handleUpdateStatuses={updateStatuses}
                searchNewClient={searchNewClient}
                carId={carDetailsInfo?.id}
                isLoadSearchClient={isLoadSearchClient}
                searchInfo={searchInfo}
                foundClients={foundClients}
            />
            <Statuses
                carClients={otherCarClients}
                handleUpdateStatuses={updateStatuses}
                handleAddClient={(data) => updateStatuses(data, true)}
                handleUpdateClient={handleUpdateClient}
                statusesConfig={statusesConfig}
                searchNewClient={searchNewClient}
                carId={carDetailsInfo?.id}
                isError={isError}
                isLoading={isLoading}
                validObj={validObj}
                isLoadSearchClient={isLoadSearchClient}
                searchInfo={searchInfo}
                foundClients={foundClients}
            />
        </div>
    );
};

StatusesWithValidate.propTypes = {
    carDetailsInfo: PropTypes.object,
    clientIdCollect: PropTypes.number,
    handleUpdateStatuses: PropTypes.func,
    handleAddClient: PropTypes.func,
    handleUpdateClient: PropTypes.func,
    statusesConfig: PropTypes.array,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    searchNewClient: PropTypes.func,
    foundClients: PropTypes.array,
    searchInfo: PropTypes.object,
    isLoadSearchClient: PropTypes.bool
};

export default StatusesWithValidate;
