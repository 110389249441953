import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    getOrdersWithVisitors,
    addOffsetForVisitors,
    addOffsetForSearchVisitors,
    fetchClients,
    clearFoundVisitors
} from 'reducers/visitors';
import TableVisitors from './components/TableVisitors';
import PropTypes from 'prop-types';
import ShowWrapSearch from '../../../components/ShowWrapSearch';

import Loader from '../../../components/Loader';
import style from 'components/ServiceBooks/ServiceBooks.module.scss';

const VisitorsBody = ({
    countVisitors,
    foundedVisitors,
    visitorsLimitOffset,
    getOrdersWithVisitors,
    addOffsetForVisitors,
    addOffsetForSearchVisitors,
    isVisitorsSuccess,
    textSearch,
    isSearchVisitorsSuccess,
    isSearchVisitorsError,
    isVisitorsError,
    fetchClients,
    clearFoundVisitors,
    clients,
    loader
}) => {
    const columnHeader = ['', 'Гость', 'Телефон', '', ''];
    const columnHeader2 = ['', 'Гость', 'Телефон', '', ''];
    const columnsSizes = [0.5, 2, 1, 1, 1];
    // const columnsSizes2 = [0.5, 3, 1, 0.5, 0.5];
    const fields = ['', ['clientInfo', 'nick'], 'clientPhone', 'cars', 'scheduledTo'];
    const fields2 = ['', ['nick'], 'clientPhone', '', ''];
    useEffect(() => {
        getOrdersWithVisitors();
    }, [getOrdersWithVisitors, visitorsLimitOffset]);
    useEffect(() => {
        fetchClients();
    }, [fetchClients, visitorsLimitOffset]);
    const tableSearchVisitors = (
        <>
            <ShowWrapSearch
                isFetching={isSearchVisitorsSuccess}
                classWrap={`${style.bg2} ${style.mgBottom}`}
                title='Найдено гостей'
                clearResultFunc={clearFoundVisitors}
                uniqItems={foundedVisitors.items}
                showMoreFunc={addOffsetForSearchVisitors}
                totalFoundedItems={foundedVisitors.count}
                classes={['carsListVisitor']}
                classesButton={['visitorButtonAdd']}
                isSearch
                btn
                guests
            >
                <TableVisitors
                    key='searchVisitors'
                    fields={fields2}
                    columnHeader={columnHeader}
                    columnsSizes={columnsSizes}
                    isSuccess={isSearchVisitorsSuccess}
                    items={foundedVisitors.items}
                    countItems={foundedVisitors.count}
                    addItems={addOffsetForSearchVisitors}
                    isActionError={isSearchVisitorsError}
                />
            </ShowWrapSearch>
        </>
    );
    return (
        <div className={style.mgBottomVisitors}>
            {
                textSearch.search
                    ? tableSearchVisitors
                    : ''
            }
            <TableVisitors
                key='visitors'
                fields={fields}
                columnHeader={columnHeader2}
                columnsSizes={columnsSizes}
                isSuccess={isVisitorsSuccess}
                items={clients.items}
                countItems={countVisitors}
                addItems={addOffsetForVisitors}
                isActionError={isVisitorsError}
                isOrder
            />
            <Loader active={loader} classes={['loaderTop', 'gray']} />
        </div>
    );
};

VisitorsBody.propTypes = {
    visitorsLimitOffset: PropTypes.object,
    getOrdersWithVisitors: PropTypes.func,
    addOffsetForVisitors: PropTypes.func,
    foundedVisitors: PropTypes.object,
    isVisitorsSuccess: PropTypes.bool,
    isVisitorsError: PropTypes.bool,
    addOffsetForSearchVisitors: PropTypes.func,
    isSearchVisitorsError: PropTypes.bool,
    isSearchVisitorsSuccess: PropTypes.bool,
    countVisitors: PropTypes.number,
    textSearch: PropTypes.object,
    clients: PropTypes.object,
    fetchClients: PropTypes.func,
    loader: PropTypes.bool,
    clearFoundVisitors: PropTypes.func
};

const mapStateToProps = ({
    visitors: {
        loader,
        allVisitors,
        visitorsLimitOffset,
        foundedVisitors,
        isVisitorsSuccess,
        textSearch,
        countVisitors,
        isSearchVisitorsSuccess,
        isSearchVisitorsError,
        isVisitorsError,
        clients
    }
}, carDetailsInfo) => {
    return {
        allVisitors,
        visitorsLimitOffset,
        foundedVisitors,
        isVisitorsSuccess,
        textSearch,
        countVisitors,
        isSearchVisitorsSuccess,
        isSearchVisitorsError,
        isVisitorsError,
        carDetailsInfo,
        clients,
        loader
    };
};

export default connect(mapStateToProps, {
    getOrdersWithVisitors,
    addOffsetForVisitors,
    addOffsetForSearchVisitors,
    fetchClients,
    clearFoundVisitors
})(VisitorsBody);
