import React from 'react';
import RadioButtonsForm from '../../../../../../../../components/RadioButtonsForm';
import PropTypes from 'prop-types';
import ConnectionRowCheckBoxes from '../ConnectionRowCheckBoxes';

import style from './Connection.module.scss';

const Connection = ({
    header,
    showOrder,
    handleChangeOrder,
    handleUpdateSecretaryInfo,
    updateState,
    typeInput = 'checkBox',
    connections = [],
    classes = [],
    id
}) => {
    function handleChangeConnection(id, isCheck, order) {
        updateState(connections.map(item => item.channel_connection_id === id ? { ...item, isCheck: isCheck, order: order } : item));
    }

    const handleChange = (id, isCheck, order) => {
        handleChangeConnection(id, isCheck, order);
    };

    const max = connections.reduce((accum, item) => {
        return accum < item.order ? item.order : accum;
    }, 1);

    const items = connections.map((connection) => {
        return (
            <ConnectionRowCheckBoxes
                id={connection.channel_connection_id}
                key={'keyConnection&' + connection.label}
                text={connection.text}
                label={connection.label}
                order={connection.order}
                isCheck={connection.isCheck}
                arrowPosition={(connection.order === 1) ? 'down' : ((connection.order === max) ? 'up' : 'middle')}
                showOrder={showOrder}
                handleChangeOrder={handleChangeOrder}
                handleChange={handleChange}
            />
        );
    });

    const handleUpdateConnections = () => {
        handleUpdateSecretaryInfo();
    };

    const radio = (
        <RadioButtonsForm items={connections} />
    );

    return (
        <div className={style.connection + classes.map(item => ' ' + style[item]).join('')} onMouseLeave={handleUpdateConnections} id={id}>
            <span className={style.connectionHeader}>{ header }</span>
            { typeInput === 'radioButton'
                ? radio
                : items }
        </div>
    );
};

Connection.propTypes = {
    updateState: PropTypes.func,
    header: PropTypes.string,
    showOrder: PropTypes.bool,
    typeInput: PropTypes.string,
    connections: PropTypes.array,
    handleChangeOrder: PropTypes.func,
    handleUpdateSecretaryInfo: PropTypes.func,
    classes: PropTypes.array,
    id: PropTypes.string
};

export default Connection;
