import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import style from './OwnerSettingsBody.module.scss';
import PropTypes from 'prop-types';
import { HeaderSettingsInBody } from '../SettingsHeader';
import SelectBox from '../../../components/SelectField';
import InputElement from 'react-input-mask';
import { loadingOwnersCompany, setOwnerId, setUpdateOwnerData, updateOwner } from '../../../reducers/settings';
import LoadingIndicatorBlock from '../../../components/LoadingIndicator/LoadingIndicatorBlock';
import MultiSelect from '../../../components/MultiSelect';
import { isEmpty } from '../../../utils';

const Owner = ({
    id,
    roles,
    setUpdateOwnerData,
    updateOwner,
    loadingOwnersCompany,
    fetchOwnersCompanySuccess,
    resourcesCompany,
    updateOwnerData,
    setOwnerId,
    ownerInfo = {}
}) => {
    const { name = '', phone = '', role2 = {}, passwordNew = '', email = '', resources = [] } = useMemo(() => (ownerInfo), [ownerInfo]);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [toggleResourceSelectLocal, setToggleResourceSelectLocal] = useState(true);

    useEffect(() => {
        id && setOwnerId(id);
    }, [id, setOwnerId]);

    useEffect(() => {
        return () => loadingOwnersCompany(false);
    }, [loadingOwnersCompany]);

    useEffect(() => {
        if (resources.length > 0) {
            setSelectedFilter(resources);
        } else {
            setSelectedFilter(s => (s.length === 0) && (resources.length === 0) ? s : resources);
        }
    }, [resources]);

    useEffect(() => {
        (selectedFilter.length > 0)
            ? setSelectedResources(resourcesCompany.filter((item) => selectedFilter.includes(item.id)))
            : setSelectedResources([]);
    }, [resourcesCompany, selectedFilter]);

    const handleUpdateOwner = () => {
        !isEmpty(updateOwnerData) && updateOwner();
    };

    const handleChange = (value, isChecked) => {
        setSelectedFilter(s => {
            return isChecked
                ? (s.filter((item) => item === value).length > 0)
                    ? [...s]
                    : [...s, value]
                : [...s.filter((item) => item !== value)];
        });
    };

    const handleDefault = () => {
        setSelectedFilter([]);
        setSelectedResources([]);
        handleOnChange('resources', []);
    };

    const handleOnChange = (field, value) => {
        setUpdateOwnerData(field, value);
    };

    const handleUpdateFilter = () => {
        const arrayValue = selectedFilter.length > 0 ? selectedFilter.reduce((accum, item) => {
            const newItem = resourcesCompany && resourcesCompany.find(resource => resource.id === item);
            if (newItem && newItem.angara_id) {
                return [...accum, { id: newItem.angara_id && newItem.angara_id }];
            } else {
                return accum;
            }
        }, []) : [];

        handleOnChange('resources', arrayValue);
    };

    const ownerFields = (
        <div className={style.rowFieldsClient}>
            <HeaderSettingsInBody text='Пользователь' />
            {
                fetchOwnersCompanySuccess
                    ? <LoadingIndicatorBlock reasonLoader={fetchOwnersCompanySuccess} />
                    : ''

            }
            <form className={style.addFormColumn} onSubmit={handleUpdateOwner}>
                <label>  ФИО:
                    <input className={style.client} type='text' placeholder='ФИО' value={name} onChange={(e) => handleOnChange('name', e.target.value)} />
                </label>
                <label>  Телефон:
                    <InputElement className={style.phone} type='tel' name='ownerPhones' value={phone} placeholder='+7 (___)___-___-__' mask='+9 (999) 999-99-99' onChange={(e) => handleOnChange('phone', e.target.value)} />
                </label>
                <label>  email:
                    <input className={style.phone} type='text' name='email' autoComplete='off' value={email} placeholder='example@example.org' onChange={(e) => handleOnChange('email', e.target.value)} />
                </label>
                <label>  Роль:
                    <SelectBox handleChangeSelect={(e) => handleOnChange('role', e)} options={roles} selectValueFather={role2.id} />
                </label>
                <label>  Сменить пароль:
                    <input className={style.client} type='password' placeholder='Пароль' value={passwordNew} autoComplete='new-password' onChange={(e) => handleOnChange('passwordNew', e.target.value)} />
                </label>
                <div className={style.multiSelect}>  Посты/Подразделения:
                    <MultiSelect
                        id='setResourcesOwner'
                        resources={resourcesCompany}
                        selectedResources={selectedResources}
                        handleChange={handleChange}
                        showAllResource={selectedFilter.length === 0}
                        handleDefault={handleDefault}
                        handleUpdateFilter={handleUpdateFilter}
                        toggleResourceSelect={toggleResourceSelectLocal}
                        setToggleResourceFilter={setToggleResourceSelectLocal}
                        classes={['marginInSettings', 'withoutShadow']}
                        withCheckBox
                    />
                </div>
            </form>
            <button className={style.blueBtn} onClick={handleUpdateOwner}>Изменить</button>
        </div>
    );

    return (
        <>
            <div className={style.svBookDetails}>
                <div className={style.svColumn}>
                    {
                        !isEmpty(ownerInfo)
                            ? ownerFields
                            : ''
                    }
                </div>
            </div>
        </>
    );
};

Owner.propTypes = {
    ownerInfo: PropTypes.object,
    setUpdateOwnerData: PropTypes.func,
    updateOwner: PropTypes.func,
    roles: PropTypes.array,
    fetchOwnersCompanySuccess: PropTypes.bool,
    loadingOwnersCompany: PropTypes.func,
    resourcesCompany: PropTypes.array,
    updateOwnerData: PropTypes.object,
    setOwnerId: PropTypes.func,
    id: PropTypes.number
};

const mapStateToProps = ({ settings: { ownerInfo, roles, fetchOwnersCompanySuccess, resourcesCompany, updateOwnerData } }) => {
    return {
        ownerInfo,
        roles,
        fetchOwnersCompanySuccess,
        resourcesCompany,
        updateOwnerData
    };
};

export default connect(mapStateToProps, { setUpdateOwnerData, updateOwner, setOwnerId, loadingOwnersCompany })(Owner);
