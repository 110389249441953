import React from 'react';
import { MONTHS } from '../../../../../../constants/Months';
import PropTypes from 'prop-types';
import style from './ColumnsMonthProlongation.module.scss';

const PairDataReport = ({ count, percent, classes = [] }) => (
    <>
        <div className={style.count + classes.map(item => ' ' + style[item]).join('')}>{ count }</div>
        <div className={style.percent + classes.map(item => ' ' + style[item]).join('')}>
            {
                percent !== null
                    ? `${percent}%`
                    : ''
            }
        </div>
    </>
);

PairDataReport.propTypes = {
    count: PropTypes.number,
    percent: PropTypes.number,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['onlyCount', 'boldText', 'greenText', 'transparentBorder']))
};

const ColumnsMonthProlongation = ({ columnMonth }) => {
    return (
        <div className={style.columnMonth}>
            <div className={style.headerColumn}>{ MONTHS[columnMonth.month - 1] }</div>
            <div>
                <PairDataReport count={columnMonth.value.allOrders.count} percent={100} classes={['onlyCount']} />
            </div>
            <div className={style.boldText}>
                <PairDataReport count={columnMonth.value.expected.count} percent={100} classes={['boldText']} />
            </div>
            <div>
                <PairDataReport count={columnMonth.value.expectedMoved.count} percent={columnMonth.value.expectedMoved.percent} />
            </div>
            <div className={style.greenText}>
                <PairDataReport count={columnMonth.value.returned.count} percent={columnMonth.value.returned.percent} classes={['greenText']} />
            </div>
            <div>
                <PairDataReport count={columnMonth.value.transferred.count} percent={columnMonth.value.transferred.percent} />
            </div>
            <div className={style.itemEnd}>
                <PairDataReport count={columnMonth.value.canceled.count} percent={columnMonth.value.canceled.percent} classes={['transparentBorder']} />
            </div>
        </div>
    );
};

ColumnsMonthProlongation.propTypes = {
    columnMonth: PropTypes.object
};

export default ColumnsMonthProlongation;
