import React from 'react';
import { GrayButton } from '../../../Buttons';
import PropTypes from 'prop-types';

const ButtonsAction = ({ packetId, handleOpenModal, handleUpdate, handleDelete }) => {
    return (
        <React.Fragment key={'action_prereserve_button_' + packetId}>
            <GrayButton
                key={'update_prereserve_button_' + packetId}
                text='Изменить'
                classes={['small', 'showMore', 'marginRight16']}
                handlerClick={handleUpdate}
            />
            <GrayButton
                key={'delete_prereserve_button_' + packetId}
                text='Удалить'
                classes={['small', 'showMore', 'redBg']}
                handlerClick={() => handleOpenModal(true, {
                    onConfirm: handleDelete,
                    nameButtons: ['Отмена', 'Удалить'],
                    labelFields: 'Удалить преварительную запись?',
                    textBody: '',
                    classesBody: ['bgDanger', 'boldFirst']
                })}
            />
        </React.Fragment>
    );
};

ButtonsAction.propTypes = {
    packetId: PropTypes.number,
    handleOpenModal: PropTypes.func,
    handleDelete: PropTypes.func,
    handleUpdate: PropTypes.func
};

export default ButtonsAction;
