import React, { useEffect, useMemo } from 'react';
import style from './FiltersForSheets.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchOwners } from '../../../../../../reducers/settings';
import { STATUSES_PREFER_ARRAY } from '../../../../../../constants/StatusesPrefer';

const SheetsFilter = ({ nameFilter, arrayItems, selectedItem, handleSetNewValue, isDisabled }) => {
    const handleChangeValue = (e) => {
        handleSetNewValue(e.target.value);
    };

    return (
        <select className={style.filterOne} id={'filterSelect&' + nameFilter} value={selectedItem} onChange={handleChangeValue} disabled={isDisabled}>
            {
                arrayItems && arrayItems.length > 0 ? arrayItems.map((item, i) => {
                    return <option className={style.select} key={i} value={item.id}>{ item.name || item.id }</option>;
                }) : ''
            }
        </select>
    );
};

SheetsFilter.propTypes = {
    nameFilter: PropTypes.string,
    arrayItems: PropTypes.array,
    selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleSetNewValue: PropTypes.func,
    isDisabled: PropTypes.bool
};

const FiltersForSheets = ({ ownersCompany, ownerId, statusesPrefer, history, fetchOwners }) => {
    useEffect(() => {
        ownersCompany && ownersCompany.length === 0 && fetchOwners();
    }, [fetchOwners, ownersCompany]);

    const urlBase = useMemo(() => ('/reports/collection-sheets-report'), []);
    const handleSetNewValue = (dataForSet, keyFilter) => {
        if (keyFilter === 'StatusPreferFilter') {
            history.push(`${urlBase}/${ownerId}/${dataForSet}`);
        }

        if (keyFilter === 'OwnerFilter') {
            history.push(`${urlBase}/${dataForSet}/${statusesPrefer}`);
        }
    };

    return (
        <div className={style.filtersSheets}>
            <SheetsFilter
                key='StatusPreferFilter'
                nameFilter='Статус'
                arrayItems={STATUSES_PREFER_ARRAY}
                selectedItem={statusesPrefer}
                handleSetNewValue={(selectedItem) => handleSetNewValue(selectedItem, 'StatusPreferFilter')}
            />
            <SheetsFilter
                key='OwnerFilter'
                nameFilter='Эксперт'
                arrayItems={ownersCompany}
                selectedItem={ownerId}
                handleSetNewValue={(selectedItem) => handleSetNewValue(selectedItem, 'OwnerFilter')}
            />
        </div>
    );
};

FiltersForSheets.propTypes = {
    history: PropTypes.object,
    fetchOwners: PropTypes.func,
    ownerId: PropTypes.number,
    statusesPrefer: PropTypes.string,
    ownersCompany: PropTypes.array
};

const mapStateToProps = ({ reportsCollectionSheets: { collectionSheetsOwner, ownerId, statusesPrefer }, settings: { ownersCompany } }) => {
    return {
        collectionSheetsOwner,
        ownerId,
        statusesPrefer,
        ownersCompany
    };
};

export default withRouter(connect(mapStateToProps, { fetchOwners })(FiltersForSheets));
