import React from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import PropTypes from 'prop-types';
import getTimeFromString from '../../../../../../../../utils/getTimeFromString';
import 'react-datepicker/dist/react-datepicker.css';
import style from './TimeSecretary.module.scss';
import CustomTimeInput from '../../../../../../../../components/TimeInput/CustomTimeInput';

const TimeSecretary = ({ handleUpdateTimesRange, objectRange = { startTime: '10:00', endTime: '18:00' } }) => {
    const handleChangeRange = (data, placeRange) => {
        handleUpdateTimesRange({ [placeRange]: data.toTimeString().slice(0, 8) });
    };

    return (
        <div className={style.timeWeekSecretary}>
            <DatePicker
                placeholderText='00:00'
                selected={objectRange.startTime ? getTimeFromString(objectRange.startTime) : getTimeFromString('10:00')}
                selectsStart
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                popperClassName={style.popperClass}
                locale={ru}
                timeCaption='Время'
                dateFormat='HH:mm'
                customInput={<CustomTimeInput textSpan='с' />}
                onChange={(data) => {
                    handleChangeRange(data, 'startTime');
                }}
            />
            <DatePicker
                placeholderText='00:00'
                selected={objectRange.endTime ? getTimeFromString(objectRange.endTime) : getTimeFromString('18:00')}
                selectsStart
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                locale={ru}
                timeCaption='Время'
                dateFormat='HH:mm'
                customInput={<CustomTimeInput textSpan='по' />}
                onChange={(data) => {
                    handleChangeRange(data, 'endTime');
                }}
            />
        </div>
    );
};

TimeSecretary.propTypes = {
    handleUpdateTimesRange: PropTypes.func,
    objectRange: PropTypes.object
};

export default TimeSecretary;
