import {
    typeSetProlongationFlag,
    typeUpdateProlongationFlag,
    typeProlongationFlagSuccess,
    typeProlongationFlagError
} from '../actionsTypes';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const SET_PROLONGATION_FLAG = typeSetProlongationFlag;
const UPDATE_PROLONGATION_FLAG = typeUpdateProlongationFlag;
const PROLONGATION_FLAG_SUCCESS = typeProlongationFlagSuccess;
const PROLONGATION_FLAG_ERROR = typeProlongationFlagError;
// ------------------------------------
// Actions
// ------------------------------------

const setProlongationFlag = (references) => {
    return {
        type: SET_PROLONGATION_FLAG,
        payload: references
    };
};

const updateProlongationFlag = (reference) => {
    return {
        type: UPDATE_PROLONGATION_FLAG,
        payload: reference
    };
};

const prolongationFlagError = (isError) => {
    return {
        type: PROLONGATION_FLAG_ERROR,
        payload: isError
    };
};

const prolongationFlagSuccess = (isSuccess) => {
    return {
        type: PROLONGATION_FLAG_SUCCESS,
        payload: isSuccess
    };
};

export const getProlongationsFlag = (carId, clientId) => (dispatch, getState, { apiService }) => {
    // for old collection sheets
    dispatch(prolongationFlagError(false));
    dispatch(prolongationFlagSuccess(true));
    return carId && clientId && apiService.getBlockProlongations(carId, clientId)
        .then((res) => {
            dispatch(setProlongationFlag(res));
            dispatch(prolongationFlagSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(prolongationFlagSuccess(false));
            dispatch(prolongationFlagError(true));
            return err;
        });
};

export const getProlongationsFlagById = (id) => (dispatch, getState, { apiService }) => {
    // for new unique collection sheets
    if (id) {
        dispatch(prolongationFlagError(false));
        dispatch(prolongationFlagSuccess(true));
    }
    return id && apiService.getProlongationsInfo(id)
        .then((res) => {
            dispatch(setProlongationFlag(res));
            dispatch(prolongationFlagSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(prolongationFlagSuccess(false));
            dispatch(prolongationFlagError(true));
            return err;
        });
};

export const updateProlongationsFlag = (data) => (dispatch, getState, { apiService }) => {
    // for old collection sheets
    if (data?.carId && data?.clientId && data?.blockId) {
        dispatch(prolongationFlagError(false));
        dispatch(prolongationFlagSuccess(true));
    }
    return data?.carId && data?.clientId && data?.blockId && apiService.updateBlockProlongations(data)
        .then((res) => {
            dispatch(updateProlongationFlag(res));
            dispatch(prolongationFlagSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(prolongationFlagSuccess(false));
            dispatch(prolongationFlagError(true));
            return err;
        });
};

export const updateProlongations = (id, data) => (dispatch, getState, { apiService }) => {
    // for new unique collection sheets
    if (id && data?.blockId) {
        dispatch(prolongationFlagError(false));
        dispatch(prolongationFlagSuccess(true));
    }
    return id && data?.blockId && apiService.updateProlongation(id, data)
        .then((res) => {
            dispatch(updateProlongationFlag(res));
            dispatch(prolongationFlagSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(prolongationFlagSuccess(false));
            dispatch(prolongationFlagError(true));
            return err;
        });
};

export const actions = {
    getProlongationsFlag,
    updateProlongationsFlag,
    getProlongationsFlagById
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_PROLONGATION_FLAG]: (state, action) => {
        return ({
            ...state,
            prolongations: action.payload
        });
    },

    [UPDATE_PROLONGATION_FLAG]: (state, action) => {
        const oldProlongation = _.cloneDeep(state.prolongations);
        const actionPayloadResult = Array.isArray(action.payload) ? (action.payload.length > 0 ? action.payload[0] : {}) : action.payload;
        if (oldProlongation.length > 0) {
            const idx = oldProlongation.findIndex(item => item?.id === actionPayloadResult.id);
            if (~idx) {
                return ({
                    ...state,
                    prolongations: [
                        ...oldProlongation.slice(0, idx),
                        actionPayloadResult,
                        ...oldProlongation.slice(idx + 1)
                    ]
                });
            } else {
                return ({
                    ...state,
                    prolongations: [...oldProlongation, actionPayloadResult]
                });
            }
        } else {
            return ({
                ...state,
                prolongations: [actionPayloadResult]
            });
        }
    },

    [PROLONGATION_FLAG_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isProlongationLoading: action.payload
        });
    },

    [PROLONGATION_FLAG_ERROR]: (state, action) => {
        return ({
            ...state,
            prolongationError: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            prolongations: [],
            prolongationError: false,
            isProlongationLoading: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.prolongation, action) : state.prolongation;
};

export default commonReducer;
