import React from 'react';
import style from './WrapFilter.module.scss';
import PropTypes from 'prop-types';

const WrapFilter = ({ filters, rangePicker, children, classes = [] }) => {
    return (
        <section className={style.wrapFilter + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.filters}>
                {
                    filters
                }
            </div>
            <div className={style.children}>
                {
                    children
                }
                {
                    rangePicker
                }
            </div>
        </section>
    );
};

WrapFilter.propTypes = {
    children: PropTypes.node,
    filters: PropTypes.node,
    classes: PropTypes.array,
    rangePicker: PropTypes.node
};

export default WrapFilter;
