import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setNewRanges } from '../../../../reducers/reportsCollectionSheets';
import DateInputRange from '../../../../components/DateInputRange';
import { endOfDateIso } from '../../../../utils';

const DateInputCollectReports = ({ timeFrom, timeTo, setNewRanges, classes = [] }) => {
    return (
        <DateInputRange
            timeFrom={timeFrom}
            timeTo={timeTo}
            setNewRanges={setNewRanges}
            classes={classes}
            maxDate={endOfDateIso(new Date())}
        />
    );
};

DateInputCollectReports.propTypes = {
    setNewRanges: PropTypes.func,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    classes: PropTypes.array
};

const mapStateToProps = ({ reportsCollectionSheets: { collectionSheets, timeFrom, timeTo } }) => {
    return {
        collectionSheets,
        timeFrom,
        timeTo
    };
};

export default connect(mapStateToProps, { setNewRanges })(DateInputCollectReports);
