import React from 'react';
import SheetInspectNodesRow from '../SheetInspectNodesRow';
import PropTypes from 'prop-types';

import style from './SheetInspectCategoryNodes.module.scss';

// создаем body для таблицы с узлами
const SheetInspectCategoryNodes = (opts) => {
    return (
        <div className={style.cardShInspBody}>
            <div className={style.bNodes}>
                <SheetInspectNodesRow
                    {...opts}
                />
            </div>
        </div>
    );
};

SheetInspectCategoryNodes.propTypes = {
    opts: PropTypes.object
};

export default SheetInspectCategoryNodes;
