import React, { useEffect, useState } from 'react';
import styleRadio from '../../styles/radioButtonsBlock/radioButtonsBlock.module.scss';
import InputRadioSelect from '../InputRadioSelect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const RadioButtonsForm = ({ carIdCollect, client = {}, fieldName = 'linkChannel', items = [], classes = [], initialInput = '', handleChange = () => {} }) => {
    const [selectedRadioInput, setSelectedRadioInput] = useState('');

    const handleUpdate = (value, clientId, fieldName) => {
        const post = {
            car_id: carIdCollect,
            clientId: clientId,
            [fieldName]: value
        };

        value && handleChange(post);
    };

    useEffect(() => {
        !selectedRadioInput && setSelectedRadioInput(initialInput);
    }, [initialInput, selectedRadioInput]);

    const handleChangeLocal = (e) => {
        setSelectedRadioInput(e.currentTarget.value);
        handleUpdate(e.currentTarget.value, client.id, fieldName);
    };

    return (
        <form className={styleRadio.radio + classes.map(item => ' ' + styleRadio[item]).join('')}>
            {
                items.map((connection) => {
                    return (
                        <InputRadioSelect
                            value={connection.label}
                            key={'radioButton&' + connection.label}
                            checked={selectedRadioInput === connection.label}
                            handlerCheck={handleChangeLocal}
                            label={connection.text}
                        />
                    );
                })
            }
        </form>
    );
};

RadioButtonsForm.propTypes = {
    carIdCollect: PropTypes.number,
    fieldName: PropTypes.string,
    initialInput: PropTypes.string,
    handleChange: PropTypes.func,
    client: PropTypes.object,
    classes: PropTypes.array,
    items: PropTypes.array
};

const mapStateToProps = ({ carDetailsState: { carIdCollect } }) => {
    return {
        carIdCollect
    };
};

export default connect(mapStateToProps)(RadioButtonsForm);
