import React, { useState } from 'react';

import style from './HeadToggle.module.scss';
import PropTypes from 'prop-types';

const HeadToggle = ({ headerText, initialHidden = false, numItems, classes, children }) => {
    const [hidden, setHidden] = useState(initialHidden);

    return (
        <>
            <h3
                className={style.h4Toggle + ' ' + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}
                onClick={() => setHidden(s => !s)}
            >

                { headerText }
                {
                    numItems
                        ? ' (' + numItems + ')'
                        : ''
                }

                <div className={hidden ? style.triangleDown1 : style.triangleUp1}> </div>
            </h3>
            { hidden
                ? ''
                : children }
        </>
    );
};

HeadToggle.propTypes = {
    headerText: PropTypes.string,
    numItems: PropTypes.number,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['small', 'gray', 'big', 'leftPosition', 'onHoverArrow', 'withoutArrow', 'withoutPadding', 'centerPosition', 'fontSize16'])),
    children: PropTypes.node,
    initialHidden: PropTypes.bool
};

export default HeadToggle;
