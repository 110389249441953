import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import CustomInput from '../DateInput/CustomDateInput';
import ru from 'date-fns/locale/ru';
import PropTypes from 'prop-types';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import style from './DateInputRange.module.scss';

const DateInputRange = ({ timeFrom, timeTo, setNewRanges, maxDate, handlePickData = () => {}, classes = [] }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        setNewRanges({
            from: startDate && (+new Date(timeFrom) !== +startDate) ? formatISO(startDate) : null,
            to: endDate && (+new Date(timeTo) !== +endDate) ? formatISO(endDate) : null
        });
    }, [startDate, endDate, timeFrom, timeTo, setNewRanges]);

    useEffect(() => {
        timeFrom && setStartDate(startOfDay(new Date(timeFrom)) < new Date('2021-05-01T00:00:00+10:00') ? new Date('2021-05-01T00:00:00+10:00') : startOfDay(new Date(timeFrom)));
        timeTo && setEndDate(endOfDay(new Date(timeTo)));
    }, [timeFrom, timeTo]);

    const handleSetStart = (data) => {
        setStartDate(data);
        handlePickData(true);
    };
    const handleSetEnd = (data) => {
        setEndDate(data);
        handlePickData(true);
    };

    const fromDatePicker = (
        <DatePicker
            key='fromDatePicker'
            selected={startDate}
            startDate={startDate}
            minDate={new Date('2021-05-01T00:00:00+10:00')}
            onChange={handleSetStart}
            maxDate={parseISO(maxDate)}
            dateFormat='dd.MM.yy'
            locale={ru}
            customInput={<CustomInput textSpan='c' classes={['rangeReport', 'left', (classes.includes('blueBorder') ? 'blueBorder' : '')]} imgBlue reports />}
        />
    );

    const toDatePicker = (
        <DatePicker
            key='toDatePicker'
            selected={endDate}
            startDate={endDate}
            onChange={handleSetEnd}
            minDate={startDate}
            maxDate={parseISO(maxDate)}
            dateFormat='dd.MM.yy'
            locale={ru}
            customInput={<CustomInput textSpan='по' classes={['rangeReport', 'right', (classes.includes('blueBorder') ? 'blueBorder' : '')]} imgBlue reports />}
        />
    );

    return (
        <div className={style.range + classes.map(item => ' ' + style[item]).join('')}>
            { fromDatePicker }
            { toDatePicker }
        </div>
    );
};

DateInputRange.propTypes = {
    setNewRanges: PropTypes.func,
    handlePickData: PropTypes.func,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    maxDate: PropTypes.string,
    classes: PropTypes.array
};

export default DateInputRange;
