import React from 'react';
import style from '../ModalCommon/ModalCommon.module.scss';
import HeadToggle from '../HeadToggle';
import PropTypes from 'prop-types';

const ModalHeader = ({ text, handleCloseModal, classesTitle, classesHeader }) => {
    return (
        <div className={style.headerModal + ' ' + (classesHeader ? classesHeader.map(item => ' ' + style[item]).join('') : '')}>
            <HeadToggle id='HeaderReasonsModal' headerText={text} classes={classesTitle} initialHidden />
            <button className={style.btnClose} onClick={handleCloseModal}>X</button>
        </div>
    );
};

ModalHeader.propTypes = {
    text: PropTypes.string,
    handleCloseModal: PropTypes.func,
    classesTitle: PropTypes.array,
    classesHeader: PropTypes.array
};

export default ModalHeader;
