import React, { useState, useEffect, useMemo } from 'react';
import CheckBoxes from '../CheckBoxes';
import PropTypes from 'prop-types';

import style from './SetWhyThisInterest.module.scss';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';
import { fetchPostCarReasons } from '../../actions';
import TextAreaForm from '../TextAreaForm';

const TextReason = ({ valueField, handleUpdateBtnInterest, buttonId }) => {
    const [text, setText] = useState('');
    const [btnId, setBtnId] = useState(0);

    useEffect(() => {
        setText(valueField || '');
    }, [valueField]);

    useEffect(() => {
        setBtnId(buttonId);
    }, [buttonId]);

    useEffect(() => {
        const timer = setTimeout(() => {
            text && (valueField !== text) && (btnId > 0) && handleUpdateBtnInterest(btnId, null, text);
        }, 2000);
        return () => clearTimeout(timer);
    }, [valueField, btnId, text, handleUpdateBtnInterest]);

    const handleOnChange = (e) => {
        const newValue = e.currentTarget.value;
        if (newValue.length < 255) {
            setText(newValue);
        }
    };

    return (
        <TextAreaForm handleOnChange={handleOnChange} handleUpdate={() => {}} text={text} />
    );
};

TextReason.propTypes = {
    valueField: PropTypes.string,
    handleUpdateBtnInterest: PropTypes.func,
    buttonId: PropTypes.number
};

const SetWhyThisInterest = ({
    carIdCollect,
    clientIdCollect,
    buttonPassed,
    interestsReasons,
    handleUpdateBtnInterest,
    blockId,
    textValue,
    fetchPostCarReasons,
    carReasonsUpdateError
}) => {
    const [isRender, setIsRender] = useState(false);

    const isReasonNull = useMemo(() => (interestsReasons
        ? (interestsReasons.length > 0
            ? interestsReasons.filter((item) => item.reason)
            : [])
        : []), [interestsReasons]);

    useEffect(() => {
        setIsRender(isReasonNull.length > 0);
    }, [isReasonNull]);

    if (carReasonsUpdateError) {
        return <div>Something is wrong</div>;
    }

    const handleChecked = (id, isChecked) => {
        const blockInterestReasonsId = id.slice(6);

        const reasonChange = {
            car_id: carIdCollect,
            block_interest_reasons_id: +blockInterestReasonsId,
            is_reason_checked: +isChecked,
            client_id: clientIdCollect
        };

        carIdCollect && id && fetchPostCarReasons(reasonChange);
    };

    if (!isRender) {
        return null;
    }

    return (
        <div className={style.why}>
            <div className={style.labelChoice}>
                Причины отказа
            </div>
            <CheckBoxes arrNameCheckBoxes={interestsReasons} handleChecked={handleChecked} blockId={blockId} />
            <TextReason valueField={textValue} handleUpdateBtnInterest={handleUpdateBtnInterest} buttonId={buttonPassed} />
        </div>
    );
};

SetWhyThisInterest.propTypes = {
    buttonPassed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    interestsReasons: PropTypes.array,
    handleUpdateBtnInterest: PropTypes.func,
    blockId: PropTypes.number,
    textValue: PropTypes.string,
    carIdCollect: PropTypes.number,
    fetchPostCarReasons: PropTypes.func,
    carReasonsUpdateError: PropTypes.bool,
    clientIdCollect: PropTypes.number
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carReasonsUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carReasonsUpdateError,
        clientIdCollect
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        fetchPostCarReasons: fetchPostCarReasons(apiService, dispatch)
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SetWhyThisInterest);
