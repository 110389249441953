import React, { useEffect } from 'react';
import SelectPostFilter from '../SelectPostFilter';
import CompletedCards from './CompletedCards';
import CarsCollectList from './CarsCollectList';
import HeadToggle from '../HeadToggle';
import PropTypes from 'prop-types';
import { showBtnCarsNotCollectBlocks, showBtnCarsCollectBlocksOverdue } from '../../reducers/sheetCollectInfo';
import { connect } from 'react-redux';
import Loader from '../Loader';

import style from './ListInspectCollect.module.scss';

const ListCollectArea = ({
    header,
    carsFullyBlockCount,
    carsNotFullyBlocks,
    carsNotFullyBlocksCount,
    carsNotFullyOverdueCount,
    carsNotFullyOverdue,
    carsListCollectFilterLoading,
    limitNotCollectCars,
    limitNotCollectCarsOverdue,
    showBtnCarsCollectBlocksOverdue,
    showBtnNotCollectBlocks,
    showBtnOverdue,
    showBtnCarsNotCollectBlocks,
    isShowLists
}) => {
    const layout = 'CardRight';

    useEffect(() => {
        return () => { showBtnCarsCollectBlocksOverdue(); };
    }, [showBtnCarsCollectBlocksOverdue]);

    useEffect(() => {
        return () => { showBtnCarsNotCollectBlocks(); };
    }, [showBtnCarsNotCollectBlocks]);

    if (!isShowLists) {
        return null;
    }

    return (
        <div className={style.blListInspect}>
            <div className={style.listCollectInspectHeader}>
                <h1>{ header }</h1>
            </div>

            <SelectPostFilter key='collectPostFilter' id='collectPostFilter' listCollect withCheckBox />
            {
                carsListCollectFilterLoading
                    ? (
                        <div className={style.btnLoad}>
                            <Loader
                                active={carsListCollectFilterLoading}
                                classes={['small', 'loaderTop', 'gray']}
                            />
                        </div>
                    )
                    : ''
            }
            <HeadToggle
                headerText='Незаполненные'
                numItems={carsNotFullyBlocksCount}
            >
                {
                    carsNotFullyBlocksCount === 0
                        ? <div className={style.emptyData}>Нет данных</div>
                        : (
                            <CarsCollectList
                                layout={layout} cars={carsNotFullyBlocks} whatAddParam='notCollect'
                                limit={limitNotCollectCars} showBtn={showBtnNotCollectBlocks}
                            />
                        )
                }
            </HeadToggle>

            <HeadToggle
                headerText='Просроченные'
                numItems={carsNotFullyOverdueCount}
            >
                {
                    carsNotFullyOverdueCount === 0
                        ? <div className={style.emptyData}>Нет данных</div>
                        : (
                            <CarsCollectList
                                layout={layout} cars={carsNotFullyOverdue} whatAddParam='notCollectOverdue'
                                limit={limitNotCollectCarsOverdue} showBtn={showBtnOverdue}
                            />)
                }
            </HeadToggle>

            <CompletedCards layout={layout} completed={carsFullyBlockCount} />
        </div>
    );
};

ListCollectArea.defaultProps = {
    header: 'Листы осмотра'
};

ListCollectArea.propTypes = {
    header: PropTypes.string,
    carsFullyBlockCount: PropTypes.number,
    carsNotFullyBlocks: PropTypes.array,
    carsNotFullyBlocksCount: PropTypes.number,
    carsNotFullyOverdueCount: PropTypes.number,
    carsNotFullyOverdue: PropTypes.array,
    limitNotCollectCars: PropTypes.number,
    limitNotCollectCarsOverdue: PropTypes.number,
    showBtnOverdue: PropTypes.bool,
    showBtnCarsCollectBlocksOverdue: PropTypes.func,
    showBtnNotCollectBlocks: PropTypes.bool,
    showBtnCarsNotCollectBlocks: PropTypes.func,
    carsListCollectFilterLoading: PropTypes.bool,
    isShowLists: PropTypes.bool
};

const mapStateToProps = ({
    carsListState: {
        carsListCollectFilterLoading
    },
    sheetCollectInfo: {
        carsNotFullyBlocks,
        carsNotFullyBlocksCount,
        showBtnNotCollectBlocks,
        carsNotFullyOverdue,
        carsNotFullyOverdueCount,
        showBtnOverdue,
        carsFullyBlockCount,
        limitNotCollectCars,
        limitNotCollectCarsOverdue
    }
}) => {
    return {
        carsFullyBlockCount,
        carsNotFullyBlocks,
        carsNotFullyOverdueCount,
        carsNotFullyOverdue,
        carsNotFullyBlocksCount,
        carsListCollectFilterLoading,
        limitNotCollectCars,
        limitNotCollectCarsOverdue,
        showBtnNotCollectBlocks,
        showBtnOverdue
    };
};

export default connect(mapStateToProps, {
    showBtnCarsCollectBlocksOverdue,
    showBtnCarsNotCollectBlocks
})(ListCollectArea);
