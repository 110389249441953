import React from 'react';
import style from './BigHeadCollectBlock.module.scss';
import CheckToggle from '../CheckToggle';
import ArrowHiddenContent from '../ArrowHiddenContent';
import PropTypes from 'prop-types';
import AnchorsMarkStates from '../AnchorsMarkStates/AnchorsMarkStates';

const BigHeadCollectBlock = ({
    carDetailsInfo,
    passedHeader,
    isHiddenContent,
    handleSetHidden,
    handleSetCollectInfo,
    textHeader,
    withoutCheckToggle,
    blockId,
    classes = []
}) => {
    return (
        <div className={style.head + ' ' + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.leftHead}>
                <AnchorsMarkStates blockId={blockId} occupancyRateBlocks={carDetailsInfo.occupancy_rate_blocks} textHeader={textHeader} withoutCheckToggle />
                { passedHeader || '' }
                {
                    withoutCheckToggle
                        ? ''
                        : <CheckToggle handleUpdateBlockInterest={handleSetCollectInfo} blockId={blockId} />
                }
            </div>
            <ArrowHiddenContent handleSetHidden={handleSetHidden} stateBool={isHiddenContent} blockId={blockId} />
        </div>
    );
};

BigHeadCollectBlock.propTypes = {
    carDetailsInfo: PropTypes.object,
    isHiddenContent: PropTypes.bool,
    handleSetHidden: PropTypes.func,
    handleSetCollectInfo: PropTypes.func,
    textHeader: PropTypes.string,
    withoutCheckToggle: PropTypes.bool,
    classes: PropTypes.array,
    blockId: PropTypes.number,
    passedHeader: PropTypes.node
};

export default BigHeadCollectBlock;
