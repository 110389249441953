import React, { useEffect } from 'react';
import style from './VisitorGarage.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchClientCars } from '../../../../../reducers/visitors';
import _ from 'lodash';
import { CardCar } from '../../../../../components/Cards';
import Loader from '../../../../../components/Loader';

const VisitorGarage = ({ visitor, fetchClientCars, clientCars, loader }) => {
    useEffect(() => {
        fetchClientCars(visitor.id);
    }, [fetchClientCars, visitor.id]);
    return (
        <>
            <div className={style.mainContainer}>
                { _.map(clientCars.items, item => {
                    return (
                        <CardCar oneCar={item} key={item.car_id || item.id} />
                    );
                }) }
            </div>
            <Loader active={loader} classes={['loaderTop', 'gray']} />
        </>
    );
};

VisitorGarage.propTypes = {
    visitor: PropTypes.object,
    fetchClientCars: PropTypes.func,
    clientCars: PropTypes.any,
    loader: PropTypes.bool
};

const mapStateToProps = ({ visitors: { visitor, clientCars, loader } }) => {
    return {
        visitor,
        clientCars,
        loader
    };
};

export default connect(mapStateToProps, { fetchClientCars })(VisitorGarage);
