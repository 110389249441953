import React, { useState, useEffect } from 'react';
import arrowDown from '../../../../../../../../assets/arrowDownSmall.svg';
import arrowUp from '../../../../../../../../assets/arrowUpSmall.svg';
import CheckBoxCommon from '../../../../../../../../components/CheckBoxes/CheckBoxesCommon';
import PropTypes from 'prop-types';

import style from './ConnectionRowCheckBoxes.module.scss';

const ConnectionRowCheckBoxes = ({ id, order, text, label, showOrder, isCheck, handleChangeOrder, handleChange, arrowPosition = '' }) => {
    const [orderCheckBox, setOrderCheckBox] = useState(0);

    const arrDown = (
        <>
            <div className={style.order}>{ order }</div>
            <div className={style.arrows}>
                <img className={style.arrow} src={arrowDown} onClick={() => handleChangeOrder('arrowDown', order)} alt='orderCount' />
            </div>
        </>
    );
    const arrUp = (
        <>
            <div className={style.order}>{ order }</div>
            <div className={style.arrows}>
                <img className={style.arrow} src={arrowUp} onClick={() => handleChangeOrder('arrowUp', order)} alt='orderCount' />
            </div>
        </>
    );
    const arrMiddle = (
        <>
            <div className={style.order}>{ order }</div>
            <div className={style.arrows}>
                <img className={style.arrow} src={arrowUp} onClick={() => handleChangeOrder('arrowUp', order)} alt='orderCount' />
                <img className={style.arrow} src={arrowDown} onClick={() => handleChangeOrder('arrowDown', order)} alt='orderCount' />
            </div>
        </>
    );

    useEffect(() => {
        setOrderCheckBox(order);
    }, [order]);

    return (
        <div className={style.connectionRowCheckBoxes} style={{ order: orderCheckBox }} key={'keyConnection&' + label}>
            {
                showOrder && orderCheckBox
                    ? (arrowPosition === 'down')
                        ? arrDown
                        : ((arrowPosition === 'up')
                            ? arrUp
                            : arrMiddle
                        )
                    : ''
            }
            <CheckBoxCommon textLabel={text} stateChecked={isCheck} handleChecked={() => handleChange(id, !isCheck, order)} classes={['paddingConnection', 'blueLabel']} withCheckBox />
        </div>
    );
};

ConnectionRowCheckBoxes.propTypes = {
    order: PropTypes.number,
    showOrder: PropTypes.bool,
    text: PropTypes.string,
    id: PropTypes.number,
    label: PropTypes.string,
    isCheck: PropTypes.bool,
    arrowPosition: PropTypes.string,
    handleChangeOrder: PropTypes.func,
    handleChange: PropTypes.func
};

export default ConnectionRowCheckBoxes;
