import React, { useState, useEffect } from 'react';
import style from './VisitorCommon.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputElement from 'react-input-mask';
import _ from 'lodash';
import { setDataChangeVisitor, clearNewDataVisitor } from '../../../../../reducers/visitors';
import Loader from '../../../../../components/Loader';

const VisitorCommon = ({ setDataChangeVisitor, visitor, loader, clearNewDataVisitor }) => {
    const handleUpdateValueVisitor = (value, field, setDataChangeVisitor) => {
        if (value !== (visitor[field] ? visitor[field] : '')) {
            setDataChangeVisitor({ [field]: value }, field, visitor.id);
        }
    };
    const handleUpdateValueVisitorFields = (value, field, setDataChangeVisitor) => {
        const newField = visitor.fields[_.findIndex(visitor.fields, ['label', field])];
        if (value !== (newField ? newField.value : '')) {
            setDataChangeVisitor({ [field]: value }, field, visitor.id);
        }
    };

    const [newVisitor, setVisitor] = useState({
        nick: '',
        message_name: '',
        phone: '',
        comment: '',
        email: '',
        birthday: '',
        sex: ''
    });
    useEffect(() => {
        if (visitor.nick) {
            setVisitor(
                {
                    nick: visitor.nick,
                    message_name: visitor.message_name,
                    phone: visitor.phone,
                    comment: visitor.comment,
                    email: _.findIndex(visitor.fields, ['label', 'email']) !== -1 ? visitor.fields[_.findIndex(visitor.fields, ['label', 'email'])].value : '',
                    birthday: _.findIndex(visitor.fields, ['label', 'birthday']) !== -1 ? visitor.fields[_.findIndex(visitor.fields, ['label', 'birthday'])].value : '',
                    sex: _.findIndex(visitor.fields, ['label', 'sex']) !== -1 ? visitor.fields[_.findIndex(visitor.fields, ['label', 'sex'])].value : ''
                });
        }
    }, [visitor]);
    useEffect(() => {
        return () => {
            // Код отписки
            clearNewDataVisitor();
        };
    }, [clearNewDataVisitor]);

    const handleChangeInput = (e, field) => {
        const newState = _.clone(newVisitor);
        newState[field] = e.target.value;
        setVisitor(newState);
    };
    // 2445 - id референса, в котором лежит мужской пол. Не должен меняться.
    return (
        <>
            <div className={style.fieldContainer}>
                <span>Имя</span>
                <div>
                    <input
                        id='VisitorCommon_id'
                        className={style.fieldLabel}
                        value={newVisitor.nick}
                        onChange={(e) => handleChangeInput(e, 'nick')}
                        onBlur={(e) => handleUpdateValueVisitor(e.target.value, 'nick', setDataChangeVisitor)}
                        type='text'
                    />
                </div>
            </div>
            <div className={style.fieldContainer}>
                <span>Как обращаться</span>
                <div>
                    <input
                        id='VisitorCommon_nickname'
                        className={style.fieldLabel}
                        value={newVisitor.message_name === null ? '' : newVisitor.message_name}
                        onChange={(e) => handleChangeInput(e, 'message_name')}
                        onBlur={(e) => handleUpdateValueVisitor(e.target.value, 'message_name', setDataChangeVisitor)}
                        type='text'
                    />
                </div>
            </div>
            <div className={`${style.fieldContainer} ${style.margin}`}>
                <span>Телефон</span>
                <div>
                    <InputElement
                        id='VisitorCommon_phone'
                        className={`${style.fieldLabel} ${style.phone}`}
                        value={newVisitor.phone || ''}
                        placeholder='+7 (___)___-__-__'
                        mask='+7 (999) 999-99-99'
                        readOnly
                        // onChange={(e) => setVisitor({ phone: e.target.value })}
                        // onBlur={(e) => handleUpdateValueVisitor(e.target.value, fieldName, setDataChangeVisitor)}
                        type='text'
                        disabled
                    />
                </div>
                <div className={style.phoneLabel}>
                    телефон нельзя изменить
                </div>
            </div>
            <div className={style.fieldContainer}>
                <span>Эл. почта</span>
                <div>
                    <input
                        id='VisitorCommon_email'
                        className={style.fieldLabel}
                        value={_.get(newVisitor, 'email', '')}
                        onChange={(e) => handleChangeInput(e, 'email')}
                        onBlur={(e) => handleUpdateValueVisitorFields(e.target.value, 'email', setDataChangeVisitor)}
                        type='email'
                        placeholder='email@mail.ru'
                    />
                </div>
            </div>
            <div className={`${style.fieldContainer} ${style.margin}`}>
                <span>Дата рождения</span>
                <div>
                    <input
                        id='VisitorCommon_birthday'
                        className={`${style.fieldLabel} ${style.date}`}
                        value={newVisitor.birthday || ''}
                        onChange={(e) => handleChangeInput(e, 'birthday')}
                        onBlur={(e) => handleUpdateValueVisitorFields(e.target.value, 'birthday', setDataChangeVisitor)}
                        type='date'
                    />
                </div>
            </div>
            <div className={style.fieldContainer}>
                <span>Пол</span>
                <div className={style.buttonPanel}>
                    <div
                        onClick={() => handleUpdateValueVisitorFields(2445, 'sex', setDataChangeVisitor)}
                        className={`${style.buttonFirst} ${newVisitor.sex === '2445' ? style.active : ''} `}
                    >Мужчина
                    </div>
                    <div className={style.borderBox} />
                    <div
                        onClick={() => handleUpdateValueVisitorFields(2446, 'sex', setDataChangeVisitor)}
                        className={`${style.buttonLast} ${newVisitor.sex === '2446' ? style.active : ''}`}
                    >Женщина
                    </div>
                </div>
            </div>
            <div className={`${style.fieldContainer} ${style.margin}`}>
                <span>Комментарий</span>
                <div>
                    <textarea
                        id='VisitorCommon_comment'
                        className={`${style.fieldLabel} ${style.comment}`}
                        value={newVisitor.comment}
                        onChange={(e) => handleChangeInput(e, 'comment')}
                        onBlur={(e) => handleUpdateValueVisitor(e.target.value, 'comment', setDataChangeVisitor)}
                        placeholder='заметки о госте'
                    />
                </div>
            </div>
            <Loader active={loader} classes={['loaderTop', 'gray']} />
        </>
    );
};

VisitorCommon.propTypes = {
    visitor: PropTypes.object,
    setDataChangeVisitor: PropTypes.func,
    clearNewDataVisitor: PropTypes.func,
    loader: PropTypes.bool
};

const mapStateToProps = ({ visitors: { visitor, dataChangeVisitor, clearNewDataVisitor, loader } }) => {
    return {
        visitor,
        dataChangeVisitor,
        clearNewDataVisitor,
        loader
    };
};

export default connect(mapStateToProps, { setDataChangeVisitor, clearNewDataVisitor })(VisitorCommon);
