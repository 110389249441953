import style from './StatusForm.module.scss';
import BlueSpanLink from '../BlueSpanLink';
import { isEmpty } from '../../utils';
import ClientNamePhoneRow from '../ClientNamePhoneRow';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckBoxCommon from '../CheckBoxes/CheckBoxesCommon';
import { ButtonClear } from '../Buttons';
import { STATUS_MAIN_USER, STATUS_OWNER, MODAL_DATA_STATUSES } from '../../constants/statusesCarClient';
import ModalCommon from '../ModalCommon';

const StatusForm = ({
    status,
    title,
    handleChangeClient,
    handleDeleteStatuses,
    itNew,
    withDeleteBtn,
    searchNewClient,
    carId,
    foundClients,
    searchInfo,
    isLoadSearchClient,
    disabled,
    classes,
    isUnknown,
    mainExist,
    ownerExist,
    textSpan = 'Скопировать на...',
    copyToStatuses = [],
    client = {}
}) => {
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
    const [selected, setSelected] = useState([]);
    const [isConfirm, setIsConfirm] = useState(true);
    const [modalData, setModalData] = useState({});

    useEffect(() => {
        const modalData = {};
        if (selected.filter(status => status === STATUS_MAIN_USER || status === STATUS_OWNER).length === 2) {
            modalData.labelFields = MODAL_DATA_STATUSES[STATUS_OWNER + '_' + STATUS_MAIN_USER].labelFields;
            modalData.textBody = MODAL_DATA_STATUSES[STATUS_OWNER + '_' + STATUS_MAIN_USER].textBody;
        } else if (selected.find(status => status === STATUS_MAIN_USER)) {
            modalData.labelFields = MODAL_DATA_STATUSES[STATUS_MAIN_USER].labelFields;
            modalData.textBody = MODAL_DATA_STATUSES[STATUS_MAIN_USER].textBody;
        } else if (selected.find(status => status === STATUS_OWNER)) {
            modalData.labelFields = MODAL_DATA_STATUSES[STATUS_OWNER].labelFields;
            modalData.textBody = MODAL_DATA_STATUSES[STATUS_OWNER].textBody;
        }

        setModalData(modalData);
    }, [selected]);

    const handleClose = () => {
        isOpenPopupConfirm && setIsOpenPopupConfirm(false);
        setIsOpenPopup(false);
        setSelected([]);
    };

    const handleConfirmPush = () => {
        if (isConfirm) {
            handleClosePush();
        } else {
            setIsOpenPopupConfirm(true);
        }
    };

    const handleClosePush = () => {
        setIsOpenPopupConfirm(false);
        setIsOpenPopup(false);
        if (!itNew) {
            selected.length > 0 && handleChangeClient({ statuses: selected });
            setSelected([]);
        }
    };

    const handleSetSelected = (statusLabel) => {
        setSelected(s => {
            if (s.includes(statusLabel)) {
                return [...s.filter(item => item !== statusLabel)];
            } else {
                if (((STATUS_OWNER === statusLabel) && ownerExist) || ((STATUS_MAIN_USER === statusLabel) && mainExist)) {
                    setIsConfirm(false);
                }
                return [...s, statusLabel];
            }
        });
    };

    const popupModalConfirm = (
        <ModalCommon
            id='ConfirmMultiSelectStatuses&ModalCommon'
            handelClose={handleClose}
            handleConfirm={handleClosePush}
            nameButtons={['Отмена', 'Изменить']}
            classesBody={['bgDanger', 'boldFirst']}
            classesModal={['middleModal']}
            labelFields={modalData.labelFields}
            textBody={modalData.textBody}
        />
    );

    const popup = (
        <>
            <div className={style.areaPopup} onClick={handleClose} />
            <div className={style.popupStatusCopyTo + (selected && selected.length > 0 ? ' ' + style.addStatus : '')} onClick={e => e.stopPropagation()}>
                {
                    copyToStatuses.map(status => {
                        return (
                            <div key={`copyStatus_${status.label}`} className={style.status}>
                                <CheckBoxCommon withCheckBox textLabel={status.title} stateChecked={false} classes={['forSecretary']} handleChecked={() => handleSetSelected(status.label)} />
                            </div>
                        );
                    })
                }
                {
                    selected && selected.length > 0
                        ? <ButtonClear textBtn='Сохранить' classes={['blue', 'marginTop12']} handleClick={handleConfirmPush} />
                        : ''
                }
            </div>
        </>
    );

    return (
        <div className={style.statusForm} key={'new_client_' + status.label}>
            <div className={style.statusFormHeader}>
                <div className={style.text + (isUnknown ? ' ' + style.orange : '')}>{ title }</div>
                <div>
                    {
                        itNew
                            ? ''
                            : copyToStatuses.length > 0
                                ? <BlueSpanLink handleClick={() => setIsOpenPopup(s => !s)} textSpan={textSpan} classes={['navLinks']} />
                                : ''
                    }
                    {
                        isOpenPopup
                            ? popup
                            : ''
                    }
                    {
                        isOpenPopupConfirm
                            ? popupModalConfirm
                            : ''
                    }
                </div>
            </div>
            <ClientNamePhoneRow
                name={client.nick || ''}
                phone={client.phone || ''}
                handleChangeClient={handleChangeClient}
                handleDeleteClient={handleDeleteStatuses}
                carId={carId}
                id={(client.id ? (client.id) : '') + '_' + status.label}
                itNew={isEmpty(client)}
                withDeleteBtn={withDeleteBtn}
                searchNewClient={searchNewClient}
                isLoadSearchClient={isLoadSearchClient}
                searchInfo={searchInfo}
                foundClients={foundClients}
                classes={classes}
                disabled={disabled}
            />
        </div>
    );
};

StatusForm.propTypes = {
    status: PropTypes.object,
    title: PropTypes.string,
    textSpan: PropTypes.string,
    itNew: PropTypes.bool,
    withDeleteBtn: PropTypes.bool,
    client: PropTypes.object,
    handleChangeClient: PropTypes.func,
    handleDeleteStatuses: PropTypes.func,
    searchNewClient: PropTypes.func,
    carId: PropTypes.number,
    foundClients: PropTypes.array,
    copyToStatuses: PropTypes.array,
    classes: PropTypes.array,
    searchInfo: PropTypes.object,
    disabled: PropTypes.bool,
    isUnknown: PropTypes.bool,
    mainExist: PropTypes.bool,
    ownerExist: PropTypes.bool,
    isLoadSearchClient: PropTypes.bool
};

export default StatusForm;
