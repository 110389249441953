import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postSecretaryInfo } from '../../../../../../reducers/secretary';
import DayTimeBoxWrapper from './components/DayTimeBoxWrapper';
import style from '../DayTimeBox/DayTimeBox.module.scss';
import { SECRETARY_MILEAGE } from '../../../../../../constants/secretaryTypes';
import { GrayButton } from '../../../../../../components/Buttons';

const DayTimesBox = ({ clientId, postSecretaryInfo, timeRangeMileageSecretary, typeSecretary, carIdCollect, secretariesMileage = [], secretariesCommon = [], classes = [] }) => {
    const [secretaries, setSecretaries] = useState([]);
    const [addButtonActive, setAddButtonActive] = useState(true);

    useEffect(() => {
        setSecretaries((typeSecretary === SECRETARY_MILEAGE) ? secretariesMileage : secretariesCommon);
    }, [typeSecretary, secretariesMileage, secretariesCommon]);

    const handleAddForm = () => {
        secretaries.length > 0 && setAddButtonActive(false);
    };

    const postSecretaryLocal = (clientId, dataForPut) => {
        postSecretaryInfo(clientId, { ...dataForPut, carId: carIdCollect });
        setAddButtonActive(true);
    };

    const secretariesTimes = secretaries.length > 0
        ? secretaries.map(secretary => {
            return (
                <DayTimeBoxWrapper
                    clientId={clientId}
                    key={secretary.id}
                    postSecretaryInfo={(clientId, dataForPut) => postSecretaryInfo(clientId, { ...dataForPut, carId: carIdCollect })}
                    typeSecretary={typeSecretary}
                    dataSecretary={secretary}
                />
            );
        })
        : (
            <DayTimeBoxWrapper
                clientId={clientId}
                key={'initialDayTimeBoxWrapper_' + typeSecretary}
                postSecretaryInfo={(clientId, dataForPut) => postSecretaryInfo(clientId, { ...dataForPut, carId: carIdCollect })}
                typeSecretary={typeSecretary}
                timeRangeMileageSecretary={timeRangeMileageSecretary}
            />
        );

    return (
        <div className={style.dayTimes + classes.map(item => ' ' + style[item]).join('')}>
            {
                secretariesTimes
            }
            {
                (typeSecretary !== SECRETARY_MILEAGE)
                    ? (addButtonActive
                        ? <GrayButton handlerClick={handleAddForm} text='+ добавить время' />
                        : (
                            <DayTimeBoxWrapper
                                clientId={clientId}
                                key={'initialDayTimeBoxWrapper_' + typeSecretary}
                                postSecretaryInfo={postSecretaryLocal}
                                typeSecretary={typeSecretary}
                                timeRangeMileageSecretary={timeRangeMileageSecretary}
                            />
                        )
                    )
                    : ''
            }
        </div>
    );
};

DayTimesBox.propTypes = {
    classes: PropTypes.array,
    postSecretaryInfo: PropTypes.func,
    secretariesMileage: PropTypes.array,
    secretariesCommon: PropTypes.array,
    clientId: PropTypes.number,
    carIdCollect: PropTypes.number,
    typeSecretary: PropTypes.string,
    timeRangeMileageSecretary: PropTypes.object
};

const mapStateToProps = ({ secretary: { secretariesMileage, secretariesCommon }, templatesOfFrameState: { timeRangeMileageSecretary } }) => {
    return {
        secretariesMileage,
        secretariesCommon,
        timeRangeMileageSecretary
    };
};

export default connect(mapStateToProps, { postSecretaryInfo })(DayTimesBox);
