import React from 'react';
import style from './CardReport.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const CardReport = ({ text, link, img, alt }) => {
    return (
        <Link to={{
            pathname: link
        }}
        >
            <div className={style.cardReport}>
                <div>{ text }</div>
                <div className={style.imgIn}><img src={img} alt={alt} /></div>
            </div>
        </Link>
    );
};

CardReport.propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    img: PropTypes.any,
    alt: PropTypes.string
};

export default CardReport;
