export const PROLONGATION_FORMATS = [
    { id: 1, name: 'Года', label: 'year' },
    { id: 2, name: 'Месяца', label: 'month' },
    { id: 3, name: 'Недели', label: 'week' },
    { id: 4, name: 'Дни', label: 'day' },
    { id: 4, name: 'Все время', label: 'allTime' }
];

export const PROLONGATION_FILTER_FORMATS = {
    day: { label: 'day', chunk: 'day' },
    week: { label: 'week', chunk: 'day' },
    month: { label: 'month', chunk: 'week' },
    year: { label: 'year', chunk: 'month' },
    allTime: { label: 'allTime', chunk: 'year' }
};
