import React, { useEffect } from 'react';
import { HeaderSettingsInBody } from '../SettingsHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeadToggle from '../../../components/HeadToggle';
import BlockDetailsSettings from './components/BlockDetailsSettings';
import { fetchCollectBlocks } from '../../../actions';
import _ from 'lodash';
import { NOT_ALLOW_LABELS_SETTINGS } from '../../../constants/CarCollectBlocks';
import style from '../OwnerSettingsBody/OwnerSettingsBody.module.scss';

const OtherSettings = ({ blockInterestReason, blocks, fetchCollectBlocks }) => {
    useEffect(() => {
        (blockInterestReason && blockInterestReason.length === 0) && fetchCollectBlocks();
    }, [fetchCollectBlocks, blockInterestReason]);

    const blocksMap = blocks
        .filter(block => !(_.includes(NOT_ALLOW_LABELS_SETTINGS, block.label)))
        .map((item) => {
            const interests = blockInterestReason && (blockInterestReason.length > 0)
                ? (blockInterestReason.filter(interests => interests.car_collect_block_id === item.id))
                : [];
            return (
                <div
                    className={style.clients + ' ' + style.shadowBlock}
                    key={item.id}
                >
                    <HeadToggle
                        id={'HeaderToggle' + item.id}
                        headerText={item.title}
                        classes={['big', 'gray', 'leftPosition', 'onHoverArrow']}
                        initialHidden
                    >
                        <BlockDetailsSettings blockId={item.id} interests={interests} blocks={blocks.filter(block => !(_.includes(NOT_ALLOW_LABELS_SETTINGS, block.label)))} />
                    </HeadToggle>
                </div>
            );
        });

    return (
        <div className={style.svBookDetails}>
            <div className={style.svColumn}>
                <div className={style.clients + ' ' + style.shadowBlock}>
                    <HeaderSettingsInBody text='Настройка блоков листа сбора информации' />
                </div>
                {
                    blocksMap
                }
            </div>
        </div>
    );
};

OtherSettings.propTypes = {
    blocks: PropTypes.array,
    blockInterestReason: PropTypes.array,
    fetchCollectBlocks: PropTypes.func
};

const mapStateToProps = ({ templatesOfFrameState: { blockInterestReason, blocks } }) => {
    return {
        blockInterestReason,
        blocks
    };
};

export default connect(mapStateToProps, { fetchCollectBlocks })(OtherSettings);
