export default function getQueryVariable(searchParam, variable = null) {
    const query = searchParam.substring(1);
    const vars = query.split('&');
    const result = {};

    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable && variable !== null) {
            return decodeURIComponent(pair[1]);
        }
        result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return variable ? result[variable] : result;
}
