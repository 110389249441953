function reIdsResourcesFromAngara(arrayResourcesCompany = [], arrayOwners = []) {
    // changed data with resource from owner object with ids angara to ids phpApiIds
    const res = arrayOwners.map(item => {
        if (typeof item === 'object') {
            const newResources = (item.resources.length > 0) && typeof item.resources !== 'number' ? item.resources.reduce((accum, resourceOld) => {
                const resourceNew = arrayResourcesCompany.length > 0 && arrayResourcesCompany.find(resource => resource.angara_id === resourceOld.id);

                return [...accum, resourceNew && resourceNew.id];
            }, []) : item.resources;
            return { ...item, resources: newResources };
        } else {
            return arrayOwners;
        }
    });
    return res;
}

export default reIdsResourcesFromAngara;
