import {
    typeCarsFullyBlocksClear,
    typeCarsFullyBlocksCountLoaded,
    typeCarsFullyBlocksLoaded,
    typeCarsNotFullyBlocksCountLoaded,
    typeCarsNotFullyBlocksLoaded,
    typeCarsNotFullyOverdueCountLoaded,
    typeCarsNotFullyOverdueLoaded,
    typeListCollectResourceFilterError,
    typeListCollectResourceFilterSuccess,
    typeShowBtnNotCollect,
    typeShowBtnOverdue,
    typeAddOffsetNotCollectCarsLoaded,
    typeAddOffsetNotCollectOverdueCarsLoaded,
    typeClearOffsetLimitCarOverdue,
    typeClearOffsetLimitCar,
    typeSetResource,
    typeSetIsOpenMinHeader
} from '../actionsTypes';
import _ from 'lodash';
import {
    DEFAULT_LIMIT_CARS_NOT_COLLECT_OVERDUE,
    DEFAULT_OFFSET_CARS_NOT_COLLECT_OVERDUE,
    DEFAULT_LIMIT_NOT_COLLECT_CARS,
    DEFAULT_OFFSET_NOT_COLLECT_CARS
} from '../constants/limitDefaultCarCollect';

// ------------------------------------
// Constants
// ------------------------------------
const GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT = typeCarsNotFullyBlocksLoaded;
const GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT_COUNT = typeCarsNotFullyBlocksCountLoaded;
const SHOW_BTN_NOT_COLLECT_INFO_CARS = typeShowBtnNotCollect;
const GET_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT = typeCarsNotFullyOverdueLoaded;
const GET_COUNT_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT = typeCarsNotFullyOverdueCountLoaded;
const SHOW_BTN_OVERDUE_COLLECT_INFO_CARS = typeShowBtnOverdue;
const CAR_NOT_FULLY_BLOCKS_CLEAR = typeCarsFullyBlocksClear;
const GET_CARS_WITH_FULLY_BLOCKS_COLLECT = typeCarsFullyBlocksLoaded;
const GET_CARS_WITH_FULLY_BLOCKS_COLLECT_COUNT = typeCarsFullyBlocksCountLoaded;
const ADD_OFFSET_NOT_COLLECT_CARS = typeAddOffsetNotCollectCarsLoaded;
const ADD_OFFSET_NOT_COLLECT_OVERDUE_CARS = typeAddOffsetNotCollectOverdueCarsLoaded;
const CLEAR_OFFSET_LIMIT_CAR_OVERDUE = typeClearOffsetLimitCarOverdue;
const CLEAR_OFFSET_LIMIT_CAR = typeClearOffsetLimitCar;
const SET_IS_OPEN_MIN_HEADER = typeSetIsOpenMinHeader;

// ------------------------------------
// Actions
// ------------------------------------

const carsNotFullyBlocksClear = () => {
    return {
        type: typeCarsFullyBlocksClear
    };
};

const carsNotFullyBlocksLoaded = (cars) => {
    return {
        type: typeCarsNotFullyBlocksLoaded,
        payload: cars
    };
};

const carsFullyBlocksCountLoaded = (carsFullyCount) => {
    return {
        type: typeCarsFullyBlocksCountLoaded,
        payload: carsFullyCount
    };
};

const carsNotFullyOverdueLoaded = (cars) => {
    return {
        type: typeCarsNotFullyOverdueLoaded,
        payload: cars
    };
};

export const showBtnCarsNotCollectBlocks = () => {
    return {
        type: typeShowBtnNotCollect
    };
};

export const showBtnCarsCollectBlocksOverdue = () => {
    return {
        type: typeShowBtnOverdue
    };
};

const carsNotFullyBlocksCountLoaded = (carsNotFullyCount) => {
    return {
        type: typeCarsNotFullyBlocksCountLoaded,
        payload: carsNotFullyCount
    };
};

const carsNotFullyOverdueCountLoaded = (countCars) => {
    return {
        type: typeCarsNotFullyOverdueCountLoaded,
        payload: countCars
    };
};

export const setListCollectResourceFilterError = (isError) => {
    return {
        type: typeListCollectResourceFilterError,
        payload: isError
    };
};

export const setListCollectResourceFilterSuccess = (isLoad) => {
    return {
        type: typeListCollectResourceFilterSuccess,
        payload: isLoad
    };
};

const addOffsetNotCollectCars = () => {
    return {
        type: typeAddOffsetNotCollectCarsLoaded
    };
};

const addOffsetNotCollectOverdueCars = () => {
    return {
        type: typeAddOffsetNotCollectOverdueCarsLoaded
    };
};

export const clearOffsetLimitCarOverdue = () => {
    return {
        type: typeClearOffsetLimitCarOverdue
    };
};

export const clearOffsetLimitCarsNotCollect = () => {
    return {
        type: typeClearOffsetLimitCar
    };
};

export const fetchCollectInfoSheetsCars = (data = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setListCollectResourceFilterError(false));
    (getState().carsListState.carsListCollectFilterLoading === false) && dispatch(setListCollectResourceFilterSuccess(true));
    let initialParams = {};
    const { limitNotCollectCars, offsetNotCollectCars, limitNotCollectCarsOverdue, offsetNotCollectCarsOverdue } = getState().sheetCollectInfo;
    const { selectedResource } = getState().carDetailsState;
    if (data.status_car === 'not_fully') {
        initialParams = { limit: limitNotCollectCars, offset: offsetNotCollectCars };
    }
    if (data.status_car === 'overdue') {
        initialParams = { limit: limitNotCollectCarsOverdue, offset: offsetNotCollectCarsOverdue };
    }
    if (data.status_car === 'fully') {
        initialParams = { limit: 1500, offset: 0 };
    }

    return apiService.getCollectInfoSheets({ ...initialParams, ...data, selectedResource: selectedResource })
        .then((res) => {
            dispatch(setListCollectResourceFilterSuccess(false));
            if (Object.hasOwnProperty.call(data, 'status_car') && Object.hasOwnProperty.call(res, 'items') && Object.hasOwnProperty.call(res, 'count')) {
                if (data.status_car === 'not_fully') {
                    dispatch(carsNotFullyBlocksLoaded(res.items));
                    dispatch(carsNotFullyBlocksCountLoaded(res.count));
                    dispatch(showBtnCarsNotCollectBlocks());
                }

                if (data.status_car === 'overdue') {
                    dispatch(carsNotFullyOverdueLoaded(res.items));
                    dispatch(carsNotFullyOverdueCountLoaded(res.count));
                    dispatch(showBtnCarsCollectBlocksOverdue());
                }

                if (data.status_car === 'fully') {
                    dispatch(carsFullyBlocksCountLoaded(res.count));
                }
            }

            return res;
        })
        .catch((err) => {
            dispatch(setListCollectResourceFilterSuccess(false));
            dispatch(setListCollectResourceFilterError(true));
            return err;
        });
};

const fetchFully = () => (dispatch) => {
    return dispatch(fetchCollectInfoSheetsCars({ status_car: 'fully', limit: 1000, offset: 0 }));
};

const fetchNotFully = () => (dispatch) => {
    return dispatch(fetchCollectInfoSheetsCars({ status_car: 'not_fully' }));
};

const fetchOverdue = () => (dispatch) => {
    return dispatch(fetchCollectInfoSheetsCars({ status_car: 'overdue' }));
};

export const getSVBooksWithParams = (params = {}) => (dispatch) => {
    dispatch(fetchFully());
    dispatch(fetchOverdue());
    dispatch(fetchNotFully());
};

export const fetchOffsetNotCollectCars = (whatAddParam) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (whatAddParam === 'notCollect') {
            resolve(dispatch(addOffsetNotCollectCars()));
        }
        if (whatAddParam === 'notCollectOverdue') {
            resolve(dispatch(addOffsetNotCollectOverdueCars()));
        } else {
            reject(new Error('error. Not found what need update: notCollect or notCollectOverdue'));
        }
    })
        .then(() => {
            if (whatAddParam === 'notCollect') {
                dispatch(fetchNotFully());
            }
            if (whatAddParam === 'notCollectOverdue') {
                dispatch(fetchOverdue());
            } else {
                return new Error('error. Not found what need update: notCollect or notCollectOverdue');
            }
        })
        .catch(err => console.log(err));
};

const setSelectResource = (idResource) => {
    return {
        type: typeSetResource,
        payload: idResource
    };
};

export const setOpenMinHeader = (isOpen) => {
    return {
        type: typeSetIsOpenMinHeader,
        payload: isOpen
    };
};

export const filterResource = (idResource) => (dispatch, getState) => {
    dispatch(carsNotFullyBlocksClear());
    dispatch(setListCollectResourceFilterSuccess(true));
    dispatch(setListCollectResourceFilterError(false));
    dispatch(clearOffsetLimitCarOverdue());
    dispatch(clearOffsetLimitCarsNotCollect());
    dispatch(showBtnCarsNotCollectBlocks());
    dispatch(showBtnCarsCollectBlocksOverdue());
    return new Promise((resolve, reject) => {
        dispatch(setSelectResource(idResource));
        if (getState().carDetailsState.selectedResource === idResource) {
            resolve(getState().carDetailsState.selectedResource);
        } else {
            const error = new Error('Does not save the selected resource');
            reject(error);
        }
    })
        .then(() => {
            dispatch(getSVBooksWithParams());
        })
        .catch(err => {
            dispatch(setListCollectResourceFilterSuccess(false));
            dispatch(setListCollectResourceFilterError(true));
            return err;
        });
};

export const actions = {
    fetchCollectInfoSheetsCars,
    fetchOffsetNotCollectCars,
    filterResource,
    setOpenMinHeader
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT]: (state, action) => {
        let carsNotFully = _.cloneDeep(state.carsNotFullyBlocks);
        const idsOldCars = carsNotFully.map(item => item.id);
        const arrNewCars = [
            ...action.payload.filter(item => idsOldCars.indexOf(item.id) === -1)
        ];
        carsNotFully = [...carsNotFully, ...arrNewCars];

        return ({
            ...state,
            carsNotFullyBlocks: carsNotFully
        });
    },

    [GET_CARS_WITH_NOT_FULLY_BLOCKS_COLLECT_COUNT]: (state, action) => {
        return ({
            ...state,
            carsNotFullyBlocksCount: action.payload
        });
    },

    [SHOW_BTN_NOT_COLLECT_INFO_CARS]: (state) => {
        if ((Array.isArray(state.carsNotFullyBlocks) && state.carsNotFullyBlocks.length) >= state.carsNotFullyBlocksCount) {
            return ({
                ...state,
                showBtnNotCollectBlocks: false
            });
        } else {
            return ({
                ...state,
                showBtnNotCollectBlocks: true
            });
        }
    },

    [GET_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT]: (state, action) => {
        let carsNotFullyOverdue = _.cloneDeep(state.carsNotFullyOverdue);
        const idsOldCars = carsNotFullyOverdue.map(item => item.id);
        const arrNewCars = [
            ...action.payload.filter(item => idsOldCars.indexOf(item.id) === -1)
        ];
        carsNotFullyOverdue = [...carsNotFullyOverdue, ...arrNewCars];

        return ({
            ...state,
            carsNotFullyOverdue: carsNotFullyOverdue
        });
    },

    [GET_COUNT_CARS_WITH_NOT_FULLY_BLOCKS_OVERDUE_COLLECT]: (state, action) => {
        return ({
            ...state,
            carsNotFullyOverdueCount: action.payload
        });
    },

    [SHOW_BTN_OVERDUE_COLLECT_INFO_CARS]: (state) => {
        if ((Array.isArray(state.carsNotFullyOverdue) && state.carsNotFullyOverdue.length) >= state.carsNotFullyOverdueCount) {
            return ({
                ...state,
                showBtnOverdue: false
            });
        } else {
            return ({
                ...state,
                showBtnOverdue: true
            });
        }
    },

    [CAR_NOT_FULLY_BLOCKS_CLEAR]: (state) => {
        return ({
            ...state,
            carsNotFullyBlocks: [],
            carsNotFullyOverdue: [],
            carsNotFullyBlocksCount: 0,
            carsNotFullyOverdueCount: 0
        });
    },

    [GET_CARS_WITH_FULLY_BLOCKS_COLLECT]: (state, action) => {
        return ({
            ...state,
            carsFullyBlocks: action.payload
        });
    },

    [GET_CARS_WITH_FULLY_BLOCKS_COLLECT_COUNT]: (state, action) => {
        return ({
            ...state,
            carsFullyBlockCount: action.payload
        });
    },

    [ADD_OFFSET_NOT_COLLECT_CARS]: (state) => {
        let offsetCarNotCollect = _.cloneDeep(state.offsetNotCollectCars);
        offsetCarNotCollect = offsetCarNotCollect + state.limitNotCollectCars;
        return ({
            ...state,
            offsetNotCollectCars: offsetCarNotCollect
        });
    },

    [ADD_OFFSET_NOT_COLLECT_OVERDUE_CARS]: (state) => {
        let offsetCarNotCollectOverdue = _.cloneDeep(state.offsetNotCollectCarsOverdue);
        offsetCarNotCollectOverdue = offsetCarNotCollectOverdue + state.limitNotCollectCarsOverdue;
        return ({
            ...state,
            offsetNotCollectCarsOverdue: offsetCarNotCollectOverdue
        });
    },

    [CLEAR_OFFSET_LIMIT_CAR_OVERDUE]: (state) => {
        return ({
            ...state,
            limitNotCollectCarsOverdue: DEFAULT_LIMIT_CARS_NOT_COLLECT_OVERDUE,
            offsetNotCollectCarsOverdue: DEFAULT_OFFSET_CARS_NOT_COLLECT_OVERDUE
        });
    },

    [CLEAR_OFFSET_LIMIT_CAR]: (state) => {
        return ({
            ...state,
            limitNotCollectCars: DEFAULT_LIMIT_NOT_COLLECT_CARS,
            offsetNotCollectCars: DEFAULT_OFFSET_NOT_COLLECT_CARS
        });
    },

    [SET_IS_OPEN_MIN_HEADER]: (state, action) => {
        return ({
            ...state,
            isOpenMinHeader: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            carsFullyBlocks: [],
            carsFullyBlockCount: 0,
            carsNotFullyBlocks: [],
            carsNotFullyBlocksCount: 0,
            showBtnNotCollectBlocks: true,
            carsNotFullyOverdue: [],
            carsNotFullyOverdueCount: 0,
            showBtnOverdue: true,
            limitNotCollectCars: DEFAULT_LIMIT_NOT_COLLECT_CARS,
            offsetNotCollectCars: DEFAULT_OFFSET_NOT_COLLECT_CARS,
            limitNotCollectCarsOverdue: DEFAULT_LIMIT_CARS_NOT_COLLECT_OVERDUE,
            offsetNotCollectCarsOverdue: DEFAULT_OFFSET_CARS_NOT_COLLECT_OVERDUE,
            isOpenMinHeader: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.sheetCollectInfo, action) : state.sheetCollectInfo;
};

export default commonReducer;
