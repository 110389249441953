import React from 'react';
import style from './RadioButtonsHorizontal.module.scss';
import PropTypes from 'prop-types';

const RadioButton = ({ title, handleOnClick, classes = [] }) => {
    return (
        <div
            className={style.rButton + classes.map(item => ' ' + style[item]).join('')}
            onClick={handleOnClick}
        >
            { title }
        </div>
    );
};

RadioButton.propTypes = {
    title: PropTypes.string,
    classes: PropTypes.array,
    handleOnClick: PropTypes.func
};

const RadioButtonsHorizontal = ({ initial, arrButtons = [], handleOnClick = () => {} }) => {
    return (
        <div className={style.rButtons}>
            {
                arrButtons?.map((button) => {
                    return (
                        <RadioButton
                            key={button.id}
                            title={button.title}
                            handleOnClick={() => handleOnClick(button.id)}
                            classes={button.id === initial ? ['select'] : []}
                        />
                    );
                })
            }
        </div>
    );
};

RadioButtonsHorizontal.propTypes = {
    arrButtons: PropTypes.array,
    initial: PropTypes.number,
    handleOnClick: PropTypes.func
};

export default RadioButtonsHorizontal;
