import React, { useEffect, useState } from 'react';
import { spaceInNumber } from '../../utils';
import HeaderWithSearch from '../../components/HeaderWithSearch';
import PropTypes from 'prop-types';

const SearchLayout = ({
    textSearch,
    countItems,
    searchItems,
    saveSearchError,
    id,
    nameHeader,
    placeholderSearch,
    isLoad,
    setItemsLoaded,
    clearFoundItems,
    handlerAdd,
    sortBySchedule,
    textButtonAdd = ''

}) => {
    const [sumItems, setSumItems] = useState('');
    const [label, setLabel] = useState('');

    useEffect(() => {
        setSumItems(spaceInNumber(+countItems));
        return () => saveSearchError(false);
    }, [countItems, saveSearchError]);

    useEffect(() => {
        setLabel(textSearch.search);
    }, [textSearch]);

    const handleSearch = (event) => {
        setLabel(event.target.value);
    };

    const onSearch = (text) => {
        clearFoundItems();
        setItemsLoaded({
            search: text,
            offset: 0
        });
        searchItems();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        onSearch(label);
    };

    return (
        <>
            <HeaderWithSearch
                key={id}
                nameHeader={nameHeader}
                placeholderSearch={placeholderSearch}
                label={label}
                sumItems={sumItems}
                onSubmit={onSubmit}
                handleSearch={handleSearch}
                isLoad={isLoad}
                textButtonAdd={textButtonAdd}
                handlerAdd={handlerAdd}
                sortBySchedule={sortBySchedule}
            />
        </>
    );
};

SearchLayout.propTypes = {
    textSearch: PropTypes.object,
    countItems: PropTypes.number,
    searchItems: PropTypes.func,
    saveSearchError: PropTypes.func,
    id: PropTypes.string,
    nameHeader: PropTypes.string,
    placeholderSearch: PropTypes.string,
    isLoad: PropTypes.bool,
    setItemsLoaded: PropTypes.func,
    clearFoundItems: PropTypes.func,
    handlerAdd: PropTypes.func,
    textButtonAdd: PropTypes.string,
    sortBySchedule: PropTypes.bool
};

export default SearchLayout;
