import React from 'react';
import { RowThree } from '../Row';
import { ListInspectCollect, ListCollectArea } from '../ListInspectCollect';
import MiddleInfo from '../MiddleInfo';

const MainInfo = () => {
    return (
        <RowThree
            left={
                <ListInspectCollect
                    header='Листы осмотра'
                    layout='CardLeft'
                />
            }
            middle={
                <MiddleInfo />
            }
            right={
                <ListCollectArea
                    header='Листы сбора информации'
                />
            }
        />
    );
};

export default MainInfo;
