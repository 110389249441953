import React from 'react';

import style from '../CarAndVisitor/CarAndVisitor.module.scss';
import LoadingIndicatorBlock from '../LoadingIndicator/LoadingIndicatorBlock';
import PropTypes from 'prop-types';
import { formatNumber } from '../../utils';

const VisitorAndPhone = ({ visitor, isVisitorSuccess }) => {
    return (
        <div className={style.carAndVisitorInfo}>
            <LoadingIndicatorBlock reasonLoader={isVisitorSuccess} />
            <p>
                {
                    visitor && visitor.nick
                        ? visitor.nick
                        : ''
                }
            </p>
            <p>
                {
                    visitor && visitor.phone
                        ? formatNumber(visitor.phone)
                        : ''
                }
            </p>
        </div>
    );
};

VisitorAndPhone.propTypes = {
    visitor: PropTypes.object,
    isVisitorSuccess: PropTypes.bool
};

export default VisitorAndPhone;
