import React from 'react';
import PropTypes from 'prop-types';
import { spaceInNumber } from '../../utils';
import road from './assets/road.svg';

import style from './SvBasicMileage.module.scss';
import { connect } from 'react-redux';

const SvBasicMileage = ({ carDetailsInfo }) => {
    const { mileage } = carDetailsInfo;
    return (
        <div className={style.mileage}>
            <div><img src={road} alt='road' /></div>
            <div>{ mileage ? spaceInNumber(mileage) : '0' } км</div>
        </div>
    );
};

SvBasicMileage.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(SvBasicMileage);
