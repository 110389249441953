import React, { useEffect, useState } from 'react';
import style from './ButtonsCommon.module.scss';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import {
    CHANGE_MODE,
    PROLONGATION_MODE_INITIATION,
    PROLONGATION_MODE_PROLONGATION
} from '../../constants/prolongationMode';

export const ButtonsProlongation = ({
    dataSuccess,
    handleClick,
    btnSelected,
    isError = false,
    classes = [],
    buttonsName = [
        { title: 'Развитие', label: PROLONGATION_MODE_INITIATION }, { title: 'Пролонгация', label: PROLONGATION_MODE_PROLONGATION }
    ]
}) => {
    const [selected, setSelected] = useState(btnSelected);

    useEffect(() => {
        btnSelected !== null && setSelected(btnSelected);
    }, [btnSelected]);

    const handleAction = (data) => {
        setSelected(CHANGE_MODE[data]);
        handleClick(data);
    };

    const twoButtons = (
        <div className={style.btnSmall + classes.map(item => ' ' + style[item]).join('')}>
            {
                dataSuccess
                    ? <div className={style.btnLoad}><Loader active={dataSuccess} classes={['small', 'withoutBackground', 'light']} /></div>
                    : ''
            }
            <div className={`${style.firstOil} ${selected === buttonsName[0].label ? style.selectBtn : ''}`} onClick={() => handleAction(false)}>{ buttonsName[0].title }</div>
            <div className={`${style.secondOil} ${selected === buttonsName[1].label ? style.selectBtn : ''}`} onClick={() => handleAction(true)}>{ buttonsName[1].title }</div>
        </div>
    );

    return (
        isError
            ? <div>Something is wrong</div>
            : (buttonsName?.length === 2) ? twoButtons : ''
    );
};

ButtonsProlongation.propTypes = {
    dataSuccess: PropTypes.bool,
    handleClick: PropTypes.func,
    btnSelected: PropTypes.string,
    isError: PropTypes.bool,
    classes: PropTypes.array,
    buttonsName: PropTypes.array
};

const ButtonsCommon = ({
    dataSuccess,
    handleClick,
    btnSelected,
    isError = false,
    classes = [],
    countButtons = 2,
    buttonsName = ['Точно', 'Примерно']
}) => {
    const twoButtons = (
        <div className={style.btnSmall + classes.map(item => ' ' + style[item]).join('')}>
            {
                dataSuccess
                    ? <div className={style.btnLoad}><Loader active={dataSuccess} classes={['small', 'withoutBackground', 'light']} /></div>
                    : ''
            }
            <div className={`${style.firstOil} ${btnSelected ? style.selectBtn : ''}`} onClick={() => handleClick(true)}>{ buttonsName[0] }</div>
            <div className={`${style.secondOil} ${btnSelected ? '' : style.selectBtn}`} onClick={() => handleClick(false)}>{ buttonsName[1] }</div>
        </div>
    );

    return (
        isError
            ? <div>Something is wrong</div>
            : (countButtons === 2) ? twoButtons : ''
    );
};

ButtonsCommon.propTypes = {
    dataSuccess: PropTypes.bool,
    handleClick: PropTypes.func,
    btnSelected: PropTypes.bool,
    isError: PropTypes.bool,
    classes: PropTypes.array,
    countButtons: PropTypes.number,
    buttonsName: PropTypes.array
};

export default ButtonsCommon;
