import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateEndInsurance from '../DateEndInsurance/DateEndInsurance';
import { fetchPostCarInsurance } from '../../actions';
import { createDateForSaveFromDatePicker } from '../../utils';

const SetCarInsuranceDate = ({
    carIdCollect,
    valueField,
    propCar,
    idName,
    nameForm,
    fetchPostCarInsurance,
    carInsuranceUpdateError,
    clientIdCollect,
    classes
}) => {
    const [valueForm, setValueForm] = useState(valueField);

    useEffect(() => {
        setValueForm(valueField && valueField);
    }, [valueField, carIdCollect]);

    const handleUpdate = (valueUp) => {
        const payload = {
            car_id: carIdCollect,
            [propCar]: createDateForSaveFromDatePicker(valueUp)
        };
        valueUp && clientIdCollect && fetchPostCarInsurance(payload, clientIdCollect);
    };

    if (carInsuranceUpdateError) {
        return <div>Something is wrong</div>;
    }

    return (
        <DateEndInsurance
            nameForm={nameForm}
            valueField={valueForm}
            forUpdateField={handleUpdate}
            labelName={nameForm}
            idName={idName}
            id={propCar}
            classes={classes}
        />
    );
};

SetCarInsuranceDate.propTypes = {
    classes: PropTypes.array,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    valueField: PropTypes.any,
    propCar: PropTypes.string,
    idName: PropTypes.string,
    nameForm: PropTypes.string,
    fetchPostCarInsurance: PropTypes.func,
    carInsuranceUpdateError: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carInsuranceUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carInsuranceUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarInsurance })(SetCarInsuranceDate);
