import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import style from './ClientNamePhoneRow.module.scss';
import InputWithStyle from '../Inputs/InputWithStyle';
import InputPhoneWithStyle from '../Inputs/InputPhoneWithStyle';
import DeleteButtonIcon from '../Buttons/DeleteButtonIcon';
import AddSearchClientForm from '../AddSearchClientForm';

const ClientNamePhoneRow = ({
    id,
    name,
    phone,
    handleChangeClient,
    handleDeleteClient,
    itNew,
    withDeleteBtn,
    carId,
    foundClients,
    searchInfo,
    isLoadSearchClient,
    searchNewClient,
    disabled,
    classes = []
}) => {
    const initialClient = useMemo(() => {
        return { nick: name || '', phone: phone || '' };
    }, [name, phone]);

    const [valueClient, setValueClient] = useState(initialClient);

    const handleOnBlur = useCallback(() => {
        if (valueClient.id || (valueClient.nick && valueClient.phone && valueClient.nick.trim() !== name && valueClient.phone.length >= 10 && valueClient.phone !== phone)) {
            if (valueClient.id) {
                handleChangeClient(valueClient);
                setValueClient(initialClient);
            } else {
                valueClient.nick.trim() &&
                handleChangeClient({ nick: valueClient.nick.trim(), phone: '+' + valueClient.phone.slice(1) });
                setValueClient(initialClient);
            }
        }
    }, [valueClient, handleChangeClient, initialClient, name, phone]);

    useEffect(() => {
        setValueClient({ nick: name, phone: phone });
    }, [name, phone]);

    useEffect(() => {
        if (valueClient.id || (valueClient.nick && valueClient.phone)) {
            handleOnBlur();
        }
    }, [valueClient, handleOnBlur]);

    const handleUpdateValue = (value, field) => {
        setValueClient(s => ({ ...s, [field]: value }));
    };

    const inputsForExistsClients = (
        <>
            <div className={style.fieldName}>
                <InputWithStyle
                    setValue={(value) => handleUpdateValue(value, 'nick')}
                    handlerUpdateValue={() => {}}
                    placeholderText='Имя пользователя авто'
                    keyId={'clientName' + id}
                    value={valueClient.nick}
                    id={'clientName' + id}
                    classes={['bigMaxWidth', ...classes, 'font16', 'maxWidth600', 'minHeight38']}
                    disabled={disabled}
                />
            </div>
            <div className={style.fieldPhone}>
                <InputPhoneWithStyle
                    setValue={(value) => handleUpdateValue(value.trim().replace(/([^0-9+]*)/ig, ''), 'phone')}
                    handlerUpdateValue={() => {}}
                    placeholderText='Телефон'
                    keyId={'clientPhone' + id}
                    value={valueClient.phone}
                    id={'clientPhone' + id}
                    classes={['bigMaxWidth', ...classes, 'minHeight38']}
                    disabled={disabled}
                />
            </div>
        </>
    );

    const inputsForNewClient = (
        <AddSearchClientForm
            id={`new${id}`}
            handlePushNewClient={(data) => setValueClient({ id: data.id, nick: data.nick, phone: data.phone })}
            carId={carId}
            foundClients={foundClients}
            searchInfo={searchInfo}
            handleSearchNewClient={searchNewClient}
            isLoadSearchClient={isLoadSearchClient}
            classesInputs={['minHeight38', 'maxWidth600', ...classes]}
            classesColumns={['stretchChildren', 'flexGrow0']}
            placeholderText='Имя пользователя авто'
        />
    );

    return (
        <div className={style.clientNamePhoneRow}>
            {
                itNew
                    ? inputsForNewClient
                    : inputsForExistsClients
            }

            {
                withDeleteBtn
                    ? <DeleteButtonIcon handleDeleteSelectedValue={() => handleDeleteClient(id || undefined)} />
                    : ''
            }

        </div>
    );
};

ClientNamePhoneRow.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    phone: PropTypes.string,
    classes: PropTypes.array,
    handleChangeClient: PropTypes.func,
    handleDeleteClient: PropTypes.func,
    itNew: PropTypes.bool,
    withDeleteBtn: PropTypes.bool,
    carId: PropTypes.number,
    foundClients: PropTypes.array,
    searchInfo: PropTypes.object,
    isLoadSearchClient: PropTypes.bool,
    disabled: PropTypes.bool,
    searchNewClient: PropTypes.func
};

export default ClientNamePhoneRow;
