import React from 'react';
import SearchLayout from '../../../layouts/SearchLayout/SearchLayout';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setTextVisitors, searchVisitors, clearFoundVisitors } from 'reducers/visitors';

const VisitorsWrap = ({
    textSearch,
    countVisitors,
    searchVisitorsSuccess,
    setTextVisitors,
    searchVisitors,
    clearFoundVisitors
}) => {
    return (
        <SearchLayout
            id='headerSearchUsers'
            nameHeader='Карточки гостей'
            placeholderSearch='Поиск по имени / телефону / автомобилю / гос. номеру авто'
            textSearch={textSearch}
            countItems={countVisitors}
            isLoad={searchVisitorsSuccess}
            saveSearchError={() => {}}
            setItemsLoaded={setTextVisitors}
            clearFoundItems={clearFoundVisitors}
            searchItems={searchVisitors}
            sortBySchedule
        />
    );
};

VisitorsWrap.propTypes = {
    textSearch: PropTypes.object,
    countVisitors: PropTypes.number,
    searchVisitorsSuccess: PropTypes.bool,
    setTextVisitors: PropTypes.func,
    searchVisitors: PropTypes.func,
    clearFoundVisitors: PropTypes.func
};

const mapStateToProps = ({ visitors: { textSearch, countVisitors, searchVisitorsSuccess } }) => {
    return {
        textSearch,
        countVisitors,
        searchVisitorsSuccess
    };
};

export default connect(mapStateToProps, { setTextVisitors, searchVisitors, clearFoundVisitors })(VisitorsWrap);
