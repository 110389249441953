import reducer from './reducers';
import thunk from 'redux-thunk';
import { typeFetchError } from './actionsTypes';
import { logout, setError } from './reducers/auth';
import { setError404Redirect, setError500, setError422 } from './reducers/common';
import ApiService from './services/ApiService';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as Sentry from '@sentry/react';

export const apiService = new ApiService();

const logMiddleware = () => (next) => (action) => {
    // console.log('store', action);
    return next(action);
};

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const errorMiddleware = (store) => (next) => (action) => {
    if ((action.type === typeFetchError)) {
        next(setError(action.payload));
        if (action.payload.status === 401) {
            store.dispatch(logout());
        }

        if (action.payload.status === 404) {
            store.dispatch(setError404Redirect(true));
        }

        if (action.payload.status >= 500) {
            store.dispatch(setError500(true));
        }

        if (action.payload.status === 422) {
            store.dispatch(setError422(action.payload));
        }
    }
    return next(action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
});

const middlewares = applyMiddleware(logMiddleware, thunk.withExtraArgument({ apiService }), errorMiddleware);

const enhancer = process.env.NODE_ENV === 'production' ? composeEnhancers(middlewares) : composeEnhancers(middlewares, sentryReduxEnhancer);

const store = createStore(reducer, enhancer);

export default store;
