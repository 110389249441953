import React, { useState, useEffect } from 'react';

import style from './SelectBox.module.scss';
import PropTypes from 'prop-types';

const SelectBox = ({ handleChangeSelect, selectValueFather, options, create }) => {
    const [selectValue, setSelectValue] = useState(0);

    useEffect(() => {
        setSelectValue(selectValueFather || 0);
        return () => setSelectValue(0);
    }, [selectValueFather]);

    const updateStatusValue = (event) => {
        setSelectValue(event.target.value);
        handleChangeSelect(+event.target.value);
    };

    return (
        <select className={style.selectField} name='owner' value={selectValue} onChange={updateStatusValue}>
            {
                options && options.map((item) => {
                    return create && (item.name === 'PARTNER') ? null : <option key={item.id} value={item.id}>{ item.roleLabel }</option>;
                })
            }
        </select>
    );
};

SelectBox.propTypes = {
    handleChangeSelect: PropTypes.func,
    selectValueFather: PropTypes.number,
    options: PropTypes.array,
    create: PropTypes.bool
};

export default SelectBox;
