import React, { useEffect, useState, useMemo } from 'react';
import DayTimesBox from '../DayTimeBox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSecretaryInfo, postSecretaryInfo } from '../../../../../../reducers/secretary';
import Connection from './components/Connection';

import { SECRETARY_MILEAGE } from '../../../../../../constants/secretaryTypes';
import style from './SecretaryWrapper.module.scss';

function arrowUpDownFunc(oldArray, orderOld, up) {
    const oldOrderChange = up ? (orderOld - 1) : (orderOld + 1);

    let oldItemWithNewOrder = oldArray.find(item => item.order === oldOrderChange);
    let resultPre = oldArray;
    if (oldItemWithNewOrder) {
        oldItemWithNewOrder = { ...oldItemWithNewOrder, order: orderOld };
        resultPre = resultPre.filter(item => item.channel_connection_id !== oldItemWithNewOrder.channel_connection_id);
    }
    let itemWithOldOrder = oldArray.find(item => item.order === orderOld);
    if (itemWithOldOrder) {
        itemWithOldOrder = { ...itemWithOldOrder, order: oldOrderChange };
        resultPre = resultPre.filter(item => item.channel_connection_id !== itemWithOldOrder.channel_connection_id);
    }
    return [...resultPre, oldItemWithNewOrder, itemWithOldOrder];
}

function transformConnectionFromApiToUsed(connectionSecretary) {
    return connectionSecretary && (connectionSecretary.length > 0) ? connectionSecretary.map(item => {
        return { priority: item.priority, channel_connection_id: item.channel_connection_id.id, is_checked: !!item.is_checked };
    }) : [];
}

function compareSortByChannelConnectionId(item1, item2) {
    if (item1.channel_connection_id < item2.channel_connection_id) return -1;
    if (item1.channel_connection_id > item2.channel_connection_id) return 1;
    return 0;
}

const SecretaryInner = ({
    isRadioButtonConnection,
    connections,
    setConnections,
    handleChangeOrder,
    handleUpdateSecretaryInfo,
    typeSecretary,
    clientId,
    occasionData,
    occasions,
    hasOccasion,
    carIdCollect
}) => {
    return (
        <>
            {
                isRadioButtonConnection
                    ? <Connection typeInput='radioButton' connections={connections} header='Канал связи' />
                    : (
                        <Connection
                            typeInput='checkBox'
                            connections={connections}
                            header='Канал связи'
                            updateState={setConnections}
                            handleChangeOrder={handleChangeOrder}
                            handleUpdateSecretaryInfo={handleUpdateSecretaryInfo}
                            showOrder
                            id={`connection_${typeSecretary}`}
                        />
                    )
            }
            <DayTimesBox classes={['andPosition']} clientId={clientId} typeSecretary={typeSecretary} carIdCollect={carIdCollect} />
            {
                hasOccasion
                    ? <Connection clientId={clientId} typeInput='checkBox' connections={occasionData || occasions} header='Связываться по поводу' classes={['andPosition']} />
                    : ''
            }
        </>
    );
};

SecretaryInner.propTypes = {
    isRadioButtonConnection: PropTypes.bool,
    connections: PropTypes.array,
    setConnections: PropTypes.func,
    handleChangeOrder: PropTypes.func,
    handleUpdateSecretaryInfo: PropTypes.func,
    typeSecretary: PropTypes.string,
    clientId: PropTypes.number,
    carIdCollect: PropTypes.number,
    occasionData: PropTypes.array,
    occasions: PropTypes.array,
    hasOccasion: PropTypes.bool
};

const SecretaryWrapper = ({
    occasionData,
    isRadioButtonConnection,
    hasOccasion,
    secretaryMileageConnections,
    secretaryCommonConnections,
    clientId,
    getSecretaryInfo,
    postSecretaryInfo,
    typeSecretary,
    carIdCollect,
    dataInner
}) => {
    const connectionsTemplate = useMemo(() => ([
        { order: 1, channel_connection_id: 1, text: 'Звонок', label: 'call', isCheck: false },
        { order: 2, channel_connection_id: 2, text: 'СМС', label: 'sms', isCheck: false },
        { order: 3, channel_connection_id: 3, text: 'WhatsApp', label: 'whatsApp', isCheck: false }
    ]), []);

    const occasions = useMemo(() => ([
        { text: 'Приглашений', label: 'invitation', isCheck: false },
        { text: 'OKK', label: 'okk', isCheck: false },
        { text: 'Напоминаний', label: 'reminder', isCheck: false }
    ]), []);

    const [connections, setConnections] = useState(connectionsTemplate);
    const [connectionSecretary, setConnectionSecretary] = useState([]);

    useEffect(() => {
        setConnectionSecretary((typeSecretary === SECRETARY_MILEAGE) ? secretaryMileageConnections : secretaryCommonConnections);
    }, [secretaryMileageConnections, typeSecretary, secretaryCommonConnections]);

    useEffect(() => {
        const data = clientId ? { secretaryType: typeSecretary, clientId: clientId, connections: 1 } : { secretaryType: typeSecretary };
        clientId && getSecretaryInfo(clientId, data, carIdCollect);
    }, [getSecretaryInfo, clientId, typeSecretary, carIdCollect]);

    useEffect(() => {
        connectionSecretary && (connectionSecretary.length > 0) ? setConnections(s => {
            return s.length > 0 ? s.map(item => {
                const newData = connectionSecretary.find(i => i.channel_connection_id.id === item.channel_connection_id);
                return newData ? { ...item, order: newData.priority, isCheck: !!newData.is_checked, id: newData.id } : item;
            }) : connectionsTemplate;
        }) : setConnections(connectionsTemplate);
    }, [connectionSecretary, connectionsTemplate]);

    useEffect(() => {
        return () => setConnections(connectionsTemplate);
    }, [clientId, connectionsTemplate]);

    const handleChangeOrder = (nameButton, orderOld) => {
        if (nameButton === 'arrowUp') {
            setConnections(s => arrowUpDownFunc(s, orderOld, true));
        }
        if (nameButton === 'arrowDown') {
            setConnections(s => arrowUpDownFunc(s, orderOld, false));
        }
    };

    const handleUpdateSecretaryInfo = () => {
        const newConnections = connections.map(item => {
            return { priority: item.order, channel_connection_id: item.channel_connection_id, is_checked: item.isCheck };
        });

        const initialConnections = connectionsTemplate.map(item => {
            return { priority: item.order, channel_connection_id: item.channel_connection_id, is_checked: item.isCheck };
        });

        const jsonNewData = JSON.stringify(newConnections.sort(compareSortByChannelConnectionId));
        const jsonOldData = JSON.stringify(transformConnectionFromApiToUsed(connectionSecretary).sort(compareSortByChannelConnectionId));
        const jsonInitialData = JSON.stringify(initialConnections.sort(compareSortByChannelConnectionId));

        const dataPost = {
            connections: newConnections,
            secretaryType: typeSecretary,
            carId: carIdCollect
        };

        clientId && !(jsonNewData === jsonOldData) && !(jsonInitialData === jsonNewData) && postSecretaryInfo(clientId, dataPost);
    };

    return (
        <div className={style.secretaryWrapper}>
            {
                typeSecretary === 'mileage'
                    ? dataInner
                    : (
                        <SecretaryInner
                            clientId={clientId}
                            carIdCollect={carIdCollect}
                            typeSecretary={typeSecretary}
                            handleUpdateSecretaryInfo={handleUpdateSecretaryInfo}
                            connections={connections}
                            handleChangeOrder={handleChangeOrder}
                            hasOccasion={hasOccasion}
                            occasionData={occasionData}
                            isRadioButtonConnection={isRadioButtonConnection}
                            occasions={occasions}
                            setConnections={setConnections}
                        />
                    )
            }
        </div>
    );
};

SecretaryWrapper.propTypes = {
    occasionData: PropTypes.array,
    isRadioButtonConnection: PropTypes.bool,
    secretaryMileageConnections: PropTypes.array,
    secretaryCommonConnections: PropTypes.array,
    clientId: PropTypes.number,
    getSecretaryInfo: PropTypes.func,
    postSecretaryInfo: PropTypes.func,
    typeSecretary: PropTypes.string,
    hasOccasion: PropTypes.bool,
    carIdCollect: PropTypes.number,
    dataInner: PropTypes.node
};

const mapStateToProps = ({ secretary: { secretaryMileageConnections, secretaryCommonConnections } }) => {
    return {
        secretaryMileageConnections,
        secretaryCommonConnections
    };
};

export default connect(mapStateToProps, { postSecretaryInfo, getSecretaryInfo })(SecretaryWrapper);
