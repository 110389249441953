import {
    typeCarDetailsErrorUploaded,
    typeCarDetailsUploaded,
    typeCarIdCollectLoaded,
    typeCarImageAdded,
    typeCarImageDeleted,
    typeCarImagesAdded,
    typeCarImageUpdated,
    typeCarInsuranceDeadlineUpdate,
    typeCarInsuranceDeadlineUpdateError,
    typeCarInsuranceUpdate,
    typeCarInsuranceUpdateError,
    typeCarOccupancyRateUpdate,
    typeChangeCarChangingImagesIds,
    typeChangeCarSettingImagesCount,
    typeChangeSavingMainImageCarsIds,
    typeFetchCarDetailsSuccess,
    typeGetCarDetailsLoaded,
    typeSelectedIdCarLoaded,
    typeSetShowAnchors,
    typeSetShowSecretary,
    typeRequestCarMileage,
    typeReceiveCarMileage,
    typeCalculateMileageInMiles,
    typeResetCarMileage,
    typeCarInterestsUpdate,
    typeCarReasonsUpdate,
    typeCarReasonsUpdateError,
    typeCarInterestsUpdateError,
    typeCarOilUpdateError,
    typeCarOilUpdate,
    typeCarOilMonthsUpdate,
    typeCarOilMonthsUpdateError,
    typeCarTiresUpdate,
    typeCarTiresUpdateError,
    typeCarClientSeasonalPreferenceUpdate,
    typeCarClientSeasonalPreferenceUpdateError,
    typeResetCarId,
    typeResetCarCollectId,
    typeCarOilIsForTimeLoadSuccess,
    typeCarIsExactlyOilLastMileageSuccess,
    typeCarIsExactlyOilLastTimeSuccess,
    typeCarIsReShoeTiresSuccess,
    typeCarIsDoWheelAlignmentSuccess,
    typeCarPostInterestsSuccess,
    typeGetAllResources,
    typeSetAllResourcesError,
    typeSetAllResourcesLoading,
    typeSetResource,
    typeToggleResourceFilter,
    typeChangeFlagDoNotCallClient,
    typeErrorSetFlagDoNotCallClient,
    typeSetFlagDoNotCallClientSuccess,
    typeUpdateDataOilSuccess,
    typeDeleteClientFromCar,
    typeSetNewCarClientStatuses,
    typeChangeNewCallClient,
    typeClientIdCollectSet,
    typeCarInterestDelete,
    typeCarDetailsSuccess,
    typeCarDetailsError, typeSetMileageAt
} from '../actionsTypes';
import _ from 'lodash';

import { MILES_IN_KILOMETERS } from 'utils/constants';
import { OBJECT_STATUS_UNKNOWN, STATUS_MAIN_USER, STATUS_OWNER, STATUS_UNKNOWN } from '../constants/statusesCarClient';

const getNewCarsCache = (carsWithDetailsCache, carDetailsInfoNew) => {
    let carsCacheNew = _.cloneDeep(carsWithDetailsCache);
    const index = carsCacheNew.findIndex(car => car.id === carDetailsInfoNew.id);
    carsCacheNew = [
        ...carsCacheNew.slice(0, index),
        carDetailsInfoNew,
        ...carsCacheNew.slice(index + 1)
    ];

    return carsCacheNew;
};

const carDetails = (state, action) => {
    let carChangingImagesIds,
        carDetailsInfo,
        index,
        idxOldOwner,
        oldImgIndex,
        newImgIndex,
        carDetailsInfoNew,
        carSettingImagesCount,
        savingMainImageCarsIds,
        idx,
        carMileage,
        fetchingCarMileage,
        carsWithDetailsCacheNew,
        isOwner,
        idxOldMainUser,
        isMainUser;

    if (state === undefined) {
        return {
            carId: 0,
            carIdCollect: 0,
            clientIdCollect: 0,
            carDetailsInfo: {},
            carsWithDetailsCache: [],
            carDetailsInfoError: {},
            carInsuranceUpdateError: false,
            carInsuranceDeadlineUpdateError: false,
            fetchCarDetailsSuccess: false,
            showAnchors: true,
            showSecretary: true,
            initialUsers: [
                { status: 'fizUser', userId: 0, fio: '', phone: '' },
                { status: 'fizContact', userId: 0, fio: '', phone: '' },
                { status: 'fizLpr', userId: 0, fio: '', phone: '' },
                { status: 'fizMainUser', userId: 0, fio: '', phone: '' },
                { status: 'urContact', userId: 0, fio: '', phone: '' }
            ],
            occupancyRateBlocks: [
                { id: 0, car_id: 0, car_collect_block_id: 0, car_collect_block: { id: 0, parent_block_id: 0, label: 'casco' }, state_block: 'empty' },
                { id: 0, car_id: 0, car_collect_block_id: 0, car_collect_block: { id: 0, parent_block_id: 0, label: 'casco' }, state_block: 'some_collected' },
                { id: 0, car_id: 0, car_collect_block_id: 0, car_collect_block: { id: 0, parent_block_id: 0, label: 'casco' }, state_block: 'failed_collect' },
                { id: 0, car_id: 0, car_collect_block_id: 0, car_collect_block: { id: 0, parent_block_id: 0, label: 'casco' }, state_block: 'fully' }
            ],
            carChangingImagesIds: [],
            carSettingImagesCount: 0,
            savingMainImageCarsIds: [],
            carMileage: {},
            fetchingCarMileage: false,
            carReasonsUpdateError: false,
            carInterestsUpdateError: false,
            carOilUpdateSuccess: false,
            carOilUpdateError: false,
            carOilMonthsUpdateError: false,
            carTiresUpdateError: false,
            carClientSeasonalPreferenceUpdateError: false,
            carOilIsForTimeLoadSuccess: false,
            carIsExactlyOilLastMileageSuccess: false,
            carIsExactlyOilLastTimeSuccess: false,
            carIsReShoeTiresSuccess: false,
            carIsDoWheelAlignmentSuccess: false,
            carPostInterestsSuccess: { status: false, blockId: 0 },
            resources: [],
            resourcesLoading: false,
            resourcesError: false,
            selectedResource: [],
            toggleResourceSelect: false,
            flagDoNotCallClientError: false,
            flagDoNotCallClientSuccess: false,
            carSvBookSuccess: false,
            carSvBookError: false
        };
    }

    const { carDetailsState } = state;

    switch (action.type) {
    case typeSelectedIdCarLoaded:
        return {
            ...carDetailsState,
            carId: action.payload
        };

    case typeGetCarDetailsLoaded:
        if (carDetailsState.carId && (action.payload.id === carDetailsState.carId)) {
            return {
                ...carDetailsState,
                carDetailsInfo: action.payload
            };
        }
        return {
            ...carDetailsState
        };

    case typeCarDetailsSuccess:
        return {
            ...carDetailsState,
            carSvBookSuccess: action.payload
        };

    case typeCarDetailsError:
        return {
            ...carDetailsState,
            carSvBookError: action.payload
        };

    case typeCarIdCollectLoaded:
        return {
            ...carDetailsState,
            carIdCollect: action.payload
        };

    case typeClientIdCollectSet:
        return {
            ...carDetailsState,
            clientIdCollect: action.payload
        };

    case typeFetchCarDetailsSuccess:
        return {
            ...carDetailsState,
            fetchCarDetailsSuccess: action.payload
        };

    case typeSetShowAnchors:
        return {
            ...carDetailsState,
            showAnchors: action.payload
        };

    case typeSetShowSecretary:
        return {
            ...carDetailsState,
            showSecretary: action.payload
        };

    case typeCarImageUpdated:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        oldImgIndex = _.findIndex(carDetailsInfo.photo, ['is_main', 1]);
        newImgIndex = _.findIndex(carDetailsInfo.photo, ['id', action.payload.id]);
        carChangingImagesIds = [...carDetailsState.carChangingImagesIds];
        index = _.findIndex(carChangingImagesIds, item => item === action.payload.id);

        if (carDetailsState.carId === action.payload.pivot.car_id) {
            if (oldImgIndex !== -1) {
                carDetailsInfo.photo[oldImgIndex].is_main = 0;
            }
            if (newImgIndex !== -1) {
                carDetailsInfo.photo[newImgIndex].is_main = 1;
            }
        }

        if (index !== -1) {
            carChangingImagesIds.splice(index, 1);
        }

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfo);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo,
            carChangingImagesIds: carChangingImagesIds,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarImageDeleted:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        carChangingImagesIds = [...carDetailsState.carChangingImagesIds];
        index = _.findIndex(carChangingImagesIds, item => item === action.payload);

        if (index !== -1) {
            carChangingImagesIds.splice(index, 1);
        }

        _.remove(carDetailsInfo.photo, ['id', action.payload]);

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfo);
        // ----create new cache
        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo,
            carChangingImagesIds: carChangingImagesIds,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarImagesAdded:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);

        if (carDetailsState.carDetailsInfo.id === action.payload.carId) {
            carDetailsInfo.photo = carDetailsInfo.photo.concat(action.payload.images);
        }

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfo);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo,
            carSettingImagesCount: Math.max(carDetailsState.carSettingImagesCount - action.payload.count, 0),
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeChangeCarChangingImagesIds:
        carChangingImagesIds = [...carDetailsState.carChangingImagesIds];
        index = _.findIndex(carChangingImagesIds, item => item === action.payload);

        if (index === -1) {
            carChangingImagesIds.push(action.payload);
        } else {
            carChangingImagesIds.splice(index, 1);
        }

        return {
            ...carDetailsState,
            carChangingImagesIds: carChangingImagesIds
        };

    case typeCarDetailsUploaded: // car details from collect block
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        if (carDetailsState.carIdCollect) {
            if (action.payload.id === carDetailsState.carIdCollect) {
                return {
                    ...carDetailsState,
                    carDetailsInfo: action.payload,
                    occupancyRateBlocks: action.payload.occupancyRateBlocks
                };
            }
        } else {
            return {
                ...carDetailsState,
                carDetailsInfo: action.payload,
                occupancyRateBlocks: action.payload.occupancyRateBlocks
            };
        }

        return {
            ...carDetailsState
        };

    case typeCarDetailsErrorUploaded:
        return {
            ...carDetailsState,
            carDetailsInfoError: action.payload
        };

    case typeChangeCarSettingImagesCount:
        if (action.payload.add) {
            carSettingImagesCount = carDetailsState.carSettingImagesCount + action.payload.count;
        } else {
            carSettingImagesCount = carDetailsState.carSettingImagesCount - action.payload.count;
        }

        return {
            ...carDetailsState,
            carSettingImagesCount: Math.max(carSettingImagesCount, 0)
        };

    case typeCarImageAdded:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        savingMainImageCarsIds = [...carDetailsState.savingMainImageCarsIds];
        index = _.findIndex(savingMainImageCarsIds, item => item === action.payload.carId);

        if (index === -1) {
            savingMainImageCarsIds.push(action.payload.carId);
        } else {
            savingMainImageCarsIds.splice(index, 1);
        }

        if (carDetailsState.carDetailsInfo.id === action.payload.carId) {
            if (action.payload.image.is_main) {
                carDetailsInfo.photo = _.map(carDetailsInfo.photo, photo => {
                    photo.is_main = 0;

                    return photo;
                });
            }

            carDetailsInfo.photo.push(action.payload.image);
        }

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfo);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo,
            savingMainImageCarsIds: savingMainImageCarsIds,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeChangeSavingMainImageCarsIds:
        savingMainImageCarsIds = [...carDetailsState.savingMainImageCarsIds];
        index = _.findIndex(savingMainImageCarsIds, item => item === action.payload);

        if (index === -1) {
            savingMainImageCarsIds.push(action.payload);
        } else {
            savingMainImageCarsIds.splice(index, 1);
        }

        return {
            ...carDetailsState,
            savingMainImageCarsIds: savingMainImageCarsIds
        };

    case typeCarInsuranceDeadlineUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (action.payload.id !== carDetailsState.carIdCollect) {
            return {
                ...carDetailsState,
                carsWithDetailsCache: []
            };
        }
        carDetailsInfoNew.insurance_deadline = action.payload.insurance_deadline;

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeSetMileageAt:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);
        carDetailsInfoNew.mileage_at = action.payload.mileage_at;
        carDetailsInfoNew.mileage = action.payload.mileage;

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew
        };

    case typeCarOccupancyRateUpdate:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        carDetailsInfo.occupancyRateBlocks = action.payload;

        return {
            ...carDetailsState,
            carDetailsInfo,
            occupancyRateBlocks: action.payload
        };

    case typeCarInsuranceDeadlineUpdateError:
        return {
            ...carDetailsState,
            carInsuranceDeadlineUpdateError: action.payload
        };

    case typeCarInsuranceUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (carDetailsInfoNew.id !== action.payload.car_id) {
            return {
                ...carDetailsState
            };
        }

        carDetailsInfoNew = {
            ...carDetailsInfoNew,
            has_casco: action.payload.has_casco || false,
            cost_casco: action.payload.cost_casco || 0,
            date_end_driver_license: action.payload.date_end_driver_license || null,
            date_end_casco: action.payload.date_end_casco || null,
            company_osago_id: action.payload.company_osago_id || null,
            company_casco_id: action.payload.company_casco_id || null
        };

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew
        };

    case typeCarInsuranceUpdateError:
        return {
            ...carDetailsState,
            carInsuranceUpdateError: action.payload
        };

    case typeCarInterestsUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        if (carDetailsInfoNew.car_selected_interests) {
            idx = carDetailsInfoNew.car_selected_interests.findIndex((item) => item.id === action.payload.id);

            if (idx === -1) {
                carDetailsInfoNew.car_selected_interests = [
                    ...carDetailsInfoNew.car_selected_interests,
                    action.payload
                ];

                // create new cache
                carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
                // ----create new cache

                return {
                    ...carDetailsState,
                    carDetailsInfo: carDetailsInfoNew,
                    carsWithDetailsCache: carsWithDetailsCacheNew
                };
            }

            carDetailsInfoNew.car_selected_interests = [
                ...carDetailsInfoNew.car_selected_interests.slice(0, idx),
                action.payload,
                ...carDetailsInfoNew.car_selected_interests.slice(idx + 1)
            ];
        } else {
            carDetailsInfoNew.car_selected_interests = [
                action.payload
            ];
        }

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarInterestDelete:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo); // ToDo NEED refactoring

        if (carDetailsInfoNew.car_selected_interests) {
            idx = carDetailsInfoNew.car_selected_interests.findIndex((item) => {
                if (action.payload.dataPush.car_collect_block_id === 8) {
                    return (item.client_id === action.payload.dataPush.client_id) && (item.car_collect_block_id === action.payload.dataPush.car_collect_block_id);
                } else {
                    return (item.car_id === action.payload.dataPush.car_id) && (item.car_collect_block_id === action.payload.dataPush.car_collect_block_id);
                }
            });

            if (idx !== -1) {
                carDetailsInfoNew.car_selected_interests = [
                    ...carDetailsInfoNew.car_selected_interests.slice(0, idx),
                    ...carDetailsInfoNew.car_selected_interests.slice(idx + 1)
                ];
            }
        }
        if (carDetailsInfoNew.car_block_reasons) {
            carDetailsInfoNew.car_block_reasons = carDetailsInfoNew.car_block_reasons.filter(item => {
                if (action.payload.dataPush.car_collect_block_id === 8) {
                    return (item.client_id === action.payload.dataPush.client_id) && (item.block_interest_reasons && item.block_interest_reasons.car_collect_block_id !== action.payload.dataPush.car_collect_block_id);
                } else {
                    return (item.car_id === action.payload.dataPush.car_id) && (item.block_interest_reasons && item.block_interest_reasons.car_collect_block_id !== action.payload.dataPush.car_collect_block_id);
                }
            });
        }

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew
        };

    case typeCarReasonsUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        if (carDetailsInfoNew.id !== action.payload.car_id) {
            return {
                ...carDetailsState,
                carsWithDetailsCache: []
            };
        }

        if (carDetailsInfoNew.car_block_reasons) {
            idx = carDetailsInfoNew.car_block_reasons.findIndex((item) => item.id === action.payload.id);
            if (idx === -1) {
                carDetailsInfoNew.car_block_reasons = [
                    ...carDetailsInfoNew.car_block_reasons,
                    action.payload
                ];

                // create new cache
                carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
                // ----create new cache

                return {
                    ...carDetailsState,
                    carDetailsInfo: carDetailsInfoNew,
                    carsWithDetailsCache: carsWithDetailsCacheNew
                };
            }
            carDetailsInfoNew.car_block_reasons = [
                ...carDetailsInfoNew.car_block_reasons.slice(0, idx),
                action.payload,
                ...carDetailsInfoNew.car_block_reasons.slice(idx + 1)
            ];
        } else {
            carDetailsInfoNew.car_block_reasons = [
                action.payload
            ];
        }

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarReasonsUpdateError:
        return {
            ...carDetailsState,
            carReasonsUpdateError: action.payload
        };

    case typeCarInterestsUpdateError:
        return {
            ...carDetailsState,
            carInterestsUpdateError: action.payload
        };

    case typeCarOilUpdateError:
        return {
            ...carDetailsState,
            carOilUpdateError: action.payload
        };

    case typeUpdateDataOilSuccess:
        return {
            ...carDetailsState,
            carOilUpdateSuccess: action.payload
        };

    case typeCarOilUpdate:

        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        carDetailsInfoNew = {
            ...carDetailsInfoNew,
            is_for_time: action.payload.is_for_time,
            mileage_next_change: action.payload.mileage_next_change,
            mark_motor_oil_field_ref_id: action.payload.mark_motor_oil_field_ref_id,
            viscosity_oil_field_ref_id: action.payload.viscosity_oil_field_ref_id,
            type_oil_field_ref_id: action.payload.type_oil_field_ref_id,
            expendable_materials_oil_field_ref_id: action.payload.expendable_materials_oil_field_ref_id,
            washing_oil_field_ref_id: action.payload.washing_oil_field_ref_id,
            protection_oil_field_ref_id: action.payload.protection_oil_field_ref_id,
            car_oil: action.payload,
            is_last_mileage: (!!action.payload.is_last_mileage && +action.payload.is_last_mileage) || 0,
            oil_mileage: action.payload.oil_mileage || null
        };

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew
        };

    case typeCarOilMonthsUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        if ((action.payload.length > 0) && (carDetailsState.carDetailsInfo.id !== action.payload[0].car_id)) {
            return {
                ...carDetailsState,
                carsWithDetailsCache: []
            };
        }

        if (action.payload.length === 0) {
            carDetailsInfoNew.car_change_oil_months = null;

            // create new cache
            carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
            // ----create new cache*/

            return {
                ...carDetailsState,
                carDetailsInfo: carDetailsInfoNew,
                carsWithDetailsCache: carsWithDetailsCacheNew
            };
        }

        carDetailsInfoNew.car_change_oil_months = action.payload;

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarOilMonthsUpdateError:
        return {
            ...carDetailsState,
            carOilMonthsUpdateError: action.payload
        };

    case typeCarTiresUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);

        if (carDetailsState.carDetailsInfo.id !== action.payload.car_id) {
            return {
                ...carDetailsState,
                carsWithDetailsCache: []
            };
        }
        carDetailsInfoNew = {
            ...carDetailsInfoNew,
            is_re_shoe: action.payload.is_re_shoe,
            is_do_wheel_alignment: action.payload.is_do_wheel_alignment
        };

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarTiresUpdateError:
        return {
            ...carDetailsState,
            carTiresUpdateError: action.payload
        };

    case typeCarClientSeasonalPreferenceUpdate:
        carDetailsInfoNew = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (action.payload && action.payload.length === 0) {
            return {
                ...carDetailsState,
                carDetailsInfo: { ...carDetailsInfoNew, car_client_seasonal_preference: [] }
            };
        }

        if (carDetailsState.carDetailsInfo.id !== (action.payload && (action.payload.length > 0) && action.payload[0].car_id)) {
            return {
                ...carDetailsState,
                carsWithDetailsCache: []
            };
        }
        carDetailsInfoNew.car_client_seasonal_preference = action.payload;

        // create new cache
        carsWithDetailsCacheNew = getNewCarsCache(carDetailsState.carsWithDetailsCache, carDetailsInfoNew);
        // ----create new cache

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfoNew,
            carsWithDetailsCache: carsWithDetailsCacheNew
        };

    case typeCarClientSeasonalPreferenceUpdateError:
        return {
            ...carDetailsState,
            carClientSeasonalPreferenceUpdateError: action.payload
        };

    case typeRequestCarMileage:
        return {
            ...carDetailsState,
            fetchingCarMileage: true
        };

    case typeReceiveCarMileage:
        if (carDetailsState.carId.toString() === action.payload.carId) {
            carMileage = action.payload.res;
            carMileage.carId = action.payload.carId;
            fetchingCarMileage = false;
        } else {
            carMileage = carDetailsState.carMileage;
            fetchingCarMileage = carDetailsState.fetchingCarMileage;
        }

        return {
            ...carDetailsState,
            carMileage: carMileage,
            fetchingCarMileage: fetchingCarMileage
        };

    case typeCalculateMileageInMiles:
        // carMileage = Object.assign({}, carDetailsState.carMileage);
        carMileage = { ...carDetailsState.carMileage };
        carMileage.inMiles = _.cloneDeep(carMileage);

        _.each(carMileage.inMiles.average, (item, key) => {
            if (item) {
                carMileage.inMiles.average[key] = parseInt(item * MILES_IN_KILOMETERS);
            }
        });

        _.each(carMileage.inMiles.history, (history) => {
            if (_.get(history, 'length')) {
                _.each(history, (item) => {
                    item.value = parseInt(item.value * MILES_IN_KILOMETERS); // some mutations muhahahahahaah
                });
            }
        });

        return {
            ...carDetailsState,
            carMileage: carMileage
        };

    case typeResetCarMileage:
        return {
            ...carDetailsState,
            carMileage: {},
            fetchingCarMileage: false
        };

    case typeResetCarId:
        return {
            ...carDetailsState,
            carId: 0
        };

    case typeResetCarCollectId:
        return {
            ...carDetailsState,
            carIdCollect: 0,
            clientIdCollect: 0
        };

    case typeCarOilIsForTimeLoadSuccess:
        return {
            ...carDetailsState,
            carOilIsForTimeLoadSuccess: action.payload
        };

    case typeCarIsExactlyOilLastMileageSuccess:
        return {
            ...carDetailsState,
            carIsExactlyOilLastMileageSuccess: action.payload
        };

    case typeCarIsExactlyOilLastTimeSuccess:
        return {
            ...carDetailsState,
            carIsExactlyOilLastTimeSuccess: action.payload
        };

    case typeCarIsReShoeTiresSuccess:
        return {
            ...carDetailsState,
            carIsReShoeTiresSuccess: action.payload
        };

    case typeCarIsDoWheelAlignmentSuccess:
        return {
            ...carDetailsState,
            carIsDoWheelAlignmentSuccess: action.payload
        };

    case typeCarPostInterestsSuccess:
        return {
            ...carDetailsState,
            carPostInterestsSuccess: action.payload
        };

    case typeGetAllResources:
        return {
            ...carDetailsState,
            resources: action.payload
        };

    case typeSetAllResourcesError:
        return {
            ...carDetailsState,
            resourcesError: action.payload
        };

    case typeSetAllResourcesLoading:
        return {
            ...carDetailsState,
            resourcesLoading: action.payload
        };

    case typeSetResource:
        return {
            ...carDetailsState,
            selectedResource: action.payload
        };

    case typeToggleResourceFilter:
        return {
            ...carDetailsState,
            toggleResourceSelect: action.payload
        };

    case typeErrorSetFlagDoNotCallClient:
        return {
            ...carDetailsState,
            flagDoNotCallClientError: action.payload
        };

    case typeSetFlagDoNotCallClientSuccess:
        return {
            ...carDetailsState,
            flagDoNotCallClientSuccess: action.payload
        };

    case typeChangeFlagDoNotCallClient:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (carDetailsInfo.clients && carDetailsInfo.clients.length > 0) {
            idx = carDetailsInfo.clients.findIndex(item => item.id === action.payload.id);
            if (~idx) {
                carDetailsInfo.clients[idx]['do-not-call'] = action.payload.do_not_call;
            } else {
                carDetailsInfo.clients = [
                    ...carDetailsInfo.clients,
                    action.payload
                ];
            }
        }

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo
        };

    case typeChangeNewCallClient:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (carDetailsInfo.clients && carDetailsInfo.clients.length > 0) {
            idx = carDetailsInfo.clients.findIndex(item => item.id === action.payload.id);
            idxOldOwner = carDetailsInfo.clients.findIndex(item => item.statuses.find(status => status.label === STATUS_OWNER));
            idxOldMainUser = carDetailsInfo.clients.findIndex(item => item.statuses.find(status => status.label === STATUS_MAIN_USER));
            if (~idx) {
                carDetailsInfo.clients[idx] = action.payload;
                carDetailsInfo.statuses = [
                    ...carDetailsInfo.statuses.filter(item => item.client_id === action.payload.id),
                    ...action.payload?.statuses?.map(status => {
                        return { car_id: carDetailsInfo.id, client_id: action.payload.id, client_status: status };
                    })
                ];
            } else {
                carDetailsInfo.clients = [
                    ...carDetailsInfo.clients,
                    action.payload
                ];

                carDetailsInfo.statuses = [
                    ...carDetailsInfo.statuses,
                    ...action.payload?.statuses?.map(status => {
                        return { car_id: carDetailsInfo.id, client_id: action.payload.id, client_status: status };
                    })
                ];
            }
            isOwner = action.payload.statuses.find(item => item.label === STATUS_OWNER);
            if (isOwner && ~idxOldOwner && (action.payload?.id !== carDetailsInfo?.clients[idxOldOwner]?.id)) {
                // удаляем статус owner со старого владельца
                carDetailsInfo.clients[idxOldOwner].statuses = carDetailsInfo.clients[idxOldOwner].statuses.filter(status => status.label !== STATUS_OWNER);
                // удаляем клиентов с пустыми статусами
            }
            isMainUser = action.payload.statuses.find(item => item.label === STATUS_MAIN_USER);
            if (isMainUser && ~idxOldMainUser && (action.payload?.id !== carDetailsInfo?.clients[idxOldMainUser]?.id)) {
                // удаляем статус main_user со старого владельца
                carDetailsInfo.clients[idxOldMainUser].statuses = carDetailsInfo.clients[idxOldMainUser].statuses.filter(status => status.label !== STATUS_MAIN_USER);
                // удаляем клиентов с пустыми статусами
            }
        }

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo
        };

    case typeDeleteClientFromCar:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);
        if (carDetailsInfo.clients && carDetailsInfo.clients.length > 0) {
            idx = carDetailsInfo.clients.findIndex(item => item.id === action.payload);
            if (~idx) {
                carDetailsInfo.clients = [
                    ...carDetailsInfo.clients.slice(0, idx),
                    ...carDetailsInfo.clients.slice(idx + 1)
                ];
            }
        }

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo
        };

    case typeSetNewCarClientStatuses:
        carDetailsInfo = _.cloneDeep(carDetailsState.carDetailsInfo);

        if ((carDetailsInfo.id === action.payload.carId) && carDetailsInfo.clients && (carDetailsInfo.clients.length > 0)) {
            idx = carDetailsInfo.clients.findIndex(item => item.id === action.payload.clientId);
            if (~idx) {
                carDetailsInfo.clients[idx].statuses = action.payload.statuses;

                if (action.payload.statuses.find(item => item.label === STATUS_OWNER)) {
                    carDetailsInfo.clients.forEach(item => {
                        if (item.id !== action.payload.clientId) {
                            item.statuses = Array.isArray(item.statuses) ? item.statuses.filter(status => status.label !== STATUS_OWNER) : [];
                        }
                    });
                    carDetailsInfo.statuses = carDetailsInfo.statuses.filter(carClient => {
                        return !(carClient.client_id !== action.payload.clientId && carClient.client_status.label === STATUS_OWNER);
                    });
                }

                if (action.payload.statuses.find(item => item.label === STATUS_MAIN_USER)) {
                    carDetailsInfo.clients.forEach(item => {
                        if (item.id !== action.payload.clientId) {
                            item.statuses = Array.isArray(item.statuses) ? item.statuses.filter(status => status.label !== STATUS_MAIN_USER) : [];
                        }
                    });

                    carDetailsInfo.statuses = carDetailsInfo.statuses.filter(carClient => {
                        return !(carClient.client_id !== action.payload.clientId && carClient.client_status.label === STATUS_MAIN_USER);
                    });
                }
            }
        }

        if (action.payload?.statuses?.length === 0) {
            carDetailsInfo.statuses = [...carDetailsInfo.statuses?.filter(status => {
                return (status.client_id !== action.payload.clientId) || ((status.client_id === action.payload.clientId) && status.client_status?.label === STATUS_UNKNOWN);
            })];
            const clientWithUnknown = carDetailsInfo.statuses.find(client => client.client_id === action.payload.clientId);
            if (!clientWithUnknown) {
                carDetailsInfo.statuses.push({ client_id: action.payload.clientId, car_id: action.payload.carId, client_status: OBJECT_STATUS_UNKNOWN });
            }
        } else if (action.payload?.statuses?.length > 0) {
            carDetailsInfo.statuses = [
                ...carDetailsInfo.statuses?.filter(item => item.client_id !== action.payload.clientId),
                ...action.payload?.statuses.map(status => {
                    return { car_id: action.payload.carId, client_id: action.payload.clientId, client_status: status };
                })
            ];
        }

        return {
            ...carDetailsState,
            carDetailsInfo: carDetailsInfo
        };

    default:
        return carDetailsState;
    }
};

export default carDetails;
