import React from 'react';
import PropTypes from 'prop-types';
import style from './OwnerSettingsBody.module.scss';
import { Link } from 'react-router-dom';
import BtnActionsRow from '../components/BtnActionsRow';

const RowOwners = ({ user, deleteOwner }) => {
    const handleDelete = (id) => {
        deleteOwner(id);
    };

    const link = `/settings/owner/${user.id}`;

    return (
        <tr key={user.id} className={style.tr}>
            <td>{ user.id }</td>
            <td><Link to={link}>{ user.name }</Link></td>
            <td>{ user.phone }</td>
            <td>{ user.role2.roleLabel }</td>
            <td>
                <BtnActionsRow link={link} handleClickTrash={() => handleDelete(user.id)} del upd />
            </td>
        </tr>
    );
};

RowOwners.propTypes = {
    user: PropTypes.object,
    deleteOwner: PropTypes.func
};

export default RowOwners;
