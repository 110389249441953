import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../Loader';

import style from './SetInterestsButtons.module.scss';

const SetInterestsButtons = ({ nameBlock, carCollectBlocks, handleUpdateBtnInterest, carDetailsInfo, blockId, carPostInterestsSuccess }) => {
    const [selected, setSelected] = useState(null);

    const initialSelectedInterest = useMemo(() => (carDetailsInfo.car_selected_interests ? carDetailsInfo.car_selected_interests.filter((item) => {
        return item.car_collect_block_id === blockId;
    }) : null), [blockId, carDetailsInfo]);

    const allBtn = useMemo(() => (carCollectBlocks ? (carCollectBlocks.clientServiceInterests ? carCollectBlocks.clientServiceInterests : null) : null), [carCollectBlocks]);
    const resBtn = allBtn
        ? allBtn.filter((item, index) => {
            return (item.title === 'Интересно') || (item.title === 'Сомнение') || (item.title === 'Отказ');
        })
        : null;

    const styleArr = [style.first, style.center, style.right];

    useEffect(() => {
        setSelected((initialSelectedInterest ? (initialSelectedInterest.length > 0 ? initialSelectedInterest[0].client_service_interest_id : null) : null));
    }, [initialSelectedInterest]);

    const choiceButton = (id, event) => {
        handleUpdateBtnInterest(id);
        setSelected(id);
        document.getElementById(`interest_${id}_${blockId}`).classList.remove(`${style.chooseBtn}`);
        event.currentTarget.classList.add(style.chooseBtn);
    };

    const btns = resBtn ? resBtn.map((btn, index) => {
        const styleSelect = style.chooseBtn;
        const classNamesAdded = `${styleArr[index]} ${styleSelect}`;
        const classNamesRemoved = `${styleArr[index]}`;

        return (
            <div
                key={index}
                className={(btn.id === selected) ? classNamesAdded : classNamesRemoved}
                onClick={(e) => choiceButton(btn.id, e)}
                id={`interest_${btn.id}_${blockId}`}
            >
                { btn.title }
            </div>
        );
    }) : (<div>Loading... </div>);

    return (
        <div className={style.interest} key={blockId}>
            <div className={style.labelChoiceInterest}>{ nameBlock }</div>
            <div className={style.buttonsInterest}>
                {
                    carPostInterestsSuccess.status && (carPostInterestsSuccess.blockId === blockId)
                        ? <div className={style.btnLoad}><Loader active={carPostInterestsSuccess.status} classes={['small', 'light']} /></div>
                        : ''
                }
                {
                    btns && btns
                }
            </div>
        </div>
    );
};

SetInterestsButtons.propTypes = {
    blockId: PropTypes.number,
    nameBlock: PropTypes.string,
    carCollectBlocks: PropTypes.object,
    handleUpdateBtnInterest: PropTypes.func,
    carDetailsInfo: PropTypes.object,
    carPostInterestsSuccess: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, carPostInterestsSuccess }, templatesOfFrameState: { carCollectBlocks } }) => {
    return {
        carCollectBlocks,
        carDetailsInfo,
        carPostInterestsSuccess
    };
};

export default connect(mapStateToProps)(SetInterestsButtons);
