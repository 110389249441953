import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './OilsIsExactlyLastTime.module.scss';
import { fetchPostCarOil } from 'actions';
import ButtonsCommon from '../../../../../../components/Buttons/ButtonsCommon';

const OilsIsExactlyLastMileage = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fieldNamePiece,
    fetchPostCarOil,
    carOilUpdateError,
    carIsExactlyOilLastMileageSuccess
}) => {
    const fieldName = `is_exactly_${fieldNamePiece}_mileage`;
    const [value, setValue] = useState(carDetailsInfo[fieldName]);

    useEffect(() => {
        setValue(carDetailsInfo[fieldName]);
    }, [carDetailsInfo, fieldName]);

    const handleUpdate = (value, fieldName) => {
        const post = {
            car_id: id,
            [fieldName]: +value
        };

        id && !carIsExactlyOilLastMileageSuccess && fetchPostCarOil(post, clientIdCollect);
    };

    const handleClick = (itBool) => {
        setValue(itBool);
        handleUpdate(itBool, fieldName);
    };

    return (
        <div className={style.btnOilSmall}>
            <span className={style.textBefore}>Пробег</span>
            <ButtonsCommon
                key={`key${fieldName}`}
                btnSelected={!!value}
                countButtons={2}
                dataSuccess={carIsExactlyOilLastMileageSuccess}
                handleClick={handleClick}
                isError={carOilUpdateError}
            />
        </div>
    );
};

OilsIsExactlyLastMileage.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldNamePiece: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    carIsExactlyOilLastMileageSuccess: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilUpdateError, carIsExactlyOilLastMileageSuccess, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        carIsExactlyOilLastMileageSuccess,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilsIsExactlyLastMileage);
