import React, { useState, useEffect } from 'react';
import ArrowHiddenContent from '../ArrowHiddenContent';
import PropTypes from 'prop-types';
import style from './SubHeaderCollect.module.scss';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

const SubHeaderCollect = ({
    title,
    handleSetHidden,
    blockId,
    stateBool,
    occupancyRateBlocks,
    children,
    anchors,
    actions,
    classes = [],
    blockId2 = false
}) => {
    const [stateBlock, setStateBlock] = useState('empty');
    const [styleMark, setStyleMark] = useState('roundMarkRed');

    useEffect(() => {
        setStateBlock('empty');
        !blockId2 && occupancyRateBlocks && occupancyRateBlocks.reduce((accum, item) => {
            (item.car_collect_block_id === blockId) && setStateBlock(item.state_block);
            return accum;
        }, {});

        const stateBlock1 = occupancyRateBlocks && occupancyRateBlocks.reduce((accum, item) => {
            const fully = (item.car_collect_block_id === blockId) && item.state_block;
            accum = fully || accum;
            return accum;
        }, 'empty');

        const stateBlock2 = occupancyRateBlocks && occupancyRateBlocks.reduce((accum, item) => {
            const fully = (item.car_collect_block_id === blockId2) && item.state_block;
            accum = fully || accum;
            return accum;
        }, 'empty');

        blockId2 && ((stateBlock1 === stateBlock2) ? setStateBlock(stateBlock1) : setStateBlock('some_collected'));
    }, [occupancyRateBlocks, blockId, blockId2]);

    useEffect(() => {
        setStyleMark('roundMarkRed');
        (stateBlock === 'empty') && setStyleMark('roundMarkRed');
        (stateBlock === 'some_collected') && setStyleMark('roundMarkOrange');
        (stateBlock === 'failed_collect') && setStyleMark('roundMarkRed');
        (stateBlock === 'fully') && setStyleMark('roundMarkGreen');
    }, [stateBlock, occupancyRateBlocks]);

    return (
        <div className={`${style.subheader} ${anchors ? style.anchors : ''}` + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.column}>
                <div className={style.row}>
                    <div className={style.startHead}>
                        <div className={style[styleMark]} />
                        <div className={style.textHeader}>
                            { title }
                        </div>
                        <div>
                            {
                                children
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.rightActions}>
                {
                    actions
                }
                <ArrowHiddenContent handleSetHidden={handleSetHidden} blockId={blockId} stateBool={stateBool} classes={actions && ['marginLeft10']} />
            </div>
        </div>
    );
};

SubHeaderCollect.propTypes = {
    title: PropTypes.string,
    blockId: PropTypes.number,
    stateBool: PropTypes.bool,
    anchors: PropTypes.bool,
    occupancyRateBlocks: PropTypes.array,
    handleSetHidden: PropTypes.func,
    blockId2: PropTypes.number,
    children: PropTypes.node,
    actions: PropTypes.node,
    classes: PropTypes.array
};

const mapStateToProps = ({ carDetailsState: { occupancyRateBlocks } }) => {
    return {
        occupancyRateBlocks
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(SubHeaderCollect);
