import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import style from './FieldLabelForm.module.scss';

const FieldLabelForm = ({ nameForm, valueField, toUpperCase, forUpdateField, isDisable }) => {
    const [valueForm, setValueForm] = useState('');

    useEffect(() => {
        setValueForm(valueField);
    }, [valueField]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const value = (valueForm === '') ? null : valueForm;
        forUpdateField(e, value);
    };

    const handleChange = (e) => {
        toUpperCase
            ? setValueForm(e.target.value.toUpperCase())
            : setValueForm(e.target.value || '');
    };

    return (
        <div>
            <form onSubmit={handleSubmit} onBlur={handleSubmit} className={style.formField}>
                <label>{ nameForm }
                    <input type='text' className={style.fieldInput} value={valueForm || ''} onChange={handleChange} disabled={isDisable} />
                </label>
            </form>
        </div>
    );
};

FieldLabelForm.defaultProps = {
    toUpperCase: false,
    valueField: ''
};

FieldLabelForm.propTypes = {
    nameForm: PropTypes.string.isRequired,
    valueField: PropTypes.any,
    toUpperCase: PropTypes.bool,
    forUpdateField: PropTypes.func,
    isDisable: PropTypes.bool
};

export default FieldLabelForm;
