import React from 'react';
import style from './HistoryReportBody.module.scss';
import PropTypes from 'prop-types';
import HistoryReportRow from '../HistoryReportRow';

const HistoryReportBody = ({ historyReports }) => {
    const historyRows = historyReports && historyReports.length > 0 ? historyReports.map((item) => {
        return (
            <HistoryReportRow historyReport={item} key={item.id} />
        );
    }) : <div className={style.notFound}>Ничего не найдено</div>;

    return (
        <div className={style.historyReportBody}>
            <div className={style.tableHeader}>
                <div className={style.rowOwner + ' ' + style.headerRowOwner} key='header'>
                    <div className={style.dataRow}>Дата время</div>
                    <div>Якорь</div>
                    <div className={style.flexEnd}>Значение в якоре</div>
                    <div>Эксперт</div>
                </div>
            </div>
            <div className={style.tableBody}>
                {
                    historyRows
                }
            </div>
        </div>
    );
};

HistoryReportBody.propTypes = {
    historyReports: PropTypes.array
};

export default HistoryReportBody;
