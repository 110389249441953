import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import style from './CheckBoxesCommon.module.scss';
import cb from '../CheckBoxes/assets/checked.svg';
import cbWhite from '../CheckBoxes/assets/checked_white.svg';

const CheckBoxCommon = ({ textLabel, handleChecked, stateChecked, blockInterestReasonId, withCheckBox, children, withStringId, darkMode, classes = [] }) => {
    const [isChecked, setIsChecked] = useState(false);
    const checked = useMemo(() => (<img src={darkMode ? cbWhite : cb} alt='checked icon' />), [darkMode]);

    const [styleCk, setStyleCk] = useState(isChecked ? checked : '');

    useEffect(() => {
        setIsChecked(stateChecked && stateChecked);
    }, [stateChecked]);

    useEffect(() => {
        setStyleCk(isChecked ? checked : '');
    }, [isChecked, checked]);

    const handleClick = (e) => {
        handleChecked(withStringId ? e.currentTarget.id : +e.currentTarget.id, !isChecked);
        setIsChecked((state) => !state);
    };

    return (
        <label className={style.labelCheckBox + classes.map(item => ' ' + style[item]).join('')} id={blockInterestReasonId} onClick={handleClick}>
            {
                withCheckBox
                    ? <div className={style.checkBox}>{ styleCk }</div>
                    : ''
            }
            { textLabel }
            {
                children || ''
            }
        </label>
    );
};

CheckBoxCommon.defaultProps = {
    textLabel: ''
};

CheckBoxCommon.propTypes = {
    textLabel: PropTypes.string,
    stateChecked: PropTypes.bool,
    withStringId: PropTypes.bool,
    darkMode: PropTypes.bool,
    handleChecked: PropTypes.func,
    children: PropTypes.node,
    blockInterestReasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    withCheckBox: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['paddingConnection', 'selectBgBlue', 'maxWidth125', 'blueLabel', 'forSecretary', 'greyWithDiv', 'paddingEmpty', 'paddingRight16', 'paddingLeft16', 'margin10', 'alignItemsEnd']))
};

export default CheckBoxCommon;
