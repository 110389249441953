import React from 'react';
import style from './HistoryReportRow.module.scss';
import { dateFromDateField } from '../../../../../../utils';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const HistoryReportRow = ({ historyReport }) => {
    return (
        <div className={style.rowOwner}>
            <div className={style.dataRow + ' ' + style.dataRowBody + ' ' + style.pairDescription}>
                <div>{ dateFromDateField(historyReport.createdAt) }</div>
                <div className={style.greyText}>{ format(new Date(historyReport.createdAt), 'hh:mm') }</div>
            </div>
            <div className={style.dataRowBody}>{ historyReport.carCollectBlockTitle }</div>
            <div className={style.pairDescription + ' ' + style.flexEnd}>
                <div>
                    {
                        historyReport.field[0].toUpperCase() + historyReport.field.slice(1, (historyReport.field.length))
                    }:
                </div>
                <div className={style.greyText}>
                    { historyReport.value }
                </div>
            </div>
            <div className={style.dataRowBody}>
                { historyReport.creator.name }
            </div>
        </div>
    );
};

HistoryReportRow.propTypes = {
    historyReport: PropTypes.object
};

export default HistoryReportRow;
