import carDetails from './carDetails';
import carsList from './carsList';
import templatesOfFrame from './templatesOfFrame';
import auth from './auth';
import common from './common';
import settings from './settings';
import visitors from './visitors';
import statusesClientCar from './statusesClientCar';
import car from './car';
import carOrders from './carOrders';
import sheetCollectInfo from './sheetCollectInfo';
import secretary from './secretary';
import connectionReducer from './connection';
import autoInsuranceReducer from './autoInsurance';
import settingsTires from './settingsTires';
import settingsSeasons from './settingsSeasons';
import preReserve from './preReserve';
import anchorsFrom1C from './anchorsFrom1C';
import reportsCollectionSheets from './reportsCollectionSheets';
import reportsProlongation from './reportsProlongation';
import reportsProlongationInAction from './reportsProlongationInAction';
import settingsOil from './settingsOil';
import prolongation from './prolongation';

const reducer = (state, action) => {
    return {
        common: common(state, action),
        auth: auth(state, action),
        carDetailsState: carDetails(state, action),
        carsListState: carsList(state, action),
        templatesOfFrameState: templatesOfFrame(state, action),
        settings: settings(state, action),
        visitors: visitors(state, action),
        statusesClientCar: statusesClientCar(state, action),
        car: car(state, action),
        carOrders: carOrders(state, action),
        sheetCollectInfo: sheetCollectInfo(state, action),
        secretary: secretary(state, action),
        connectionReducer: connectionReducer(state, action),
        autoInsuranceReducer: autoInsuranceReducer(state, action),
        settingsTires: settingsTires(state, action),
        settingsSeasons: settingsSeasons(state, action),
        preReserve: preReserve(state, action),
        anchorsFrom1C: anchorsFrom1C(state, action),
        reportsCollectionSheets: reportsCollectionSheets(state, action),
        reportsProlongation: reportsProlongation(state, action),
        reportsProlongationInAction: reportsProlongationInAction(state, action),
        settingsOil: settingsOil(state, action),
        prolongation: prolongation(state, action)
    };
};

export default reducer;
