import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getReportCollectionSheets } from '../../../../../../reducers/reportsCollectionSheets';
import { fetchOwners } from '../../../../../../reducers/settings';
import CollectInfoReportTable from '../CollectInfoReportTable';
import sortEmployeeByField from '../../../../../../utils/sortEmployeeByField';

const CollectInfoReportBody = ({ collectionSheets, getReportCollectionSheets, timeFrom, timeTo, collectionSheetSuccess }) => {
    useEffect(() => {
        getReportCollectionSheets({ time_from: timeFrom, time_to: timeTo });
    }, [getReportCollectionSheets, timeFrom, timeTo]);

    return (
        <CollectInfoReportTable ownersStatistic={sortEmployeeByField(collectionSheets, 'name', 'viewed')} loading={collectionSheetSuccess} />
    );
};

CollectInfoReportBody.propTypes = {
    collectionSheets: PropTypes.array,
    getReportCollectionSheets: PropTypes.func,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    collectionSheetSuccess: PropTypes.bool
};

const mapStateToProps = ({ reportsCollectionSheets: { collectionSheets, timeFrom, timeTo, collectionSheetSuccess } }) => {
    return {
        collectionSheets,
        timeFrom,
        timeTo,
        collectionSheetSuccess
    };
};

export default connect(mapStateToProps, { getReportCollectionSheets, fetchOwners })(CollectInfoReportBody);
