import dateComparisonFirstBeforeSecond from './dateComparison';

export default function buildSeasonsRangeObj(arrWithDateStartEnd, start = 'date_start', end = 'date_end') {
    const res = arrWithDateStartEnd.reduce((accum, item) => {
        if (accum.firstRange !== '') {
            dateComparisonFirstBeforeSecond(item[start], accum.firstRange) && (accum.firstRange = item[start]);
        } else {
            accum.firstRange = item[start];
        }

        if (accum.lastRange !== '') {
            dateComparisonFirstBeforeSecond(accum.lastRange, item[end]) && (accum.lastRange = item[end]);
        } else {
            accum.lastRange = item[end];
        }

        return accum;
    }, { firstRange: '', lastRange: '' });
    return res;
}
