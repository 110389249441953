
const createUniqueArrItems = (array, field) => {
    let tmpArray = [];
    const itemCheck = (item, field) => {
        if (tmpArray.indexOf(item[field]) === -1) {
            tmpArray = [...tmpArray, item[field]];
            return true;
        }
        return false;
    };

    return array.filter((item) => itemCheck(item, field));
};

export default createUniqueArrItems;
