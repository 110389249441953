import React from 'react';
import PropTypes from 'prop-types';

import style from './loader.module.scss';

const Loader = ({ active, classes }) => {
    return (
        <div className={style.loaderContainer + ' ' + (active ? style.active : '') + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.loader}>
                <svg className={style.circular} viewBox='0 0 50 50'>
                    <circle className={style.path} cx='25' cy='25' fill='none' r='20' />
                </svg>
            </div>
        </div>
    );
};

Loader.defaultProps = {
    active: false,
    classes: []
};

Loader.propTypes = {
    active: PropTypes.bool.isRequired,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['circle', 'small', 'small240', 'withoutBackground', 'light', 'loaderTop', 'gray', 'violetWithoutLoader', 'fixed', 'posStart', 'grayLight']))
};

export default Loader;
