import React from 'react';

import style from './ButtonSavePushClose.module.scss';

const ButtonSavePushClose = () => {
    return (
        <button className={style.greenBtn}>Сохранить и закрыть</button>
    );
};

export default ButtonSavePushClose;
