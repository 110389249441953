import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './SubHeaderCollect.module.scss';
import { connect } from 'react-redux';

const SubHeaderCollectSecretary = ({ title, carCollectBlocks, children, classes = [] }) => {
    const [blocks, setBlocks] = useState(null);
    useEffect(() => {
        setBlocks(carCollectBlocks ? carCollectBlocks.blocks : null);
    }, [carCollectBlocks]);

    return (
        <div className={style.subheader + classes.map(item => ' ' + style[item]).join('')} id={blocks && blocks[8].id}>
            <div className={style.wrapMarkTitle}>
                <div>{ title }</div>
            </div>
            <div className={style.rowHeaderItems}>
                {
                    children
                }
            </div>
        </div>
    );
};

SubHeaderCollectSecretary.propTypes = {
    title: PropTypes.string,
    carCollectBlocks: PropTypes.object,
    children: PropTypes.node,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['justifyContentLeft', 'paddingRight20']))
};

const mapStateToProps = ({ templatesOfFrameState: { carCollectBlocks } }) => {
    return {
        carCollectBlocks
    };
};

export default connect(mapStateToProps)(SubHeaderCollectSecretary);
