import React, { useState, useEffect } from 'react';
import CheckToggle from '../CheckToggle';
import PropTypes from 'prop-types';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

import style from './AnchorsMarkStates.module.scss';

const AnchorsMarkStates = ({
    textHeader,
    handleUpdateBlockInterest,
    blockId,
    withoutCheckToggle,
    passedHeader,
    occupancyRateBlocks = []
}) => {
    const [stateBlock, setStateBlock] = useState('empty');
    const [styleMark, setStyleMark] = useState('roundMarkRed');

    useEffect(() => {
        setStateBlock('empty');
        occupancyRateBlocks.length > 0 && occupancyRateBlocks.reduce((accum, item) => {
            (item.car_collect_block_id === blockId) && setStateBlock(item.state_block);
            return accum;
        }, {});
    }, [occupancyRateBlocks, blockId]);

    useEffect(() => {
        (stateBlock === 'empty') && setStyleMark('roundMarkRed');
        (stateBlock === 'some_collected') && setStyleMark('roundMarkOrange');
        (stateBlock === 'failed_collect') && setStyleMark('roundMarkRed');
        (stateBlock === 'fully') && setStyleMark('roundMarkGreen');
    }, [stateBlock, occupancyRateBlocks]);

    return (
        <div className={style.leftHead}>
            <div className={style[styleMark]}> </div>
            <h1 className={style.textHeader}>
                { textHeader }
            </h1>
            { passedHeader || '' }
            {
                withoutCheckToggle
                    ? ''
                    : <CheckToggle handleUpdateBlockInterest={handleUpdateBlockInterest} blockId={blockId} />
            }
        </div>
    );
};

AnchorsMarkStates.propTypes = {
    textHeader: PropTypes.string,
    handleUpdateBlockInterest: PropTypes.func,
    blockId: PropTypes.number,
    occupancyRateBlocks: PropTypes.array,
    withoutCheckToggle: PropTypes.bool,
    passedHeader: PropTypes.node
};

const mapStateToProps = ({ carDetailsState: { occupancyRateBlocks } }) => {
    return {
        occupancyRateBlocks
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(AnchorsMarkStates);
