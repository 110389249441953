import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SeasonTires } from '../../routes/SheetCollect/SheetCollectBody/components/Anchors/TiresFields';
import { fetchPostCarSeasonalPreference } from '../../actions';

const SetCarClientTiresPreference = ({
    carIdCollect,
    clientIdCollect,
    carDetailsInfo,
    title,
    fetchPostCarSeasonalPreference,
    carClientSeasonalPreferenceUpdateError,
    seasonId,
    isValid
}) => {
    const handleUpdatePost = (periodId, seasonId, preferencesId) => {
        const put = {
            car_id: carIdCollect,
            id: preferencesId,
            client_id: clientIdCollect,
            season_id: seasonId,
            period_id: periodId
        };

        const post = {
            car_id: carIdCollect,
            client_id: clientIdCollect,
            season_id: seasonId,
            period_id: periodId
        };

        carIdCollect && clientIdCollect && seasonId && fetchPostCarSeasonalPreference(
            carDetailsInfo.car_client_seasonal_preference && (carDetailsInfo.car_client_seasonal_preference.length > 0)
                ? put
                : post
        );
    };

    if (carClientSeasonalPreferenceUpdateError) {
        return <div>Something is wrong</div>;
    }

    return (
        <SeasonTires
            handleUpdate={handleUpdatePost}
            title={title}
            seasonId={seasonId}
            isValid={isValid || !!((carDetailsInfo?.car_client_seasonal_preference?.length > 0) && carDetailsInfo.car_client_seasonal_preference.find(item => item.season_id === seasonId))}
        />
    );
};

SetCarClientTiresPreference.propTypes = {
    carDetailsInfo: PropTypes.object,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    seasonId: PropTypes.number,
    title: PropTypes.string,
    fetchPostCarSeasonalPreference: PropTypes.func,
    carClientSeasonalPreferenceUpdateError: PropTypes.bool,
    isValid: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carClientSeasonalPreferenceUpdateError, clientIdCollect } }) => {
    return {
        carDetailsInfo,
        clientIdCollect,
        carIdCollect,
        carClientSeasonalPreferenceUpdateError
    };
};

export default connect(mapStateToProps, { fetchPostCarSeasonalPreference })(SetCarClientTiresPreference);
