import React, { useState, useEffect } from 'react';
import style from './VisitorsBody.module.scss';
import { dateComparisonFirstBeforeSecond, dateFromDateField, timeFromDateField } from '../../../utils';
import { AFTER_TOMORROW, TODAY, TOMORROW } from '../../../constants/dates';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const TableRowVisitors = ({ visitor = { clientInfo: null }, fields = [] }) => {
    // const link = `/visitors/${isOrder ? visitor.clientId : visitor.id}`
    const link = `/visitors/${visitor.id}`;
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const [avatar, setAvatar] = useState('');
    useEffect(() => {
        setAvatar(visitor.avatar ? visitor.avatar.thumbnails.avatar : '');
    }, [visitor.avatar]);
    const rowOrder = (
        <div className={style.tableVisitorsBodyRow + ' ' + (dateComparisonFirstBeforeSecond(visitor[fields[4]], TOMORROW) ? style.blueText : '')}>
            <div className={style.col05}>
                <div className={style.imgIn}>
                    <img src={visitor.clientAvatar} className={style.mainImg} alt={visitor.clientAvatar ? visitor.clientName : ''} />
                </div>
                { visitor[fields[0]] }
            </div>
            <div className={style.col2}>
                {
                    visitor && visitor.clientInfo ? (visitor.clientInfo.nick || visitor.clientInfo.companies_nick || visitor.clientName) : visitor.clientName
                }
            </div>
            <div className={style.col1}>{ visitor ? visitor[fields[2]] : '' }</div>
            <div className={style.col2}>
                {
                    visitor.cars && visitor.cars.length > 0
                        ? visitor.cars[0].model.mark.title + ' ' + visitor.cars[0].model.title
                        : ''
                }
            </div>
            <div className={style.col1}>
                {
                    dateComparisonFirstBeforeSecond(visitor[fields[4]], TOMORROW) && dateComparisonFirstBeforeSecond(TODAY, visitor[fields[4]])
                        ? 'сегодня '
                        : (dateComparisonFirstBeforeSecond(visitor[fields[4]], AFTER_TOMORROW) && dateComparisonFirstBeforeSecond(TODAY, visitor[fields[4]])
                            ? 'завтра '
                            : dateFromDateField(visitor[fields[4]]))
                }
                {
                    ` в ${timeFromDateField(visitor[fields[4]])}`
                }
            </div>
        </div>
    );

    const rowVisitors = (
        <div className={style.tableVisitorsBodyRow + ' ' + (dateComparisonFirstBeforeSecond(visitor[fields[4]], TOMORROW) ? style.blueText : '')}>
            <div className={style.col05}>
                <div className={`${style.imgIn} ${visitor.avatar ? '' : style[`rnd${getRandomInt(0, 12)}`]}`}>
                    { visitor.avatar
                        ? <img src={avatar} className={style.mainImg} alt='' />
                        : null }
                </div>
            </div>
            <div className={style.col3}>
                {
                    (visitor && visitor.nick) || visitor.companies_nick || visitor.company_nick || visitor[fields[2]]
                }
            </div>
            <div className={style.col3 + style.center}>
                { visitor.phone }
            </div>
            <div className={style.col3} />
            { /* <div className={style.col1}>{ visitor ? visitor[fields[2]] : '' }</div> */ }
            <div className={style.col05}>
                {
                    visitor.cars && visitor.cars.length > 0
                        ? visitor.cars[0].model.mark.title + ' ' + visitor.cars[0].model.title
                        : ''
                }
            </div>
            <div className={style.col05} />
        </div>
    );
    return (
        <>
            <Link to={link}>
                {
                    visitor.cars && visitor.cars.length > 0
                        ? rowOrder
                        : rowVisitors
                }
            </Link>
        </>
    );
};

TableRowVisitors.propTypes = {
    visitor: PropTypes.object,
    fields: PropTypes.array
};

export default TableRowVisitors;
