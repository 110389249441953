import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SelectWithLabel from '../SelectWithLabel';
import { fetchPostCarInsurance } from '../../actions';

const SetCarDetailsSelectCollect = ({
    carIdCollect: id,
    valueField,
    propCar,
    optsForInsuranceCompany,
    fetchPostCarInsurance,
    carInsuranceUpdateError,
    clientIdCollect,
    idName
}) => {
    const [valueForm, setValueForm] = useState(valueField || null);

    useEffect(() => {
        setValueForm(valueField);
    }, [valueField]);

    const handleUpdate = (e, valueUp) => {
        id && valueUp && (valueUp > 0) && fetchPostCarInsurance({
            car_id: id, [propCar]: +valueUp
        }, clientIdCollect);
    };

    return (
        <>
            <SelectWithLabel
                idName={idName}
                arrOptions={optsForInsuranceCompany}
                valueReal={valueForm}
                labelName='Страховая'
                forUpdateField={handleUpdate}
                error={carInsuranceUpdateError}
            />
        </>
    );
};

SetCarDetailsSelectCollect.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    valueField: PropTypes.any,
    propCar: PropTypes.string,
    optsForInsuranceCompany: PropTypes.array,
    carInsuranceUpdateError: PropTypes.bool,
    fetchPostCarInsurance: PropTypes.func,
    idName: PropTypes.string
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect }, templatesOfFrameState: { optsForInsuranceCompany } }) => {
    return {
        carIdCollect,
        optsForInsuranceCompany,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarInsurance })(SetCarDetailsSelectCollect);
