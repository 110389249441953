import React from 'react';
import style from './Columns.module.scss';
import PropTypes from 'prop-types';

const Columns = ({ first, second, classes = [] }) => {
    return (
        <div className={style.columns + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.first}>
                { first }
            </div>
            <div className={style.second}>
                { second }
            </div>
        </div>
    );
};

Columns.propTypes = {
    first: PropTypes.node,
    second: PropTypes.node,
    classes: PropTypes.array
};

export default Columns;
