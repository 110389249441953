import React from 'react';
import style from './CustomTimeInput.module.scss';
import PropTypes from 'prop-types';
import arrow from '../../../assets/arrow-down.png';

const CustomTimeInput = React.forwardRef(({ value, onClick, textSpan, disabled, withArrow, classes = [] }, ref) => {
    const handleOnClick = (data) => {
        if (!withArrow) {
            onClick(data);
        }
    };

    return (
        <div ref={ref} className={style.timeInput + (disabled ? ' ' + style.disabled : '') + (!withArrow ? ' ' + style.withArrow : '') + classes.map(item => ' ' + style[item]).join('')} onClick={handleOnClick}>
            {
                textSpan && <span className={style.spanTimeInput}>{ textSpan }</span>
            }
            <div>{ value }</div>
            {
                withArrow
                    ? (
                        <div
                            className={style.imgDateInput}
                            onClick={onClick}
                        >
                            <img src={arrow} alt='' />
                        </div>
                    )
                    : ''
            }
        </div>
    );
});

CustomTimeInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    textSpan: PropTypes.string,
    withArrow: PropTypes.bool,
    classes: PropTypes.array
};

export default CustomTimeInput;
