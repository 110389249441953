import React from 'react';
import SelectPostFilter from '../SelectPostFilter';
import CompletedCards from './CompletedCards';
import CarsInspectCollect from './CarsInspectCollect';
import HeadToggle from '../HeadToggle';
import PropTypes from 'prop-types';

import style from './ListInspectCollect.module.scss';

const arrCarsInspectCollect = [
    {
        carId: 13,
        markCar: 'Nissan',
        modelCar: 'Juke',
        status: 'active',
        regNumberCar: '',
        regNumberCarEnd: '125',
        mileage: [
            {
                date1: '100000',
                date2: '120000',
                date3: '200000'

            }
        ],
        berthYear: '2003',
        mainImageCar: 'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691415.jpg',
        imagesCar:
            [
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691415.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691416.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691417.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691418.jpg'
            ],
        users: [
            { status: 'owner', userId: 1, userName: 'Иванов Иван Иванович', phone: '89666666666' },
            { status: 'lpr', userId: 1, userName: 'Иванов Иван Иванович', phone: '89666666666' },
            { status: 'contact', userId: 1, userName: 'Иванов Иван Иванович', phone: '89666666666' },
            { status: 'driver', userId: 1, userName: 'Иванов Иван Иванович', phone: '89666666666' },
            { status: 'driver', userId: 2, userName: 'Петров Петр Петрович', phone: '89020000001' }
        ],
        companyId: 1,
        engineNumber: '1Nfre34344444',
        engineCapacity: '200',
        bodyType: '1Nhrhr637787',
        bodyColor: 'white',
        bodyNumber: '1N6372GHJDJ838',
        chassis: '1Nshassis',
        fluid: [
            { oilDvs: { carState: 'Отличное', chit: 'nothing' } },
            { oilAutomatic: { carState: 'Отличное', chit: 'nothing' } },
            { antifreeze: { carState: 'Отличное', chit: 'nothing' } },
            { brakeFluid: { carState: 'Отличное', chit: 'nothing' } },
            { powerSteeringFluid: { carState: 'Отличное', chit: 'nothing' } }
        ]
    },
    {
        carId: 24,
        markCar: 'Toyota',
        modelCar: 'Prius',
        status: 'active',
        regNumberCar: 'c333хc',
        regNumberCarEnd: '125',
        mileage: [
            {
                date1: '100000',
                date2: '120000',
                date3: '200000'

            }
        ],
        berthYear: '2013',
        mainImageCar: '',
        imagesCar:
            [
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691415.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691416.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691417.jpg',
                'https://s.auto.drom.ru/i24234/s/photos/34221/34220038/gen1200_386691418.jpg'
            ],
        users: [
            { status: 'owner', userId: 33, userName: 'Сидоров Фёдр Фёдорович', phone: '89025555555' },
            { status: 'lpr', userId: 33, userName: 'Сидоров Фёдр Фёдорович', phone: '89025555555' },
            { status: 'contact', userId: 33, userName: 'Сидоров Фёдр Фёдорович', phone: '89025555555' },
            { status: 'driver', userId: 1, userName: 'Иванов Иван Иванович', phone: '89999999999' },
            { status: 'driver', userId: 2, userName: 'Петров Петр Петрович', phone: '89508888888' }
        ],
        companyId: 1,
        engineNumber: 'eng_number',
        engineCapacity: '200',
        bodyType: 'body_type',
        bodyColor: 'black',
        bodyNumber: '45454545454545',
        chassis: 'what_chassis',
        fluid: [
            { oilDvs: { car_state: 'Отличное', chit: 'nothing' } },
            { oilAutomatic: { car_state: 'Отличное', chit: 'nothing' } },
            { antifreeze: { car_state: 'Плохое', chit: 'чинить' } },
            { brakeFluid: { car_state: 'Отличное', chit: 'nothing' } },
            { powerSteeringFluid: { car_state: 'Отличное', chit: 'nothing' } }
        ]
    }
];

const allCarsCompleted = [{ car: 'car0' }, { car: 'car10' }, { car: 'car11' }, { car: 'car11' }, { car: 'car11' }, { car: 'car11' }];

const arrPosts = [{ name: 'post1', value: 'Пост 1' }, { name: 'post2', value: 'Пост 2' }, { name: 'post3', value: 'Пост 3' }, { name: 'post4', value: 'Пост 4' }];

const ListInspectCollect = ({ header, layout, dontShowLists }) => {
    if (!dontShowLists) {
        return (
            <div className={style.blListInspect}>
                <div className={style.listCollectInspectHeader}>
                    <h1>{ header }</h1>
                </div>

                <SelectPostFilter arrOpts={arrPosts} listCollect={false} key='inspectPostFilter' id='inspectPostFilter' />

                <HeadToggle
                    headerText='Незаполненные'
                    numItems={arrCarsInspectCollect.length}
                >
                    <CarsInspectCollect
                        layout={layout}
                        cars={arrCarsInspectCollect}
                    />
                </HeadToggle>

                <HeadToggle
                    headerText='Просроченные'
                    numItems={arrCarsInspectCollect.length}
                >
                    <CarsInspectCollect
                        layout={layout}
                        cars={arrCarsInspectCollect}
                    />
                </HeadToggle>

                <CompletedCards layout={layout} completed={allCarsCompleted.length} />
            </div>
        );
    } else return null;
};

ListInspectCollect.defaultProps = {
    header: 'Листы осмотра',
    layout: 'CardRight'
};

ListInspectCollect.propTypes = {
    layout: PropTypes.string,
    header: PropTypes.string,
    dontShowLists: PropTypes.bool
};

export default ListInspectCollect;
