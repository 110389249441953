import {
    typeCarsForTodayLoaded,
    typeCarsForTomorrowLoaded,
    typeCarsOrdersError,
    typeCarsOrdersSuccess,
    typeCarsOrdersForRangeLoaded,
    typeCountCarsLoaded,
    typeSvBooksCountSuccess,
    typeSvBooksCountError
} from '../actionsTypes';

// ------------------------------------
// Constants
// ------------------------------------
const CARS_ORDERS_FOR_TODAY_LOADED = typeCarsForTodayLoaded;
const CARS_ORDERS_FOR_TOMORROW_LOADED = typeCarsForTomorrowLoaded;
const CARS_ORDERS_ERROR = typeCarsOrdersError;
const CARS_ORDERS_SUCCESS = typeCarsOrdersSuccess;
const CARS_ORDERS_FOR_RANGE = typeCarsOrdersForRangeLoaded;
const COUNT_CARS_COMPANY = typeCountCarsLoaded;
const SV_BOOKS_COUNT_SUCCESS = typeSvBooksCountSuccess;
const SV_BOOKS_COUNT_ERROR = typeSvBooksCountError;

// ------------------------------------
// Actions
// ------------------------------------

export const setCarsOrdersForToday = (payload) => {
    return {
        type: CARS_ORDERS_FOR_TODAY_LOADED,
        payload: payload
    };
};

export const setCarsOrdersForTomorrow = (payload) => {
    return {
        type: CARS_ORDERS_FOR_TOMORROW_LOADED,
        payload: payload
    };
};

export const setCarsOrdersError = (payload) => {
    return {
        type: CARS_ORDERS_ERROR,
        payload: payload
    };
};

export const setCarsOrdersSuccess = (payload) => {
    return {
        type: CARS_ORDERS_SUCCESS,
        payload: payload
    };
};

export const setCarsOrdersForTimeRange = (payload) => {
    return {
        type: CARS_ORDERS_FOR_RANGE,
        payload: payload
    };
};

export const carsCountLoaded = (countCars) => {
    return {
        type: COUNT_CARS_COMPANY,
        payload: countCars
    };
};

export const allSvBooksCountSuccess = (isSuccess) => {
    return {
        type: SV_BOOKS_COUNT_SUCCESS,
        payload: isSuccess
    };
};

export const allSvBooksCountError = (isError) => {
    return {
        type: SV_BOOKS_COUNT_ERROR,
        payload: isError
    };
};

export const getCarsOrders = (data = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setCarsOrdersSuccess(true));
    dispatch(setCarsOrdersError(false));

    return apiService.getCarsOrdersForTimeRange(data)
        .then((res) => {
            if (data.for_time === 'today') {
                dispatch(setCarsOrdersForToday(res));
            }
            if (data.for_time === 'tomorrow') {
                dispatch(setCarsOrdersForTomorrow(res));
            }
            if ((data.for_time !== 'today') && (data.for_time !== 'tomorrow')) {
                dispatch(setCarsOrdersForTimeRange(res));
            }

            dispatch(setCarsOrdersSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setCarsOrdersSuccess(false));
            dispatch(setCarsOrdersError(true));
            return err;
        });
};

export const getAllSvBooks = () => (dispatch, getState, { apiService }) => {
    dispatch(allSvBooksCountSuccess(true));
    dispatch(allSvBooksCountError(false));

    return apiService.getAllCarCompany()
        .then((res) => {
            dispatch(allSvBooksCountSuccess(false));
            dispatch(carsCountLoaded(res));
            return res;
        })
        .catch((err) => {
            dispatch(allSvBooksCountSuccess(false));
            dispatch(allSvBooksCountError(true));
            return err;
        });
};

export const actions = {
    getCarsOrders,
    getAllSvBooks,
    allSvBooksCountSuccess,
    allSvBooksCountError
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [CARS_ORDERS_FOR_TODAY_LOADED]: (state, action) => {
        return ({
            ...state,
            carsOrdersForToday: action.payload
        });
    },

    [CARS_ORDERS_FOR_TOMORROW_LOADED]: (state, action) => {
        return ({
            ...state,
            carsOrdersForTomorrow: action.payload
        });
    },

    [CARS_ORDERS_FOR_RANGE]: (state, action) => {
        return ({
            ...state,
            carsOrdersForRange: action.payload
        });
    },

    [CARS_ORDERS_ERROR]: (state, action) => {
        return ({
            ...state,
            isCarOrdersError: action.payload
        });
    },

    [CARS_ORDERS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isCarOrdersSuccess: action.payload
        });
    },

    [COUNT_CARS_COMPANY]: (state, action) => {
        return ({
            ...state,
            allSvBooksCount: action.payload
        });
    },

    [SV_BOOKS_COUNT_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isAllSvBooksCountSuccess: action.payload
        });
    },

    [SV_BOOKS_COUNT_ERROR]: (state, action) => {
        return ({
            ...state,
            isAllSvBooksCountError: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            carsOrdersForToday: [],
            carsOrdersForTomorrow: [],
            carsOrdersForRange: [],
            isCarOrdersError: false,
            isCarOrdersSuccess: false,
            allSvBooksCount: 0,
            isAllSvBooksCountError: false,
            isAllSvBooksCountSuccess: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.carOrders, action) : state.carOrders;
};

export default commonReducer;
