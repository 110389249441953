import React from 'react';
import { connect } from 'react-redux';
import { fetchLogout } from '../../reducers/auth';
import PropTypes from 'prop-types';
import ForbiddenHTML from './ForbiddenHTML';

const Forbidden = ({ fetchLogout }) => {
    const handleClick = () => {
        fetchLogout();
    };

    return (
        <ForbiddenHTML handleClick={handleClick} text='Недостаточно прав' textButton='Зайти под другим пользователем' />
    );
};

Forbidden.propTypes = {
    fetchLogout: PropTypes.func
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { fetchLogout })(Forbidden);
