import React from 'react';
import style from './ErrorModal.module.scss';
import storeError from '../../assets/storeError.svg';
import storeSuccess from '../../assets/storeSuccess.svg';
import PropTypes from 'prop-types';

const ErrorModal = ({ isError, image, message, showModal, withButton, onlyError = false }) => {
    const errorModal = (
        <div className={style.modal + ' ' + (isError ? style.errorModal : style.successModal)}>
            <img className={style.img} src={image || (isError ? storeError : storeSuccess)} alt='message_after_action' />
            {
                message || (isError ? 'Ошибка' : 'Успех')
            }
            {
                withButton
                    ? <button className={style.buttonModal} onClick={() => showModal(false)}>Ок</button>
                    : ''
            }
        </div>
    );

    return (
        <div className={style.modal}>
            {
                onlyError
                    ? (
                        isError
                            ? errorModal
                            : ''
                    )
                    : errorModal
            }
        </div>
    );
};

ErrorModal.propTypes = {
    isError: PropTypes.bool,
    image: PropTypes.object,
    message: PropTypes.string,
    onlyError: PropTypes.bool,
    showModal: PropTypes.func,
    withButton: PropTypes.bool
};

export default ErrorModal;
