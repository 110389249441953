import {
    typeDeleteSeason,
    typeDeleteSeasonsPeriod,
    typeErrorUpdateSeason,
    typeErrorUpdateSeasonPeriods,
    typePacketsSeasonsSuccess,
    typeSaveSeasonsPeriod,
    typeSeasonsPeriodsErrorLoaded,
    typeSeasonsPeriodsLoaded,
    typeSeasonsPeriodsSuccess,
    typeSetErrorPacketsSeasons,
    typeSetPacketsSeasons,
    typeSetSeasons,
    typeSetUpdateSeason,
    typeSetUpdateSeasonPeriods,
    typeUpdateSeasonPeriodsSuccess,
    typeUpdateSeasonSuccess
} from '../actionsTypes';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const DELETE_SEASON = typeDeleteSeason;
const DELETE_SEASONS_PERIOD = typeDeleteSeasonsPeriod;
const UPDATE_SEASON_TIRES_ERROR = typeErrorUpdateSeason;
const UPDATE_SEASON_PERIODS_TIRES_ERROR = typeErrorUpdateSeasonPeriods;
const SET_PACKETS_SEASONS_SUCCESS = typePacketsSeasonsSuccess;
const SAVE_SEASONS_PERIOD = typeSaveSeasonsPeriod;
const SET_ERROR_GET_SEASONS_AND_PERIODS = typeSeasonsPeriodsErrorLoaded;
const GET_SEASONS_AND_PERIODS = typeSeasonsPeriodsLoaded;
const SEASON_PERIODS_SUCCESS = typeSeasonsPeriodsSuccess;
const SET_ERROR_PACKETS_SEASONS = typeSetErrorPacketsSeasons;
const SET_PACKETS_SEASONS = typeSetPacketsSeasons;
const SET_SEASONS = typeSetSeasons;
const SET_UPDATE_SEASON_TIRES = typeSetUpdateSeason;
const SET_UPDATE_SEASON_PERIODS_TIRES = typeSetUpdateSeasonPeriods;
const UPDATE_SEASON_PERIODS_TIRES_SUCCESS = typeUpdateSeasonPeriodsSuccess;
const UPDATE_SEASON_TIRES_SUCCESS = typeUpdateSeasonSuccess;
// ------------------------------------
// Actions
// ------------------------------------

export const setSeasons = (seasons) => {
    return {
        type: SET_SEASONS,
        payload: seasons
    };
};

const updateSeasonSuccess = (isLoading) => {
    return {
        type: UPDATE_SEASON_TIRES_SUCCESS,
        payload: isLoading
    };
};

const setErrorUpdateSeason = (isError) => {
    return {
        type: UPDATE_SEASON_TIRES_ERROR,
        payload: isError
    };
};

export const getSeasonsTires = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeason(false));
    dispatch(updateSeasonSuccess(true));
    return apiService.fetchSeasonsTires()
        .then((res) => {
            dispatch(setSeasons(res));
            dispatch(updateSeasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeason(true));
            dispatch(updateSeasonSuccess(false));
            return err;
        });
};

const setUpdateSeason = (update) => {
    return {
        type: SET_UPDATE_SEASON_TIRES,
        payload: update
    };
};

export const updateSeasonTires = (nameTable, payload = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeason(false));
    dispatch(updateSeasonSuccess(true));
    return apiService.setSeasonsTires(payload)
        .then((res) => {
            dispatch(setUpdateSeason(res));
            dispatch(updateSeasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeason(true));
            dispatch(updateSeasonSuccess(false));
            return err;
        });
};

const deleteSeasons = (id) => {
    return {
        type: DELETE_SEASON,
        payload: id
    };
};

export const delSeasonsTires = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeason(false));
    dispatch(updateSeasonSuccess(true));
    return apiService.deleteSeasonsTires(id)
        .then((res) => {
            res && dispatch(deleteSeasons(id));
            dispatch(updateSeasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeason(true));
            dispatch(updateSeasonSuccess(false));
            return err;
        });
};

export const seasonsPeriodsLoaded = (season) => {
    return {
        type: GET_SEASONS_AND_PERIODS,
        payload: season
    };
};

const fetchSeasonsPeriodsErrorLoaded = (seasonError) => {
    return {
        type: SET_ERROR_GET_SEASONS_AND_PERIODS,
        payload: seasonError
    };
};

const fetchSeasonsPeriodsSuccess = (seasonSuccess) => {
    return {
        type: SEASON_PERIODS_SUCCESS,
        payload: seasonSuccess
    };
};

export const fetchSeasonsPeriods = () => (dispatch, getState, { apiService }) => {
    dispatch(fetchSeasonsPeriodsErrorLoaded(false));
    dispatch(fetchSeasonsPeriodsSuccess(true));
    apiService.getTiresCatalogs()
        .then((data) => {
            dispatch(fetchSeasonsPeriodsSuccess(false));
            dispatch(seasonsPeriodsLoaded(data));
        })
        .catch(() => {
            dispatch(fetchSeasonsPeriodsErrorLoaded(true));
            dispatch(fetchSeasonsPeriodsSuccess(false));
        });
};

const deleteSeasonsPeriod = (id) => {
    return {
        type: DELETE_SEASONS_PERIOD,
        payload: id
    };
};

export const delSeasonsPeriodTires = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeasonPeriods(false));
    dispatch(updateSeasonPeriodsSuccess(true));
    return apiService.deleteSeasonsPeriodTires(id)
        .then((res) => {
            res && dispatch(deleteSeasonsPeriod(id));
            dispatch(updateSeasonPeriodsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeasonPeriods(true));
            dispatch(updateSeasonPeriodsSuccess(false));
            return err;
        });
};

const setSeasonsPeriod = (data) => {
    return {
        type: SAVE_SEASONS_PERIOD,
        payload: data
    };
};

export const updateSeasonsPeriod = (nameTable, payload = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeasonPeriods(false));
    dispatch(updateSeasonPeriodsSuccess(true));
    return apiService.postSeasonsPeriodTires(payload)
        .then((res) => {
            res && dispatch(setSeasonsPeriod(res));
            dispatch(updateSeasonPeriodsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeasonPeriods(true));
            dispatch(updateSeasonPeriodsSuccess(false));
            return err;
        });
};

const updateSeasonPeriodsSuccess = (isLoading) => {
    return {
        type: UPDATE_SEASON_PERIODS_TIRES_SUCCESS,
        payload: isLoading
    };
};

const setErrorUpdateSeasonPeriods = (isError) => {
    return {
        type: UPDATE_SEASON_PERIODS_TIRES_ERROR,
        payload: isError
    };
};

export const setUpdateSeasonPeriods = (update) => {
    return {
        type: SET_UPDATE_SEASON_PERIODS_TIRES,
        payload: update
    };
};

export const getSeasonPeriodsTires = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateSeasonPeriods(false));
    dispatch(updateSeasonPeriodsSuccess(true));
    return apiService.fetchSeasonPeriodsTires()
        .then((res) => {
            dispatch(setUpdateSeasonPeriods(res));
            dispatch(updateSeasonPeriodsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateSeasonPeriods(true));
            dispatch(updateSeasonPeriodsSuccess(false));
            return err;
        });
};

export const setPackets = (seasons) => {
    return {
        type: SET_PACKETS_SEASONS,
        payload: seasons
    };
};

const setErrorPacketsSeasons = (isError) => {
    return {
        type: SET_ERROR_PACKETS_SEASONS,
        payload: isError
    };
};

const packetsSeasonsSuccess = (isLoading) => {
    return {
        type: SET_PACKETS_SEASONS_SUCCESS,
        payload: isLoading
    };
};

export const getPacketsSeasons = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorPacketsSeasons(false));
    dispatch(packetsSeasonsSuccess(true));
    return apiService.getPackets()
        .then((res) => {
            dispatch(setPackets([{ id: 0, name: '' }, ...res]));
            dispatch(packetsSeasonsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorPacketsSeasons(true));
            dispatch(packetsSeasonsSuccess(false));
            return err;
        });
};

export const actions = {
    setSeasons,
    getSeasonsTires,
    updateSeasonTires,
    delSeasonsTires,
    fetchSeasonsPeriods,
    seasonsPeriodsLoaded,
    delSeasonsPeriodTires,
    updateSeasonsPeriod,
    getSeasonPeriodsTires,
    setUpdateSeasonPeriods,
    setPackets,
    getPacketsSeasons
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_SEASONS]: (state, action) => {
        return ({
            ...state,
            seasons: action.payload
        });
    },

    [SET_UPDATE_SEASON_TIRES]: (state, action) => {
        let newSeason = _.cloneDeep(state.seasons);
        const newSeasonsAndPeriods = _.cloneDeep(state.seasonsAndPeriods);

        const idx = newSeason.findIndex((item) => item.id === action.payload.id);
        if (idx !== -1) {
            newSeason = [
                ...newSeason.slice(0, idx),
                action.payload,
                ...newSeason.slice(idx + 1)
            ];
        } else {
            newSeason = [
                ...newSeason,
                action.payload
            ];
        }

        newSeasonsAndPeriods.seasons = newSeason;

        return ({
            ...state,
            seasons: newSeason,
            seasonsAndPeriods: newSeasonsAndPeriods
        });
    },

    [UPDATE_SEASON_TIRES_ERROR]: (state, action) => {
        return ({
            ...state,
            errorUpdateSeason: action.payload
        });
    },

    [UPDATE_SEASON_TIRES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            seasonsTiresSuccess: action.payload
        });
    },

    [DELETE_SEASON]: (state, action) => {
        const newSeason = _.cloneDeep(state.seasons);
        const idx = newSeason.findIndex((item) => item.id === action.payload);
        if (idx !== -1) {
            return ({
                ...state,
                seasons: [
                    ...newSeason.slice(0, idx),
                    ...newSeason.slice(idx + 1)
                ]
            });
        } else {
            return ({
                ...state
            });
        }
    },

    [GET_SEASONS_AND_PERIODS]: (state, action) => {
        return ({
            ...state,
            seasonsAndPeriods: action.payload
        });
    },

    [SET_ERROR_GET_SEASONS_AND_PERIODS]: (state, action) => {
        return ({
            ...state,
            seasonsAndPeriodsError: action.payload
        });
    },

    [SEASON_PERIODS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            seasonsAndPeriodsSuccess: action.payload
        });
    },

    [SAVE_SEASONS_PERIOD]: (state, action) => {
        const oldItems = _.cloneDeep(state.seasonPeriods);
        const idx = oldItems.findIndex((item) => item.id === action.payload.id);
        if (idx !== -1) {
            return ({
                ...state,
                seasonPeriods: [
                    ...oldItems.slice(0, idx),
                    action.payload,
                    ...oldItems.slice(idx + 1)
                ]
            });
        } else {
            return ({
                ...state,
                seasonPeriods: [
                    ...oldItems,
                    action.payload
                ]
            });
        }
    },

    [UPDATE_SEASON_PERIODS_TIRES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            updateSeasonPeriodsSuccess: action.payload
        });
    },

    [UPDATE_SEASON_PERIODS_TIRES_ERROR]: (state, action) => {
        return ({
            ...state,
            errorUpdateSeasonPeriods: action.payload
        });
    },

    [DELETE_SEASONS_PERIOD]: (state, action) => {
        const oldItems = _.cloneDeep(state.seasonPeriods);
        const idx = oldItems.findIndex((item) => item.id === action.payload);
        if (idx !== -1) {
            return ({
                ...state,
                seasonPeriods: [
                    ...oldItems.slice(0, idx),
                    ...oldItems.slice(idx + 1)
                ]
            });
        } else {
            return ({
                ...state
            });
        }
    },

    [SET_UPDATE_SEASON_PERIODS_TIRES]: (state, action) => {
        return ({
            ...state,
            seasonPeriods: action.payload
        });
    },

    [SET_PACKETS_SEASONS]: (state, action) => {
        return ({
            ...state,
            packets: action.payload
        });
    },

    [SET_ERROR_PACKETS_SEASONS]: (state, action) => {
        return ({
            ...state,
            errorPacketsSeasons: action.payload
        });
    },

    [SET_PACKETS_SEASONS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            packetsSeasonsSuccess: action.payload
        });
    }

};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            seasons: [],
            errorUpdateSeason: false,
            seasonsTiresSuccess: false,
            seasonsAndPeriods: {},
            seasonsAndPeriodsError: false,
            seasonsAndPeriodsSuccess: false,
            seasonPeriods: [],
            updateSeasonPeriodsSuccess: false,
            errorUpdateSeasonPeriods: false,
            packets: [],
            errorPacketsSeasons: false,
            packetsSeasonsSuccess: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.settingsSeasons, action) : state.settingsSeasons;
};

export default commonReducer;
