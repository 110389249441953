import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetch1CServices,
    fetchOrtusServices,
    fetchLinks,
    makeLink,
    deleteLink
} from '../../../reducers/connection';
import style from '../Connection/Connection.module.scss';
import _ from 'lodash';
import Loader from '../../../components/Loader';
import ServiceTemplateItem from './ServiceTemplateItem';

function isHasST(catalogue, companyId) {
    const st = catalogue?.serviceTemplates?.length > 0 && catalogue?.serviceTemplates?.find(template => {
        return template?.services?.find(service => {
            return service?.company?.id === companyId && !service?.isHidden;
        });
    });
    const chc = catalogue?.childrenCatalogues.length > 0 && catalogue?.childrenCatalogues?.find(catalogue => {
        return isHasST(catalogue, companyId);
    });

    return !!st || chc;
}

export class Connection extends React.Component {
    state = {
        type: 'all',
        showDelete: false,
        activeTemplate: '',
        catalogueIsConnected: [],
        showLabel: '',
        companyId: '',
        serviceId: ''
    };

    componentDidMount() {
        const partner = localStorage.getItem('partner');
        const newPartner = JSON.parse(partner);
        const id = newPartner ? newPartner.company_id : 0;
        this.setState({ companyId: id });
        this.props.fetch1CServices();
        this.props.fetchOrtusServices(id);
        this.props.fetchLinks();
    };

    makeNewLink = (odinID) => {
        const data = [{
            ext_id: odinID,
            angara_id: this.state.serviceId
        }];
        this.props.makeLink(data);
        this.setState({ activeTemplate: '' });
    };

    deleteLink = (template, companyId) => {
        const currentService = [];
        _.map(template?.services, service => {
            if (service?.company?.id === companyId && !service?.isHidden) {
                currentService.push(service);
            }
        });
        this.props.deleteLink(currentService[0]?.id);
    };

    checkForIsActive = (catalogue, links, catIsConnected, catIsNotConnected, companyIdNum) => {
        const connect = {};
        const companyId = companyIdNum;
        connect.catalogueIsConnected = catIsConnected;
        connect.catalogueIsNotConnected = catIsNotConnected;
        _.forEach(catalogue?.serviceTemplates, function(serviceTemplate) {
            const currentService = [];
            _.map(serviceTemplate?.services, service => {
                if (service?.company?.id === companyId && !service?.isHidden) {
                    currentService.push(service);
                }
            });
            if (_.find(links, ['angara_id', currentService[0]?.id])) {
                connect.catalogueIsConnected.push(serviceTemplate);
            } else {
                connect.catalogueIsNotConnected.push(serviceTemplate);
            }
        });
        if (!_.isEmpty(catalogue.childrenCatalogues)) {
            _.map(catalogue.childrenCatalogues, catalogue => {
                this.checkForIsActive(catalogue, links, connect.catalogueIsConnected, connect.catalogueIsNotConnected, companyId);
            });
        }
        return connect;
    };

    catalogueRecursionRender = (catalogue, companyId) => {
        const links = _.get(this.props, 'links');
        const divStyle = {
            width: catalogue.name.length * 9 + 'px'
        };

        if (links && isHasST(catalogue, companyId)) {
            const catalogueIsConnected = this.checkForIsActive(catalogue, links, [], [], companyId);

            const serviceTemplates = catalogue?.serviceTemplates?.length > 0 && catalogue.serviceTemplates.filter(serviceTemplate => {
                return serviceTemplate?.services?.find(service => {
                    return service?.company?.id === companyId && !service?.isHidden;
                });
            });

            if ((this.state.type === 'connected' && catalogueIsConnected.catalogueIsConnected.length !== 0) ||
                (this.state.type === 'notConnected' && catalogueIsConnected.catalogueIsNotConnected.length !== 0) ||
                this.state.type === 'all'
            ) {
                return (
                    <div
                        key={catalogue.id}
                        className={style.catalogueNameContainer}
                    >
                        <div
                            className={style.catalogueName}
                            key={catalogue.id}
                        >
                            <div
                                className={style.catalogueNameUnder}
                                style={divStyle}
                            >
                                <div>{ catalogue.name }</div>
                            </div>
                            <div className={style.templatesContainer}>
                                { serviceTemplates?.length > 0 && _.map(serviceTemplates, template => {
                                    const currentService = [];
                                    _.map(template.services, service => {
                                        if (service?.company?.id === companyId && !service?.isHidden) {
                                            currentService.push(service);
                                        }
                                    });
                                    let isConnected = null;
                                    currentService.forEach(item => {
                                        const found = _.find(links, ['angara_id', item.id]);
                                        if (found) {
                                            isConnected = found;
                                        }
                                    });

                                    let itemName;
                                    if (isConnected) {
                                        itemName = _.find(this.props.oneServices, ['id', isConnected.ext_id]);
                                    }
                                    if ((this.state.type === 'connected' && isConnected) ||
                                            (this.state.type === 'notConnected' && !isConnected) ||
                                            this.state.type === 'all'
                                    ) {
                                        return (
                                            <ServiceTemplateItem
                                                key={template.id}
                                                template={template}
                                                type={this.state.type}
                                                isConnected={isConnected}
                                                itemName={itemName}
                                                activeTemplate={this.state.activeTemplate}
                                                showDelete={this.state.showDelete}
                                                deleteLink={this.deleteLink}
                                                companyId={companyId}
                                                makeTemplateActive={this.makeTemplateActive}
                                                showDeleteButton={this.showDeleteButton}
                                                hideDeleteButton={this.hideDeleteButton}
                                            />
                                        );
                                    }
                                }
                                ) }
                                {
                                    catalogue?.childrenCatalogues?.length > 0 && (
                                        _.map(catalogue.childrenCatalogues, (catalogue) => {
                                            return this.catalogueRecursionRender(catalogue, companyId);
                                        })
                                    )
                                }
                            </div>
                        </div>
                    </div>
                );
            } else return null;
        }
    };

    newOneServicesMaker = (services) => {
        return _.reduce(services, (result, service) => {
            if (!_.find(this.props.links, ['ext_id', service.id])) {
                result.push(service);
            }
            return result;
        }, []);
    };

    makeTemplateActive = (template) => {
        const currentService = [];
        _.map(template.services, service => {
            if (service.company.id === this.state.companyId && !service?.isHidden) {
                currentService.push(service);
            }
        });
        this.setState({ serviceId: currentService[0].id });
        this.state.activeTemplate !== template.id ? this.setState({ activeTemplate: template.id }) : this.setState({ activeTemplate: '' });
    };

    showDeleteButton = (template) => {
        this.setState({ showDelete: template.id });
    };

    hideDeleteButton = () => {
        this.setState({ showDelete: false });
    };

    render() {
        const partner = localStorage.getItem('partner');
        const newPartner = JSON.parse(partner);
        const companyId = newPartner ? newPartner.company_id : 0;
        const ortusServices = this.props.ortusServices;
        const oneServices = this.props.oneServices;
        const newOneServices = this.newOneServicesMaker(oneServices);
        if (!_.isEmpty(ortusServices) && !_.isEmpty(oneServices)) {
            return (
                <div className={style.svBookDetails}>
                    <div className={style.buttonPanel}>
                        <div
                            onClick={() => this.setState({ type: 'notConnected' })}
                            className={`${style.buttonFirst} ${this.state.type === 'notConnected' ? style.active : null}`}
                        >Несвязанные
                        </div>
                        <div className={style.borderBox} />
                        <div
                            onClick={() => this.setState({ type: 'connected' })}
                            className={`${style.button} ${this.state.type === 'connected' ? style.active : null}`}
                        >Связанные
                        </div>
                        <div className={style.borderBox} />
                        <div
                            onClick={() => this.setState({ type: 'all' })}
                            className={`${style.buttonLast} ${this.state.type === 'all' ? style.active : null}`}
                        >Все
                        </div>
                    </div>
                    <div className={style.mainContainer}>
                        <div className={style.ortusContainer}>
                            <div className={style.tableTopOrtus}>
                                <div className={style.firstTableTop}>Услуги Ortus</div>
                                <div className={style.secondTableTop}>Услуги 1С - связанные</div>
                            </div>
                            { _.map(ortusServices.items, catalogue => {
                                return (
                                    this.catalogueRecursionRender(catalogue, companyId)
                                );
                            }) }
                        </div>
                        <div
                            className={`${style.oneServicesContainer} ${this.state.activeTemplate ? style.green : ''}`}
                        >
                            <div className={style.tableTopOne}>
                                <div className={style.thirdTableTop}>Услуги 1С - несвязанные</div>
                            </div>
                            { _.map(newOneServices, service => {
                                return (
                                    <div
                                        className={`${style.oneServiceItem} ${this.state.activeTemplate ? style.active : ''}`}
                                        key={service.id}
                                        onClick={() => this.state.activeTemplate ? this.makeNewLink(service.id) : null}
                                        title={service.id}
                                    >
                                        { service.name }
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                    <Loader active={this.props.fetching} classes={['fixed']} />
                </div>
            );
        } else return <Loader active />;
    };
}

Connection.propTypes = {
    fetch1CServices: PropTypes.func,
    fetchOrtusServices: PropTypes.func,
    fetchLinks: PropTypes.func,
    deleteLink: PropTypes.func,
    makeLink: PropTypes.func,
    oneServices: PropTypes.array,
    links: PropTypes.array,
    fetching: PropTypes.bool,
    ortusServices: PropTypes.object
};

const mapStateToProps = (state) => ({
    fetching: state.connectionReducer.fetching,
    oneServices: state.connectionReducer.oneServices,
    ortusServices: state.connectionReducer.ortusServices,
    links: state.connectionReducer.links
});
const mapDispatchToProps = {
    fetch1CServices,
    fetchOrtusServices,
    fetchLinks,
    makeLink,
    deleteLink
};

export default connect(mapStateToProps, mapDispatchToProps)(Connection);
