export const WEEKDAYS = [
    { value: 1, title: 'Понедельник' },
    { value: 2, title: 'Вторник' },
    { value: 3, title: 'Среда' },
    { value: 4, title: 'Четверг' },
    { value: 5, title: 'Пятница' },
    { value: 6, title: 'Суббота' },
    { value: 7, title: 'Воскресенье' }
];

export const HOURS_COUNT_OBJ = [...new Array(24).keys()].map(item => {
    return {
        value: (item + 1),
        title: '' + (item + 1)
    };
});

export const MINUTES_COUNT_OBJ = [...new Array(12).keys()].map(item => {
    return {
        value: (item * 5),
        title: `${item * 5}`.padStart(2, '0')
    };
});
