import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicatorBlock from '../../../../../../components/LoadingIndicator/LoadingIndicatorBlock';
import ReportProlongationTable from '../ReportProlongationTable';
import style from './ProlongationReportBody.module.scss';

const ProlongationReportBody = ({ statisticYearProlongation, loading }) => {
    return (
        <div className={style.prolongationBody}>
            {
                loading
                    ? <LoadingIndicatorBlock reasonLoader={loading} />
                    : ''
            }
            <ReportProlongationTable statisticYearProlongation={statisticYearProlongation} />
        </div>
    );
};

ProlongationReportBody.propTypes = {
    statisticYearProlongation: PropTypes.array,
    loading: PropTypes.bool
};

export default ProlongationReportBody;
