
import { typeLogout } from '../actionsTypes';
// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_AUTH = 'requestAuth';

const SET_USER = 'setUser';

const RESET_ERROR = 'resetError';
const SET_ERROR = 'setError';

const CHANGE_VALUE = 'changeValue';

const SET_ACTIVATION_KEY = 'setActivationKey';

const CHANGE_REMIND_STEP = 'changeRemindStep';

const LOGOUT = typeLogout;

// ------------------------------------
// Actions
// ------------------------------------
export const authUser = (apiService, dispatch) => (data) => {
    dispatch(requestAuth());
    return apiService.authUser(data)
        .then((res) => {
            if (res && res.token && res.partner) {
                return dispatch(setUser(res));
            }
        })
        .catch((error) => {
            return dispatch(setError(error));
        });
};

function requestAuth() {
    return {
        type: REQUEST_AUTH
    };
}

export const setUser = (tokenAndUser) => {
    return {
        type: SET_USER,
        payload: tokenAndUser
    };
};

export const resetError = () => {
    return {
        type: RESET_ERROR
    };
};

export const changeValue = (name, value) => {
    return {
        type: CHANGE_VALUE,
        payload: {
            name: name,
            value: value
        }
    };
};

export const setError = (error) => {
    fetchLogout();
    return {
        type: SET_ERROR,
        payload: error
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const fetchLogout = () => (dispatch, getState, { apiService }) => {
    return apiService.fetchLogoutApi()
        .then(() => {
            return dispatch(logout());
        })
        .catch((err) => {
            return dispatch(setError(err));
        });
};

export const passwordRestore = (apiService, dispatch) => (data) => {
    dispatch(requestAuth());
    return apiService.passwordRestore(data)
        .then((res) => {
            if (res && res.restoreData && res.restoreData.activationKey && res.restoreData.id) {
                return dispatch(setActivationKey(res.restoreData));
            }
        })
        .catch((error) => {
            return dispatch(setError(error));
        });
};

function setActivationKey(res) {
    return {
        type: SET_ACTIVATION_KEY,
        payload: res
    };
}

export const passwordConfirm = (apiService, dispatch) => (data) => {
    dispatch(requestAuth());
    return apiService.passwordConfirm(data)
        .then((res) => {
            if (res && res.token && res.partner) {
                return dispatch(setUser(res));
            }
        })
        .catch((error) => {
            return dispatch(setError(error));
        });
};

export const changeRemindStep = (step) => {
    return {
        type: CHANGE_REMIND_STEP,
        payload: step
    };
};

export const actions = {
    setUser,
    changeValue,
    fetchLogout,
    passwordRestore,
    passwordConfirm,
    resetError,
    changeRemindStep
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
    [REQUEST_AUTH]: (state) => {
        return ({ ...state, fetchingAuth: true });
    },
    [SET_USER]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('partner', JSON.stringify(action.payload.partner));
        return ({ ...state, partner: action.payload.partner, token: action.payload.token, fetchingAuth: false });
    },
    [LOGOUT]: (state) => {
        localStorage.removeItem('token');
        localStorage.removeItem('partner');
        return ({ ...state, partner: null, token: null });
    },
    [RESET_ERROR]: (state) => {
        return ({ ...state, error: null });
    },
    [CHANGE_VALUE]: (state, action) => {
        return ({ ...state, [action.payload.name]: action.payload.value, error: null });
    },
    [SET_ERROR]: (state, action) => {
        return ({ ...state, error: action.payload, password: '', fetchingAuth: false });
    },
    [SET_ACTIVATION_KEY]: (state, action) => {
        return ({ ...state, activationKey: action.payload.activationKey, ownerId: action.payload.id, remindStep: 'sendCode', fetchingAuth: false });
    },
    [CHANGE_REMIND_STEP]: (state, action) => {
        return ({ ...state, remindStep: action.payload, error: null });
    }
};

let initialState = {
    phone: '',
    password: '',
    partner: null,
    token: null,
    error: null,
    activationKey: null,
    ownerId: null,
    remindStep: 'sendPhone',
    fetchingAuth: false
};

const partner = window.localStorage.getItem('partner');
const token = window.localStorage.getItem('token');
if (partner && token) {
    // Object.assign(initialState, { partner: JSON.parse(partner), token: token });
    initialState = { ...initialState, partner: JSON.parse(partner), token: token };
}

export default function authReducer(state, action) {
    if (state === undefined) {
        return initialState;
    }
    // console.log(Object.assign({}, initialState, state.auth))
    // state.auth = Object.assign({}, initialState, state.auth);

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.auth, action) : state.auth;
}
