import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './OilChangeType.module.scss';
import { fetchPostCarOil } from 'actions';
import InputWithStyle from '../../../../../../components/Inputs/InputWithStyle';

export const FieldText = ({ value, setValue, carOilUpdateError, label, placeholderText, id, keyId }) => {
    return (
        <div className={style.lines}>
            <label htmlFor={id} className={style.oilChoice}>{ label }</label>
            {
                carOilUpdateError
                    ? <div>Something is wrong</div>
                    : <InputWithStyle id={id} value={value} keyId={keyId} placeholderText={placeholderText} setValue={setValue} classes={['font16']} />
            }
        </div>
    );
};

FieldText.propTypes = {
    id: PropTypes.string,
    keyId: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    label: PropTypes.string,
    placeholderText: PropTypes.string
};

const OilChangeType = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fieldName,
    fetchPostCarOil,
    carOilUpdateError
}) => {
    const [value, setValue] = useState(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');

    useEffect(() => {
        setValue(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');
    }, [carDetailsInfo, fieldName]);

    const handleUpdate = (e, value, fieldName) => {
        e.preventDefault();

        const post = {
            car_id: id,
            [fieldName]: '' + value
        };

        id && (value || value === '') && fetchPostCarOil(post, clientIdCollect);
    };

    return (
        <form onSubmit={(e) => handleUpdate(e, value, fieldName)} onBlur={(e) => handleUpdate(e, value, fieldName)}>
            <FieldText
                value={value}
                setValue={setValue}
                carOilUpdateError={carOilUpdateError}
                label='Марка масла'
                placeholderText='Введите марку масла'
                id='oilMark'
                keyId='oilChange'
            />
        </form>
    );
};

OilChangeType.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldName: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilChangeType);
