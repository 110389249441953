import React, { useEffect } from 'react';
import { HeaderSettingsInBody } from '../SettingsHeader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    fetchCollectBlocks,
    deleteReason,
    fetchNewReason,
    updateCarCollectBlock
} from '../../../actions';
import {
    fetchInsurance,
    createOrChangeInsurance,
    deleteInsurance
} from '../../../reducers/autoInsurance';
import {
    getSeasonPeriodTimes,
    storeSeasonPeriodTimes,
    delSeasonPeriodTimes,
    seasonPeriodTimesStore
} from '../../../reducers/settingsTires';
import {
    setSeasons,
    getSeasonsTires,
    updateSeasonTires,
    delSeasonsTires,
    delSeasonsPeriodTires,
    getSeasonPeriodsTires,
    setUpdateSeasonPeriods,
    updateSeasonsPeriod,
    getPacketsSeasons,
    setPackets
} from '../../../reducers/settingsSeasons';
import SettingsHandbook from './components/SettingsHandbook';
import style from './Handbooks.module.scss';
import { delOilReferences, fetchOilReferences, updateCreateOilReferences } from '../../../reducers/settingsOil';
import Loader from '../../../components/Loader';

const Handbooks = ({
    reasons,
    deleteReason,
    fetchNewReason,
    blocks,
    updateCarCollectBlock,
    updateSeasonTires,
    getSeasonPeriodsTires,
    setUpdateSeasonPeriods,
    seasonPeriods,
    getSeasonsTires,
    setSeasons,
    getPacketsSeasons,
    setPackets,
    delSeasonsTires,
    updateSeasonsPeriod,
    delSeasonsPeriodTires,
    updateSeasonPeriodsSuccess,
    seasonsTiresSuccess,
    isReasonsSuccess,
    insurance,
    fetchInsurance,
    createOrChangeInsurance,
    deleteInsurance,
    seasonPeriodTimes,
    seasonPeriodTimesSuccess,
    getSeasonPeriodTimes,
    storeSeasonPeriodTimes,
    seasonPeriodTimesStore,
    delSeasonPeriodTimes,
    fetchCollectBlocks,
    oilReferences,
    fetchOilReferences,
    updateCreateOilReferences,
    delOilReferences,
    isOilReferencesLoading,
    seasons = []
}) => {
    useEffect(() => {
        getPacketsSeasons();
    }, [getPacketsSeasons]);

    useEffect(() => {
        Array.isArray(oilReferences) && oilReferences.length === 0 && fetchOilReferences();
    }, [fetchOilReferences, oilReferences]);

    useEffect(() => {
        return () => setPackets([]);
    }, [setPackets]);

    return (
        <div className={style.svBookDetails}>
            <div className={style.svColumn}>
                {
                    isOilReferencesLoading
                        ? <Loader active={isOilReferencesLoading} classes={['grayLight']} />
                        : ''
                }
                <div className={style.clients + ' ' + style.shadowBlock}>
                    <HeaderSettingsInBody text='Настройка справочников' />
                </div>

                <SettingsHandbook
                    id='reasonsHandbooks'
                    keyId='reasonsCRUD'
                    initialHiddenHeader
                    headerText='Причины'
                    items={reasons}
                    actionDelete={deleteReason}
                    actionUpdate={fetchNewReason}
                    pairHeadersWithBtnAddName={['id', 'Причина', 'Добавить причину']}
                    fields={['id', 'title', 'label']}
                    name='reason'
                    isSuccess={isReasonsSuccess}
                    delBtn
                    updBtn
                    addBtn
                />

                <SettingsHandbook
                    id='blocksHandbooks'
                    keyId='blocksCRUD'
                    initialHiddenHeader
                    headerText='Блоки'
                    items={blocks}
                    actionGet={fetchCollectBlocks}
                    actionDelete={() => {}}
                    actionUpdate={updateCarCollectBlock}
                    pairHeadersWithBtnAddName={['id', 'Название блока', 'Добавить блок']}
                    fields={['id', 'title']}
                    name='blocks'
                    isSuccess={blocks && blocks.length === 0}
                    updBtn
                />

                <SettingsHandbook
                    id='seasonPeriodTimesHandbooks'
                    keyId='seasonPeriodTimesCRUD'
                    initialHiddenHeader
                    headerText='Даты периодов сезонов шиномонтажа'
                    items={seasonPeriodTimes}
                    actionGet={getSeasonPeriodTimes}
                    actionClear={seasonPeriodTimesStore}
                    actionDelete={delSeasonPeriodTimes}
                    actionUpdate={storeSeasonPeriodTimes}
                    pairHeadersWithBtnAddName={['id', 'Начало периода сезона', 'Добавить даты периодов сезона']}
                    fields={['id', 'date_start', 'date_end', 'season_period_id', 'season_id']}
                    representations={[
                        { field: 'season_id', type: 'select', options: [{ id: 0, title: '' }, ...seasons], fieldNameOption: 'title', order: 1 },
                        { field: 'season_period_id', type: 'select', options: [{ id: 0, title: '' }, ...seasonPeriods], fieldNameOption: 'title', order: 2 },
                        { field: 'date_start', type: 'date', order: 3 },
                        { field: 'date_end', type: 'date', order: 4 }
                    ]}
                    name='seasonPeriodTimes'
                    isSuccess={seasonPeriodTimesSuccess}
                    delBtn
                    updBtn
                    addBtn
                />

                <SettingsHandbook
                    id='seasonsHandbooks'
                    keyId='seasonsCRUD'
                    initialHiddenHeader
                    headerText='Сезоны'
                    items={seasons}
                    actionGet={getSeasonsTires}
                    actionClear={setSeasons}
                    actionDelete={delSeasonsTires}
                    actionUpdate={updateSeasonTires}
                    pairHeadersWithBtnAddName={['id', 'Сезон', 'Добавить сезон']}
                    fields={['id', 'title', 'label', 'settings_title']}
                    name='seasons'
                    isSuccess={seasonsTiresSuccess}
                    delBtn
                    updBtn
                    addBtn
                />

                <SettingsHandbook
                    id='seasonsPeriodsHandbooks'
                    keyId='seasonsPeriodsCRUD'
                    initialHiddenHeader
                    headerText='Периоды сезонов'
                    items={seasonPeriods}
                    actionGet={getSeasonPeriodsTires}
                    actionClear={setUpdateSeasonPeriods}
                    actionDelete={delSeasonsPeriodTires}
                    actionUpdate={updateSeasonsPeriod}
                    pairHeadersWithBtnAddName={['id', 'Периоды сезонов', 'Добавить период сезона']}
                    fields={['id', 'title', 'label']}
                    name='seasonsPeriods'
                    isSuccess={updateSeasonPeriodsSuccess}
                    delBtn
                    updBtn
                    addBtn
                />

                <SettingsHandbook
                    id='autoInsuranceHandbooks'
                    keyId='autoInsuranceCRUD'
                    initialHiddenHeader
                    headerText='Автострахование'
                    items={insurance}
                    actionGet={fetchInsurance}
                    actionDelete={deleteInsurance}
                    actionUpdate={createOrChangeInsurance}
                    pairHeadersWithBtnAddName={['id', 'Страховая компания', 'Добавить страховую компанию']}
                    fields={['id', 'title']}
                    name='autoInsurance'
                    delBtn
                    updBtn
                    addBtn
                />

                {
                    oilReferences.map(item => {
                        return (
                            <SettingsHandbook
                                key={item.label + 'oil_references'}
                                id={item.label + 'oil_references'}
                                keyId={item.label + 'oil_references_CRUD'}
                                initialHiddenHeader
                                headerText={item.title}
                                items={item.references}
                                actionGet={() => {}}
                                actionDelete={delOilReferences}
                                actionUpdate={updateCreateOilReferences}
                                pairHeadersWithBtnAddName={['id', item.title, 'Добавить']}
                                fields={['id', 'title', 'label']}
                                name={item.label}
                                delBtn
                                updBtn
                                addBtn
                            />
                        );
                    })
                }
            </div>
        </div>
    );
};

Handbooks.propTypes = {
    reasons: PropTypes.array,
    deleteReason: PropTypes.func,
    fetchNewReason: PropTypes.func,
    updateCarCollectBlock: PropTypes.func,
    blocks: PropTypes.array,
    seasons: PropTypes.array,
    getSeasonPeriodsTires: PropTypes.func,
    setUpdateSeasonPeriods: PropTypes.func,
    getSeasonsTires: PropTypes.func,
    setSeasons: PropTypes.func,
    getPacketsSeasons: PropTypes.func,
    setPackets: PropTypes.func,
    delSeasonsTires: PropTypes.func,
    updateSeasonsPeriod: PropTypes.func,
    updateSeasonPeriodsSuccess: PropTypes.bool,
    seasonsTiresSuccess: PropTypes.bool,
    isReasonsSuccess: PropTypes.bool,
    delSeasonsPeriodTires: PropTypes.func,
    updateSeasonTires: PropTypes.func,
    seasonPeriods: PropTypes.array,
    insurance: PropTypes.array,
    fetchInsurance: PropTypes.func,
    createOrChangeInsurance: PropTypes.func,
    deleteInsurance: PropTypes.func,
    getSeasonPeriodTimes: PropTypes.func,
    seasonPeriodTimesSuccess: PropTypes.bool,
    seasonPeriodTimes: PropTypes.array,
    delSeasonPeriodTimes: PropTypes.func,
    storeSeasonPeriodTimes: PropTypes.func,
    fetchCollectBlocks: PropTypes.func,
    seasonPeriodTimesStore: PropTypes.func,
    oilReferences: PropTypes.array,
    fetchOilReferences: PropTypes.func,
    updateCreateOilReferences: PropTypes.func,
    delOilReferences: PropTypes.func,
    isOilReferencesLoading: PropTypes.bool
};

const mapStateToProps = (
    {
        templatesOfFrameState: {
            reasons,
            blocks,
            isReasonsSuccess
        },
        autoInsuranceReducer: { insurance },
        settingsTires: { seasonPeriodTimes, seasonPeriodTimesSuccess },
        settingsSeasons: {
            seasonsTiresSuccess,
            packets,
            seasons,
            updateSeasonPeriodsSuccess,
            seasonPeriods
        },
        settingsOil: {
            oilReferences,
            isOilReferencesLoading
        }
    }) => {
    return {
        reasons,
        blocks,
        seasons,
        packets,
        seasonPeriods,
        updateSeasonPeriodsSuccess,
        seasonsTiresSuccess,
        isReasonsSuccess,
        insurance,
        seasonPeriodTimes,
        seasonPeriodTimesSuccess,
        oilReferences,
        isOilReferencesLoading
    };
};

export default connect(mapStateToProps, {
    deleteReason,
    fetchCollectBlocks,
    fetchNewReason,
    updateCarCollectBlock,
    updateSeasonTires,
    getSeasonPeriodsTires,
    setUpdateSeasonPeriods,
    getSeasonsTires,
    setSeasons,
    getPacketsSeasons,
    setPackets,
    delSeasonsTires,
    updateSeasonsPeriod,
    delSeasonsPeriodTires,
    fetchInsurance,
    createOrChangeInsurance,
    deleteInsurance,
    getSeasonPeriodTimes,
    storeSeasonPeriodTimes,
    delSeasonPeriodTimes,
    seasonPeriodTimesStore,
    fetchOilReferences,
    updateCreateOilReferences,
    delOilReferences
})(Handbooks);
