import compareDaysMonthFirstGreater from './compareDaysMonthFirstGreater';
import { AUTUMN_SEASON_SHOW, SPRING_SEASON_SHOW, AUTUMN_SEASON_START_COLLECT, SPRING_SEASON_START_COLLECT } from '../constants/seasonTimeStartCollect';

export default function selectSeasonTires(dataNow, dateFirstSeason = AUTUMN_SEASON_START_COLLECT, dateSecondSeason = SPRING_SEASON_START_COLLECT, AUTUMN_SEASON = AUTUMN_SEASON_SHOW, SPRING_SEASON = SPRING_SEASON_SHOW) {
    const dataNowLocal = dataNow;

    let result;
    if (compareDaysMonthFirstGreater(dataNowLocal, dateFirstSeason) && compareDaysMonthFirstGreater(dateSecondSeason, dataNowLocal)) {
        result = AUTUMN_SEASON;
    }

    if (compareDaysMonthFirstGreater(dataNowLocal, dateSecondSeason) || compareDaysMonthFirstGreater(dateFirstSeason, dataNowLocal)) {
        result = SPRING_SEASON;
    }

    if (!compareDaysMonthFirstGreater(dateFirstSeason, dataNowLocal) && !compareDaysMonthFirstGreater(dataNowLocal, dateFirstSeason)) {
        result = SPRING_SEASON;
    }

    if (!compareDaysMonthFirstGreater(dateSecondSeason, dataNowLocal) && !compareDaysMonthFirstGreater(dataNowLocal, dateSecondSeason)) {
        result = AUTUMN_SEASON;
    }

    return result;
}
