import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import LoadingIndicatorBlock from '../../../components/LoadingIndicator/LoadingIndicatorBlock';
import { fetchResourcesCompany } from '../../../reducers/settings';
import style from './OwnerSettingsHeader.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchAllReasons } from '../../../actions';
import { fetchSeasonsPeriods, seasonsPeriodsLoaded } from '../../../reducers/settingsSeasons';

const OwnerSettingsHeader = ({ partner, fetchResourcesCompany, fetchAllReasons, fetchSeasonsPeriods, seasonsPeriodsLoaded }) => {
    const src = false;
    const fetchCarDetailsSuccess = false;

    useEffect(() => {
        fetchResourcesCompany();
    }, [fetchResourcesCompany]);

    useEffect(() => {
        fetchAllReasons();
    }, [fetchAllReasons]);

    useEffect(() => {
        fetchSeasonsPeriods();
    }, [fetchSeasonsPeriods]);

    useEffect(() => {
        return () => seasonsPeriodsLoaded({});
    }, [seasonsPeriodsLoaded]);

    return (
        <div className={style.svBookHeaderContainer}>
            <div className={style.svBookHeader}>
                <div className={style.carInfo}><h1>Настройки</h1></div>
                <div className={style.carInfo}>
                    <div className={style.carImgInfo}>
                        <div className={style.imgIn}>
                            { src
                                ? <img src={src} className={style.mainImg} alt='' />
                                : null }
                        </div>
                        <div className={style.carAndVisitorInfo}>
                            <LoadingIndicatorBlock reasonLoader={fetchCarDetailsSuccess} />
                            <p>{ partner.owner.name }</p>
                            <p>{ partner.owner.phone }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.routes}>
                <NavLink activeClassName={style.active} to='/settings/users-owners' replace exact>
                    <div className={style.text}>Пользователи</div>
                </NavLink>
                <NavLink activeClassName={style.active} to='/settings/other' replace>
                    <div className={style.text}>Настройки листов сбора</div>
                </NavLink>
                <NavLink activeClassName={style.active} to='/settings/handbooks' replace>
                    <div className={style.text}>Справочники</div>
                </NavLink>
                <NavLink activeClassName={style.active} to='/settings/1cconnection' replace>
                    <div className={style.text}>Связь услуг с 1С</div>
                </NavLink>
            </div>
        </div>
    );
};

OwnerSettingsHeader.propTypes = {
    partner: PropTypes.object,
    fetchResourcesCompany: PropTypes.func,
    fetchAllReasons: PropTypes.func,
    fetchSeasonsPeriods: PropTypes.func,
    seasonsPeriodsLoaded: PropTypes.func
};

const mapStateToProps = ({ auth: { partner } }) => {
    return {
        partner
    };
};

export default connect(mapStateToProps, { fetchResourcesCompany, fetchAllReasons, fetchSeasonsPeriods, seasonsPeriodsLoaded })(OwnerSettingsHeader);
