import {
    typeSetStatusesConfig,
    typeDeleteClientFromCar,
    typeSetStatusesConfigSuccess,
    typeSetStatusesConfigError,
    typeSetNewCarClientStatuses,
    typeSetNewCarClientStatusesError,
    typeSetNewCarClientStatusesSuccess,
    typeSetMessagesErrorSendStatus,
    typeSetCreateNewClientInCarError,
    typeSetCreateNewClientInCarSuccess,
    typeSaveReservePostCreateNewClientData,
    typeChangeNewCallClient,
    typeAddSearchClientsData,
    typeSetFoundClientsData,
    typeClearFoundClientsData,
    typeSearchClientsStatusesSuccess
} from '../actionsTypes';
import _ from 'lodash';
import { createUniqueArrItems, isNotEmpty } from '../utils';
import { carDetailsUpload } from '../actions';
import { carOccupancyRateUpdate } from './car';

// ------------------------------------
// Constants
// ------------------------------------
const SET_CLIENT_STATUSES_CONFIG = typeSetStatusesConfig;
const SET_CLIENT_STATUSES_CONFIG_SUCCESS = typeSetStatusesConfigSuccess;
const SET_CLIENT_STATUSES_CONFIG_ERROR = typeSetStatusesConfigError;
const SET_DATA_CHANGE_CLIENT = typeChangeNewCallClient;
const DELETE_CLIENT_FROM_CAR = typeDeleteClientFromCar;
const SET_NEW_CAR_CLIENT_STATUSES = typeSetNewCarClientStatuses;
const SET_NEW_CAR_CLIENT_STATUSES_ERROR = typeSetNewCarClientStatusesError;
const SET_NEW_CAR_CLIENT_STATUSES_SUCCESS = typeSetNewCarClientStatusesSuccess;
const SET_MESSAGES_ERROR_SEND_STATUS = typeSetMessagesErrorSendStatus;
const SET_CREATE_NEW_CLIENT_IN_CAR_ERROR = typeSetCreateNewClientInCarError;
const SET_CREATE_NEW_CLIENT_IN_CAR_SUCCESS = typeSetCreateNewClientInCarSuccess;
const SAVE_POST_CREATE_NEW_CLIENT_DATA = typeSaveReservePostCreateNewClientData;
const ADD_SEARCH_CLIENTS_DATA = typeAddSearchClientsData;
const SET_FOUND_CLIENTS_DATA = typeSetFoundClientsData;
const CLEAR_FOUND_CLIENTS_DATA = typeClearFoundClientsData;
const SEARCH_CLIENTS_STATUSES_SUCCESS = typeSearchClientsStatusesSuccess;

// ------------------------------------
// Actions
// ------------------------------------

export const setAllStatusesError = (isError) => {
    return {
        type: SET_CLIENT_STATUSES_CONFIG_ERROR,
        payload: isError
    };
};

export const setAllStatusesSuccess = (isSuccess) => {
    return {
        type: SET_CLIENT_STATUSES_CONFIG_SUCCESS,
        payload: isSuccess
    };
};

export const setAllStatuses = (statuses) => {
    return {
        type: SET_CLIENT_STATUSES_CONFIG,
        payload: statuses
    };
};

export const setNewDataClient = (data) => {
    return {
        type: SET_DATA_CHANGE_CLIENT,
        payload: data
    };
};

export const deleteClientFromCarInfo = (id) => {
    return {
        type: DELETE_CLIENT_FROM_CAR,
        payload: id
    };
};

export const setMessageErrorStatus = (message) => {
    return {
        type: SET_MESSAGES_ERROR_SEND_STATUS,
        payload: message
    };
};

export const putNewDataClient = (id, data) => (dispatch, getState, { apiService }) => { // ToDo
    return id && apiService.putVisitor(id, data)
        .then((res) => {
            const idx = getState().carDetailsState.carDetailsInfo.clients.findIndex(item => item.id === res.id);
            dispatch(setNewDataClient({
                ...res,
                companyNick: res.nick,
                statuses: getState().carDetailsState.carDetailsInfo.clients[idx].statuses
            }));
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const getStatusesCarClient = (carId, clientId) => (dispatch, getState, { apiService }) => {
    return apiService.getStatuses(carId, clientId)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err;
        });
};

// -------->set statuses

export const setNewStatusesForClient = (data) => {
    return {
        type: SET_NEW_CAR_CLIENT_STATUSES,
        payload: data
    };
};

export const setNewStatusesForClientError = (isError) => {
    return {
        type: SET_NEW_CAR_CLIENT_STATUSES_ERROR,
        payload: isError
    };
};

export const setNewStatusesForClientSuccess = (isError) => {
    return {
        type: SET_NEW_CAR_CLIENT_STATUSES_SUCCESS,
        payload: isError
    };
};

export const setStatusesCarClient = (data = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setNewStatusesForClientError(false));
    dispatch(setNewStatusesForClientSuccess(true));
    const isClientExist = getState()?.carDetailsState?.carDetailsInfo?.statuses?.find(client => client?.client_id === data.clientId);
    return apiService.storingStatuses(data)
        .then((res) => {
            dispatch(setNewStatusesForClientSuccess(false));
            dispatch(setNewStatusesForClient({ carId: data.carId, clientId: data.clientId, statuses: res.statuses }));
            if (!isClientExist && data.carId && data.clientId) {
                dispatch(carDetailsUpload(data.carId, data.clientId, false));
            }
            dispatch(carOccupancyRateUpdate(res.occupancy_rate_blocks));
            return res;
        })
        .catch((err) => {
            dispatch(setNewStatusesForClientSuccess(false));
            dispatch(setNewStatusesForClientError(true));
            return err;
        });
};

// <--------set statuses

export const getAllStatusesCarClients = () => (dispatch, getState, { apiService }) => {
    dispatch(setAllStatusesSuccess(true));
    dispatch(setAllStatusesError(false));
    return apiService.getConfigStatuses()
        .then((res) => {
            dispatch(setAllStatuses(res));
            dispatch(setAllStatusesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setAllStatusesSuccess(false));
            dispatch(setAllStatusesError(true));
            return err;
        });
};

export const handleSaveDataBeforeFetch = (data) => {
    return {
        type: SAVE_POST_CREATE_NEW_CLIENT_DATA,
        payload: data
    };
};

export const postCreateNewClientSuccess = (isSuccess) => {
    return {
        type: SET_CREATE_NEW_CLIENT_IN_CAR_SUCCESS,
        payload: isSuccess
    };
};

export const postCreateNewClientError = (isError) => {
    return {
        type: SET_CREATE_NEW_CLIENT_IN_CAR_ERROR,
        payload: isError
    };
};

export const postCreateNewClient = (data) => (dispatch, getState, { apiService }) => {
    dispatch(handleSaveDataBeforeFetch({ method: 'postCreateNewClient', arg: data, delete: false }));
    dispatch(postCreateNewClientSuccess(true));
    dispatch(postCreateNewClientError(false));
    return apiService.createClient(data)
        .then((res) => {
            dispatch(handleSaveDataBeforeFetch({ method: 'postCreateNewClient', arg: data, delete: true }));
            dispatch(postCreateNewClientSuccess(false));
            dispatch(postCreateNewClientError(false));
            dispatch(setNewDataClient(res.client));
            dispatch(carOccupancyRateUpdate(res.occupancy_rate_blocks));
            return res;
        })
        .catch((err) => {
            // show new form with  data for store
            dispatch(postCreateNewClientSuccess(false));
            dispatch(postCreateNewClientError(true));
            return err;
        });
};

export const deleteClientFromCar = (carId, clientId) => (dispatch, getState, { apiService }) => {
    if (carId && clientId) {
        dispatch(postCreateNewClientSuccess(true));
        dispatch(postCreateNewClientError(false));
    }
    return carId && clientId && apiService.deleteClient(carId, clientId)
        .then((res) => {
            res.delete && dispatch(deleteClientFromCarInfo(clientId));
            dispatch(postCreateNewClientSuccess(false));
            dispatch(postCreateNewClientError(false));
            return res;
        })
        .catch((err) => {
            dispatch(postCreateNewClientSuccess(false));
            dispatch(postCreateNewClientError(true));
            return err;
        });
};

export const setMessagesErrorSendStatus = (message) => (dispatch) => {
    dispatch(setMessageErrorStatus(message));
};

export const resendingDataSaveClient = (method) => (dispatch, getState) => {
    const forSaveAgain = getState().statusesClientCar.savedDataBeforeFetch;

    const needItem = forSaveAgain.find(item => item.method === method);
    if (needItem) {
        needItem && Object.prototype.hasOwnProperty.call(needItem, 'arg') && dispatch(postCreateNewClient(needItem.arg));
    }
};

export const searchNewClient = (data) => (dispatch, getState, { apiService }) => {
    dispatch(addSearchClientsData(data));
    if (isNotEmpty(data)) {
        dispatch(searchExistClients(data));
    } else {
        dispatch(clearFoundClientsData());
    }
};

export const addSearchClientsData = (search) => {
    return {
        type: ADD_SEARCH_CLIENTS_DATA,
        payload: search
    };
};

export const setFoundClientsData = (clients) => {
    return {
        type: SET_FOUND_CLIENTS_DATA,
        payload: clients
    };
};

export const clearFoundClientsData = () => {
    return {
        type: CLEAR_FOUND_CLIENTS_DATA
    };
};

export const setSearchClientsSuccess = (isLoad) => {
    return {
        type: SEARCH_CLIENTS_STATUSES_SUCCESS,
        payload: isLoad
    };
};

export const searchExistClients = (data) => (dispatch, getState, { apiService }) => {
    // dispatch(setSearchVisitorsError(false));
    if (!getState().statusesClientCar.searchInfo.search || (getState().statusesClientCar.searchInfo.search && getState().statusesClientCar.searchInfo.search.length < 4)) {
        return;
    }
    dispatch(setSearchClientsSuccess(true));
    return apiService.searchVisitorsWithNamePhone({ ...getState().statusesClientCar.searchInfo })
        .then((res) => {
            dispatch(setFoundClientsData(res));

            dispatch(setSearchClientsSuccess(false));
            return res;
        })
        .catch((err) => {
            // dispatch(setSearchVisitorsError(true));
            dispatch(setSearchClientsSuccess(false));
            return err;
        });
};

export const actions = {
    getStatusesCarClient,
    setStatusesCarClient,
    getAllStatusesCarClients,
    putNewDataClient,
    postCreateNewClient,
    deleteClientFromCar,
    setMessagesErrorSendStatus,
    resendingDataSaveClient,
    handleSaveDataBeforeFetch,
    postCreateNewClientError,
    searchNewClient,
    searchExistClients
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SAVE_POST_CREATE_NEW_CLIENT_DATA]: (state, action) => {
        const savedDataBeforeFetchNew = _.cloneDeep(state.savedDataBeforeFetch);
        const dataIdx = savedDataBeforeFetchNew.length > 0
            ? savedDataBeforeFetchNew.findIndex(item => item.method === action.payload.method)
            : -1;

        if (Object.prototype.hasOwnProperty.call(action.payload, 'delete') && action.payload.delete === true) {
            /*   Побитовое НЕ означает:    ~n = -(n+1)  */
            if (~dataIdx) {
                /* если у нас уже существуют сохраненные данные для посторного вызова данного метода, и мы хотим его удалить */
                return ({
                    ...state,
                    savedDataBeforeFetch: [
                        ...savedDataBeforeFetchNew.slice(0, dataIdx),
                        ...savedDataBeforeFetchNew.slice(dataIdx + 1)
                    ]
                });
            } else {
                /* если у нас не существует сохраненных данных для посторного вызова данного метода, то просто выводим предыдущее состояние state */
                return ({
                    ...state
                });
            }
        } if (Object.prototype.hasOwnProperty.call(action.payload, 'delete') && action.payload.delete === false) {
            /* если мы хотим сохранить данные для вызова метода и у нас пришли старые данные, то заменяем их на новые */
            const res = action.payload;

            /* удаляем ненужное нам свойство  */
            delete res.delete;
            if (~dataIdx) {
                /* при наличии уже сохраненных данных, мы их заменяем */
                return ({
                    ...state,
                    savedDataBeforeFetch: [
                        ...savedDataBeforeFetchNew.slice(0, dataIdx),
                        action.payload,
                        ...savedDataBeforeFetchNew.slice(dataIdx + 1)
                    ]
                });
            } else {
                /* при отсутствии уже сохраненных данных, мы их просто добавляем */
                return ({
                    ...state,
                    savedDataBeforeFetch: [
                        ...savedDataBeforeFetchNew,
                        action.payload
                    ]
                });
            }
        } else {
            return ({
                ...state
            });
        }
    },

    [SET_CREATE_NEW_CLIENT_IN_CAR_ERROR]: (state, action) => {
        return ({
            ...state,
            isCreateNewClientInCarError: action.payload
        });
    },

    [SET_CREATE_NEW_CLIENT_IN_CAR_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isCreateNewClientInCarSuccess: action.payload
        });
    },

    [SET_CLIENT_STATUSES_CONFIG]: (state, action) => {
        return ({
            ...state,
            statuses: action.payload
        });
    },

    [SET_CLIENT_STATUSES_CONFIG_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isStatusSuccess: action.payload
        });
    },

    [SET_CLIENT_STATUSES_CONFIG_ERROR]: (state, action) => {
        return ({
            ...state,
            isStatusError: action.payload
        });
    },

    [SET_MESSAGES_ERROR_SEND_STATUS]: (state, action) => {
        return ({
            ...state,
            messageErrorSendStatus: action.payload
        });
    },

    [SET_NEW_CAR_CLIENT_STATUSES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            changeClientStatusesSuccess: action.payload
        });
    },

    [SET_NEW_CAR_CLIENT_STATUSES_ERROR]: (state, action) => {
        return ({
            ...state,
            changeClientStatusesError: action.payload
        });
    },

    [ADD_SEARCH_CLIENTS_DATA]: (state, action) => {
        const searchInfo = _.cloneDeep(state.searchInfo);
        if (!action.payload) {
            return ({
                ...state,
                searchInfo: initialSearchClients
            });
        }
        return ({
            ...state,
            searchInfo: { ...searchInfo, ...action.payload }
        });
    },

    [SET_FOUND_CLIENTS_DATA]: (state, action) => {
        const uniqClients = createUniqueArrItems(action.payload.items, 'id');
        return ({
            ...state,
            foundClients: uniqClients,
            foundClientsTotal: action.payload.total
        });
    },

    [CLEAR_FOUND_CLIENTS_DATA]: (state) => {
        return ({
            ...state,
            foundClients: [],
            foundClientsTotal: 0
        });
    },

    [SEARCH_CLIENTS_STATUSES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isLoadSearchClient: action.payload
        });
    }

};

const initialSearchClients = {
    search: '',
    limit: 6,
    offset: 0
};

const statusesClientCarReducer = (state, action) => {
    if (state === undefined) {
        return {
            statuses: [],
            isStatusSuccess: false,
            isStatusError: false,
            clientStatuses: [],
            changeClientStatusesError: false,
            changeClientStatusesSuccess: false,
            messageErrorSendStatus: '',
            isCreateNewClientInCarSuccess: false,
            isCreateNewClientInCarError: false,
            reservePostCreateNewClientData: {},
            savedDataBeforeFetch: [
                /*
                {
                method: null,
                arg: []
                }
                */
            ],
            searchInfo: initialSearchClients,
            foundClients: [],
            foundClientsTotal: 0,
            isLoadSearchClient: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.statusesClientCar, action) : state.statusesClientCar;
};

export default statusesClientCarReducer;
