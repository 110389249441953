import React from 'react';
import CarNumberInbox from '../CarNumberInbox';
import PropTypes from 'prop-types';

import style from './CarBasicNumber.module.scss';

const CarBasicNumber = ({ regNumberCar, regNumberCarEnd }) => {
    return (
        <div className={style.carBasicNumber}>
            {
                regNumberCar
                    ? <CarNumberInbox regNumberCar={regNumberCar} regNumberCarEnd={regNumberCarEnd} />
                    : <div className={`${style.carNumber} ${style.carNull}`}>БЕЗ НОМЕРА</div>
            }
        </div>
    );
};

CarBasicNumber.propTypes = {
    regNumberCar: PropTypes.string,
    regNumberCarEnd: PropTypes.string
};

export default CarBasicNumber;
