import React from 'react';
import { SvBooksSearchHeader } from '../MiddleHeader';
import { SheetInspectionsHeader, SheetInspectionsBody } from '../../routes/SheetInspections';
import SheetCollectContainer from '../../routes/SheetCollect/SheetCollectContainer';
import UniqueSheetCollectContainer from '../../routes/SheetCollect/UniqueSheetCollectContainer';
import ServiceBooks from '../ServiceBooks';
import { GetCarDetails } from '../Requests';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { setError500 } from 'reducers/common';
import { connect } from 'react-redux';
import { OwnerSettings } from '../../routes/Settings';
import Visitors from '../../routes/Visitors/Visitors';
import Visitor from '../../routes/Visitor/Visitor';
import ErrorModal from '../ErrorModal';
import style from './MiddleInfo.module.scss';
import ReportsContainer from '../../routes/Reports';
import CollectInfoReport from '../../routes/Reports/ReportsBody/components/CollectInfoReport';
import HistoryReport from '../../routes/Reports/ReportsBody/components/HistoryReport';
import CollectionSheetsReport from '../../routes/Reports/ReportsBody/components/CollectionSheetsReport';
import { setOwnerIdSheets, setStatusesPreferSheets } from '../../reducers/reportsCollectionSheets';
import ProlongationReport from '../../routes/Reports/ReportsBody/components/ProlongationReport';
import ProlongationInAction from '../../routes/Reports/ReportsBody/components/ProlongationInAction';
import PreReservesReport from '../../routes/Reports/ReportsBody/components/PreReservesReport';
import getQueryVariable from '../../utils/getQueryVariable';
import PreReserveContainer from '../../routes/PreReserve/PreReserveContainer';

const MiddleInfo = ({ partner, error500andMore, setError500 }) => {
    return (
        <div className={style.middleInfo}>
            <Route path='/svbooks' component={SvBooksSearchHeader} />
            <Route path='/svbooks' component={ServiceBooks} />

            <Route path='/' component={SvBooksSearchHeader} exact />
            <Route path='/' component={ServiceBooks} exact />

            <Route path='/reports' component={ReportsContainer} exact />
            <Route path='/reports/collect-report' component={CollectInfoReport} exact />
            <Route path='/reports/prolongation' component={ProlongationReport} exact />
            <Route path='/reports/prolongation-in-action' render={() => <ProlongationInAction title='Пролонгация' />} exact />

            <Route
                path='/reports/prolongation-in-action/pre-reserves' render={({ location }) => {
                    return <PreReservesReport searchParams={location?.search} />;
                }}
            />

            <Route path='/reports/collection-sheets-report' exact>
                <Redirect to='/reports/collect-report' />
            </Route>

            <Route
                path='/reports/collection-sheets-report/:ownerId/:statusesPrefer' render={({ match }) => {
                    return <CollectionSheetsReport ownerId={+match?.params?.ownerId || 0} statusesPrefer={match?.params?.statusesPrefer || ''} />;
                }} exact
            />

            <Route path='/reports/history-report' exact>
                <Redirect to='/reports' />
            </Route>
            <Route
                path='/reports/history-report/:carId/:clientId' render={({ match }) => {
                    return <HistoryReport carId={+match?.params?.carId || 0} clientId={+match?.params?.clientId || 0} />;
                }} exact
            />

            <Route path='/visitors' component={Visitors} exact />

            <Route
                path='/collect/:id' render={({ match, location }) => {
                    const clientId = location?.search ? getQueryVariable(location?.search, 'client_id') : 0;
                    return (
                        <SheetCollectContainer
                            carIdRaw={+match?.params?.id || 0}
                            clientIdRaw={+location?.state?.clientVisitor?.id || +clientId || 0}
                            reserveOrderRaw={location?.state?.reserveOrOrder || {}}
                        />);
                }}
            />

            <Route
                path='/collection-sheet/:id' render={({ match }) => {
                    return (
                        <UniqueSheetCollectContainer
                            sheetIdRaw={+match?.params?.id || 0}
                        />);
                }}
            />

            <Route
                path='/reports/cars/:carId/pre-reserves/:id' render={({ match }) => {
                    return (
                        <PreReserveContainer
                            carId={+match?.params?.carId || 0}
                            id={+match?.params?.id || 0}
                        />
                    );
                }}
            />

            <Route path='/inspect' render={() => <SheetInspectionsHeader />} />
            <Route path='/inspect' render={() => <SheetInspectionsBody />} />

            <Route
                path='/svbook-details/:id' render={({ match }) => {
                    return <GetCarDetails id={+match?.params?.id || 0} />;
                }}
            />

            <Route
                path='/visitors/:id' render={({ match }) => {
                    return <Visitor id={+match?.params?.id || 0} />;
                }}
            />

            { partner?.role === 'PARTNER_EXTEND'
                ? <Route path='/settings' component={OwnerSettings} />
                : null }

            <ErrorModal isError={error500andMore} showModal={setError500} message='Возникли технические проблемы. Произошла ошибка на сервере. Если были отправлены данные, то они не сохранились' onlyError withButton />
        </div>
    );
};

MiddleInfo.propTypes = {
    partner: PropTypes.object,
    error500andMore: PropTypes.bool,
    setError500: PropTypes.func
};

const mapStateToProps = ({ auth: { partner }, common: { error500andMore } }) => {
    return {
        partner,
        error500andMore
    };
};

const mapDispatchToProps = {
    setError500,
    setOwnerIdSheets,
    setStatusesPreferSheets
};

export default connect(mapStateToProps, mapDispatchToProps)(MiddleInfo);
