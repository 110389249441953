import React, { useEffect } from 'react';
import style from './OwnerSettingsBody.module.scss';
import UsersTable from './Containers/UsersTableContainer';
import { connect } from 'react-redux';
import { createOwner, loadingOwnersCompany, setErrorOwners, createNewOwnerData } from '../../../reducers/settings';
import PropTypes from 'prop-types';
import AddNewOwner from './AddNewOwner';

const OwnerSettingsBody = ({ createOwner, loadingOwnersCompany, setErrorOwners, fetchOwnersCompanySuccess }) => {
    useEffect(() => {
        return () => {
            loadingOwnersCompany(false);
            setErrorOwners(false);
        };
    }, [loadingOwnersCompany, setErrorOwners]);

    const handlerAddOwner = () => {
        createOwner();
    };

    return (
        <div className={style.svBookDetails}>
            <div className={style.svColumn}>
                <div className={style.clients + ' ' + style.shadowBlock}>
                    <UsersTable text='Список владельцев аккаунта' loading={fetchOwnersCompanySuccess} />
                </div>
                <AddNewOwner handlerAddOwner={handlerAddOwner} text='Добавить пользователя' />
            </div>
        </div>
    );
};

OwnerSettingsBody.propTypes = {
    createOwner: PropTypes.func,
    loadingOwnersCompany: PropTypes.func,
    setErrorOwners: PropTypes.func,
    fetchOwnersCompanySuccess: PropTypes.bool
};

const mapStateToProps = ({ settings: { fetchOwnersCompanySuccess } }) => {
    return {
        fetchOwnersCompanySuccess
    };
};

export default connect(mapStateToProps, {
    createOwner,
    loadingOwnersCompany,
    setErrorOwners,
    createNewOwnerData
})(OwnerSettingsBody);
