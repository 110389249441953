import {
    typeGetSecretaryInfoSuccess,
    typeGetSecretaryInfoError,
    typeSetSecretaryMileageInfo,
    typeSetSecretaryMileageConnectionsInfo,
    typePutSecretaryInfoError,
    typePutSecretaryInfoSuccess,
    typeSetSecretaryConnectionAfterUpdate,
    typeSetSecretaryInfoAfterUpdate,
    typeSetSecretaryCommonInfoAfterUpdateError,
    typeSetSecretaryConnectionCommonAfterUpdate,
    typeDeleteSecretaryByTypeSuccess,
    typeDeleteSecretaryByTypeError,
    typeCarOccupancyRateUpdate,
    typeSetClientNotificationsSettings,
    typeSetClientNotificationsSettingsError,
    typeSetClientNotificationsSettingsSuccess
} from '../actionsTypes';
import { SECRETARY_COMMON, SECRETARY_MILEAGE } from '../constants/secretaryTypes';
import { checkChangeSecretaries } from '../utils';

// ------------------------------------
// Constants
// ------------------------------------

const GET_SECRETARY_INFO_SUCCESS = typeGetSecretaryInfoSuccess;
const GET_SECRETARY_INFO_ERROR = typeGetSecretaryInfoError;
const SET_SECRETARY_MILEAGE_INFO = typeSetSecretaryMileageInfo;
const SET_SECRETARY_MILEAGE_CONNECTIONS_INFO = typeSetSecretaryMileageConnectionsInfo;
const PUT_SECRETARY_INFO_SUCCESS = typePutSecretaryInfoSuccess;
const PUT_SECRETARY_INFO_ERROR = typePutSecretaryInfoError;
const SET_SECRETARY_CONNECTION_AFTER_UPDATE = typeSetSecretaryConnectionAfterUpdate;
const SET_SECRETARY_CONNECTION_COMMON_AFTER_UPDATE = typeSetSecretaryConnectionCommonAfterUpdate;
const SET_SECRETARY_INFO_AFTER_UPDATE = typeSetSecretaryInfoAfterUpdate;
const SET_SECRETARY_COMMON_INFO_AFTER_UPDATE = typeSetSecretaryCommonInfoAfterUpdateError;
const DELETE_SECRETARY_BY_TYPE_SUCCESS = typeDeleteSecretaryByTypeSuccess;
const DELETE_SECRETARY_BY_TYPE_ERROR = typeDeleteSecretaryByTypeError;
const SET_CLIENT_NOTIFICATIONS_SETTINGS = typeSetClientNotificationsSettings;
const SET_CLIENT_NOTIFICATIONS_SETTINGS_ERROR = typeSetClientNotificationsSettingsError;
const SET_CLIENT_NOTIFICATIONS_SETTINGS_SUCCESS = typeSetClientNotificationsSettingsSuccess;

// ------------------------------------
// Actions
// ------------------------------------

export const getSecretaryInfoSuccess = (isSuccess) => {
    return {
        type: GET_SECRETARY_INFO_SUCCESS,
        payload: isSuccess
    };
};

export const getSecretaryInfoError = (isError) => {
    return {
        type: GET_SECRETARY_INFO_ERROR,
        payload: isError
    };
};

export const setSecretaryMileage = (data) => {
    return {
        type: SET_SECRETARY_MILEAGE_INFO,
        payload: data
    };
};

export const setSecretaryMileageConnections = (data) => {
    return {
        type: SET_SECRETARY_MILEAGE_CONNECTIONS_INFO,
        payload: data
    };
};

export const getSecretaryInfoUpdateSuccess = (isSuccess) => {
    return {
        type: PUT_SECRETARY_INFO_SUCCESS,
        payload: isSuccess
    };
};

export const getSecretaryInfoUpdateError = (isError) => {
    return {
        type: PUT_SECRETARY_INFO_ERROR,
        payload: isError
    };
};

export const setSecretaryConnectionsAfterUpdate = (connections) => {
    return {
        type: SET_SECRETARY_CONNECTION_AFTER_UPDATE,
        payload: connections
    };
};

export const setSecretaryConnectionsCommonAfterUpdate = (connections) => {
    return {
        type: SET_SECRETARY_CONNECTION_COMMON_AFTER_UPDATE,
        payload: connections
    };
};

export const setSecretaryInfoAfterUpdate = (secretaries) => {
    return {
        type: SET_SECRETARY_INFO_AFTER_UPDATE,
        payload: secretaries
    };
};

export const setSecretaryCommonInfoAfterUpdate = (secretaries) => {
    return {
        type: SET_SECRETARY_COMMON_INFO_AFTER_UPDATE,
        payload: secretaries
    };
};

export const deleteSecretaryByTypeSuccess = (isSuccess) => {
    return {
        type: DELETE_SECRETARY_BY_TYPE_SUCCESS,
        payload: isSuccess
    };
};

export const deleteSecretaryByTypeError = (isError) => {
    return {
        type: DELETE_SECRETARY_BY_TYPE_ERROR,
        payload: isError
    };
};

export const getSecretaryInfo = (clientId, data, carId = null) => (dispatch, getState, { apiService }) => {
    dispatch(getSecretaryInfoSuccess(true));
    dispatch(getSecretaryInfoError(false));
    return apiService.getSecretary(clientId, data, carId)
        .then((response) => {
            const res = response.result;
            if (data.secretaryType === SECRETARY_MILEAGE) {
                Object.hasOwnProperty.call(res, 'secretaries') && dispatch(setSecretaryMileage(res.secretaries));
                Object.hasOwnProperty.call(res, 'connections') && dispatch(setSecretaryMileageConnections(res.connections));
            }

            if (data.secretaryType === SECRETARY_COMMON) {
                Object.hasOwnProperty.call(res, 'secretaries') && dispatch(setSecretaryCommonInfoAfterUpdate(res.secretaries));
                Object.hasOwnProperty.call(res, 'connections') && dispatch(setSecretaryConnectionsCommonAfterUpdate(res.connections));
            }

            if (response.occupancy_rate_blocks) {
                dispatch(carOccupancyRateUpdate(response.occupancy_rate_blocks));
            }

            dispatch(getSecretaryInfoSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getSecretaryInfoSuccess(false));
            dispatch(getSecretaryInfoError(true));
            return err;
        });
};

export const postSecretaryInfo = (clientId, data = {}, setDefault = false) => (dispatch, getState, { apiService }) => {
    dispatch(getSecretaryInfoUpdateSuccess(true));
    dispatch(getSecretaryInfoUpdateError(false));

    if (!setDefault) {
        if (data.secretaryType === SECRETARY_COMMON) {
            if (checkChangeSecretaries(data, getState().secretary.secretariesCommon)) {
                return false;
            }
        }

        if (data.secretaryType === SECRETARY_MILEAGE) {
            if (checkChangeSecretaries(data, getState().secretary.secretariesMileage)) {
                return false;
            }
        }
    }

    return clientId && apiService.postSecretary(clientId, data)
        .then((res) => {
            const dataRes = res.result;
            if (data.secretaryType === SECRETARY_MILEAGE) {
                Object.hasOwnProperty.call(dataRes, 'secretary') && dispatch(setSecretaryInfoAfterUpdate(dataRes.secretary));
                Object.hasOwnProperty.call(dataRes, 'connections') && dispatch(setSecretaryConnectionsAfterUpdate(dataRes.connections));
            }
            if (data.secretaryType === SECRETARY_COMMON) {
                Object.hasOwnProperty.call(dataRes, 'secretary') && dispatch(setSecretaryCommonInfoAfterUpdate(dataRes.secretary));
                Object.hasOwnProperty.call(dataRes, 'connections') && dispatch(setSecretaryConnectionsCommonAfterUpdate(dataRes.connections));
            }

            if (res.occupancy_rate_blocks) {
                dispatch(carOccupancyRateUpdate(res.occupancy_rate_blocks));
            }

            dispatch(getSecretaryInfoUpdateSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getSecretaryInfoUpdateSuccess(false));
            dispatch(getSecretaryInfoUpdateError(true));
            return err;
        });
};

const carOccupancyRateUpdate = (updateOccupancyRate) => {
    return {
        type: typeCarOccupancyRateUpdate,
        payload: updateOccupancyRate
    };
};

export const deleteSecretariesByType = (clientId, secretaryType, carId) => (dispatch, getState, { apiService }) => {
    dispatch(deleteSecretaryByTypeSuccess(true));
    dispatch(deleteSecretaryByTypeError(false));
    return clientId && apiService.deleteSecretary(clientId, secretaryType, carId)
        .then((res) => {
            if (res.result) {
                if (secretaryType === SECRETARY_MILEAGE) {
                    dispatch(setSecretaryMileage([]));
                    dispatch(setSecretaryMileageConnections([]));
                }
                if (secretaryType === SECRETARY_COMMON) {
                    dispatch(setSecretaryCommonInfoAfterUpdate([]));
                    dispatch(setSecretaryConnectionsCommonAfterUpdate([]));
                }
            }
            if (res.occupancy_rate_blocks) {
                dispatch(carOccupancyRateUpdate(res.occupancy_rate_blocks));
            }
            dispatch(deleteSecretaryByTypeSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(deleteSecretaryByTypeSuccess(false));
            dispatch(deleteSecretaryByTypeError(true));
            return err;
        });
};

export const setDefaultSecretary = (clientId, data = {}, carId = null) => (dispatch, getState) => {
    const timeRange = getState().templatesOfFrameState.timeRangeMileageSecretary;
    const days = getState().templatesOfFrameState.days;
    const connections = getState().templatesOfFrameState.connections;
    const dataPost = {
        ...data,
        timeRange: timeRange,
        days: days,
        connections: connections
    };

    clientId && dispatch(postSecretaryInfo(clientId, carId ? { ...dataPost, carId: carId } : dataPost, true));
};

export const clearBlockSecretary = (clientIdCollect, carIdCollect) => (dispatch) => {
    clientIdCollect && dispatch(deleteSecretariesByType(clientIdCollect, SECRETARY_MILEAGE, carIdCollect));
    clientIdCollect && dispatch(deleteSecretariesByType(clientIdCollect, SECRETARY_COMMON, carIdCollect));
};

const handleSetClientNotification = (data) => {
    return {
        type: typeSetClientNotificationsSettings,
        payload: data
    };
};

const handleSetClientNotificationError = (err) => {
    return {
        type: typeSetClientNotificationsSettingsError,
        payload: err
    };
};

const handleSetClientNotificationSuccess = (load) => {
    return {
        type: typeSetClientNotificationsSettingsSuccess,
        payload: load
    };
};

export const handleGetClientNotificationsSettings = (clientId) => (dispatch, getState, { apiService }) => {
    dispatch(handleSetClientNotificationSuccess(true));
    // dispatch(handleSetClientNotificationError(false));
    return clientId && apiService.getClientNotificationsSettings(clientId)
        .then((res) => {
            const mileageSettings = Array.isArray(res) && res.length > 0
                ? res.find(s => s.type === 'question:mileage')
                : (res.type === 'question:mileage' ? res : false);
            if (mileageSettings) {
                dispatch(handleSetClientNotification(mileageSettings));
            }
            dispatch(handleSetClientNotificationSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(handleSetClientNotificationSuccess(false));
            dispatch(handleSetClientNotificationError(true));
            return err;
        });
};

export const handleUpdateClientNotificationsSettings = (clientId, data) => (dispatch, getState, { apiService }) => {
    dispatch(handleSetClientNotificationSuccess(true));
    dispatch(handleSetClientNotificationError(false));
    return clientId && apiService.postClientNotificationsSettings(clientId, data)
        .then((res) => {
            const mileageSettings = Array.isArray(res) && res.length > 0
                ? res.find(s => s.type === 'question:mileage')
                : (res.type === 'question:mileage' ? res : false);
            if (mileageSettings) {
                dispatch(handleSetClientNotification(mileageSettings));
            }
            dispatch(handleSetClientNotificationSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(handleSetClientNotificationSuccess(false));
            dispatch(handleSetClientNotificationError(true));
            return err;
        });
};

export const actions = {
    postSecretaryInfo,
    getSecretaryInfo,
    deleteSecretariesByType,
    setDefaultSecretary,
    clearBlockSecretary,
    handleGetClientNotificationsSettings,
    handleUpdateClientNotificationsSettings
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {

    [GET_SECRETARY_INFO_SUCCESS]: (state, action) => {
        return ({
            ...state,
            secretaryInfoSuccess: action.payload
        });
    },

    [GET_SECRETARY_INFO_ERROR]: (state, action) => {
        return ({
            ...state,
            secretaryInfoError: action.payload
        });
    },

    [SET_SECRETARY_MILEAGE_INFO]: (state, action) => {
        return ({
            ...state,
            secretariesMileage: action.payload
        });
    },

    [SET_SECRETARY_MILEAGE_CONNECTIONS_INFO]: (state, action) => {
        return ({
            ...state,
            secretaryMileageConnections: action.payload
        });
    },

    [PUT_SECRETARY_INFO_SUCCESS]: (state, action) => {
        return ({
            ...state,
            secretaryInfoUpdateSuccess: action.payload
        });
    },

    [PUT_SECRETARY_INFO_ERROR]: (state, action) => {
        return ({
            ...state,
            secretaryInfoUpdateError: action.payload
        });
    },

    [SET_SECRETARY_CONNECTION_AFTER_UPDATE]: (state, action) => {
        if (action.payload) {
            return ({
                ...state,
                secretaryMileageConnections: action.payload
            });
        }
    },

    [SET_SECRETARY_CONNECTION_COMMON_AFTER_UPDATE]: (state, action) => {
        if (action.payload) {
            return ({
                ...state,
                secretaryCommonConnections: action.payload
            });
        }
    },

    [SET_SECRETARY_INFO_AFTER_UPDATE]: (state, action) => {
        return ({
            ...state,
            secretariesMileage: [action.payload]
        });
    },

    [SET_SECRETARY_COMMON_INFO_AFTER_UPDATE]: (state, action) => {
        if (action.payload.length === 0) {
            return ({
                ...state,
                secretariesCommon: []
            });
        }
        if (Array.isArray(action.payload) && (!action.payload[0].message)) {
            return ({
                ...state,
                secretariesCommon: action.payload
            });
        } else {
            const idx = state.secretariesCommon.findIndex(item => item.id === action.payload.id);
            if (~idx) {
                return ({
                    ...state,
                    secretariesCommon: [
                        ...state.secretariesCommon.slice(0, idx),
                        action.payload,
                        ...state.secretariesCommon.slice(idx + 1)
                    ]
                });
            } else {
                const filterSecretary = state.secretariesCommon.filter(item => typeof item.id !== 'string');
                return ({
                    ...state,
                    secretariesCommon: [...filterSecretary, action.payload]
                });
            }
        }
    },

    [DELETE_SECRETARY_BY_TYPE_ERROR]: (state, action) => {
        return ({
            ...state,
            deleteSecretaryByTypeError: action.payload
        });
    },

    [DELETE_SECRETARY_BY_TYPE_SUCCESS]: (state, action) => {
        return ({
            ...state,
            deleteSecretaryByTypeSuccess: action.payload
        });
    },

    [SET_CLIENT_NOTIFICATIONS_SETTINGS]: (state, action) => {
        return ({
            ...state,
            weekDayTimeSettings: action.payload
        });
    },

    [SET_CLIENT_NOTIFICATIONS_SETTINGS_ERROR]: (state, action) => {
        return ({
            ...state,
            weekDayTimeSettingsError: action.payload
        });
    },

    [SET_CLIENT_NOTIFICATIONS_SETTINGS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            weekDayTimeSettingsSuccess: action.payload
        });
    }
};

const secretaryReducer = (state, action) => {
    if (state === undefined) {
        return {
            secretaryInfoSuccess: false,
            secretaryInfoError: false,
            secretariesMileage: [],
            secretariesCommon: [],
            secretaryMileageConnections: [],
            secretaryCommonConnections: [],
            secretaryInfoUpdateSuccess: false,
            secretaryInfoUpdateError: false,
            deleteSecretaryByTypeError: false,
            deleteSecretaryByTypeSuccess: false,
            weekDayTimeSettingsError: false,
            weekDayTimeSettingsSuccess: false,
            weekDayTimeSettings: {}
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.secretary, action) : state.secretary;
};

export default secretaryReducer;
