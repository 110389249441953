export const AUTUMN_SEASON_START_COLLECT = new Date(Date.UTC(2021, 5, 15, 0, 0, 0));
export const SPRING_SEASON_START_COLLECT = new Date(Date.UTC(2021, 11, 15, 0, 0, 0));

export const AUTUMN_SEASON_SHOW = 'autumn';
export const SPRING_SEASON_SHOW = 'spring';

export const HOW_SEASON = {
    [AUTUMN_SEASON_SHOW]: 'осень',
    [SPRING_SEASON_SHOW]: 'весна'
};
