export const DATA_LABELS_MAP_KEYS_OLD = {
    startPeriod: 'НАЧАЛО ПЕРИОДА',
    comeIn: 'ПРИХОД',
    cycleServices: 'Цикличные услуги',
    activation: '% активаций',
    goOut: 'ВЫХОД',
    registration: 'запись',
    cancellation: 'отказы',
    competitors: 'сделали у других',
    asleep: 'уснувшие',
    sold: 'проданные',
    fromInvitations: 'ИЗ ПРИГЛАШЕНИЙ',
    ofIntentions: 'ИЗ НАМЕРЕНИЙ',
    fromExpectations: 'ИЗ ОЖИДАНИЙ',
    rest: 'ОСТАТОК НА КОНЕЦ ПЕРИОДА',
    expectation: 'ожидание',
    intentions: 'намерение',
    invitations: 'приглашение',
    green: 'зеленый',
    yellow: 'желтый',
    red: 'красный'
};

export const DAY_WEEKS = [
    'Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'
];

export const DATA_LABELS_MAP_KEYS = {
    comeIn: 'НОВЫЕ ПРОЛОНГАЦИИ',
    cycleServices: 'Выполненно услуг',
    activation: 'Активации',
    goOut: 'ВЫХОД',
    registration: 'ЗАПИСЬ',
    competitors: 'СДЕЛАЛ У ДРУГИХ',
    sold: 'ПРОДАЛ АВТО',
    asleep: 'ВЫШЕ НОРМЫ',
    cancellation: 'ОТКАЗ',
    rest: 'ОСТАТОК НА КОНЕЦ ПЕРИОДА',
    expectation: 'ожидание',
    intentions: 'намерение',
    invitations: 'приглашение',
    green: 'зеленый',
    yellow: 'желтый',
    red: 'красный'
};

export const DATA_LABELS_ARRAY = [
    { title: 'НОВЫЕ ПРОЛОНГАЦИИ', father: '', label: 'comeIn' },
    { title: 'Выполненно услуг', father: 'comeIn', label: 'cycleServices' },
    { title: 'Активации', father: 'comeIn', label: 'activation' },
    { title: 'ВЫХОД', father: '', label: 'goOut' },
    { title: 'ЗАПИСЬ', father: 'goOut', label: 'registration' },
    { title: 'СДЕЛАЛ У ДРУГИХ', father: 'goOut', label: 'competitors' },
    { title: 'ПРОДАЛ АВТО', father: 'goOut', label: 'sold' },
    { title: 'ВЫШЕ НОРМЫ', father: 'goOut', label: 'asleep' },
    { title: 'ОТКАЗ', father: 'goOut', label: 'cancellation' },
    { title: 'ОСТАТОК НА КОНЕЦ ПЕРИОДА', father: '', label: 'rest' },
    { title: 'ожидание', father: 'rest', label: 'expectation' },
    { title: 'намерение', father: 'rest', label: 'intentions' },
    { title: 'приглашение', father: 'rest', label: 'invitations' },
    { title: 'зеленый', father: 'rest', label: 'green' },
    { title: 'желтый', father: 'rest', label: 'yellow' },
    { title: 'красный', father: 'rest', label: 'red' }
];
