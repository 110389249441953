import _ from 'lodash';
import spaceInNumber from './numToStringAddSpaces';
import createUniqueArrItems from './uniqueArrItems';
import createNewItemForCar from './createNewItemForCar';
import isEmpty from './isEmpty';
import searchParentBlockId from './searchParentBlockId';
import createDateForSaveFromDatePicker from './createDateForSaveFromDatePicker';
import reIdsResourcesFromAngara from './reIdsRersourcesFromAngara';
import dateFromDateField from './dateFromDateField';
import timeFromDateField from './timeFromDateField';
import dateComparisonFirstBeforeSecond from './dateComparison';
import sortByField from './sortByField';
import buildSeasonsRangeObj from './buildSeasonsRangeObj';
import mapBuildDatesPeriods from './mapBuildDatesPeriods';
import stringBuildDateRange from './stringBuildDateRange';
import isNotEmpty from './isNotEmpty';
import convertMileage from './convertMileage';
import getTimeFromString from './getTimeFromString';
import checkChangeSecretaries from './checkChangeSecretaries';
import selectSeasonTires from './selectSeasonTires';
import compareDaysMonthFirstGreater from './compareDaysMonthFirstGreater';
import numbersDaysAgo from './numbersDaysAgo';
import { startOfDateIso, endOfDateIso } from './startOfDate';
import getMainClient from './getMainClient';
import validateBlock from './validateBlock';
import { getAllStatusesLabels } from './getAllStatusesLabels';
import { formatNumber } from './formatNumber';
import checkStatusExist from './checkStatusExist';

export {
    spaceInNumber,
    createUniqueArrItems,
    createNewItemForCar,
    isEmpty,
    searchParentBlockId,
    createDateForSaveFromDatePicker,
    reIdsResourcesFromAngara,
    dateFromDateField,
    timeFromDateField,
    dateComparisonFirstBeforeSecond,
    sortByField,
    buildSeasonsRangeObj,
    mapBuildDatesPeriods,
    stringBuildDateRange,
    isNotEmpty,
    convertMileage,
    getTimeFromString,
    checkChangeSecretaries,
    selectSeasonTires,
    compareDaysMonthFirstGreater,
    numbersDaysAgo,
    startOfDateIso,
    endOfDateIso,
    getMainClient,
    validateBlock,
    getAllStatusesLabels,
    formatNumber,
    checkStatusExist
};

export const beforeMaskedPhoneChange = (newState, oldState, userInput) => {
    let { value } = newState;
    let selection = newState.selection;
    let cursorPosition = selection ? selection.start : null;
    if (oldState.value.replace(/\s+/g, '') === '+7(___)___-__-__' && userInput && (userInput[0] === '8' || userInput[0] === '7' || (userInput[0] === '+' && userInput[1] === '7'))) {
        userInput = userInput.replace(/([^0-9+]*)/ig, '');
        if (userInput[0] === '+') {
            value = '+7' + userInput.slice(2);
            if (userInput.length < 12) {
                if (userInput.length === 11) {
                    cursorPosition--;
                } else {
                    cursorPosition = cursorPosition - 2;
                }
                selection = { start: cursorPosition, end: cursorPosition };
            }
        } else {
            value = '+7' + userInput.slice(1);
            if (userInput.length < 11) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
        }
    }
    return {
        value,
        selection
    };
};

// Generate URL-encoded query string
export const httpBuildQuery = (formdata, numericPrefix, argSeparator) => {
    let key;
    let useVal;
    let useKey;
    let i = 0;
    const tmpArr = [];

    if (!argSeparator) {
        argSeparator = '&';
    }
    for (key in formdata) {
        useKey = encodeURIComponent(key);
        useVal = _.isObject(formdata[key]) ? formdata[key] : encodeURIComponent((formdata[key].toString()));
        if (useVal !== '') {
            if (_.isObject(useVal)) {
                if (numericPrefix && !isNaN(key)) {
                    useKey = numericPrefix + i;
                }
                for (const _key in useVal) {
                    tmpArr[i] = useKey + '[' + _key + ']=' + useVal[_key];
                    i++;
                }
            } else {
                if (numericPrefix && !isNaN(key)) {
                    useKey = numericPrefix + i;
                }
                tmpArr[i] = useKey + '=' + useVal;
                i++;
            }
        }
    }
    return tmpArr.join(argSeparator);
};

// правильное окончание существительного коего... n
export const declOfNum = (number, titles, defaultValue = '') => {
    if (number) {
        number = number.toString().substr(-4);
    } else {
        return defaultValue;
    }
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};
export const NameSurname = (name) => {
    return name.trim().split(' ').map(x => x[0].toUpperCase()).join('').slice(0, 2);
};
