import React from 'react';
import PropTypes from 'prop-types';
import style from './MessageForForm.module.scss';

const MessageForForm = ({ message, icon, alt, classes = [] }) => {
    return (
        <div className={style.message + classes.map(item => ' ' + style[item]).join('')}>
            {
                icon
                    ? <img src={icon} alt={alt} />
                    : ''
            }
            <div className={style.messageText}>
                {
                    message
                }
            </div>
        </div>
    );
};

MessageForForm.propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    classes: PropTypes.arrayOf(PropTypes.oneOf(['green', 'maxWidthStretch', 'grey', 'height40', 'greyLight', 'paddingLeft', 'warningColor', 'paddingRight35', 'flexEnd'])),
    icon: PropTypes.string,
    alt: PropTypes.string
};

export default MessageForForm;
