/**
 * Проверяет является ли первая дата (проверяет только месяц и день) больше второй, не учитывая год
 * Принимает даты из значения объекта Date
 *
 * */
export default function compareDaysMonthFirstGreater(dateOne, dateTwo) {
    let result = false;

    if (dateOne.getMonth() > dateTwo.getMonth()) {
        result = true;
    } else {
        if (dateOne.getMonth() === dateTwo.getMonth()) {
            if (dateOne.getDate() > dateTwo.getDate()) {
                result = true;
            }
        }
    }
    return result;
}
