import React, { useState } from 'react';
import BlockTypeReportProlongationTable from '../BlockTypeReportProlongationTable';
import PropTypes from 'prop-types';
import style from './ReportProlongationTable.module.scss';
import ScrollCustom from '../../../../../../components/ScrollCustom';

const ReportProlongationTable = ({ statisticYearProlongation }) => {
    const [valueTranslateX, setValueTranslateX] = useState(0);
    const [maxScroll, setMaxScroll] = useState(0);

    const handleSetDeltaWidthTable = (delta) => {
        setMaxScroll(delta);
    };

    const handleSetValueScroll = (value) => {
        setValueTranslateX(value);
    };

    const blocks = statisticYearProlongation && statisticYearProlongation.length > 0
        ? statisticYearProlongation.map((item, i) => {
            return (
                <BlockTypeReportProlongationTable
                    key={item.serviceTemplateId}
                    type={item.title}
                    monthsReport={item.months}
                    valueTranslateX={valueTranslateX}
                    handleSetDeltaWidthTable={handleSetDeltaWidthTable}
                    classes={i === statisticYearProlongation.length - 1 ? ['bottomBlock'] : []}
                />
            );
        }) : '';

    return (
        <div className={style.prolongationTable}>
            <div className={style.allTypes}>
                {
                    blocks
                }
            </div>
            <ScrollCustom
                handleSetValueScroll={handleSetValueScroll}
                valueTranslateX={valueTranslateX}
                maxScroll={maxScroll}
            />
        </div>
    );
};

ReportProlongationTable.propTypes = {
    statisticYearProlongation: PropTypes.array
};

export default ReportProlongationTable;
