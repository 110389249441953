import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SheetCollectHeader from '../SheetCollectHeader/SheetCollectHeader';
import { connect } from 'react-redux';
import { fetchCollectBlocks } from '../../../actions';

const SheetCollectHeaderContainer = ({ fetchCollectBlocks, isOpenMinHeader }) => {
    useEffect(() => {
        fetchCollectBlocks();
    }, [fetchCollectBlocks]);

    return (
        <>
            <SheetCollectHeader isOpenMinHeader={isOpenMinHeader} />
        </>
    );
};

SheetCollectHeaderContainer.propTypes = {
    fetchCollectBlocks: PropTypes.func,
    isOpenMinHeader: PropTypes.bool
};

const mapStateToProps = ({ sheetCollectInfo: { isOpenMinHeader } }) => {
    return {
        isOpenMinHeader
    };
};

export default connect(mapStateToProps, { fetchCollectBlocks })(SheetCollectHeaderContainer);
