import React from 'react';
import style from './BtnActionsRow.module.scss';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const BtnActionsRow = ({ handleClickTrash, handleAddPoint, handleUpdate, del, withoutEmptyUpd, upd, link = '', classesDel = [] }) => {
    const btnsActions = (
        <>
            {
                del
                    ? <button className={style.btnTrash + ' ' + classesDel.map(item => ' ' + style[item]).join('')} onClick={handleClickTrash}> </button>
                    : <button className={style.btnEmpty}> </button>
            }

            {
                upd
                    ? link
                        ? <Link to={link}><button className={style.btnUpdate}> </button></Link>
                        : handleUpdate && <button className={style.btnUpdate} onClick={handleUpdate}> </button>
                    : (withoutEmptyUpd ? '' : <button className={style.btnEmpty}> </button>)

            }
            {
                handleAddPoint
                    ? <button className={style.btnAdd} onClick={handleAddPoint}> </button>
                    : ''
            }
        </>
    );

    return (
        btnsActions
    );
};

BtnActionsRow.propTypes = {
    handleClickTrash: PropTypes.func,
    handleAddPoint: PropTypes.func,
    handleUpdate: PropTypes.func,
    link: PropTypes.string,
    del: PropTypes.bool,
    withoutEmptyUpd: PropTypes.bool,
    upd: PropTypes.bool,
    classesDel: PropTypes.array
};

export default BtnActionsRow;
