import React from 'react';
import style from './LabelRowProlongationTable.module.scss';
import PropTypes from 'prop-types';

const LabelRowProlongationTable = ({ type, arrTitleTable }) => {
    return (
        <div className={style.nameLines}>
            <div className={style.blueType}>{ type }</div>
            <div>{ arrTitleTable[0] }</div>
            <div className={style.boldText}>{ arrTitleTable[1] }</div>
            <div>{ arrTitleTable[2] }</div>
            <div className={style.greenText}>{ arrTitleTable[3] }</div>
            <div>{ arrTitleTable[4] }</div>
            <div className={style.itemEnd}>{ arrTitleTable[5] }</div>
        </div>
    );
};

LabelRowProlongationTable.propTypes = {
    type: PropTypes.string,
    arrTitleTable: PropTypes.array
};

export default LabelRowProlongationTable;
