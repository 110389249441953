import React, { useEffect } from 'react';
import HeadToggle from '../../../../../components/HeadToggle';
import Loader from '../../../../../components/Loader';
import IterableItemsTable from '../../../../../components/IterableItemsTable';
import style from '../../Handbooks.module.scss';
import PropTypes from 'prop-types';

const SettingsHandbook = ({
    id,
    keyId,
    initialHiddenHeader,
    headerText,
    actionDelete,
    actionGet,
    actionUpdate,
    actionClear,
    pairHeadersWithBtnAddName,
    representations,
    delBtn,
    updBtn,
    addBtn,
    fields,
    name,
    isSuccess,
    items = [],
    classesHeadToggle = ['big', 'gray', 'leftPosition', 'onHoverArrow'],
    classesLoader = ['small', 'loaderTop', 'gray']
}) => {
    useEffect(() => {
        actionGet && actionGet();
    }, [actionGet]);

    useEffect(() => {
        return () => actionClear && actionClear([]);
    }, [actionClear]);

    return (
        <div
            key={keyId}
            className={style.clients + ' ' + style.shadowBlock}
        >
            <HeadToggle
                id={id}
                headerText={headerText}
                classes={classesHeadToggle}
                initialHidden={initialHiddenHeader}
            >
                {
                    isSuccess
                        ? <div><Loader active={isSuccess} classes={classesLoader} /></div>
                        : ''
                }
                <IterableItemsTable
                    key={keyId + '_IterableItemsTable'}
                    iterableItems={items && items}
                    pairHeadersWithBtnAddName={pairHeadersWithBtnAddName}
                    handleAddItem={actionUpdate}
                    handleClickTrash={actionDelete}
                    name={name}
                    representations={representations}
                    fields={fields}
                    delBtn={delBtn}
                    updBtn={updBtn}
                    addBtn={addBtn}
                />
            </HeadToggle>
        </div>
    );
};

SettingsHandbook.propTypes = {
    id: PropTypes.string,
    keyId: PropTypes.string,
    initialHiddenHeader: PropTypes.bool,
    headerText: PropTypes.string,
    items: PropTypes.array,
    actionDelete: PropTypes.func,
    actionGet: PropTypes.func,
    actionUpdate: PropTypes.func,
    actionClear: PropTypes.func,
    pairHeadersWithBtnAddName: PropTypes.array,
    representations: PropTypes.array,
    delBtn: PropTypes.bool,
    updBtn: PropTypes.bool,
    addBtn: PropTypes.bool,
    fields: PropTypes.array,
    name: PropTypes.string,
    isSuccess: PropTypes.bool,
    classesHeadToggle: PropTypes.array,
    classesLoader: PropTypes.array
};

export default SettingsHandbook;
