import React, { useState, useEffect } from 'react';
import style from './OwnerSettingsBody.module.scss';
import { HeaderSettingsInBody } from '../SettingsHeader';
import RowOwners from './RowOwners';
import PropTypes from 'prop-types';
import LoadingIndicatorBlock from '../../../components/LoadingIndicator/LoadingIndicatorBlock';

const UsersTable = ({ text, ownersCompany, deleteOwner, loading }) => {
    const [ownersLocal, setOwnersLocal] = useState(ownersCompany);

    useEffect(() => {
        setOwnersLocal(ownersCompany);
    }, [ownersCompany]);

    const owners = (ownersLocal && ownersLocal.length > 0) ? ownersLocal.map((owner) => {
        return <RowOwners user={owner} key={owner.id} deleteOwner={deleteOwner} />;
    }) : [];

    const tableOwners = (
        <table>
            <thead>
                <tr>
                    <th>id</th>
                    <th>ФИО</th>
                    <th>Телефон</th>
                    <th>Роль</th>
                    <th>Действия</th>
                </tr>
            </thead>

            <tbody>
                {
                    owners
                }
            </tbody>
        </table>
    );

    return (
        <div className={style.usersCart}>
            <HeaderSettingsInBody text={text} />
            {
                loading
                    ? <LoadingIndicatorBlock reasonLoader={loading} />
                    : ''
            }
            { tableOwners }
        </div>
    );
};

UsersTable.propTypes = {
    text: PropTypes.string,
    ownersCompany: PropTypes.array,
    deleteOwner: PropTypes.func,
    loading: PropTypes.bool
};

export default UsersTable;
