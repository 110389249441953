import React, { useState, useEffect } from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import PropTypes from 'prop-types';
import { compose, withApiService } from 'Hoc';
import { connect } from 'react-redux';
import { mapBuildDatesPeriods, stringBuildDateRange } from '../../../../../../utils';
import ItemChangeRectangleButton from '../../../../../../components/Buttons/ItemChangeRectangleButton';
import { Link } from 'react-router-dom';
import snow from './../../../assets/snow.svg';
import leaf from './../../../assets/leaf.svg';
import style from '../../../SheetCollectBody.module.scss';
import { SEASON_ID_SPRING } from '../../../../../../constants/Seasons';

const SeasonTires = ({
    carDetailsInfo,
    handleUpdate,
    title,
    seasonsAndPeriodsSuccess,
    partner,
    isValid,
    seasonsAndPeriods = {},
    seasonId = 1
}) => {
    const [selectedPeriodClient, setSelectedPeriodClient] = useState(0);
    const [preferencesId, setPreferencesId] = useState(0);
    const [periodsTemplate, setPeriodsTemplate] = useState([]);
    const [resPeriodsTimes, setResPeriodsTimes] = useState([]);
    const [seasonsRange, setSeasonsRange] = useState('');

    const styleSelect = `${style.tireSeason} ${style.tireSeasonSelect}`;
    const styleNotSelect = `${style.tireSeason}`;

    const seasonNotSet = (
        ((partner.role === 'PARTNER_EXTEND')
            ? <Link to='/settings/handbooks'> <span className={style.textDisable + ' ' + style.andHover + ' ' + style.navLinks}><div>Настроить даты сезона</div></span></Link>
            : <span className={style.textDisable}>(даты сезона не определены)</span>)
    );

    useEffect(() => {
        const periodSearch = carDetailsInfo.car_client_seasonal_preference
            ? carDetailsInfo.car_client_seasonal_preference.find(item => item.season_id === seasonId)
            : 0;

        setSelectedPeriodClient(periodSearch ? periodSearch.period_id : 0);
        setPreferencesId(periodSearch ? periodSearch.id : 0);
    }, [carDetailsInfo, seasonId]);

    useEffect(() => {
        setResPeriodsTimes((seasonsAndPeriods.period_times && seasonsAndPeriods.period_times.length > 0)
            ? mapBuildDatesPeriods(seasonsAndPeriods.period_times, 'date_start', 'date_end')
            : 0);

        setSeasonsRange((seasonsAndPeriods.period_times && seasonsAndPeriods.period_times.length > 0)
            ? stringBuildDateRange(seasonsAndPeriods.period_times, seasonId, 'date_start', 'date_end')
            : '');
    }, [seasonsAndPeriods, seasonId]);

    function handleChange(periodId, seasonId, preferencesId, handleUpdate, setSelectedPeriodClient, setPreferencesId) {
        setSelectedPeriodClient(periodId);
        handleUpdate(periodId, seasonId, preferencesId);
        if (periodId === 0) {
            setPreferencesId(0);
            setSelectedPeriodClient(0);
        }
    }

    useEffect(() => {
        setPeriodsTemplate((seasonsAndPeriods.periods && (seasonsAndPeriods.periods.length > 0)) ? seasonsAndPeriods.periods.map((item) => {
            const findPeriods = (resPeriodsTimes && resPeriodsTimes.length > 0)
                ? resPeriodsTimes.find(i => (i.season_period_id === item.id) && (i.season_id === seasonId))
                : false;

            const timeRange = findPeriods ? findPeriods.times : '';

            return (
                <ItemChangeRectangleButton
                    key={item.id}
                    handleChange={(periodId, seasonId) => handleChange(periodId, seasonId, preferencesId, handleUpdate, setSelectedPeriodClient, setPreferencesId)}
                    item={item}
                    seasonId={seasonId}
                    selectedItemId={selectedPeriodClient}
                    styleSelect={styleSelect}
                    styleNotSelect={styleNotSelect + (!isValid ? ' ' + style.redBorder : '')}
                    timeRange={timeRange}
                />
            );
        }) : '');
    }, [seasonsAndPeriods, selectedPeriodClient, seasonId, styleNotSelect, styleSelect, resPeriodsTimes, preferencesId, handleUpdate, isValid]);

    return (
        <div className={style.tireChildSection}>
            {
                (seasonsAndPeriodsSuccess)
                    ? (<label className={style.labelTire}><LoadingIndicator /></label>)
                    : (
                        <label className={style.labelTire}>
                            <div className={style.seasonTiresImg}><img src={SEASON_ID_SPRING === seasonId ? leaf : snow} alt='season_img' /></div>
                            <span>{ title }</span>
                            {
                                seasonsRange
                                    ? <span className={style.textDisable}>{ seasonsRange }</span>
                                    : seasonNotSet
                            }
                        </label>
                    )
            }
            {
                periodsTemplate
            }
        </div>
    );
};

SeasonTires.propTypes = {
    seasonsAndPeriodsSuccess: PropTypes.bool,
    seasonId: PropTypes.number,
    seasonsAndPeriods: PropTypes.object,
    carDetailsInfo: PropTypes.object,
    handleUpdate: PropTypes.func,
    partner: PropTypes.object,
    title: PropTypes.string,
    isValid: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo }, settingsSeasons: { seasonsAndPeriods, seasonsAndPeriodsSuccess }, auth: { partner } }) => {
    return {
        carDetailsInfo,
        seasonsAndPeriods,
        seasonsAndPeriodsSuccess,
        partner
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(SeasonTires);
