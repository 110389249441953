import React from 'react';
import SvPhotoViewer from './components/SvPhotoViewer';
import SvCarBasicInfo from './components/SvCarBasicInfo';
import SvBasicHeader from 'components/SvBasicHeader';
import LowNodes from './components/LowNodes';
import { connect } from 'react-redux';
import { SetCarDetails, SetCarDetailsObjectsFields } from 'components/Requests';

import style from './SvBookDetails.module.scss';
import PropTypes from 'prop-types';
import StatusesWrap from '../../../components/StatusesWrap/StatusesWrap';
import StatusHistory from './components/StatusHistory/StatusHistory';

const SvBookDetails = ({ carDetailsInfo }) => {
    return (
        <div className={style.svBookDetails}>
            <SvCarBasicInfo />
            <SvPhotoViewer imagesCar={carDetailsInfo.photo} />
            <StatusHistory>
                <StatusesWrap classes={['light']} withoutHeader />
            </StatusHistory>
            <div className={style.svRow}>
                <div className={style.svEngineNodes}>
                    <div className={`${style.carEngine}`}>
                        <SvBasicHeader header='Двигатель' />
                        <SetCarDetailsObjectsFields nameForm='Тип ДВС' propCar='engine' key='engine' valueField={carDetailsInfo.engine} />
                        <SetCarDetails nameForm='Объем, см^3' propCar='engine_capacity' key='engineCapacity' valueField={carDetailsInfo.engineCapacity} />
                        <SetCarDetails nameForm='Мощность, л.с.' propCar='power' key='enginePower' valueField={carDetailsInfo.power} />
                    </div>
                </div>
                <LowNodes />
            </div>
        </div>
    );
};

SvBookDetails.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(SvBookDetails);
