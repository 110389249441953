import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    carDetailsUpload,
    carIdCollectLoaded,
    clientIdCollectLoaded,
    fetchCollectBlocks,
    resetCarCollectId
} from '../../../actions';
import { saveReserveOrOrder } from '../../../reducers/preReserve';
import AnchorsBlock from '../../SheetCollect/SheetCollectBody/components/AnchorsBlock';
import { clearInvitationsFrom1C, getAnchorsFrom1C } from '../../../reducers/anchorsFrom1C';

const SvBookCollectContainer = ({
    carIdCollectLoaded,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    fetchCollectBlocks,
    carDetailsUpload,
    resetCarCollectId,
    clearInvitationsFrom1C,
    getAnchorsFrom1C,
    carIdCollect,
    clientIdCollect,
    carIdRaw,
    textHeaders,
    carCollectBlocks,
    blocks,
    carDetailsInfo,
    showAnchors,
    clientIdRaw = 0,
    reserveOrderRaw = {}
}) => {
    useEffect(() => {
        carIdRaw && carIdCollectLoaded(carIdRaw);
    }, [carIdCollectLoaded, carIdRaw]);

    useEffect(() => {
        clientIdRaw && clientIdCollectLoaded(clientIdRaw);
    }, [clientIdCollectLoaded, clientIdRaw]);

    useEffect(() => {
        saveReserveOrOrder(reserveOrderRaw);
    }, [saveReserveOrOrder, reserveOrderRaw]);

    useEffect(() => {
        carIdCollect && carDetailsUpload(carIdCollect, clientIdCollect);
    }, [carIdCollect, clientIdCollect, carDetailsUpload]);

    useEffect(() => {
        return () => resetCarCollectId();
    }, [resetCarCollectId]);

    useEffect(() => {
        fetchCollectBlocks();
    }, [fetchCollectBlocks]);

    useEffect(() => {
        getAnchorsFrom1C(carIdCollect);
        return () => {
            clearInvitationsFrom1C();
        };
    }, [carIdCollect, clearInvitationsFrom1C, getAnchorsFrom1C]);

    return (
        <AnchorsBlock
            blocks={blocks}
            textHeaders={textHeaders}
            carDetailsInfo={carDetailsInfo}
            showAnchors={showAnchors}
            carCollectBlocks={carCollectBlocks}
            forSvBook
        />
    );
};

SvBookCollectContainer.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsUpload: PropTypes.func,
    resetCarCollectId: PropTypes.func,
    saveReserveOrOrder: PropTypes.func,
    clientIdCollectLoaded: PropTypes.func,
    carIdCollectLoaded: PropTypes.func,
    carIdRaw: PropTypes.number,
    clientIdRaw: PropTypes.number,
    reserveOrderRaw: PropTypes.object,
    fetchCollectBlocks: PropTypes.func,
    textHeaders: PropTypes.array.isRequired,
    blocks: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    showAnchors: PropTypes.bool,
    carCollectBlocks: PropTypes.object,
    clearInvitationsFrom1C: PropTypes.func,
    getAnchorsFrom1C: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { showAnchors, carDetailsInfo, carIdCollect, clientIdCollect }, templatesOfFrameState: { textHeaders, carCollectBlocks, blocks } }) => {
    return {
        carIdCollect,
        clientIdCollect,
        textHeaders,
        carCollectBlocks,
        blocks,
        carDetailsInfo,
        showAnchors
    };
};

export default connect(mapStateToProps, {
    getAnchorsFrom1C,
    clearInvitationsFrom1C,
    fetchCollectBlocks,
    carDetailsUpload,
    resetCarCollectId,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    carIdCollectLoaded
})(SvBookCollectContainer);
