import React from 'react';
import { PROLONGATION_MODE_INITIATION } from '../../../../../constants/prolongationMode';
import style from './PreReserveReportTableHeader.module.scss';
import PropTypes from 'prop-types';
import arrow from 'assets/arrowDark.svg';

const fields1 = {
    id: '№',
    createdAt: 'Создан',
    car: 'Машина',
    carNumber: 'Регистрационный номер',
    label: 'Услуга',
    dates: 'Якорь',
    scheduledTo: 'С',
    completesAt: 'По',
    statusStage: 'Статус',
    createdByOwner: 'Кто создал',
    interest: 'Интерес'
};

const WithSort = ({ styleName, field, data, sortField, handleSort, handleClear }) => {
    const clearBlock = (
        <div key='clear_block' className={style.clearSort} onClick={(e) => e.stopPropagation()}>
            <div
                onClick={() => handleSort(field)}
                className={(field === sortField?.slice(-field?.length) ? (sortField === field ? style.select : '') : '')}
            >
                По возрастанию
            </div>
            <div
                onClick={() => handleSort(`-${field}`)}
                className={(field === sortField?.slice(-field?.length) ? (sortField === field ? '' : style.select) : '')}
            >
                По убыванию
            </div>
            <div onClick={handleClear} className={style.red}>Очистить сортировку</div>
        </div>
    );

    const sortButton = (
        <div key='sort_arrow' className={`${style.sort} ${field === sortField ? style.sortAsk : style.sortDesk}`}>
            <img src={arrow} alt='сортировка' />
        </div>
    );

    return (
        <div className={styleName + ' ' + style.sortStyle} onClick={() => handleSort(sortField ? (sortField === field ? `-${field}` : field) : `-${field}`)}>
            { data }
            {
                field === sortField?.slice(-field?.length)
                    ? [sortButton, clearBlock]
                    : ''
            }
            { clearBlock }
        </div>
    );
};

WithSort.propTypes = {
    field: PropTypes.string,
    styleName: PropTypes.string,
    data: PropTypes.string,
    handleSort: PropTypes.func,
    handleClear: PropTypes.func,
    sortField: PropTypes.string
};

const PreReserveReportTableHeader = ({ mode, withLabel, sortField, handleSort, handleClearSort, fields = fields1 }) => {
    const path = mode === PROLONGATION_MODE_INITIATION && (
        <div className={style.other}>{ fields.interest }</div>
    );

    return (
        <div className={style.prereserve + ' ' + style.prereserveHeader}>
            <div className={style.id}>{ fields.id }</div>
            <WithSort sortField={sortField} handleSort={handleSort} field='id' styleName={style.date} data={fields.createdAt} handleClear={handleClearSort} />
            <div className={style.car}>{ fields.car }</div>
            <div className={style.carNumber}>{ fields.carNumber }</div>
            { withLabel && <div className={style.other}>{ fields.label }</div> }
            <div className={style.dateHeader}>
                <WithSort sortField={sortField} handleSort={handleSort} field='scheduled_from' styleName={style.dateHeaderHeader} data={fields.dates} handleClear={handleClearSort} />
                { /* Нужно переделать в scheduled_to после переименования на ангаре */ }
            </div>
            { path }
            <div className={style.owner}>{ fields.createdByOwner }</div>
            <div className={style.owner}>{ fields.statusStage }</div>
        </div>
    );
};

PreReserveReportTableHeader.propTypes = {
    mode: PropTypes.string,
    fields: PropTypes.object,
    handleSort: PropTypes.func,
    handleClearSort: PropTypes.func,
    sortField: PropTypes.string,
    withLabel: PropTypes.bool
};

export default PreReserveReportTableHeader;
