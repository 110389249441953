function searchParentBlockId(blocks, blockId, recursiveSearchParentIds = false) {
    let parentBlockId = null;
    const parentBlockIds = [];
    const block = blocks ? blocks.reduce((accum, item) => {
        return item.id === blockId ? item : accum;
    }, null) : null;
    if (block) {
        if (recursiveSearchParentIds === false) {
            parentBlockId = block.parent_block_id ? { childId: block.id, parentId: block.parent_block_id } : null;
            return parentBlockId;
        }
        if (recursiveSearchParentIds === true) {
            const parentBlockIdItem = block.parent_block_id ? { childId: block.id, parentId: block.parent_block_id } : null;
            parentBlockIdItem && parentBlockIds.push(parentBlockIdItem);
            searchParentBlockId(blocks, parentBlockIdItem.parentId) && parentBlockIds.push(searchParentBlockId(blocks, parentBlockIdItem.parentId));

            return parentBlockIds && parentBlockIds.length > 0 ? parentBlockIds : null;
        }
    }
    return null;
}

export default searchParentBlockId;
