import React, { useState, useEffect } from 'react';
import style from '../../SheetCollectBody.module.scss';
import SubHeaderCollect from 'components/SubHeaderCollect';
import { compose, withApiService } from 'Hoc';
import { SetCarDetailsDate, SetCarDetailsSelectCollect, SetCarInsuranceDate, SetCarInterest } from 'components/Requests';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BLOCK_LABEL_OSAGO } from '../../../../../constants/carCollectBlockLabels';
import dateFromDateField from '../../../../../utils/dateFromDateField';
import { fetchPostCarInsuranceDeadline } from '../../../../../actions';
import PreReserve from '../../../../../components/PreReserve/PreReserve';
import { isBefore } from 'date-fns';
import MessageForForm from '../../../../../components/MessageFoForm';
import InviteFrom1C from '../InviteFrom1C';
import { ButtonsProlongation } from '../../../../../components/Buttons/ButtonsCommon';
import ToggleRadio from '../../../../../components/CheckToggle/ToggleRadio';
import { CHANGE_MODE } from '../../../../../constants/prolongationMode';

const Osago = ({
    textSubHeaders,
    forSvBook,
    carDetailsInfo,
    id,
    blocks,
    carCollectBlocks,
    anchors,
    clientIdCollect,
    invitations1C,
    isProlongation,
    handleUpdateProlongation,
    fetchPostCarInsuranceDeadline
}) => {
    const [isHidden, setIsHidden] = useState(false);
    const [packetId, setPacketId] = useState(0);
    const [inviteDate, setInviteDate] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isValidationOn, setValidationOn] = useState(false);

    const handleValidationTurnOn = (turnOn) => {
        setValidationOn(turnOn);
    };

    useEffect(() => {
        return () => setValidationOn(false);
    }, [carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        const resValidate = (carDetailsInfo?.insurance_deadline && isBefore(new Date(), new Date(carDetailsInfo.insurance_deadline))) && (isProlongation !== null);
        setIsValid(!!resValidate);
    }, [carDetailsInfo, isProlongation]);

    useEffect(() => {
        const inviteDate = invitations1C && invitations1C.length > 0 && invitations1C.find(item => item.packetId === packetId) && invitations1C.find(item => item.packetId === packetId).inviteDate;
        setInviteDate(inviteDate || '');
    }, [invitations1C, packetId]);

    useEffect(() => {
        const block = carCollectBlocks && carCollectBlocks.blocks && carCollectBlocks.blocks.find(item => item.label === BLOCK_LABEL_OSAGO);
        block && block.packet_id && setPacketId(block.packet_id);
    }, [carCollectBlocks]);

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };

    const interestsReasons = blocks ? blocks[2] : null;

    const osago = (
        <div className={style.wrapBodySubCollect}>
            <div className={style.fields}>
                <div className={style.dateEnd}>
                    <SetCarDetailsDate
                        nameForm='Дата окончания'
                        valueField={carDetailsInfo.insurance_deadline}
                        propCar='insurance_deadline'
                        idName='dateEndOsago'
                        key='insurance_deadline'
                        blockId={id}
                        classes={!isValid && (!carDetailsInfo.insurance_deadline || isBefore(new Date(carDetailsInfo.insurance_deadline), new Date()))
                            ? (isValidationOn ? ['redBorder'] : (carDetailsInfo.insurance_deadline ? ['warningColor'] : []))
                            : []}
                    />
                    {
                        isValidationOn && !carDetailsInfo.insurance_deadline
                            ? (isValid ? '' : <MessageForForm key='validate_osago' message='Укажите дату окончания' classes={['maxWidthStretch', 'height40']} />)
                            : (
                                carDetailsInfo.insurance_deadline && isBefore(new Date(carDetailsInfo.insurance_deadline), new Date())
                                    ? <MessageForForm key='is_date_over' message='Просрочен' classes={['warningColor', 'maxWidthStretch', 'height40']} />
                                    : ''
                            )
                    }
                </div>
                <SetCarDetailsSelectCollect idName='insuranceCompanyOSAGO' propCar='company_osago_id' key='company_osago_id' valueField={carDetailsInfo.company_osago_id} blockId={id} />
                <div className={style.dateEnd}>
                    <SetCarInsuranceDate nameForm='Дата окончания ВУ' valueField={carDetailsInfo.date_end_driver_license} propCar='date_end_driver_license' idName='dateEndVU' key='date_end_driver_license' blockId={id} />
                </div>
            </div>
            {
                isProlongation
                    ? ''
                    : <SetCarInterest nameBlock='Интерес к услуге' interestsReasons={interestsReasons} id={id} />
            }
            { forSvBook
                ? ''
                : (
                    <PreReserve
                        packetId={packetId}
                        type='standard'
                        serviceName='ОСАГО'
                        id='pre_reserve_osago'
                        isNotValid={!isValid}
                        isValidationOn={isValidationOn}
                        handleValidationTurnOn={handleValidationTurnOn}
                        isProlongation={isProlongation}
                        handleUpdateProlongation={handleUpdateProlongation}
                    />
                ) }
        </div>
    );

    const handleUpdateInsuranceDeadline = (newValue, oldValue) => {
        const payload = {
            id: carDetailsInfo.id,
            insurance_deadline: dateFromDateField(inviteDate)
        };
        clientIdCollect && carDetailsInfo.id && (payload.insurance_deadline !== oldValue) && fetchPostCarInsuranceDeadline(payload, clientIdCollect);
    };

    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationOsago'
            id='prolongationOsago'
            handleClick={(res) => handleUpdateProlongation(res)}
            btnSelected={CHANGE_MODE[isProlongation]}
            classes={['btnStretchCycle', 'btnTires', 'margin5', 'bgWhite', (isProlongation === null ? (isValidationOn ? 'redBorder' : 'notCheck') : '')]}
        />
    );

    return (
        <div className={style.blockAnchors} id={id}>
            <SubHeaderCollect
                mark='fullUp'
                title={textSubHeaders[0].toUpperCase()}
                handleSetHidden={handleSetHidden}
                blockId={id}
                stateBool={isHidden}
                anchors={anchors}
                actions={forSvBook ? [] : [prolongationButton]}
                classes={['withoutPadding']}
            >
                {
                    forSvBook
                        ? ''
                        : (
                            <ToggleRadio
                                text='Не собрано'
                                isActive={false}
                                key='isCollectOsagoInfo'
                                id='isCollectOsagoInfo'
                                handleClickActive={(res) => console.log('prolo nggggg', res)}
                            />
                        )
                }
            </SubHeaderCollect>
            {
                inviteDate && !carDetailsInfo.insurance_deadline
                    ? (
                        <InviteFrom1C
                            key='InviteFrom1C_osago'
                            text='дата ОСАГО'
                            inviteDate={inviteDate}
                            buttonText='Применить'
                            handleUpdateNextOil={() => handleUpdateInsuranceDeadline(inviteDate, carDetailsInfo.insurance_deadline)}
                        />
                    )
                    : ''
            }
            {
                isHidden
                    ? ''
                    : osago
            }
        </div>
    );
};

Osago.propTypes = {
    textSubHeaders: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    carCollectBlocks: PropTypes.object,
    anchors: PropTypes.bool,
    forSvBook: PropTypes.bool,
    id: PropTypes.number,
    blocks: PropTypes.array,
    invitations1C: PropTypes.array,
    clientIdCollect: PropTypes.number,
    handleUpdateProlongation: PropTypes.func,
    isProlongation: PropTypes.bool,
    fetchPostCarInsuranceDeadline: PropTypes.func
};

const mapStateToProps = ({
    carDetailsState: { carDetailsInfo, clientIdCollect },
    templatesOfFrameState: { initialTemplateBlocks, textSubHeaders, optsForInsuranceCompany, blocks, carCollectBlocks },
    anchorsFrom1C: { invitations1C }
}) => {
    return {
        carDetailsInfo,
        initialTemplateBlocks,
        textSubHeaders,
        optsForInsuranceCompany,
        carCollectBlocks,
        blocks,
        invitations1C,
        clientIdCollect
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, { fetchPostCarInsuranceDeadline })
)(Osago);
