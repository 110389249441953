import React from 'react';

import PropTypes from 'prop-types';
import style from './GrayButton.module.scss';

const GrayButton = ({ id, text, disabled, handlerClick, classes = [] }) => {
    return (
        <button id={id} className={style.grayButton + classes.map(item => ' ' + style[item]).join('')} disabled={disabled} onClick={handlerClick}>{ text }</button>
    );
};

GrayButton.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    text: PropTypes.string,
    handlerClick: PropTypes.func,
    classes: PropTypes.arrayOf(PropTypes.oneOf(
        ['bgGrey', 'small', 'red', 'redBg', 'showMore', 'visitorButtonAdd', 'paddingMore', 'disabled', 'blueBg', 'minWidth', 'marginRight16', 'marginLeft8', 'bgTransparent', 'thinButton', 'bgGreen', 'margin0_30_25_30']
    ))
};

export default GrayButton;
