import React from 'react';
import style from './CardInfo.module.scss';
import PropTypes from 'prop-types';

const CardInfo = ({ children, textHeader = '', classes = [] }) => {
    return (
        <div className={style.cardInfo + classes.map(item => ' ' + style[item]).join('')}>
            <h2 className={style.text}>{ textHeader }</h2>
            <div className={style.children}>
                {
                    children || ''
                }
            </div>
        </div>
    );
};

CardInfo.propTypes = {
    children: PropTypes.node,
    textHeader: PropTypes.string,
    classes: PropTypes.array
};

export default CardInfo;
