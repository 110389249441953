import qs from 'query-string';

export default function addedParamToUrl(fields, newDataField, history, pathname, search) {
    let data;

    if (Array.isArray(fields)) {
        const res = {};
        fields.forEach((field, key) => {
            res[field] = newDataField[key];
        });
        data = search && qs.parse(search)
            ? { ...qs.parse(search), ...res }
            : { ...res };
    }

    history.push({
        pathname: pathname,
        search: qs.stringify(data)
    });
}
