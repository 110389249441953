import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './Statuses.module.scss';
import { GrayButton } from '../../Buttons';
import StatusForm from '../../StatusForm';
import { OBJECT_STATUS_UNKNOWN, STATUS_MAIN_USER, STATUS_OWNER, STATUS_LPR, STATUS_DELIVER } from '../../../constants/statusesCarClient';

function getOldStatusesClient(clientId, carClients) {
    const existClient = carClients.find(client => client.id === clientId);
    return existClient ? (existClient.statuses ? existClient.statuses.map(item => item.label) : []) : [];
}

function fillStatusesFields(carClients) {
    let res = [];
    carClients.forEach(item => {
        res = item.statuses.length > 0 ? [...res, ...item.statuses.map(stat => {
            return {
                client: item,
                statusLabel: stat.label
            };
        })] : res;
    });

    res = res.reduce((accum, item) => {
        accum[item.statusLabel] = [...(Object.prototype.hasOwnProperty.call(accum, item.statusLabel) ? accum[item.statusLabel] : []), item.client];
        return accum;
    }, {});

    return res;
}

const Statuses = ({
    carClients,
    withoutStatuses,
    handleUpdateStatuses,
    handleAddClient,
    validObj,
    statusesConfig,
    searchNewClient,
    carId,
    foundClients,
    searchInfo,
    isLoadSearchClient,
    handleDeleteClient,
    classes
}) => {
    const [fieldsForms, setFieldsForms] = useState([]);
    const [newStatuses, setNewStatuses] = useState([]);
    const [withoutStatusesClients, setWithoutStatusesClients] = useState([]);

    useEffect(() => {
        validObj.dataForCorrection && setNewStatuses(validObj.dataForCorrection);
    }, [validObj]);

    useEffect(() => {
        const resFromExist = carClients.length > 0 && fillStatusesFields(carClients);

        const handleUpdateClientStatuses = (dataClient, clientStatuses, isDeleteStatus) => {
            if (dataClient.clientId) {
                delete dataClient.id;
                const oldStatuses = getOldStatusesClient(dataClient.clientId, carClients);
                handleUpdateStatuses({ ...dataClient, clientStatuses: isDeleteStatus ? clientStatuses : [...oldStatuses, ...clientStatuses] });
            } else {
                handleAddClient({ statuses: clientStatuses, nick: dataClient.nick, phone: dataClient.phone });
            }
        };

        setFieldsForms(statusesConfig?.map(status => {
            let result = [];
            if (newStatuses?.includes(status.label)) {
                result = ([
                    <StatusForm
                        key={'new_client_' + status.label}
                        status={status}
                        title={status.title}
                        handleChangeClient={(data) => handleUpdateClientStatuses({ ...data, clientId: data.id }, [status.label])}
                        handleDeleteStatuses={() => setNewStatuses(s => s.filter(item => item !== status.label))}
                        withDeleteBtn={!validObj?.dataForCorrection.includes(status.label)}
                        searchNewClient={searchNewClient}
                        isLoadSearchClient={isLoadSearchClient}
                        searchInfo={searchInfo}
                        foundClients={foundClients}
                        carId={carId}
                        mainExist={!!resFromExist[STATUS_MAIN_USER]}
                        ownerExist={!!resFromExist[STATUS_OWNER]}
                        classes={classes?.includes('light') ? ['light2', 'marginBottom5'] : ['marginBottom5']}
                        itNew
                    />
                ]);
            }

            if (Object.prototype.hasOwnProperty.call(resFromExist, status.label)) {
                resFromExist[status.label].forEach((item, key) => {
                    const statusesExist = item?.statuses?.map(stat => stat.label) || [];
                    result.push(
                        <StatusForm
                            key={'client_' + item.id + '_' + status.label}
                            status={status}
                            title={status.title + (key > 0 ? ' ' + (key + 1) : '')}
                            client={item}
                            carId={carId}
                            handleDeleteStatuses={() => handleUpdateClientStatuses(
                                { clientId: item.id },
                                [
                                    ...item.statuses
                                        .map(stat => stat.label)
                                        .filter(i => i !== status.label)
                                ],
                                true
                            )}
                            handleChangeClient={(data) => handleUpdateClientStatuses(
                                { clientId: item.id },
                                data.statuses || []
                            )}
                            withDeleteBtn
                            copyToStatuses={[...statusesConfig?.filter(stat => !statusesExist.includes(stat.label))]}
                            classes={classes?.includes('light') ? ['light2', 'marginBottom5'] : ['marginBottom5']}
                            disabled
                            mainExist={!!resFromExist[STATUS_MAIN_USER]}
                            ownerExist={!!resFromExist[STATUS_OWNER]}
                        />
                    );
                });
            }
            return result;
        }) || []);

        setWithoutStatusesClients(withoutStatuses
            ? withoutStatuses.map(client => {
                return (
                    <StatusForm
                        key={'client_' + client.id + '_unknown'}
                        status={OBJECT_STATUS_UNKNOWN}
                        title='Статус пользования отсутствует'
                        textSpan='Назначить'
                        client={client}
                        carId={carId}
                        handleDeleteStatuses={() => handleDeleteClient(client.id)}
                        handleChangeClient={(data) => handleUpdateClientStatuses(
                            { clientId: client.id },
                            data.statuses || []
                        )}
                        withDeleteBtn
                        copyToStatuses={[...statusesConfig?.filter(stat => !client.statuses?.map(stat => stat.label).includes(stat.label))]}
                        classes={classes?.includes('light') ? ['light2', 'marginBottom5'] : ['marginBottom5']}
                        mainExist={!!resFromExist[STATUS_MAIN_USER]}
                        ownerExist={!!resFromExist[STATUS_OWNER]}
                        isUnknown
                        disabled
                    />
                );
            })
            : []);
    }, [handleDeleteClient, classes, carClients, statusesConfig, newStatuses, validObj, searchNewClient, handleAddClient, handleUpdateStatuses, carId, foundClients, isLoadSearchClient, searchInfo, withoutStatuses]);

    const handleAddNewStatusForm = (status) => {
        setNewStatuses(s => {
            if (s.includes(status)) {
                return s;
            } else {
                return [...s, status];
            }
        });
    };

    const buttons = (
        <div className={style.buttons}>
            <GrayButton
                id='add_lpr'
                text='Добавить ЛПР'
                handlerClick={() => handleAddNewStatusForm(STATUS_LPR)}
                classes={(classes?.includes('light') ? ['showMore', 'minWidth', 'marginRight16', 'bgTransparent'] : ['showMore', 'minWidth', 'marginRight16'])}
            />
            <GrayButton
                id='add_deliver'
                text='Добавить «Пригоняю на обслуживание»'
                handlerClick={() => handleAddNewStatusForm(STATUS_DELIVER)}
                classes={(classes?.includes('light') ? ['showMore', 'minWidth', 'bgTransparent'] : ['showMore', 'minWidth'])}
            />
        </div>
    );

    return (
        <section className={style.statuses + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}>
            {
                statusesConfig
                    ? <div className={style.withStatuses}>{ fieldsForms }</div>
                    : ''
            }
            {
                withoutStatusesClients && withoutStatusesClients.length > 0
                    ? <div className={style.withoutStatuses}>{ withoutStatusesClients }</div>
                    : ''
            }
            {
                buttons || ''
            }
        </section>
    );
};

Statuses.propTypes = {
    carClients: PropTypes.array,
    handleUpdateStatuses: PropTypes.func,
    handleDeleteClient: PropTypes.func,
    handleAddClient: PropTypes.func,
    statusesConfig: PropTypes.array,
    validObj: PropTypes.object,
    withoutStatuses: PropTypes.array,
    searchNewClient: PropTypes.func,
    carId: PropTypes.number,
    foundClients: PropTypes.array,
    searchInfo: PropTypes.object,
    isLoadSearchClient: PropTypes.bool,
    classes: PropTypes.array
};

export default Statuses;
