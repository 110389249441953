import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import classes from './authLayout.module.scss';
import PropTypes from 'prop-types';

class AuthLayout extends Component {
    state = {
        passwordVisible: false
    };

    componentDidMount() {
        if (this.props.partner && this.props.token) {
            this.props.history.push('/');
        }
    }

    componentDidUpdate() {
        if (this.props.partner && this.props.token) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div className={classes.login}>
                <div className={classes.header}><span>Connect Pro</span><span className={classes.blue}>Auto</span></div>
                <div className={classes.body}>
                    { this.props.children }
                </div>
            </div>
        );
    }
}

AuthLayout.propTypes = {
    partner: PropTypes.object,
    token: PropTypes.string,
    children: PropTypes.node,
    history: PropTypes.object
};

const mapStateToProps = ({ auth: { partner, token } }) => {
    return {
        partner,
        token
    };
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
