import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setShowAnchors, setShowSecretary } from '../../actions';
import ToggleRadio from './ToggleRadio';
import { clearBlockSecretary } from '../../reducers/secretary';

const CheckToggle = ({ showAnchors, setShowAnchors, handleUpdateBlockInterest, blockId, showSecretary, setShowSecretary, clearBlockSecretary, carIdCollect, clientIdCollect }) => {
    const handleClickToggle = () => {
        if (blockId === 2) {
            setShowAnchors(!showAnchors);
            (showAnchors === false) && handleUpdateBlockInterest(blockId);
        } else if (blockId === 8) {
            setShowSecretary(!showSecretary);
            if (showSecretary === false) {
                handleUpdateBlockInterest(blockId);
                clearBlockSecretary(clientIdCollect, carIdCollect);
            }
        }
    };

    return (
        <ToggleRadio isActive={blockId === 8 ? !showSecretary : !showAnchors} id={`setCheckToggle${blockId}`} text='Не удалось собрать' handleClickActive={handleClickToggle} />
    );
};

CheckToggle.propTypes = {
    showAnchors: PropTypes.bool,
    setShowAnchors: PropTypes.func,
    handleUpdateBlockInterest: PropTypes.func,
    blockId: PropTypes.number,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    showSecretary: PropTypes.bool,
    setShowSecretary: PropTypes.func,
    clearBlockSecretary: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { showAnchors, showSecretary, carIdCollect, clientIdCollect } }) => {
    return {
        showAnchors,
        showSecretary,
        carIdCollect,
        clientIdCollect
    };
};

const mapDispatchToProps = {
    setShowAnchors,
    setShowSecretary,
    clearBlockSecretary
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckToggle);
