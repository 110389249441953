import React from 'react';
import ReportsHeader from '../../../ReportsHeader';
import PairColumn from '../../../../../components/PairColumn';
import CollectInfoReportBody from './CollectInfoReportBody';

const CollectInfoReport = () => {
    return (
        <PairColumn>
            <ReportsHeader textHeader='Отчет по листам сбора информации' classes={['inReport']} withDataFilter />
            <CollectInfoReportBody />
        </PairColumn>
    );
};

export default CollectInfoReport;
