import React from 'react';
import PropTypes from 'prop-types';
import style from './PreReserveDatePickerWrap.module.scss';

const PreReserveDatePickerWrap = ({ text, children }) => {
    return (
        <div className={style.paddingDateInput}>
            <div className={style.paddingText}>
                {
                    text || ''
                }
            </div>
            { children }
        </div>
    );
};

PreReserveDatePickerWrap.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node
};

export default PreReserveDatePickerWrap;
