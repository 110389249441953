import React from 'react';
import { isEmpty } from '../../../../../../utils';
import { getStringPeriodSeasonOrPeriodTimes, getStringPeriodTimes } from '../../../../../../utils/getSeasonPeriodByDate';
import style from '../PreReservesReport.module.scss';
import { differenceInCalendarDays, format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import CarNumber from '../../../../../../components/CarNumber';
import { OPTIONS_PACKETS_PACKET_NAME } from '../../../../../../constants/packets';
import { PROLONGATION_MODE_INITIATION } from '../../../../../../constants/prolongationMode';
import PropTypes from 'prop-types';
import { COLORS } from '../../../../../../constants/colors';

const PreReserveReportItem = ({ prereserve, mode, seasonsAndPeriods, timezone, withLabel }) => {
    const { id, createdAt, scheduledTo, completesAt, label, car, color, selectedInterest, createdByOwner } = prereserve;
    const periodSeason = !isEmpty(seasonsAndPeriods) ? getStringPeriodSeasonOrPeriodTimes(scheduledTo, completesAt, seasonsAndPeriods) : '';

    const date = (
        <>
            <div className={style.date2}>{ scheduledTo ? format(new Date(scheduledTo), 'dd MMMM yyyy', { locale: ru, timeZone: timezone }) : '' }</div>
        </>
    );

    const month = (
        differenceInCalendarDays(new Date(completesAt), new Date(scheduledTo)) > 1
            ? <div className={style.date2}>{ scheduledTo ? format(new Date(scheduledTo), 'LLLL yyyy', { locale: ru, timeZone: timezone }) : '' }</div>
            : date
    );

    const tires = (
        <>
            <div className={style.date2}>
                {
                    scheduledTo
                        ? periodSeason
                        : ''
                }
            </div>
        </>
    );

    const prolongationPath = (
        <>
            <div className={style.date2}>
                { (scheduledTo && completesAt && getStringPeriodTimes(scheduledTo, completesAt)) || 'ошибка периода' }
            </div>
        </>
    );

    const initiationPath = (
        <div className={style.other}>{ selectedInterest || 'Неизвестно' }</div>
    );
    return (
        <div className={style.prereserve}>
            <div className={style.id}>{ id }</div>
            <div className={style.date}>{ createdAt ? format(new Date(createdAt), 'dd.MM.yyyy HH:mm', { locale: ru, timeZone: timezone }) : '' }</div>
            <div className={style.car}>
                <div className={style.text}>{ car?.model?.mark?.title } { car?.model?.title }</div>
            </div>
            <div className={style.carNumber}>
                <CarNumber number={car?.license_plate} region={car?.region_id} classes={['whiteNumber']} />
            </div>
            { withLabel && <div className={style.other}>{ OPTIONS_PACKETS_PACKET_NAME[label] }</div> }
            {
                label === 'kacko' || label === 'osago'
                    ? date
                    : (
                        label === 'oil'
                            ? month
                            : (
                                label === 'tires'
                                    ? tires
                                    : prolongationPath)
                    )
            }
            { mode === PROLONGATION_MODE_INITIATION && initiationPath }
            <div className={style.owner}>{ createdByOwner?.name }</div>
            <div className={style.owner + (color ? ' ' + style[color?.toLowerCase()] : '')}>{ color ? COLORS[color?.toLowerCase()] : 'Не указан' }</div>
        </div>
    );
};

PreReserveReportItem.propTypes = {
    prereserve: PropTypes.object,
    seasonsAndPeriods: PropTypes.object,
    withLabel: PropTypes.bool,
    mode: PropTypes.string,
    timezone: PropTypes.string
};

export default PreReserveReportItem;
