import React, { useState, useEffect } from 'react';
import style from './SecretaryWeekDays.module.scss';
import SubHeaderCollectSecretary from 'components/SubHeaderCollect/SubHeaderCollectSecretary';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ToggleRadio from '../../../../../components/CheckToggle/ToggleRadio';
import { setFlagDoNotCallClient } from 'actions';
import { GrayButton } from '../../../../../components/Buttons';
import SecretaryWrapper from './SecretaryWrapper/SecretaryWrapper';
import {
    deleteSecretariesByType,
    handleGetClientNotificationsSettings,
    handleUpdateClientNotificationsSettings,
    setDefaultSecretary
} from 'reducers/secretary';
import { SECRETARY_MILEAGE } from '../../../../../constants/secretaryTypes';
import NotificationWeekTimeBlock from './SecretaryWrapper/components/NotificationWeekTimeBlock';
import ArrowHiddenContent from '../../../../../components/ArrowHiddenContent';

const SecretaryWeekDays = ({
    isMainClientExist,
    deleteSecretariesByType,
    secretariesCommon,
    secretaryCommonConnections,
    setDefaultSecretary,
    clientId,
    carIdCollect,
    weekDayTimeSettings,
    handleGetClientNotificationsSettings,
    handleUpdateClientNotificationsSettings,
    weekDayTimeSettingsSuccess,
    classes = []
}) => {
    const [isSecretaryCommonActive, setSecretaryCommonActive] = useState(false);
    const [isMileageOpen, setIsMileageOpen] = useState(true);

    useEffect(() => {
        setSecretaryCommonActive((secretaryCommonConnections.length > 0) || ((secretariesCommon.length > 0) && (secretariesCommon[0].id !== 'initialDayTimeBoxWrapper')));
    }, [secretariesCommon, secretaryCommonConnections]);

    const handleDefault = (typeSecretary) => {
        if (typeSecretary === SECRETARY_MILEAGE) {
            setDefaultSecretary(clientId, { secretaryType: typeSecretary }, carIdCollect);
        }
        if ((typeSecretary !== SECRETARY_MILEAGE) && typeSecretary) {
            deleteSecretariesByType(clientId, typeSecretary, carIdCollect);
            setDefaultSecretary(clientId, { secretaryType: typeSecretary }, carIdCollect);
        }
    };

    return (
        <>
            <div className={style.secretaryCallTime + ' ' + style.firstSecretary + classes.map(item => ' ' + style[item]).join('')}>
                <SubHeaderCollectSecretary key='secretaryCommon' classes={['justifyContentLeft']} mark='soSoFilled' title='Связь call-центра с гостем'>
                    <ToggleRadio key='timeForCallIdCommon' text='' isActive={isSecretaryCommonActive} id='timeForCallIdCommon' handleClickActive={() => deleteSecretariesByType(clientId, 'common', carIdCollect)} />
                    <GrayButton key='secretaryCommonGrayButton' text='Вернуть настройки по умолчанию' handlerClick={() => handleDefault('common')} disabled={!isSecretaryCommonActive} />
                </SubHeaderCollectSecretary>
                <SecretaryWrapper clientId={clientId} typeSecretary='common' key='SecretaryWrapper_common' carIdCollect={carIdCollect} />
            </div>
            {
                isMainClientExist
                    ? (
                        <div className={style.secretaryCallTime + classes.map(item => ' ' + style[item]).join('')}>
                            <SubHeaderCollectSecretary
                                stateBool={isMileageOpen}
                                key='secretaryMileage'
                                classes={['justifyContentLeft', 'paddingRight20']}
                                mark='soSoFilled'
                                title='Сбор информации о пробеге'
                            >
                                <div />
                                <ArrowHiddenContent handleSetHidden={() => setIsMileageOpen(s => !s)} stateBool={!isMileageOpen} blockId='mileage_secretary' />
                            </SubHeaderCollectSecretary>
                            {
                                isMileageOpen && (
                                    <SecretaryWrapper
                                        clientId={clientId}
                                        typeSecretary={SECRETARY_MILEAGE}
                                        key='SecretaryWrapper_mileage'
                                        carIdCollect={carIdCollect}
                                        dataInner={
                                            <NotificationWeekTimeBlock
                                                type={SECRETARY_MILEAGE}
                                                handleGetSettings={() => handleGetClientNotificationsSettings(clientId)}
                                                weekDayTimeSettings={weekDayTimeSettings}
                                                handleUpdate={(data) => handleUpdateClientNotificationsSettings(clientId, data)}
                                                weekDayTimeSettingsSuccess={weekDayTimeSettingsSuccess}
                                            />
                                        }
                                    />
                                )
                            }
                        </div>
                    )
                    : ''
            }
        </>
    );
};

SecretaryWeekDays.propTypes = {
    setDefaultSecretary: PropTypes.func,
    deleteSecretariesByType: PropTypes.func,
    secretariesCommon: PropTypes.array,
    secretaryCommonConnections: PropTypes.array,
    clientId: PropTypes.number,
    carIdCollect: PropTypes.number,
    classes: PropTypes.array,
    isMainClientExist: PropTypes.bool,
    weekDayTimeSettings: PropTypes.object,
    handleGetClientNotificationsSettings: PropTypes.func,
    handleUpdateClientNotificationsSettings: PropTypes.func,
    weekDayTimeSettingsSuccess: PropTypes.bool
};

const mapStateToProps = ({
    secretary: { secretariesMileage, secretaryMileageConnections, secretariesCommon, secretaryCommonConnections, weekDayTimeSettings, weekDayTimeSettingsSuccess }
}) => {
    return {
        secretariesMileage,
        secretaryMileageConnections,
        secretariesCommon,
        secretaryCommonConnections,
        weekDayTimeSettings,
        weekDayTimeSettingsSuccess
    };
};

export default connect(mapStateToProps, { setFlagDoNotCallClient, deleteSecretariesByType, setDefaultSecretary, handleGetClientNotificationsSettings, handleUpdateClientNotificationsSettings })(SecretaryWeekDays);
