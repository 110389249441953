import React, { useEffect } from 'react';
import ReportsHeader from '../../../ReportsHeader';
import ProlongationInActionBodyWrap from './ProlongationInActionBodyWrap';
import PairColumn from '../../../../../components/PairColumn';
import PropTypes from 'prop-types';
import { PROLONGATION_MODE_INITIATION, PROLONGATION_MODE_PROLONGATION } from '../../../../../constants/prolongationMode';
import { connect } from 'react-redux';
import { setModeProlongation } from '../../../../../reducers/reportsProlongationInAction';
import CardsArrayTemplate from '../../../../../components/Cards/CardsArrayTemplate';

const ProlongationInitiation = ({
    mode,
    setModeProlongation,
    prolongationsCount,
    initiationsCount
}) => {
    useEffect(() => {
        !mode && setModeProlongation(PROLONGATION_MODE_PROLONGATION);
    }, [mode, setModeProlongation]);

    const handleSetMode = (data) => {
        setModeProlongation(data);
    };

    const HeaderReports = (
        [
            <ReportsHeader
                key={'ReportsHeader_' + PROLONGATION_MODE_PROLONGATION}
                textHeader='Пролонгация'
                classes={['columnWrap', 'inReport', 'cards'].concat(mode !== PROLONGATION_MODE_PROLONGATION ? ['notActive'] : [])}
                withDateFilterProlongation
                handleOnClick={() => handleSetMode(PROLONGATION_MODE_PROLONGATION)}
                count={prolongationsCount}
            />,
            <ReportsHeader
                key={'ReportsHeader_' + PROLONGATION_MODE_INITIATION}
                textHeader='Развитие'
                classes={['columnWrap', 'inReport', 'cards'].concat(mode !== PROLONGATION_MODE_INITIATION ? ['notActive'] : [])}
                withDateFilterProlongation
                handleOnClick={() => handleSetMode(PROLONGATION_MODE_INITIATION)}
                count={initiationsCount}
            />
        ]
    );

    return <CardsArrayTemplate cardsHeaderChildren={HeaderReports} classes={['sticky']} />;
};

ProlongationInitiation.propTypes = {
    mode: PropTypes.string,
    setModeProlongation: PropTypes.func,
    prolongationsCount: PropTypes.number,
    initiationsCount: PropTypes.number
};

const ProlongationInAction = ({
    mode,
    setModeProlongation,
    prolongationsCount,
    initiationsCount
}) => {
    return (
        <PairColumn>
            <ProlongationInitiation mode={mode} setModeProlongation={setModeProlongation} prolongationsCount={prolongationsCount} initiationsCount={initiationsCount} />
            <ProlongationInActionBodyWrap mode={mode} />
        </PairColumn>
    );
};

ProlongationInAction.propTypes = {
    mode: PropTypes.string,
    setModeProlongation: PropTypes.func,
    prolongationsCount: PropTypes.number,
    initiationsCount: PropTypes.number
};

const mapStateToProps = ({ reportsProlongationInAction: { mode, prolongationsCount, initiationsCount } }) => {
    return {
        mode,
        prolongationsCount,
        initiationsCount
    };
};

export default connect(mapStateToProps, { setModeProlongation })(ProlongationInAction);
