import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose, withApiService } from 'Hoc';
import { connect } from 'react-redux';
import { updateCarImage, deleteCarImage } from 'actions';
import _ from 'lodash';
import Loader from 'components/Loader';

import style from './svPhotoViewerModal.module.scss';

const SvPhotoViewerModal = ({ activePhoto, leftShiftVisible, rightShiftVisible, carId, carChangingImagesIds, handleHidePhoto, handleShiftLeft, handleShiftRight, updateCarImage, deleteCarImage }) => {
    const [okIconVisible, setOkIconVisible] = useState(false);

    const prevActivePhotoRef = useRef({});

    useEffect(() => {
        const prevActivePhoto = prevActivePhotoRef.current;

        let timerId;
        if (activePhoto.id === prevActivePhoto.id && activePhoto.is_main === 1 && prevActivePhoto.is_main === 0) {
            setOkIconVisible(true);

            timerId = setTimeout(() => {
                setOkIconVisible(false);
            }, 1200);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [activePhoto.is_main, activePhoto.id]);

    useEffect(() => {
        prevActivePhotoRef.current = activePhoto;
    });

    const handleSetPhotoIsMain = () => {
        const data = { is_main: 1 };
        updateCarImage(data, carId, activePhoto.id);
    };

    const handleRemovePhoto = () => {
        deleteCarImage(carId, activePhoto.id);
    };

    let mainButton, deleteButton;

    if (_.includes(carChangingImagesIds, activePhoto.id)) {
        deleteButton = (
            <div className={style.buttonContainer + ' ' + style.delete}>
                <div className={`${style.button} ${style.disabled}`}>
                    <span>Удалить фото</span>
                </div>
            </div>
        );
        mainButton = (
            <div className={style.buttonContainer + ' ' + style.main}>
                <div className={`${style.button} ${style.disabled}`}>
                    <span>Сделать главным</span>
                </div>
            </div>
        );
    } else {
        deleteButton = (
            <div className={style.buttonContainer + ' ' + style.delete}>
                <div className={style.button} onClick={handleRemovePhoto}>
                    <span>Удалить фото</span>
                </div>
            </div>
        );

        mainButton = (
            <div className={style.buttonContainer + ' ' + style.main}>
                <div className={style.button} onClick={handleSetPhotoIsMain}>
                    <span>Сделать главным</span>
                </div>
            </div>
        );
    }

    if (activePhoto.is_main) {
        mainButton = (
            <div className={style.buttonContainer + ' ' + style.main}>
                <div className={`${style.button} ${style.disabled}`}>
                    <span>Установлено как главное</span>
                </div>
            </div>
        );
    }

    let okIcon;

    if (okIconVisible) {
        okIcon = <div className={style.ok} />;
    }

    return (
        <div className={style.photoModal} onClick={handleHidePhoto}>
            <div className={style.body} onClick={e => e.stopPropagation()}>
                <div className={style.imgContainer}>
                    { leftShiftVisible
                        ? <div className={`${style.shift} ${style.left}`} onClick={handleShiftLeft} /> : null }
                    { rightShiftVisible
                        ? <div className={`${style.shift} ${style.right}`} onClick={handleShiftRight} /> : null }
                    <div className={style.close} onClick={handleHidePhoto} />
                    <img src={activePhoto.url} alt='' />
                    { okIconVisible }
                    { okIcon }
                    <Loader active={_.includes(carChangingImagesIds, activePhoto.id)} />
                </div>
                <div className={style.footer}>
                    { mainButton }
                    { deleteButton }
                </div>
            </div>
        </div>
    );
};

SvPhotoViewerModal.defaultProps = {
    activePhoto: {},
    leftShiftVisible: false,
    rightShiftVisible: false
};

SvPhotoViewerModal.propTypes = {
    activePhoto: PropTypes.object.isRequired,
    carId: PropTypes.number.isRequired,
    carChangingImagesIds: PropTypes.array.isRequired,
    leftShiftVisible: PropTypes.bool,
    rightShiftVisible: PropTypes.bool,
    handleHidePhoto: PropTypes.func.isRequired,
    handleShiftLeft: PropTypes.func,
    handleShiftRight: PropTypes.func,
    updateCarImage: PropTypes.func,
    deleteCarImage: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carId, carChangingImagesIds } }) => {
    return {
        carId,
        carChangingImagesIds
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        updateCarImage: updateCarImage(apiService, dispatch),
        deleteCarImage: deleteCarImage(apiService, dispatch)
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SvPhotoViewerModal);
