import { createUniqueArrItems, httpBuildQuery, isNotEmpty } from 'utils';
import store from '../store';
import { setErrorCommon, clearErrorsCommon } from '../reducers/common';
import _ from 'lodash';
import { ORIGIN } from '../constants/origin';
import * as Sentry from '@sentry/react';
import { ContractReportError, ServerFetchError } from '../errors';
import { MESSAGES } from '../constants/errors';
import { ANGARA_PROLONGATION_FIELDS } from '../constants/angaraProlongationFields';
import { STATUS_OWNER } from '../constants/statusesCarClient';

const dayWeekendDefault = [{ value: 'Сб', day: 'saturday', isChecked: false }, { value: 'Вс', day: 'sunday', isChecked: false }];

const dayWeekDefault = [
    { value: 'Пн', day: 'monday', isChecked: false },
    { value: 'Вт', day: 'tuesday', isChecked: false },
    { value: 'Ср', day: 'wednesday', isChecked: false },
    { value: 'Чт', day: 'thursday', isChecked: false },
    { value: 'Пт', day: 'friday', isChecked: false }
];

export default class ApiService {
    _apiBase = process.env.REACT_APP_API_PATH;

    getResource = async(url, method, data, pointBase = this._apiBase, fileUpload = false, isBlob = false) => {
        const opts = {
            method: `${method}`,
            mode: 'cors',
            headers: {
                Accept: 'application/json'
            },
            ...data
        };

        const token = localStorage.getItem('token');
        if (token) {
            opts.headers['X-Api-Token'] = token;
        }

        if (!fileUpload && !isBlob) {
            opts.headers['Content-Type'] = 'application/json; charset=UTF-8';
        }

        store.dispatch(clearErrorsCommon());
        let res = await fetch(`${pointBase}${url}`, opts);

        if (!res.ok) {
            const status = res.status;
            res = (await res.json());
            res.status = status;
            store.dispatch(setErrorCommon({ ...res, trace: JSON.stringify(res.trace) }));

            Sentry.captureException(new ServerFetchError(
                `Could not fetch ${pointBase}${url} received status: ${status}, message: ${res.message}`
            ));

            throw new Error(`Could not fetch ${url} received ${res.status}`);
        }

        let body;

        if (isBlob) {
            body = await res.blob();
        } else {
            body = await res.json();
        }
        return body;
    };

    getFakeFetch = async(data) => {
        return data;
    };

    // посты
    getAllResources = async() => {
        const resources = await this.getResource('/v3/partner/resources', 'GET');
        const res = resources.items;
        return res;
    };

    // посты все
    getAllResourcesCompany = async() => {
        const resources = await this.getResource('/v3/partner/resources/all', 'GET');
        const res = resources.items;
        return res;
    };

    getSVBooks = async(params) => {
        const res = await this.getResource(`/v3/partner/svbook?${httpBuildQuery(params)}`, 'GET');
        return res;
    };

    getCarsOrdersForTimeRange = async(params) => {
        const res = await this.getResource(`/v4/partner/cars/orders?${httpBuildQuery(params)}`, 'GET');
        return res;
    };

    getAllCarCompany = async() => {
        const res = await this.getResource('/v4/partner/cars/all', 'GET');
        return res;
    };

    getCollectInfoSheets = async(params) => {
        const res = await this.getResource(`/v4/partner/cars/orders/collect-info?${httpBuildQuery(params)}`, 'GET');
        return res;
    };

    getSVBook = async(id, clientId = null, sheetCollectView = false) => {
        const data = {
            sheet_collect_view: +sheetCollectView
        };

        if (clientId) {
            data.client_id = clientId;
        }

        const preRes = await this.getResource(`/v3/partner/svbook/svbook-details/${id}?${httpBuildQuery(data)}`, 'GET');

        const next = { ...preRes, clients: Object.prototype.hasOwnProperty.call(preRes, 'clients') ? createUniqueArrItems(preRes.clients, 'id') : [] };
        const res = this._transformCarDetails(next);
        return res;
    };

    getUniqueCollectionSheet = async(id) => {
        const pre = await this.getResource('/v4/partner/collection-sheet/' + id, 'GET');
        const car = { ...pre?.car, clientId: pre?.uniqueCollectionSheet?.client_id };

        const next = { ...car, clients: Object.prototype.hasOwnProperty.call(car, 'clients') ? createUniqueArrItems(car.clients, 'id') : [] };
        const result = this._transformCarDetails(next);
        return { ...pre, car: result };
    };

    updateSVBook = async(dataCarPost, id) => {
        const preRes = await this.getResource(`/v3/partner/svbook/svbook-details/${id}`, 'POST', { body: JSON.stringify(dataCarPost) });
        const res = { ...preRes, clients: Object.prototype.hasOwnProperty.call(preRes, 'clients') ? createUniqueArrItems(preRes.clients, 'id') : [] };
        return res;
    };

    updateSVBook2 = async(dataCarPost) => {
        const preRes = await this.getResource('/v3/partner/svbook/svbook-details2', 'POST', { body: JSON.stringify(dataCarPost) });
        const res = {
            ...preRes,
            car: { ...preRes.car, clients: Object.prototype.hasOwnProperty.call(preRes.car, 'clients') ? createUniqueArrItems(preRes.car.clients, 'id') : [] }
        };
        return res;
    };

    postInsurance = async(dataPost) => {
        const res = await this.getResource('/v3/partner/svbook/insurance', 'POST', { body: JSON.stringify(dataPost) });
        return res;
    };

    postOilInfo = async(dataOil) => {
        const res = await this.getResource('/v3/partner/svbook/oil', 'POST', { body: JSON.stringify(dataOil) });
        return res;
    };

    postOilMonthsInfo = async(dataOilMonths) => {
        const res = await this.getResource('/v3/partner/svbook/oil-months', 'POST', { body: JSON.stringify(dataOilMonths) });
        return res;
    };

    deleteOilMonth = async(dataOilMonth) => {
        const res = await this.getResource('/v3/partner/svbook/oil-months', 'DELETE', { body: JSON.stringify(dataOilMonth) });
        return res;
    };

    postCarTires = async(dataCarTires) => {
        const res = await this.getResource('/v3/partner/svbook/tires', 'POST', { body: JSON.stringify(dataCarTires) });
        return res;
    };

    getInitialFrame = async() => {
        const res = await this.getResource('/v3/partner/svbook/blocks', 'GET', {});
        return res;
    };

    setReasons = async(reasons) => {
        const res = await this.getResource('/v3/partner/svbook/reasons', 'POST', { body: JSON.stringify(reasons) });
        return res;
    };

    setInterests = async(interests) => {
        const res = await this.getResource('/v3/partner/svbook/interests', 'POST', { body: JSON.stringify(interests) });
        return res;
    };

    deleteInterests = async(interestsBlockDelete) => {
        const res = await this.getResource('/v3/partner/svbook/interestsUnset', 'DELETE', { body: JSON.stringify(interestsBlockDelete) });
        return res;
    };

    setTiresClientPreferences = async(dataCarTiresPreferences) => {
        const res = await this.getResource('/v3/partner/svbook/tires-preferences', 'POST', { body: JSON.stringify(dataCarTiresPreferences) });
        return res;
    };

    getTiresCatalogs = async() => {
        const resultFetch = await this.getResource('/v4/partner/cars/seasons-periods/with-times', 'GET', {});
        return resultFetch;
    };

    // Получение страховок. Без  приходят все, с id - конкретная

    getInsurance = async(id) => {
        const result = await this.getResource(`/v3/references/company-insurance/${id || ''}`, 'GET', {});
        return result;
    };

    createInsurance = async(data) => {
        const result = await this.getResource('/v3/references/company-insurance', 'POST', { body: JSON.stringify(data) });
        return result;
    };

    updateInsurance = async(data) => {
        const result = await this.getResource(`/v3/references/company-insurance/${data.id}`, 'PUT', { body: JSON.stringify(data) });
        return result;
    }

    delInsurance = async(id) => {
        const result = await this.getResource(`/v3/references/company-insurance/${id}`, 'DELETE', {});
        return result;
    }

    fetchSeasonsTires = async() => {
        const res = await this.getResource('/v3/partner/svbook/seasons', 'GET', {});
        return res;
    };

    fetchSeasonPeriodsTires = async() => {
        const res = await this.getResource('/v3/partner/svbook/season-periods', 'GET', {});
        return res;
    };

    postSeasonsPeriodTires = async(newSeasonsPeriod) => {
        const res = await this.getResource('/v3/partner/svbook/season-periods', 'POST', { body: JSON.stringify(newSeasonsPeriod) });
        return res;
    };

    deleteSeasonsPeriodTires = async(id) => {
        const res = await this.getResource(`/v3/partner/svbook/season-periods/${id}/delete`, 'DELETE');
        return res;
    };

    getSeasonPeriodTimesTires = async() => {
        const res = await this.getResource('/v4/partner/cars/seasons-periods/times', 'GET', {});
        return res;
    };

    postSeasonsPeriodTimesTires = async(newSeasonsPeriod) => {
        const res = await this.getResource('/v4/partner/cars/seasons-periods/times', 'POST', { body: JSON.stringify(newSeasonsPeriod) });
        return res;
    };

    deleteSeasonsPeriodTimesTires = async(id) => {
        const res = await this.getResource(`/v4/partner/cars/seasons-periods/times/${id}`, 'DELETE');
        return res;
    };

    getPackets = async() => {
        const res = await this.getResource('/v3/partner/svbook/packets', 'GET', {});
        return res;
    };

    setSeasonsTires = async(newSeason) => {
        const res = await this.getResource('/v3/partner/svbook/change-season', 'POST', { body: JSON.stringify(newSeason) });
        return res;
    };

    deleteSeasonsTires = async(id) => {
        const res = await this.getResource(`/v3/partner/svbook/seasons/${id}/delete`, 'DELETE');
        return res;
    };

    setSecretaryDoNotCall = async(doNotCallClient) => {
        const res = await this.getResource('/v3/partner/svbook/set-secretary-do-not-call', 'POST', { body: JSON.stringify(doNotCallClient) });
        return res;
    };

    getSearchResult = async(strSearch) => {
        const res = await this.getResource(`/v3/partner/svbook/search?${httpBuildQuery(strSearch)}`, 'GET');
        return res;
    };

    setCarImages = async(data, carId) => {
        const res = await this.getResource(`/v3/partner/clients/cars/${carId}/images/bulk-store`, 'POST', { body: data }, this._apiBase, true);
        return res;
    };

    fetchClientCars = async(id) => {
        const res = await this.getResource(`/v4/partner/clients/${id}/cars`, 'GET');
        return res;
    };

    fetchClients = async() => {
        const res = await this.getResource('/v3/partner/clients', 'GET');
        return res;
    };

    updateCarImage = async(data, carId, id) => {
        const res = await this.getResource(`/v3/partner/clients/cars/${carId}/images/${id}`, 'PUT', { body: JSON.stringify(data) });
        return res;
    };

    deleteCarImage = async(carId, id) => {
        const res = await this.getResource(`/v3/partner/clients/cars/${carId}/images/${id}`, 'DELETE');
        return res;
    };

    storeCarImage = async(data, carId, query) => {
        const res = await this.getResource(`/v3/partner/clients/cars/${carId}/images?` + httpBuildQuery(query), 'POST', { body: data }, this._apiBase, true);
        return res;
    };

    authUser = async(data) => {
        const res = await this.getResource('/partner/login', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    passwordRestore = async(data) => {
        const res = await this.getResource('/partner/password/restore', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    passwordConfirm = async(data) => {
        const res = await this.getResource('/partner/password/confirm', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    fetchCarMileage = async(carId) => {
        const res = await this.getResource(`/v4/partner/clients/cars/${carId}/mileages`, 'GET');
        return res;
    };

    fetchLogoutApi = async() => {
        const res = await this.getResource('/partner/logout', 'GET', {});
        return res;
    };

    fetchOwnersCompany = async() => {
        const res = await this.getResource('/v4/partner/owners', 'GET', {});
        return res;
    };

    deleteOwnerCompany = async(id) => {
        const res = await this.getResource(`/v4/partner/owners/${id}/delete`, 'DELETE');
        return res;
    };

    createOwnerCompany = async(data) => {
        const res = await this.getResource('/v4/partner/owners/create', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    updateOwnerCompany = async(data) => {
        if (Object.hasOwnProperty.call(data, 'passwordNew')) {
            data.password_new = data.passwordNew;
            delete data.passwordNew;
        }
        const res = await this.getResource('/v4/partner/owners/update', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    fetchPartnerGroupRoles = async() => {
        const res = await this.getResource('/v4/partner/owners/roles', 'GET');
        return res;
    };

    fetchAddUpdateReason = async(data) => {
        const res = await this.getResource('/v4/partner/blocks-reasons/create', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    fetchReasons = async() => {
        const res = await this.getResource('/v4/partner/reasons', 'GET');
        return res;
    };

    deleteBlockReason = async(id) => {
        const res = await this.getResource(`/v4/partner/blocks-reasons/${id}/delete`, 'DELETE');
        return res;
    };

    delReason = async(id) => {
        const res = await this.getResource(`/v4/partner/reasons/${id}/delete`, 'DELETE');
        return res;
    };

    fetchReason = async(data) => {
        const res = await this.getResource('/v4/partner/reasons/post', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    fakeFetch = async(data) => {
        const res = await this.getFakeFetch(data);
        return res;
    };

    fetchCarCollectBlocks = async(data) => {
        const res = await this.getResource('/v4/partner/sheet-collect/car-collect-blocks', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    getFromOrdersVisitors = async(params) => {
        const res = await this.getResource(`/v3/partner/clients/from-orders?${httpBuildQuery(params)}`, 'GET');
        return res;
    };

    searchVisitorsWithNamePhone = async(params) => {
        const res = await this.getResource(`/v3/partner/clients/search-by-nick-phone-car?${httpBuildQuery(params)}`, 'GET');
        return res;
    };

    getVisitor = async(id) => {
        const res = await this.getResource(`/v3/partner/clients/${id}`, 'GET');
        return res;
    };

    getVisitorAvatar = async(id) => {
        const res = await this.getResource(`/v3/partner/clients/${id}/avatar`, 'GET');
        return res;
    };

    // для изменения клиентских полей и company_nick
    putVisitor = async(id, data) => {
        const res = await this.getResource(`/v3/partner/clients/${id}`, 'PUT', { body: JSON.stringify(data) });
        return res;
    };

    getStatuses = async(carId, clientId) => {
        const res = await this.getResource(`/v4/partner/car-clients/statuses?${httpBuildQuery({ carId: carId, clientId: clientId })}`, 'GET');
        return res;
    };

    storingStatuses = async(data) => {
        const res = await this.getResource('/v4/partner/car-clients/statuses', 'PUT', { body: JSON.stringify(data) });
        return res;
    };

    getConfigStatuses = async() => {
        const res = await this.getResource('/v4/partner/car-clients/statuses/config', 'GET');
        res.sort(function(a, b) {
            if (a.sort > b.sort) {
                return 1;
            }
            if (a.sort < b.sort) {
                return -1;
            }
            return 0;
        });
        return res;
    };

    activateClient = async(idClient) => {
        const res = await this.getResource(`/v3/partner/clients/${idClient}/activate`, 'PUT');
        return res;
    };

    // добавление клиентов к машине
    createClient = async(data) => {
        const res = await this.getResource('/v4/partner/clients/cars/add-car-client', 'POST', { body: JSON.stringify({ ...data, origin: ORIGIN }) });
        return res;
    };

    deleteClient = async(carId, clientId) => {
        const res = await this.getResource(`/v4/partner/clients/cars/${carId}/${clientId}`, 'DELETE');
        return res;
    };

    // Получение услуг 1С
    fetchOneServices = async() => {
        const res = await this.getResource('/v4/partner/service-groups', 'GET');
        return res;
    };

    // Получение связанных услуг
    fetchActiveLinks = async(data) => {
        const res = await this.getResource('/v1/partner/service-groups/links' + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), 'GET');
        return res;
    };

    // Получение активности клиента (входы в приложение)
    fetchActivity = async(data) => {
        const res = await this.getResource(`/v3/partner/clients/${data}/activity` + (_.isEmpty(data) ? '' : ('?' + httpBuildQuery(data))), 'GET');
        return res;
    };

    // Получение услуг Ortus
    fetchOrtusCompanyServices = async(id) => {
        const res = await this.getResource(`/v4/partner/catalogues?${httpBuildQuery({ company_id: id, expand: 'thumbnails,services,childrenCatalogues,serviceTemplates,childrenCatalogues.serviceTemplates' })}`, 'GET');
        return res;
    };

    // Создание новой связи услуги Ortus с услугой 1С
    makeNewLink = async(data) => {
        const res = await this.getResource('/v1/partner/service-groups/links', 'POST', { body: JSON.stringify(data) });
        return res;
    };

    // Удаление связи услуг
    deleteActiveLink = async(data) => {
        const res = await this.getResource('/v1/partner/service-groups/links', 'DELETE', { body: JSON.stringify(data) });
        return res;
    };

    getSecretary = async(clientId, data, carId = null) => {
        let resPre;
        if (carId) {
            resPre = await this.getResource(`/v4/partner/clients/secretary/${clientId}?${httpBuildQuery({ ...data, car_id: carId })}`, 'GET');
        } else {
            resPre = await this.getResource(`/v4/partner/clients/secretary/${clientId}?${httpBuildQuery({ ...data })}`, 'GET');
        }

        const res = this._transformSecretaries(resPre.result);
        return { result: res, occupancy_rate_blocks: resPre.occupancy_rate_blocks };
    };

    postSecretary = async(clientId, data) => {
        const res = await this.getResource(`/v4/partner/clients/secretary/${clientId}`, 'PUT', { body: JSON.stringify(data) });
        const resultSecretary = this._transformSecretaries(res.result);
        const resultOccupancyRate = res.occupancy_rate_blocks;
        return { result: resultSecretary, occupancy_rate_blocks: resultOccupancyRate };
    };

    deleteSecretary = async(clientId, secretaryType, carId) => {
        const res = await this.getResource(`/v4/partner/clients/secretary/${clientId}/${secretaryType}`, 'DELETE', { body: JSON.stringify({ carId: carId }) });
        return res;
    };

    getPreReserveById = async(id, data = null) => {
        const res = await this.getResource(`/v4/partner/pre-reserves/${id}?${httpBuildQuery(data)}`, 'GET');
        return res;
    };

    inviteDatePreReserve = async(carId, clientId, packetId, averageDailyMileage = null) => {
        const data = {
            car_id: carId,
            client_id: clientId,
            packet_id: packetId
        };
        if (averageDailyMileage) {
            data.average_daily_mileage = averageDailyMileage;
        }
        const res = await this.getResource(`/v4/partner/pre-reserves/invite-date?${httpBuildQuery(data)}`, 'GET');
        return this._transformDateRange(res);
    };

    searchPreReserves = async(data) => {
        const dataFetch = {
            car_id: data.carId,
            client_id: data.clientId,
            statuses: ['ACTIVE', 'READY_TO_LINK'],
            not_expired_only: 0
        };
        const res = await this.getResource(`/v4/partner/pre-reserves?${httpBuildQuery(dataFetch)}`, 'GET');
        res.prereserves = this._preReserveUpdate(res.prereserves);
        return res;
    };

    _preReserveUpdate = (data) => {
        return data.map(item => ({
            ...item,
            scheduledFrom: item.scheduledTo,
            scheduledTo: item.completesAt
        }));
    }

    createPreReserve = async(data) => {
        const postData = {
            car_id: data.carId,
            client_id: data.clientId,
            packet_id: data.packetId,
            scheduled_to: data.scheduledFrom,
            completes_at: data.scheduledTo,
            reserve_or_order: data.reserveOrOrder,
            mode: data.mode,
            origin: ORIGIN
        };

        if (isNotEmpty(data.reasonChangeDataInvite) && data?.reasonChangeDataInvite?.scheduledFrom && data?.reasonChangeDataInvite?.scheduledTo && data?.reasonChangeDataInvite?.reason) {
            postData.reason_change_data = {
                scheduled_from: data?.reasonChangeDataInvite?.scheduledFrom,
                scheduled_to: data?.reasonChangeDataInvite?.scheduledTo,
                reason: data?.reasonChangeDataInvite?.reason
            };
        }

        if (data.skipHelloMessage) {
            postData.sendMessagePolicy = 'skipHelloMessage';
        }

        const res = await this.getResource('/v4/partner/pre-reserves', 'POST', { body: JSON.stringify(postData) });
        res.scheduledFrom = res.scheduledTo;
        res.scheduledTo = res.completesAt;
        return res;
    };

    updatePreReserve = async(id, data) => {
        const postData = {
            car_id: data.carId,
            client_id: data.clientId,
            scheduled_to: data.scheduledFrom,
            packet_id: data.packetId,
            completes_at: data.scheduledTo,
            reserve_or_order: data.reserveOrOrder,
            mode: data.mode,
            origin: ORIGIN
        };

        if (data.skipHelloMessage) {
            postData.sendMessagePolicy = 'skipHelloMessage';
        }

        const res = await this.getResource(`/v4/partner/pre-reserves/${id}`, 'PUT', { body: JSON.stringify(postData) });
        res.scheduledFrom = res.scheduledTo;
        res.scheduledTo = res.completesAt;
        return res;
    };

    deletePreReserve = async(id, data) => {
        const postData = {
            car_id: data.carId,
            client_id: data.clientId,
            packet_id: data.packetId,
            origin: ORIGIN
        };
        const res = await this.getResource(`/v4/partner/pre-reserves/${id}`, 'DELETE', { body: JSON.stringify(postData) });
        return res;
    };

    getDateFrom1C = async(carId) => {
        const res = await this.getResource(`/v4/partner/cars/${carId}/invites`, 'GET');
        return this._transformDataFrom1C(res);
    };

    getReportsCollectionSheets = async(data = null) => {
        let res;
        if (data !== null) {
            res = await this.getResource(`/v4/partner/reports/collection-sheets?${httpBuildQuery(data)}`, 'GET');
        } else {
            res = await this.getResource('/v4/partner/reports/collection-sheets', 'GET');
        }
        return this._transformCollectReport(res);
    };

    getCollectionSheetHistoryLogs = async(carId, clientId) => {
        const res = await this.getResource(`/v4/partner/reports/collection-sheet-history/${carId}/${clientId}`, 'GET');
        return { ...res, history_log: this._transformSheetHistoryReport(res.history_log), collection_sheet_details: this._transformCarClientCollectionSheet(res.collection_sheet_details) };
    };

    getCollectionSheets = async(ownerId, statusesPrefer, data) => {
        let res = await this.getResource(`/v4/partner/reports/${ownerId}/${statusesPrefer}?${httpBuildQuery(data)}`, 'GET');
        res = res.map((sheet) => {
            return this._transformCarClientCollectionSheet(sheet);
        });
        return res;
    };

    getReportYearProlongation = async(data) => {
        const res = await this.getResource(`/v4/partner/reports/year-prolongation?${httpBuildQuery(data)}`, 'GET');
        return res;
    };

    getProlongationInAction = async(data) => {
        const newDataPost = {
            from: data?.range?.from,
            to: data?.range?.to,
            chunks: data.chunks,
            packets: data.packets,
            union: +data.unionFlag,
            mode: data.mode
        };

        const res = await this.getResource(`/v4/partner/reports/prolongation/activity?${httpBuildQuery(newDataPost)}`, 'GET');

        const report = this._transformProlongationInAction(res.report, ['packet', 'data', 'total'], {
            dateFrom: data?.range?.from,
            dateTo: data?.range?.to
        });

        return {
            report: report,
            prolongationsCount: res.prolongation,
            initiationsCount: res.initiation
        };
    };

    updateOilsReferences = async(data) => {
        if (!data.id) {
            return;
        }

        const dataUpdate = {
            id: data.id
        };

        if (data.sort !== undefined) {
            dataUpdate.sort = data.sort;
        }

        if (data.title !== undefined) {
            dataUpdate.title = data.title;
        }

        const resultFetch = await this.getResource('/v4/partner/cars/oil/references', 'PUT', { body: JSON.stringify(dataUpdate) });
        return resultFetch;
    };

    getOilReferences = async(data = []) => {
        let resultFetch;
        if (data.length > 0) {
            resultFetch = await this.getResource(`/v4/partner/cars/oil/references?${httpBuildQuery({ references: data })}`, 'GET');
        } else {
            resultFetch = await this.getResource('/v4/partner/cars/oil/references', 'GET');
        }

        return resultFetch;
    };

    getOilReferencesNames = async() => {
        const resultFetch = await this.getResource('/v4/partner/cars/oil/references/names-references', 'GET');
        return resultFetch;
    };

    storeOilReferencesField = async(postData) => {
        const resultFetch = await this.getResource('/v4/partner/cars/oil/references', 'POST', { body: JSON.stringify(postData) });
        return resultFetch;
    };

    deleteOilReferencesField = async(id) => {
        const resultFetch = await this.getResource(`/v4/partner/cars/oil/references/${id}`, 'DELETE');
        return resultFetch;
    };

    getBlockProlongations = async(carId, clientId) => {
        const res = await this.getResource(`/v4/partner/prolongations/${carId}/${clientId}`, 'GET');
        return res;
    };

    getProlongationsInfo = async(id) => {
        const res = await this.getResource(`/v4/partner/collection-sheet/prolongations/${id}`, 'GET');
        return res;
    };

    updateBlockProlongations = async(data) => {
        const dataPost = {
            car_id: data.carId,
            client_id: data.clientId,
            is_activated: data.isActivated,
            car_collect_block_id: data.blockId
        };
        const res = await this.getResource('/v4/partner/prolongations', 'POST', { body: JSON.stringify(dataPost) });
        return res;
    };

    updateProlongation = async(id, data) => {
        const dataPost = {
            unique_collection_sheet_id: id,
            is_activated: data.isActivated,
            car_collect_block_id: data.blockId
        };
        const res = await this.getResource('/v4/partner/collection-sheet/prolongations', 'PUT', { body: JSON.stringify(dataPost) });
        return res;
    };

    getPreReservesReport = async(data) => {
        const res = await this.getResource(`/v4/partner/pre-reserves/report?${httpBuildQuery(data)}`, 'GET');
        return res;
    };

    getCompanyInfo = async(id, expand) => {
        const res = await this.getResource(`/v3/partner/companies/${id}?${httpBuildQuery(expand)}`, 'GET');
        return res;
    };

    getClientNotificationsSettings = async(clientId) => {
        const res = await this.getResource(`/v4/partner/notifications/settings/${clientId}`, 'GET');
        return res;
    };

    postClientNotificationsSettings = async(clientId, dataPost) => {
        const res = await this.getResource('/v4/partner/notifications/settings', 'POST', { body: JSON.stringify({ client_id: clientId, ...dataPost }) });
        return res;
    };

    activateProlongationExport = async(data) => {
        const res = await this.getResource(`/v4/partner/prolongation-statistic/export?${httpBuildQuery(data)}`, 'GET');
        return res;
    };

    getProlongationExport = async(refId) => {
        const res = await this.getResource(`/v4/partner/prolongation-statistic/export/${refId}`, 'GET', {}, this._apiBase, false, true);
        return res;
    };

    _mapperProlongationItem(item, chunk, packet) {
        const fields = ANGARA_PROLONGATION_FIELDS;
        return {
            chunks: chunk || item[fields.chunks],
            packet: packet,
            dateFrom: item[fields.dateFrom] || '1970-01-01',
            dateTo: item[fields.dateTo] || '1970-01-01',
            startPeriod: item[fields.startPeriod] || 0,
            comeIn: item[fields.comeIn] || 0,
            comeInPercent: item[fields.comeInPercent] || 0,
            cycleServices: item[fields.cycleServices] || 0,
            activation: item[fields.activation] || 0,
            activationPercent: item[fields.activationPercent] || 0,
            goOut: item[fields.goOut] || 0,
            registration: item[fields.registration] || 0,
            registrationPercent: item[fields.registrationPercent] || 0,
            cancellation: item[fields.cancellation] || 0,
            cancellationPercent: item[fields.cancellationPercent] || 0,
            competitors: item[fields.competitors] || 0,
            competitorsPercent: item[fields.competitorsPercent] || 0,
            asleep: item[fields.asleep] || 0,
            asleepPercent: item[fields.asleepPercent] || 0,
            sold: item[fields.sold] || 0,
            soldPercent: item[fields.soldPercent] || 0,
            fromInvitations: item[fields.fromInvitations] || 0,
            ofIntentions: item[fields.ofIntentions] || 0,
            fromExpectations: item[fields.fromExpectations] || 0,
            rest: item[fields.rest] || 0,
            expectation: item[fields.expectation] || 0,
            intentions: item[fields.intentions] || 0,
            invitations: item[fields.invitations] || 0,
            green: item[fields.green] || 0,
            yellow: item[fields.yellow] || 0,
            red: item[fields.red] || 0
        };
    }

    _transformProlongationInAction = (data, arrayFields, initialTotal) => {
        if (Array.isArray(data) && data.length > 0) {
            return data.map((item) => {
                if ((item[arrayFields[0]] === undefined) || (item[arrayFields[0]] === null) || !item[arrayFields[1]] || !(item[arrayFields[1]] && Array.isArray(item[arrayFields[1]]))) {
                    Sentry.captureException(new ContractReportError(
                        `Contract violation recorded: part of report retrieved from the backend do not conform to contract, report part: ${JSON.stringify(item)}`,
                        MESSAGES.contract_prolongation_activity_part_of_report_error
                    ));
                }
                return {
                    packet: item[arrayFields[0]],
                    data: Array.isArray(item[arrayFields[1]]) ? item[arrayFields[1]].map(stat => {
                        return this._mapperProlongationItem(stat, null, item[arrayFields[0]]);
                    }) : {},
                    total: isNotEmpty(item[arrayFields[2]])
                        ? this._mapperProlongationItem(item[arrayFields[2]], 'total', item[arrayFields[0]])
                        : this._mapperProlongationItem(initialTotal, 'total', item[arrayFields[0]])
                };
            });
        } else {
            Sentry.captureException(new ContractReportError(
                `Contract violation recorded: report retrieved from the backend do not conform to contract, report: ${JSON.stringify(data)}`,
                MESSAGES.contract_prolongation_activity_report_error
            ));
        }
        return [];
    }

    _transformCarClientCollectionSheet = (sheet) => {
        if (!sheet && !sheet.client.nick && !sheet.client.phone && !sheet.car && !sheet.car.model) {
            Sentry.captureException(new ContractReportError(
                `Contract violation recorded: user fields do not conform to contract, collection sheet: ${JSON.stringify(sheet)}`,
                MESSAGES.contract_collection_sheet_report_error
            ));
        }

        const newData = {
            clientName: sheet.client.nick || '',
            clientPhone: sheet.client.phone || '',
            carNumber: sheet.car ? sheet.car.license_plate || '' : '',
            carNumberRegion: sheet.car
                ? sheet.car.region_id && (typeof sheet.car.region_id !== 'string')
                    ? (sheet.car.region_id.length === 1)
                        ? '0' + String(sheet.car.region_id)
                        : '' + sheet.car.region_id
                    : sheet.car.region_id ? String(sheet.car.region_id) : '__'
                : '',
            carMark: sheet.car ? (sheet.car.model ? (sheet.car.model.mark ? sheet.car.model.mark.title : '') : '') : '',
            carModel: sheet.car ? (sheet.car.model ? sheet.car.model.title : '') : '',
            status: sheet.status ? sheet.status : '',
            carId: sheet.car_id,
            clientId: sheet.client_id,
            id: sheet.id
        };

        return newData;
    }

    _transformSheetHistoryReport = (logs) => {
        if (Array.isArray(logs) && logs.length > 0) {
            const logsNew = logs.map(log => {
                if (!log.id ||
                !log.created_at ||
                !log.sheet_field ||
                !log.sheet_field.title ||
                !log.value_field ||
                !log.sheet_field.car_collect_block_id ||
                !log.sheet_field_id ||
                !log.collection_sheet_id
                ) {
                    Sentry.captureException(new ContractReportError(
                        `Contract violation recorded: log fields do not conform to contract, log: ${JSON.stringify(log)}`,
                        MESSAGES.contract_history_log_report_error
                    ));
                }
                return {
                    id: log.id || 0,
                    createdAt: log.created_at || '',
                    creator: log.creator ? {
                        name: log.creator.name || '',
                        id: log.creator.id || 0,
                        phone: log.creator.phone || '',
                        role: log.creator.role || '',
                        roleLabel: log.creator.role2 ? log.creator.role2.roleLabel : ''
                    } : {
                        name: 'Эксперт другой компании',
                        id: 0,
                        phone: '',
                        role: '',
                        roleLabel: ''
                    },
                    field: log.sheet_field ? (log.sheet_field.title || '') : '',
                    value: log.value_field || '',
                    carCollectBlockId: log.sheet_field ? (log.sheet_field.car_collect_block_id || 0) : 0,
                    carCollectBlockTitle: log.sheet_field ? (log.sheet_field.car_collect_block ? log.sheet_field.car_collect_block.title : '') : '',
                    sheetFieldId: log.sheet_field_id || 0,
                    collectionSheetId: log.collection_sheet_id || 0

                };
            });

            return logsNew;
        }

        return [];
    }

    _transformCollectReport = (usersInfo) => {
        if (Array.isArray(usersInfo) && usersInfo.length > 0) {
            const usersNew = usersInfo.map(user => {
                if (!user.id || !user.name) {
                    Sentry.captureException(new ContractReportError(
                        `Contract violation recorded: user fields do not conform to contract, user: ${JSON.stringify(user)}`
                    ));
                }

                return {
                    id: user.id || 0,
                    name: user.name || '',
                    allSheets: user.allSheets || 0,
                    viewed: user.viewed || 0,
                    allTouched: user.allTouched || 0,
                    fully: user.fully || 0,
                    someCollected: user.someCollected || 0,
                    empty: user.empty || 0
                };
            });

            return usersNew;
        }

        return [];
    }

    _transformDataFrom1C = (arrRes) => {
        if (Array.isArray(arrRes)) {
            if (arrRes.length > 0) {
                return arrRes.map(item => {
                    if (item.invite) {
                        return { packetId: item.id, inviteDate: item.invite.invite_date, carId: item.invite.car_id };
                    } else {
                        return {};
                    }
                });
            } else {
                return arrRes;
            }
        } else {
            return [];
        }
    };

    _transformDateRange = (rangeTime) => {
        let newRange = rangeTime;
        if (rangeTime.scheduled_from && rangeTime.scheduled_to) {
            newRange = {
                scheduledFrom: rangeTime.scheduled_from,
                scheduledTo: rangeTime.scheduled_to
            };
        }

        return newRange;
    };

    _transformCarDetails = (body) => {
        // const item = body[0];
        const item = body;
        const newCar = {
            ...item,
            id: item.id,
            model: item.model ? item.model.title : '',
            mark: item.model ? item.model.mark ? item.model.mark.title : '' : '',
            region: item.region_id
                ? ((typeof item.region_id !== 'string')
                    ? ((String(item.region_id).length === 1) ? '0' + String(item.region_id) : String(item.region_id))
                    : item.region_id)
                : null,
            licensePlate: item.license_plate ? item.license_plate.split(' ').join('').slice(0, 6) : null,
            photo: item.files ? item.files : [],
            clients: item.clients ? item.clients.length > 0 ? item.clients : null : null,
            clientId: item.clientId || null,
            clientVisitor: item.clientVisitor
                ? item.clientVisitor
                : (item?.clients?.length > 0
                    ? (item.clientId
                        ? item.clients.find(client => client.id === item.clientId)
                        : (item.clients.find(client => client.statuses.find(status => status.label === STATUS_OWNER) !== undefined) || item.clients[0]))
                    : null),
            engine: item.engine ? item.engine : null,
            engineId: item.engine_id ? item.engine_id : null,
            chassisId: item.chassis_id ? item.chassis_id : null,
            engineCapacity: item.engine_capacity ? item.engine_capacity : null,
            engineDvs: item.engine ? item.engine.title : null,
            power: item.power ? item.power : 0,
            bodyVin: item.vin ? item.vin : null,
            colorBody: item.color ? item.color : null,
            occupancyRateBlocks: item.occupancy_rate_blocks ? (item.occupancy_rate_blocks.length > 0) ? item.occupancy_rate_blocks : null : null,
            insurance_deadline: item.insurance_deadline ? item.insurance_deadline : null,
            insuranceinfo: item.insuranceinfo ? item.insuranceinfo : null,
            has_casco: item.insuranceinfo ? (item.insuranceinfo.has_casco ? item.insuranceinfo.has_casco : false) : false,
            cost_casco: item.insuranceinfo ? (item.insuranceinfo.cost_casco ? item.insuranceinfo.cost_casco : 0) : 0,
            date_end_driver_license: item.insuranceinfo ? (item.insuranceinfo.date_end_driver_license ? item.insuranceinfo.date_end_driver_license : null) : null,
            date_end_casco: item.insuranceinfo ? (item.insuranceinfo.date_end_casco ? item.insuranceinfo.date_end_casco : null) : null,
            company_osago_id: item.insuranceinfo ? (item.insuranceinfo.company_osago_id ? item.insuranceinfo.company_osago_id : null) : null,
            company_casco_id: item.insuranceinfo ? (item.insuranceinfo.company_casco_id ? item.insuranceinfo.company_casco_id : null) : null,
            is_for_time: item.car_oil ? (item.car_oil.is_for_time ? item.car_oil.is_for_time : null) : null,
            is_last_mileage: +item?.car_oil?.is_last_mileage || 0,
            oil_mileage: item?.car_oil?.oil_mileage || null,
            mileage_next_change: item.car_oil ? (item.car_oil.mileage_next_change ? item.car_oil.mileage_next_change : null) : null,
            car_change_oil_months: item.car_change_oil_months ? (item.car_change_oil_months.length === 0 ? null : item.car_change_oil_months) : null,
            mark_motor_oil_field_ref_id: item.car_oil ? item.car_oil.mark_motor_oil_field_ref_id : null,
            viscosity_oil_field_ref_id: item.car_oil ? item.car_oil.viscosity_oil_field_ref_id : null,
            type_oil_field_ref_id: item.car_oil ? item.car_oil.type_oil_field_ref_id : null,
            expendable_materials_oil_field_ref_id: item.car_oil ? item.car_oil.expendable_materials_oil_field_ref_id : null,
            washing_oil_field_ref_id: item.car_oil ? item.car_oil.washing_oil_field_ref_id : null,
            protection_oil_field_ref_id: item.car_oil ? item.car_oil.protection_oil_field_ref_id : null,
            is_re_shoe: item.car_tires ? (item.car_tires.is_re_shoe ? item.car_tires.is_re_shoe : null) : null,
            is_do_wheel_alignment: item.car_tires ? (item.car_tires.is_do_wheel_alignment ? item.car_tires.is_do_wheel_alignment : null) : null,
            car_client_seasonal_preference: item.car_client_seasonal_preference ? ((item.car_client_seasonal_preference.length > 0) ? item.car_client_seasonal_preference : null) : null,
            car_selected_interests: item.car_selected_interests ? ((item.car_selected_interests.length > 0) ? item.car_selected_interests : null) : null,
            car_block_reasons: item.car_block_reasons ? item.car_block_reasons : null,
            secretary_call_days: item.secretary_call_days ? ((item.secretary_call_days.length > 0) ? item.secretary_call_days : null) : null,
            reserve_or_order: item.reserve_or_order ? item.reserve_or_order : null,
            average_daily_mileage: item.average_daily_mileage || null
        };
        return newCar;
    };

    _transformSecretaries = ({ connections, secretaries, secretary }) => {
        if (secretary) {
            if (Array.isArray(secretary) && (!secretary[0].message)) {
                secretaries = secretary;
            } else {
                secretaries = [secretary];
            }
        }

        const secretaryNew = secretaries && (secretaries.length > 0) ? secretaries.map((item) => {
            if (!item) {
                return {
                    ...item,
                    dayWeek: dayWeekDefault,
                    dayWeekend: dayWeekendDefault,
                    startTime: '10:00:00',
                    endTime: '18:00:00'
                };
            }

            const dayWeek = [
                { value: 'Пн', day: 'monday', isChecked: !!item.monday },
                { value: 'Вт', day: 'tuesday', isChecked: !!item.tuesday },
                { value: 'Ср', day: 'wednesday', isChecked: !!item.wednesday },
                { value: 'Чт', day: 'thursday', isChecked: !!item.thursday },
                { value: 'Пт', day: 'friday', isChecked: !!item.friday }
            ];

            const dayWeekend = [
                { value: 'Сб', day: 'saturday', isChecked: !!item.saturday },
                { value: 'Вс', day: 'sunday', isChecked: !!item.sunday }
            ];

            return {
                ...item,
                dayWeek: dayWeek,
                dayWeekend: dayWeekend,
                startTime: Object.hasOwnProperty.call(item, 'time_from') ? item.time_from : '10:00:00',
                endTime: Object.hasOwnProperty.call(item, 'time_to') ? item.time_to : '18:00:00'
            };
        }) : [{
            id: 'initialDayTimeBoxWrapper',
            dayWeek: dayWeekDefault,
            dayWeekend: dayWeekendDefault,
            startTime: '10:00:00',
            endTime: '18:00:00'
        }];

        const result = { secretaries: secretaryNew };

        if (secretary) {
            result.secretary = secretaryNew[0];
        }

        if (connections) {
            result.connections = connections;
        }

        return result;
    }
}
