import React from 'react';

import style from './CardLeft.module.scss';
import styleList from '../ListInspectCollect/ListInspectCollect.module.scss';
import carEye from '../../assets/car-eye.svg';

const CardLeft = () => {
    // const styleActive = `${styleList.cardItem} ${style.listCollectionActive} ${style.itemHover}`;
    const styleNotActive = `${styleList.cardItem} ${style.itemHover}`;

    const clearName = 'Семенов Григорий Семенович';

    const markModel = 'Nissan Patrol (к435аа/125)';
    const name = clearName.slice(0, 22) + '...';

    return (
        <div className={styleNotActive}>
            <div className={style.cardHeaderLeft}>
                <div className={style.pictures}>
                    <img src={carEye} alt='Auto_name' />
                </div>

            </div>
            <div className={style.cardBodyLeft}>
                <div className={style.cardP}>
                    <p className={style.textBlack}>{ markModel }</p>
                    <p className={style.textGreen}>{ name }</p>
                    <p className={style.textGreen}>+7 (914) 666-44-44</p>
                </div>
            </div>
            <div className={style.arrowTop}> </div>
        </div>
    );
};

export default CardLeft;
