import React from 'react';
import style from './DeleteButtonIcon.module.scss';
import PropTypes from 'prop-types';

const DeleteButtonIcon = ({ handleDeleteSelectedValue }) => {
    return (
        <button className={style.iconDel} onClick={handleDeleteSelectedValue} />
    );
};

DeleteButtonIcon.propTypes = {
    handleDeleteSelectedValue: PropTypes.func
};

export default DeleteButtonIcon;
