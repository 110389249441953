import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import style from './CheckBoxWithLabel.module.scss';
import cb from '../CheckBoxes/assets/checked.svg';

const CheckBoxReasons = ({ textLabel, handleChecked, stateChecked, blockInterestReasonId }) => {
    const [isChecked, setIsChecked] = useState(false);
    const checked = useMemo(() => (<img src={cb} alt='error icon' />), []);

    const [styleCk, setStyleCk] = useState(isChecked ? checked : '');

    useEffect(() => {
        setIsChecked(stateChecked && stateChecked);
    }, [stateChecked]);

    useEffect(() => {
        setStyleCk(isChecked ? checked : '');
    }, [isChecked, checked]);

    const handleClick = (e) => {
        handleChecked(e.currentTarget.id, !isChecked);
        setIsChecked((state) => !state);
    };

    return (
        <label className={style.labelCheckBox}>
            <div className={style.checkBox} id={`reason${blockInterestReasonId}`} onClick={handleClick}>{ styleCk }</div>
            { textLabel }
        </label>
    );
};

CheckBoxReasons.defaultProps = {
    textLabel: 'Выбрать'
};

CheckBoxReasons.propTypes = {
    textLabel: PropTypes.string,
    stateChecked: PropTypes.bool,
    handleChecked: PropTypes.func,
    blockInterestReasonId: PropTypes.number
};

export default CheckBoxReasons;
