import {
    typeSetMileageCollect,
    typeUpdateMileageCollectSuccess,
    typeUpdateMileageCollectError,
    typeSetIsKmMileage,
    typeCarOccupancyRateUpdate,
    typeSetMileageAt
} from '../actionsTypes';
import { MILES_IN_KILOMETERS } from '../utils/constants';

// ------------------------------------
// Constants
// ------------------------------------
const SET_MILEAGE_COLLECT = typeSetMileageCollect;
const UPDATE_MILEAGE_COLLECT_SUCCESS = typeUpdateMileageCollectSuccess;
const UPDATE_MILEAGE_COLLECT_ERROR = typeUpdateMileageCollectError;
const SET_IS_KM_MILEAGE = typeSetIsKmMileage;

// ------------------------------------
// Actions
// ------------------------------------

export const setMileage = (mileage) => {
    return {
        type: SET_MILEAGE_COLLECT,
        payload: mileage
    };
};

export const setKmMileage = (isKm) => {
    return {
        type: SET_IS_KM_MILEAGE,
        payload: isKm
    };
};

export const updateMileageSuccess = (isSuccess) => {
    return {
        type: UPDATE_MILEAGE_COLLECT_SUCCESS,
        payload: isSuccess
    };
};

export const updateMileageError = (isError) => {
    return {
        type: UPDATE_MILEAGE_COLLECT_ERROR,
        payload: isError
    };
};

export const carOccupancyRateUpdate = (updateOccupancyRate) => {
    return {
        type: typeCarOccupancyRateUpdate,
        payload: updateOccupancyRate
    };
};

const setMileageAt = (mileageAt) => {
    return {
        type: typeSetMileageAt,
        payload: mileageAt
    };
};

export const postMileage = (carId, mileage, clientId) => (dispatch, getState, { apiService }) => {
    dispatch(updateMileageSuccess(true));
    dispatch(updateMileageError(false));
    return clientId && apiService.updateSVBook2({ id: carId, mileage: mileage, client_id: clientId })
        .then((res) => {
            dispatch(setMileage({ mileage: res.car && res.car.mileage }));
            res.car && dispatch(setMileageAt(res.car));
            dispatch(carOccupancyRateUpdate(res.occupancy_rate_blocks));
            dispatch(updateMileageSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(updateMileageSuccess(false));
            dispatch(updateMileageError(true));
            return err;
        });
};

export const actions = {
    postMileage,
    setMileage,
    updateMileageError,
    setKmMileage
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {

    [SET_MILEAGE_COLLECT]: (state, action) => {
        const mileage = action.payload.mileage;

        return ({
            ...state,
            mileage: mileage,
            isKm: true
        });
    },

    [SET_IS_KM_MILEAGE]: (state, action) => {
        const mileage = state.mileage;

        if (action.payload === true && state.isKm === false) {
            return ({
                ...state,
                mileage: Math.round(mileage / MILES_IN_KILOMETERS),
                isKm: true
            });
        } if (action.payload === false && state.isKm === true) {
            return ({
                ...state,
                mileage: Math.round(mileage * MILES_IN_KILOMETERS),
                isKm: false
            });
        } else {
            return ({
                ...state
            });
        }
    },

    [UPDATE_MILEAGE_COLLECT_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isUpdateMileageSuccess: action.payload
        });
    },

    [UPDATE_MILEAGE_COLLECT_ERROR]: (state, action) => {
        return ({
            ...state,
            isUpdateMileageError: action.payload
        });
    }

};

const carReducer = (state, action) => {
    if (state === undefined) {
        return {
            mileage: 0,
            isUpdateMileageSuccess: false,
            isUpdateMileageError: false,
            isKm: true
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.car, action) : state.car;
};

export default carReducer;
