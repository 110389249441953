// this is ids car_collect_blocks table from API ------->
export const ALL_SHEET_COLLECT = 1;
export const ANCHORS = 2;
export const OSAGO = 3;
export const CASCO = 4;
export const CHANGING_ENGINE_OIL = 5;
export const TIRE_SERVICE = 6;
export const TIRE_STORAGE = 7;
export const SECRETARY = 8;
export const TIME_FOR_CALL = 9;
export const CLIENT_SERVICE = 10;
export const MILEAGE = 11;
export const STATUSES = 12;
// <-------labels
export const WRAP_CAR_LABEL = 'sheetCollect';
export const SERVICE_CAR_LABEL = 'clientService';
export const TIME_CALL_TO_CLIENT_LABEL = 'timeForCall';
export const MILEAGE_BLOCK_LABEL = 'mileage';
export const STATUSES_BLOCK_LABEL = 'statuses';
export const SECRETARY_BLOCK_LABEL = 'secretary';
export const NOT_ALLOW_LABELS_SETTINGS = [WRAP_CAR_LABEL, SERVICE_CAR_LABEL, TIME_CALL_TO_CLIENT_LABEL, MILEAGE_BLOCK_LABEL, STATUSES_BLOCK_LABEL];

// <-------this is ids car_collect_blocks table from API

export const FULLY = 'fully';

export const BLOCKS_LABELS = {
    tireService: 'tireService',
    tireStorage: 'tireStorage',
    changingEngineOil: 'changingEngineOil',
    anchors: 'Anchors',
    osago: 'osago',
    casco: 'casco',
    mileage: 'mileage',
    statuses: 'statuses',
    secretary: 'secretary'
};
