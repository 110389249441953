import React from 'react';
import SvBasicImg from 'components/SvBasicImg';
import SvBasicInfoCard from 'components/SvBasicInfoCard';

import style from './SvCarBasicInfo.module.scss';

const SvCarBasicInfo = () => {
    return (
        <div className={style.carBasicInfo}>
            <div className={style.carBasicInfoRow}>
                <SvBasicImg />
                <SvBasicInfoCard />
            </div>
        </div>

    );
};

export default SvCarBasicInfo;
