import React from 'react';
import style from './OptionsWrapPopup.module.scss';
import PropTypes from 'prop-types';

const OptionsWrapPopup = ({ options, options2, handleClose, classesOptions = [] }) => {
    return (
        <>
            <div
                onClick={e => e.stopPropagation()}
                className={style.monthsSelectWrap + classesOptions.map(item => ' ' + style[item]).join('')}
            >
                <div className={style.options}>
                    {
                        options2
                            ? [
                                <div className={style.scrollY} key='OptionsWrapPopup_options'>
                                    { options }
                                </div>,
                                <div className={style.scrollY} key='OptionsWrapPopup_options2'>
                                    { options2 }
                                </div>
                            ]
                            : <div className={style.scrollY} key='OptionsWrapPopup_options_along'>{ options }</div>
                    }
                </div>
            </div>
            <div className={style.modalSelect} onClick={() => handleClose(true)} />
        </>
    );
};

OptionsWrapPopup.propTypes = {
    options: PropTypes.array,
    options2: PropTypes.array,
    handleClose: PropTypes.func,
    classesOptions: PropTypes.array
};

export default OptionsWrapPopup;
