import React, { useState } from 'react';
import style from './DateInput.module.scss';
import calendar from '../../assets/calendar.svg';
import blueCalendar from '../../assets/blueCalendar.svg';
import reload from '../../assets/reload_grey.svg';
import blueReload from '../../assets/reload_blue.svg';
import del from '../../assets/delete.svg';
import delRed from '../../assets/deleteRed.svg';
import PropTypes from 'prop-types';

const CustomInput = React.forwardRef(({
    value,
    textSpan,
    start,
    end,
    rangeDates,
    propId,
    textSpanAfter,
    withDelButton,
    onClick,
    handleReload,
    handleDelete,
    classes = []
}, ref) => {
    const [isBlue, setIsBlue] = useState(false);
    const icon = handleReload ? (isBlue ? blueReload : reload) : (isBlue ? blueCalendar : calendar);
    const iconDel = withDelButton ? (isBlue ? delRed : del) : (isBlue ? blueCalendar : calendar);
    if (rangeDates) {
        return (
            <button
                ref={ref}
                className={style.dateInput + ' ' + (rangeDates ? style.sizeMore : '') + classes.map(item => ' ' + style[item]).join('')}
            >
                <span className={style.span} id={propId} onClick={onClick}>{ start ? (new Date(start)).toLocaleString('RU-ru', { day: 'numeric', month: 'numeric', year: '2-digit' }) : '' }</span>
                { start ? <span className={style.paddingAround}>-</span> : '' }
                <span>{ end ? (new Date(end)).toLocaleString('RU-ru', { day: 'numeric', month: 'numeric', year: '2-digit' }) : '' }</span>
                <div
                    className={style.imgDateInput}
                    onClick={withDelButton ? handleDelete : (handleReload || onClick)}
                >
                    <img src={withDelButton ? iconDel : icon} alt='' />
                </div>
            </button>
        );
    }

    return (
        <button ref={ref} className={style.dateInput + classes.map(item => ' ' + style[item]).join('')}>
            <span className={style.span} id={propId} onClick={onClick}>{ textSpan } { value } { textSpanAfter }</span>
            <div
                className={style.imgDateInput}
                onClick={withDelButton ? handleDelete : (handleReload || onClick)}
                onMouseOver={() => setIsBlue(true)}
                onMouseLeave={() => setIsBlue(false)}
            >
                <img src={withDelButton ? iconDel : icon} alt='' />
            </div>
        </button>
    );
});

CustomInput.propTypes = {
    start: PropTypes.any,
    end: PropTypes.any,
    propId: PropTypes.any,
    rangeDates: PropTypes.bool,
    value: PropTypes.string,
    onClick: PropTypes.func,
    handleReload: PropTypes.func,
    handleDelete: PropTypes.func,
    withDelButton: PropTypes.bool,
    textSpan: PropTypes.string,
    textSpanAfter: PropTypes.string,
    classes: PropTypes.array
};

export default CustomInput;
