import React, { useEffect, useState } from 'react';
import style from './OwnerSettingsBody.module.scss';
import SelectBox from '../../../components/SelectField';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputElement from 'react-input-mask';
import { createNewOwnerData } from '../../../reducers/settings';
import MultiSelect from '../../../components/MultiSelect';

const NewUserFields = ({ createNewOwnerData, roles, newOwnerData, resourcesCompany }) => {
    const [valueSelect, setValueSelect] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [toggleResourceSelectLocal, setToggleResourceSelectLocal] = useState(true);

    useEffect(() => {
        (selectedFilter.length > 0)
            ? setSelectedResources(resourcesCompany.filter((item) => selectedFilter.includes(item.id)))
            : setSelectedResources([]);
    }, [resourcesCompany, selectedFilter]);

    useEffect(() => {
        if (newOwnerData.resources && newOwnerData.resources.length === 0) {
            setSelectedFilter([]);
            setSelectedResources([]);
        }
    }, [newOwnerData]);

    const handleChange = (value, isChecked) => {
        setSelectedFilter(s => {
            return isChecked
                ? (s.filter((item) => item === value).length > 0)
                    ? [...s]
                    : [...s, value]
                : [...s.filter((item) => item !== value)];
        });
    };

    const handleDefault = () => {
        setSelectedFilter([]);
        setSelectedResources([]);
        handleOnChange('resources', []);
    };

    const handleOnChange = (field, value) => {
        createNewOwnerData(field, value);
    };

    const handleUpdateFilter = () => {
        const arrayValue = selectedFilter.length > 0 ? selectedFilter.reduce((accum, item) => {
            const newItem = resourcesCompany && resourcesCompany.find(resource => resource.id === item);
            if (newItem && newItem.angara_id) {
                return [...accum, { id: newItem.angara_id && newItem.angara_id }];
            } else {
                return accum;
            }
        }, []) : [];

        handleOnChange('resources', arrayValue);
    };

    useEffect(() => {
        setValueSelect(newOwnerData.role.id);
    }, [newOwnerData]);

    return (
        <form className={style.addForm}>
            <label>  ФИО:
                <input className={style.client} type='text' placeholder='ФИО' value={newOwnerData.name} onChange={(e) => handleOnChange('name', e.target.value)} />
            </label>
            <label>  Телефон:
                <InputElement type='tel' className={style.phone} name='ownerPhones' value={newOwnerData.phone} placeholder='+7 (___)___-___-__' mask='+9 (999) 999-99-99' onChange={(e) => handleOnChange('phone', e.target.value)} />
            </label>
            <label>  email:
                <input className={style.phone} type='text' name='email' value={newOwnerData.email} placeholder='example@example.org' onChange={(e) => handleOnChange('email', e.target.value)} />
            </label>
            <label>  Роль:
                <SelectBox handleChangeSelect={(e) => handleOnChange('role', e)} options={roles} selectValueFather={valueSelect} create />
            </label>
            <div className={`${style.multiSelect} ${style.newUserFields}`}>  Посты/Подразделения:
                <MultiSelect
                    id='setResourcesNewOwner'
                    resources={resourcesCompany}
                    selectedResources={selectedResources}
                    handleChange={handleChange}
                    showAllResource={selectedFilter.length === 0}
                    handleDefault={handleDefault}
                    handleUpdateFilter={handleUpdateFilter}
                    toggleResourceSelect={toggleResourceSelectLocal}
                    setToggleResourceFilter={setToggleResourceSelectLocal}
                    classes={['marginInSettings', 'withoutShadow']}
                    withCheckBox
                />
            </div>
        </form>
    );
};

NewUserFields.propTypes = {
    createNewOwnerData: PropTypes.func,
    roles: PropTypes.array,
    newOwnerData: PropTypes.object,
    resourcesCompany: PropTypes.array
};

const mapStateToProps = ({ settings: { roles, newOwnerData, resourcesCompany } }) => {
    return {
        roles,
        newOwnerData,
        resourcesCompany
    };
};

export default connect(mapStateToProps, { createNewOwnerData })(NewUserFields);
