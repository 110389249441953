import CollectionSheetName from '../../../../../../components/CollectionSheetName';
import React from 'react';
import PropTypes from 'prop-types';

const CollectionSheetRow = ({ collectionSheet, withSheetLink }) => {
    return (
        <CollectionSheetName collectionSheetDetails={collectionSheet} withSheetLink={withSheetLink} />
    );
};

CollectionSheetRow.propTypes = {
    collectionSheet: PropTypes.object,
    withSheetLink: PropTypes.bool
};

export default CollectionSheetRow;
