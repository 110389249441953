import React from 'react';
import PropTypes from 'prop-types';
import style from '../Connection/Connection.module.scss';

class ServiceTemplateItem extends React.Component {
    render() {
        const template = this.props.template;
        const type = this.props.type;
        const isConnected = this.props.isConnected;
        const itemName = this.props.itemName;
        const activeTemplate = this.props.activeTemplate;
        const showDelete = this.props.showDelete;
        if (template) {
            switch (type) {
            case 'connected':
                return (
                    <div
                        key={template.id}
                        className={style.templateName}
                    >
                        <div
                            className={`${style.templateNameLabel} ${isConnected ? style.active : ''}`}
                        >
                            { template.name }
                        </div>
                        <div
                            className={`${style.dotted} ${isConnected ? style.active : ''}`}
                        />
                        <div
                            className={`${style.notGreenDiv} ${activeTemplate === template.id ? style.greenDiv : ''}`}
                        >
                            <div
                                onMouseEnter={() => this.props.showDeleteButton(template)}
                                onMouseLeave={() => this.props.hideDeleteButton(template)}
                                className={style.connectedServiceName}
                                title={itemName.id}
                            >{ itemName ? itemName.name : '' }
                                <div
                                    onClick={() => this.props.deleteLink(template, this.props.companyId)}
                                    className={`${showDelete === template.id ? style.deleteButton : ''}`}
                                />
                            </div>
                        </div>
                    </div>
                );
            case
                'notConnected'
                :
                return (
                    <div
                        key={template.id}
                        className={style.templateName}
                    >
                        <div
                            className={`${style.templateNameLabel} ${isConnected ? style.active : ''}`}
                        >
                            { template.name }
                        </div>
                        <div
                            className={`${style.dotted} ${isConnected ? style.active : ''}`}
                        />
                        <div
                            className={`${style.notGreenDiv} ${activeTemplate === template.id ? style.greenDiv : ''}`}
                        >
                            <div
                                className={`${style.blueButton} ${activeTemplate === template.id ? style.green : ''}`}
                                onClick={() => this.props.makeTemplateActive(template)}
                            />
                        </div>
                    </div>
                );
            default:
                return (
                    <div
                        className={style.templateName}
                    >
                        <div className={`${style.templateNameLabel} ${isConnected ? style.active : ''}`}>
                            { template.name }
                        </div>
                        <div className={`${style.dotted} ${isConnected ? style.active : ''}`} />
                        <div
                            key={template.name}
                            className={`${style.notGreenDiv} ${activeTemplate === template.id ? style.greenDiv : ''}`}
                        >
                            { isConnected ? (
                                <div
                                    onMouseEnter={() => this.props.showDeleteButton(template)}
                                    onMouseLeave={() => this.props.hideDeleteButton(template)}
                                    className={style.connectedServiceName}
                                    title={itemName.id}
                                >{ itemName ? itemName.name : '' }
                                    <div
                                        onClick={() => this.props.deleteLink(template, this.props.companyId)}
                                        className={`${showDelete === template.id ? style.deleteButton : ''}`}
                                    />
                                </div>
                            ) : (
                                <div
                                    className={`${style.blueButton} ${activeTemplate === template.id ? style.green : ''}`}
                                    onClick={() => this.props.makeTemplateActive(template)}
                                />
                            ) }
                        </div>
                    </div>
                );
            }
        } else return null;
    }
}

ServiceTemplateItem.propTypes = {
    makeTemplateActive: PropTypes.func,
    deleteLink: PropTypes.func,
    template: PropTypes.object,
    type: PropTypes.string,
    isConnected: PropTypes.object,
    itemName: PropTypes.object,
    activeTemplate: PropTypes.any,
    showDelete: PropTypes.any,
    showDeleteButton: PropTypes.func,
    hideDeleteButton: PropTypes.func,
    companyId: PropTypes.number
};

export default ServiceTemplateItem;
