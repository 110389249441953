import React from 'react';
import PropTypes from 'prop-types';

import style from './SectionWrap.module.scss';

const SectionWrap = ({ children }) => {
    return (
        <div className={style.blockWrap}>
            {
                children
            }
        </div>
    );
};

SectionWrap.propTypes = {
    children: PropTypes.node
};

export default SectionWrap;
