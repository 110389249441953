import { MESSAGES } from '../constants/errors';

function ContractReportError(message, name) {
    this.message = message || 'Ошибка, контракт полученных данных не соблюден';
    this.name = name || MESSAGES.contract_users_report_error;
    this.stack = (new Error()).stack;
}
ContractReportError.prototype = Object.create(Error.prototype);
ContractReportError.prototype.constructor = ContractReportError;

export default ContractReportError;
