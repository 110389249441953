import React, { useState, useEffect } from 'react';

import OilMonth from './OilMonth';
import style from '../../../SheetCollectBody.module.scss';
import style2 from './OilsChangeForTime.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// deprecated, used a new component OilChangeMonths

const OilsChangeForTime = ({ carDetailsInfo }) => {
    const [selectMonths, setSelectMonths] = useState(carDetailsInfo.car_change_oil_months);
    const [count, setCount] = useState(0);
    const [countMonth, setCountMonth] = useState(carDetailsInfo.car_change_oil_months ? carDetailsInfo.car_change_oil_months.length : 0);

    useEffect(() => {
        setSelectMonths(carDetailsInfo.car_change_oil_months);
        setCountMonth(carDetailsInfo.car_change_oil_months ? carDetailsInfo.car_change_oil_months.length : 0);
        setCount(carDetailsInfo.car_change_oil_months ? (carDetailsInfo.car_change_oil_months.length < 1 ? 1 : carDetailsInfo.car_change_oil_months.length) : 1);
    }, [carDetailsInfo]);

    const arrayMonths = new Set();
    for (let i = 0; i < count - countMonth; i++) {
        arrayMonths.add(<OilMonth key={1 + i} valueSelect={99} fieldName='month' />);
    }

    const allMonths = selectMonths ? selectMonths.map((item, i) => {
        return (<OilMonth key={1 + i} indexId={item.id} valueSelect={+item.month} fieldName='month' />);
    }) : arrayMonths;

    return (
        <>
            <div className={style2.howManyMonths}>
                <label htmlFor='oilCycleHowYear'>Количество раз в год</label>
                <select
                    className={style2.oilCycleHowYear}
                    onChange={(e) => setCount(e.target.value)}
                    id='oilCycleHowYear'
                    value={count}
                >
                    <option key={1} value={1}>1</option>
                    <option key={2} value={2}>2</option>
                    <option key={3} value={3}>3</option>
                    <option key={4} value={4}>4</option>
                </select>
            </div>

            <div className={style.lineMonths}>
                {
                    allMonths
                }
                {
                    allMonths.length > 0
                        ? arrayMonths
                        : ''
                }
            </div>
        </>
    );
};

OilsChangeForTime.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(OilsChangeForTime);
