import React from 'react';

import style from './RegNumberCarEnd.module.scss';
import PropTypes from 'prop-types';

const RegNumberCarEnd = ({ regNumberCarEnd }) => {
    return (
        <div className={style.carNumberIntroRus}>
            <div>{ regNumberCarEnd }</div>
            <div className={style.rus}>RUS</div>
        </div>
    );
};

RegNumberCarEnd.defaultProps = {
    regNumberCarEnd: '25'
};

RegNumberCarEnd.propTypes = {
    regNumberCarEnd: PropTypes.string.isRequired
};

export default RegNumberCarEnd;
