export const ANGARA_PROLONGATION_FIELDS = {
    chunks: 'chunks',
    dateFrom: 'dateFrom',
    dateTo: 'dateTo',
    startPeriod: 'startPeriod',
    comeIn: 'comeIn',
    comeInPercent: 'activation',
    cycleServices: 'cycleServices',
    cycleServicesPercent: 'cycleServicesPercent',
    activation: 'comeIn',
    activationPercent: 'activation',
    goOut: 'goOut',
    registration: 'registration',
    registrationPercent: 'registrationPercent',
    cancellation: 'cancellation',
    cancellationPercent: 'cancellationPercent',
    competitors: 'competitors',
    competitorsPercent: 'competitorsPercent',
    asleep: 'asleep',
    asleepPercent: 'asleepPercent',
    sold: 'sold',
    soldPercent: 'soldPercent',
    fromInvitations: 'fromInvitations',
    ofIntentions: 'ofIntentions',
    fromExpectations: 'fromExpectations',
    rest: 'rest',
    expectation: 'expectation',
    intentions: 'intentions',
    invitations: 'invitations',
    green: 'green',
    yellow: 'yellow',
    red: 'red'
};
