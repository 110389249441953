import React from 'react';
import './mileageHeader.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from '../BigHeadCollectBlock/BigHeadCollectBlock.module.scss';
import { spaceInNumber } from '../../utils';
import { format, isEqual, startOfDay } from 'date-fns';

const MileageHeader = ({ mileage, isKm, carDetailsInfo }) => {
    const currentMileage = mileage || (carDetailsInfo ? carDetailsInfo.mileage : '');
    const typeMileage = isKm ? ' км' : ' миль';
    return (
        <div className={style.lastMileage}>
            { currentMileage && carDetailsInfo && carDetailsInfo.mileage && carDetailsInfo.mileage_at && carDetailsInfo.mileage !== 0 ? (
                <>
                    ({ `${spaceInNumber(isKm ? currentMileage : Math.round(currentMileage / 1.609))} ${typeMileage} - ` }
                    { isEqual(startOfDay(new Date(carDetailsInfo.mileage_at)), startOfDay(new Date())) ? 'Сегодня' : format(new Date(carDetailsInfo.mileage_at), 'dd.MM.yyyy') })
                </>
            ) : (
                '(сбор пробега не производился)'
            ) }
        </div>
    );
};

MileageHeader.propTypes = {
    mileage: PropTypes.any,
    isKm: PropTypes.bool,
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ car: { mileage, isKm } }) => {
    return {
        mileage,
        isKm
    };
};
export default connect(mapStateToProps)(MileageHeader);
