import React from 'react';

import style from './SheetInspectColumnNames.module.scss';
import PropTypes from 'prop-types';

// делаем хэдер для таблицы
const SheetInspectColumnNames = ({ arrHeaders }) => {
    const hrs = arrHeaders.map((item, i) => <th key={i}>{ item }</th>);

    return (
        <tr className={style.trHead}>
            { hrs }
        </tr>
    );
};

SheetInspectColumnNames.defaultProps = {
    arrHeaders: ['Узел', 'Состояние', 'Рекомендации']
};

SheetInspectColumnNames.propTypes = {
    arrHeaders: PropTypes.array.isRequired
};

export default SheetInspectColumnNames;
