import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose, withApiService } from 'Hoc';
import { declOfNum } from 'utils';
import { fetchCarMileage, calculateMileageInMiles } from 'actions';
import Loader from 'components/Loader';
import AverageMileage from './AverageMileage';
import _ from 'lodash';
import moment from 'moment';
import { MILES_IN_KILOMETERS } from 'utils/constants';

import classes from './mileage.module.scss';

const UNITS = {
    kilometers: {
        label: 'kilometers',
        shortName: 'км',
        names: ['Километр', 'Километра', 'Километров']
    },
    miles: {
        label: 'miles',
        shortName: 'м',
        names: ['Миля', 'Мили', 'Миль']
    }
};
class Mileage extends Component {
    state = {
        mileageUnit: UNITS.kilometers, // kilometers, miles
        openedYears: {}
    };

    componentDidMount() {
        if ((!this.props.carMileage.carId || this.props.carMileage.carId !== this.props.match.params.id) && !this.props.fetchingCarMileage) {
            this.props.fetchCarMileage(this.props.match.params.id);
        }
    }

    handleSutUnitToKilometers = () => {
        if (this.state.mileageUnit.label !== UNITS.kilometers.label) {
            this.setState({ mileageUnit: UNITS.kilometers });
        }
    };

    handleSutUnitToMiles = () => {
        if (this.state.mileageUnit.label !== UNITS.miles.label) {
            if (!this.props.carMileage.inMiles) {
                this.props.calculateMileageInMiles();
            }

            this.setState({ mileageUnit: UNITS.miles });
        }
    };

    handleChangeOpenedYears(year) {
        // const openedYears = Object.assign({}, this.state.openedYears); // babel not wont by himself usage Object.assign polyfill
        const openedYears = { ...this.state.openedYears };

        if (openedYears[year]) {
            openedYears[year] = 0;
        } else {
            const element = document.getElementById('year' + year);
            if (element) {
                openedYears[year] = element.offsetHeight;
            }
        }

        this.setState({ openedYears: openedYears });
    }

    getTimeFromNowByDate(date) {
        const diffInDays = moment().diff(date, 'days');

        if (diffInDays === 0) {
            return '(сегодня)';
        }

        return `(${diffInDays} дн. назад)`;
    }

    render() {
        const { mileageUnit } = this.state;
        const { carDetailsInfo, fetchingCarMileage } = this.props;

        let carMileage, currentMileageValue;

        if (mileageUnit.label === UNITS.miles.label) {
            carMileage = this.props.carMileage.inMiles;
            currentMileageValue = parseInt(carDetailsInfo.mileage * MILES_IN_KILOMETERS, 10).toString();
        } else {
            carMileage = this.props.carMileage;
            currentMileageValue = carDetailsInfo.mileage || '0';
        }

        let averageMileage,
            averageDaysMileage,
            averageMonthsMileage,
            averageYearsMileage;

        if (_.some(carMileage.average)) {
            if (carMileage.average.days) {
                averageDaysMileage = (
                    <AverageMileage title='Дневной' value={`${carMileage.average.days} ${mileageUnit.shortName}`} />
                );
            }

            if (carMileage.average.months) {
                averageMonthsMileage = (
                    <AverageMileage title='Месячный' value={`${carMileage.average.months} ${mileageUnit.shortName}`} />
                );
            }

            if (carMileage.average.years) {
                averageYearsMileage = (
                    <AverageMileage title='Годовой' value={`${carMileage.average.years} ${mileageUnit.shortName}`} />
                );
            }

            averageMileage = (
                <div className={classes.block + ' ' + classes.averageMileage}>
                    <div className={classes.blockTitle}>Средний пробег</div>
                    <div className={classes.averageMileageValues}>
                        { averageDaysMileage }
                        { averageMonthsMileage }
                        { averageYearsMileage }
                    </div>
                </div>
            );
        }

        let lastMileage;

        if (parseInt(currentMileageValue, 10) || carDetailsInfo.mileage_at) {
            lastMileage = (
                <div className={classes.mileageCollectionDates}>
                    <div className={classes.block}>
                        <div>
                            <div className={classes.blockTitle}>Последний сбор пробега</div>
                            { carDetailsInfo.mileage_at
                                ? [
                                    <div className={classes.blockDate} key='date'>
                                        { moment(carDetailsInfo.mileage_at).format('DD.MM.YYYY') }
                                    </div>,
                                    <div className={classes.blockDays} key='days'>
                                        { this.getTimeFromNowByDate(carDetailsInfo.mileage_at) }
                                    </div>
                                ]
                                : null }
                        </div>
                        <div className={classes.lastMileageValue}>
                            <div className={classes.numbersContainer}>
                                <div className={classes.numbers}>
                                    { _.map((currentMileageValue + '').split(''), (number, key) => {
                                        return <div className={classes.number} key={key}>{ number }</div>;
                                    }) }
                                </div>
                            </div>
                            <div className={classes.unit}>
                                { declOfNum(currentMileageValue, mileageUnit.names) }
                            </div>
                        </div>
                    </div>
                    { /* <div className={classes.block + ' ' + classes.small}>
                     <div>
                     <div className={classes.blockTitle}>Последний сбор пробега</div>
                     <div className={classes.blockDate}>21.09.2019</div>
                     <div className={classes.blockDays}>(10 дн. назад)</div>
                     </div>
                     </div> */ }
                </div>
            );
        }

        let mileageHistory;

        if (carMileage.history) {
            mileageHistory = (
                <div className={classes.block}>
                    <div className={classes.blockTitle}>История сбора пробега</div>
                    { _.map(carMileage.history, (history, year) => {
                        return (
                            <div className={classes.mileageYear} key={year}>
                                <div
                                    className={classes.mileageHeader + (_.get(this.state.openedYears, year) ? ' ' + classes.opened : '')}
                                    onClick={() => this.handleChangeOpenedYears(year)}
                                >
                                    { year } г
                                </div>
                                <div className={classes.tableWrapper} style={{ height: _.get(this.state.openedYears, year, 0) + 'px' }}>
                                    <table id={'year' + year}>
                                        <thead>
                                            <tr>
                                                <th>Дата сбора</th>
                                                <th>Пробег</th>
                                                <th>Способ сбора</th>
                                                <th>Собрал</th>
                                                <th>Должность</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { _.map(history, (item, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{ moment(item.date).format('DD.MM.YYYY') }</td>
                                                        <td>{ item.value } { mileageUnit.shortName }</td>
                                                        { item.collection_way ? <td>{ item.collection_way }</td> : null }
                                                        { item.collector ? <td>{ item.collector }</td> : null }
                                                        { item.collector_position ? <td>{ item.collector_position }</td> : null }
                                                    </tr>
                                                );
                                            }) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            );
        }

        return (
            <div className={classes.mileage}>
                <div className={classes.header}>
                    <div className={classes.block + ' ' + classes.speedometerContainer}>
                        <div className={classes.speedometer + (mileageUnit.label === UNITS.miles.label ? ' ' + classes.miles : '')}>
                            <button
                                className={classes.button + ' ' + classes.left + (mileageUnit.label === UNITS.kilometers.label ? ' ' + classes.active : '')}
                                onClick={this.handleSutUnitToKilometers}
                            >
                                Километры
                            </button>
                            <button
                                className={classes.button + ' ' + classes.right + (mileageUnit.label === UNITS.miles.label ? ' ' + classes.active : '')}
                                onClick={this.handleSutUnitToMiles}
                            >
                                Мили
                            </button>
                        </div>
                    </div>
                    { lastMileage }
                </div>
                { averageMileage }
                { mileageHistory }
                <Loader active={fetchingCarMileage} classes={['light', 'loaderTop']} />
            </div>
        );
    }
}

Mileage.propTypes = {
    carMileage: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    fetchingCarMileage: PropTypes.bool.isRequired,
    calculateMileageInMiles: PropTypes.func.isRequired,
    fetchCarMileage: PropTypes.func.isRequired,
    carDetailsInfo: PropTypes.object.isRequired
};

const mapStateToProps = ({ carDetailsState: { carMileage, carDetailsInfo, fetchingCarMileage } }) => {
    return {
        carMileage,
        carDetailsInfo,
        fetchingCarMileage
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        fetchCarMileage: fetchCarMileage(apiService, dispatch),
        calculateMileageInMiles: () => dispatch(calculateMileageInMiles())
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Mileage);
