import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteMonthFromOil, fetchPostOilMonthsInfo } from '../../../../../../../actions';
import { CustomSelect } from '../../../../../../../components/Selects';
import BlueSpanLink from '../../../../../../../components/BlueSpanLink';
import { MONTHS_OBJ } from '../../../../../../../constants/Months';
import style from './OilChangeMonths.module.scss';

const OilChangeMonths = ({
    months,
    textBtnAdd,
    handleAddCloseMonthsSelect,
    handleSelectMonth,
    handleDeleteMonth,
    titleBlock,
    showAddNew,
    disabledItems,
    isValid
}) => {
    const emptySelect = (
        <CustomSelect
            options={MONTHS_OBJ}
            selectedItem={{ id: 0 }}
            handleSelectItems={handleSelectMonth}
            handleDeleteChoosingItem={() => handleAddCloseMonthsSelect(false)}
            key='MonthsSelectEmpty'
            disabledItems={disabledItems}
            disableTitle='Месяц'
            id='CustomSelect_month_oil_empty'
            withBtnDel
            isNotValid={!isValid && months?.length === 0}
        />
    );

    const monthsRender = months?.length > 0
        ? months.map((selectedItem) => {
            return (
                <CustomSelect
                    options={MONTHS_OBJ}
                    selectedItem={selectedItem}
                    handleSelectItems={handleSelectMonth}
                    handleDeleteChoosingItem={handleDeleteMonth}
                    key={'MonthsSelect' + selectedItem.id}
                    disableTitle='Месяц'
                    disabledItems={disabledItems}
                    id={`CustomSelect_month_oil_${selectedItem.id}`}
                    withBtnDel
                />);
        })
        : emptySelect;

    return (
        <div className={style.monthsWrap + (months?.length >= 4 ? ' ' + style.padBottom : '')}>
            {
                titleBlock && (
                    <div className={style.titleMonths}>
                        { titleBlock }
                    </div>
                )
            }
            <div className={style.monthSelected}>
                {
                    monthsRender
                }
                {
                    showAddNew && months?.length < 4
                        ? emptySelect
                        : ''
                }
            </div>
            <div className={style.paddingRight30}>
                {
                    months?.length < 4
                        ? (<BlueSpanLink textSpan={textBtnAdd} classes={['navLinks']} handleClick={() => handleAddCloseMonthsSelect(true)} />)
                        : ''
                }
            </div>
        </div>
    );
};

OilChangeMonths.propTypes = {
    months: PropTypes.array,
    textBtnAdd: PropTypes.string,
    handleAddCloseMonthsSelect: PropTypes.func,
    handleSelectMonth: PropTypes.func,
    handleDeleteMonth: PropTypes.func,
    titleBlock: PropTypes.string,
    showAddNew: PropTypes.bool,
    isValid: PropTypes.bool,
    disabledItems: PropTypes.array
};

const OilChangeMonthsWrap = ({ fetchPostOilMonthsInfo, titleBlock, carDetailsInfo, clientIdCollect, carIdCollect, deleteMonthFromOil, isValid }) => {
    const [isShowAddNew, setIsShowAddNew] = useState(false);

    useEffect(() => {
        setIsShowAddNew(false);
    }, [carDetailsInfo?.car_change_oil_months]);

    const handleSelectMonth = (data = {}, fieldName = 'month') => {
        const post = {
            car_id: carIdCollect,
            [fieldName]: +data.value
        };

        if (data.id > 0) {
            post.id = data.id;
        }

        fetchPostOilMonthsInfo(post, clientIdCollect);
    };

    const handleDeleteMonth = (data = {}) => {
        const post = {
            id: data.id,
            car_id: carIdCollect
        };

        data.id && deleteMonthFromOil(post, clientIdCollect);
    };

    return (
        <OilChangeMonths
            months={carDetailsInfo?.car_change_oil_months || []}
            disabledItems={carDetailsInfo?.car_change_oil_months && carDetailsInfo?.car_change_oil_months.length > 0 ? carDetailsInfo.car_change_oil_months.map(item => item.month) : []}
            handleAddCloseMonthsSelect={(bool) => setIsShowAddNew(bool)}
            textBtnAdd='Добавить месяц'
            titleBlock={titleBlock}
            handleSelectMonth={handleSelectMonth}
            handleDeleteMonth={handleDeleteMonth}
            showAddNew={isShowAddNew}
            isValid={isValid}
        />
    );
};

OilChangeMonthsWrap.propTypes = {
    fetchPostOilMonthsInfo: PropTypes.func,
    carDetailsInfo: PropTypes.object,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    titleBlock: PropTypes.string,
    isValid: PropTypes.bool,
    deleteMonthFromOil: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilMonthsUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilMonthsUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostOilMonthsInfo, deleteMonthFromOil })(OilChangeMonthsWrap);
