import React from 'react';
import { spaceInNumber } from '../../utils';
import PropTypes from 'prop-types';

import style from './CompletedCards.module.scss';

const CompletedCards = ({ layout, completed }) => {
    const classCompletedCard = (layout === 'CardLeft') ? `${style.aHref} ${style.textColorBlue}` : `${style.aHref} ${style.textColorViolet}`;

    return (
        <div className={style.completedCards}>
            <div className={style.filledListInspect}>
                <h3 className={classCompletedCard}>Заполненные ({ spaceInNumber(completed) })</h3>
            </div>
        </div>
    );
};

CompletedCards.defaultProps = {
    layout: 'CardLeft',
    completed: 0
};

CompletedCards.propTypes = {
    layout: PropTypes.string,
    completed: PropTypes.number
};

export default CompletedCards;
