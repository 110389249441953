import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import VisitorBody from './VisitorBody';
import VisitorHeader from './VisitorHeader';
import PropTypes from 'prop-types';
import { fetchVisitor, setVisitor, setVisitorId } from '../../reducers/visitors';

const Visitor = ({ id, visitorId, visitor, setVisitor, fetchVisitor, isVisitorSuccess, setVisitorId }) => {
    useEffect(() => {
        id && setVisitorId(id);
    }, [id, setVisitorId]);

    useEffect(() => {
        visitorId > 0 && fetchVisitor(visitorId);
        return () => setVisitor({});
    }, [visitorId, fetchVisitor, setVisitor]);

    const menu = [
        { linkHeader: '', titleLink: 'Общая информация' },
        { linkHeader: '/secretary', titleLink: 'Секретарь' },
        { linkHeader: '/service', titleLink: 'Сервис' },
        { linkHeader: '/garage', titleLink: 'Гараж' }
    ];

    return (
        <>
            <VisitorHeader navbarLinks={menu} mainLink='/visitors/' visitorId={visitorId} visitor={visitor} isVisitorSuccess={isVisitorSuccess} />
            <VisitorBody visitorId={visitorId} visitor={visitor} />
        </>
    );
};

Visitor.propTypes = {
    visitorId: PropTypes.number,
    visitor: PropTypes.object,
    setVisitor: PropTypes.func,
    fetchVisitor: PropTypes.func,
    isVisitorSuccess: PropTypes.bool,
    id: PropTypes.number,
    setVisitorId: PropTypes.func
};

const mapStateToProps = ({ visitors: { visitorId, visitor, isVisitorSuccess } }) => {
    return {
        visitorId,
        visitor,
        isVisitorSuccess
    };
};

export default connect(mapStateToProps, { fetchVisitor, setVisitor, setVisitorId })(Visitor);
