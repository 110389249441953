import React from 'react';
import AboutAutoText from 'components/MiddleHeader/AboutAutoText';
// import BlockCollectCardsContainer from 'components/BlockCollectCardsContainer/BlockCollectCardsContainer';
import style from './SheetCollectHeader.module.scss';
import InviteToOrtusButton from '../../../components/InviteToOrtusButton/InviteToOrtusButton';
import PropTypes from 'prop-types';

const SheetCollectHeader = ({ isOpenMinHeader }) => {
    return (
        <div className={style.sheetCollectHeader + (isOpenMinHeader ? ' ' + style.paddingBottom0 : '')}>
            <h1>Листы сбора информации</h1>
            <div className={style.columnContainer}>
                <AboutAutoText />
                <InviteToOrtusButton />
            </div>
            { /* <BlockCollectCardsContainer /> */ }
        </div>
    );
};

SheetCollectHeader.propTypes = {
    isOpenMinHeader: PropTypes.bool
};

export default SheetCollectHeader;
