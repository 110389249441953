import React from 'react';
import ReportsHeader from '../../../ReportsHeader';
import PairColumn from '../../../../../components/PairColumn';
import HistoryReportBodyWrap from './HistoryReportBodyWrap';
import PropTypes from 'prop-types';

const HistoryReport = ({ carId, clientId }) => {
    return (
        <PairColumn>
            <ReportsHeader textHeader='История изменений листов сбора информации' classes={['inReport']} />
            <HistoryReportBodyWrap carId={carId} clientId={clientId} />
        </PairColumn>
    );
};

HistoryReport.propTypes = {
    carId: PropTypes.number,
    clientId: PropTypes.number
};

export default HistoryReport;
