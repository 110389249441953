import React from 'react';
import InputElement from 'react-input-mask';
import style from '../../../routes/SheetCollect/SheetCollectBody/components/Anchors/OilFields/OilChangeType.module.scss';
import PropTypes from 'prop-types';

const InputPhoneWithStyle = ({ keyId, id, value, setValue, handlerUpdateValue, disabled, classes = [] }) => {
    return (
        <InputElement
            key={keyId}
            id={id}
            type='tel'
            className={style.inputGray + ' ' + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}
            name='ownerPhones'
            value={value}
            placeholder='+7 (___)___-__-__'
            mask='+7 (999) 999-99-99'
            onChange={(e) => setValue(e.target.value)}
            onBlur={handlerUpdateValue}
            disabled={disabled}
        />
    );
};

InputPhoneWithStyle.propTypes = {
    id: PropTypes.string,
    keyId: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    handlerUpdateValue: PropTypes.func,
    classes: PropTypes.array,
    disabled: PropTypes.bool
};

export default InputPhoneWithStyle;
