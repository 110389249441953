import {
    typeCarsLoaded,
    typeSelectedIdCarLoaded,
    typeTextSearchCarLoaded,
    typeFoundCarsLoaded,
    typeGetSvHeaderLoaded,
    typeGetCarDetailsLoaded,
    typeCarsFullyBlocksLoaded,
    typeCarIdCollectLoaded,
    typeFetchCarDetailsSuccess,
    typeCarCollectBlocksLoaded,
    typeGetFetchCollectBlocksError,
    typeCollectBlocksSuccess,
    typeCompaniesInsuranceLoaded,
    typeBlockInterestReasonLoaded,
    typeSetShowAnchors,
    typeSetShowSecretary,
    typeCarImageUpdated,
    typeCarImageDeleted,
    typeCarImagesAdded,
    typeChangeCarChangingImagesIds,
    typeChangeCarSettingImagesCount,
    typeCarDetailsUploaded,
    typeCarDetailsErrorUploaded,
    typeCarImageAdded,
    typeChangeSavingMainImageCarsIds,
    typeCarInsuranceDeadlineUpdate,
    typeCarInsuranceDeadlineUpdateError,
    typeCarInsuranceUpdate,
    typeCarInsuranceUpdateError,
    typeCarOccupancyRateUpdate,
    typeClearFoundCars,
    typeCarsSearchFetching,
    typeRequestCarMileage,
    typeReceiveCarMileage,
    typeCalculateMileageInMiles,
    typeResetCarMileage,
    typeCarInterestsUpdate,
    typeCarReasonsUpdate,
    typeCarReasonsUpdateError,
    typeCarInterestsUpdateError,
    typeCarOilUpdate,
    typeCarOilUpdateError,
    typeCarOilMonthsUpdate,
    typeCarOilMonthsUpdateError,
    typeCarTiresUpdate,
    typeCarTiresUpdateError,
    typeCarClientSeasonalPreferenceUpdate,
    typeCarClientSeasonalPreferenceUpdateError,
    typeResetCarId,
    typeResetCarCollectId,
    typeCarOilIsForTimeLoadSuccess,
    typeCarIsExactlyOilLastMileageSuccess,
    typeCarIsReShoeTiresSuccess,
    typeCarIsDoWheelAlignmentSuccess,
    typeCarPostInterestsSuccess,
    typeSearchCarsError,
    typeGetAllResources,
    typeSetAllResourcesError,
    typeSetAllResourcesLoading,
    typeToggleResourceFilter,
    typeSetReasonsToState,
    typeSetErrorReasons,
    typeReasonsSuccess,
    typeDeleteReasonFromBlock,
    typeDeleteErrorReasonFromBlock,
    typeDeleteReasonFromBlockSuccess,
    typeNewInterestReasonSuccess,
    typeSetErrorNewInterestReason,
    typeSetNewInterestReason,
    typeDeleteReason,
    typeDeleteErrorReason,
    typeDeleteReasonSuccess,
    typeSetNewReason,
    typeNewReasonSuccess,
    typeSetErrorNewReason,
    typeSetUpdateReason,
    typeUpdateBlockSuccess,
    typeErrorUpdateBlock,
    typeSetUpdateBlock,
    typeErrorSetFlagDoNotCallClient,
    typeChangeFlagDoNotCallClient,
    typeSetFlagDoNotCallClientSuccess,
    typeUpdateDataOilSuccess,
    typeClientIdCollectSet,
    typeCarInterestDelete,
    typeCarDetailsSuccess,
    typeCarDetailsError, typeUpdateDataOilFieldsSuccess
} from '../actionsTypes';

export const setReasonsToState = (reasons) => {
    return {
        type: typeSetReasonsToState,
        payload: reasons
    };
};

export const setErrorReasons = (isReasonsError) => {
    return {
        type: typeSetErrorReasons,
        payload: isReasonsError
    };
};

export const loadingReasons = (reasonsSuccess) => {
    return {
        type: typeReasonsSuccess,
        payload: reasonsSuccess
    };
};

const carsLoaded = (newCars) => {
    return {
        type: typeCarsLoaded,
        payload: newCars
    };
};

const selectedIdCarLoaded = (carId) => {
    return {
        type: typeSelectedIdCarLoaded,
        payload: carId
    };
};

const setTextCarsLoaded = (search) => {
    return {
        type: typeTextSearchCarLoaded,
        payload: search
    };
};

const clearFoundCars = () => {
    return {
        type: typeClearFoundCars
    };
};

const fetchingCarsSearch = (isLoading) => {
    return {
        type: typeCarsSearchFetching,
        payload: isLoading
    };
};

const saveSearchCarsError = (isError) => {
    return {
        type: typeSearchCarsError,
        payload: isError
    };
};

const foundCarsLoaded = (foundCars) => {
    return {
        type: typeFoundCarsLoaded,
        payload: foundCars
    };
};

const setSvHeaderLoaded = (svHeader) => {
    return {
        type: typeGetSvHeaderLoaded,
        payload: svHeader
    };
};

const carDetailsLoaded = (carDetailsInfo) => {
    return {
        type: typeGetCarDetailsLoaded,
        payload: carDetailsInfo
    };
};

const carDetailsSuccess = (isLoading) => {
    return {
        type: typeCarDetailsSuccess,
        payload: isLoading
    };
};

const carDetailsError = (isError) => {
    return {
        type: typeCarDetailsError,
        payload: isError
    };
};

const fetchCarServiceBook = (carId) => (dispatch, getState, { apiService }) => {
    dispatch(carDetailsSuccess(true));
    dispatch(carDetailsError(false));
    carId && apiService.getSVBook(carId)
        .then((data) => {
            dispatch(carDetailsLoaded(data));
            dispatch(carDetailsSuccess(false));
        })
        .catch((err) => {
            dispatch(carDetailsSuccess(false));
            dispatch(carDetailsError(true));
            return err;
        });
};

// not use now:
const carsFullyBlocksLoaded = (carsFully) => {
    return {
        type: typeCarsFullyBlocksLoaded,
        payload: carsFully
    };
};

const carIdCollectLoaded = (idSelectCar) => {
    return {
        type: typeCarIdCollectLoaded,
        payload: idSelectCar
    };
};

const clientIdCollectLoaded = (idSelectClient) => {
    return {
        type: typeClientIdCollectSet,
        payload: idSelectClient
    };
};

const loadingCarDetails = (isLoading) => {
    return {
        type: typeFetchCarDetailsSuccess,
        payload: isLoading
    };
};

const carCollectBlocksLoaded = (blocks) => {
    return {
        type: typeCarCollectBlocksLoaded,
        payload: blocks
    };
};

const fetchCollectBlocksErrorLoaded = (error) => {
    return {
        type: typeGetFetchCollectBlocksError,
        payload: error && true
    };
};

const collectBlocksSuccess = (isLoading) => {
    return {
        type: typeCollectBlocksSuccess,
        payload: isLoading
    };
};

const companiesInsuranceLoaded = (isLoading) => {
    return {
        type: typeCompaniesInsuranceLoaded,
        payload: isLoading
    };
};

const blockInterestReasonLoaded = (isLoading) => {
    return {
        type: typeBlockInterestReasonLoaded,
        payload: isLoading
    };
};

const setShowAnchors = (anchorsShow) => {
    return {
        type: typeSetShowAnchors,
        payload: anchorsShow
    };
};

const setShowSecretary = (secretaryShow) => {
    return {
        type: typeSetShowSecretary,
        payload: secretaryShow
    };
};

const fetchCollectBlocks = () => (dispatch, getState, { apiService }) => {
    apiService.getInitialFrame()
        .then((data) => {
            dispatch(carCollectBlocksLoaded(data));
            dispatch(companiesInsuranceLoaded(data.insurance));
            dispatch(blockInterestReasonLoaded(data.blockInterestReason));
        })
        .catch((err) => dispatch(fetchCollectBlocksErrorLoaded(err)));
};

const setCarImages = (apiService, dispatch) => (data, carId, imagesCount) => {
    dispatch(changeCarSettingImagesCount(imagesCount));
    return apiService.setCarImages(data, carId).then(res => {
        if (res && res.items) {
            return dispatch(addCarImages(res.items, imagesCount, carId));
        } else {
            return dispatch(changeCarSettingImagesCount(imagesCount, false));
        }
    });
};

const changeCarSettingImagesCount = (count, add = true) => {
    return {
        type: typeChangeCarSettingImagesCount,
        payload: { count, add }
    };
};

const updateCarImage = (apiService, dispatch) => (data, carId, id) => {
    dispatch(changeCarChangingImagesIds(id));
    return apiService.updateCarImage(data, carId, id).then(res => {
        if (res && res.item) {
            return dispatch(carImageUpdated(res.item));
        } else {
            return dispatch(changeCarChangingImagesIds(id));
        }
    });
};

const carImageUpdated = (res) => {
    return {
        type: typeCarImageUpdated,
        payload: res
    };
};

const deleteCarImage = (apiService, dispatch) => (carId, id) => {
    dispatch(changeCarChangingImagesIds(id));
    return apiService.deleteCarImage(carId, id).then(res => {
        if (res && res.message === 'Изображение удалено') {
            return dispatch(carImageDeleted(id));
        } else {
            return dispatch(changeCarChangingImagesIds(id));
        }
    });
};

const changeCarChangingImagesIds = (id) => {
    return {
        type: typeChangeCarChangingImagesIds,
        payload: id
    };
};

const carImageDeleted = (res) => {
    return {
        type: typeCarImageDeleted,
        payload: res
    };
};

const addCarImages = (images, count, carId) => {
    return {
        type: typeCarImagesAdded,
        payload: { images, count, carId }
    };
};

const carDetailsUploadToState = (carDetails) => {
    return {
        type: typeCarDetailsUploaded,
        payload: carDetails
    };
};

const carDetailsUploadError = (carDetailsUploadError) => {
    return {
        type: typeCarDetailsErrorUploaded,
        payload: carDetailsUploadError
    };
};

const searchCars = () => (dispatch, getState, { apiService }) => {
    dispatch(fetchingCarsSearch(true));
    if (getState().carsListState.searchCarsError) {
        dispatch(saveSearchCarsError(false));
    }
    return apiService.getSearchResult(getState().carsListState.textSearch)
        .then((data) => {
            dispatch(fetchingCarsSearch(false));
            dispatch(foundCarsLoaded(data));
            return data;
        })
        .catch((error) => {
            dispatch(fetchingCarsSearch(false));
            dispatch(saveSearchCarsError(true));
            return error;
        });
};

const carDetailsUpload = (carId, clientId, forSheetCollect = true) => (dispatch, getState, { apiService }) => {
    carId && dispatch(loadingCarDetails(true));
    return carId && apiService.getSVBook(carId, clientId, forSheetCollect)
        .then((data) => {
            dispatch(loadingCarDetails(false));
            return dispatch(carDetailsUploadToState(data));
        })
        .catch((err) => {
            dispatch(loadingCarDetails(false));
            return dispatch(carDetailsUploadError(err));
        });
};

const uniqueSheetCollectUpload = (sheetCollectId) => (dispatch, getState, { apiService }) => {
    sheetCollectId && dispatch(loadingCarDetails(true));
    return sheetCollectId && apiService.getUniqueCollectionSheet(sheetCollectId)
        .then((data) => {
            dispatch(loadingCarDetails(false));
            return dispatch(carDetailsUploadToState(data.car));
        })
        .catch((err) => {
            dispatch(loadingCarDetails(false));
            return dispatch(carDetailsUploadError(err));
        });
};

const saveCarImage = (apiService, dispatch) => (data, carId, query = {}) => {
    if (query.is_main) {
        dispatch(changeSavingMainImageCarsIds(carId));
    }
    return apiService.storeCarImage(data, carId, query).then(res => {
        if (res && res.item) {
            return dispatch(addCarImage(res.item, carId));
        } else {
            return query.is_main ? dispatch(changeSavingMainImageCarsIds(carId)) : null;
        }
    });
};

const addCarImage = (image, carId) => {
    return {
        type: typeCarImageAdded,
        payload: {
            image: image,
            carId: carId
        }
    };
};

const changeSavingMainImageCarsIds = (value) => {
    return {
        type: typeChangeSavingMainImageCarsIds,
        payload: value
    };
};

const carInsuranceDeadlineUpdate = (carInsuranceData) => {
    return {
        type: typeCarInsuranceDeadlineUpdate,
        payload: carInsuranceData
    };
};

const carInsuranceDeadlineUpdateError = (carInsuranceDeadlineUpdateError) => {
    return {
        type: typeCarInsuranceDeadlineUpdateError,
        payload: carInsuranceDeadlineUpdateError && true
    };
};

const carInsuranceUpdate = (carInsuranceNew) => {
    return {
        type: typeCarInsuranceUpdate,
        payload: carInsuranceNew
    };
};

const carInsuranceUpdateError = (carInsuranceUpdateError) => {
    return {
        type: typeCarInsuranceUpdateError,
        payload: carInsuranceUpdateError && true
    };
};

const carOccupancyRateUpdate = (updateOccupancyRate) => {
    return {
        type: typeCarOccupancyRateUpdate,
        payload: updateOccupancyRate
    };
};

const fetchPostCarInsuranceDeadline = (dataPost, clientId) => (dispatch, getState, { apiService }) => {
    return clientId && apiService.updateSVBook2({ ...dataPost, client_id: clientId })
        .then((data) => {
            dispatch(carInsuranceDeadlineUpdate(data.car));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        }).catch((err) => {
            dispatch(carInsuranceDeadlineUpdateError(err));
        });
};

const fetchPostCarInsurance = (dataInsurance, clientId) => (dispatch, getState, { apiService }) => {
    return clientId && apiService.postInsurance({ ...dataInsurance, client_id: clientId })
        .then((data) => {
            dispatch(carInsuranceUpdate(data.car_insurance));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        })
        .catch((err) => {
            dispatch(carInsuranceUpdateError(err));
        });
};

const carInterestsUpdate = (carInterests) => {
    return {
        type: typeCarInterestsUpdate,
        payload: carInterests
    };
};

const carInterestsUpdateError = (errorInterests) => {
    return {
        type: typeCarInterestsUpdateError,
        payload: errorInterests && true
    };
};

const carPostCarInterestsLoad = (interestsLoad) => {
    return {
        type: typeCarPostInterestsSuccess,
        payload: interestsLoad
    };
};

const fetchPostCarInterests = (dataInterests, blockId, clientId) => (dispatch, getState, { apiService }) => {
    dispatch(carPostCarInterestsLoad({ status: true, blockId: blockId }));
    return clientId && apiService.setInterests({ ...dataInterests, client_id: clientId })
        .then((data) => {
            dispatch(carInterestsUpdate(data.interests));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        })
        .then(() => dispatch(carPostCarInterestsLoad({ status: false, blockId: blockId })))
        .catch((err) => {
            dispatch(carInterestsUpdateError(err));
        });
};

const carInterestDelete = (deleteInfo) => {
    return {
        type: typeCarInterestDelete,
        payload: deleteInfo
    };
};

const deleteCarInterest = (dataPush = {}) => (dispatch, getState, { apiService }) => {
    return dataPush.client_id && dataPush.car_id && apiService.deleteInterests(dataPush)
        .then((data) => {
            dispatch(carInterestDelete({ ...data, dataPush: dataPush }));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        })
        .catch((err) => {
            return err;
        });
};

const carReasonsUpdate = (carReasons) => {
    return {
        type: typeCarReasonsUpdate,
        payload: carReasons
    };
};

const carReasonsUpdateError = (errorReasons) => {
    return {
        type: typeCarReasonsUpdateError,
        payload: errorReasons && true
    };
};

const fetchPostCarReasons = (apiService, dispatch) => (dataReason) => {
    return apiService.setReasons(dataReason)
        .then((data) => {
            dispatch(carReasonsUpdate(data));
            return data;
        })
        .catch((err) => {
            dispatch(carReasonsUpdateError(err));
        });
};

const carOilUpdate = (carOilPayload) => {
    return {
        type: typeCarOilUpdate,
        payload: carOilPayload
    };
};

const carOilUpdateError = (isError) => {
    return {
        type: typeCarOilUpdateError,
        payload: isError
    };
};

const carOilIsForTimeLoad = (isForTimeLoad) => {
    return {
        type: typeCarOilIsForTimeLoadSuccess,
        payload: isForTimeLoad
    };
};

const carIsExactlyMileageLoad = (isExactlyMileage) => {
    return {
        type: typeCarIsExactlyOilLastMileageSuccess,
        payload: isExactlyMileage
    };
};

const updateDataOilSuccess = (isUpdateSuccess) => {
    return {
        type: typeUpdateDataOilSuccess,
        payload: isUpdateSuccess
    };
};

const updateDataOilFieldsSuccess = (isUpdateSuccess) => {
    return {
        type: typeUpdateDataOilFieldsSuccess,
        payload: isUpdateSuccess
    };
};

const fetchPostCarOil = (dataOil, clientId) => (dispatch, getState, { apiService }) => {
    // dispatch(setErrorNewReason(false));
    dispatch(carOilUpdateError(false));
    dispatch(updateDataOilFieldsSuccess(true));
    if (Object.prototype.hasOwnProperty.call(dataOil, 'is_for_time')) {
        dispatch(carOilIsForTimeLoad(true));
    }
    if (Object.prototype.hasOwnProperty.call(dataOil, 'is_last_mileage')) {
        dispatch(carIsExactlyMileageLoad(true));
    }

    return clientId && apiService.postOilInfo({ ...dataOil, client_id: clientId })
        .then((data) => {
            dispatch(carOilUpdate(data.oil));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        }).then((data) => {
            if (Object.prototype.hasOwnProperty.call(dataOil, 'is_for_time')) {
                dispatch(carOilIsForTimeLoad(false));
            }
            if (Object.prototype.hasOwnProperty.call(dataOil, 'is_last_mileage')) {
                dispatch(carIsExactlyMileageLoad(false));
            }

            dispatch(updateDataOilFieldsSuccess(false));
            return data;
        })
        .catch(() => {
            dispatch(updateDataOilFieldsSuccess(false));
            dispatch(updateDataOilSuccess(false));
            dispatch(carOilUpdateError(true));
        });
};

const carOilMonthsUpdate = (carOilMonthPayload) => {
    return {
        type: typeCarOilMonthsUpdate,
        payload: carOilMonthPayload
    };
};

const carOilMonthsUpdateError = (carOilMonthsPayload) => {
    return {
        type: typeCarOilMonthsUpdateError,
        payload: carOilMonthsPayload && true
    };
};

const fetchPostOilMonthsInfo = (carOilMonthsPayload, clientId) => (dispatch, getState, { apiService }) => {
    return clientId && apiService.postOilMonthsInfo({ ...carOilMonthsPayload, client_id: clientId })
        .then((data) => {
            dispatch(carOilMonthsUpdate(data.oilMonths));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        })
        .catch((err) => {
            return dispatch(carOilMonthsUpdateError(err));
        });
};

const deleteMonthFromOil = (payload, clientId) => (dispatch, getState, { apiService }) => {
    return clientId && apiService.deleteOilMonth({ ...payload, client_id: clientId })
        .then((data) => {
            dispatch(carOilMonthsUpdate(data.oilMonths));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        })
        .catch((err) => {
            return dispatch(carOilMonthsUpdateError(err));
        });
};

const carTiresUpdate = (carTiresPayload) => {
    return {
        type: typeCarTiresUpdate,
        payload: carTiresPayload
    };
};

const carTiresUpdateError = (carTiresPayloadErr) => {
    return {
        type: typeCarTiresUpdateError,
        payload: carTiresPayloadErr && true
    };
};

const carIsReShoeLoad = (dataTires) => {
    return {
        type: typeCarIsReShoeTiresSuccess,
        payload: dataTires
    };
};

const carIsDoWheelAlignmentLoad = (dataTires) => {
    return {
        type: typeCarIsDoWheelAlignmentSuccess,
        payload: dataTires
    };
};

const fetchPostCarTires = (carTiresPayload, clientId) => (dispatch, getState, { apiService }) => {
    if (Object.prototype.hasOwnProperty.call(carTiresPayload, 'is_re_shoe')) {
        dispatch(carIsReShoeLoad(true));
    }
    if (Object.prototype.hasOwnProperty.call(carTiresPayload, 'is_do_wheel_alignment')) {
        dispatch(carIsDoWheelAlignmentLoad(true));
    }
    return clientId && apiService.postCarTires({ ...carTiresPayload, client_id: clientId })
        .then((data) => {
            dispatch(carTiresUpdate(data.tires));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        }).then((data) => {
            if (Object.prototype.hasOwnProperty.call(carTiresPayload, 'is_re_shoe')) {
                dispatch(carIsReShoeLoad(false));
            }
            if (Object.prototype.hasOwnProperty.call(carTiresPayload, 'is_do_wheel_alignment')) {
                dispatch(carIsDoWheelAlignmentLoad(false));
            }
            return data;
        })
        .catch((err) => {
            return dispatch(carTiresUpdateError(err));
        });
};

const carClientSeasonalPreferenceUpdate = (carSeasonalPreference) => {
    return {
        type: typeCarClientSeasonalPreferenceUpdate,
        payload: carSeasonalPreference
    };
};

const carClientSeasonalPreferenceUpdateError = (isError) => {
    return {
        type: typeCarClientSeasonalPreferenceUpdateError,
        payload: isError
    };
};

const fetchPostCarSeasonalPreference = (carTiresPayload) => (dispatch, getState, { apiService }) => {
    dispatch(carClientSeasonalPreferenceUpdateError(false));
    return apiService.setTiresClientPreferences(carTiresPayload)
        .then((data) => {
            dispatch(carClientSeasonalPreferenceUpdate(data.tiresPreferences));
            dispatch(carOccupancyRateUpdate(data.occupancy_rate_blocks));
            return data;
        }).catch(() => {
            return dispatch(carClientSeasonalPreferenceUpdateError(true));
        });
};

const fetchCarMileage = (apiService, dispatch) => (carId) => {
    dispatch(requestCarMileage());
    return apiService.fetchCarMileage(carId).then(res => {
        return dispatch(receiveCarMileage(res, carId));
    });
};

function requestCarMileage() {
    return {
        type: typeRequestCarMileage
    };
}

function receiveCarMileage(res, carId) {
    return {
        type: typeReceiveCarMileage,
        payload: {
            res: res,
            carId: carId
        }
    };
}

const calculateMileageInMiles = () => {
    return {
        type: typeCalculateMileageInMiles
    };
};

const resetCarMileage = () => {
    return {
        type: typeResetCarMileage
    };
};

const resetCarId = () => {
    return {
        type: typeResetCarId
    };
};

const resetCarCollectId = () => {
    return {
        type: typeResetCarCollectId
    };
};

const setAllResources = (resources) => {
    return {
        type: typeGetAllResources,
        payload: resources
    };
};

const setAllResourcesError = (resourcesError) => {
    return {
        type: typeSetAllResourcesError,
        payload: resourcesError
    };
};

const setAllResourcesLoading = (success) => {
    return {
        type: typeSetAllResourcesLoading,
        payload: success
    };
};

const setDeleteErrorReasonFromBlock = (isDeleteError) => {
    return {
        type: typeDeleteErrorReasonFromBlock,
        payload: isDeleteError
    };
};

const deleteReasonFromBlockState = (idDeleteReason) => {
    return {
        type: typeDeleteReasonFromBlock,
        payload: idDeleteReason
    };
};

const setDeleteReasonFromBlockSuccess = (isDeleteResLoading) => {
    return {
        type: typeDeleteReasonFromBlockSuccess,
        payload: isDeleteResLoading
    };
};

const fetchAllResources = () => (dispatch, getState, { apiService }) => {
    dispatch(setAllResourcesLoading(true));
    return apiService.getAllResources()
        .then((resources) => {
            dispatch(setAllResourcesLoading(false));
            dispatch(setAllResources(resources));
            return resources;
        })
        .catch((err) => {
            dispatch(setAllResourcesLoading(false));
            dispatch(setAllResourcesError(true));
            return err;
        });
};

const setToggleResourceFilter = (setBool) => {
    return {
        type: typeToggleResourceFilter,
        payload: setBool
    };
};

const fetchAllReasons = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorReasons(false));
    dispatch(loadingReasons(true));
    return apiService.fetchReasons()
        .then((res) => {
            dispatch(setReasonsToState(res));
            dispatch(loadingReasons(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorReasons(true));
            return err;
        });
};

const deleteReasonFromBlock = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setDeleteErrorReasonFromBlock(false));
    dispatch(setDeleteReasonFromBlockSuccess(true));
    if (typeof id !== 'number') {
        return;
    }
    return apiService.deleteBlockReason(id)
        .then((res) => {
            (res === true) && dispatch(deleteReasonFromBlockState(id));
            dispatch(setDeleteReasonFromBlockSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setDeleteErrorReasonFromBlock(true));
            return err;
        });
};

const newInterestReasonSuccess = (isLoading) => {
    return {
        type: typeNewInterestReasonSuccess,
        payload: isLoading
    };
};

const setErrorNewInterestReason = (isError) => {
    return {
        type: typeSetErrorNewInterestReason,
        payload: isError
    };
};

const setNewInterestReason = (newInterestReason) => {
    return {
        type: typeSetNewInterestReason,
        payload: newInterestReason
    };
};

const fetchNewInterestsReason = (reasonChange = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorNewInterestReason(false));
    dispatch(newInterestReasonSuccess(true));
    return apiService.fetchAddUpdateReason(reasonChange)
        .then((res) => {
            if (Object.prototype.hasOwnProperty.call(reasonChange, 'reason_title')) {
                dispatch(fetchAllReasons());
            }
            const resNew = reasonChange.id ? { res: res, idPushItem: reasonChange.id } : { res: res };
            dispatch(setNewInterestReason(resNew));
            dispatch(newInterestReasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorNewInterestReason(true));
            dispatch(newInterestReasonSuccess(false));
            return err;
        });
};

const setDelReason = (delReason) => {
    return {
        type: typeDeleteReason,
        payload: delReason
    };
};

const setDelErrorReason = (isError) => {
    return {
        type: typeDeleteErrorReason,
        payload: isError
    };
};

const delReasonSuccess = (isSuccess) => {
    return {
        type: typeDeleteReasonSuccess,
        payload: isSuccess
    };
};

const deleteReason = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setDelErrorReason(false));
    dispatch(delReasonSuccess(true));
    return apiService.delReason(id)
        .then((res) => {
            if (res) {
                dispatch(setDelReason(id));
            }
            dispatch(delReasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setDelErrorReason(true));
            dispatch(delReasonSuccess(false));
            return err;
        });
};

const newReasonSuccess = (isLoadingReason) => {
    return {
        type: typeNewReasonSuccess,
        payload: isLoadingReason
    };
};

const setErrorNewReason = (isErrorReason) => {
    return {
        type: typeSetErrorNewReason,
        payload: isErrorReason
    };
};

const setNewReason = (newReason) => {
    return {
        type: typeSetNewReason,
        payload: newReason
    };
};

const setUpdateReason = (updateReason) => {
    return {
        type: typeSetUpdateReason,
        payload: updateReason
    };
};

const fetchNewReason = (nameTable, payload = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorNewReason(false));
    dispatch(newReasonSuccess(true));
    return apiService.fetchReason(payload)
        .then((res) => {
            if (payload.id) {
                dispatch(setUpdateReason(res));
            } else {
                dispatch(setNewReason(res));
            }
            dispatch(newReasonSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorNewReason(true));
            dispatch(newReasonSuccess(false));
            return err;
        });
};

const updateBlockSuccess = (isLoading) => {
    return {
        type: typeUpdateBlockSuccess,
        payload: isLoading
    };
};

const setErrorUpdateBlock = (isError) => {
    return {
        type: typeErrorUpdateBlock,
        payload: isError
    };
};

const setUpdateBlock = (update) => {
    return {
        type: typeSetUpdateBlock,
        payload: update
    };
};

const updateCarCollectBlock = (nameTable, payload = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorUpdateBlock(false));
    dispatch(updateBlockSuccess(true));
    return apiService.fetchCarCollectBlocks(payload)
        .then((res) => {
            if (payload.id === res.id) {
                dispatch(setUpdateBlock(res));
            }
            dispatch(updateBlockSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorUpdateBlock(true));
            dispatch(updateBlockSuccess(false));
            return err;
        });
};

const changeFlagDoNotCallClient = (client) => {
    return {
        type: typeChangeFlagDoNotCallClient,
        payload: client
    };
};

const setErrorFlagDoNotCallClient = (error) => {
    return {
        type: typeErrorSetFlagDoNotCallClient,
        payload: error
    };
};

const setFlagDoNotCallClientSuccess = (client) => {
    return {
        type: typeSetFlagDoNotCallClientSuccess,
        payload: client
    };
};

const setFlagDoNotCallClient = (clientWithStateCall) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorFlagDoNotCallClient(false));
    dispatch(setFlagDoNotCallClientSuccess(true));
    return apiService.setSecretaryDoNotCall(clientWithStateCall)
        .then((res) => {
            dispatch(changeFlagDoNotCallClient(res));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorFlagDoNotCallClient(true));
            dispatch(setFlagDoNotCallClientSuccess(false));
            return err;
        });
};

export {
    carsLoaded,
    selectedIdCarLoaded,
    setTextCarsLoaded,
    foundCarsLoaded,
    setSvHeaderLoaded,
    carDetailsLoaded,
    carsFullyBlocksLoaded,
    carIdCollectLoaded,
    clientIdCollectLoaded,
    loadingCarDetails,
    carCollectBlocksLoaded,
    fetchCollectBlocksErrorLoaded,
    fetchCollectBlocks,
    collectBlocksSuccess,
    setShowAnchors,
    setShowSecretary,
    updateCarImage,
    deleteCarImage,
    setCarImages,
    addCarImages,
    carDetailsUpload,
    saveCarImage,
    fetchPostCarInsuranceDeadline,
    fetchPostCarInsurance,
    searchCars,
    clearFoundCars,
    fetchPostCarInterests,
    fetchPostCarReasons,
    fetchPostCarOil,
    fetchPostOilMonthsInfo,
    fetchPostCarTires,
    fetchPostCarSeasonalPreference,
    fetchCarMileage,
    calculateMileageInMiles,
    resetCarMileage,
    resetCarId,
    resetCarCollectId,
    saveSearchCarsError,
    fetchAllResources,
    setToggleResourceFilter,
    fetchAllReasons,
    deleteReasonFromBlock,
    fetchNewInterestsReason,
    deleteReason,
    fetchNewReason,
    updateCarCollectBlock,
    setFlagDoNotCallClient,
    deleteCarInterest,
    fetchCarServiceBook,
    deleteMonthFromOil,
    uniqueSheetCollectUpload
};
