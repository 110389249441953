import React from 'react';
import { CardRight } from '../Cards';
import PropTypes from 'prop-types';

import style from './CarsInspectCollect.module.scss';
// import { fetchOffsetNotCollectCars } from '../../reducers/sheetCollectInfo';
import { connect } from 'react-redux';

const CarsCollectList = ({ cars, limit, showBtn }) => {
    const handleAddCars = () => {
        // fetchOffsetNotCollectCars(whatAddParam);  // hidden get collection sheets
    };

    const items = cars ? cars.map((carItem) => {
        return (
            <div key={carItem.id} className={style.card}>
                { /* {
                    toggleResourceSelect
                        ? <div className={style.carsLoad}><Loader active={toggleResourceSelect} classes={['small', 'loaderTop', 'gray']} /></div>
                        : ''
                } */ }
                <CardRight carItem={carItem} />
            </div>
        );
    }) : <div>Loading... </div>;

    const btnMore = (
        <div className={style.elems}>
            <div onClick={handleAddCars} className={style.addCars}>Показать еще...</div>
        </div>
    );

    return (
        <div className={style.scrollSet}>
            { items }
            {
                (cars && (cars.length < limit)) || !showBtn
                    ? ''
                    : btnMore
            }
        </div>
    );
};

CarsCollectList.propTypes = {
    cars: PropTypes.array,
    showBtn: PropTypes.bool,
    limit: PropTypes.number
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(CarsCollectList);
