import React from 'react';
import { CardCar } from '../Cards';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTextCarsLoaded, searchCars, clearFoundCars } from '../../actions';

import style from './SearchResultCars.module.scss';
import { createUniqueArrItems } from '../../utils';
import ShowWrapSearch from '../ShowWrapSearch';

const SearchResultCars = ({ sBooksHeader, cars, btn, bgClassName, totalCountFoundCars, isSearch, isFetching, searchCars, setTextCarsLoaded, clearFoundCars }) => {
    const uniqueCars = createUniqueArrItems(cars, 'id');
    const items = uniqueCars.map(item => <CardCar oneCar={item} key={item.car_id || item.id} />);
    const classCss = `${style.listServiceBooks} ${bgClassName}`;
    const searchCarsWithOffset = (offset) => {
        setTextCarsLoaded({ offset });
        searchCars(offset);
    };

    return (
        <ShowWrapSearch
            classWrap={classCss}
            btn={btn}
            isFetching={isFetching}
            isSearch={isSearch}
            clearResultFunc={clearFoundCars}
            uniqItems={uniqueCars}
            showMoreFunc={searchCarsWithOffset}
            totalFoundedItems={totalCountFoundCars}
            emptyMessage='Введенной информации не найдено ни в одной сервисной книжке. Проверьте, правильно ли вы ввели данные.'
            title={sBooksHeader.title}
            classesButton={['showMore']}
        >
            {
                items
            }
        </ShowWrapSearch>
    );
};

SearchResultCars.defaultProps = {
    cars: [],
    btn: false
};

SearchResultCars.propTypes = {
    sBooksHeader: PropTypes.object,
    cars: PropTypes.array,
    totalCountFoundCars: PropTypes.number,
    setTextCarsLoaded: PropTypes.func,
    btn: PropTypes.bool,
    bgClassName: PropTypes.string,
    isSearch: PropTypes.bool,
    isFetching: PropTypes.bool,
    searchCars: PropTypes.func,
    clearFoundCars: PropTypes.func
};

const mapDispatchToProps = {
    setTextCarsLoaded,
    searchCars,
    clearFoundCars
};

export default connect(() => ({}), mapDispatchToProps)(SearchResultCars);
