export default function getBlockIdByLabel(blocks, label, returnObject = false) {
    if (Array.isArray(blocks) && blocks.length > 0) {
        const block = blocks.find(item => item.label === label);
        if (returnObject) {
            return block || {};
        }
        return block?.id || 0;
    }

    return 0;
}

export function getBlockIdByPacketLabel(blocks, packetLabel, returnObject = false) {
    const blocksWithPacket = blocks.filter((block) => {
        return (block.packet && block.packet.id) || 0;
    });

    if (Array.isArray(blocksWithPacket) && blocksWithPacket.length > 0) {
        const block = blocksWithPacket.find(item => item.packet.label === packetLabel);
        if (returnObject) {
            return block || {};
        }
        return block?.id || 0;
    }

    return 0;
}
