import {
    typeSetRangeProlongationInAction,
    typeSetReportProlongationInActionSuccess,
    typeSetReportProlongationInActionError,
    typeSetReportProlongationInAction,
    typeSetProlongationData,
    typeSetProlongationFormatChunks,
    typeChangeUnionFlagProlongation,
    typeSetPreReservesFromReport,
    typeClearPreReservesFromReport,
    typeSetPreReservesLimitOffset,
    typeSetModeProlongation,
    typeCountProlongationInitiation,
    typeAddToPreReservesFromReport,
    typeExportProlongationReportSuccess
} from '../actionsTypes';
import { initialChunkProlongation, initialTimeRangeProlongation } from '../constants/initialTimeRangeProlongation';
import { OPTIONS_PACKETS, OPTIONS_PACKETS_LABELS } from '../constants/packets';
import { getRangeAndChunk } from '../utils/getRangeAndChunk';
import { format } from 'date-fns';
import _ from 'lodash';
import { LIMIT_DEFAULT_PRE_RESERVES, OFFSET_DEFAULT_PRE_RESERVES } from '../constants/limitsDefault';
import { PROLONGATION_MODE_PROLONGATION } from '../constants/prolongationMode';

// ------------------------------------
// Constants
// ------------------------------------
const SET_RANGE_PROLONGATION_IN_ACTION = typeSetRangeProlongationInAction;
const SET_PROLONGATION_IN_ACTION_SUCCESS = typeSetReportProlongationInActionSuccess;
const SET_PROLONGATION_IN_ACTION_ERROR = typeSetReportProlongationInActionError;
const SET_REPORT_PROLONGATION_IN_ACTION = typeSetReportProlongationInAction;
const SET_DATA_PROLONGATION_IN_ACTION = typeSetProlongationData;
const SET_PROLONGATION_FORMAT_CHUNKS = typeSetProlongationFormatChunks;
const CHANGE_UNION_FLAG_PROLONGATION = typeChangeUnionFlagProlongation;
const SET_PRE_RESERVES_FOR_REPORT = typeSetPreReservesFromReport;
const ADD_TO_PRE_RESERVES_FOR_REPORT = typeAddToPreReservesFromReport;
const CLEAR_PRE_RESERVES_FOR_REPORT = typeClearPreReservesFromReport;
const SET_PRE_RESERVES_LIMIT_OFFSET = typeSetPreReservesLimitOffset;
const SET_MODE_PROLONGATION = typeSetModeProlongation;
const SET_COUNT_PROLONGATION_INITIATION = typeCountProlongationInitiation;
const EXPORT_PROLONGATION_REPORT_SUCCESS = typeExportProlongationReportSuccess;

// ------------------------------------
// Actions
// ------------------------------------

export const setRangeReportProlongationInAction = (payload) => {
    return {
        type: SET_RANGE_PROLONGATION_IN_ACTION,
        payload: payload
    };
};

export const setProlongationInAction = (payload) => {
    return {
        type: SET_REPORT_PROLONGATION_IN_ACTION,
        payload: payload
    };
};

export const setProlongationInActionError = (payload) => {
    return {
        type: SET_PROLONGATION_IN_ACTION_ERROR,
        payload: payload
    };
};

export const setProlongationInActionSuccess = (payload) => {
    return {
        type: SET_PROLONGATION_IN_ACTION_SUCCESS,
        payload: payload
    };
};

export const setProlongationInActionData = (payload) => {
    return {
        type: SET_DATA_PROLONGATION_IN_ACTION,
        payload: payload
    };
};

export const picProlongationFormat = (payload) => {
    return {
        type: SET_PROLONGATION_FORMAT_CHUNKS,
        payload: payload
    };
};

export const setCountProlongationInitiation = (payload) => {
    return {
        type: SET_COUNT_PROLONGATION_INITIATION,
        payload: payload
    };
};

export const getReportProlongationForRange2 = (isEqualData = false, unionFlag) => (dispatch, getState, { apiService }) => { // may delete function, not use
    if (getState().reportsProlongationInAction?.prolongationRange) {
        const timeFrom = getState().reportsProlongationInAction?.prolongationRange?.from;
        const timeTo = getState().reportsProlongationInAction?.prolongationRange?.to;
        let packets, chunks, from, to;
        if (getState().reportsProlongationInAction.prolongationPacket?.length > 0) {
            packets = getState()
                .reportsProlongationInAction
                .prolongationPacket?.map(item => {
                    return OPTIONS_PACKETS.find(packet => packet.id === item)?.label;
                });
        } else {
            packets = OPTIONS_PACKETS_LABELS;
        }
        const formatChunk = getState().reportsProlongationInAction.prolongationFormat;

        const { chunkDateLocal, fromDateLocal, toDateLocal } = getRangeAndChunk(timeFrom, timeTo);
        if (isEqualData) {
            chunks = formatChunk;
            from = format(new Date(timeFrom), 'yyyy-MM-dd');
            to = format(new Date(timeTo), 'yyyy-MM-dd');
        } else {
            chunks = formatChunk || chunkDateLocal;
            from = fromDateLocal;
            to = toDateLocal;
        }

        if (from && to && chunks) {
            dispatch(setProlongationInActionSuccess(true));
            dispatch(setProlongationInActionError(false));
            return from && to && apiService.getProlongationInAction({
                range: { from, to },
                packets: packets,
                chunks: [chunks],
                unionFlag: unionFlag,
                mode: PROLONGATION_MODE_PROLONGATION
            })
                .then((data) => {
                    !isEqualData && !formatChunk && dispatch(picProlongationFormat(chunkDateLocal));
                    dispatch(setProlongationInAction(data.report));
                    dispatch(setCountProlongationInitiation(data));
                    dispatch(setProlongationInActionSuccess(false));
                    return data;
                })
                .catch((err) => {
                    dispatch(setProlongationInActionSuccess(false));
                    dispatch(setProlongationInActionError(true));
                    return err;
                });
        }
    } else {
        return false;
    }
};

export const getReportProlongationForRange = (data) => (dispatch, getState, { apiService }) => {
    if (data.from && data.to && data.chunks && data.packets && data.mode) {
        dispatch(setProlongationInActionSuccess(true));
        dispatch(setProlongationInActionError(false));
        const dataRequest = {
            range: { from: data.from, to: data.to },
            packets: Array.isArray(data.packets) ? data.packets : [data.packets],
            chunks: [data.chunks],
            unionFlag: +data.unionFlag,
            mode: data.mode
        };

        return apiService.getProlongationInAction(dataRequest)
            .then((data) => {
                dispatch(setProlongationInAction(data.report));
                dispatch(setCountProlongationInitiation(data));
                dispatch(setProlongationInActionSuccess(false));
                return data;
            })
            .catch((err) => {
                dispatch(setProlongationInActionSuccess(false));
                dispatch(setProlongationInActionError(true));
                return err;
            });
    }
};

export const exportProlongationReport = (data) => (dispatch, getState, { apiService }) => {
    // start fetch
    dispatch(fetchProlongationReportExport(true));
    if (data.from && data.to) {
        return apiService.activateProlongationExport(data)
            .then(async(data) => {
                if (data?.refId) {
                    return apiService.getProlongationExport(data.refId)
                        .then(report => {
                            dispatch(fetchProlongationReportExport(false));
                            if (report) {
                                return report;
                            } else {
                                return null;
                            }
                        })
                        .catch((err) => {
                            dispatch(fetchProlongationReportExport(false));
                            return err;
                        });
                } else {
                    dispatch(fetchProlongationReportExport(false));
                    return false;
                }
            })
            .catch((err) => {
                dispatch(fetchProlongationReportExport(false));
                return err;
            });
    }
};

export const fetchProlongationReportExport = (payload) => {
    return {
        type: EXPORT_PROLONGATION_REPORT_SUCCESS,
        payload: payload
    };
};

export const setUnionFlag = (payload) => {
    return {
        type: CHANGE_UNION_FLAG_PROLONGATION,
        payload: payload
    };
};

export const changeUnionFlag = (unionFlag) => (dispatch) => {
    dispatch(setUnionFlag(unionFlag));
};

export const setPreReservesReportForMetric = (payload) => {
    return {
        type: ADD_TO_PRE_RESERVES_FOR_REPORT,
        payload: payload
    };
};

export const setPreReservesReportNew = (payload) => {
    return {
        type: SET_PRE_RESERVES_FOR_REPORT,
        payload: payload
    };
};

export const clearPreReservesReportForMetric = (payload) => {
    return {
        type: CLEAR_PRE_RESERVES_FOR_REPORT,
        payload: payload
    };
};

export const getPreReservesReportForMetric = (data) => (dispatch, getState, { apiService }) => {
    const dataPush = {
        ...data,
        limit: getState()?.reportsProlongationInAction?.limit || LIMIT_DEFAULT_PRE_RESERVES,
        offset: getState()?.reportsProlongationInAction?.offset || OFFSET_DEFAULT_PRE_RESERVES
    };

    if (dataPush.offset === 0 || (dataPush.offset !== 0 && dataPush.offset !== getState()?.reportsProlongationInAction?.preReservesReportCount)) {
        dispatch(setProlongationInActionSuccess(true));
        dispatch(setProlongationInActionError(false));
    }
    return (dataPush.offset === 0 || (dataPush.offset !== 0 && dataPush.offset !== getState()?.reportsProlongationInAction?.preReservesReportCount)) && apiService.getPreReservesReport(dataPush)
        .then((data) => {
            if (data && data.prereserves) {
                if (dataPush.offset === 0) {
                    dispatch(setPreReservesReportNew(data));
                } else {
                    dispatch(setPreReservesReportForMetric(data));
                }
            }
            dispatch(setProlongationInActionSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(setProlongationInActionSuccess(false));
            dispatch(setProlongationInActionError(true));
            return err;
        });
};

export const setPreReservesLimitOffset = (payload) => {
    return {
        type: SET_PRE_RESERVES_LIMIT_OFFSET,
        payload: payload
    };
};

export const setModeProlongation = (payload) => {
    return {
        type: SET_MODE_PROLONGATION,
        payload: payload
    };
};

export const actions = {
    setRangeReportProlongationInAction,
    getReportProlongationForRange,
    setProlongationInActionData,
    picProlongationFormat,
    changeUnionFlag,
    getPreReservesReportForMetric,
    setPreReservesReportForMetric,
    setPreReservesLimitOffset,
    setModeProlongation
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_MODE_PROLONGATION]: (state, action) => {
        return ({
            ...state,
            mode: action.payload
        });
    },
    [SET_RANGE_PROLONGATION_IN_ACTION]: (state, action) => {
        return ({
            ...state,
            prolongationRange: action.payload
        });
    },
    [SET_REPORT_PROLONGATION_IN_ACTION]: (state, action) => {
        return ({
            ...state,
            prolongationResult: action.payload
        });
    },

    [SET_PROLONGATION_IN_ACTION_ERROR]: (state, action) => {
        return ({
            ...state,
            prolongationError: action.payload
        });
    },

    [SET_PROLONGATION_IN_ACTION_SUCCESS]: (state, action) => {
        return ({
            ...state,
            prolongationSuccess: action.payload
        });
    },

    [SET_DATA_PROLONGATION_IN_ACTION]: (state, action) => {
        return ({
            ...state,
            prolongationPacket: action.payload
        });
    },

    [SET_PROLONGATION_FORMAT_CHUNKS]: (state, action) => {
        return ({
            ...state,
            prolongationFormat: action.payload
        });
    },

    [CHANGE_UNION_FLAG_PROLONGATION]: (state, action) => {
        return ({
            ...state,
            unionFlag: action.payload
        });
    },

    [ADD_TO_PRE_RESERVES_FOR_REPORT]: (state, action) => {
        const oldPreReserves = _.cloneDeep(state.preReservesReport);
        return ({
            ...state,
            preReservesReport: [
                ...oldPreReserves,
                ...action.payload.prereserves
            ],
            preReservesReportCount: action.payload.prereservesTotalCount,
            preReservesTotalCountPreviousPeriod: action.payload.prereservesTotalCountPreviousPeriod
        });
    },

    [SET_PRE_RESERVES_FOR_REPORT]: (state, action) => {
        return ({
            ...state,
            preReservesReport: [
                ...action.payload.prereserves
            ],
            preReservesReportCount: action.payload.prereservesTotalCount,
            preReservesTotalCountPreviousPeriod: action.payload.prereservesTotalCountPreviousPeriod
        });
    },

    [CLEAR_PRE_RESERVES_FOR_REPORT]: (state) => {
        return ({
            ...state,
            preReservesReport: [],
            prolongationError: false,
            prolongationSuccess: false,
            offset: OFFSET_DEFAULT_PRE_RESERVES,
            limit: LIMIT_DEFAULT_PRE_RESERVES
        });
    },

    [SET_PRE_RESERVES_LIMIT_OFFSET]: (state, action) => {
        return ({
            ...state,
            offset: action.payload.offset
        });
    },

    [SET_COUNT_PROLONGATION_INITIATION]: (state, action) => {
        return ({
            ...state,
            prolongationsCount: action.payload.prolongationsCount,
            initiationsCount: action.payload.initiationsCount
        });
    },

    [EXPORT_PROLONGATION_REPORT_SUCCESS]: (state, action) => {
        return ({
            ...state,
            exportProlongationSuccess: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            prolongationRange: initialTimeRangeProlongation,
            prolongationPacket: OPTIONS_PACKETS ? OPTIONS_PACKETS.map(item => item.id) : [],
            prolongationFormat: initialChunkProlongation,
            prolongationError: false,
            prolongationSuccess: false,
            preReservesReport: [],
            preReservesReportCount: 0,
            preReservesTotalCountPreviousPeriod: 0,
            prolongationResult: [],
            offset: OFFSET_DEFAULT_PRE_RESERVES,
            limit: LIMIT_DEFAULT_PRE_RESERVES,
            unionFlag: false,
            mode: '',
            prolongationsCount: 0,
            initiationsCount: 0,
            exportProlongationSuccess: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state.reportsProlongationInAction, action) : state.reportsProlongationInAction;
};

export default commonReducer;
