import React from 'react';
import PairColumn from '../../../../../components/PairColumn';
import ReportsHeader from '../../../ReportsHeader';
import ProlongationReportBodyWrap from './ProlongationReportBodyWrap';

const ProlongationReport = () => {
    return (
        <PairColumn>
            <ReportsHeader textHeader='Отчет по пролонгации' classes={['inReport']} withYearFilter />
            <ProlongationReportBodyWrap />
        </PairColumn>
    );
};

export default ProlongationReport;
