import React from 'react';
import PropTypes from 'prop-types';
import style from './WrapTable.module.scss';

const WrapTable = ({ children }) => {
    return (
        <section className={style.wrapTable}>
            { children }
        </section>
    );
};

WrapTable.propTypes = {
    children: PropTypes.node
};

export default WrapTable;
