import React, { useState } from 'react';
import PropTypes from 'prop-types';

import style from './FieldLabelFormTransformSelect.module.scss';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

const FieldLabelFormTransformSelect = ({ nameForm, valueField, forUpdateField, colors }) => {
    const [valueForm, setValueForm] = useState(valueField || '');

    const handleSubmit = (e) => {
        const value = (e.target.value === 'empty') ? null : e.target.value;
        forUpdateField(e, value);
        e.preventDefault();
    };

    const options = colors.map((item, index) => {
        return <option key={index + 1} value={item.label}>{ item.title }</option>;
    });

    const updateValue = (e) => {
        setValueForm(e.target.value);
        handleSubmit(e);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className={style.formSelect}>
                <label>{ nameForm }
                    <select value={valueForm} name='formSelectField' onChange={updateValue}>
                        {
                            options
                        }
                    </select>
                </label>
            </form>
        </div>
    );
};

FieldLabelFormTransformSelect.defaultProps = {
    toUpperCase: false,
    powerEngine: false,
    valueField: ''
};

FieldLabelFormTransformSelect.propTypes = {
    nameForm: PropTypes.string.isRequired,
    valueField: PropTypes.any,
    forUpdateField: PropTypes.func,
    colors: PropTypes.array
};

const mapStateToProps = ({ templatesOfFrameState: { colors } }) => {
    return {
        colors
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(FieldLabelFormTransformSelect);
