import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import style from './SetNotCollectCarInterests.module.scss';
import { connect } from 'react-redux';
import Loader from '../Loader';

const SetNotCollectCarInterests = ({
    carCollectBlocks,
    handleUpdateBtnInterest,
    carDetailsInfo,
    blockId,
    carPostInterestsSuccess
}) => {
    const [serverBtns, setServerBtns] = useState(carDetailsInfo.car_selected_interests);

    const [selected, setSelected] = useState(null);

    const allBtn = useMemo(() => (carCollectBlocks ? (carCollectBlocks.clientServiceInterests ? carCollectBlocks.clientServiceInterests : null) : null), [carCollectBlocks]);

    const resBtn = allBtn
        ? allBtn.filter((item) => {
            return (item.title === 'Из-за эксперта') || (item.title === 'Из-за гостя');
        })
        : null;

    const styleArr = [style.first, style.right];

    useEffect(() => {
        setServerBtns(carDetailsInfo.car_selected_interests);
    }, [carDetailsInfo, blockId, serverBtns, selected]);

    useEffect(() => {
        setSelected(serverBtns ? serverBtns.reduce((res, item) => {
            res = (item.car_collect_block_id === blockId) ? item : res;
            return res;
        }, {}).client_service_interest_id : null);
    }, [serverBtns, blockId]);

    const choiceButton = (id, event) => {
        handleUpdateBtnInterest(id);
        setSelected(id);
        document.getElementById(`interest_${id}_${blockId}`).classList.remove(`${style.chooseBtn}`);
        event.currentTarget.classList.add(style.chooseBtn);
    };

    const btns = resBtn ? resBtn.map((btn, index) => {
        const styleSelect = style.chooseBtn;
        const classNamesAdded = `${styleArr[index]} ${styleSelect}`;
        const classNamesRemoved = `${styleArr[index]}`;

        return (
            <div
                key={index}
                className={(btn.id === selected) ? classNamesAdded : classNamesRemoved}
                onClick={(e) => choiceButton(btn.id, e)}
                id={`interest_${btn.id}_${blockId}`}
            >
                { btn.title }
            </div>
        );
    }) : (<div>Loading... </div>);

    return (
        <div className={style.btnStorageTire} key={`div${selected}${blockId}`}>
            <div className={style.buttons}>
                {
                    carPostInterestsSuccess.status && (carPostInterestsSuccess.blockId === blockId)
                        ? <div className={style.btnLoad}><Loader active={carPostInterestsSuccess.status} classes={['small', 'light']} /></div>
                        : ''
                }
                {
                    btns && btns
                }
            </div>
        </div>
    );
};

SetNotCollectCarInterests.propTypes = {
    blockId: PropTypes.number,
    carCollectBlocks: PropTypes.object,
    handleUpdateBtnInterest: PropTypes.func,
    carDetailsInfo: PropTypes.object,
    carPostInterestsSuccess: PropTypes.object
};

const mapStateToProps = ({ templatesOfFrameState: { carCollectBlocks }, carDetailsState: { carDetailsInfo, carPostInterestsSuccess } }) => {
    return {
        carCollectBlocks,
        carDetailsInfo,
        carPostInterestsSuccess
    };
};

export default connect(mapStateToProps)(SetNotCollectCarInterests);
