
export const getAllStatusesLabels = (clientsStatuses) => {
    const statuses = clientsStatuses?.length > 0 ? clientsStatuses.reduce((accum, item) => {
        return [...accum, ...item.statuses];
    }, []) : [];

    return statuses.length > 0
        ? [...new Set(statuses.map(item => item.label))]
        : [];
};
