import React, { useState } from 'react';
import { CardLeft, CardRight } from '../Cards';
import PropTypes from 'prop-types';

import style from './CarsInspectCollect.module.scss';

const CarsInspectCollect = ({ layout, cars, hidden }) => {
    const [selectedId, setSelectedId] = useState(0);
    const hiddenStyle = hidden ? style.hiddenItems : '';

    const items = cars ? cars.map((carItem) => {
        if (layout === 'CardLeft') {
            return (
                <div key={carItem.carId} className={style.card} onClick={() => setSelectedId(carItem.carId)}>
                    <CardLeft carItem={carItem} selectedid={selectedId} />
                </div>
            );
        } else if (layout === 'CardRight') {
            return (
                <div key={carItem.id} className={style.card} onClick={() => setSelectedId(carItem.id)}>
                    <CardRight carItem={carItem} selectedid={selectedId} />
                </div>
            );
        }
        return (
            <div key={carItem.id} className={style.card} onClick={() => setSelectedId(carItem.id)}>
                <CardRight carItem={carItem} selectedid={selectedId} />
            </div>
        );
    }) : <div>Loading... </div>;

    return (
        <div className={hiddenStyle}>
            { items }
        </div>
    );
};

CarsInspectCollect.defaultProps = {
    layout: 'CardRight'
};

CarsInspectCollect.propTypes = {
    layout: PropTypes.string,
    cars: PropTypes.array,
    hidden: PropTypes.bool
};

export default CarsInspectCollect;
