import UsersTable from '../UsersTable';
import { connect } from 'react-redux';
import { deleteOwner } from '../../../../reducers/settings';

const mapStateToProps = ({ settings: { ownersCompany } }) => {
    return {
        ownersCompany
    };
};

export default connect(mapStateToProps, { deleteOwner })(UsersTable);
