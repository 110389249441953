import React from 'react';
import PropTypes from 'prop-types';
import style from './CollectionSheetName.module.scss';
import { Link } from 'react-router-dom';
import { formatNumber } from '../../utils';
import CarMarkNumber from '../CarMarkNumber';

const CollectionSheetName = ({ collectionSheetDetails, withSheetLink, minHeader, classes = [] }) => {
    const row = minHeader
        ? (
            <div className={style.collectionSheetDetails + (withSheetLink ? ' ' + style.withSheetLinkBg : '') + classes.map(item => ' ' + style[item]).join('')}>
                <Link to={collectionSheetDetails.clientId ? `/visitors/${collectionSheetDetails.clientId}` : ''}>
                    <div className={style.client}>
                        <div>{ collectionSheetDetails.clientName }</div>
                        <div>{ formatNumber(collectionSheetDetails.clientPhone) }</div>
                    </div>
                </Link>
                {
                    collectionSheetDetails?.carMark && collectionSheetDetails?.carModel && <div className={style.pipe} />
                }
                <Link to={collectionSheetDetails.carId ? `/svbook-details/${collectionSheetDetails.carId}` : ''}>
                    <CarMarkNumber carInfo={collectionSheetDetails} />
                </Link>
            </div>
        )
        : (
            <div className={style.collectionSheetDetails + (withSheetLink ? ' ' + style.withSheetLinkBg : '') + classes.map(item => ' ' + style[item]).join('')}>
                {
                    withSheetLink
                        ? <div className={style.imgMargin} />
                        : ''
                }
                <div>{ collectionSheetDetails.clientName }</div>
                <div>{ formatNumber(collectionSheetDetails.clientPhone) }</div>
                <div>{ collectionSheetDetails.carMark } { collectionSheetDetails.carModel } { collectionSheetDetails.carNumber ? `(${collectionSheetDetails.carNumber}/${collectionSheetDetails.carNumberRegion})` : '' }</div>
            </div>
        );

    if (withSheetLink) {
        return (
            <Link to={`/reports/history-report/${collectionSheetDetails.carId}/${collectionSheetDetails.clientId}`}>
                { row }
            </Link>
        );
    }
    return (
        <>
            { row }
        </>
    );
};

CollectionSheetName.propTypes = {
    collectionSheetDetails: PropTypes.object,
    minHeader: PropTypes.bool,
    classes: PropTypes.array,
    withSheetLink: PropTypes.bool
};

export default CollectionSheetName;
