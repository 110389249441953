import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ItemChangeRectangleButton = ({ item, selectedItemId, styleSelect, styleNotSelect, seasonId, handleChange, timeRange }) => {
    return (
        <div
            className={(item.id === selectedItemId) ? styleSelect : styleNotSelect}
            key={'periodSeason&' + item.id}
            onClick={(e) => handleChange(item.id, seasonId)}
        >
            { item.title }
            { ' ' + timeRange }
        </div>
    );
};

ItemChangeRectangleButton.propTypes = {
    item: PropTypes.object,
    selectedItemId: PropTypes.number,
    styleSelect: PropTypes.string,
    styleNotSelect: PropTypes.string,
    seasonId: PropTypes.number,
    handleChange: PropTypes.func,
    timeRange: PropTypes.string
};

export default memo(ItemChangeRectangleButton);
