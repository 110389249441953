import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './CustomSelect.module.scss';
import DeleteButtonIcon from '../../Buttons/DeleteButtonIcon';
import OptionsWrapPopup from '../OptionsWrapPopup';
import CustomOptionSelect from '../CustomOptionSelect';

const CustomSelectWrapper = ({ inputButton, optionsWrapPopup, deleteBtn, selectedValue, isOpenOption, handleIsOpenOptions, id, classes = [], classesInput = [] }) => {
    return (
        <div className={style.rowSelectMonth + classesInput.map(item => ' ' + style[item]).join('')} id={id}>
            {
                optionsWrapPopup
            }
            <div
                className={style.selectedValueButton + (selectedValue === 0 ? (' ' + style.disableTitle) : '') + (isOpenOption ? (' ' + style.focused) : '') + classes.map(item => ' ' + style[item]).join('')}
                onClick={handleIsOpenOptions}
                id={id}
            >
                {
                    inputButton
                }
            </div>
            {
                deleteBtn
            }
        </div>
    );
};

CustomSelectWrapper.propTypes = {
    inputButton: PropTypes.string,
    optionsWrapPopup: PropTypes.node,
    deleteBtn: PropTypes.node,
    selectedValue: PropTypes.number,
    isOpenOption: PropTypes.bool,
    handleIsOpenOptions: PropTypes.func,
    classes: PropTypes.array,
    classesInput: PropTypes.array,
    id: PropTypes.string
};

const CustomSelect = ({
    options,
    selectedItem,
    handleSelectItems,
    handleDeleteChoosingItem,
    withBtnDel,
    disabledItems,
    classesOption,
    classesOptions,
    id,
    isNotValid,
    classesInput,
    fieldMain = 'month',
    classes = [],
    disableTitle = '',
    keysOptions = {
        value: 'value',
        title: 'title'
    }
}) => {
    const [selectedValue, setSelectedValue] = useState(0);
    const [isOpenOption, setIsOpenOptions] = useState(false);

    useEffect(() => {
        setSelectedValue(selectedItem?.[fieldMain] || 0);
    }, [selectedItem, fieldMain]);

    const handleToggleOption = (value) => {
        setIsOpenOptions(value);
    };

    const handleSelectValue = (value) => {
        handleToggleOption(false);
        setSelectedValue(value);
        handleSelectItems({ id: selectedItem ? selectedItem?.id : 0, value: value });
    };
    const handleDeleteSelectedValue = () => {
        handleDeleteChoosingItem({ id: selectedItem.id });
    };

    const selectedTitle = options?.length > 0
        ? (options.find(objItem => objItem[keysOptions.value] === selectedValue) ? options.find(objItem => objItem[keysOptions.value] === selectedValue)[keysOptions.title] : '')
        : '';

    function wrappedOptions(options, disabledItems, handleSelectValue, selectedValue, keysOptions, classesOption) {
        return options && options.length > 0 && options.map((item) => {
            return (
                <CustomOptionSelect
                    key={`month${item[keysOptions.value]}`}
                    keysOptions={keysOptions}
                    option={item}
                    selectedValue={selectedValue}
                    handleSelectValue={() => handleSelectValue(item[keysOptions.value])}
                    isDisable={!!disabledItems.find(disableItem => disableItem === item[keysOptions.value])}
                    classesOption={classesOption}
                />
            );
        });
    }

    const optionsWrapPopup = (
        <OptionsWrapPopup
            options={wrappedOptions(options, disabledItems, handleSelectValue, selectedValue, keysOptions, classesOption)}
            handleClose={() => handleToggleOption(false)}
            classesOptions={classesOptions}
        />
    );

    return (
        <CustomSelectWrapper
            inputButton={selectedValue ? selectedTitle : disableTitle}
            optionsWrapPopup={isOpenOption ? optionsWrapPopup : ''}
            deleteBtn={withBtnDel ? <DeleteButtonIcon handleDeleteSelectedValue={handleDeleteSelectedValue} /> : ''}
            selectedValue={selectedValue}
            isOpenOption={isOpenOption}
            handleIsOpenOptions={() => setIsOpenOptions(true)}
            classes={isNotValid ? [...classes, 'redBorder'] : classes}
            classesInput={classesInput}
            id={id}
        />
    );
};

CustomSelect.propTypes = {
    options: PropTypes.array,
    selectedItem: PropTypes.object,
    handleSelectItems: PropTypes.func,
    handleDeleteChoosingItem: PropTypes.func,
    withBtnDel: PropTypes.bool,
    disabledItems: PropTypes.arrayOf(PropTypes.number),
    disableTitle: PropTypes.string,
    keysOptions: PropTypes.object,
    classes: PropTypes.array,
    classesOption: PropTypes.array,
    classesOptions: PropTypes.array,
    isNotValid: PropTypes.bool,
    fieldMain: PropTypes.string,
    classesInput: PropTypes.array,
    id: PropTypes.string
};

export default CustomSelect;
