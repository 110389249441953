import React from 'react';
import PropTypes from 'prop-types';
import CarBasicNumber from '../CarBasicNumber';
import createNewItemForCar from '../../utils/createNewItemForCar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import style from './CardCar.module.scss';

const CardCar = ({ oneCar }) => {
    const newCar = createNewItemForCar(oneCar);

    const { id, model, mark, region, licensePlate, photo } = newCar;
    const link = `/svbook-details/${newCar.id}`;
    return (
        <Link to={link}>
            <div className={style.svCar} key={id}>
                <div className={style.cardSvH}>
                    <img src={_.get(photo, '0.url')} alt='' />
                </div>
                <div className={style.cardSvB}>
                    <div className={style.cardSvBH2}>{ mark || '' } { model || '' }</div>
                    <CarBasicNumber regNumberCar={licensePlate ? licensePlate.split(' ').join('').slice(0, 6) : licensePlate} regNumberCarEnd={region ? String(region) : '  '} />
                </div>
            </div>
        </Link>
    );
};

CardCar.propTypes = {
    oneCar: PropTypes.object
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(CardCar);
