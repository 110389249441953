import React from 'react';
import SelectPostFilter from '../SelectPostFilter';
import SheetInspectColumnNames from '../SheetInspectColumnNames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import style from './SheetInspectNodesRow.module.scss';

// создаем таблицу листов осмотра с узлами
const SheetInspectNodesRow = ({ nodes, optsState, optsRecommend, nameColumnTable }) => {
    const onSelectChange = (selectedItem, nameStr) => {
        // must save and push to database
    };

    const items = nodes.map((item) => {
        return (
            <tr className={`${style.trBody} ${style.trHead}`} key={item.name}>
                <td className={`${style.thFirst} ${style.nodesFont} ${style.paddingBody}`}>
                    <img src={item.img} alt={item.name} />
                    <span>{ item.value }</span>
                </td>
                <td className={style.thOther}>
                    <SelectPostFilter
                        arrOpts={optsState}
                        onSelectChange={onSelectChange}
                        nameTd={item.name}
                    />
                </td>
                <td className={style.thOther}>
                    <SelectPostFilter
                        arrOpts={optsRecommend}
                        onSelectChange={onSelectChange}
                        nameTd={item.name}
                    />
                </td>
            </tr>
        );
    });

    return (
        <table className={style.tableInspectNodes}>
            <thead>
                <SheetInspectColumnNames arrHeaders={nameColumnTable} />
            </thead>
            <tbody>{ items }</tbody>
        </table>
    );
};

const mapStateToProps = ({ templatesOfFrameState: { optsState, optsRecommend, nameColumnTable } }) => {
    return {
        optsState,
        optsRecommend,
        nameColumnTable
    };
};

SheetInspectNodesRow.defaultProps = {
    nodes: []
};

SheetInspectNodesRow.propTypes = {
    nodes: PropTypes.array.isRequired,
    optsState: PropTypes.array.isRequired,
    optsRecommend: PropTypes.array.isRequired,
    nameColumnTable: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(SheetInspectNodesRow);
