import style from '../PreReserveCardTableHeader/PreReserveCardTableHeader.module.scss';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import PropTypes from 'prop-types';
import React from 'react';

/* const fields2 = {
    id: '№',
    createdAt: 'Дата/Время',
    action: 'Действие',
    description: 'Описание',
    user: 'Кто изменил',
    origin: 'Канал'
}; */

export const PreReserveCardTableBody = ({ events, timezone, field = 'title' }) => {
    return (
        events?.reverse().map((event) => {
            return (
                <div key={event.id || new Date().toISOString()} className={style.prereserve}>
                    <div className={style.id}>{ event.id }</div>
                    <div className={style.date + ' ' + style.column}>
                        <div>{ format(new Date(event?.createdAt), 'dd.MM.yyyy', { locale: ru, timeZone: timezone }) }</div>
                        <div>{ format(new Date(event?.createdAt), 'HH:mm', { locale: ru, timeZone: timezone }) }</div>
                    </div>
                    <div className={style.action}>
                        { event?.bpmnStep ? event?.bpmnStep[field] : event[field] }
                    </div>
                    <div className={style.channel}>
                        { (event?.owner && event?.owner.name) || 'Системное изменение' }
                    </div>
                </div>
            );
        })

    );
};

PreReserveCardTableBody.propTypes = {
    events: PropTypes.array,
    timezone: PropTypes.string,
    preReserve: PropTypes.object
};

export default PreReserveCardTableBody;
