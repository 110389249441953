import React from 'react';
import PropTypes from 'prop-types';

import style from './SvBasicHeader.module.scss';

const SvBasicHeader = ({ header }) => {
    return (
        <div className={style.carBasicHeader}>{ header }</div>
    );
};

SvBasicHeader.propTypes = {
    header: PropTypes.string.isRequired
};

export default SvBasicHeader;
