import React from 'react';

import style from './ButtonSearchClear.module.scss';
import PropTypes from 'prop-types';

const ButtonClear = ({ textBtn, active, classes = [], handleClick = () => {} }) => {
    return (
        <button
            className={style.btnSearchClear + ' ' + (active ? '' : style.notActive) + ' ' + classes.map(item => ' ' + style[item]).join('')}
            onClick={handleClick}
        >
            { textBtn }
        </button>
    );
};

ButtonClear.propTypes = {
    textBtn: PropTypes.string,
    active: PropTypes.bool,
    classes: PropTypes.array,
    handleClick: PropTypes.func
};

export default ButtonClear;
