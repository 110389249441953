import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPostCarOil } from 'actions';
import ButtonsCommon from '../../../../../../components/Buttons/ButtonsCommon';

const OilsIsForTime = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fetchPostCarOil,
    carOilUpdateError,
    carOilIsForTimeLoadSuccess
}) => {
    const fieldName = 'is_for_time';
    const [value, setValue] = useState(carDetailsInfo.is_for_time);

    useEffect(() => {
        setValue(carDetailsInfo.is_for_time);
    }, [carDetailsInfo.is_for_time]);

    const handleUpdate = (value, fieldName) => {
        const post = {
            car_id: id,
            [fieldName]: +value
        };

        if (id && !carOilIsForTimeLoadSuccess) {
            fetchPostCarOil(post, clientIdCollect);
        }
    };

    const handleClick = (itBool) => {
        setValue(itBool);
        handleUpdate(itBool, fieldName);
    };

    return (
        <ButtonsCommon
            key={`key${fieldName}`}
            btnSelected={!!value}
            countButtons={2}
            dataSuccess={carOilIsForTimeLoadSuccess}
            handleClick={handleClick}
            isError={carOilUpdateError}
            classes={['btnStretchCycle', 'height32', 'alignSelfEnd', 'height40']}
            buttonsName={['Время', 'Пробег']}
        />
    );
};

OilsIsForTime.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    carOilIsForTimeLoadSuccess: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilUpdateError, carOilIsForTimeLoadSuccess, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        carOilIsForTimeLoadSuccess,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilsIsForTime);
