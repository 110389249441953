import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DateEndInsurance from '../DateEndInsurance/DateEndInsurance';
import { fetchPostCarInsuranceDeadline } from '../../actions';
import { createDateForSaveFromDatePicker } from '../../utils';

const SetCarDetailsDate = ({
    carIdCollect,
    valueField,
    propCar,
    idName,
    nameForm,
    fetchPostCarInsuranceDeadline,
    carInsuranceDeadlineUpdateError,
    clientIdCollect,
    classes
}) => {
    const [valueForm, setValueForm] = useState(valueField);

    useEffect(() => {
        setValueForm(valueField && valueField);
    }, [valueField, carIdCollect]);

    if (carInsuranceDeadlineUpdateError) {
        return <div>Something is wrong</div>;
    }

    const handleUpdate = (valueUp) => {
        const payload = {
            id: carIdCollect,
            [propCar]: createDateForSaveFromDatePicker(valueUp)
        };
        carIdCollect && valueUp && fetchPostCarInsuranceDeadline(payload, clientIdCollect);
    };

    return (
        <DateEndInsurance
            nameForm={nameForm}
            valueField={valueForm}
            forUpdateField={handleUpdate}
            labelName={nameForm}
            idName={idName}
            id={propCar}
            classes={classes}
        />
    );
};

SetCarDetailsDate.propTypes = {
    classes: PropTypes.array,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    valueField: PropTypes.any,
    propCar: PropTypes.string,
    idName: PropTypes.string,
    nameForm: PropTypes.string,
    fetchPostCarInsuranceDeadline: PropTypes.func,
    carInsuranceDeadlineUpdateError: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carInsuranceDeadlineUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carInsuranceDeadlineUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarInsuranceDeadline })(SetCarDetailsDate);
