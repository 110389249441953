import React, { useState, useEffect } from 'react';
import style from './CheckToggle.module.scss';
import PropTypes from 'prop-types';

const ToggleRadio = ({ id, text, handleClickActive, isActive = false, classes = [] }) => {
    const [active, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(isActive);
    }, [isActive]);

    const handleToggleActive = () => {
        setIsActive(active => !active);
        handleClickActive(!active, id);
    };

    return (
        <div className={style.radioBtnCollected + ' ' + (active ? style.activeUnCollect : '') + classes.map(item => ' ' + style[item]).join('')} key={id} onClick={handleToggleActive}>
            <div className={style.radioBtn}>
                <div className={style.radioCircle} />
            </div>
            <div className={style.radioText}>{ text }</div>
        </div>
    );
};

ToggleRadio.propTypes = {
    isActive: PropTypes.bool,
    text: PropTypes.string,
    id: PropTypes.string,
    handleClickActive: PropTypes.func,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['circle', 'small', 'withoutBackground', 'light', 'loaderTop', 'gray', 'violetWithoutLoader', 'columnTextFirst']))
};

export default ToggleRadio;
