import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, withApiService } from '../../Hoc';
import { resetCarMileage, resetCarId, fetchCarServiceBook, selectedIdCarLoaded } from '../../actions';
import { SvBookDetailsHeader } from '../MiddleHeader';
import Mileage from 'routes/SvBookDetails/Mileage';
import PropTypes from 'prop-types';
import SvBookDetails from '../../routes/SvBookDetails/SvBookDetails';
import { Switch, Route } from 'react-router-dom';
import LoadingIndicatorBlock from '../LoadingIndicator/LoadingIndicatorBlock';
import SvBookCollectContainer from '../../routes/SvBookDetails/SvBookCollectContainer/SvBookCollectContainer';

const GetCarDetails = ({ id, selectedIdCarLoaded, carId, fetchCarServiceBook, resetCarMileage, resetCarId, carSvBookSuccess, carSvBookError }) => {
    useEffect(() => {
        id && selectedIdCarLoaded(id);
    }, [id, selectedIdCarLoaded]);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, [carId]);

    useEffect(() => {
        return () => {
            resetCarMileage();
            resetCarId();
        };
    }, [resetCarMileage, resetCarId]);

    useEffect(() => {
        carId && fetchCarServiceBook(carId);
    }, [carId, fetchCarServiceBook]);

    if (carSvBookSuccess) {
        return <LoadingIndicatorBlock reasonLoader={carSvBookSuccess} />;
    }

    if (carSvBookError) {
        return <div>Авто с таким номером не найдены</div>;
    }

    return (
        <>
            <SvBookDetailsHeader />
            <Switch>
                <Route path='/svbook-details/:id' component={SvBookDetails} exact />
                <Route path='/svbook-details/:id/mileage' component={Mileage} />
                <Route path='/svbook-details/:id/collect' render={() => <SvBookCollectContainer carIdRaw={id} />} />
            </Switch>
        </>
    );
};

GetCarDetails.propTypes = {
    fetchCarServiceBook: PropTypes.func,
    resetCarMileage: PropTypes.func.isRequired,
    carId: PropTypes.number,
    resetCarId: PropTypes.func,
    carSvBookSuccess: PropTypes.bool,
    carSvBookError: PropTypes.bool,
    id: PropTypes.number,
    selectedIdCarLoaded: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carId, carSvBookSuccess, carSvBookError } }) => {
    return {
        carId,
        carSvBookSuccess,
        carSvBookError
    };
};

const mapDispatchToProps = {
    fetchCarServiceBook,
    resetCarMileage,
    resetCarId,
    selectedIdCarLoaded
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(GetCarDetails);
