import React, { useEffect } from 'react';
import CollectionSheetName from '../../../../../../components/CollectionSheetName';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCollectionSheetHistory } from '../../../../../../reducers/reportsCollectionSheets';
import HistoryReportBody from '../HistoryReportBody';
import LoadingIndicatorBlock from '../../../../../../components/LoadingIndicator/LoadingIndicatorBlock';

const HistoryReportBodyWrap = ({ getCollectionSheetHistory, collectionSheetHistory, collectionSheetDetails, carId, clientId, collectionSheetSuccess }) => {
    useEffect(() => {
        getCollectionSheetHistory(carId, clientId);
    }, [getCollectionSheetHistory, carId, clientId]);

    const part = (
        <>
            <CollectionSheetName collectionSheetDetails={collectionSheetDetails} />
            <HistoryReportBody historyReports={collectionSheetHistory} loading={collectionSheetSuccess} />
        </>
    );

    return (
        <>
            {
                collectionSheetSuccess
                    ? <LoadingIndicatorBlock reasonLoader={collectionSheetSuccess} />
                    : ''
            }
            { part }
        </>
    );
};

HistoryReportBodyWrap.propTypes = {
    collectionSheetHistory: PropTypes.array,
    carId: PropTypes.number,
    clientId: PropTypes.number,
    getCollectionSheetHistory: PropTypes.func,
    collectionSheetDetails: PropTypes.object,
    collectionSheetSuccess: PropTypes.bool
};

const mapStateToProps = ({ reportsCollectionSheets: { collectionSheetHistory, collectionSheetDetails, collectionSheetSuccess } }) => {
    return {
        collectionSheetHistory,
        collectionSheetDetails,
        collectionSheetSuccess
    };
};

export default connect(mapStateToProps, { getCollectionSheetHistory })(HistoryReportBodyWrap);
