import React, { useEffect, useState } from 'react';
import style from '../../SheetCollectBody.module.scss';
import HeadAnchors from '../HeadAnchors';
import MileageHeader from '../../../../../components/MileageHeader/MileageHeader';
import BlocksWrap from '../BlocksWrap';
import SectionWrap from '../../../../../layouts/SectionWrap';
import MileageCollect from '../MileageCollect';
import PropTypes from 'prop-types';
import { MILEAGE } from '../../../../../constants/CarCollectBlocks';

const MileageBlock = ({ carDetailsInfo, block, focusMileage }) => {
    const [isHiddenMileage, setIsHiddenMileage] = useState(true);

    useEffect(() => {
        focusMileage && setIsHiddenMileage(!focusMileage);
    }, [focusMileage]);

    const handleSetHiddenMileage = () => {
        setIsHiddenMileage(s => !s);
    };

    const handleClose = () => {
        setIsHiddenMileage(true);
    };

    const mileageCollect = (
        <SectionWrap>
            <MileageCollect mileage={carDetailsInfo?.mileage} focusMileage={focusMileage} handleClose={handleClose} />
        </SectionWrap>
    );

    return (
        <BlocksWrap KeyName='mileageCollect' id='anchorMileage'>
            <>
                <HeadAnchors
                    bgStyle={style.headMileage}
                    textHeader={block?.title || 'Пробег'}
                    id={block?.id || MILEAGE}
                    handleSetHidden={handleSetHiddenMileage}
                    stateBool={isHiddenMileage}
                    passedHeader={<MileageHeader carDetailsInfo={carDetailsInfo} />}
                    withoutCheckToggle
                />
                {
                    isHiddenMileage
                        ? ''
                        : mileageCollect
                }
            </>
        </BlocksWrap>
    );
};

MileageBlock.propTypes = {
    carDetailsInfo: PropTypes.object,
    block: PropTypes.object,
    focusMileage: PropTypes.bool
};

export default MileageBlock;
