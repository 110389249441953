import React from 'react';
import PropTypes from 'prop-types';
import { initialTimeRangeProlongation } from '../../../constants/initialTimeRangeProlongation';
import { dateRangeFromTo } from '../../../utils/dateRangeFromTo';
import { GrayButton } from '../../Buttons';

const FilterRangesTimes = ({
    id,
    handleChangeRange,
    isActive,
    handleSetActive,
    objectRange,
    classes = []
}) => {
    const handleOnClick = () => {
        if (isActive) {
            handleChangeRange(initialTimeRangeProlongation);
            handleSetActive(objectRange.tag);
        } else {
            const { from, to } = dateRangeFromTo(objectRange.tag);
            handleChangeRange({
                timeFrom: from,
                timeTo: to
            });
            handleSetActive(objectRange.tag);
        }
    };

    return (
        <>
            <GrayButton
                id={id}
                handlerClick={handleOnClick}
                text={objectRange.name}
                classes={classes.includes('isActive') ? ['small', 'marginRight16', 'blueBg', 'showMore', 'minWidth', 'bgTransparent'] : ['marginRight16', 'small', 'showMore', 'minWidth', 'bgTransparent']}
            />
        </>
    );
};

FilterRangesTimes.propTypes = {
    objectRange: PropTypes.object,
    handleChangeRange: PropTypes.func,
    handleSetActive: PropTypes.func,
    isActive: PropTypes.bool,
    id: PropTypes.string,
    classes: PropTypes.array
};

export default FilterRangesTimes;
