import React, { useState } from 'react';
import PropTypes from 'prop-types';

import style from './StatusHistory.module.scss';
import HeaderWithArrow from '../../../../../components/HeaderWithArrow';

const StatusHistory = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div className={`${style.carBasicInfo} ${style.svColumn}`}>
            <HeaderWithArrow text='Статусы пользования авто' handleClick={() => setIsOpen(s => !s)} rotate={!isOpen} />
            {
                isOpen && children
            }
        </div>
    );
};

StatusHistory.propTypes = {
    children: PropTypes.node
};

export default StatusHistory;
