import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { clientIdCollectLoaded } from '../../actions';
import { NavLink } from 'react-router-dom';
import style from './CarAndVisitor.module.scss';
import { getMainClient, formatNumber } from '../../utils';
import CarNumber from '../CarNumber';

const CarAndVisitor = ({ carDetailsInfo, fetchCarDetailsSuccess, clientIdCollect, clientIdCollectLoaded }) => {
    const { mark, model, licensePlate, region } = carDetailsInfo;

    const mainClient = useMemo(() => {
        return getMainClient(clientIdCollect, carDetailsInfo);
    }, [clientIdCollect, carDetailsInfo]);

    useEffect(() => {
        !clientIdCollect && !carDetailsInfo?.clientId && mainClient?.id && clientIdCollectLoaded(mainClient.id);
    }, [clientIdCollect, mainClient, carDetailsInfo, clientIdCollectLoaded]);

    return (
        <div className={`${style.carAndVisitorInfo} ${style.listLabel}`}>
            <NavLink to={`/visitors/${mainClient?.id || ''}`} exact>
                <p>{ mainClient?.companyNick || 'Нет данных' }</p>
            </NavLink>
            <NavLink to={`/visitors/${mainClient?.id || ''}`} exact>
                <p>{ !fetchCarDetailsSuccess ? (formatNumber(mainClient?.phone) || ' нет данных') : <LoadingIndicator /> }</p>
            </NavLink>
            <NavLink to={`/svbook-details/${carDetailsInfo ? carDetailsInfo.id : ''}`} exact>
                {
                    fetchCarDetailsSuccess
                        ? ''
                        : [
                            <p key={`${mark || 'Нет данных'} ${model || ''}`}>
                                { mark || 'Нет данных' } { model || '' }
                            </p>,
                            <span className={style.span} key={licensePlate + '_span'}><CarNumber number={licensePlate} region={region} /></span>
                        ]
                }
            </NavLink>
        </div>
    );
};

CarAndVisitor.propTypes = {
    carDetailsInfo: PropTypes.object,
    fetchCarDetailsSuccess: PropTypes.bool,
    clientIdCollectLoaded: PropTypes.func,
    clientIdCollect: PropTypes.number
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, fetchCarDetailsSuccess, clientIdCollect } }) => {
    return {
        carDetailsInfo,
        fetchCarDetailsSuccess,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { clientIdCollectLoaded })(CarAndVisitor);
