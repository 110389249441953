import React, { useState, useEffect } from 'react';
import InputElement from 'react-input-mask';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { postMileage, updateMileageError, setMileage, setKmMileage } from '../../../../../reducers/car';
import ButtonsCommon from '../../../../../components/Buttons/ButtonsCommon';
import { MILES_IN_KILOMETERS } from '../../../../../utils/constants';
import ErrorMessageLine from '../../../../../components/ErrorMessageLine';
import { GrayButton } from '../../../../../components/Buttons';
import MessageForForm from '../../../../../components/MessageFoForm';
import { spaceInNumber } from '../../../../../utils';
import { handleSetFocusMileage } from '../../../../../reducers/preReserve';
import styleInput from '../Anchors/OilFields/OilChangeType.module.scss';
import style from './MileageCollect.module.scss';

const MileageCollect = ({
    mileage,
    carIdCollect,
    postMileage,
    isUpdateMileageSuccess,
    isUpdateMileageError,
    updateMileageError,
    setMileage,
    setKmMileage,
    clientIdCollect,
    focusMileage,
    handleSetFocusMileage,
    handleClose,
    classes = []
}) => {
    const [value, setValue] = useState('');
    const [selectedButton, setSelectedButton] = useState(true);
    const [isValid, setIsValid] = useState(true);

    const handleUpdateValue = (selectedButton = true) => {
        setSelectedButton(selectedButton);
        const result = selectedButton ? +value : Math.round(+value / MILES_IN_KILOMETERS);
        result && clientIdCollect && postMileage(carIdCollect, result, clientIdCollect);
        handleClose();
        setValue('');
        setSelectedButton(true);
    };

    useEffect(() => {
        const result = selectedButton ? +value : Math.round(+value / MILES_IN_KILOMETERS);
        setIsValid(result === 0 || result > mileage);
    }, [mileage, selectedButton, value]);

    useEffect(() => {
        return () => setMileage({ mileage: 0, isKm: true });
    }, [setMileage, carIdCollect]);

    useEffect(() => {
        return () => updateMileageError(false);
    }, [updateMileageError]);

    useEffect(() => {
        updateMileageError(false);
    }, [updateMileageError, carIdCollect]);

    const handleChange = (e) => {
        const val = e.replace(/[^0-9.]/g, '');
        setValue('' + val);
    };

    const handleChoose = (e) => {
        setSelectedButton(e);
        setKmMileage(e);
    };

    return (
        <div className={style.mileageCollectWrap}>
            <div className={style.mileageCollect}>
                <div id='' className={style.text}>Пробег авто</div>
                <div>
                    <InputElement
                        key='inputMileageCollect'
                        id='inputMileageCollectId'
                        className={styleInput.inputGray + ' font16' + (classes ? [...classes, (isUpdateMileageError || !isValid) ? 'borderRed' : ''].map(item => ' ' + styleInput[item]).join('') : '')}
                        name='ownerPhones'
                        value={spaceInNumber(value)}
                        onChange={(e) => handleChange(e.target.value)}
                        autoFocus={focusMileage}
                        onBlur={() => handleSetFocusMileage(false)}
                        disabled={isUpdateMileageSuccess}
                    />
                    {
                        isUpdateMileageError
                            ? <ErrorMessageLine refNode='inputMileageCollectId' isError={isUpdateMileageError} />
                            : ''
                    }
                </div>
                {
                    !isUpdateMileageError && (
                        <ButtonsCommon
                            countButtons={2}
                            buttonsName={['Км', 'Миль']}
                            key='key_mileage'
                            btnSelected={selectedButton}
                            dataSuccess={isUpdateMileageSuccess}
                            handleClick={handleChoose}
                            isError={isUpdateMileageError}
                            classes={['mileage']}
                        />
                    )
                }
            </div>
            {
                !isValid && <MessageForForm message='Введенный пробег меньше прошлого' classes={['maxWidthStretch', 'height40', 'paddingLeft']} />
            }
            <div className={style.buttonWrap + ' ' + (!isValid && style.withoutPaddingTop)}>
                <GrayButton
                    id='save_mileage_button'
                    text='Сохранить пробег'
                    handlerClick={() => handleUpdateValue(selectedButton)}
                    classes={value ? ['bgGreen'] : ['bgGrey']}
                />
            </div>
        </div>
    );
};

MileageCollect.propTypes = {
    isUpdateMileageSuccess: PropTypes.bool,
    isUpdateMileageError: PropTypes.bool,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    postMileage: PropTypes.func,
    updateMileageError: PropTypes.func,
    setMileage: PropTypes.func,
    setKmMileage: PropTypes.func,
    mileage: PropTypes.number,
    handleSetFocusMileage: PropTypes.func,
    handleClose: PropTypes.func,
    classes: PropTypes.array,
    focusMileage: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect }, car: { isUpdateMileageSuccess, isUpdateMileageError }, common: { error422 } }) => {
    return {
        carIdCollect,
        clientIdCollect,
        isUpdateMileageSuccess,
        isUpdateMileageError,
        error422
    };
};

export default connect(mapStateToProps, { postMileage, updateMileageError, setMileage, setKmMileage, handleSetFocusMileage })(MileageCollect);
