import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    deleteClientFromCar,
    getAllStatusesCarClients,
    postCreateNewClient,
    searchNewClient,
    setStatusesCarClient
} from 'reducers/statusesClientCar';
import style from './StatusesWrap.module.scss';
import Statuses from '../StatusesWithValidate/Statuses';
import Loader from '../Loader';
import { getAllStatusesLabels, isEmpty, validateBlock } from '../../utils';
import { STANDARD_COMPLETELY_CONTAINED_IN_DATA } from '../../constants/validationRules';

const StatusesWrap = ({
    carDetailsInfo,
    getAllStatusesCarClients,
    statuses,
    classes,
    setStatusesCarClient,
    searchNewClient,
    postCreateNewClient,
    isCreateNewClientInCarSuccess,
    changeClientStatusesSuccess,
    isLoadSearchClient,
    searchInfo,
    foundClients,
    deleteClientFromCar
}) => {
    const [validObj, setValidObj] = useState({});
    const [withoutStatuses, setWithoutStatuses] = useState([]);

    const handleDelete = (clientId) => {
        carDetailsInfo?.id && clientId && deleteClientFromCar(carDetailsInfo.id, clientId);
    };

    useEffect(() => {
        (!statuses || statuses?.length === 0) && getAllStatusesCarClients();
    }, [getAllStatusesCarClients, statuses]);

    useEffect(() => {
        const carClientsStatuses = carDetailsInfo.clients;
        if (carClientsStatuses && carClientsStatuses.length > 0) {
            const statusesNeeded = statuses.length > 0 ? statuses.map(item => item.label) : [];
            const clientWithStatusesIds = [...new Set(carDetailsInfo?.statuses?.filter(item => statusesNeeded.includes(item.client_status.label)).map(item => item.client_id))];
            setWithoutStatuses(carClientsStatuses.filter(item => !clientWithStatusesIds.includes(item.id)) || []);
            const res = statusesNeeded.length > 0 ? validateBlock(getAllStatusesLabels(carClientsStatuses), statusesNeeded, STANDARD_COMPLETELY_CONTAINED_IN_DATA) : {};
            !isEmpty(res) && setValidObj(res);
        }
    }, [carDetailsInfo, statuses]);

    const updateStatuses = (dataPost, isAddNew = false) => {
        if (isAddNew) {
            postCreateNewClient({ ...dataPost, car_id: carDetailsInfo.id });
        } else {
            setStatusesCarClient({ ...dataPost, carId: carDetailsInfo.id });
        }
    };

    return (
        <div className={style.blockWrap}>
            {
                isCreateNewClientInCarSuccess || changeClientStatusesSuccess
                    ? <div className={style.btnLoad}><Loader active={isCreateNewClientInCarSuccess || changeClientStatusesSuccess} classes={['withoutBackground', 'grayLight']} /></div>
                    : ''
            }
            <Statuses
                carClients={carDetailsInfo?.clients || []}
                withoutStatuses={withoutStatuses}
                handleUpdateStatuses={updateStatuses}
                handleDeleteClient={handleDelete}
                handleAddClient={(data) => updateStatuses(data, true)}
                handleUpdateClient={() => {}}
                statusesConfig={statuses}
                searchNewClient={searchNewClient}
                carId={carDetailsInfo?.id}
                validObj={validObj}
                isLoadSearchClient={isLoadSearchClient}
                searchInfo={searchInfo}
                foundClients={foundClients}
                classes={classes}
            />
        </div>
    );
};

StatusesWrap.propTypes = {
    carDetailsInfo: PropTypes.object,
    getAllStatusesCarClients: PropTypes.func,
    deleteClientFromCar: PropTypes.func,
    statuses: PropTypes.array,
    classes: PropTypes.array,
    isLoadSearchClient: PropTypes.bool,
    postCreateNewClient: PropTypes.func,
    setStatusesCarClient: PropTypes.func,
    searchNewClient: PropTypes.func,
    isCreateNewClientInCarSuccess: PropTypes.bool,
    searchInfo: PropTypes.object,
    changeClientStatusesSuccess: PropTypes.bool,
    foundClients: PropTypes.array
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo }, statusesClientCar: { statuses, foundClients, isLoadSearchClient, searchInfo, isCreateNewClientInCarSuccess, changeClientStatusesSuccess } }) => {
    return {
        carDetailsInfo,
        statuses,
        isCreateNewClientInCarSuccess,
        changeClientStatusesSuccess,
        searchInfo,
        isLoadSearchClient,
        foundClients
    };
};

export default connect(mapStateToProps, { getAllStatusesCarClients, setStatusesCarClient, searchNewClient, postCreateNewClient, deleteClientFromCar })(StatusesWrap);
