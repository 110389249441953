import React, { useEffect, useState } from 'react';
import style from './inviteToOrtusButton.module.scss';
import _ from 'lodash';
import { sendInvite, fetchActivity } from '../../reducers/visitors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../Loader';

const InviteToOrtusButton = ({ carDetailsInfo, activity, sendInvite, fetchActivity, target, loader, clientIdCollect }) => {
    const [invited, setInvited] = useState(false);
    const [client, setClients] = useState({});

    useEffect(() => {
        if (target) {
            setClients(target);
        } else {
            const clientRaw = carDetailsInfo.clients && carDetailsInfo.clients.length > 0 &&
                carDetailsInfo.clients.find(item => item.id === clientIdCollect);
            clientRaw && (clientRaw.id !== client.id) && setClients(clientRaw);
        }
    }, [target, carDetailsInfo, clientIdCollect, client]);

    useEffect(() => {
        if (client?.id) {
            fetchActivity(client.id);
        }
    }, [fetchActivity, client.id]);

    const inviteToOrtus = () => {
        sendInvite(client && client.id);
        setInvited(true);
    };

    if (activity && !_.isEmpty(activity.lastActivity)) {
        return (
            <div className={style.activated}>
                <p className={style.buttonText}>установлен</p>
            </div>
        );
    } else {
        if ((client && client.is_activated) || invited) {
            return (
                <div
                    className={`${style.mainContainer} ${style.invited}`}
                >
                    <p className={style.buttonText}>Приглашение отправлено</p>
                </div>
            );
        } else {
            return (
                <>
                    <div
                        onClick={inviteToOrtus}
                        className={style.mainContainer}
                    >
                        <p className={style.buttonText}>Пригласить Ortus</p>
                    </div>
                    <Loader active={loader} classes={['loaderTop', 'gray']} />
                </>
            );
        }
    }
};

InviteToOrtusButton.propTypes = {
    carDetailsInfo: PropTypes.object,
    loader: PropTypes.bool,
    target: PropTypes.object,
    activity: PropTypes.any,
    sendInvite: PropTypes.func,
    clientIdCollect: PropTypes.number,
    fetchActivity: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, clientIdCollect }, visitors: { activity, loader } }) => {
    return {
        carDetailsInfo,
        activity,
        clientIdCollect,
        loader
    };
};

export default connect(mapStateToProps, { sendInvite, fetchActivity })(InviteToOrtusButton);
