import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import style from '../ArrowHiddenContent/ArrowHiddenContent.module.scss';
import styleParent from '../../routes/SheetCollect/SheetCollectBody/SheetCollectBody.module.scss';

const ArrowRender = ({ handleHidden, arrowStyle, classes = [] }) => {
    return (
        <div className={style.rightHead + classes.map(item => ' ' + style[item]).join('')} onClick={handleHidden}>
            <div className={arrowStyle} />
        </div>
    );
};

ArrowRender.propTypes = {
    handleHidden: PropTypes.func,
    arrowStyle: PropTypes.string,
    classes: PropTypes.array
};

const ArrowHiddenContent = ({ handleSetHidden, stateBool, blockId, classes }) => {
    const [isHidden, setIsHidden] = useState(false);
    const [arrowStyle, setArrowStyle] = useState(styleParent.arrowDown);

    useEffect(() => {
        setIsHidden(false);
        setIsHidden(stateBool);
    }, [blockId, stateBool]);

    useEffect(() => {
        setArrowStyle(isHidden ? styleParent.arrowUp : styleParent.arrowDown);
    }, [isHidden]);

    const handleHidden = () => {
        setIsHidden(s => !s);
        handleSetHidden(!isHidden);
    };

    return (
        <ArrowRender handleHidden={handleHidden} arrowStyle={arrowStyle} classes={classes} />
    );
};

ArrowHiddenContent.propTypes = {
    handleSetHidden: PropTypes.func,
    classes: PropTypes.array,
    blockId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    stateBool: PropTypes.bool
};

export default ArrowHiddenContent;
