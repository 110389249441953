import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import style from './InviteFrom1C.module.scss';
import { GrayButton } from '../../../../../components/Buttons';

const InviteFrom1C = ({ text, inviteDate, buttonText, handleUpdateNextOil }) => {
    return (
        <div className={style.from1C}>
            <div className={style.text}>Якоря 1C: { text } - { format(new Date(inviteDate), 'dd.MM.yy') }</div>
            <GrayButton text={buttonText} handlerClick={handleUpdateNextOil} />
        </div>
    );
};

InviteFrom1C.propTypes = {
    inviteDate: PropTypes.string,
    text: PropTypes.string,
    buttonText: PropTypes.string,
    handleUpdateNextOil: PropTypes.func
};

export default InviteFrom1C;
