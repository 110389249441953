import React, { useEffect } from 'react';
import { OwnerSettingsHeader } from './SettingsHeader';
import { OtherSettings } from './OtherSettings';
import { OwnerSettingsBody } from './OwnerSettingsBody';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    fetchOwners,
    loadingOwnersCompany,
    setErrorOwners,
    fetchRoles
} from '../../reducers/settings';
import Owner from './OwnerSettingsBody/Owner';
import Handbooks from './Handbooks';
import { Connection } from './Connection';

const OwnerSettings = ({
    fetchOwners,
    setErrorOwners,
    loadingOwnersCompany,
    fetchRoles,
    roles
}) => {
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        fetchOwners();
    }, [fetchOwners]);

    useEffect(() => {
        (roles.length === 0) && fetchRoles();
    }, [roles, fetchRoles]);

    useEffect(() => {
        return () => {
            loadingOwnersCompany(false);
            setErrorOwners(false);
        };
    }, [loadingOwnersCompany, setErrorOwners]);

    return (
        <div>
            <OwnerSettingsHeader />
            <Switch>
                <Route path='/settings/users-owners' component={OwnerSettingsBody} exact />
                <Route path='/settings/other' component={OtherSettings} />
                <Route
                    path='/settings/owner/:id' render={({ match }) => {
                        const { id } = match.params;
                        return <Owner id={id ? +id : 0} />;
                    }}
                />
                <Route path='/settings/handbooks' component={Handbooks} />
                <Route path='/settings/1cconnection' component={Connection} />
            </Switch>
        </div>
    );
};

OwnerSettings.propTypes = {
    fetchOwners: PropTypes.func,
    loadingOwnersCompany: PropTypes.func,
    setErrorOwners: PropTypes.func,
    roles: PropTypes.array,
    fetchRoles: PropTypes.func
};

const mapStateToProps = ({ settings: { ownersCompany, fetchOwnersCompanySuccess, roles } }) => {
    return {
        ownersCompany,
        fetchOwnersCompanySuccess,
        roles
    };
};

export default connect(mapStateToProps, { fetchOwners, loadingOwnersCompany, setErrorOwners, fetchRoles })(OwnerSettings);
