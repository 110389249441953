import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_1C_SERVICES = 'request1CServices';
const RECEIVE_1C_SERVICES = 'receive1CServices';
const REQUEST_ORTUS_SERVICES = 'requestOrtusServices';
const RECEIVE_ORTUS_SERVICES = 'receiveOrtusServices';
const REQUEST_LINKS = 'requestLinks';
const RECEIVE_LINKS = 'receiveLinks';
const RECEIVE_DELETE_LINKS = 'receiveDeleteLinks';
const RECEIVE_MAKE_LINKS = 'receiveMakeLinks';
const REQUEST_CATALOGUES = 'requestCatalogues';
const RECEIVE_CATALOGUES = 'receiveCatalogues';

// ------------------------------------
// Actions
// ------------------------------------

export const fetch1CServices = () => (dispatch, getState, { apiService }) => {
    dispatch(request1CServices());
    return apiService.fetchOneServices()
        .then((res) => {
            dispatch(receive1CServices(res));
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const fetchOrtusServices = (id) => (dispatch, getState, { apiService }) => {
    dispatch(requestOrtusServices());
    return apiService.fetchOrtusCompanyServices(id)
        .then((res) => {
            dispatch(receiveOrtusServices(res));
            return res;
        })
        .catch((err) => {
            return err;
        });
};

export const fetchLinks = (data) => (dispatch, getState, { apiService }) => {
    dispatch(requestLinks());
    return apiService.fetchActiveLinks(data)
        .then(res => {
            dispatch(receiveLinks(res));
        })
        .catch((err) => {
            return err;
        });
};

export const makeLink = (data) => (dispatch, getState, { apiService }) => {
    dispatch(requestLinks());
    return apiService.makeNewLink(data)
        .then(res => {
            const payload = {};
            payload.data = data;
            payload.res = res;
            dispatch(receiveMakeLinks(payload));
        })
        .catch((err) => {
            return err;
        });
};

export const deleteLink = (data) => (dispatch, getState, { apiService }) => {
    dispatch(requestLinks());
    return apiService.deleteActiveLink(data)
        .then(res => {
            const payload = {};
            payload.data = data;
            payload.res = res;
            dispatch(receiveDeleteLinks(payload));
        })
        .catch((err) => {
            return err;
        });
};

export const request1CServices = () => {
    return {
        type: REQUEST_1C_SERVICES
    };
};

function receive1CServices(response) {
    return {
        type: RECEIVE_1C_SERVICES,
        payload: response
    };
}

export const requestOrtusServices = () => {
    return {
        type: REQUEST_ORTUS_SERVICES
    };
};

function receiveOrtusServices(response) {
    return {
        type: RECEIVE_ORTUS_SERVICES,
        payload: response
    };
}

export const requestLinks = () => {
    return {
        type: REQUEST_LINKS
    };
};

function receiveLinks(response) {
    return {
        type: RECEIVE_LINKS,
        payload: response
    };
}

function receiveMakeLinks(payload) {
    return {
        type: RECEIVE_MAKE_LINKS,
        payload: payload
    };
}

function receiveDeleteLinks(payload) {
    return {
        type: RECEIVE_DELETE_LINKS,
        payload: payload
    };
}

export const actions = {
    fetch1CServices,
    fetchOrtusServices,
    fetchLinks,
    makeLink,
    deleteLink
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [REQUEST_1C_SERVICES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_1C_SERVICES]: (state, action) => {
        return ({ ...state, fetching: false, oneServices: action.payload });
    },
    [REQUEST_ORTUS_SERVICES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_ORTUS_SERVICES]: (state, action) => {
        return ({ ...state, fetching: false, ortusServices: action.payload });
    },
    [REQUEST_LINKS]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_LINKS]: (state, action) => {
        return ({ ...state, fetching: false, links: action.payload });
    },
    [RECEIVE_MAKE_LINKS]: (state, action) => {
        const links = _.clone(state.links);
        const newData = action.payload.data[0];
        newData.hash = '';
        if (action.payload.res === 'OK') {
            links.push(newData);
        }
        return ({ ...state, fetching: false, links: links });
    },
    [RECEIVE_DELETE_LINKS]: (state, action) => {
        const links = _.clone(state.links);
        const index = _.findIndex(links, ['angara_id', action.payload.data]);
        if (action.payload.res === 'OK' && index !== -1) {
            links.splice(index, 1);
        }
        return ({ ...state, fetching: false, links: links });
    },
    [REQUEST_CATALOGUES]: (state) => {
        return ({ ...state, fetching: true });
    },
    [RECEIVE_CATALOGUES]: (state, action) => {
        return ({ ...state, fetching: false, catalogues: action.payload });
    }
};

const initialState = {
    fetching: false,
    oneServices: null,
    ortusServices: null,
    links: null,
    catalogues: null
};

export default function connectionReducer(state = initialState, action) {
    state = Object.assign({}, initialState, state.connectionReducer);
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
