import React from 'react';
import PropTypes from 'prop-types';

import style from './HeaderNodeToggle.module.scss';

const HeaderNodeToggle = ({ siHeadField, imageHeader }) => {
    const img = <img src={imageHeader} alt='icon-inspections' />;
    return (
        <div className={style.cardShInspHeader}>
            <div className={style.imgAndH2}>
                { img }
                <span>{ siHeadField }</span>
            </div>
            <div className={style.triangleDownB}> </div>
        </div>
    );
};

HeaderNodeToggle.propTypes = {
    siHeadField: PropTypes.string.isRequired,
    imageHeader: PropTypes.node
};

export default HeaderNodeToggle;
