import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import style from './Navbar.module.scss';
import _ from 'lodash';

const Navbar = ({ menuItemsHeader, location }) => {
    const [itemSelected, setItemSelected] = useState(0);

    const newMenuItemsHeader = JSON.parse(localStorage.partner).role === 'PARTNER_EXTEND' ? menuItemsHeader : [
        { id: 1, title: 'АВТО', url: '/svbooks', isDisabled: false },
        { id: 2, title: 'ОТЧЕТЫ', url: '/reports', isDisabled: false },
        { id: 3, title: 'ГОСТИ', url: '/visitors', isDisabled: false }
    ];

    useEffect(() => {
        menuItemsHeader && !(_.includes(menuItemsHeader.map(item => item.url), location.pathname)) && setItemSelected(0);
    }, [menuItemsHeader, location]);

    const itemsMenu = newMenuItemsHeader.map(({ id, title, url, isDisabled }) => {
        const classSelected = (itemSelected === id) ? style.liSelected : '';
        const classDisabled = isDisabled ? style.liDisabled : '';
        return (
            <li
                className={`${classSelected} ${classDisabled}`}
                key={id}
                onClick={() => setItemSelected(id)}
            >
                <Link to={url}>{ title }</Link>
            </li>
        );
    });

    return (
        <ul className={style.menuUp}>
            { itemsMenu }
        </ul>
    );
};

Navbar.defaultPropTypes = {
    menuItemsHeader: [
        { id: 1, title: 'АВТО', url: '/svbooks', isDisabled: false },
        { id: 2, title: 'ОТЧЕТЫ', url: '/reports', isDisabled: false },
        { id: 3, title: 'ГОСТИ', url: '/visitors', isDisabled: false },
        { id: 4, title: 'НАСТРОЙКИ', url: '/settings/users-owners', isDisabled: false }
    ]
};

Navbar.propTypes = {
    menuItemsHeader: PropTypes.array.isRequired,
    location: PropTypes.object
};

const mapStateToProps = ({ templatesOfFrameState: { menuItemsHeader } }) => {
    return {
        menuItemsHeader
    };
};

export default connect(mapStateToProps)(withRouter(Navbar));
