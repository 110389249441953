import React from 'react';
import { ButtonSavePushClose } from '../../components/Buttons';
import SheetInspectCard from '../../components/SheetInspectCard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import style from './SheetInspectionsBody.module.scss';

// все карточки из Листа Осмотра
const SheetInspectionsBody = ({ siHeader, nodeFlueArr, carId, sheetInspectionsImagesHeader }) => {
    const sheets = nodeFlueArr.map((item, index) => {
        return (
            <SheetInspectCard
                key={index}
                siHeaderOne={siHeader[index]}
                nodeFlue={item}
                imagesHeader={sheetInspectionsImagesHeader[index]}
            />
        );
    });

    return (
        <div className={`${style.sheetInspectionsBody} ${style.wrapMiddle}`}>
            {
                sheets
            }
            <ButtonSavePushClose />
        </div>
    );
};

SheetInspectionsBody.defaultProps = {
    siHeader: ['Жидкости', 'Расходники', 'Ходовая часть', 'Шины и диски', 'Аккумулятор', 'Охрана', 'Кузов', 'Салон', 'Двигатель'],
    nameColumnTable: [
        'Узел',
        'Состояние',
        'Рекомендации'
    ]
};

SheetInspectionsBody.propTypes = {
    siHeader: PropTypes.array.isRequired,
    nodeFlueArr: PropTypes.array.isRequired,
    carId: PropTypes.number.isRequired,
    sheetInspectionsImagesHeader: PropTypes.array.isRequired
};

const mapStateToProps = ({ carDetailsState: { carId }, templatesOfFrameState: { siHeader, nodeFlueArr, sheetInspectionsImagesHeader } }) => {
    return {
        siHeader,
        nodeFlueArr,
        carId,
        sheetInspectionsImagesHeader
    };
};

export default connect(mapStateToProps)(SheetInspectionsBody);
