import React from 'react';
import style from './Text.module.scss';
import PropTypes from 'prop-types';

const Text = ({ text, children, classes = [] }) => {
    return (
        <div className={style.text + classes.map(item => ' ' + style[item]).join('')}>{ text || children }</div>
    );
};

Text.propTypes = {
    text: PropTypes.string,
    children: PropTypes.string,
    classes: PropTypes.array
};

export default Text;
