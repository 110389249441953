import React from 'react';
import style from './PreReserveHeader.module.scss';
import PropTypes from 'prop-types';
import { InfoInHeader } from '../../../Reports/ReportsBody/components/PreReservesReport/PreReservesReport';
import { PROLONGATION_MODE_TITLES, PROLONGATION_STATUSES } from '../../../../constants/prolongationMode';
import { OPTIONS_PACKETS_PACKET_NAME } from '../../../../constants/packets';
import CarMarkNumber from '../../../../components/CarMarkNumber';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import BlueSpanLink from '../../../../components/BlueSpanLink';
import { getBlockIdByPacketLabel } from '../../../../utils/getBlockIdByLabel';
import { getDataCar } from '../../../../utils/getDataCar';

const RowTitleLabel = ({ title, label, classes = [] }) => {
    return (
        <div className={style.rowTitleLabel + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.label}>{ label }</div>
            <div className={style.title}>{ title }</div>
        </div>
    );
};

RowTitleLabel.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.array
};

const mapperTitles = (arrData, Wrapper, classes) => {
    return arrData.map((item, k) => {
        return <Wrapper key={item.label + (new Date()).toISOString() + k} title={item.title} label={item.label} classes={classes} />;
    });
};

const PreReserveHeader = ({
    preReserve,
    timezone,
    blocks,
    classes = []
}) => {
    const carTitle = preReserve?.car
        ? (
            <div className={style.collectServiceInfo} key={preReserve?.client?.id}>
                <CarMarkNumber key={preReserve.car?.id} carInfo={getDataCar(preReserve.car)} classes={['darkTheme', 'withoutLink', ...(!classes.includes('red') ? ['darkText'] : [])]} />
                <BlueSpanLink url={`/svbook-details/${preReserve.car?.id}`} textSpan='Пользователи и авто' classes={['navLinks', 'minHeight', (!classes.includes('red') ? '' : 'linkForRedBg')]} />
            </div>
        )
        : '';

    const collectServiceInfo = preReserve?.car
        ? (
            <div className={style.collectServiceInfo} key='collectServiceInfo'>
                <div>{ OPTIONS_PACKETS_PACKET_NAME[preReserve.label] }</div>
                <BlueSpanLink
                    url={`/svbook-details/${preReserve.car?.id}/collect#${getBlockIdByPacketLabel(blocks, preReserve.label)}`}
                    textSpan='Настройки услуги'
                    classes={['navLinks', 'minHeight', (!classes.includes('red') ? '' : 'linkForRedBg')]}
                />
            </div>
        )
        : '';

    const carCreateTime = preReserve?.createdAt ? format(new Date(preReserve?.createdAt), 'dd.MM.yyyy', { locale: ru, timeZone: timezone }) : '';
    return (
        <div className={style.preReserveHeader + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.info}>
                <InfoInHeader
                    title={`Карточка предзаписи ${preReserve?.id ? '№ ' + preReserve?.id : ''}`}
                    subHeaders={[
                        { label: 'Услуга: ', title: collectServiceInfo },
                        { label: 'Автомобиль: ', title: carTitle },
                        { label: 'Дата создания: ', title: carCreateTime },
                        { label: 'Кто создал: ', title: preReserve?.creator?.name }
                    ]}
                    classes={classes.includes('red') ? ['forRed'] : []}
                />
            </div>
            <div className={style.mode}>
                { mapperTitles(
                    [
                        {
                            label: 'Режим:',
                            title: PROLONGATION_MODE_TITLES[preReserve?.mode?.toLowerCase()] || 'не указан'
                        },
                        {
                            label: 'Статус:',
                            title: PROLONGATION_STATUSES[preReserve?.status] || 'не указан'
                        }
                    ],
                    RowTitleLabel,
                    classes.includes('red') ? ['greenTitle'] : []
                ) }
            </div>
        </div>
    );
};

PreReserveHeader.propTypes = {
    classes: PropTypes.array,
    preReserve: PropTypes.object,
    timezone: PropTypes.string,
    blocks: PropTypes.array
};

export default PreReserveHeader;
