import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setTextCarsLoaded, searchCars, clearFoundCars, saveSearchCarsError } from '../../actions';
import PropTypes from 'prop-types';
import SearchLayout from '../../layouts/SearchLayout/SearchLayout';
import { getAllSvBooks, allSvBooksCountSuccess, allSvBooksCountError } from '../../reducers/carOrders';

const SvBooksSearchHeader = ({
    textSearch,
    setTextCarsLoaded,
    allSvBooksCount,
    fetchingSearchCars,
    searchCars,
    clearFoundCars,
    saveSearchCarsError,
    getAllSvBooks,
    allSvBooksCountSuccess,
    allSvBooksCountError
}) => {
    useEffect(() => {
        getAllSvBooks();
    }, [getAllSvBooks]);

    useEffect(() => {
        return () => allSvBooksCountSuccess(false);
    }, [allSvBooksCountSuccess]);

    useEffect(() => {
        return () => allSvBooksCountError(false);
    }, [allSvBooksCountError]);

    return (
        <SearchLayout
            id='headerSearchCars'
            nameHeader='Сервисные книжки авто'
            placeholderSearch='Поиск по имени / телефону / автомобилю или по гос. номеру'
            textSearch={textSearch}
            countItems={allSvBooksCount}
            isLoad={fetchingSearchCars}
            saveSearchError={saveSearchCarsError}
            setItemsLoaded={setTextCarsLoaded}
            clearFoundItems={clearFoundCars}
            searchItems={searchCars}
        />
    );
};

SvBooksSearchHeader.propTypes = {
    textSearch: PropTypes.object,
    setTextCarsLoaded: PropTypes.func,
    fetchingSearchCars: PropTypes.bool,
    clearFoundCars: PropTypes.func,
    searchCars: PropTypes.func,
    saveSearchCarsError: PropTypes.func,
    allSvBooksCountSuccess: PropTypes.func,
    allSvBooksCountError: PropTypes.func,
    allSvBooksCount: PropTypes.number,
    getAllSvBooks: PropTypes.func
};

const mapStateToProps = ({ carsListState: { textSearch, fetchingSearchCars }, carOrders: { allSvBooksCount } }) => {
    return {
        textSearch,
        fetchingSearchCars,
        allSvBooksCount
    };
};

const mapDispatchToProps = {
    setTextCarsLoaded,
    searchCars,
    clearFoundCars,
    saveSearchCarsError,
    getAllSvBooks,
    allSvBooksCountSuccess,
    allSvBooksCountError
};

export default connect(mapStateToProps, mapDispatchToProps)(SvBooksSearchHeader);
