import React from 'react';
import style from './ProlongationFiltersBoard.module.scss';
import FilterConfigure from '../../../../../../components/FilterConfigure';
import { OPTIONS_PACKETS } from '../../../../../../constants/packets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setProlongationInActionData } from '../../../../../../reducers/reportsProlongationInAction';

const ProlongationFiltersBoard = ({ setProlongationInActionData, prolongationPacket }) => {
    return (
        <div className={style.filterConfigure}>
            <FilterConfigure
                id='filterPackets'
                handleSetData={setProlongationInActionData}
                initialData={prolongationPacket}
                options={OPTIONS_PACKETS}
                fields={{ uniqId: 'id', displayed: 'name' }}
                textChoose='Выберите услугу'
            />
        </div>
    );
};

ProlongationFiltersBoard.propTypes = {
    prolongationPacket: PropTypes.array,
    setProlongationInActionData: PropTypes.func
};

const mapStateToProps = ({ reportsProlongationInAction: { prolongationPacket } }) => {
    return {
        prolongationPacket
    };
};

export default connect(mapStateToProps, { setProlongationInActionData })(ProlongationFiltersBoard);
