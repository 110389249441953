import React, { useState, useEffect } from 'react';
import style from '../../SheetCollectBody.module.scss';
import SubHeaderCollect from 'components/SubHeaderCollect';
import PropTypes from 'prop-types';
import { compose, withApiService } from 'Hoc';
import { connect } from 'react-redux';
import { BLOCK_LABEL_OIL_DVS } from '../../../../../constants/carCollectBlockLabels';
import { fetchPostCarOil } from '../../../../../actions';
import { ButtonsProlongation } from '../../../../../components/Buttons/ButtonsCommon';
import ToggleRadio from '../../../../../components/CheckToggle/ToggleRadio';
import { setAverageDailyMileage, setMonthForChangeOil, handleSetFocusMileage } from '../../../../../reducers/preReserve';
import OilWrap from './OilFields/OilWrap';
import {
    CHANGE_MODE
} from '../../../../../constants/prolongationMode';

const Oils = ({
    textSubHeaders,
    carDetailsInfo,
    id,
    carCollectBlocks,
    anchors,
    clientIdCollect,
    forSvBook,
    isProlongation,
    handleUpdateProlongation,
    setAverageDailyMileage,
    setMonthForChangeOil,
    preReservesExist,
    handleSetFocusMileage,
    fetchPostCarOil,
    carIsExactlyOilLastMileageSuccess
}) => {
    const [isHidden, setIsHidden] = useState(false);
    const [packetId, setPacketId] = useState(0);
    const [isValid, setIsValid] = useState(true);
    const [isValidationOn, setValidationOn] = useState(false);
    const [isOpenAverageDailyMileage, setIsOpenAverageDailyMileage] = useState(false);

    const handleValidationTurnOn = (turnOn) => {
        setValidationOn(turnOn);
    };

    useEffect(() => {
        return () => setValidationOn(false);
    }, [carDetailsInfo.id, clientIdCollect]);

    useEffect(() => {
        const resValidate = ((carDetailsInfo.mileage && carDetailsInfo.mileage_next_change && !carDetailsInfo.is_for_time) ||
            (carDetailsInfo.car_change_oil_months && carDetailsInfo.is_for_time)) && (isProlongation !== null);
        setIsValid(!!resValidate);
    }, [carDetailsInfo, isProlongation]);

    useEffect(() => {
        const block = carCollectBlocks && carCollectBlocks.blocks && carCollectBlocks.blocks.find(item => item.label === BLOCK_LABEL_OIL_DVS);
        block && block.packet_id && setPacketId(block.packet_id);
    }, [carCollectBlocks]);

    useEffect(() => {
        setIsHidden(false);
    }, [carDetailsInfo]);

    const handleSetHidden = (isHide) => {
        setIsHidden(isHide);
    };

    const interestsReasons = carCollectBlocks ? (carCollectBlocks.blocks ? carCollectBlocks.blocks[4] : null) : null;

    const oils = (
        <OilWrap
            id={id}
            isProlongation={isProlongation}
            isValidationOn={isValidationOn}
            isValid={isValid}
            carDetailsInfo={carDetailsInfo}
            interestsReasons={interestsReasons}
            preReservesExist={preReservesExist}
            isOpenAverageDailyMileage={isOpenAverageDailyMileage}
            packetId={packetId}
            setAverageDailyMileage={setAverageDailyMileage}
            setMonthForChangeOil={setMonthForChangeOil}
            forSvBook={forSvBook}
            handleValidationTurnOn={handleValidationTurnOn}
            setIsOpenAverageDailyMileage={setIsOpenAverageDailyMileage}
            handleSetFocusMileage={handleSetFocusMileage}
            fetchPostCarOil={fetchPostCarOil}
            clientIdCollect={clientIdCollect}
            carIsExactlyOilLastMileageSuccess={carIsExactlyOilLastMileageSuccess}
            handleUpdateProlongation={handleUpdateProlongation}
        />
    );

    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationOilInfo'
            id='prolongationOilInfo'
            handleClick={(res) => handleUpdateProlongation(res)}
            btnSelected={CHANGE_MODE[isProlongation]}
            classes={['btnStretchCycle', 'margin5', 'bgWhite', 'height40', (isProlongation === null ? (isValidationOn ? 'redBorder' : 'notCheck') : '')]}
        />
    );

    return (
        <div className={style.blockAnchors} id={id}>
            <SubHeaderCollect
                mark='fullUp'
                title={textSubHeaders[2]}
                handleSetHidden={handleSetHidden}
                blockId={id}
                stateBool={isHidden}
                anchors={anchors}
                actions={forSvBook ? [] : [prolongationButton]}
                classes={['withoutPadding']}
            >
                {
                    forSvBook
                        ? ''
                        : (
                            <ToggleRadio
                                text='Не собрано'
                                isActive={false}
                                key='isCollectOilInfo'
                                id='isCollectOilInfo'
                                handleClickActive={(res) => console.log('prolo nggggg', res)}
                            />
                        )
                }
            </SubHeaderCollect>
            {
                isHidden
                    ? ''
                    : oils
            }
        </div>
    );
};

Oils.propTypes = {
    textSubHeaders: PropTypes.array,
    carDetailsInfo: PropTypes.object,
    anchors: PropTypes.bool,
    id: PropTypes.number,
    carCollectBlocks: PropTypes.object,
    clientIdCollect: PropTypes.number,
    forSvBook: PropTypes.bool,
    handleUpdateProlongation: PropTypes.func,
    setAverageDailyMileage: PropTypes.func,
    setMonthForChangeOil: PropTypes.func,
    handleSetFocusMileage: PropTypes.func,
    fetchPostCarOil: PropTypes.func,
    preReservesExist: PropTypes.object,
    isProlongation: PropTypes.bool,
    carIsExactlyOilLastMileageSuccess: PropTypes.bool
};

const mapStateToProps = ({
    carDetailsState: { carDetailsInfo, clientIdCollect, carIsExactlyOilLastMileageSuccess },
    templatesOfFrameState: { textSubHeaders, carCollectBlocks },
    preReserve: { preReservesExist }
}) => {
    return {
        carDetailsInfo,
        textSubHeaders,
        carCollectBlocks,
        clientIdCollect,
        preReservesExist,
        carIsExactlyOilLastMileageSuccess
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, { fetchPostCarOil, setAverageDailyMileage, setMonthForChangeOil, handleSetFocusMileage })
)(Oils);
