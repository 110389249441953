import React from 'react';
import PropTypes from 'prop-types';

import style from './RowPair.module.scss';

const RowPair = ({ left, right, center }) => {
    return (
        <div className={style.rowPair}>
            <div className={style.rowPairLeft}>
                { left }
            </div>
            <div className={style.rowPairCenter}>
                { center }
            </div>
            <div className={style.rowPairRight}>
                { right }
            </div>
        </div>
    );
};

RowPair.propTypes = {
    left: PropTypes.node,
    center: PropTypes.node,
    right: PropTypes.node
};

export default RowPair;
