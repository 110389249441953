import React from 'react';
import Loader from '../Loader';
import PropTypes from 'prop-types';

const LoadingIndicatorBlock = ({ reasonLoader }) => {
    return (<Loader active={reasonLoader} classes={['loaderTop', 'gray']} />);
};

LoadingIndicatorBlock.propTypes = {
    reasonLoader: PropTypes.bool
};

export default LoadingIndicatorBlock;
