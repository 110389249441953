import React from 'react';
import style from './ReportsHeader.module.scss';
import PropTypes from 'prop-types';
import DateInputCollectReports from './DateInputCollectReports/DateInputCollectReports';
import DateInputPickYearProlongation from './DateInputPickYearProlongation';

const ReportsHeader = ({ children, count, textHeader = '', classes = [], handleOnClick = () => {}, withDataFilter = false, withYearFilter = false }) => {
    return (
        <div className={style.reportsHeader + classes.map(item => ' ' + style[item]).join('')} onClick={handleOnClick}>
            {
                textHeader
                    ? <div className={style.textHeader}>{ textHeader }</div>
                    : ''
            }
            {
                children || ''
            }
            <div>
                {
                    withDataFilter
                        ? <DateInputCollectReports />
                        : ''
                }
                {
                    withYearFilter
                        ? <DateInputPickYearProlongation />
                        : ''
                }
            </div>
            { count > 0 && <div className={style.count}>{ count }</div> }
        </div>
    );
};

ReportsHeader.propTypes = {
    children: PropTypes.node,
    textHeader: PropTypes.string,
    handleOnClick: PropTypes.func,
    withDataFilter: PropTypes.bool,
    withYearFilter: PropTypes.bool,
    classes: PropTypes.array,
    count: PropTypes.number
};

export default ReportsHeader;
