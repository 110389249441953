import React, { useState, useEffect } from 'react';
import { GrayButton } from '../Buttons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getDatePreReserve,
    postPreReserve,
    setNoticePreReserve,
    clearPreReserveInvites,
    canceledPreReserves,
    updatePreReserve
} from '../../reducers/preReserve';
import { isNotEmpty } from '../../utils';
import MessageForForm from '../MessageFoForm';
import ModalCommon from '../ModalCommon';
import PreReserveDatePickerWrap from '../PreReserveDatePickerWrap';
import PreReserveContainer from '../PreReserveContainer';
import PreReserveDatePicker from '../PreReserveDatePicker';
import { format, isEqual } from 'date-fns';
import CheckBoxCommon from '../CheckBoxes/CheckBoxesCommon';
import style from './Prereserve.module.scss';
import ReasonForChange from '../ReasonForChange';
import PreReserveActions from './components/PreReserveActions';
import BlueSpanLink from '../BlueSpanLink';
import arrowBack from 'assets/arrow-back.png';
import {
    CHANGE_MODE
} from '../../constants/prolongationMode';
import { ButtonsProlongation } from '../Buttons/ButtonsCommon';

const PreReserve = ({
    getDatePreReserve,
    carIdCollect,
    clientIdCollect,
    postPreReserve,
    invites,
    type,
    carDetailsInfo,
    serviceName,
    packetId,
    clearPreReserveInvites,
    preReservesExist,
    canceledPreReserves,
    updatePreReserve,
    isNotValid,
    isValidationOn,
    handleValidationTurnOn,
    carCollectBlocks,
    isProlongation,
    handleOpenAverageDailyMileage,
    handleUpdateProlongation,
    noMileageDataMessage
}) => {
    const [isShowDate, setIsShowDate] = useState(false);
    const [dateTimePreReserve, setDateTimePreReserve] = useState({ scheduledFrom: null, scheduledTo: null });
    const [showModal, setShowModal] = useState(false);
    const [client, setClient] = useState({});
    const [modalParams, setModalParams] = useState({});
    const [isOpenCRUD, setIsOpenCRUD] = useState(false);
    const [skipHelloMessage, setSkipHelloMessage] = useState(false);
    const [payloadReasons, setPayloadReasons] = useState({});
    const [changeDate, setChangeDate] = useState('');
    const [isOpenReason, setIsOpenReason] = useState(false);
    const [reset, setReset] = useState(true);
    const [clearDate, setClearDate] = useState(false);
    const [prolongationMode, setProlongationMode] = useState(null);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setProlongationMode(isProlongation);
    }, [isProlongation]);

    const handleSetPayload = (data, field) => {
        setPayloadReasons(s => ({ ...s, [field]: data }));
    };

    useEffect(() => {
        if (confirm && !showModal && prolongationMode !== null) {
            handleUpdateProlongation(prolongationMode);
            setProlongationMode(null);
            setConfirm(false);
            handleValidationTurnOn(true);
        } else {
            setConfirm(false);
        }
    }, [confirm, showModal, prolongationMode, handleUpdateProlongation, handleValidationTurnOn]);

    const handleConfirmButtonProlongation = () => {
        setConfirm(true);
    };

    const handleSetProlongation = (mode) => {
        setProlongationMode(mode);
    };

    useEffect(() => {
        setSkipHelloMessage(!isProlongation);
    }, [isProlongation]);

    useEffect(() => {
        const invite = invites?.find(item => item.packetId === packetId);
        invite && setPayloadReasons({ oldData: invite?.invite, scheduledFrom: invite?.invite?.scheduledFrom, scheduledTo: invite?.invite?.scheduledTo });
    }, [invites, packetId]);

    useEffect(() => {
        return () => {
            setDateTimePreReserve({ scheduledFrom: null, scheduledTo: null });
            setIsShowDate(false);
            setShowModal(false);
            setClient({});
            setReset(false);
            setSkipHelloMessage(!isProlongation);
            clearPreReserveInvites({ packetId: packetId });
        };
    }, [carIdCollect, clientIdCollect, clearPreReserveInvites, packetId, isProlongation]);

    useEffect(() => {
        handleOpenAverageDailyMileage && handleOpenAverageDailyMileage(!isOpenCRUD);
    }, [handleOpenAverageDailyMileage, isOpenCRUD]);

    useEffect(() => {
        if (preReservesExist && (preReservesExist[packetId]?.length > 0)) {
            setReset(false);
            setIsShowDate(true);
            setDateTimePreReserve({ scheduledFrom: preReservesExist[packetId][0].scheduledFrom, scheduledTo: preReservesExist[packetId][0].scheduledTo });
            clearPreReserveInvites({ packetId: packetId });
            setIsOpenCRUD(true);
        }
    }, [preReservesExist, packetId, reset, clearPreReserveInvites]);

    useEffect(() => {
        const mainClient = carDetailsInfo.clients && (carDetailsInfo.clients.length > 0) && carDetailsInfo.clients.find(item =>
            clientIdCollect ? item.id === clientIdCollect : item.statuses.find(item => item.label === 'owner')
        );
        setClient(mainClient || {});
    }, [carDetailsInfo, clientIdCollect]);

    useEffect(() => {
        const isInviteExist = invites?.find(item => item.packetId === packetId)?.invite;
        isNotEmpty(isInviteExist) ? setDateTimePreReserve(isInviteExist) : setDateTimePreReserve({ scheduledFrom: null, scheduledTo: null });
    }, [invites, packetId, carIdCollect]);

    const handleChangeDateTime = (newDateTime) => {
        if (
            !isEqual(new Date(newDateTime.scheduledFrom), new Date(dateTimePreReserve.scheduledFrom)) || !isEqual(new Date(newDateTime.scheduledTo), new Date(dateTimePreReserve.scheduledTo))
        ) {
            setDateTimePreReserve(newDateTime);
            if (payloadReasons?.scheduledFrom && (!isEqual(new Date(payloadReasons.scheduledFrom), new Date(newDateTime.scheduledFrom)) || !isEqual(new Date(payloadReasons.scheduledTo), new Date(newDateTime.scheduledTo)))) {
                setChangeDate(`Изменено с ${format(new Date(payloadReasons?.scheduledFrom), 'dd.MM.yyyy')} на ${format(new Date(newDateTime?.scheduledFrom), 'dd.MM.yyyy')}`);
                setIsOpenReason(true);
            }
        }
    };

    const prolongationButton = (
        <ButtonsProlongation
            key='prolongationOilInfo'
            id='prolongationOilInfo'
            handleClick={handleSetProlongation}
            btnSelected={CHANGE_MODE[prolongationMode]}
            classes={['btnStretchCycle', 'margin5', 'bgWhite', 'height40', (isProlongation === null ? 'notCheck' : '')]}
        />
    );

    const actionPreReserve = (action) => {
        if (action === 'setMode') {
            setModalParams({
                onConfirm: () => handleConfirmButtonProlongation(),
                nameButtons: ['Отмена', 'Подтвердить'],
                textHeader: 'Укажите статус услуги для расчета предварительной записи',
                componentBody: prolongationButton,
                classesBody: ['boldFirst'],
                classesModal: ['padding10x30'],
                classesHeader: ['bgGrey', 'withoutBtnClose', 'maxHeaderSize'],
                classesTitleHeader: ['fontSize16'],
                classesButtonsArea: ['greyTextButtons'],
                disabledButtonConfirm: prolongationMode === null,
                action: 'setMode'
            });
            setShowModal(true);
        } else {
            setModalParams({
                onConfirm: () => confirmModal(preReservesExist && (preReservesExist[packetId]?.length > 0)),
                nameButtons: ['Отмена', 'Отправить'],
                labelFields: `${action === 'post' ? 'Отправить' : 'Изменить'} предварительную запись?`,
                textBody: `Процесс пролонгации будет ${action === 'put' ? 'снова' : ''} запущен ${skipHelloMessage ? `для гостя ${client?.companyNick || ''}` : `и ${client ? client.companyNick : 'Гость'} получит push-уведомление с приглашением на ${serviceName || 'услугу'}`}`,
                classesBody: ['bgDanger', 'boldFirst']
            });

            if (action === 'turnOnValidation') {
                handleValidationTurnOn(true);
            }
            if (action === 'get') {
                isNotEmpty(dateTimePreReserve) && !dateTimePreReserve.scheduledFrom && !dateTimePreReserve.scheduledTo && carIdCollect && clientIdCollect && packetId && getDatePreReserve(carIdCollect, clientIdCollect, packetId);
                setIsShowDate(true);
                setIsOpenCRUD(false);
            }
            if (action === 'post') {
                setShowModal(true);
            }
            if (action === 'put') {
                setShowModal(true);
            }

            setChangeDate('');
            setIsOpenReason(false);
        }
    };

    const handleCreatePreReserve = () => {
        if (isNotEmpty(dateTimePreReserve) && dateTimePreReserve.scheduledFrom && dateTimePreReserve.scheduledTo && isProlongation !== null) {
            postPreReserve({
                skipHelloMessage: skipHelloMessage,
                carId: carIdCollect,
                clientId: clientIdCollect,
                packetId: packetId,
                ...dateTimePreReserve,
                reasonChangeDataInvite: payloadReasons,
                mode: CHANGE_MODE[isProlongation]
            });
            setChangeDate('');
            setPayloadReasons({});
            setIsOpenReason(false);
            setSkipHelloMessage(!isProlongation);
        }
    };

    const handleUpdatePreReserve = () => {
        if (isNotEmpty(dateTimePreReserve) && dateTimePreReserve.scheduledFrom && dateTimePreReserve.scheduledTo) {
            const oldDate = preReservesExist && (preReservesExist[packetId]?.length > 0 && preReservesExist[packetId][0]);
            if (oldDate &&
                (
                    !isEqual(new Date(oldDate.scheduledFrom), new Date(dateTimePreReserve.scheduledFrom)) ||
                    !isEqual(new Date(oldDate.scheduledTo), new Date(dateTimePreReserve.scheduledTo))
                )
            ) {
                updatePreReserve(
                    (preReservesExist && (preReservesExist[packetId]?.length > 0) && preReservesExist[packetId][0]?.id),
                    {
                        skipHelloMessage: skipHelloMessage,
                        carId: carIdCollect,
                        clientId: clientIdCollect,
                        scheduledTo: dateTimePreReserve.scheduledTo,
                        scheduledFrom: dateTimePreReserve.scheduledFrom,
                        packetId
                    }
                );
                setIsOpenCRUD(true);
                setSkipHelloMessage(false);
            }
        }
    };

    const confirmModal = (isUpdate = false) => {
        if (isUpdate) {
            handleUpdatePreReserve();
        } else {
            handleCreatePreReserve();
        }
    };

    const handleDelete = () => {
        setIsShowDate(false);
        setIsOpenCRUD(false);
        preReservesExist && (preReservesExist[packetId]?.length > 0) && canceledPreReserves(
            (preReservesExist[packetId][0]?.id),
            { carId: carIdCollect, clientId: clientIdCollect, packetId: packetId }
        );
    };

    const handleUpdate = () => {
        setIsShowDate(true);
        setIsOpenCRUD(false);
    };

    const classesButton = dateTimePreReserve && dateTimePreReserve.scheduledFrom && dateTimePreReserve.scheduledTo ? ['showMore'] : ['showMore', 'disabled'];

    const button = (
        <div className={style.buttonsCreate + (isShowDate ? ' ' + style.margin10_0 : '')}>
            <GrayButton
                key={'push_create_prereserve_button_' + packetId}
                id={'push_create_prereserve_button_' + packetId}
                text={isShowDate ? `${preReservesExist && (preReservesExist[packetId]?.length > 0) ? 'Изменить' : 'Отправить'} предварительную запись` : 'Создать предварительную запись'}
                classes={isShowDate ? [...classesButton, 'blueBg'] : (!isNotValid ? ['showMore', 'paddingMore', 'blueBg'] : ['showMore', 'paddingMore', 'blueBg', 'disabled'])}
                disabled={
                    ((!dateTimePreReserve.scheduledFrom || !dateTimePreReserve.scheduledTo) && isShowDate) ||
                    (isNotValid && isValidationOn) ||
                    (preReservesExist && (preReservesExist[packetId]?.length > 0) &&
                        (isEqual(new Date(preReservesExist[packetId][0].scheduledFrom), new Date(dateTimePreReserve.scheduledFrom)) &&
                            isEqual(new Date(preReservesExist[packetId][0].scheduledTo), new Date(dateTimePreReserve.scheduledTo)))
                    )
                }
                handlerClick={() => actionPreReserve(
                    isProlongation === null
                        ? 'setMode'
                        : (isShowDate
                            ? ((preReservesExist && (preReservesExist[packetId]?.length > 0) && preReservesExist[packetId][0]?.id)
                                ? 'put'
                                : 'post')
                            : (!isNotValid ? 'get' : (isValidationOn ? 'get' : 'turnOnValidation')))
                )}
            />
            {
                isShowDate && (
                    <CheckBoxCommon
                        key='skip_hello_message'
                        blockInterestReasonId='skip_hello_message'
                        stateChecked={skipHelloMessage}
                        handleChecked={() => setSkipHelloMessage(s => !s)}
                        textLabel='Отключить уведомление'
                        classes={['blueLabel', 'paddingEmpty', 'paddingRight16', 'paddingLeft16', 'maxWidth125']}
                        withCheckBox
                        withStringId
                    />
                )
            }
        </div>
    );

    const handleOpenModal = (state, data) => {
        if (state) {
            setModalParams(data);
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    };

    const notice = (
        invites && invites.find(item => item.packetId === packetId) && invites.find(item => item.packetId === packetId).notice
            ? invites.find(item => item.packetId === packetId).notice
            : ''
    );

    useEffect(() => {
        invites.find(item => item.packetId === packetId) && setClearDate(false);
    }, [invites, packetId]);

    const handleReload = () => {
        setClearDate(true);
        setShowModal(false);
        setIsOpenReason(false);
        clearPreReserveInvites({ packetId: packetId });
        carIdCollect && clientIdCollect && packetId && getDatePreReserve(carIdCollect, clientIdCollect, packetId);
        setIsOpenCRUD(false);
    };

    const messageNote = <MessageForForm message={notice || changeDate} classes={(changeDate && isShowDate) ? ['maxWidthStretch', 'greyLight'] : ['maxWidthStretch']} />;

    const handleNotConfirm = () => {
        setProlongationMode(null);
        setShowModal(false);
    };

    return (
        <>
            <PreReserveContainer
                classes={isShowDate && !isOpenCRUD ? (changeDate ? ['paddingBottom5', 'paddingTopButton20'] : ['paddingTopButton20']) : []}
                key={'PreReserveContainer_' + packetId}
                id={'pre_reserve_' + packetId}
                noticeNode={(notice && !isOpenCRUD) || (changeDate && isShowDate && !isOpenCRUD) ? messageNote : null}
                back={isShowDate && !isOpenCRUD && preReservesExist && (preReservesExist[packetId]?.length > 0)
                    ? (
                        <div>
                            <BlueSpanLink
                                textSpan='Отменить изменения'
                                classes={['navLinks']}
                                img={arrowBack}
                                handleClick={() => {
                                    if (preReservesExist && (preReservesExist[packetId]?.length > 0)) {
                                        if (
                                            !isEqual(new Date(preReservesExist[packetId][0].scheduledFrom), new Date(dateTimePreReserve.scheduledFrom)) ||
                                            !isEqual(new Date(preReservesExist[packetId][0].scheduledTo), new Date(dateTimePreReserve.scheduledTo))
                                        ) {
                                            setReset(true);
                                            setSkipHelloMessage(false);
                                            setIsOpenCRUD(true);
                                        } else {
                                            setIsOpenCRUD(true);
                                        }
                                    }
                                }}
                            />
                        </div>
                    )
                    : noMileageDataMessage && <div className={style.notice}>{ noMileageDataMessage }</div>}
            >
                {
                    isShowDate
                        ? (
                            <PreReserveDatePickerWrap key={'PreReserveDatePickerWrap_' + packetId} text='Пред. запись'>
                                <PreReserveDatePicker
                                    key={'PreReserveDatePicker_PreReserveDatePickerWrap_' + packetId}
                                    dateInitialFrom={dateTimePreReserve.scheduledFrom}
                                    dateInitialTo={dateTimePreReserve.scheduledTo}
                                    handleChange={handleChangeDateTime}
                                    handleReload={!isOpenCRUD ? handleReload : undefined}
                                    classes={notice && !isOpenCRUD ? ['redBorder'] : []}
                                    type={type}
                                    packetId={packetId}
                                    disabled={isOpenCRUD}
                                    clear={clearDate}
                                />
                            </PreReserveDatePickerWrap>
                        )
                        : ''
                }
                {
                    isShowDate && isOpenCRUD
                        ? (
                            <PreReserveActions
                                dateCreatePreReserve={(preReservesExist && preReservesExist[packetId] && preReservesExist[packetId].length > 0 && preReservesExist[packetId][0].createdAt) || null}
                                handleOpenModal={handleOpenModal}
                                handleDelete={handleDelete}
                                handleUpdate={handleUpdate}
                                packetId={packetId}
                            />
                        )
                        : button
                }
                {
                    showModal
                        ? (
                            <ModalCommon
                                id={`modalConfirmCreatePreReserve_${packetId}`}
                                handleConfirm={modalParams.onConfirm}
                                handleNotConfirm={handleNotConfirm}
                                labelFields={modalParams.labelFields}
                                textBody={modalParams.textBody}
                                textHeader={modalParams.textHeader}
                                componentBody={modalParams.componentBody}
                                nameButtons={modalParams.nameButtons}
                                handelClose={() => setShowModal(false)}
                                withHeader={!!modalParams.textHeader}
                                classesModal={modalParams.classesModal}
                                classesBody={modalParams.classesBody}
                                classesHeader={modalParams.classesHeader}
                                classesTitleHeader={modalParams.classesTitleHeader}
                                classesButtonsArea={modalParams.classesButtonsArea}
                                disabledButtonConfirm={modalParams.action === 'setMode' && prolongationMode === null}
                            />
                        )
                        : ''
                }
            </PreReserveContainer>
            {
                isShowDate && !isOpenCRUD
                    ? isOpenReason && <ReasonForChange handleSetPayload={(data) => handleSetPayload(data, 'reason')} payloadDataText={payloadReasons?.reason} reasons={carCollectBlocks.reasons} />
                    : ''
            }
        </>
    );
};

PreReserve.propTypes = {
    packetId: PropTypes.number,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    getDatePreReserve: PropTypes.func,
    postPreReserve: PropTypes.func,
    clearPreReserveInvites: PropTypes.func,
    canceledPreReserves: PropTypes.func,
    updatePreReserve: PropTypes.func,
    handleValidationTurnOn: PropTypes.func,
    handleOpenAverageDailyMileage: PropTypes.func,
    invites: PropTypes.array,
    carCollectBlocks: PropTypes.object,
    carDetailsInfo: PropTypes.object,
    serviceName: PropTypes.string,
    preReservesExist: PropTypes.object,
    isNotValid: PropTypes.bool,
    isValidationOn: PropTypes.bool,
    isProlongation: PropTypes.bool,
    noMileageDataMessage: PropTypes.node,
    handleUpdateProlongation: PropTypes.func,
    type: PropTypes.oneOf(['range', 'standard', 'month'])
};

const mapStateToProps = ({
    carDetailsState: { carIdCollect, clientIdCollect, carDetailsInfo },
    templatesOfFrameState: { carCollectBlocks },
    preReserve: {
        invites,
        preReservesExist
    }
}) => {
    return {
        carIdCollect,
        carCollectBlocks,
        clientIdCollect,
        carDetailsInfo,
        invites,
        preReservesExist
    };
};

export default connect(mapStateToProps, {
    getDatePreReserve,
    postPreReserve,
    setNoticePreReserve,
    clearPreReserveInvites,
    canceledPreReserves,
    updatePreReserve
})(PreReserve);
