export const PROLONGATION_MODE_PROLONGATION = 'prolongation';
export const PROLONGATION_MODE_INITIATION = 'initiation';

export const PROLONGATION_MODE_TITLES = {
    [PROLONGATION_MODE_PROLONGATION]: 'Пролонгация',
    [PROLONGATION_MODE_INITIATION]: 'Развитие'
};

export const PROLONGATION_STATUS_ACTIVE = 'ACTIVE';
export const PROLONGATION_STATUS_ACTIVE_READY = 'READY_TO_LINK';
export const PROLONGATION_STATUS_CANCELED = 'CANCELED';
export const PROLONGATION_STATUS_LINKED = 'LINKED';

export const PROLONGATION_STATUSES = {
    [PROLONGATION_STATUS_ACTIVE]: 'В процессе',
    [PROLONGATION_STATUS_CANCELED]: 'Отменен',
    [PROLONGATION_STATUS_LINKED]: 'Трансформирован',
    [PROLONGATION_STATUS_ACTIVE_READY]: 'В процессе'
};
export const CHANGE_MODE = {
    [true]: PROLONGATION_MODE_PROLONGATION,
    [false]: PROLONGATION_MODE_INITIATION,
    [null]: null
};
