const createNewItemForCar = (item) => {
    if (item.id) {
        return {
            ...item,
            id: item.id,
            model: item.model.title,
            mark: item.model.mark.title,
            region: item.region_id,
            licensePlate: item.license_plate,
            photo: item.files
        };
    }
};

export default createNewItemForCar;
