import {
    typeGetYearReportProlongation,
    typeGetYearReportProlongationSuccess,
    typeGetYearReportProlongationError,
    typeSetYearForReportProlongation
} from '../actionsTypes';
import { getYear } from 'date-fns';

// ------------------------------------
// Constants
// ------------------------------------
const SET_YEAR_REPORT_PROLONGATION = typeGetYearReportProlongation;
const SET_REPORT_PROLONGATION_SUCCESS = typeGetYearReportProlongationSuccess;
const SET_REPORT_PROLONGATION_ERROR = typeGetYearReportProlongationError;
const SET_YEAR_FOR_REPORT_PROLONGATION = typeSetYearForReportProlongation;

// ------------------------------------
// Actions
// ------------------------------------

export const setYearForReportProlongation = (payload) => {
    return {
        type: SET_YEAR_FOR_REPORT_PROLONGATION,
        payload: payload
    };
};

export const setStatisticYearProlongation = (payload) => {
    return {
        type: SET_YEAR_REPORT_PROLONGATION,
        payload: payload
    };
};

export const setStatisticYearProlongationError = (payload) => {
    return {
        type: SET_REPORT_PROLONGATION_ERROR,
        payload: payload
    };
};

export const setStatisticYearProlongationSuccess = (payload) => {
    return {
        type: SET_REPORT_PROLONGATION_SUCCESS,
        payload: payload
    };
};

export const getStatisticYearProlongation = (data) => (dispatch, getState, { apiService }) => {
    dispatch(setStatisticYearProlongationSuccess(true));
    dispatch(setStatisticYearProlongationError(false));
    return apiService.getReportYearProlongation(data)
        .then((data) => {
            dispatch(setStatisticYearProlongation(data));
            dispatch(setStatisticYearProlongationSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(setStatisticYearProlongationSuccess(false));
            dispatch(setStatisticYearProlongationError(true));
            return err;
        });
};

export const actions = {
    getStatisticYearProlongation,
    setYearForReportProlongation

};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_YEAR_FOR_REPORT_PROLONGATION]: (state, action) => {
        return ({
            ...state,
            yearForReport: action.payload
        });
    },
    [SET_YEAR_REPORT_PROLONGATION]: (state, action) => {
        return ({
            ...state,
            statisticYearProlongation: action.payload
        });
    },

    [SET_REPORT_PROLONGATION_ERROR]: (state, action) => {
        return ({
            ...state,
            statisticYearProlongationError: action.payload
        });
    },

    [SET_REPORT_PROLONGATION_SUCCESS]: (state, action) => {
        return ({
            ...state,
            statisticYearProlongationSuccess: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            yearForReport: '' + getYear(new Date()),
            statisticYearProlongationError: false,
            statisticYearProlongationSuccess: false,
            statisticYearProlongation: [
                /*
                EXAMPLE:
                {
                    label: 'oil',
                    title: 'МАСЛО',
                    serviceTemplateId: 1001,
                    year: 2013,
                    months: [
                        {
                            month: 1,
                            value: {
                                allOrders: { title: 'Ожидаемые возвраты', count: 180 },
                                expected: { title: 'Пред. записи', count: 115 },
                                expectedMoved: { percent: 120, count: 166 },
                                returned: { title: 'Факт возвраты', percent: 80, count: 80 },
                                transferred: { title: 'Факт возвраты с переносом', percent: 13, count: 25 },
                                canceled: { title: 'Факт отказы', percent: 7, count: 10 }
                            }
                        }
                    ]
                } */
            ]
        };
    }

    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state.reportsProlongation, action) : state.reportsProlongation;
};

export default commonReducer;
