import React, { useEffect, useState } from 'react';
import BigHeadCollectBlock from '../../../../../components/BigHeadCollectBlock/BigHeadCollectBlock';
import BlocksWrap from '../BlocksWrap';
import PropTypes from 'prop-types';
import StatusesSheetCollectWrap from '../StatusesSheetCollectWrap';
import { STATUSES } from '../../../../../constants/CarCollectBlocks';

const StatusesBlock = ({ carDetailsInfo, block }) => {
    const [isHiddenStatuses, setIsHiddenStatuses] = useState(false);

    useEffect(() => {
        setIsHiddenStatuses(false);
    }, [carDetailsInfo]);

    const handleSetHiddenStatuses = () => {
        setIsHiddenStatuses(s => !s);
    };

    return (
        <BlocksWrap KeyName='statuses' id='anchorStatuses'>
            <>
                <BigHeadCollectBlock
                    textHeader={block?.title || 'Статусы'}
                    carDetailsInfo={carDetailsInfo}
                    handleSetHidden={handleSetHiddenStatuses}
                    isHiddenContent={isHiddenStatuses}
                    classes={['headStatus']}
                    blockId={block?.id || STATUSES}
                    withoutCheckToggle
                />
                {
                    isHiddenStatuses
                        ? ''
                        : <StatusesSheetCollectWrap />
                }
            </>
        </BlocksWrap>
    );
};

StatusesBlock.propTypes = {
    carDetailsInfo: PropTypes.object,
    block: PropTypes.object
};

export default StatusesBlock;
