import React, { useState } from 'react';
import CustomInput from '../DateInput/CustomDateInput';
import DatePicker from 'react-datepicker';
import { getYear } from 'date-fns';
import PropTypes from 'prop-types';
import './datePickerYear.css';

const DateInputPickYear = ({ handleSetNewYear, initialYear }) => {
    const [startYear, setStartYear] = useState(new Date(initialYear));

    const handleSetYear = (date) => {
        setStartYear(date);
        handleSetNewYear(`${getYear(date)}`);
    };

    return (
        <DatePicker
            key='YearPicker'
            selected={startYear}
            onChange={handleSetYear}
            showYearPicker
            dateFormat='yyyy'
            yearItemNumber={6}
            closeOnScroll
            customInput={<CustomInput textSpanAfter='год' imgBlue reports />}
        />
    );
};

DateInputPickYear.propTypes = {
    handleSetNewYear: PropTypes.func,
    initialYear: PropTypes.string
};

export default DateInputPickYear;
