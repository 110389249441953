import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import errorIcon from './assets/error-img.png';
import style from './ErrorIndicator.module.scss';
import { connect } from 'react-redux';
import { setError404Redirect } from '../../reducers/common';

const ErrorIndicator = ({ errorNumber, text }) => {
    useEffect(() => {
        return () => setError404Redirect(false);
    });

    return (
        <div className={style.errorIndicator}>
            <img src={errorIcon} alt='error icon' />
            <span className={style.boom}>
                BOOM!
            </span>
            <span>
                {
                    errorNumber
                        ? `Ошибка: ${errorNumber}`
                        : 'Что-то пошло не так'
                }
            </span>
            <span>
                {
                    text || '(Проверьте ваше подключение к интернету и обновите страницу. Если ничего не помогло - отойдите от компа и посмотрите в окно. Мир не вечен.)'
                }
            </span>
        </div>
    );
};

ErrorIndicator.propTypes = {
    errorNumber: PropTypes.string,
    text: PropTypes.string
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { setError404Redirect })(ErrorIndicator);
