import React from 'react';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import style from './BlueSpanLink.module.scss';

const HandleLink = ({ url, children }) => {
    return (
        <HashLink to={url}>
            { children }
        </HashLink>
    );
};

HandleLink.propTypes = {
    url: PropTypes.string,
    children: PropTypes.node
};

const BlueSpan = ({ handleClick, textSpan, img, classes = [] }) => {
    return (
        <div className={style.wrapBlueSpan + (img ? ' ' + style.marginLeft165 : '')}>
            { img && <div className={style.img}><img key='BlueSpanLink_img_arrow' src={img} alt='назад' /></div> }
            <span onClick={handleClick} className={style.blueSpan + classes.map(item => ' ' + style[item]).join('')}>{ textSpan }</span>
        </div>
    );
};

BlueSpan.propTypes = {
    handleClick: PropTypes.func,
    textSpan: PropTypes.string,
    img: PropTypes.string,
    classes: PropTypes.array
};

const BlueSpanLink = ({ url, handleClick, textSpan, img, classes = [] }) => {
    const link = (
        <HandleLink url={url}>
            <BlueSpan img={img} textSpan={textSpan} classes={classes} />
        </HandleLink>
    );

    return (
        url
            ? link
            : <BlueSpan key='BlueSpanLink_BlueSpan_arrow' img={img} textSpan={textSpan} classes={classes} handleClick={handleClick} />
    );
};

BlueSpanLink.propTypes = {
    url: PropTypes.string,
    handleClick: PropTypes.func,
    textSpan: PropTypes.string,
    img: PropTypes.string,
    classes: PropTypes.array
};

export default BlueSpanLink;
