import React from 'react';
import style from './HeaderWithArrow.module.scss';
import arrowUp from 'assets/arrow-down-dark-blue.png';
import PropTypes from 'prop-types';

const HeaderWithArrow = ({ text, handleClick, rotate }) => {
    return (
        <div className={style.headerStatuses}>
            <h2>{ text }</h2>
            <div className={style.arrowBlue + (rotate ? ' ' + style.rotate : '')} onClick={handleClick}><img src={arrowUp} alt='arrow-down' /></div>
        </div>
    );
};

HeaderWithArrow.propTypes = {
    text: PropTypes.string,
    handleClick: PropTypes.func,
    rotate: PropTypes.bool
};

export default HeaderWithArrow;
