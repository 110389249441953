import React, { useState, useEffect } from 'react';
import { fetchNewInterestsReason } from '../../../../../actions';
import SelectWithLabel from '../../../../../components/SelectWithLabel';
import HeadToggle from '../../../../../components/HeadToggle';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import style from './ReasonsModal.module.scss';

const ReasonsModal = ({
    selectedBlock,
    handleCloseAddReason,
    fetchNewInterestsReason,
    pathHeader = 'Создание',
    isError = false,
    blocks = [],
    interests = [],
    interestId = 0,
    reasons = [],
    reasonId = 0,
    blockInterestId = 0
}) => {
    const [newReason, setNewReason] = useState('');
    const [oldReason, setOldReason] = useState(0);
    const [selectedInterest, setSelectedInterest] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        const fieldReason = newReason !== '' ? { reason_title: newReason } : { reason_id: oldReason };
        const fieldId = (blockInterestId !== 0) ? { id: blockInterestId } : {};
        const payload = {
            car_collect_block_id: selectedBlock,
            client_service_interest_id: selectedInterest,
            ...fieldReason,
            ...fieldId
        };
        (newReason !== '' || oldReason > 0) && fetchNewInterestsReason(payload);
        handleCloseAddReason();
    };

    useEffect(() => {
        setSelectedInterest(interestId);
    }, [interestId]);

    useEffect(() => {
        setOldReason(reasonId);
    }, [reasonId]);

    const handleBlockSet = (e, value) => {
        // console.log('ReasonsModal set Blocks: ', value);
    };

    const handleChangeInterest = (e, value) => {
        setSelectedInterest(value);
    };

    const handleChangeReasonPayload = (e, value) => {
        typeof value === 'string' ? setNewReason(value) : setOldReason(value);
    };

    const selectReason = (
        <SelectWithLabel
            key={'reasons' + selectedBlock}
            idName={'reasons' + selectedBlock}
            arrOptions={reasons}
            valueReal={oldReason}
            labelName=' '
            forUpdateField={handleChangeReasonPayload}
            error={isError}
            isDisabled={!!newReason}
        />
    );

    const createNewReason = (
        <div className={style.newReasonLabel} key={'reasonsInput' + selectedBlock}>
            <label htmlFor='newReason' className={style.reasonLabel}>Причина: </label>
            <input
                type='newReason'
                id='newReason'
                value={newReason}
                onChange={e => handleChangeReasonPayload(e, e.target.value)}
            />
        </div>
    );

    return (
        <div className={style.reasonsModal} onClick={handleCloseAddReason}>
            <div className={style.whiteReasonsModal} onClick={e => e.stopPropagation()}>
                <div className={style.headerModal}>
                    <HeadToggle id='HeaderReasonsModal' headerText={pathHeader + ' причины интереса клиента'} classes={['big', 'gray', 'leftPosition', 'withoutArrow']} initialHidden />
                    <button className={style.btnClose} onClick={handleCloseAddReason}>X</button>
                </div>
                <div className={style.fieldsReason}>
                    <div className={style.childPadding}>
                        <SelectWithLabel
                            key={'block' + selectedBlock}
                            idName={'block' + selectedBlock}
                            arrOptions={blocks}
                            valueReal={selectedBlock}
                            labelName='Блок: '
                            forUpdateField={handleBlockSet}
                            error={isError}
                            isDisabled
                        />
                    </div>
                    <div className={style.childPadding}>
                        <SelectWithLabel
                            key={'interest' + selectedBlock}
                            idName={'interest' + selectedBlock}
                            arrOptions={interests}
                            valueReal={selectedInterest}
                            labelName='Интерес: '
                            forUpdateField={handleChangeInterest}
                            error={isError}
                        />
                    </div>
                    <div className={style.childPadding}>
                        {
                            [createNewReason, selectReason]
                        }
                    </div>
                    <div className={style.childPadding}>
                        <input className={style.btnAdd} type='submit' value='Сохранить' onClick={handleSubmit} />
                    </div>
                </div>

            </div>

        </div>
    );
};

ReasonsModal.propTypes = {
    selectedBlock: PropTypes.number,
    handleCloseAddReason: PropTypes.func,
    isError: PropTypes.bool,
    blocks: PropTypes.array,
    interests: PropTypes.array,
    reasons: PropTypes.array,
    fetchNewInterestsReason: PropTypes.func,
    pathHeader: PropTypes.string,
    interestId: PropTypes.number,
    reasonId: PropTypes.number,
    blockInterestId: PropTypes.number
};

const mapStateToProps = ({ templatesOfFrameState: { reasons } }) => {
    return {
        reasons
    };
};

export default connect(mapStateToProps, { fetchNewInterestsReason })(ReasonsModal);
