import style from './HeaderWithSearch.module.scss';
import Loader from '../Loader';
import React from 'react';
import PropTypes from 'prop-types';

const HeaderWithSearch = ({
    nameHeader,
    placeholderSearch,
    label,
    sumItems,
    onSubmit,
    handleSearch,
    isLoad,
    textButtonAdd,
    handlerAdd,
    sortBySchedule
}) => {
    return (
        <div className={style.svBooks}>
            <div className={style.svBooksHeader}>
                <h1>{ nameHeader }</h1>
                <div className={style.h1Numbers}>
                    <h1>
                        { sumItems }
                    </h1>
                </div>
                {
                    textButtonAdd
                        ? <button onClick={handlerAdd}>{ textButtonAdd }</button>
                        : ''
                }
                { /* <div className={style.addGuest}>Добавить гостя</div> */ }
            </div>
            <div className={style.inputWithFilter}>
                <form
                    className={style.formSearch + ' ' + (sortBySchedule ? style.delMargin : '')}
                    onSubmit={onSubmit}
                >
                    <label>
                        <input
                            type='text'
                            name='sv-search'
                            value={label}
                            placeholder={placeholderSearch}
                            onChange={handleSearch}
                        />
                    </label>
                    <button type='submit' className={style.btnSubmit + ' ' + (isLoad ? style.isLoadBg : '')}>
                        <Loader active={isLoad} classes={['small', 'withoutBackground']} />
                    </button>
                </form>
            </div>
        </div>
    );
};

HeaderWithSearch.propTypes = {
    nameHeader: PropTypes.string,
    placeholderSearch: PropTypes.string,
    label: PropTypes.string,
    sumItems: PropTypes.string,
    onSubmit: PropTypes.func,
    handleSearch: PropTypes.func,
    isLoad: PropTypes.bool,
    textButtonAdd: PropTypes.string,
    handlerAdd: PropTypes.func,
    sortBySchedule: PropTypes.bool
};

export default HeaderWithSearch;
