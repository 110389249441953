import React, { useEffect } from 'react';
import style from './VisitorSecretary.module.scss';
import SecretaryWeekDays from '../../../../SheetCollect/SheetCollectBody/components/Secretary/SecretaryWeekDays';
import PropTypes from 'prop-types';
import { checkStatusExist } from '../../../../../utils';
import { STATUS_MAIN_USER } from '../../../../../constants/statusesCarClient';
import { getAllStatusesCarClients } from '../../../../../reducers/statusesClientCar';
import { connect } from 'react-redux';

const VisitorSecretary = ({ clientId, visitor, statuses, getAllStatusesCarClients }) => {
    useEffect(() => {
        (!statuses || statuses.length === 0) && getAllStatusesCarClients();
    }, [getAllStatusesCarClients, statuses]);

    return (
        <div className={style.mainContainer}>
            <SecretaryWeekDays
                clientId={clientId}
                classes={['inVisitor']}
                isMainClientExist={checkStatusExist(visitor?.statuses || [], statuses, STATUS_MAIN_USER)}
            />
        </div>
    );
};

VisitorSecretary.propTypes = {
    clientId: PropTypes.number,
    getAllStatusesCarClients: PropTypes.func,
    visitor: PropTypes.object,
    statuses: PropTypes.array
};

const mapStateToProps = ({
    visitors: { visitor },
    statusesClientCar: { statuses }
}) => {
    return {
        visitor,
        statuses
    };
};

export default connect(mapStateToProps, { getAllStatusesCarClients })(VisitorSecretary);
