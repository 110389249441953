import React from 'react';
import style from './ModalCommon.module.scss';
import PropTypes from 'prop-types';
import ModalHeader from '../ModalHeader';

const ModalCommon = ({
    withHeader,
    id,
    children,
    disabledButtonConfirm,
    componentBody,
    classesHeader = [],
    classesTitleHeader = [],
    classesBody = [],
    classesModal = [],
    classesButtonsArea = [],
    handleConfirm = () => {},
    handelClose = () => {},
    handleNotConfirm = () => {},
    textHeader = '',
    textBody = '',
    typeModal = 'confirm',
    labelFields = '',
    nameButtons = ['Ок']
}) => {
    const handleAction = (action, doNotClose = false) => {
        !doNotClose && handelClose();
        action === 'cancel' && handleNotConfirm();
        action === 'agree' && handleConfirm();
    };

    const confirmButtons = (
        <>
            <input className={style.btnBlue} type='button' value={nameButtons[0]} onClick={() => handleAction('cancel')} />
            <div className={style.pipeLine} />
            <input className={style.btnPink} type='button' value={nameButtons[1]} onClick={() => handleAction('agree')} disabled={disabledButtonConfirm} />
        </>
    );

    const alertBtn = (
        <input className={style.btnBlue} type='button' value={nameButtons[0]} onClick={() => handleAction('agree')} />
    );

    const node = (
        <div id={'ModalCommon_' + id} key={'ModalCommon:' + id} className={style.reasonsModal} onClick={() => handleAction('cancel')}>
            <div className={style.whiteReasonsModal + classesModal.map(item => ' ' + style[item]).join('')} onClick={e => e.stopPropagation()}>
                { withHeader ? <ModalHeader classesHeader={classesHeader} classesTitle={classesTitleHeader} text={textHeader} handleCloseModal={handleAction} /> : '' }
                <div className={style.labelText + ' ' + (classesBody ? classesBody.map(item => ' ' + style[item]).join('') : '')}>
                    { labelFields && <p>{ labelFields }</p> }
                    { textBody }
                    { componentBody }
                </div>
                {
                    children
                        ? <div className={style.labelText2}>{ children }</div>
                        : ''
                }
                <div className={style.fieldsReason + classesButtonsArea.map(item => ' ' + style[item]).join('')}>
                    <div className={style.childPadding}>
                        {
                            typeModal === 'confirm'
                                ? confirmButtons
                                : alertBtn
                        }
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        node
    );
};

ModalCommon.propTypes = {
    handleConfirm: PropTypes.func,
    handelClose: PropTypes.func,
    handleNotConfirm: PropTypes.func,
    withHeader: PropTypes.bool,
    id: PropTypes.string,
    textHeader: PropTypes.string,
    textBody: PropTypes.string,
    componentBody: PropTypes.node,
    children: PropTypes.node,
    typeModal: PropTypes.string,
    labelFields: PropTypes.string,
    nameButtons: PropTypes.array,
    classesTitleHeader: PropTypes.arrayOf(PropTypes.oneOf(['small', 'gray', 'big', 'leftPosition', 'onHoverArrow', 'withoutArrow', 'withoutPadding', 'centerPosition', 'fontSize16'])),
    classesHeader: PropTypes.arrayOf(PropTypes.oneOf(['bgDanger', 'hiddenWithoutHover', 'bgGrey', 'withoutBtnClose', 'withoutBtnClose', 'maxHeaderSize'])),
    classesBody: PropTypes.arrayOf(PropTypes.oneOf(['bgWhite', 'bgGray', 'bgOrange', 'bgDanger', 'boldFirst'])),
    classesModal: PropTypes.arrayOf(PropTypes.oneOf(['bigModal', 'middleModal', 'fontSize22', 'labelText2pad32', 'padding10x30'])),
    classesButtonsArea: PropTypes.arrayOf(PropTypes.oneOf(['statusOwner', 'greyTextButtons'])),
    disabledButtonConfirm: PropTypes.bool
};

export default ModalCommon;
