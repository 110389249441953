import React from 'react';
import style from './ClientsFoundTemplate.module.scss';
import PropTypes from 'prop-types';

const ClientsFoundTemplate = ({ isOpen, optionsFounded, handleClose }) => {
    if (!isOpen) {
        return '';
    }

    return (
        <>
            <div className={style.options} onClick={e => e.stopPropagation()}>
                <div>
                    {
                        optionsFounded
                    }
                </div>
            </div>
            <div className={style.wrapModal} id='wrapModalArea' onClick={handleClose} />
        </>
    );
};

export default ClientsFoundTemplate;

ClientsFoundTemplate.propTypes = {
    isOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    optionsFounded: PropTypes.node
};
