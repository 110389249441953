import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from './NewValueField.module.scss';
import DateInput from '../DateInput';
import { createDateForSaveFromDatePicker, isEmpty } from '../../utils';
import SelectBoxCommon from '../SelectField/SelectBoxCommon';

const NewValueField = ({ field, selectedItemId, updateField, initialValue, typeFieldObj = {} }) => {
    const [newItemTitle, setNewItemTitle] = useState(initialValue || '');

    useEffect(() => {
        initialValue && setNewItemTitle(initialValue || '');
    }, [initialValue]);

    const handleUpdateValueField = () => {
        newItemTitle && updateField({ [field]: newItemTitle });
    };

    const handleChangeUpdateDate = (value) => {
        setNewItemTitle(value);
        updateField({ [field]: createDateForSaveFromDatePicker(value) });
    };

    const handleChangeUpdateFromSelect = (value) => {
        setNewItemTitle(value);
        updateField({ [field]: value });
    };

    const inputString = (
        <input className={style.inputModal} type='newItem' id='newItem' value={newItemTitle} onChange={e => setNewItemTitle(e.target.value)} onBlur={handleUpdateValueField} />
    );

    const inputInteger = (
        <input className={style.inputModal} type='newItem' id='newItem' value={+newItemTitle} onChange={e => setNewItemTitle(+e.target.value)} onBlur={handleUpdateValueField} />
    );

    const inputSelect = (
        <SelectBoxCommon selectValueFather={+newItemTitle || 0} handleChangeSelect={handleChangeUpdateFromSelect} options={typeFieldObj.options} fieldName={typeFieldObj.fieldNameOption} classes={['modalSelect', 'backgroundGray']} />
    );

    const inputData = (
        <div className={style.dateInput}>
            <DateInput className={style.inputModal} handelChange={handleChangeUpdateDate} valueCar={newItemTitle} />
        </div>
    );

    return (
        <div className={style.childPadding} style={{ order: typeFieldObj.order || 1 }}>
            <div className={style.newReasonLabel} key={'inputItemName' + field + ' id: ' + selectedItemId}>
                <label htmlFor='newItem' className={style.reasonLabel}>
                    { field }
                    { field === 'title'
                        ? ' (ru):'
                        : (field === 'label' ? ' (en):' : ':') }
                </label>
                {
                    !isEmpty(typeFieldObj)
                        ? (typeFieldObj.type === 'date')
                            ? inputData
                            : (typeFieldObj.type === 'select')
                                ? inputSelect
                                : inputInteger
                        : inputString
                }
            </div>
        </div>
    );
};

NewValueField.propTypes = {
    field: PropTypes.any,
    selectedItemId: PropTypes.number,
    updateField: PropTypes.func,
    initialValue: PropTypes.any,
    typeFieldObj: PropTypes.object
};

export default NewValueField;
