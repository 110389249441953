import React, { useState } from 'react';
import PropTypes from 'prop-types';

import style from './FieldLabelFormIsObject.module.scss';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

const FieldLabelFormIsObject = ({ nameForm, valueField, propCar, forUpdateField, engines, bodies }) => {
    const [valueForm, setValueForm] = useState(valueField || '');

    const arrOptions = (propCar === 'engine') ? engines : (propCar === 'body' ? bodies : []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setValueForm(e.target.value);
        forUpdateField(e, e.target.value);
    };

    const options = arrOptions.map((item, index) => {
        return <option key={index + 1} value={item.id}>{ item.title }</option>;
    });

    const updateValue = (event) => {
        handleSubmit(event);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className={style.formSelect}>
                <label htmlFor='formSelectField'>{ nameForm }
                </label>
                <select value={valueForm} name='formSelectField' onChange={updateValue}>
                    {
                        options
                    }
                </select>
            </form>
        </div>
    );
};

FieldLabelFormIsObject.defaultProps = {
    toUpperCase: false,
    powerEngine: false,
    valueField: ''
};

FieldLabelFormIsObject.propTypes = {
    nameForm: PropTypes.string.isRequired,
    valueField: PropTypes.number,
    forUpdateField: PropTypes.func,
    propCar: PropTypes.string,
    bodies: PropTypes.array,
    engines: PropTypes.array
};

const mapStateToProps = ({ templatesOfFrameState: { engines, bodies } }) => {
    return {
        engines,
        bodies
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(FieldLabelFormIsObject);
