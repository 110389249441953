import React from 'react';
import PropTypes from 'prop-types';
import RegNumberCarEnd from '../CarBasicNumber/RegNumberCarEnd';

import style from './CarNumberInbox.module.scss';
import fromCarBasicNumber from '../CarBasicNumber/CarBasicNumber.module.scss';

const CarNumberInbox = ({ regNumberCar, regNumberCarEnd }) => {
    return (
        <div className={fromCarBasicNumber.carNumber}>
            <div className={style.carNumberIntro}>
                <span>{ regNumberCar.charAt(0).toUpperCase() }</span>
                { regNumberCar.slice(1, 4) }
                <span>{ regNumberCar.slice(4).toUpperCase() }</span>
            </div>
            <RegNumberCarEnd regNumberCarEnd={regNumberCarEnd} />
        </div>
    );
};

CarNumberInbox.defaultProps = {
    regNumberCarEnd: '__'
};

CarNumberInbox.propTypes = {
    regNumberCar: PropTypes.string,
    regNumberCarEnd: PropTypes.string.isRequired
};

export default CarNumberInbox;
