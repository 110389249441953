import style from './VisitorBody.module.scss';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import VisitorCommon from './components/VisitorCommon';
import VisitorSecretary from './components/VisitorSecretary/VisitorSecretary';
import VisitorGarage from './components/VisitorGarage/VisitorGarage';
import PropTypes from 'prop-types';

const Res3 = () => (<div>ID: 2</div>);

const VisitorBody = ({ visitorId }) => {
    return (
        <div className={style.svBookDetails}>
            <Switch>
                <Route path='/visitors/:id' component={VisitorCommon} exact />
                <Route path='/visitors/:id/secretary' render={() => <VisitorSecretary clientId={visitorId} />} exact />
                <Route path='/visitors/:id/service' component={Res3} exact />
                <Route path='/visitors/:id/garage' component={VisitorGarage} exact />
            </Switch>
        </div>
    );
};

VisitorBody.propTypes = {
    visitorId: PropTypes.number
};

export default VisitorBody;
