import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

export default function mapBuildDatesPeriods(arrWithDateStartEnd, start = 'date_start', end = 'date_end') {
    if (!Array.isArray(arrWithDateStartEnd) || (arrWithDateStartEnd && arrWithDateStartEnd.length === 0)) {
        return arrWithDateStartEnd;
    }

    if ((arrWithDateStartEnd.length > 0) && (Object.prototype.hasOwnProperty.call(arrWithDateStartEnd[0], start) && Object.prototype.hasOwnProperty.call(arrWithDateStartEnd[0], end))) {
        return arrWithDateStartEnd.map(item => {
            const dateStart = format(new Date(item[start]), 'dd MMM', { locale: ru });
            const dateEnd = format(new Date(item[end]), 'dd MMM', { locale: ru });
            return { ...item, times: `(${dateStart} - ${dateEnd})` };
        });
    } else {
        return arrWithDateStartEnd;
    }
}
