import React from 'react';
import MiddleInfo from '../MiddleInfo';

import './MainInfo.module.scss';
import style from '../MiddleInfo/MiddleInfo.module.scss';

const MainInfoSettings = () => {
    return (
        <div className={style.middleInfo}>
            <MiddleInfo />
        </div>
    );
};

export default MainInfoSettings;
