import React, { useEffect, useState } from 'react';
import style from './PureTimeInput.module.scss';
import arrow from '../../../assets/arrow-down.png';
import { HOURS_COUNT_OBJ, MINUTES_COUNT_OBJ } from '../../../constants/WeekDays';
import InputElement from 'react-input-mask';
import PropTypes from 'prop-types';
import OptionsWrapPopup from '../../Selects/OptionsWrapPopup';
import CustomOptionSelect from '../../Selects/CustomOptionSelect';

function wrappedOptions(options, disabledItems, handleSelectValue, selectedValue, keysOptions, classesOption) {
    return options && options.length > 0 && options.map((item) => {
        return (
            <CustomOptionSelect
                key={`month${item[keysOptions.value]}`}
                keysOptions={keysOptions}
                option={item}
                selectedValue={selectedValue}
                handleSelectValue={() => handleSelectValue(item[keysOptions.value])}
                isDisable={!!disabledItems.find(disableItem => disableItem === item[keysOptions.value])}
                classesOption={classesOption}
            />
        );
    });
}

const InputTimesPopup = ({
    handleClose,
    classesOptions,
    classesOption,
    selectedHours = 10,
    selectedMinutes = 10,
    handleSetHours = () => {},
    keysOptions = {
        value: 'value',
        title: 'title'
    }
}) => {
    return (
        <OptionsWrapPopup
            options={
                wrappedOptions(
                    HOURS_COUNT_OBJ,
                    [],
                    (value) => handleSetHours({ hours: value }),
                    selectedHours,
                    keysOptions,
                    [...(classesOption || []), 'blueSelect', 'itemCenter']
                )
            }
            options2={
                wrappedOptions(
                    MINUTES_COUNT_OBJ,
                    [],
                    (value) => handleSetHours({ minutes: value }),
                    selectedMinutes, keysOptions,
                    [...(classesOption || []), 'blueSelect', 'itemCenter']
                )
            }
            handleClose={handleClose}
            classesOptions={[...(classesOptions || []), 'noPadding']}
        />
    );
};

InputTimesPopup.propTypes = {
    selectedHours: PropTypes.number,
    selectedMinutes: PropTypes.number,
    handleSetHours: PropTypes.func,
    handleClose: PropTypes.func,
    classesOption: PropTypes.array,
    classesOptions: PropTypes.array,
    keysOptions: PropTypes.object
};

function createTimeToString(data) {
    return String(data).padStart(2, '0');
}

const PureTimeInput = ({ value = 1200, disabled, withArrow, textSpan, timeIntervals, onClick, classes = [] }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [newValue, setNewValue] = useState(0);
    const [isFocus, setIsFocus] = useState(false);

    useEffect(() => {
        setNewValue(createTimeToString(Math.floor(value / 60)) + createTimeToString(value % 60));
    }, [value]);

    const handleUpdateTime = (valuePic) => {
        const separateString = valuePic.split(':');
        const resultPush = separateString[0] * 60 + Number(separateString[1]);
        if (!isNaN(resultPush)) {
            onClick(resultPush);
        }
        setNewValue(valuePic);
    };

    const handleOnClick = (data) => {
        if (!withArrow) {
            onClick(data);
        }
    };

    const handlePickTime = ({ hours, minutes }) => {
        if (hours) {
            setNewValue(s => {
                return String(hours).padStart(2, '0') + s.slice(2, 4);
            });
            const resultPush = hours * 60 + (+newValue.slice(2, 4));
            onClick(resultPush);
        }

        if (minutes || minutes === 0) {
            setNewValue(s => {
                return s.slice(0, 2) + String(minutes).padStart(2, '0');
            });
            const resultPush = (+newValue.slice(0, 2) * 60) + minutes;
            onClick(resultPush);
        }
    };

    const handlePopup = () => {
        setIsPopupOpen(s => {
            setIsFocus(!s);
            return !s;
        });
    };

    return (
        <div
            className={style.timeInput + (disabled ? ' ' + style.disabled : '') + (isFocus ? ' ' + style.focused : '') + (!withArrow ? ' ' + style.withArrow : '') + classes.map(item => ' ' + style[item]).join('')}
            onClick={handleOnClick}
        >
            {
                isPopupOpen && (
                    <InputTimesPopup
                        handleSetHours={handlePickTime}
                        selectedHours={+newValue.slice(0, 2)}
                        selectedMinutes={+newValue.slice(2, 4)}
                        timeIntervals={timeIntervals}
                        classesOptions={['width183']}
                        handleClose={handlePopup}
                    />
                )
            }
            <div className={style.inputField}>
                {
                    textSpan && <span className={style.spanTimeInput}>{ textSpan }</span>
                }
                <div className={style.time}>
                    <InputElement
                        className={style.hours}
                        type='text'
                        value={newValue}
                        onChange={(e) => handleUpdateTime(e.target.value)}
                        onFocus={() => setIsFocus(true)}
                        onBlur={() => setIsFocus(false)}
                        mask='99:99'
                    />
                </div>
                {
                    withArrow
                        ? (
                            <div
                                className={style.imgDateInput}
                                onClick={handlePopup}
                            >
                                <img src={arrow} alt='' />
                            </div>
                        )
                        : ''
                }
            </div>
        </div>
    );
};

PureTimeInput.propTypes = {
    value: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    textSpan: PropTypes.string,
    withArrow: PropTypes.bool,
    classes: PropTypes.array,
    timeIntervals: PropTypes.number
};

export default PureTimeInput;
