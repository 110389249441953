import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './OilsEveryMileage.module.scss';
import { fetchPostCarOil } from 'actions';

function handleUpdate(value, fieldName, clientIdCollect, id, fetchPostCarOil) {
    const post = {
        car_id: id,
        [fieldName]: value === '' ? null : +value
    };

    id && (+value || value === '') && fetchPostCarOil(post, clientIdCollect);
}

const OilsEveryMileage = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fieldName,
    fetchPostCarOil,
    carOilUpdateError,
    isValid
}) => {
    const [value, setValue] = useState(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');

    useEffect(() => {
        setValue(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');
    }, [carDetailsInfo, fieldName]);

    const handleSubmit = (e) => {
        e.preventDefault();
        carDetailsInfo && !((carDetailsInfo[fieldName] === null) && (value === '' || value === '0')) && (carDetailsInfo[fieldName] !== +value) && handleUpdate(value, fieldName, clientIdCollect, id, fetchPostCarOil);
    };

    useEffect(() => {
        const timer = setTimeout((carDetailsInfo, value, fieldName, clientIdCollect, id, fetchPostCarOil) => {
            (!((carDetailsInfo[fieldName] === null) && (value === '' || value === '0')) && (carDetailsInfo[fieldName] !== +value)) && fieldName && clientIdCollect && id && handleUpdate(value, fieldName, clientIdCollect, id, fetchPostCarOil);
        }, 2000, carDetailsInfo, value, fieldName, clientIdCollect, id, fetchPostCarOil);
        return () => {
            clearTimeout(timer);
        };
    }, [carDetailsInfo, value, fieldName, clientIdCollect, id, fetchPostCarOil]);

    if (carOilUpdateError) {
        return <div>Something is wrong</div>;
    }

    return (
        <form key={'oilChange_' + fieldName} onSubmit={handleSubmit} onBlur={handleSubmit} className={style.formOilEveryMileage}>
            <input value={value} key={'oilCycleKm' + fieldName} id={'oilCycleKm_' + fieldName} className={style.oilCycleKm + (isValid ? '' : ' ' + style.redBorder)} onChange={(e) => setValue(e.target.value)} />км
        </form>
    );
};

OilsEveryMileage.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldName: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    isValid: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilsEveryMileage);
