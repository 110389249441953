import {
    typeSaveReportsCollectionSheets,
    typeSetNewTimeRangeReports,
    typeSaveCollectionSheetHistory,
    typeSaveCollectionSheetHistoryError,
    typeSaveCollectionSheetHistorySuccess,
    typeSaveCollectionSheetDetails,
    typeSetOwnerIdSheets,
    typeSetStatusesPreferSheets,
    typeSetCollectionSheets
} from '../actionsTypes';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

// ------------------------------------
// Constants
// ------------------------------------
const SAVE_REPORTS_COLLECTION_SHEETS = typeSaveReportsCollectionSheets;
const SET_NEW_TIME_RANGE_REPORTS = typeSetNewTimeRangeReports;
const SAVE_COLLECTION_SHEET_HISTORY = typeSaveCollectionSheetHistory;
const SAVE_COLLECTION_SHEET_DETAILS = typeSaveCollectionSheetDetails;
const SAVE_COLLECTION_SHEET_ERROR = typeSaveCollectionSheetHistoryError;
const SAVE_COLLECTION_SHEET_SUCCESS = typeSaveCollectionSheetHistorySuccess;
const SET_OWNER_ID_SHEETS = typeSetOwnerIdSheets;
const SET_STATUSES_PREFER_SHEETS = typeSetStatusesPreferSheets;
const SET_COLLECTION_SHEETS = typeSetCollectionSheets;

// ------------------------------------
// Actions
// ------------------------------------

export const saveCollectionSheets = (payload) => {
    return {
        type: SAVE_REPORTS_COLLECTION_SHEETS,
        payload: payload
    };
};

export const saveCollectionSheetHistory = (payload) => {
    return {
        type: SAVE_COLLECTION_SHEET_HISTORY,
        payload: payload
    };
};

export const saveCollectionSheetDetails = (payload) => {
    return {
        type: SAVE_COLLECTION_SHEET_DETAILS,
        payload: payload
    };
};

export const collectionSheetError = (payload) => {
    return {
        type: SAVE_COLLECTION_SHEET_ERROR,
        payload: payload
    };
};

export const collectionSheetSuccess = (payload) => {
    return {
        type: SAVE_COLLECTION_SHEET_SUCCESS,
        payload: payload
    };
};

export const getReportCollectionSheets = (data = null) => (dispatch, getState, { apiService }) => {
    dispatch(collectionSheetSuccess(true));
    dispatch(collectionSheetError(false));
    dispatch(saveCollectionSheets([]));
    return apiService.getReportsCollectionSheets(data)
        .then((data) => {
            dispatch(saveCollectionSheets(data));
            dispatch(collectionSheetSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(collectionSheetSuccess(false));
            dispatch(collectionSheetError(true));
            return err;
        });
};

export const getCollectionSheetHistory = (carId, clientId) => (dispatch, getState, { apiService }) => {
    dispatch(collectionSheetSuccess(true));
    dispatch(collectionSheetError(false));
    dispatch(saveCollectionSheetHistory([]));
    dispatch(saveCollectionSheetDetails({}));
    return carId && clientId && apiService.getCollectionSheetHistoryLogs(carId, clientId)
        .then((data) => {
            data && dispatch(saveCollectionSheetHistory(data.history_log));
            data && dispatch(saveCollectionSheetDetails(data.collection_sheet_details));
            dispatch(collectionSheetSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(collectionSheetSuccess(false));
            dispatch(collectionSheetError(true));
            return err;
        });
};

export const setNewRanges = (payload) => {
    return {
        type: SET_NEW_TIME_RANGE_REPORTS,
        payload: payload
    };
};

export const setOwnerIdSheets = (payload) => {
    return {
        type: SET_OWNER_ID_SHEETS,
        payload: payload
    };
};

export const setStatusesPreferSheets = (payload) => {
    return {
        type: SET_STATUSES_PREFER_SHEETS,
        payload: payload
    };
};

export const setCollectionSheets = (payload) => {
    return {
        type: SET_COLLECTION_SHEETS,
        payload: payload
    };
};

export const getCollectionSheetsOwnerStatus = (ownerId, statusesPrefer, timeRange) => (dispatch, getState, { apiService }) => {
    dispatch(collectionSheetSuccess(true));
    dispatch(collectionSheetError(false));
    const data = {
        time_from: timeRange.timeFrom,
        time_to: timeRange.timeTo
    };
    dispatch(setCollectionSheets([]));
    return ownerId && statusesPrefer && apiService.getCollectionSheets(ownerId, statusesPrefer, data)
        .then((data) => {
            dispatch(setCollectionSheets(data));
            dispatch(collectionSheetSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(collectionSheetSuccess(false));
            dispatch(collectionSheetError(true));
            return err;
        });
};

export const actions = {
    getReportCollectionSheets,
    setNewRanges,
    setOwnerIdSheets,
    setStatusesPreferSheets,
    getCollectionSheetsOwnerStatus
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SAVE_REPORTS_COLLECTION_SHEETS]: (state, action) => {
        return ({
            ...state,
            collectionSheets: action.payload
        });
    },

    [SET_NEW_TIME_RANGE_REPORTS]: (state, action) => {
        return ({
            ...state,
            timeFrom: action.payload.from ? action.payload.from : state.timeFrom,
            timeTo: action.payload.to ? action.payload.to : state.timeTo
        });
    },

    [SAVE_COLLECTION_SHEET_HISTORY]: (state, action) => {
        return ({
            ...state,
            collectionSheetHistory: action.payload
        });
    },

    [SAVE_COLLECTION_SHEET_DETAILS]: (state, action) => {
        return ({
            ...state,
            collectionSheetDetails: action.payload
        });
    },

    [SAVE_COLLECTION_SHEET_ERROR]: (state, action) => {
        return ({
            ...state,
            collectionSheetError: action.payload
        });
    },

    [SAVE_COLLECTION_SHEET_SUCCESS]: (state, action) => {
        return ({
            ...state,
            collectionSheetSuccess: action.payload
        });
    },

    [SET_OWNER_ID_SHEETS]: (state, action) => {
        return ({
            ...state,
            ownerId: action.payload
        });
    },

    [SET_STATUSES_PREFER_SHEETS]: (state, action) => {
        return ({
            ...state,
            statusesPrefer: action.payload
        });
    },

    [SET_COLLECTION_SHEETS]: (state, action) => {
        return ({
            ...state,
            collectionSheetsOwner: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            collectionSheets: [],
            timeFrom: formatISO(startOfDay(new Date())),
            timeTo: formatISO(endOfDay(new Date())),
            collectionSheetHistory: [
                /* {
                    id: 1,
                    createdAt: '2020-04-15T14:13:08+10:00',
                    carCollectBlock: { id: 2, title: 'ОСАГО' },
                    field: 'Дата окончания:',
                    value: '25.08.21',
                    creator: { id: 2, name: 'Александр Рабченко', phone: "+79332884465", role: "PARTNER_EXTEND", roleLabel: "Администратор" },
                    carCollectBlockId: 2,
                    carCollectBlockTitle: 'ОСАГО',
                    sheetFieldId: 23,
                    collectionSheetId: 3,
                } */
            ],
            collectionSheetDetails: {
                /* clientName: 'Мишка Мишка',
                clientPhone: '+7(914)555-55-55',
                carNumber: 'y125oo',
                carNumberRegion: '125',
                carMark: 'Nissan',
                carModel: 'Murano' */
            },
            collectionSheetError: false,
            collectionSheetSuccess: false,
            ownerId: 0,
            statusesPrefer: '',
            collectionSheetsOwner: [
                /* {
                    id: 2,
                    carId: 6852,
                    clientId: 88,
                    clientName: 'Мишка Мишка',
                    clientPhone: '+7(914)555-55-55',
                    carNumber: 'y125oo',
                    carNumberRegion: '125',
                    carMark: 'Nissan',
                    carModel: 'Murano'
                },
                {
                    id: 3,
                    carId: 6900,
                    clientId: 87343,
                    clientName: 'Tarrararar',
                    clientPhone: '+7(914)555-55-55',
                    carNumber: 'y125oo',
                    carNumberRegion: '125',
                    carMark: 'Nissan',
                    carModel: 'Murano'
                } */
            ]
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.reportsCollectionSheets, action) : state.reportsCollectionSheets;
};

export default commonReducer;
