import React from 'react';

import style from './DaysOfWeekForm.module.scss';
import CheckBoxCommon from '../CheckBoxes/CheckBoxesCommon';
import PropTypes from 'prop-types';

const DaySecretary = ({ classes, value, isChecked, handleCheckDay }) => {
    return (
        <div className={classes + ' ' + (isChecked ? style.checkedDay : '')} onClick={handleCheckDay}>{ value }</div>
    );
};

const DaysOfWeekForm = ({
    stateWeek,
    handlerCheck,
    handleCheckDay,
    days = [],
    classes = []
}) => {
    return (
        <div className={style.daysOfWeekForm + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.daysOfWeekGroup}>
                <div className={style.daysGroup}>

                    <div className={style.line}>
                        <div className={style.underCheckbox}>
                            <CheckBoxCommon stateChecked={stateWeek} handleChecked={handlerCheck} textLabel='' withCheckBox classes={['forSecretary']} />
                        </div>
                    </div>
                </div>
                <div className={style.daysOfWeek}>
                    {
                        days.length > 0
                            ? days.map((day, index) => {
                                const classes = (style.day +
                                    ' ' +
                                    ((day.day === 'monday') ? style.monday : '') +
                                    ' ' +
                                    ((day.day === 'saturday') ? style.saturday : '') +
                                    ' ' +
                                    ((day.day === 'sunday') ? style.sunday : '') +
                                    ' ' +
                                    ((day.day === 'friday') ? style.friday : '')
                                );
                                return <DaySecretary key={'day&' + day.day} classes={classes} value={days[index].value} isChecked={day.isChecked} handleCheckDay={() => handleCheckDay(day.day, !day.isChecked)} />;
                            })
                            : ''
                    }
                </div>
            </div>
        </div>
    );
};

DaysOfWeekForm.propTypes = {
    days: PropTypes.array,
    classes: PropTypes.array,
    handleCheckDay: PropTypes.func,
    handlerCheck: PropTypes.func,
    stateWeek: PropTypes.bool
};

DaySecretary.propTypes = {
    value: PropTypes.string,
    classes: PropTypes.string,
    isChecked: PropTypes.bool,
    handleCheckDay: PropTypes.func
};

export default DaysOfWeekForm;
