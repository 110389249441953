import React from 'react';
import PropTypes from 'prop-types';

import SvBasicHeader from 'components/SvBasicHeader';

import bodyTypeImg from './assets/carBodyType.svg';
import bodyColorImg from './assets/bodyColor.svg';
import bodyNumberImg from './assets/carNumberBody.svg';
import chassisImg from './assets/shassis.svg';

import style from './LowNodes.module.scss';
import styleParent from '../../SvBookDetails.module.scss';
import { connect } from 'react-redux';
import { SetCarDetails, SetCarDetailsObjectsFields, SetCarDetailsColors } from 'components/Requests';

const LowNodes = ({ carDetailsInfo }) => {
    return (
        <div className={`${style.carBody}`}>
            <SvBasicHeader header='Дополнительно об авто' />
            <div className={styleParent.inlineFiles}>
                <SetCarDetailsObjectsFields nameForm='Тип авто' propCar='body' key='body' valueField={carDetailsInfo.body || null} />
                <div>
                    <img src={bodyTypeImg} alt='bodyTypeImg' />
                </div>
            </div>
            <div className={styleParent.inlineFiles}>
                <SetCarDetailsColors nameForm='Цвет' propCar='color' key='color2' valueField={carDetailsInfo.color || null} />
                <div>
                    <img src={bodyColorImg} alt='bodyColorImg' />
                </div>
            </div>
            <div className={styleParent.inlineFiles}>
                <SetCarDetails nameForm='Номер кузова (vin)' propCar='vin' key='vin' valueField={carDetailsInfo.bodyVin} toUpperCase />
                <div>
                    <img src={bodyNumberImg} alt='bodyNumberImg' />
                </div>
            </div>
            <div className={styleParent.inlineFiles}>
                <SetCarDetails nameForm='Шасси' propCar='chassis_id' key='chassis_id' valueField={carDetailsInfo.chassisId} />
                <div>
                    <img src={chassisImg} alt='chassisImg' />
                </div>
            </div>
        </div>
    );
};

LowNodes.defaultProps = {
    carDetailsInfo: {}
};

LowNodes.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(LowNodes);
