import React from 'react';
import PropTypes from 'prop-types';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';
import SearchResultCars from '../SearchResultCars';

import style from '../ServiceBooks/ServiceBooks.module.scss';

const GetSearchCars = ({ sBooksHeader, foundCars, totalCountFoundCars, fetchingSearchCars }) => {
    return (
        <div>
            <SearchResultCars sBooksHeader={sBooksHeader[2]} cars={foundCars} bgClassName={style.mgBottom} totalCountFoundCars={totalCountFoundCars} isFetching={fetchingSearchCars} btn isSearch />
        </div>
    );
};

GetSearchCars.propTypes = {
    sBooksHeader: PropTypes.array,
    foundCars: PropTypes.array,
    totalCountFoundCars: PropTypes.number,
    fetchingSearchCars: PropTypes.bool
};

const mapStateToProps = ({ carsListState: { textSearch, foundCars, totalCountFoundCars, fetchingSearchCars }, templatesOfFrameState: { sBooksHeader } }) => {
    return {
        textSearch,
        sBooksHeader,
        foundCars,
        totalCountFoundCars,
        fetchingSearchCars
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(GetSearchCars);
