import React, { useEffect } from 'react';
import ProlongationReportBody from '../ProlongationReportBody';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStatisticYearProlongation } from '../../../../../../reducers/reportsProlongation';

const ProlongationReportBodyWrap = ({ getStatisticYearProlongation, statisticYearProlongation, statisticYearProlongationSuccess, yearForReport }) => {
    useEffect(() => {
        getStatisticYearProlongation({ year: yearForReport });
    }, [yearForReport, getStatisticYearProlongation]);

    return (
        <ProlongationReportBody statisticYearProlongation={statisticYearProlongation} loading={statisticYearProlongationSuccess} />
    );
};

ProlongationReportBodyWrap.propTypes = {
    statisticYearProlongation: PropTypes.array,
    statisticYearProlongationSuccess: PropTypes.bool,
    getStatisticYearProlongation: PropTypes.func,
    yearForReport: PropTypes.string
};

const mapStateToProps = ({ reportsProlongation: { statisticYearProlongation, statisticYearProlongationSuccess, yearForReport } }) => {
    return {
        statisticYearProlongation,
        statisticYearProlongationSuccess,
        yearForReport
    };
};

export default connect(mapStateToProps, { getStatisticYearProlongation })(ProlongationReportBodyWrap);
