import { PROLONGATION_FILTER_FORMATS } from '../constants/formats';
import { endOfMonth, endOfWeek, endOfYear, format, startOfMonth, startOfWeek, startOfYear, subDays } from 'date-fns';
import { INITIAL_DATE_PROLONGATION } from '../constants/initialDateProlongation';

export function dateRangeFromTo(tag, date = new Date(subDays(new Date(), 1))) {
    let from, to;
    if (tag === PROLONGATION_FILTER_FORMATS.week.label) {
        from = startOfWeek(date && typeof date === 'string' ? new Date(date) : +date, { weekStartsOn: 1 });
        from = from < new Date(INITIAL_DATE_PROLONGATION) ? startOfWeek(new Date(INITIAL_DATE_PROLONGATION)) : from;

        to = endOfWeek(date && typeof date === 'string' ? new Date(date) : +date, { weekStartsOn: 1 });
        to = to > new Date(subDays(new Date(), 1)) ? new Date(subDays(new Date(), 1)) : to;
    } else if (tag === PROLONGATION_FILTER_FORMATS.month.label) {
        from = startOfMonth(date && typeof date === 'string' ? new Date(date) : +date);
        from = from < new Date(INITIAL_DATE_PROLONGATION) ? startOfMonth(new Date(INITIAL_DATE_PROLONGATION)) : from;

        to = endOfMonth(date && typeof date === 'string' ? new Date(date) : +date);
        to = to > new Date(subDays(new Date(), 1)) ? new Date(subDays(new Date(), 1)) : to;
    } else if (tag === PROLONGATION_FILTER_FORMATS.year.label) {
        from = startOfYear(date && typeof date === 'string' ? new Date(date) : +date);
        from = from < new Date(INITIAL_DATE_PROLONGATION) ? new Date(INITIAL_DATE_PROLONGATION) : from;

        to = endOfYear(date && typeof date === 'string' ? new Date(date) : +date);
        to = to > new Date(subDays(new Date(), 1)) ? new Date(subDays(new Date(), 1)) : to;
    } else if (tag === PROLONGATION_FILTER_FORMATS.allTime.label) {
        from = new Date(INITIAL_DATE_PROLONGATION);

        to = new Date(subDays(new Date(), 1));
    } else {
        from = new Date(subDays(new Date(), 1));
        to = new Date(subDays(new Date(), 1));
    }
    return {
        from: format(from, 'yyyy-MM-dd'),
        to: format(to, 'yyyy-MM-dd')
    };
}
