import React from 'react';
import PropTypes from 'prop-types';
import { compose, withApiService } from '../../Hoc';
import { saveCarImage } from '../../actions';
import _ from 'lodash';

import style from './SvBasicImg.module.scss';
import { connect } from 'react-redux';
import Loader from '../Loader';

const SvBasicImg = ({ carDetailsInfo, savingMainImageCarsIds, saveCarImage }) => {
    const mainPhoto = _.find(carDetailsInfo.photo, 'is_main');
    const src = _.get(mainPhoto, 'thumbnails.carGallery', _.get(mainPhoto, 'url'));

    const handleAddPhoto = (e) => {
        const formData = new FormData();
        const file = e.target.files.length && /\.(jpe?g|png|gif)$/i.test(e.target.files[0].name) ? e.target.files[0] : null;

        if (file) {
            formData.append('file', file);
            saveCarImage(formData, carDetailsInfo.id, { is_main: 1 });
        }
    };

    const loading = _.includes(savingMainImageCarsIds, carDetailsInfo.id);
    const label = loading ? null : <label htmlFor='mainCarImage' />;
    return (
        <div className={style.imgIn}>
            <input type='file' id='mainCarImage' accept='image/*,image/jpeg' onChange={handleAddPhoto} className={style.inputHidden} />
            { label }
            { src
                ? <div className={style.imgIn}><img src={src} alt='' className={style.carBasicImg} /></div>
                : null }
            <Loader active={loading} classes={['small']} />
        </div>
    );
};

SvBasicImg.propTypes = {
    carDetailsInfo: PropTypes.shape({
        photo: PropTypes.array,
        id: PropTypes.number
    }),
    saveCarImage: PropTypes.func,
    savingMainImageCarsIds: PropTypes.array
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, savingMainImageCarsIds } }) => {
    return {
        carDetailsInfo,
        savingMainImageCarsIds
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        saveCarImage: saveCarImage(apiService, dispatch)
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SvBasicImg);
