import React, { useEffect } from 'react';
import SheetCollectBody from './SheetCollectBody';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqueSheetCollectUpload, carIdCollectLoaded, clientIdCollectLoaded, resetCarCollectId } from '../../actions';
import { getPreReserves, saveReserveOrOrder } from '../../reducers/preReserve';
import { getProlongationsFlagById, updateProlongations } from '../../reducers/prolongation';
import SheetCollectHeaderContainer from './SheetCollectHeaderContainer';
import LoadingIndicatorBlock from '../../components/LoadingIndicator/LoadingIndicatorBlock';

const UniqueSheetCollectContainer = ({
    resetCarCollectId,
    clientIdCollectLoaded,
    sheetIdRaw,
    carIdCollectLoaded,
    getProlongationsFlagById,
    preReservesSuccess,
    fetchCarDetailsSuccess,
    uniqueSheetCollectUpload,
    carDetailsInfo,
    updateProlongations,
    getPreReserves
}) => {
    useEffect(() => {
        carDetailsInfo?.id && carIdCollectLoaded(carDetailsInfo?.id);
    }, [carIdCollectLoaded, carDetailsInfo?.id, sheetIdRaw]);

    useEffect(() => {
        carDetailsInfo?.clientId && clientIdCollectLoaded(carDetailsInfo?.clientId);
    }, [clientIdCollectLoaded, carDetailsInfo?.clientId, sheetIdRaw]);

    useEffect(() => {
        sheetIdRaw && uniqueSheetCollectUpload(sheetIdRaw)
            .then(() => {
                getPreReserves({ carId: carDetailsInfo?.id, clientId: carDetailsInfo?.clientId });
            });
    }, [sheetIdRaw, uniqueSheetCollectUpload, getPreReserves, carDetailsInfo?.id, carDetailsInfo?.clientId]);

    useEffect(() => {
        sheetIdRaw && getProlongationsFlagById(sheetIdRaw);
    }, [sheetIdRaw, getProlongationsFlagById]);

    useEffect(() => {
        return () => resetCarCollectId();
    }, [resetCarCollectId]);

    return (
        <>
            <LoadingIndicatorBlock reasonLoader={preReservesSuccess || fetchCarDetailsSuccess} />
            <SheetCollectHeaderContainer />
            <SheetCollectBody updateProlongationsFlag={(data) => updateProlongations(sheetIdRaw, data)} />
        </>
    );
};

UniqueSheetCollectContainer.propTypes = {
    resetCarCollectId: PropTypes.func,
    clientIdCollectLoaded: PropTypes.func,
    carIdCollectLoaded: PropTypes.func,
    getProlongationsFlagById: PropTypes.func,
    uniqueSheetCollectUpload: PropTypes.func,
    sheetIdRaw: PropTypes.number,
    preReservesSuccess: PropTypes.bool,
    fetchCarDetailsSuccess: PropTypes.bool,
    carDetailsInfo: PropTypes.object,
    updateProlongations: PropTypes.func,
    getPreReserves: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, fetchCarDetailsSuccess }, preReserve: { preReservesSuccess } }) => {
    return {
        preReservesSuccess,
        fetchCarDetailsSuccess,
        carDetailsInfo
    };
};

export default connect(mapStateToProps, {
    uniqueSheetCollectUpload,
    resetCarCollectId,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    carIdCollectLoaded,
    getProlongationsFlagById,
    updateProlongations,
    getPreReserves
})(UniqueSheetCollectContainer);
