import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import style from './SvMarkModelYear.module.scss';

const SvMarkModelYear = ({ carDetailsInfo }) => {
    const { mark, model, year } = carDetailsInfo;
    return (
        <div className={style.carBasicBody}>
            <div className={style.mark}>{ mark }</div>
            <div className={style.model}>{ model }</div>
            <div>{ year }</div>
        </div>
    );
};

SvMarkModelYear.propTypes = {
    carDetailsInfo: PropTypes.object
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(SvMarkModelYear);
