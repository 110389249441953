import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose, withApiService } from '../../Hoc';
import FieldLabelForm from '../FieldLabelForm/FieldLabelForm';
import PropTypes from 'prop-types';

const SetCarDetails = ({ carId: id, nameForm, apiService, valueField, propCar, toUpperCase }) => {
    const initialCarId = useMemo(() => ({
        id: +id
    }), [id]);
    const initialDataCarField = useMemo(() => (valueField !== null ? {
        id: id,
        [propCar]: valueField
    } : { id: id }), [valueField, id, propCar]);

    const [carId, setCarId] = useState(initialCarId);

    const [query, setQuery] = useState(initialDataCarField);
    const [valueForm, setValueForm] = useState(valueField);

    const [isLoadingField, setIsLoadingField] = useState(true);
    const [isError, setIsError] = useState(false);
    const [fetch, setFetch] = useState(false);

    useEffect(() => {
        let cancelled = false;
        setCarId(id);
        setValueForm(valueField);

        async function fetchData(apiService, query, carId) {
            const result = {};
            try {
                const result = await apiService.updateSVBook(query, carId);
                result[0].id && !cancelled && setIsLoadingField(false);
            } catch (e) {
                // console.log('error', e);
                !cancelled && setIsError(true);
            }

            return result;
        }
        fetch ? fetchData(apiService, query, id) : setIsLoadingField(false);

        return () => { cancelled = true; };
    }, [carId, query, apiService, id, valueField, fetch]);

    const handleUpdate = (e, valueUp) => {
        setQuery({
            id: carId,
            [propCar]: valueUp
        });
        setFetch(true);
        e.preventDefault();
    };

    if (isError) {
        return <div>Something is wrong</div>;
    }

    if (isLoadingField) {
        return <div>Loading...</div>;
    }

    return (
        <FieldLabelForm nameForm={nameForm} valueField={valueForm} forUpdateField={(e, val) => handleUpdate(e, val)} toUpperCase={toUpperCase} />
    );
};

SetCarDetails.propTypes = {
    carId: PropTypes.number.isRequired,
    nameForm: PropTypes.string.isRequired,
    apiService: PropTypes.object.isRequired,
    valueField: PropTypes.any,
    propCar: PropTypes.string.isRequired,
    toUpperCase: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carId } }) => {
    return {
        carId
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(SetCarDetails);
