import React, { useEffect, useState } from 'react';
import WrapFilter from '../../../../../../components/FiltersTables/WrapFilter';
import DateRangeProlongationReports from '../../../../ReportsHeader/DateRangeProlongationReports';
import { endOfDateIso, startOfDateIso } from '../../../../../../utils';
import FilterRangesTimesWrap from '../../../../../../components/FiltersTables/FilterRangesTimes/FilterRangesTimesWrap';
import WrapTable from '../WrapTable';
import style from './ProlongationInActionBodyWrap.module.scss';
import TableProlongation from '../TableProlongation';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import download from 'downloadjs';
import { format, subDays } from 'date-fns';
import ProlongationFiltersBoard from '../ProlongationFiltersBoard/ProlongationFiltersBoard';
import {
    changeUnionFlag,
    picProlongationFormat,
    getReportProlongationForRange,
    setRangeReportProlongationInAction,
    exportProlongationReport
} from '../../../../../../reducers/reportsProlongationInAction';
import LoadingIndicatorBlock from '../../../../../../components/LoadingIndicator/LoadingIndicatorBlock';
import CheckBoxCommon from '../../../../../../components/CheckBoxes/CheckBoxesCommon';
import { GrayButton } from '../../../../../../components/Buttons';
import {
    initialChunkProlongation,
    initialTimeRangeProlongation
} from '../../../../../../constants/initialTimeRangeProlongation';
import { useHistory, useLocation } from 'react-router-dom';
import { OPTIONS_PACKETS, OPTIONS_PACKETS_LABELS } from '../../../../../../constants/packets';
import { getRangeAndChunk } from '../../../../../../utils/getRangeAndChunk';
import qs from 'query-string';
import { PROLONGATION_FILTER_FORMATS } from '../../../../../../constants/formats';
import getQueryVariable from '../../../../../../utils/getQueryVariable';
import { PROLONGATION_MODE_PROLONGATION } from '../../../../../../constants/prolongationMode';
import addedParamToUrl from '../../../../../../utils/addedParamToUrl';

function setQueryForProlongation(prolongationFormat, prolongationRange, activeFilter, unionFlag, packets, history, pathname, search, mode) {
    const timeFrom = prolongationRange.from;
    const timeTo = prolongationRange.to;
    let chunks;
    let from;
    let to;

    if (prolongationRange.from && prolongationRange.to && prolongationFormat) {
        const { chunkDateLocal, fromDateLocal, toDateLocal } = getRangeAndChunk(timeFrom, timeTo);
        if (activeFilter) {
            chunks = prolongationFormat;
            from = format(new Date(timeFrom), 'yyyy-MM-dd');
            to = format(new Date(timeTo), 'yyyy-MM-dd');
        } else {
            chunks = prolongationFormat || chunkDateLocal;
            from = fromDateLocal;
            to = toDateLocal;
        }

        const packetsFoGet = packets?.length > 0
            ? packets.map(item => OPTIONS_PACKETS.find(packet => packet.id === item)?.label)
            : OPTIONS_PACKETS_LABELS;

        addedParamToUrl(
            ['chunks', 'from', 'to', 'unionFlag', 'packets', 'activeFilter', 'mode'],
            [chunks, from, to, +unionFlag, packetsFoGet, activeFilter, mode],
            history,
            pathname,
            search
        );
    }
}

function getQueryVariableArray(locationSearchArray, field, initial = []) {
    return locationSearchArray[field]
        ? (
            Array.isArray(locationSearchArray[field])
                ? locationSearchArray[field].map(item => OPTIONS_PACKETS.find(packet => packet.label === item)?.id)
                : [OPTIONS_PACKETS.find(packet => packet.label === locationSearchArray[field])?.id]
        )
        : initial;
}

const ProlongationInActionBodyWrap = ({
    prolongationResult,
    getReportProlongationForRange,
    prolongationPacket,
    prolongationRange,
    prolongationSuccess,
    changeUnionFlag,
    unionFlag,
    prolongationFormat,
    picProlongationFormat,
    setRangeReportProlongationInAction,
    exportProlongationReport,
    exportProlongationSuccess,
    mode = PROLONGATION_MODE_PROLONGATION
}) => {
    const location = useLocation();
    const history = useHistory();
    const [activeFilter, setActiveFilter] = useState(getQueryVariable(location?.search, 'activeFilter') || '');
    const [packets, setPackets] = useState(location && location?.search ? getQueryVariableArray(qs.parse(location?.search), 'packets', OPTIONS_PACKETS_LABELS) : OPTIONS_PACKETS_LABELS);
    const [isShowTotal, setIsShowTotal] = useState(true);

    useEffect(() => {
        setQueryForProlongation(prolongationFormat, prolongationRange, activeFilter, unionFlag, packets, history, window.location?.pathname, window.location?.search, mode);
    }, [prolongationFormat, prolongationRange, activeFilter, unionFlag, packets, history, mode]);

    useEffect(() => {
        setPackets(prolongationPacket);
    }, [prolongationPacket]);

    useEffect(() => {
        getReportProlongationForRange(qs.parse(location?.search));
    }, [location?.search, getReportProlongationForRange]);

    useEffect(() => {
        const from = getQueryVariable(window.location?.search, 'from');
        const to = getQueryVariable(window.location?.search, 'to');

        setRangeReportProlongationInAction(from && to
            ? {
                timeFrom: from,
                timeTo: to
            }
            : initialTimeRangeProlongation);
    }, [setRangeReportProlongationInAction]);

    const handleSetFilter = (filter) => {
        setActiveFilter(s => filter === s ? initialChunkProlongation : filter);
        picProlongationFormat(activeFilter === filter ? initialChunkProlongation : PROLONGATION_FILTER_FORMATS[filter].chunk);
    };

    const handleAddUnionLabel = () => {
        changeUnionFlag(!unionFlag);
    };

    const handleSetTotal = () => {
        setIsShowTotal(s => !s);
    };

    const handlePickData = (data) => {
        data && setActiveFilter('');
    };

    const handleGetWithNewChunks = (chunk) => {
        chunk && chunk !== prolongationFormat && picProlongationFormat(chunk);
    };

    const handleExportReport = () => {
        const from = getQueryVariable(window.location?.search, 'from');
        const to = getQueryVariable(window.location?.search, 'to');
        from && to && exportProlongationReport({ from, to })
            .then((data) => {
                if (data) {
                    download(data, `Отчет_по_пролонгации_${from}_${to}.xlsx`);
                }
            });
    };

    const classActiveButtons = ['small', 'marginRight16', 'blueBg', 'showMore', 'minWidth', 'bgTransparent', 'thinButton'];
    const classNoActiveButtons = ['small', 'marginRight16', 'showMore', 'minWidth', 'bgTransparent', 'thinButton'];
    const classExportButton = ['small', 'marginRight16', 'showMore', 'minWidth', 'bgTransparent', 'margin0_30_25_30'];

    return (
        <section className={style.prolongationInAction}>
            <div className={style.stickyGroup}>
                <WrapFilter
                    classes={['padding50']}
                    filters={
                        <>
                            <FilterRangesTimesWrap
                                id='rangeButton_yesterday'
                                objectRange={{ name: 'Вчера', tag: 'day', countDays: 1, startRangeFn: startOfDateIso, endRangeFn: endOfDateIso, dateTo: new Date(subDays(new Date(), 1)) }}
                                isActive={activeFilter === 'day'}
                                handleSetActive={handleSetFilter}
                                classes={activeFilter === 'day' ? ['isActive'] : []}
                            />
                            <FilterRangesTimesWrap
                                id='rangeButton_week'
                                objectRange={{ name: 'Неделя', tag: 'week', countDays: 7, startRangeFn: startOfDateIso, endRangeFn: endOfDateIso, dateTo: new Date(subDays(new Date(), 1)) }}
                                isActive={activeFilter === 'week'}
                                handleSetActive={handleSetFilter}
                                classes={activeFilter === 'week' ? ['isActive'] : []}
                            />
                            <FilterRangesTimesWrap
                                id='rangeButton_month'
                                objectRange={{ name: 'Месяц', tag: 'month', countDays: 30, startRangeFn: startOfDateIso, endRangeFn: startOfDateIso, dateTo: new Date(subDays(new Date(), 1)) }}
                                isActive={activeFilter === 'month'}
                                handleSetActive={handleSetFilter}
                                classes={activeFilter === 'month' ? ['isActive'] : []}
                            />
                            <FilterRangesTimesWrap
                                id='rangeButton_year'
                                objectRange={{ name: 'Год', tag: 'year', countDays: 365, startRangeFn: startOfDateIso, endRangeFn: startOfDateIso, dateTo: new Date(subDays(new Date(), 1)) }}
                                isActive={activeFilter === 'year'}
                                handleSetActive={handleSetFilter}
                                classes={activeFilter === 'year' ? ['isActive'] : []}
                            />
                        </>
                    }
                    rangePicker={[<div key='text_rangePicker' className={style.padding15}>Период:</div>, <DateRangeProlongationReports key='DateRangeProlongationReports_rangePicker' classes={['blueBorder']} handlePickData={handlePickData} />]}
                />
                <div className={style.row}>
                    <div className={style.flexStart}>
                        <ProlongationFiltersBoard />
                        <div>
                            <CheckBoxCommon
                                handleChecked={handleAddUnionLabel}
                                withCheckBox
                                stateChecked={unionFlag}
                                textLabel='Объединить в один отчет'
                                classes={['paddingEmpty', 'blueLabel']}
                            />
                            <CheckBoxCommon
                                handleChecked={handleSetTotal}
                                withCheckBox
                                stateChecked={isShowTotal}
                                textLabel='Выводить ИТОГО'
                                classes={['blueLabel']}
                            />
                        </div>
                    </div>
                    <div className={style.flexEnd}>
                        <GrayButton
                            handlerClick={() => handleGetWithNewChunks('day')}
                            text='по дням'
                            classes={prolongationFormat === 'day' ? classActiveButtons : classNoActiveButtons}
                        />
                        <GrayButton
                            handlerClick={() => handleGetWithNewChunks('week')}
                            text='по неделям'
                            classes={prolongationFormat === 'week' ? classActiveButtons : classNoActiveButtons}
                        />
                        <GrayButton
                            handlerClick={() => handleGetWithNewChunks('month')}
                            text='по месяцам'
                            classes={prolongationFormat === 'month' ? classActiveButtons : classNoActiveButtons}
                        />
                    </div>
                </div>
                <GrayButton
                    handlerClick={exportProlongationSuccess ? () => {} : () => handleExportReport()}
                    text={exportProlongationSuccess ? 'загружается...' : 'загрузить отчет'}
                    classes={classExportButton}
                />
                {
                    prolongationSuccess
                        ? <LoadingIndicatorBlock reasonLoader={prolongationSuccess} />
                        : ''
                }
            </div>
            <div className={style.wrapReport}>
                {
                    prolongationResult.length > 0
                        ? prolongationResult.map((item, num) => {
                            return (
                                <WrapTable key={item.packet || num}>
                                    <TableProlongation dataTable={isShowTotal ? [...item.data, item.total] : item.data} packet={item.packet} packets={packets} mode='row' statisticMode={mode} />
                                </WrapTable>
                            );
                        })
                        : <div className={style.emptyData}>Нет ответа от сервера</div>
                }
            </div>
        </section>
    );
};

ProlongationInActionBodyWrap.propTypes = {
    prolongationFormat: PropTypes.string,
    prolongationResult: PropTypes.array,
    getReportProlongationForRange: PropTypes.func,
    setRangeReportProlongationInAction: PropTypes.func,
    picProlongationFormat: PropTypes.func,
    changeUnionFlag: PropTypes.func,
    exportProlongationReport: PropTypes.func,
    exportProlongationSuccess: PropTypes.bool,
    prolongationPacket: PropTypes.array,
    prolongationRange: PropTypes.object,
    prolongationSuccess: PropTypes.bool,
    unionFlag: PropTypes.bool,
    mode: PropTypes.string
};

const mapStateToProps = ({
    reportsProlongationInAction: {
        prolongationFormat,
        prolongationResult,
        prolongationPacket,
        prolongationRange,
        prolongationSuccess,
        unionFlag,
        exportProlongationSuccess
    }
}) => {
    return {
        prolongationFormat,
        prolongationResult,
        prolongationPacket,
        prolongationRange,
        prolongationSuccess,
        unionFlag,
        exportProlongationSuccess
    };
};

export default connect(mapStateToProps, {
    getReportProlongationForRange,
    changeUnionFlag,
    picProlongationFormat,
    setRangeReportProlongationInAction,
    exportProlongationReport
})(ProlongationInActionBodyWrap);
