import React, { useEffect, useState } from 'react';
import HeadToggle from '../../../../../components/HeadToggle';
import NewValueField from '../../../../../components/NewValueField';
import { isEmpty } from '../../../../../utils';

import style from './HandbookActionModal.module.scss';
import PropTypes from 'prop-types';

const HandbookActionModal = ({ name, fields, handleCloseModal, handleAddItem, initialItem = {}, representations = [] }) => {
    const [payload, setPayload] = useState({});

    useEffect(() => {
        return () => setPayload({});
    }, []);

    useEffect(() => {
        initialItem.id && setPayload({ ...initialItem });
    }, [initialItem]);

    const updateField = (newFieldKeyValue) => {
        setPayload(s => ({ ...s, ...newFieldKeyValue }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        !isEmpty(payload) && (JSON.stringify(initialItem) !== JSON.stringify(payload)) && handleAddItem(name, payload);
        handleCloseModal();
    };

    const fieldsInputs = fields && fields.length > 0
        ? fields.filter(item => item !== 'id').map((field, i) => {
            const typeFieldObj = representations.find(representation => representation.field === field);
            if (typeFieldObj) {
                return (
                    <NewValueField
                        key={name + ' ' + field + ' ' + i}
                        selectedItemId={initialItem.id}
                        field={field} updateField={updateField}
                        initialValue={initialItem[field]}
                        typeFieldObj={typeFieldObj}
                    />
                );
            }
            return (
                <NewValueField
                    key={name + ' ' + field + ' ' + i}
                    selectedItemId={initialItem.id}
                    field={field}
                    updateField={updateField}
                    initialValue={initialItem[field]}
                />
            );
        }) : '';

    return (
        <div className={style.reasonsModal} onClick={handleCloseModal}>
            <div className={style.whiteReasonsModal} onClick={e => e.stopPropagation()}>
                <div className={style.headerModal}>
                    <HeadToggle
                        id='HeaderReasonsModal'
                        headerText={initialItem.id > 0 ? 'Изменение' : 'Создание'}
                        classes={['big', 'gray', 'leftPosition', 'withoutArrow']}
                        initialHidden
                    />
                    <button className={style.btnClose} onClick={handleCloseModal}>X</button>
                </div>
                <div className={style.fieldsReason}>
                    <div className={style.fieldInputs}>{ fieldsInputs }</div>
                    <div className={style.childPadding}>
                        <input className={style.btnAdd} type='submit' value='Сохранить' onClick={handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    );
};

HandbookActionModal.propTypes = {
    name: PropTypes.string,
    fields: PropTypes.array,
    handleCloseModal: PropTypes.func,
    handleAddItem: PropTypes.func,
    initialItem: PropTypes.object,
    representations: PropTypes.array
};

export default HandbookActionModal;
