import {
    typeGetDataForPreReserve,
    typeDataForPreReserveError,
    typeDataForPreReserveSuccess,
    typeCreateDataForPreReserve,
    typeSetNoticePreReserve,
    typeClearPreReserveInvites,
    typeSaveReserveOrOrder,
    typeGetPreReservesSuccess,
    typeGetPreReservesError,
    typeSetPreReserves,
    typeDeletePreReserve,
    typeUpdatePreReserves,
    typeSetAverageDailyMileage,
    typeSetMonthForChangeOil,
    typeSetFocusMileage,
    typeSetPreReserve
} from '../actionsTypes';
import _ from 'lodash';
import { endOfDateIso, isNotEmpty } from '../utils';

// ------------------------------------
// Constants
// ------------------------------------
const GET_INVITE_PRE_RESERVE = typeGetDataForPreReserve;
const INVITE_DATE_PRE_RESERVE_ERROR = typeDataForPreReserveError;
const INVITE_DATE_PRE_RESERVE_SUCCESS = typeDataForPreReserveSuccess;
const CREATE_INVITE_PRE_RESERVE = typeCreateDataForPreReserve;
const SET_NOTICE_PRE_RESERVE = typeSetNoticePreReserve;
const CLEAR_PRE_RESERVE_INVITES = typeClearPreReserveInvites;
const SAVE_RESERVE_OR_ORDER = typeSaveReserveOrOrder;
const GET_PRE_RESERVES_SUCCESS = typeGetPreReservesSuccess;
const GET_PRE_RESERVES_ERROR = typeGetPreReservesError;
const SET_PRE_RESERVES = typeSetPreReserves;
const DELETE_PRE_RESERVE = typeDeletePreReserve;
const UPDATE_PRE_RESERVE = typeUpdatePreReserves;
const SET_AVERAGE_DAILY_MILEAGE = typeSetAverageDailyMileage;
const SET_MONTH_FOR_CHANGE_OIL = typeSetMonthForChangeOil;
const SET_FOCUS_MILEAGE = typeSetFocusMileage;
const PRE_RESERVE = typeSetPreReserve;

// ------------------------------------
// Actions
// ------------------------------------

export const setInviteDatePreReserve = (payload) => {
    return {
        type: GET_INVITE_PRE_RESERVE,
        payload: payload
    };
};

export const setInviteDatePreReserveError = (payload) => {
    return {
        type: INVITE_DATE_PRE_RESERVE_ERROR,
        payload: payload
    };
};

export const setInviteDatePreReserveSuccess = (payload) => {
    return {
        type: INVITE_DATE_PRE_RESERVE_SUCCESS,
        payload: payload
    };
};

export const createInvitePreReserve = (payload) => {
    return {
        type: CREATE_INVITE_PRE_RESERVE,
        payload: payload
    };
};

export const setNoticePreReserve = (payload) => {
    return {
        type: SET_NOTICE_PRE_RESERVE,
        payload: payload
    };
};

export const clearPreReserveInvites = (data) => {
    return {
        type: CLEAR_PRE_RESERVE_INVITES,
        payload: data
    };
};

export const getDatePreReserve = (carId, clientId, packetId) => (dispatch, getState, { apiService }) => {
    if (getState()?.preReserve?.monthForChangeOil && packetId === getState()?.preReserve?.monthForChangeOil?.packetId) {
        return dispatch(setInviteDatePreReserve({ ...getState()?.preReserve?.monthForChangeOil, error: null, packetId: packetId }));
    } else {
        dispatch(setInviteDatePreReserveSuccess(true));
        dispatch(setInviteDatePreReserveError(false));
        return apiService.inviteDatePreReserve(carId, clientId, packetId, getState()?.preReserve?.averageDailyMileage || null)
            .then((data) => {
                if (data.message) {
                    dispatch(setNoticePreReserve({ error: data.message, packetId: packetId }));
                } else if (data.error) {
                    dispatch(setNoticePreReserve({ error: data.error, packetId: packetId }));
                } else {
                    dispatch(setInviteDatePreReserve({ ...data, error: null, packetId: packetId }));
                    // dispatch(setNoticePreReserve({ error: null, packetId: packetId }));
                }
                dispatch(setInviteDatePreReserveSuccess(false));
                return data;
            })
            .catch((err) => {
                dispatch(setInviteDatePreReserveSuccess(false));
                dispatch(setInviteDatePreReserveError(true));
                return err;
            });
    }
};

export const postPreReserve = (dataForCreate) => (dispatch, getState, { apiService }) => {
    if (dataForCreate.scheduledTo && dataForCreate.scheduledFrom && dataForCreate.packetId) {
        dispatch(setInviteDatePreReserveSuccess(true));
        dispatch(setInviteDatePreReserveError(false));
        dataForCreate.scheduledTo = endOfDateIso(new Date(dataForCreate.scheduledTo));
        const reserve = getState().preReserve.reserveOrOrder;
        if (reserve && isNotEmpty(reserve)) {
            dataForCreate.reserveOrOrder = { id: reserve.id, type: reserve.type };
        }
    }
    return dataForCreate.scheduledTo && dataForCreate.scheduledFrom && dataForCreate.packetId && apiService.createPreReserve(dataForCreate)
        .then((data) => {
            dispatch(createInvitePreReserve({ ...data, packetId: dataForCreate.packetId }));
            dispatch(setInviteDatePreReserveSuccess(false));
            return data;
        })
        .catch((err) => {
            dispatch(setInviteDatePreReserveSuccess(false));
            dispatch(setInviteDatePreReserveError(true));
            return err;
        });
};

export const setPreReserves = (prereserve) => {
    return {
        type: typeSetPreReserves,
        payload: prereserve
    };
};

export const getPreReservesError = (error) => {
    return {
        type: typeGetPreReservesError,
        payload: error
    };
};

export const getPreReservesSuccess = (isLoad) => {
    return {
        type: typeGetPreReservesSuccess,
        payload: isLoad
    };
};

export const getPreReserves = (data) => (dispatch, getState, { apiService }) => {
    if (data.carId && data.clientId) {
        dispatch(getPreReservesError(false));
        dispatch(getPreReservesSuccess(true));
    }
    return data.carId && data.clientId && apiService.searchPreReserves(data)
        .then((res) => {
            res.prereserves && dispatch(setPreReserves(res.prereserves));
            dispatch(getPreReservesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getPreReservesError(true));
            dispatch(getPreReservesSuccess(false));
            return err;
        });
};

const setPreReserve = (error) => {
    return {
        type: typeSetPreReserve,
        payload: error
    };
};

export const getPreReserve = (id) => (dispatch, getState, { apiService }) => {
    if (id) {
        dispatch(getPreReservesError(false));
        dispatch(getPreReservesSuccess(true));
    }
    return id && apiService.getPreReserveById(
        id,
        {
            expand: 'car,client,car.model.mark',
            angara_expand: 'events,bpmnStep,ev.owner,ev.owner.account,ev.owner.account.company'
        }
    )
        .then((res) => {
            res && dispatch(setPreReserve(res));
            dispatch(getPreReservesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getPreReservesError(true));
            dispatch(getPreReservesSuccess(false));
            return err;
        });
};

export const canceledPreReserves = (id, data) => (dispatch, getState, { apiService }) => {
    if (data.carId && data.clientId && data.packetId) {
        dispatch(getPreReservesError(false));
        dispatch(getPreReservesSuccess(true));
    }
    return data.carId && data.clientId && data.packetId && apiService.deletePreReserve(id, data)
        .then((res) => {
            res && dispatch(deletePreReserves(data.packetId));
            dispatch(getPreReservesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getPreReservesError(true));
            dispatch(getPreReservesSuccess(false));
            return err;
        });
};

export const updatePreReserve = (id, data) => (dispatch, getState, { apiService }) => {
    if (data.scheduledTo && data.scheduledFrom && data.packetId && id) {
        dispatch(getPreReservesError(false));
        dispatch(getPreReservesSuccess(true));
        data.scheduledTo = endOfDateIso(new Date(data.scheduledTo));
    }
    return id && data.scheduledTo && data.scheduledFrom && data.packetId && apiService.updatePreReserve(id, data)
        .then((res) => {
            res && dispatch(updatePreReservesForPacket({ res, packetId: data.packetId }));
            dispatch(getPreReservesSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(getPreReservesError(true));
            dispatch(getPreReservesSuccess(false));
            return err;
        });
};

export const saveReserveOrOrder = (reserve) => {
    return {
        type: typeSaveReserveOrOrder,
        payload: reserve
    };
};

export const deletePreReserves = (prereserve) => {
    return {
        type: typeDeletePreReserve,
        payload: prereserve
    };
};

export const updatePreReservesForPacket = (prereserve) => {
    return {
        type: typeUpdatePreReserves,
        payload: prereserve
    };
};

export const setAverageDailyMileage = (data) => {
    return {
        type: typeSetAverageDailyMileage,
        payload: data
    };
};

export const setMonthForChangeOil = (data) => {
    return {
        type: typeSetMonthForChangeOil,
        payload: data
    };
};

export const handleSetFocusMileage = (data) => {
    return {
        type: typeSetFocusMileage,
        payload: data
    };
};

export const actions = {
    getDatePreReserve,
    postPreReserve,
    setNoticePreReserve,
    clearPreReserveInvites,
    saveReserveOrOrder,
    getPreReserves,
    setAverageDailyMileage,
    setMonthForChangeOil,
    handleSetFocusMileage
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [GET_INVITE_PRE_RESERVE]: (state, action) => {
        const oldInvites = _.cloneDeep(state.invites);
        if (oldInvites && oldInvites.length > 0) {
            const idx = oldInvites.findIndex(item => item.packetId === action.payload.packetId);
            if (idx !== -1) {
                const newInvite = oldInvites[idx];
                newInvite.invite = action.payload;
                return ({
                    ...state,
                    invites: [
                        ...oldInvites.slice(0, idx),
                        newInvite,
                        ...oldInvites.slice(idx + 1)
                    ]
                });
            } else {
                return ({
                    ...state,
                    invites: [
                        ...oldInvites,
                        {
                            packetId: action.payload.packetId,
                            invite: action.payload
                        }
                    ]
                });
            }
        } else {
            return ({
                ...state,
                invites: [
                    {
                        packetId: action.payload.packetId,
                        invite: action.payload
                    }
                ]
            });
        }
    },

    [CREATE_INVITE_PRE_RESERVE]: (state, action) => {
        const preReservesExistOld = _.cloneDeep(state.preReservesExist);
        const invitesOld = _.cloneDeep(state.invites);
        preReservesExistOld[action.payload.packetId] = [action.payload];
        return ({
            ...state,
            preReservesExist: preReservesExistOld,
            invites: invitesOld?.filter(invite => invite.packetId !== action.payload.packetId)
        });
    },

    [INVITE_DATE_PRE_RESERVE_ERROR]: (state, action) => {
        return ({
            ...state,
            inviteDateError: action.payload
        });
    },

    [INVITE_DATE_PRE_RESERVE_SUCCESS]: (state, action) => {
        return ({
            ...state,
            inviteDateSuccess: action.payload
        });
    },

    [CLEAR_PRE_RESERVE_INVITES]: (state, action) => {
        const invitesOld = _.cloneDeep(state.invites);
        return ({
            ...state,
            invites: invitesOld?.filter(invite => invite.packetId !== action.payload.packetId)
        });
    },

    [SET_NOTICE_PRE_RESERVE]: (state, action) => {
        const oldInvites = _.cloneDeep(state.invites);
        if (oldInvites && oldInvites.length > 0) {
            const idx = oldInvites.findIndex(item => item.packetId === action.payload.packetId);
            if (idx !== -1) {
                const newInvite = oldInvites[idx];
                newInvite.notice = action.payload.error;
                newInvite.invite = action.payload.invite;
                return ({
                    ...state,
                    invites: [
                        ...oldInvites.slice(0, idx),
                        newInvite,
                        ...oldInvites.slice(idx + 1)
                    ]
                });
            } else {
                return ({
                    ...state,
                    invites: [
                        ...oldInvites,
                        {
                            packetId: action.payload.packetId,
                            notice: action.payload.error
                        }
                    ]
                });
            }
        } else {
            return ({
                ...state,
                invites: [
                    {
                        packetId: action.payload.packetId,
                        notice: action.payload.error
                    }
                ]
            });
        }
    },

    [SAVE_RESERVE_OR_ORDER]: (state, action) => {
        return ({
            ...state,
            reserveOrOrder: action.payload
        });
    },

    [SET_PRE_RESERVES]: (state, action) => {
        const res = action.payload.reduce((accum, item) => {
            if (accum[item.packetId]) {
                accum[item.packetId].push(item);
            } else {
                accum[item.packetId] = [item];
            }
            return accum;
        }, {});

        return ({
            ...state,
            preReservesExist: res
        });
    },

    [GET_PRE_RESERVES_ERROR]: (state, action) => {
        return ({
            ...state,
            preReservesError: action.payload
        });
    },

    [GET_PRE_RESERVES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            preReservesSuccess: action.payload
        });
    },

    [DELETE_PRE_RESERVE]: (state, action) => {
        const preReservesExistOld = _.cloneDeep(state.preReservesExist);
        const invitesOld = _.cloneDeep(state.invites);
        delete preReservesExistOld[action.payload];
        return ({
            ...state,
            preReservesExist: preReservesExistOld,
            invites: invitesOld?.filter(invite => invite.packetId !== action.payload.packetId)
        });
    },

    [UPDATE_PRE_RESERVE]: (state, action) => {
        const preReservesExistOld = _.cloneDeep(state.preReservesExist);
        const invitesOld = _.cloneDeep(state.invites);
        preReservesExistOld[action.payload.packetId] = [action.payload.res];
        return ({
            ...state,
            preReservesExist: preReservesExistOld,
            invites: invitesOld?.filter(invite => invite.packetId !== action.payload.packetId)
        });
    },

    [PRE_RESERVE]: (state, action) => {
        return ({
            ...state,
            preReserve: action.payload
        });
    },

    [SET_AVERAGE_DAILY_MILEAGE]: (state, action) => {
        return ({
            ...state,
            averageDailyMileage: action.payload
        });
    },

    [SET_MONTH_FOR_CHANGE_OIL]: (state, action) => {
        return ({
            ...state,
            monthForChangeOil: action.payload
        });
    },

    [SET_FOCUS_MILEAGE]: (state, action) => {
        const old = state.focusMileage;
        if (old !== action.payload) {
            return ({
                ...state,
                focusMileage: action.payload
            });
        } else {
            return ({
                ...state
            });
        }
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            invites: [],
            inviteDateSuccess: false,
            inviteDateError: false,
            preReservesExist: {},
            preReserve: {},
            preReservesError: false,
            preReservesSuccess: false,
            reserveOrOrder: {},
            averageDailyMileage: 0,
            monthForChangeOil: null,
            focusMileage: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.preReserve, action) : state.preReserve;
};

export default commonReducer;
