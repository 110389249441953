import React from 'react';
import AboutAutoText from '../../components/MiddleHeader/AboutAutoText';
import style from './SheetInspectionsHeader.module.scss';

const SheetInspectionsHeader = () => {
    return (
        <div className={style.sheetInspectionsHeader}>
            <h1>Лист осмотра</h1>
            <AboutAutoText />
        </div>
    );
};

export default SheetInspectionsHeader;
