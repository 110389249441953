import React from 'react';
import ReportsHeader from './ReportsHeader';
import ReportsBody from './ReportsBody';
import PairColumn from '../../components/PairColumn';

const ReportsContainer = () => {
    return (
        <PairColumn>
            <ReportsHeader textHeader='Отчеты' />
            <ReportsBody />
        </PairColumn>
    );
};

export default ReportsContainer;
