import React from 'react';
import style from '../../styles/radioButtonsBlock/radioButtonsBlock.module.scss';
import PropTypes from 'prop-types';

const InputRadioSelect = ({ value, checked, handlerCheck, label, children, classes = [] }) => {
    return (
        <label className={style.labelRadio + classes.map(item => ' ' + style[item]).join('')}>
            <input
                type='radio'
                value={value}
                checked={checked}
                onChange={handlerCheck}
            />
            <div className={style.divRadio}><div className={style.inside} /></div>
            {
                children || label
            }
        </label>
    );
};

InputRadioSelect.propTypes = {
    value: PropTypes.string,
    checked: PropTypes.bool,
    handlerCheck: PropTypes.func,
    label: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['marginBottom', 'inputForm']))
};

export default InputRadioSelect;
