import React from 'react';

const apiContext = React.createContext({});

const {
    Provider: ApiServiceProvider,
    Consumer: ApiServiceConsumer
} = apiContext;

export {
    ApiServiceProvider,
    ApiServiceConsumer,
    apiContext
};
