import React, { useState, useEffect } from 'react';
import style from './ReasonForChange.module.scss';
import CheckBoxCommon from '../CheckBoxes/CheckBoxesCommon';
import TextAreaForm from '../TextAreaForm';
import PropTypes from 'prop-types';

const ReasonForChange = ({ payloadDataText, handleSetPayload, reasons }) => {
    const [textForArea, setTextForArea] = useState('');

    useEffect(() => {
        return () => {
            setTextForArea('');
        };
    }, []);

    const handleUpdateText = (data, state = true, checkBoxes = false) => {
        if (checkBoxes && !state) {
            handleSetPayload('');
        } else {
            if (checkBoxes) {
                setTextForArea('');
            } else {
                setTextForArea(data);
            }
            handleSetPayload(data);
        }
    };

    return (
        <div className={style.reasonContainer}>
            <div className={style.textReason}>
                <span className={style.test}>Причины изменения пред. записи</span>
            </div>
            <div className={style.reasons}>
                <div className={style.cboxes}>
                    {
                        reasons.map((item, key) => {
                            return (
                                <CheckBoxCommon
                                    key={'CheckBoxCommon_' + item.label}
                                    id={'CheckBoxCommon_' + item.label}
                                    blockInterestReasonId={item.label}
                                    stateChecked={payloadDataText === item.title}
                                    handleChecked={(id, state) => handleUpdateText(item.title, state, true)}
                                    textLabel={item.title}
                                    classes={key > 0 ? ['blueLabel', 'paddingEmpty', 'paddingRight16', 'paddingLeft16'] : ['blueLabel', 'paddingEmpty', 'paddingRight16']}
                                    withCheckBox
                                    withStringId
                                />
                            );
                        })
                    }
                </div>
                <div className={style.textarea}>
                    <TextAreaForm
                        placeholder='Другие причины изменения предварительной записи...'
                        text={textForArea}
                        handleOnChange={(e) => handleUpdateText(e.target.value)}
                        classes={['flexWrap', 'minWidth605', 'minHeight100']}
                    />
                </div>
            </div>
        </div>
    );
};

ReasonForChange.propTypes = {
    payloadDataText: PropTypes.string,
    handleSetPayload: PropTypes.func,
    reasons: PropTypes.array
};

export default ReasonForChange;
