import React, { useState, useEffect } from 'react';
import style from './BlockDetailsSettings.module.scss';
import { RowsKeyValue } from '../../../../../components/Row';
import ReasonsModal from '../ReasonsModal';
import { connect } from 'react-redux';
import { deleteReasonFromBlock } from '../../../../../actions';
import PropTypes from 'prop-types';

const BlockDetailsSettings = ({ blockId, blocks, deleteReasonFromBlock, interests = [], add = true }) => {
    const [isShowAdd, setIsShowAdd] = useState(false);
    const [interestsUnique, setInterestsUnique] = useState([]);
    const [selectedInterest, setSelectedInterest] = useState(0);
    const [selectedReason, setSelectedReason] = useState(0);
    const [blockInterestId, setBlockInterestId] = useState(0);

    const handleAddReason = (isSh, interestId, reasonId, blockInterestId) => {
        setIsShowAdd(isSh);
        interestId && setSelectedInterest(interestId);
        reasonId && setSelectedReason(reasonId);
        blockInterestId && setBlockInterestId(blockInterestId);
    };

    const handleCloseAddReason = () => {
        setIsShowAdd(false);
        setBlockInterestId(0);
        setSelectedReason(0);
    };

    function handleClickTrash(id) {
        deleteReasonFromBlock(id);
    }

    const resultInterests = interests.length > 0
        ? interests.map((interest, i) => {
            const res = interest.client_service_interest &&
                interest.reason && (
                <RowsKeyValue
                    key={'interest' + interest.id + '&' + i}
                    left={interest.client_service_interest.title}
                    right={interest.reason.title}
                    classes={['borderDashed']}
                    handleClickTrash={() => handleClickTrash(interest.id)}
                    handleUpdate={() => handleAddReason(true, interest.client_service_interest_id, interest.reason_id, interest.id)}
                    del
                    upd
                />);
            return res;
        })
        : <RowsKeyValue key='interestInitial' left='Result: ' right='Empty' />;

    useEffect(() => {
        setInterestsUnique(interests.length > 0 ? interests.reduce((accum, { client_service_interest: item = {} }) => {
            return accum.find((res) => res.id === item.id) ? accum : [...accum, item];
        }, []) : []);
    }, [interests]);

    useEffect(() => {
        setSelectedInterest(interestsUnique.reduce((accum, item) => (item.id > accum ? item.id : accum), 0));
    }, [interestsUnique]);

    return (
        <>
            <div className={style.paddingBefore} />
            <RowsKeyValue key='headerInitial' left='Интерес:' right='Причина:' classes={['header', 'borderDashed']} />
            {
                resultInterests
            }
            {
                add
                    ? <button className={style.btnAdd} onClick={() => handleAddReason(true)}>+ Добавить причину</button>
                    : ''
            }
            {
                isShowAdd
                    ? <ReasonsModal pathHeader={selectedReason > 0 ? 'Изменение' : 'Создание'} selectedBlock={blockId} handleCloseAddReason={handleCloseAddReason} blocks={blocks} interests={interestsUnique} interestId={selectedInterest} reasonId={selectedReason} blockInterestId={blockInterestId} />
                    : ''
            }
        </>
    );
};

BlockDetailsSettings.propTypes = {
    deleteReasonFromBlock: PropTypes.func,
    blockId: PropTypes.number,
    blocks: PropTypes.array,
    interests: PropTypes.array,
    add: PropTypes.bool
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { deleteReasonFromBlock })(BlockDetailsSettings);
