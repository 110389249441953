import React, { useState, useEffect, useMemo } from 'react';
import SetInterestsButtons from '../SetInterestsButtons';
import SetWhyThisInterest from '../SetWhyThisInterest';
import styleParent from '../../routes/SheetCollect/SheetCollectBody/SheetCollectBody.module.scss';
import PropTypes from 'prop-types';
import StorageTiresInterestsButtons from '../StorageTiresInterestsButtons/StorageTiresInterestsButtons';
import SetNotCollectCarInterests from '../Requests/SetNotCollectCarInterests';
import { connect } from 'react-redux';

const SetInterests = ({
    nameBlock,
    interestsReasons,
    handleUpdateBtnInterest,
    blockId,
    storageTires,
    anchorsNotCollect,
    carDetailsInfo
}) => {
    const [buttonPassed, setButtonPassed] = useState(0);
    const [interestReasonSelectedBtn, setInterestReasonSelectedBtn] = useState([]);
    const [textValue, setTextValue] = useState('');

    const interestReasonSelectedBtnInitial = useMemo(() => (buttonPassed ? (interestsReasons ? (interestsReasons.block_interest_reasons ? interestsReasons.block_interest_reasons.filter((item) => {
        return item.client_service_interest_id === buttonPassed;
    }) : []) : []) : []), [buttonPassed, interestsReasons]);

    useEffect(() => {
        setInterestReasonSelectedBtn(interestReasonSelectedBtnInitial);
    }, [interestReasonSelectedBtnInitial]);

    const filterBlock = useMemo(() => (blockId ? (carDetailsInfo.car_selected_interests ? carDetailsInfo.car_selected_interests.filter((item) => {
        return item.car_collect_block_id === blockId && item;
    }) : null) : null), [blockId, carDetailsInfo]);

    useEffect(() => {
        const res = filterBlock ? (filterBlock.length > 0 ? filterBlock[0].client_service_interest_id : 0) : 0;
        const textValue = filterBlock ? (filterBlock.length > 0 ? filterBlock[0].text_reason_interest : '') : '';
        setButtonPassed(res);
        setTextValue(textValue);
    }, [filterBlock]);

    const handleChoice = (id, e, text) => {
        handleUpdateBtnInterest(id, e, text);
        setButtonPassed(id);
    };

    const interestBtns = anchorsNotCollect
        ? <SetNotCollectCarInterests nameBlock={nameBlock} interestsReasons={interestsReasons} handleUpdateBtnInterest={handleChoice} blockId={blockId} key={`SetNotCollectCarInterests${blockId}`} />
        : storageTires
            ? <StorageTiresInterestsButtons nameBlock={nameBlock} interestsReasons={interestsReasons} handleUpdateBtnInterest={handleChoice} blockId={blockId} />
            : <SetInterestsButtons nameBlock={nameBlock} interestsReasons={interestsReasons} handleUpdateBtnInterest={handleChoice} blockId={blockId} />;

    return (
        <div className={!anchorsNotCollect ? styleParent.paragraph : styleParent.paragraph2}>
            {
                interestBtns
            }
            <SetWhyThisInterest textValue={textValue} buttonPassed={buttonPassed} interestsReasons={interestReasonSelectedBtn} handleUpdateBtnInterest={handleChoice} blockId={blockId} />
        </div>
    );
};

SetInterests.propTypes = {
    nameBlock: PropTypes.string,
    interestsReasons: PropTypes.object,
    storageTires: PropTypes.bool,
    handleUpdateBtnInterest: PropTypes.func,
    blockId: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    anchorsNotCollect: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default connect(mapStateToProps)(SetInterests);
