import React from 'react';
import PropTypes from 'prop-types';
import style from './CarNumber.module.scss';

const formatCarNumber = (carNumber) => {
    if (carNumber?.length === 6) {
        return `${carNumber[0]} ${carNumber?.slice(1, carNumber.length - 2)} ${carNumber?.slice(carNumber.length - 2)}`;
    } else {
        return '_ ___ __';
    }
};

const CarNumber = ({ number, region, regionChars = 'RUS', classes = [] }) => {
    return (
        <div className={style.car + classes.map(item => ' ' + style[item]).join('')}>
            <div className={style.whiteNumber}>
                { formatCarNumber(number) }
            </div>
            <div className={style.region}>
                <div className={style.numReg}>{ region || '_' }</div>
                <div className={style.regionChar}>{ regionChars }</div>
            </div>
        </div>
    );
};

CarNumber.propTypes = {
    number: PropTypes.string,
    region: PropTypes.string,
    regionChars: PropTypes.string,
    classes: PropTypes.array
};

export default CarNumber;
