import {
    typeAddVisitorsSearchOffset,
    typeClearFoundVisitors,
    typeClearNewDataVisitor,
    typeClearVisitors,
    typeFetchActivityError,
    typeFetchActivitySuccess,
    typeFetchClientCarsError,
    typeFetchClientCarsSuccess,
    typeFetchInviteError,
    typeFetchInviteSuccess,
    typeFetchVisitorError,
    typeFetchVisitorSuccess,
    typeNearestVisitorsError,
    typeNearestVisitorsSuccess,
    typeSaveVisitors,
    typeSearchVisitorsError,
    typeSearchVisitorsSuccess,
    typeSetDataChangeVisitor,
    typeSetDatesFilterForVisitors,
    typeSetFoundedVisitors,
    typeSetNewDataVisitor,
    typeSetNewDataVisitorError,
    typeSetNewDataVisitorSuccess,
    typeSetNewVisitorsLimitOffset,
    typeSetTextSearchVisitors,
    typeSetVisitor,
    typeSetVisitorId,
    typeFetchClientsError,
    typeFetchClientsSuccess,
    typeSetNewDataLoader,
    typeSetVisitorAvatar
} from '../actionsTypes';
import _ from 'lodash';
import { createDateForSaveFromDatePicker, dateComparisonFirstBeforeSecond } from '../utils';
import { LIMIT_DEFAULT_VISITORS, OFFSET_DEFAULT_VISITORS } from '../constants/limitsDefault';

// ------------------------------------
// Constants
// ------------------------------------
const SET_TEXT_SEARCH_VISITORS = typeSetTextSearchVisitors;
const SAVE_VISITORS = typeSaveVisitors;
const NEW_VISITORS_LIMIT_OFFSET = typeSetNewVisitorsLimitOffset;
const SET_DATES = typeSetDatesFilterForVisitors;
const CLEAR_VISITORS = typeClearVisitors;
const NEAREST_VISITORS_SUCCESS = typeNearestVisitorsSuccess;
const NEAREST_VISITORS_ERROR = typeNearestVisitorsError;
const CLEAR_FOUND_VISITORS = typeClearFoundVisitors;
const SET_FOUND_VISITORS = typeSetFoundedVisitors;
const ADD_VISITORS_SEARCH_OFFSET = typeAddVisitorsSearchOffset;
const SEARCH_VISITORS_ERROR = typeSearchVisitorsError;
const SEARCH_VISITORS_SUCCESS = typeSearchVisitorsSuccess;
const SET_VISITOR_ID = typeSetVisitorId;
const SET_VISITOR = typeSetVisitor;
const SET_VISITOR_AVATAR = typeSetVisitorAvatar;
const FETCH_VISITOR_ERROR = typeFetchVisitorError;
const FETCH_VISITOR_SUCCESS = typeFetchVisitorSuccess;
const FETCH_INVITE_ERROR = typeFetchInviteError;
const FETCH_INVITE_SUCCESS = typeFetchInviteSuccess;
const FETCH_ACTIVITY_ERROR = typeFetchActivityError;
const FETCH_ACTIVITY_SUCCESS = typeFetchActivitySuccess;
const SET_DATA_CHANGE_VISITOR = typeSetDataChangeVisitor;
const SET_NEW_DATA_VISITOR_SUCCESS = typeSetNewDataVisitorSuccess;
const SET_NEW_DATA_VISITOR_ERROR = typeSetNewDataVisitorError;
const SET_NEW_DATA_VISITOR = typeSetNewDataVisitor;
const SET_NEW_DATA_LOADER = typeSetNewDataLoader;
const CLEAR_NEW_DATA_VISITOR = typeClearNewDataVisitor;
const FETCH_CLIENT_CARS_SUCCESS = typeFetchClientCarsSuccess;
const FETCH_CLIENT_CARS_ERROR = typeFetchClientCarsError;
const FETCH_CLIENTS_SUCCESS = typeFetchClientsSuccess;
const FETCH_CLIENTS_ERROR = typeFetchClientsError;

// ------------------------------------
// Actions
// ------------------------------------

export const setTextVisitors = (textSearch) => {
    return {
        type: SET_TEXT_SEARCH_VISITORS,
        payload: textSearch
    };
};

export const saveOrdersVisitors = (visitors) => {
    return {
        type: SAVE_VISITORS,
        payload: visitors
    };
};

export const ordersVisitorsSuccess = (isSuccess) => {
    return {
        type: NEAREST_VISITORS_SUCCESS,
        payload: isSuccess
    };
};

export const ordersVisitorsError = (isError) => {
    return {
        type: NEAREST_VISITORS_ERROR,
        payload: isError
    };
};

export const setVisitorsLimit = () => {
    return {
        type: NEW_VISITORS_LIMIT_OFFSET
    };
};

export const setVisitorsSearchLimit = () => {
    return {
        type: ADD_VISITORS_SEARCH_OFFSET
    };
};

export const clearVisitors = () => {
    return {
        type: CLEAR_VISITORS
    };
};

export const getOrdersWithVisitors = (params = {}) => (dispatch, getState, { apiService }) => {
    dispatch(ordersVisitorsError(false));
    dispatch(ordersVisitorsSuccess(true));
    if (dateComparisonFirstBeforeSecond(getState().visitors.datesFilter.dateFrom, params.dateFrom) || dateComparisonFirstBeforeSecond(params.dateTo, getState().visitors.datesFilter.dateTo)) {
        dispatch(clearVisitors());
        dispatch(ordersVisitorsSuccess(false));
    }
    return apiService.getFromOrdersVisitors({ ...getState().visitors.visitorsLimitOffset, ...getState().visitors.datesFilter, ...params })
        .then((res) => {
            dispatch(saveOrdersVisitors(res));
            dispatch(ordersVisitorsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(ordersVisitorsError(true));
            dispatch(ordersVisitorsSuccess(false));
            return err;
        });
};

export const addOffsetForVisitors = () => (dispatch) => {
    return dispatch(setVisitorsLimit());
};

export const addOffsetForSearchVisitors = () => (dispatch) => {
    dispatch(setVisitorsSearchLimit());
    return dispatch(searchVisitors());
};

const setNewDatesFilter = (dateFilter) => {
    return {
        type: SET_DATES,
        payload: dateFilter
    };
};

export const setNewDatesForFilter = (dates) => (dispatch, getState) => {
    if (dates.dateFrom && dates.dateTo) {
        dispatch(getOrdersWithVisitors({
            ...getState().visitors.datesFilter,
            ...dates,
            limit: LIMIT_DEFAULT_VISITORS,
            offset: OFFSET_DEFAULT_VISITORS
        }));
    }
    return dispatch(setNewDatesFilter(dates));
};

export const clearFoundVisitors = () => {
    return {
        type: CLEAR_FOUND_VISITORS
    };
};

export const setFoundedVisitors = (visitors) => {
    return {
        type: SET_FOUND_VISITORS,
        payload: visitors
    };
};

export const setSearchVisitorsError = (isError) => {
    return {
        type: SEARCH_VISITORS_ERROR,
        payload: isError
    };
};

export const setSearchVisitorsSuccess = (isSuccess) => {
    return {
        type: SEARCH_VISITORS_SUCCESS,
        payload: isSuccess
    };
};

export const searchVisitors = (params = {}) => (dispatch, getState, { apiService }) => {
    dispatch(setSearchVisitorsError(false));
    dispatch(setSearchVisitorsSuccess(true));
    if (!getState().visitors.textSearch.search) {
        return;
    }
    return apiService.searchVisitorsWithNamePhone({ ...getState().visitors.textSearch, ...params })
        .then((res) => {
            dispatch(setFoundedVisitors(res));
            dispatch(setSearchVisitorsSuccess(false));
            return res;
        })
        .catch((err) => {
            dispatch(setSearchVisitorsError(true));
            dispatch(setSearchVisitorsSuccess(false));
            return err;
        });
};

export const setFetchVisitorSuccess = (isSuccess) => {
    return {
        type: FETCH_VISITOR_SUCCESS,
        payload: isSuccess
    };
};

export const setFetchVisitorError = (isError) => {
    return {
        type: FETCH_VISITOR_ERROR,
        payload: isError
    };
};

export const sendInvite = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setSendInviteError(false));
    dispatch(setSearchVisitorsSuccess(true));
    dispatch(setNewDataLoader(true));
    return apiService.activateClient(id)
        .then((res) => {
            dispatch(setFoundedVisitors(res));
            dispatch(setSendInviteSuccess(true));
            dispatch(setNewDataLoader(false));
            return res;
        })
        .catch((err) => {
            dispatch(setSendInviteError(true));
            dispatch(setSearchVisitorsSuccess(false));
            dispatch(setNewDataLoader(false));
            return err;
        });
};

export const setSendInviteSuccess = (isSuccess) => {
    return {
        type: FETCH_INVITE_SUCCESS,
        payload: isSuccess
    };
};

export const setSendInviteError = (isError) => {
    return {
        type: FETCH_INVITE_ERROR,
        payload: isError
    };
};

export const fetchActivity = (id) => (dispatch, getState, { apiService }) => {
    return apiService.fetchActivity(id)
        .then((res) => {
            dispatch(setfetchActivitySuccess(res));
            return res;
        })
        .catch((err) => {
            dispatch(setfetchActivityError(true));
            dispatch(setfetchActivitySuccess(false));
            return err;
        });
};

export const fetchClientCars = (id) => (dispatch, getState, { apiService }) => {
    if (id !== 0) {
        dispatch(setNewDataLoader(true));
        return apiService.fetchClientCars(id)
            .then((res) => {
                dispatch(setFetchClientCarsSuccess(res));
                dispatch(setNewDataLoader(false));
                return res;
            })
            .catch((err) => {
                dispatch(setFetchClientCarsError(true));
                dispatch(setNewDataLoader(false));
                return err;
            });
    } else {
        return false;
    }
};

export const setFetchClientCarsSuccess = (res) => {
    return {
        type: FETCH_CLIENT_CARS_SUCCESS,
        payload: res
    };
};

export const setFetchClientCarsError = (isError) => {
    return {
        type: FETCH_CLIENT_CARS_ERROR,
        payload: isError
    };
};

export const fetchClients = () => (dispatch, getState, { apiService }) => {
    dispatch(setNewDataLoader(true));
    return apiService.fetchClients()
        .then((res) => {
            dispatch(setFetchClientsSuccess(res));
            dispatch(setNewDataLoader(false));
            return res;
        })
        .catch((err) => {
            dispatch(setFetchClientsError(true));
            dispatch(setNewDataLoader(false));
            return err;
        });
};

export const setFetchClientsSuccess = (res) => {
    return {
        type: FETCH_CLIENTS_SUCCESS,
        payload: res
    };
};

export const setFetchClientsError = (isError) => {
    return {
        type: FETCH_CLIENTS_ERROR,
        payload: isError
    };
};

export const setfetchActivitySuccess = (isSuccess) => {
    return {
        type: FETCH_ACTIVITY_SUCCESS,
        payload: isSuccess
    };
};

export const setfetchActivityError = (isError) => {
    return {
        type: FETCH_ACTIVITY_ERROR,
        payload: isError
    };
};

export const setVisitorId = (id) => {
    return {
        type: SET_VISITOR_ID,
        payload: id
    };
};

export const fetchVisitor = (id) => (dispatch, getState, { apiService }) => {
    dispatch(setFetchVisitorSuccess(true));
    dispatch(setFetchVisitorError(false));
    dispatch(setNewDataLoader(true));
    return apiService.getVisitor(id)
        .then((res) => {
            dispatch(setFetchVisitorSuccess(false));
            dispatch(setVisitor(res));
            dispatch(setNewDataLoader(false));
            return res;
        })
        .catch((err) => {
            dispatch(setFetchVisitorSuccess(false));
            dispatch(setFetchVisitorError(true));
            return err;
        });
};
export const fetchVisitorAvatar = (id) => (dispatch, getState, { apiService }) => {
    return apiService.getVisitorAvatar(id)
        .then((res) => {
            dispatch(setVisitorAvatar(res));
            return res;
        });
};

export const setVisitor = (visitor) => {
    return {
        type: SET_VISITOR,
        payload: visitor
    };
};

export const setVisitorAvatar = (avatar) => {
    return {
        type: SET_VISITOR_AVATAR,
        payload: avatar
    };
};

export const setChangeVisitor = (dataChangeVisitor) => {
    return {
        type: SET_DATA_CHANGE_VISITOR,
        payload: dataChangeVisitor
    };
};

export const setDataChangeVisitor = (data, field, id) => (dispatch, getState, { apiService }) => {
    if (Object.prototype.hasOwnProperty.call(getState().visitors.dataChangeVisitor, [field]) && (getState().visitors.dataChangeVisitor[field] === data[field])) {
        return false;
    } else {
        dispatch(setNewDataLoader(true));
        let payload;
        if (field === 'sex' || field === 'birthday' || field === 'email') {
            const visitor = _.cloneDeep(getState().visitors.visitor);
            const index = _.findIndex(visitor.fields, ['label', field]);
            if (index !== -1) {
                visitor.fields[index].value = data[field];
            } else {
                visitor.fields.push({ label: field, value: data[field] });
            }
            payload = { fields: visitor.fields };
        } else {
            payload = data;
        }

        return getState().visitors.visitorId && apiService.putVisitor(id, payload)
            .then((res) => {
                if (res.id === getState().visitors.visitorId) {
                    dispatch(setNewDataVisitorSuccess(true));
                    dispatch(setNewDataVisitor(res));
                    dispatch(setNewDataLoader(false));
                }
                return res;
            })
            .catch((err) => {
                dispatch(setNewDataLoader(false));
                return err;
            });
    }
};

export const setNewDataVisitorSuccess = (isSuccess) => {
    return {
        type: SET_NEW_DATA_VISITOR_SUCCESS,
        payload: isSuccess
    };
};

export const setNewDataVisitorError = (isError) => {
    return {
        type: SET_NEW_DATA_VISITOR_ERROR,
        payload: isError
    };
};

export const setNewDataLoader = (bool) => {
    return {
        type: SET_NEW_DATA_LOADER,
        payload: bool
    };
};

export const setNewDataVisitor = (data) => {
    return {
        type: SET_NEW_DATA_VISITOR,
        payload: data
    };
};

export const clearNewDataVisitor = (newDataClear) => {
    return {
        type: CLEAR_NEW_DATA_VISITOR,
        payload: newDataClear
    };
};

export const actions = {
    setTextVisitors,
    getOrdersWithVisitors,
    setVisitorsLimit,
    addOffsetForVisitors,
    setNewDatesForFilter,
    searchVisitors,
    clearFoundVisitors,
    setFoundedVisitors,
    addOffsetForSearchVisitors,
    setVisitorId,
    fetchVisitor,
    setVisitor,
    setDataChangeVisitor,
    sendInvite,
    fetchActivity,
    fetchClientCars,
    fetchClients,
    fetchVisitorAvatar
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [CLEAR_NEW_DATA_VISITOR]: (state) => {
        return ({
            ...state,
            dataChangeVisitor: {}
        });
    },

    [SET_NEW_DATA_VISITOR_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isSetNewDataVisitorSuccess: action.payload
        });
    },

    [SET_NEW_DATA_VISITOR_ERROR]: (state, action) => {
        return ({
            ...state,
            isSetNewDataVisitorError: action.payload
        });
    },

    [SET_NEW_DATA_VISITOR]: (state, action) => {
        if (action.payload.id !== state.visitor.id) {
            return ({
                ...state
            });
        }

        return ({
            ...state,
            visitor: { ...state.visitor, ...action.payload }
        });
    },

    [SET_NEW_DATA_LOADER]: (state, action) => {
        return ({
            ...state,
            loader: action.payload
        });
    },

    [SET_DATA_CHANGE_VISITOR]: (state, action) => {
        return ({
            ...state,
            dataChangeVisitor: action.payload
        });
    },

    [FETCH_VISITOR_ERROR]: (state, action) => {
        return ({
            ...state,
            isVisitorError: action.payload
        });
    },

    [FETCH_VISITOR_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isVisitorSuccess: action.payload
        });
    },

    [FETCH_CLIENT_CARS_ERROR]: (state) => {
        return ({
            ...state
        });
    },

    [FETCH_CLIENT_CARS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            clientCars: action.payload
        });
    },

    [FETCH_CLIENTS_ERROR]: (state) => {
        return ({
            ...state
        });
    },

    [FETCH_CLIENTS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            clients: action.payload
        });
    },

    [FETCH_INVITE_ERROR]: (state, action) => {
        return ({
            ...state,
            isInviteError: action.payload
        });
    },

    [FETCH_INVITE_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isInviteSuccess: action.payload
        });
    },

    [FETCH_ACTIVITY_ERROR]: (state) => {
        return ({
            ...state,
            isActivityError: true
        });
    },

    [FETCH_ACTIVITY_SUCCESS]: (state, action) => {
        return ({
            ...state,
            activity: action.payload
        });
    },

    [SET_VISITOR]: (state, action) => {
        return ({
            ...state,
            visitor: action.payload
        });
    },

    [SET_VISITOR_AVATAR]: (state, action) => {
        return ({
            ...state,
            visitorAvatar: action.payload
        });
    },

    [SET_VISITOR_ID]: (state, action) => {
        return ({
            ...state,
            visitorId: action.payload
        });
    },

    [SEARCH_VISITORS_ERROR]: (state, action) => {
        return ({
            ...state,
            isSearchVisitorsError: action.payload
        });
    },

    [SEARCH_VISITORS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isSearchVisitorsSuccess: action.payload
        });
    },

    [SET_FOUND_VISITORS]: (state, action) => {
        const foundedItems = _.cloneDeep(state.foundedVisitors.items);
        const idsNewItems = foundedItems.map(item => item.id);
        const newVisitors = [
            ...action.payload.items.filter(item => idsNewItems.indexOf(item.id) === -1)
        ];

        // sort by uniq
        const resPreNotUniq = [...state.foundedVisitors.items, ...newVisitors];
        const ids = resPreNotUniq.map(item => item.id);
        const idsUniq = [...new Set(ids)];

        const newUniqArray = [
            ...idsUniq.map(item => resPreNotUniq.find(cli => cli.id === item))
        ];

        return ({
            ...state,
            foundedVisitors: {
                items: newUniqArray,
                count: action.payload.total
            }
        });
    },

    [CLEAR_FOUND_VISITORS]: (state) => {
        return ({
            ...state,
            foundedVisitors: initialFoundedVisitors,
            textSearch: initialTextSearchVisitors
        });
    },

    [CLEAR_VISITORS]: (state) => {
        return ({
            ...state,
            allVisitors: [],
            visitorsLimitOffset: {
                limit: LIMIT_DEFAULT_VISITORS,
                offset: OFFSET_DEFAULT_VISITORS
            }
        });
    },

    [NEAREST_VISITORS_ERROR]: (state, action) => {
        return ({
            ...state,
            isVisitorsError: action.payload
        });
    },

    [NEAREST_VISITORS_SUCCESS]: (state, action) => {
        return ({
            ...state,
            setVisitorsSuccess: action.payload
        });
    },

    [SET_TEXT_SEARCH_VISITORS]: (state, action) => {
        return ({
            ...state,
            textSearch: { ...state.textSearch, ...action.payload }
        });
    },

    [SET_DATES]: (state, action) => {
        return ({
            ...state,
            datesFilter: action.payload
        });
    },

    [SAVE_VISITORS]: (state, action) => {
        const resItems = _.cloneDeep(state.allVisitors);
        const idsNewItems = resItems.map(item => item.id);
        const newVisitors = [
            ...action.payload.items.filter(item => idsNewItems.indexOf(item.id) === -1)
        ]
            .filter(item => {
                return Object.prototype.hasOwnProperty.call(item, 'cars');
            })
            .filter(item => item.cars.length > 0);

        return ({
            ...state,
            /* allVisitors: [...state.allVisitors, ...newVisitors].sort(sortByField('scheduledTo')), */
            allVisitors: [...state.allVisitors, ...newVisitors],
            countVisitors: action.payload.total
        });
    },

    [NEW_VISITORS_LIMIT_OFFSET]: (state) => {
        if ((state.visitorsLimitOffset.offset + state.visitorsLimitOffset.limit) === state.countVisitors) {
            return ({ ...state });
        }

        if ((state.visitorsLimitOffset.offset + state.visitorsLimitOffset.limit) > state.countVisitors) {
            return ({ ...state });
        }

        return ({
            ...state,
            visitorsLimitOffset: {
                ...state.visitorsLimitOffset,
                offset: state.visitorsLimitOffset.offset + state.visitorsLimitOffset.limit
            }
        });
    },

    [ADD_VISITORS_SEARCH_OFFSET]: (state) => {
        if ((state.textSearch.offset + state.textSearch.limit) === state.foundedVisitors.count) {
            return ({ ...state });
        }

        if ((state.textSearch.offset + state.textSearch.limit) > state.foundedVisitors.count) {
            return ({ ...state });
        }

        const newTextSearch = _.cloneDeep(state.textSearch);

        return ({
            ...state,
            textSearch: {
                ...newTextSearch,
                offset: newTextSearch.offset + newTextSearch.limit
            }
        });
    }
};

const initialFoundedVisitors = {
    items: [],
    count: 0
};

const initialTextSearchVisitors = {
    search: '',
    limit: LIMIT_DEFAULT_VISITORS,
    offset: OFFSET_DEFAULT_VISITORS
};

const visitorsReducer = (state, action) => {
    if (state === undefined) {
        return {
            textSearch: initialTextSearchVisitors,
            foundedVisitors: initialFoundedVisitors,
            datesFilter: {
                dateFrom: createDateForSaveFromDatePicker(new Date()),
                dateTo: ''
            },
            visitorsLimitOffset: {
                limit: LIMIT_DEFAULT_VISITORS,
                offset: OFFSET_DEFAULT_VISITORS
            },
            searchVisitorsSuccess: false,
            countVisitors: 0,
            allVisitors: [],
            setVisitorsSuccess: false,
            isVisitorsError: false,
            ordersWithVisitors: [],
            divHeight: 0,
            isSearchVisitorsError: false,
            isSearchVisitorsSuccess: false,
            visitorId: 0,
            visitor: {},
            isVisitorError: false,
            isVisitorSuccess: false,
            isInviteError: false,
            isInviteSuccess: false,
            dataChangeVisitor: {},
            isSetNewDataVisitorError: false,
            isSetNewDataVisitorSuccess: false,
            activity: '',
            isActivityError: false,
            clientCars: [],
            clients: {},
            loader: false,
            visitorAvatar: {}
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.visitors, action) : state.visitors;
};

export default visitorsReducer;
