import React from 'react';
import GetServiceBooksCars from '../Requests/GetServiceBooksCars';
import GetSearchCars from '../Requests/GetSearchCars';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ServiceBooks = ({ textSearch }) => {
    return (
        <div>
            {
                textSearch.search ? <GetSearchCars /> : ''
            }
            <GetServiceBooksCars />
        </div>
    );
};

ServiceBooks.propTypes = {
    textSearch: PropTypes.object
};

const mapStateToProps = ({ carsListState: { textSearch } }) => {
    return {
        textSearch
    };
};

export default connect(mapStateToProps)(ServiceBooks);
