export const OPTIONS_PACKETS = [
    { id: 1, name: 'Масло', label: 'oil' },
    { id: 2, name: 'ОСАГО', label: 'osago' },
    { id: 3, name: 'КАСКО', label: 'kacko' },
    { id: 4, name: 'Шиномонтаж', label: 'tires' }
];

export const OPTIONS_PACKETS_PACKET_NAME = OPTIONS_PACKETS.reduce((accum, item) => {
    return { ...accum, [item.label]: item.name };
}, {});

export const OPTIONS_PACKETS_LABELS = OPTIONS_PACKETS.map(item => item.label);
