import React from 'react';
import ArrowHiddenContent from 'components/ArrowHiddenContent';
import AnchorsMarkStates from 'components/AnchorsMarkStates';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCarInterest } from '../../../../../actions';
import style from './HeadAnchors.module.scss';

const HeadAnchors = ({ textHeader, bgStyle, id, carIdCollect, clientIdCollect, deleteCarInterest, handleSetHidden, stateBool, passedHeader, withoutCheckToggle }) => {
    const handleUpdateBlockInterest = (blockId) => {
        carIdCollect && clientIdCollect && blockId && deleteCarInterest({
            car_id: carIdCollect,
            client_id: clientIdCollect,
            car_collect_block_id: blockId
        });
    };

    return (
        <div className={`${style.headAnchor} ${bgStyle}`} id={id}>
            <AnchorsMarkStates textHeader={textHeader} blockId={id} handleUpdateBlockInterest={handleUpdateBlockInterest} withoutCheckToggle={withoutCheckToggle} passedHeader={passedHeader} />
            <ArrowHiddenContent handleSetHidden={handleSetHidden} stateBool={stateBool} blockId={id} />
        </div>
    );
};

HeadAnchors.defaultPropTypes = {
    textHeader: 'Блок'
};

HeadAnchors.propTypes = {
    textHeader: PropTypes.string,
    bgStyle: PropTypes.string,
    id: PropTypes.number,
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    handleSetHidden: PropTypes.func,
    stateBool: PropTypes.bool,
    withoutCheckToggle: PropTypes.bool,
    passedHeader: PropTypes.node,
    deleteCarInterest: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect } }) => {
    return {
        carIdCollect,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { deleteCarInterest })(HeadAnchors);
