function dateFromDateField(date, separator = '-') {
    const dateFromString = new Date(date);
    let res = `${(dateFromString.getDate()).toString().padStart(2, '0')}${separator}${(dateFromString.getMonth() + 1).toString().padStart(2, '0')}${separator}${(dateFromString.getFullYear()).toString() + ''}`;
    res = res.split('-').reverse().join('-');
    return res;
}

export default dateFromDateField;

export function dateMonthFromDateString(date, separator = '.') {
    const dateFromString = new Date(date);
    let res = `${(dateFromString.getDate()).toString().padStart(2, '0')}${separator}${(dateFromString.getMonth() + 1).toString().padStart(2, '0')}`;
    res = res.split('-').reverse().join('-');
    return res;
}
