import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateInput from '../DateInput';

import style from './DateEndInsurance.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { compose, withApiService } from '../../Hoc';
import { connect } from 'react-redux';

const DateEndInsurance = ({ labelName, idName, isKm, forUpdateField, valueField, withoutLabel, id, classes, withDelButton }) => {
    const [dateEnd, setDateEnd] = useState(valueField || null);

    useEffect(() => {
        setDateEnd(valueField);
    }, [valueField]);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleChange = (e) => {
        setDateEnd(e);
        forUpdateField(e);
    };

    const handleDelete = () => {
        if (withDelButton) {
            setDateEnd(null);
            forUpdateField(null);
        }
    };

    return (
        <form key={idName} className={style.dateEnd} onSubmit={handleSubmit}>
            {
                withoutLabel ? '' : <label htmlFor={idName} className={style.labelChoice}>{ labelName }</label>
            }
            <div className={style.dpp}>
                <DateInput handelChange={handleChange} valueCar={dateEnd} id={id} classes={classes} handleDelete={withDelButton && handleDelete} withDelButton={withDelButton} />
            </div>
            { isKm ? <div>км</div> : '' }
        </form>
    );
};

DateEndInsurance.defaultProps = {
    isKm: false
};

DateEndInsurance.propTypes = {
    labelName: PropTypes.string,
    idName: PropTypes.string,
    isKm: PropTypes.bool,
    forUpdateField: PropTypes.func,
    valueField: PropTypes.string,
    withoutLabel: PropTypes.bool,
    withDelButton: PropTypes.bool,
    classes: PropTypes.array,
    id: PropTypes.string
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo } }) => {
    return {
        carDetailsInfo
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(DateEndInsurance);
