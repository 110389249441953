import style from './CardsArrayTemplate.module.scss';
import PropTypes from 'prop-types';
import React from 'react';

const CardTemplate = ({ children }) => {
    return (
        <div className={style.card}>
            {
                children
            }
        </div>
    );
};

CardTemplate.propTypes = {
    children: PropTypes.node
};

const CardsArrayTemplate = ({ cardsHeaderChildren, classes }) => {
    return (
        <div className={style.cardsArrayTemplate + classes.map(item => ' ' + style[item]).join('')}>
            {
                cardsHeaderChildren.map((item, k) => {
                    return <CardTemplate key={k} children={item} />;
                })
            }
        </div>
    );
};

CardsArrayTemplate.propTypes = {
    cardsHeaderChildren: PropTypes.arrayOf(PropTypes.node),
    classes: PropTypes.array
};

export default CardsArrayTemplate;
