import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import style from '../../../components/MiddleHeader/SvBookDetailsHeader.module.scss';
import PropTypes from 'prop-types';
import VisitorAndPhone from '../../../components/VisitorAndPhone';
import InviteToOrtusButton from '../../../components/InviteToOrtusButton/InviteToOrtusButton';
import { fetchVisitorAvatar } from '../../../reducers/visitors';
import { connect } from 'react-redux';

const VisitorHeader = ({
    mainLink,
    visitorId,
    isVisitorSuccess = false,
    fetchVisitorAvatar,
    visitorAvatar,
    visitor = {},
    navbarLinks = []
}) => {
    visitor.id = visitor && visitor.id ? visitor.id : visitorId;
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    useEffect(() => {
        fetchVisitorAvatar(visitor.id);
    }, [visitor.id, fetchVisitorAvatar]);
    return (
        <div className={style.svBookHeaderContainer}>
            <div className={style.svBookHeader}>
                <div className={style.carInfo}>
                    <div className={style.carImgInfo}>
                        <div className={`${style.imgIn} ${visitor.url ? '' : style[`rnd${getRandomInt(0, 12)}`]}`}>
                            { visitorAvatar.thumbnails
                                ? <img src={visitorAvatar.thumbnails.avatar} className={style.mainImg} alt='' />
                                : null }
                        </div>
                        <VisitorAndPhone visitor={visitor} isVisitorSuccess={isVisitorSuccess} />
                    </div>
                </div>
                <InviteToOrtusButton target={visitor} />
            </div>
            <div className={style.routes}>
                {
                    navbarLinks.map((item) => {
                        return (
                            <NavLink key={`${item.linkHeader}LinkHeaderVisitor`} activeClassName={style.active} to={`${mainLink}${visitor.id}${item.linkHeader}`} exact>
                                <div className={style.text}>{ item.titleLink }</div>
                            </NavLink>
                        );
                    })
                }
            </div>
        </div>
    );
};

VisitorHeader.propTypes = {
    visitorId: PropTypes.number,
    mainLink: PropTypes.string,
    isVisitorSuccess: PropTypes.bool,
    visitor: PropTypes.object,
    navbarLinks: PropTypes.array,
    fetchVisitorAvatar: PropTypes.func,
    visitorAvatar: PropTypes.any
};
const mapStateToProps = ({ visitors: { visitorAvatar } }) => {
    return {
        visitorAvatar
    };
};

export default connect(mapStateToProps, { fetchVisitorAvatar })(VisitorHeader);
