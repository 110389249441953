import React from 'react';
import style from './MicroButtons.module.scss';
import PropTypes from 'prop-types';

const MicroButton = ({ title, handleOnClick }) => {
    return (
        <div className={style.microButton} onClick={handleOnClick}>{ title }</div>
    );
};

MicroButton.propTypes = {
    title: PropTypes.string,
    handleOnClick: PropTypes.func
};

const MicroButtons = ({ items, handleOnClick }) => {
    return (
        <div className={style.microButtons}>
            {
                items.map((item, k) => {
                    return (
                        <MicroButton key={'MicroButton_' + item.title + k} title={item.title} handleOnClick={() => handleOnClick(item.data)} />
                    );
                })
            }
        </div>
    );
};

MicroButtons.propTypes = {
    items: PropTypes.array,
    handleOnClick: PropTypes.func
};

export default MicroButtons;
