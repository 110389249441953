import {
    typeSetOilReferences,
    typeUpdateOilReferences,
    typeDeleteOilReferences,
    typeUpdateOilReferencesSuccess,
    typeUpdateOilReferencesError
} from '../actionsTypes';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const SET_OIL_REFERENCES = typeSetOilReferences;
const UPDATE_OIL_REFERENCES = typeUpdateOilReferences;
const DELETE_OIL_REFERENCES = typeDeleteOilReferences;
const UPDATE_OIL_OIL_REFERENCES_SUCCESS = typeUpdateOilReferencesSuccess;
const UPDATE_OIL_OIL_REFERENCES_ERROR = typeUpdateOilReferencesError;
// ------------------------------------
// Actions
// ------------------------------------

export const setOilReferences = (references) => {
    return {
        type: SET_OIL_REFERENCES,
        payload: references
    };
};

export const updateDispatch = (reference) => {
    return {
        type: UPDATE_OIL_REFERENCES,
        payload: reference
    };
};

export const deleteDispatch = (deleteItem) => {
    return {
        type: DELETE_OIL_REFERENCES,
        payload: deleteItem
    };
};

const updateOilReferencesSuccess = (isLoading) => {
    return {
        type: UPDATE_OIL_OIL_REFERENCES_SUCCESS,
        payload: isLoading
    };
};

const setErrorUpdateOilReferences = (isError) => {
    return {
        type: UPDATE_OIL_OIL_REFERENCES_ERROR,
        payload: isError
    };
};

export const fetchOilReferences = () => (dispatch, getState, { apiService }) => {
    dispatch(updateOilReferencesSuccess(true));
    dispatch(setErrorUpdateOilReferences(false));
    return apiService.getOilReferences()
        .then((res) => {
            dispatch(setOilReferences(res));
            dispatch(updateOilReferencesSuccess(false));
            dispatch(setErrorUpdateOilReferences(false));
            return res;
        })
        .catch((err) => {
            dispatch(updateOilReferencesSuccess(false));
            dispatch(setErrorUpdateOilReferences(true));
            return err;
        });
};

export const delOilReferences = (id) => (dispatch, getState, { apiService }) => {
    dispatch(updateOilReferencesSuccess(true));
    dispatch(setErrorUpdateOilReferences(false));
    return id && apiService.deleteOilReferencesField(id)
        .then((res) => {
            if (res) {
                dispatch(deleteDispatch(id));
            }
            dispatch(updateOilReferencesSuccess(false));
            dispatch(setErrorUpdateOilReferences(false));
            return res;
        })
        .catch((err) => {
            dispatch(updateOilReferencesSuccess(false));
            dispatch(setErrorUpdateOilReferences(true));
            return err;
        });
};

export const updateCreateOilReferences = (oilReferenceLabel, data) => (dispatch, getState, { apiService }) => {
    dispatch(updateOilReferencesSuccess(true));
    dispatch(setErrorUpdateOilReferences(false));
    if (data.id) {
        return apiService.updateOilsReferences(data)
            .then((res) => {
                dispatch(updateOilReferencesSuccess(false));
                dispatch(setErrorUpdateOilReferences(false));
                dispatch(updateDispatch(res));
                return res;
            })
            .catch((err) => {
                dispatch(updateOilReferencesSuccess(false));
                dispatch(setErrorUpdateOilReferences(true));
                return err;
            });
    } else {
        data.labelReference = oilReferenceLabel;
        return apiService.storeOilReferencesField(data)
            .then((res) => {
                dispatch(updateDispatch(res));
                dispatch(updateOilReferencesSuccess(false));
                dispatch(setErrorUpdateOilReferences(false));
                return res;
            })
            .catch((err) => {
                dispatch(updateOilReferencesSuccess(false));
                dispatch(setErrorUpdateOilReferences(true));
                return err;
            });
    }
};

export const actions = {
    delOilReferences,
    updateCreateOilReferences,
    fetchOilReferences
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_OIL_REFERENCES]: (state, action) => {
        return ({
            ...state,
            oilReferences: action.payload
        });
    },

    [UPDATE_OIL_REFERENCES]: (state, action) => {
        let newStateRef = _.cloneDeep(state.oilReferences);
        const foundField = newStateRef && newStateRef.find(item => item.id === action.payload?.field_id);

        if (foundField) {
            let reference = foundField.references;
            const idx = reference.findIndex(item => item.id === action.payload.id);
            if (~idx) {
                reference = [
                    ...reference.slice(0, idx),
                    action.payload,
                    ...reference.slice(idx + 1)
                ];
            } else {
                reference = [
                    ...reference,
                    action.payload
                ];
            }
            foundField.references = reference;
            const idxFieldReference = newStateRef.findIndex(field => field.id === action.payload?.field_id);

            newStateRef = [
                ...newStateRef.slice(0, idxFieldReference),
                foundField,
                ...newStateRef.slice(idxFieldReference + 1)
            ];

            return ({
                ...state,
                oilReferences: newStateRef
            });
        } else {
            return ({
                ...state
            });
        }
    },

    [DELETE_OIL_REFERENCES]: (state, action) => {
        const newStateRef = _.cloneDeep(state.oilReferences);

        const newOilReferences = newStateRef.map(item => {
            const references = item.references.filter(ref => ref.id !== action.payload);
            return { ...item, references };
        });

        return ({
            ...state,
            oilReferences: newOilReferences
        });
    },

    [UPDATE_OIL_OIL_REFERENCES_SUCCESS]: (state, action) => {
        return ({
            ...state,
            isOilReferencesLoading: action.payload
        });
    },

    [UPDATE_OIL_OIL_REFERENCES_ERROR]: (state, action) => {
        return ({
            ...state,
            oilReferencesError: action.payload
        });
    }

};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            oilReferences: [],
            oilReferencesError: false,
            isOilReferencesLoading: false
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.settingsOil, action) : state.settingsOil;
};

export default commonReducer;
