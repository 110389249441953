import React from 'react';
import style from './HeaderSticky.module.scss';
import PropTypes from 'prop-types';

const HeaderSticky = ({ header1, header2, classes = [] }) => {
    return (
        <div className={style.headerSticky + classes.map(item => ' ' + style[item]).join('')}>
            {
                header1
            }
            {
                header2
            }
        </div>
    );
};

HeaderSticky.propTypes = {
    header1: PropTypes.node,
    header2: PropTypes.node,
    classes: PropTypes.array
};

export default HeaderSticky;
