import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import style from './ErrorMessageLine.module.scss';

const ErrorMessageLine = ({ refNode, isError, message = 'Данные не сохранены' }) => {
    const [marginMessage, setMarginMessage] = useState({ marginLeft: 0, marginTop: 0 });

    useEffect(() => {
        const self = document.getElementById('error&' + refNode);

        const ref = document.getElementById(refNode);
        ref && setMarginMessage(s => ({ ...s, marginLeft: (ref.offsetWidth - self.offsetWidth), marginTop: 0 }));
    }, [refNode]);

    const errorMessageNode = (
        <div className={style.errorMessageName} id={'error&' + refNode} style={marginMessage}>
            {
                message
            }
        </div>
    );

    return (
        <div>
            {
                isError
                    ? errorMessageNode
                    : ''
            }
        </div>

    );
};

ErrorMessageLine.propTypes = {
    refNode: PropTypes.string,
    isError: PropTypes.bool,
    message: PropTypes.string
};

export default ErrorMessageLine;
