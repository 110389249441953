import React, { useEffect, useRef } from 'react';
import ColumnsMonthProlongation from '../ColumnsMonthProlongation';
import LabelRowProlongationTable from '../LabelRowProlongationTable';
import PropTypes from 'prop-types';
import style from './BlockTypeReportProlongationTable.module.scss';

const arrTitleTable = ['Заказ-наряды', 'Предварительно записаны', 'Предварительно записаны перенесенные с другого месяца', 'Факт. вернулись', 'Факт. вернулись перенесенные с другого месяца', 'Отказы'];

const BlockTypeReportProlongationTable = ({ monthsReport, type, valueTranslateX, handleSetDeltaWidthTable, classes = [] }) => {
    const innerTableElement = useRef(null);
    const wrapTableElement = useRef(null);
    const columns = monthsReport && monthsReport.length > 0
        ? monthsReport.map((item) => {
            return <ColumnsMonthProlongation columnMonth={item} key={`${type}&${item.month}`} />;
        })
        : '';

    useEffect(() => {
        handleSetDeltaWidthTable(innerTableElement.current.offsetWidth - wrapTableElement.current.offsetWidth);
    }, [handleSetDeltaWidthTable, innerTableElement, wrapTableElement]);

    return (
        <div className={style.prolongationTableType + classes.map(item => ' ' + style[item]).join('')} ref={wrapTableElement}>
            <div className={style.yearLine} />
            <div
                className={style.columns}
                style={{ transform: `translateX(${valueTranslateX}px)` }}
                ref={innerTableElement}
            >
                <LabelRowProlongationTable type={type} arrTitleTable={arrTitleTable} />
                {
                    columns
                }
            </div>

        </div>
    );
};

BlockTypeReportProlongationTable.propTypes = {
    monthsReport: PropTypes.array,
    type: PropTypes.string,
    classes: PropTypes.array,
    valueTranslateX: PropTypes.number,
    handleSetDeltaWidthTable: PropTypes.func
};

export default BlockTypeReportProlongationTable;
