import {
    typeOwnersLoaded,
    typeOwnersLoadedError,
    typeFetchOwnersCompanySuccess,
    typeAddOwnerToCompany,
    typeDeleteOwnerFromCompany,
    typeUpdateOwnerCompany,
    typeSetDataForNewOwner,
    typeFetchPartnerGroupRoles,
    typeClearDataSetOwner,
    typeSetOwnerId,
    typeSetUpdateDataOwner,
    typeClearDataUpdateOwner,
    typeGetAllResourceCompany,
    typeErrorFetchAllResourceCompany,
    typeAllResourceCompanySuccess
} from '../actionsTypes';
import { isEmpty, reIdsResourcesFromAngara } from '../utils';
import _ from 'lodash';

// ------------------------------------
// Constants
// ------------------------------------
const SET_OWNERS = typeOwnersLoaded;
const SET_OWNERS_ERROR = typeOwnersLoadedError;
const OWNERS_COMPANY_SUCCESS = typeFetchOwnersCompanySuccess;
const ADD_OWNER_TO_COMPANY = typeAddOwnerToCompany;
const DELETE_OWNER_FROM_COMPANY = typeDeleteOwnerFromCompany;
const UPDATE_OWNER_COMPANY = typeUpdateOwnerCompany;
const SET_NEW_OWNER = typeSetDataForNewOwner;
const GET_PARTNER_GROUP_ROLES = typeFetchPartnerGroupRoles;
const CLEAR_DATA_OWNER = typeClearDataSetOwner;
const SET_OWNER_ID = typeSetOwnerId;
const SET_UPDATE_DATA_OWNER = typeSetUpdateDataOwner;
const CLEAR_DATA_UPDATE_OWNER = typeClearDataUpdateOwner;
const GET_ALL_RESOURCE_COMPANY = typeGetAllResourceCompany;
const ERROR_FETCH_ALL_RESOURCE_COMPANY = typeErrorFetchAllResourceCompany;
const ALL_RESOURCE_COMPANY_SUCCESS = typeAllResourceCompanySuccess;

// ------------------------------------
// Actions
// ------------------------------------

export const setOwners = (owners) => {
    return {
        type: SET_OWNERS,
        payload: owners
    };
};

export const setErrorOwners = (isError) => {
    return {
        type: SET_OWNERS_ERROR,
        payload: isError
    };
};

export const loadingOwnersCompany = (isLoading) => {
    return {
        type: OWNERS_COMPANY_SUCCESS,
        payload: isLoading
    };
};

export const addOwner = (fieldsOwner) => {
    return {
        type: ADD_OWNER_TO_COMPANY,
        payload: fieldsOwner
    };
};

export const delOwner = (ownerId) => {
    return {
        type: DELETE_OWNER_FROM_COMPANY,
        payload: ownerId
    };
};

export const updOwner = (fieldsOwner) => {
    return {
        type: UPDATE_OWNER_COMPANY,
        payload: fieldsOwner
    };
};

export const setNewOwnerData = (newOwner) => {
    return {
        type: SET_NEW_OWNER,
        payload: newOwner
    };
};

export const setUpdateOwnerState = (updateOwner) => {
    return {
        type: SET_UPDATE_DATA_OWNER,
        payload: updateOwner
    };
};

export const setGroupRoles = (roles) => {
    return {
        type: GET_PARTNER_GROUP_ROLES,
        payload: roles
    };
};

export const clearDataOwner = () => {
    return {
        type: CLEAR_DATA_OWNER
    };
};

export const clearDataUpdateOwner = () => {
    return {
        type: CLEAR_DATA_UPDATE_OWNER
    };
};

export const setOwner = (id) => {
    return {
        type: SET_OWNER_ID,
        payload: id
    };
};

export const setAllResourcesCompany = (resources) => {
    return {
        type: GET_ALL_RESOURCE_COMPANY,
        payload: resources
    };
};

export const setOwnerId = (id) => (dispatch) => {
    return dispatch(setOwner(id));
};

export const setErrorFetchResources = (isError) => {
    return {
        type: ERROR_FETCH_ALL_RESOURCE_COMPANY,
        payload: isError
    };
};

export const loadingFetchResources = (isSuccess) => {
    return {
        type: ALL_RESOURCE_COMPANY_SUCCESS,
        payload: isSuccess
    };
};

export const fetchResourcesCompany = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorFetchResources(false));
    dispatch(loadingFetchResources(true));
    return apiService.getAllResourcesCompany()
        .then((res) => {
            dispatch(setAllResourcesCompany(res));
            dispatch(loadingFetchResources(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorFetchResources(true));
            dispatch(loadingFetchResources(false));
            return err;
        });
};

export const fetchOwners = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorOwners(false));
    dispatch(loadingOwnersCompany(true));
    return apiService.fetchOwnersCompany()
        .then((res) => {
            if (getState().settings.resourcesCompany.length === 0) {
                dispatch(fetchResourcesCompany());
            }
            dispatch(setOwners(reIdsResourcesFromAngara(getState().settings.resourcesCompany, res)));
            dispatch(loadingOwnersCompany(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorOwners(true));
            dispatch(loadingOwnersCompany(false));
            return err;
        });
};

export const createOwner = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorOwners(false));
    dispatch(loadingOwnersCompany(true));
    if (getState().settings.newOwnerData.phone &&
        getState().settings.newOwnerData.role.id !== 0 &&
        getState().settings.newOwnerData.name) {
        return apiService.createOwnerCompany(getState().settings.newOwnerData)
            .then((res) => {
                const resNew = reIdsResourcesFromAngara(getState().settings.resourcesCompany, [res])[0];
                dispatch(addOwner(resNew));
                dispatch(clearDataOwner());
                dispatch(loadingOwnersCompany(false));
                return res;
            })
            .catch((err) => {
                dispatch(clearDataOwner());
                dispatch(setErrorOwners(true));
                dispatch(loadingOwnersCompany(false));
                return err;
            });
    } else {
        dispatch(loadingOwnersCompany(false));
        return false;
    }
};

export const deleteOwner = (ownerId) => (dispatch, getState, { apiService }) => {
    dispatch(setErrorOwners(false));
    dispatch(loadingOwnersCompany(true));
    return apiService.deleteOwnerCompany(ownerId)
        .then((res) => {
            dispatch(delOwner(res));
            dispatch(loadingOwnersCompany(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorOwners(true));
            dispatch(loadingOwnersCompany(false));
            return err;
        });
};

export const updateOwner = () => (dispatch, getState, { apiService }) => {
    const data = getState().settings.updateOwnerData;
    if (Object.hasOwnProperty.call(data, 'passwordNew') && data.passwordNew === '') {
        delete data?.passwordNew;
    }

    if (Object.keys(data).length === 1) {
        return;
    }

    dispatch(setErrorOwners(false));
    dispatch(loadingOwnersCompany(true));
    return apiService.updateOwnerCompany(data)
        .then((res) => {
            const resNew = reIdsResourcesFromAngara(getState().settings.resourcesCompany, [res])[0];
            dispatch(updOwner(resNew));
            dispatch(clearDataUpdateOwner());
            dispatch(loadingOwnersCompany(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorOwners(true));
            dispatch(loadingOwnersCompany(false));
            return err;
        });
};

export const createNewOwnerData = (field, value) => (dispatch, getState, { apiService }) => {
    if (field === 'phone') {
        value = value.trim().replace(/([^0-9+]*)/ig, '');
    }

    const data = {
        ...getState().settings.newOwnerData,
        [field]: (field === 'role') ? { id: value } : value
    };
    return dispatch(setNewOwnerData(data));
};

export const setUpdateOwnerData = (field, value) => (dispatch, getState, { apiService }) => {
    if (field === 'phone') {
        value = value.trim().replace(/([^0-9+]*)/ig, '');
    }
    if (field === 'resources') {
        // data value after redo ids ANGARA -> API PHP
        const newValue = (value.length > 0) && typeof value !== 'number' ? value.reduce((accum, resourceOld) => {
            const resourceNew = getState().settings.resourcesCompany.length > 0 && getState().settings.resourcesCompany.find(resource => resource.angara_id === resourceOld.id);
            return [...accum, resourceNew && resourceNew.id];
        }, []) : value;

        // if array don't change, not need send to API new data
        if (JSON.stringify(newValue.sort()) === JSON.stringify(getState().settings.ownerInfo.resources.sort())) {
            return;
        }
    }

    let data = {
        id: getState().settings.ownerId,
        [field]: (field === 'role') ? { id: value } : value
    };

    if (getState().settings.updateOwnerData.id && (getState().settings.updateOwnerData.id === getState().settings.ownerId)) {
        data = {
            ...getState().settings.updateOwnerData,
            id: getState().settings.ownerId,
            [field]: (field === 'role') ? { id: value } : value
        };
    }

    return dispatch(setUpdateOwnerState(data));
};

export const fetchRoles = () => (dispatch, getState, { apiService }) => {
    dispatch(setErrorOwners(false));
    dispatch(loadingOwnersCompany(true));
    return apiService.fetchPartnerGroupRoles()
        .then((res) => {
            dispatch(setGroupRoles(res));
            dispatch(loadingOwnersCompany(false));
            return res;
        })
        .catch((err) => {
            dispatch(setErrorOwners(true));
            return err;
        });
};

export const actions = {
    fetchOwners,
    setErrorOwners,
    loadingOwnersCompany,
    createOwner,
    deleteOwner,
    updateOwner,
    createNewOwnerData,
    fetchRoles,
    setOwnerId,
    setUpdateOwnerData,
    fetchResourcesCompany
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    [SET_OWNERS]: (state, action) => {
        return ({ ...state, ownersCompany: action.payload });
    },
    [SET_OWNERS_ERROR]: (state, action) => {
        return ({ ...state, errorFetchOwnersCompany: action.payload });
    },
    [OWNERS_COMPANY_SUCCESS]: (state, action) => {
        return ({ ...state, fetchOwnersCompanySuccess: action.payload });
    },
    [ADD_OWNER_TO_COMPANY]: (state, action) => {
        const ownersCompanyNew = _.cloneDeep(state.ownersCompany);
        return ({
            ...state,
            ownersCompany: [
                ...ownersCompanyNew,
                action.payload
            ]
        });
    },
    [DELETE_OWNER_FROM_COMPANY]: (state, action) => {
        const ownersCompanyNew = _.cloneDeep(state.ownersCompany);
        const idx = ownersCompanyNew.findIndex((item) => item.id === action.payload.id);

        if (idx === -1) {
            return ({
                ...state
            });
        }

        return ({
            ...state,
            ownersCompany: [
                ...ownersCompanyNew.slice(0, idx),
                ...ownersCompanyNew.slice(idx + 1)
            ]
        });
    },
    [UPDATE_OWNER_COMPANY]: (state, action) => {
        const ownersCompanyNew = _.cloneDeep(state.ownersCompany);
        const idx = ownersCompanyNew.findIndex((item) => item.id === action.payload.id);

        if (idx === -1) {
            return ({
                ...state,
                updateOwnerData: {}
            });
        }

        return ({
            ...state,
            ownersCompany: [
                ...ownersCompanyNew.slice(0, idx),
                action.payload,
                ...ownersCompanyNew.slice(idx + 1)
            ],
            updateOwnerData: {}
        });
    },
    [SET_NEW_OWNER]: (state, action) => {
        return ({
            ...state,
            newOwnerData: action.payload
        });
    },
    [GET_PARTNER_GROUP_ROLES]: (state, action) => {
        const roleWithLabel = action.payload;

        return ({
            ...state,
            roles: [{ id: 0, name: '', label: '' }, ...roleWithLabel]
        });
    },
    [CLEAR_DATA_OWNER]: (state) => {
        return ({
            ...state,
            newOwnerData: {
                phone: '',
                role: {
                    id: 0,
                    label: '',
                    name: ''
                },
                name: '',
                email: '',
                resources: []
            }
        });
    },
    [SET_OWNER_ID]: (state, action) => {
        const owners = _.cloneDeep(state.ownersCompany);
        let ownerInfoForSet = owners.find((item) => item.id === action.payload);

        const roleFind = state.roles.find(role => ownerInfoForSet && (role.name === ownerInfoForSet.role)) === undefined
            ? { id: 0, name: '', label: '', role: '' }
            : state.roles.find(role => role.name === ownerInfoForSet.role);

        ownerInfoForSet = { ...ownerInfoForSet, role: roleFind.id };

        if (isEmpty(ownerInfoForSet)) {
            return ({
                ...state
            });
        }

        return ({
            ...state,
            ownerId: action.payload,
            ownerInfo: ownerInfoForSet
        });
    },
    [SET_UPDATE_DATA_OWNER]: (state, action) => {
        let ownerUpdated = _.cloneDeep(state.ownerInfo);
        const role = _.get(action.payload, 'role.id') ? { role: action.payload.role.id } : ownerUpdated.role;
        ownerUpdated = { ...ownerUpdated, ...action.payload, id: ownerUpdated.id, ...role };

        /**
         * transform resource data owner'a,
         * if they are in the form of an array of objects with ids ANGARA'S
         * to array ids for API PHP
         * to display it correctly in multiSelect
         *
         * */
        const ownerUpdatedWithNewResources = _.get(action.payload, 'resources') && (action.payload.resources.length > 0) && (typeof action.payload.resources[0] !== 'number')
            ? reIdsResourcesFromAngara(state.resourcesCompany, [ownerUpdated])[0]
            : ownerUpdated;

        return ({
            ...state,
            updateOwnerData: action.payload,
            ownerInfo: ownerUpdatedWithNewResources
        });
    },
    [CLEAR_DATA_UPDATE_OWNER]: (state) => {
        return ({
            ...state,
            updateOwnerData: {}
        });
    },
    [GET_ALL_RESOURCE_COMPANY]: (state, action) => {
        return ({
            ...state,
            resourcesCompany: action.payload
        });
    }
};

const commonReducer = (state, action) => {
    if (state === undefined) {
        return {
            ownersCompany: [],
            errorFetchOwnersCompany: false,
            fetchOwnersCompanySuccess: false,
            newOwnerData: {
                phone: '',
                role: {
                    id: 0
                },
                name: '',
                email: ''
            },
            updateOwnerData: {},
            roles: [],
            ownerId: 0,
            ownerInfo: {},
            resourcesCompany: []
        };
    }

    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state.settings, action) : state.settings;
};

export default commonReducer;
