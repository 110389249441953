import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import InputElement from 'react-input-mask';
import { compose, withApiService } from 'Hoc';
import { beforeMaskedPhoneChange } from 'utils';
import { authUser, changeValue, resetError } from 'reducers/auth';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

import classes from 'styles/templates/auth-block.module.scss';

class Login extends Component {
    state = {
        passwordVisible: false
    };

    componentDidMount() {
        this.props.resetError();
    }

    handleChangeValue = (e) => {
        let value = e.target.value;

        if (e.target.name === 'phone') {
            value = value.trim().replace(/([^0-9+]*)/ig, '');
        }
        this.props.changeValue(e.target.name, value);
        // this.props.resetError();
    };

    handleAuth = () => {
        if (!this.props.fetchingAuth && this.props.phone.length === 12 && this.props.password) {
            this.props.authUser({ phone: this.props.phone, password: this.props.password.trim() });
        }
    };

    handleSwitchPasswordVisible = () => {
        this.setState(prevState => ({ passwordVisible: !prevState.passwordVisible }));
    };

    render() {
        const { error, phone, password, fetchingAuth } = this.props;
        const { passwordVisible } = this.state;

        const authReady = phone.length === 12 && password;

        return (
            <div className={classes.authBlock}>
                <div className={classes.title}>
                    Вход в Connect Pro <span>Auto</span>
                </div>
                <div className={classes.inputBlock}>
                    <label htmlFor='phone'>Телефон</label>
                    <InputElement
                        className={(error ? classes.error : '')}
                        type='tel'
                        id='phone'
                        name='phone'
                        placeholder='+7 (ХХХ) ХХХ-ХХ-ХХ'
                        value={phone}
                        onChange={this.handleChangeValue}
                        beforeMaskedValueChange={beforeMaskedPhoneChange}
                        mask='+7 (999) 999-99-99'
                        onKeyPress={e => { if (e.key === 'Enter') this.handleAuth(); }}
                    />
                </div>
                <div className={classes.inputBlock}>
                    <label htmlFor='password'>Пароль</label>
                    <input
                        className={(error ? classes.error : '')}
                        type={passwordVisible ? 'text' : 'password'}
                        id='password'
                        name='password'
                        value={password}
                        onChange={this.handleChangeValue}
                        onKeyPress={e => { if (e.key === 'Enter') this.handleAuth(); }}
                    />
                    <div
                        className={classes.showPassword + (passwordVisible ? ' ' + classes.passwordVisible : '')}
                        onClick={this.handleSwitchPasswordVisible}
                    />
                    <Link to='remind-password' className={classes.dashedText}>восстановить пароль</Link>
                </div>
                <div
                    className={classes.confirmButton + (!authReady || fetchingAuth ? ' ' + classes.inactive : '') + (fetchingAuth ? ' ' + classes.transparentText : '')}
                    onClick={this.handleAuth}
                >
                    Войти
                    <Loader active={fetchingAuth} classes={['small', 'withoutBackground']} />
                </div>
                { error
                    ? <div className={classes.authError}>Неверный телефон или пароль</div>
                    : null }
            </div>
        );
    }
}

Login.propTypes = {
    resetError: PropTypes.func,
    changeValue: PropTypes.func,
    fetchingAuth: PropTypes.bool,
    phone: PropTypes.string,
    password: PropTypes.string,
    authUser: PropTypes.func,
    error: PropTypes.object
};

const mapStateToProps = ({ auth: { phone, password, error, fetchingAuth } }) => {
    return {
        phone,
        password,
        error,
        fetchingAuth
    };
};

const mapDispatchToProps = (dispatch, { apiService }) => {
    return {
        authUser: authUser(apiService, dispatch),
        changeValue: (name, value) => dispatch(changeValue(name, value)),
        resetError: (name, value) => dispatch(resetError(name, value))
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Login);
