import PropTypes from 'prop-types';
import style from './OwnerSettingsBody.module.scss';
import { HeaderSettingsInBody } from '../SettingsHeader';
import NewUserFields from './NewUserFields';
import React from 'react';

export const AddNewOwner = ({ handlerAddOwner, text }) => {
    return (
        <div className={style.rowFieldsClient + ' ' + style.shadowBlock}>
            <HeaderSettingsInBody text={text} />
            <NewUserFields />
            <button className={style.blueBtn} onClick={handlerAddOwner}>Добавить</button>
        </div>
    );
};

AddNewOwner.propTypes = {
    handlerAddOwner: PropTypes.func,
    text: PropTypes.string
};

export default AddNewOwner;
