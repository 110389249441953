import React from 'react';
import CollectionSheetRow from '../CollectionSheetRow';
import style from './CollectionSheetsReportBody.module.scss';
import PropTypes from 'prop-types';
import LoadingIndicatorBlock from '../../../../../../components/LoadingIndicator/LoadingIndicatorBlock';

const CollectionSheetsReportBody = ({ collectionSheetsOwner, loading }) => {
    const sheets = collectionSheetsOwner && collectionSheetsOwner.length > 0 ? collectionSheetsOwner.map((item) => {
        return <CollectionSheetRow key={item.id} collectionSheet={item} withSheetLink />;
    }) : <div className={style.notFound}>Ничего не найдено</div>;

    return (
        <div className={style.sheetsBody}>
            {
                loading
                    ? <LoadingIndicatorBlock reasonLoader={loading} />
                    : ''
            }
            {
                sheets
            }
        </div>
    );
};

CollectionSheetsReportBody.propTypes = {
    collectionSheetsOwner: PropTypes.array,
    loading: PropTypes.bool
};

export default CollectionSheetsReportBody;
