import React from 'react';
import style from '../../SheetCollectBody.module.scss';
import PropTypes from 'prop-types';

const BlocksWrap = ({ forSvBook, children, KeyName, id }) => {
    return (
        <div className={style.collectWrap + (forSvBook ? ' ' + style.forSvBook : '')} key={KeyName} id={id}>
            { children }
        </div>
    );
};

BlocksWrap.propTypes = {
    children: PropTypes.node,
    forSvBook: PropTypes.bool,
    KeyName: PropTypes.string,
    id: PropTypes.string
};

export default BlocksWrap;
