import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import CarAndVisitor from '../CarAndVisitor';

import style from './SvBookDetailsHeader.module.scss';
import { connect } from 'react-redux';

const SvBookDetailsHeader = ({ svHeader, carDetailsInfo, match }) => {
    const mainPhoto = _.find(carDetailsInfo.photo, 'is_main');
    const src = _.get(mainPhoto, 'thumbnails.carGallery', _.get(mainPhoto, 'url'));

    return (
        <div className={style.svBookHeaderContainer}>
            <div className={style.svBookHeader}>
                <div className={style.carInfo}><h1>{ svHeader }</h1></div>
                <div className={style.carInfo}>
                    <div className={style.carImgInfo}>
                        <div className={style.imgIn}>
                            { src
                                ? <img src={src} className={style.mainImg} alt='' />
                                : null }
                        </div>
                        <CarAndVisitor />
                    </div>
                </div>
            </div>
            <div className={style.routes}>
                <NavLink activeClassName={style.active} to={`/svbook-details/${match.params.id}`} replace exact>
                    <div className={style.text}>Характеристика</div>
                </NavLink>
                <NavLink activeClassName={style.active} to={`/svbook-details/${match.params.id}/mileage`} replace>
                    <div className={style.text}>Пробег</div>
                </NavLink>
                <NavLink activeClassName={style.active} to={`/svbook-details/${match.params.id}/collect`} replace>
                    <div className={style.text}>Настройка услуг</div>
                </NavLink>
            </div>
        </div>
    );
};

SvBookDetailsHeader.defaultProps = {
    svHeader: 'Сервисная книжка'
};

SvBookDetailsHeader.propTypes = {
    svHeader: PropTypes.string.isRequired,
    carDetailsInfo: PropTypes.object,
    match: PropTypes.object.isRequired
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo }, templatesOfFrameState: { svHeader } }) => {
    return {
        svHeader,
        carDetailsInfo
    };
};

export default withRouter(connect(mapStateToProps)(SvBookDetailsHeader));
