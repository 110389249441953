import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './AverageDailyMileageCollect.module.scss';
import RadioButtonsHorizontal from '../../../../../../components/Buttons/RadioButtonsHorizontal';
import RadioButtonsForm from '../../../../../../components/RadioButtonsForm/RadioButtonsForm';
import MicroButtons from '../../../../../../components/Buttons/MicroButtons';
import InputWithStyle from '../../../../../../components/Inputs/InputWithStyle';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import CustomInput from '../../../../../../components/DateInput/CustomDateInput';
import { CustomSelect } from '../../../../../../components/Selects';
import { MONTHS_CHANGE_COUNT_OBJ } from '../../../../../../constants/Months';
import { endOfMonth, formatISO, getDaysInYear, startOfMonth } from 'date-fns';

const BUTTONS = [
    { id: 1, title: 'Средний пробег', description: 'Средний пробег' },
    { id: 2, title: 'Количество замен в год', description: 'Количество замен масла в год' },
    { id: 3, title: 'Месяц следующей замены', description: 'Месяц следующей замены масла' }
];

const PathByMileage = ({ forTime, handleSelectForTime, handleUpdateValue, dataInput, handleSelectAverageFromButtons }) => {
    return (
        <>
            <div className={style.radioButtonsAndInput}>
                <RadioButtonsForm
                    items={[{ id: 1, label: 'day', text: 'в день' }, { id: 2, label: 'month', text: 'в месяц' }]}
                    classes={['horizontal']}
                    initialInput={forTime}
                    handleChange={(data) => handleSelectForTime(data.label)}
                    fieldName='label'
                />
                <InputWithStyle
                    setValue={(value) => handleUpdateValue(+value.trim().replace(/([^0-9+]*)/ig, ''))}
                    handlerUpdateValue={() => {}}
                    placeholderText='0'
                    keyId='averageBlock.input'
                    id='averageBlock.input'
                    value={'' + dataInput}
                    span='км'
                    classes={['bigMaxWidth', 'font16', 'maxWidth600', 'minHeight38', 'marginRight8']}
                />
            </div>
            <div className={style.microButtonsArea}>
                <MicroButtons
                    items={[{ title: '10 км', data: 10 }, { title: '20 км', data: 20 }, { title: '50 км', data: 50 }, { title: '100 км', data: 100 }]}
                    handleOnClick={(data) => handleSelectAverageFromButtons(data)}
                />
            </div>
        </>
    );
};

PathByMileage.propTypes = {
    forTime: PropTypes.string,
    dataInput: PropTypes.number,
    handleSelectForTime: PropTypes.func,
    handleUpdateValue: PropTypes.func,
    handleSelectAverageFromButtons: PropTypes.func
};

const AverageDailyMileageCollect = ({ headerText, cycleMileage, handleSetAverageDailyMileage = () => {}, handleSetMonthForChangeOil = () => {} }) => {
    const [averageDailyMileageLocal, setAverageDailyMileageLocal] = useState(0);
    const [dataInput, setDataInput] = useState(0);
    const [dateInput, setDateInput] = useState(null);
    const [dataCountInput, setDataCountInput] = useState(0);
    const [forTime, setForTime] = useState('day');
    const [buttonsChoice, setButtonsChoice] = useState(1);

    useEffect(() => {
        handleSetAverageDailyMileage(averageDailyMileageLocal);
    }, [handleSetAverageDailyMileage, averageDailyMileageLocal]);

    useEffect(() => {
        if (dateInput) {
            const data = {
                scheduledFrom: formatISO(startOfMonth(dateInput)),
                scheduledTo: formatISO(endOfMonth(dateInput))
            };
            handleSetMonthForChangeOil(data);
        } else {
            handleSetMonthForChangeOil(null);
        }
    }, [handleSetMonthForChangeOil, dateInput]);

    useEffect(() => {
        if (dataInput) {
            setAverageDailyMileageLocal(forTime === 'month' ? Math.ceil(dataInput / 30) : dataInput);
        } else {
            setAverageDailyMileageLocal(0);
        }
    }, [dataInput, forTime, handleSetAverageDailyMileage]);

    const handleSelectAverageFromButtons = (data) => {
        setDataInput(data);
    };

    const handleSelectForTime = (data) => {
        setForTime(data);
    };

    const handleSetButtonsChoice = (data) => {
        if (data !== buttonsChoice) {
            setDateInput(null);
            setDataInput(0);
            setDataCountInput(0);
            setAverageDailyMileageLocal(0);
            handleSetAverageDailyMileage(0);
            setButtonsChoice(data);
            setForTime('day');
        }
    };

    const handleUpdateValue = (value) => {
        setDataInput(value);
    };
    const handleSelectItemsLocal = (value) => {
        const dataMiddle = Math.ceil(getDaysInYear(new Date()) / value.value);
        setAverageDailyMileageLocal(Math.ceil(cycleMileage / dataMiddle));
        setDataCountInput(value.value);
    };

    const monthDatePicker = (
        <div className={style.monthPicker}>
            <DatePicker
                selected={dateInput}
                startDate={new Date()}
                onChange={(date) => setDateInput(date)}
                dateFormat='LLLL yyyy'
                minDate={new Date()}
                locale={ru}
                customInput={<CustomInput classes={[]} />}
                showMonthYearPicker
                showFullMonthYearPicker
            />
        </div>
    );

    const numberMonthChange = (
        <div className={style.monthPicker}>
            <CustomSelect
                options={MONTHS_CHANGE_COUNT_OBJ}
                selectedItem={{ value: dataCountInput }}
                handleSelectItems={handleSelectItemsLocal}
                handleDeleteChoosingItem={() => console.log(false)}
                key='numberMonthChange'
                disabledItems={[]}
                disableTitle=''
                id='numberMonthChange'
                fieldMain='value'
                classesInput={['withoutMargin']}
                classesOptions={['width183']}
            />
        </div>
    );

    return (
        <div className={style.dailyMileageContainer}>
            <h3 className={style.dailyMileageHeader}>{ headerText }</h3>
            <RadioButtonsHorizontal
                arrButtons={BUTTONS}
                handleOnClick={handleSetButtonsChoice}
                initial={buttonsChoice}
            />
            <div className={style.averageBlock}>
                <div className={style.descriptionText}>{ BUTTONS?.find(item => item.id === buttonsChoice)?.description }</div>
                {
                    buttonsChoice === BUTTONS[0].id
                        ? (
                            <PathByMileage
                                dataInput={dataInput}
                                forTime={forTime}
                                handleSelectAverageFromButtons={handleSelectAverageFromButtons}
                                handleSelectForTime={handleSelectForTime}
                                handleUpdateValue={handleUpdateValue}
                            />
                        )
                        : (
                            buttonsChoice === BUTTONS[1].id
                                ? numberMonthChange
                                : monthDatePicker
                        )
                }
            </div>
        </div>
    );
};

AverageDailyMileageCollect.propTypes = {
    headerText: PropTypes.string,
    cycleMileage: PropTypes.number,
    handleSetAverageDailyMileage: PropTypes.func,
    handleSetMonthForChangeOil: PropTypes.func
};

export default AverageDailyMileageCollect;
