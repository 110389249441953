import React from 'react';
import CheckBoxCommon from '../CheckBoxes/CheckBoxesCommon';
import PropTypes from 'prop-types';

const FoundedOption = ({ item, handleSelect }) => {
    return (
        <CheckBoxCommon
            key={item.id}
            blockInterestReasonId={`foundClient_${item.id}`}
            classes={['greyWithDiv']}
            handleChecked={() => handleSelect(item.id, item)}
            stateChecked={false}
        >
            <>
                <div>
                    {
                        Object.hasOwnProperty.call(item, 'nick') ? item.nick : ''
                    }
                </div>
                <div>
                    {
                        Object.hasOwnProperty.call(item, 'phone') ? item.phone : ''
                    }
                </div>
            </>
        </CheckBoxCommon>
    );
};

FoundedOption.propTypes = {
    item: PropTypes.object,
    handleSelect: PropTypes.func
};

export default FoundedOption;
