import React from 'react';
import CardReport from '../../../components/Cards/CardReports';
import reportSheets from '../../../components/Cards/assets/reportSheets.svg';
import reportProlongation from '../../../assets/reportProlongation.svg';
import style from './ReportsBody.module.scss';

const ReportsBody = () => {
    return (
        <div className={style.reportsBody}>
            <CardReport text='Отчет по листам сбора информации' link='/reports/collect-report' img={reportSheets} alt='листы сбора инф.' />
            <CardReport text='Отчет по пролонгации' link='/reports/prolongation' img={reportProlongation} alt='пролонгация' />
            <CardReport text='Активность пролонгаций' link='/reports/prolongation-in-action' img={reportProlongation} alt='активность пролонгации' />
        </div>
    );
};

export default ReportsBody;
