import React, { useState, useEffect } from 'react';
import { differenceInCalendarDays, endOfMonth, formatISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import CustomInput from '../DateInput/CustomDateInput';
import PropTypes from 'prop-types';

const PreReserveDatePicker = ({ dateInitialFrom, dateInitialTo, handleChange, type, classes, disabled, handleReload, clear }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [rawData, setRawData] = useState(null);

    useEffect(() => {
        dateInitialFrom && setStartDate(new Date(dateInitialFrom));
        dateInitialTo && setEndDate(new Date(dateInitialTo));
    }, [dateInitialFrom, dateInitialTo]);

    useEffect(() => {
        return () => {
            setStartDate(null);
            setEndDate(null);
            setRawData(null);
        };
    }, [clear]);

    useEffect(() => {
        if (startDate && endDate) {
            const range = {
                scheduledFrom: formatISO(startDate),
                scheduledTo: formatISO(endDate)
            };
            handleChange(range);
        } else if (startDate && !endDate) {
            handleChange({
                scheduledFrom: formatISO(startDate),
                scheduledTo: null
            });
        }
    }, [startDate, endDate, handleChange]);

    const handleOpenModal = (dataRaw) => {
        if (Array.isArray(dataRaw)) {
            setStartDate(dataRaw[0]);
            setEndDate(dataRaw[1]);
        } else {
            if (startDate && endDate) {
                !Array.isArray(dataRaw) && setRawData(dataRaw);
                handleConfirm(dataRaw);
            } else {
                handleConfirm(dataRaw);
            }
        }
    };

    const handleConfirm = (dataRaw = null) => {
        if (type === 'month' || type === 'standard') {
            changeValue(dataRaw || rawData);
        }
    };

    const changeValue = (date) => {
        setStartDate(date);
        if (date) {
            if (type !== 'month' && type !== 'range') {
                const end = new Date(date);
                end.setHours(23, 59, 59);
                setEndDate(end);
            }
            if (type === 'month') {
                if (dateInitialFrom && dateInitialTo && differenceInCalendarDays(new Date(dateInitialTo), new Date(dateInitialFrom)) > 1) {
                    const end = endOfMonth(new Date(date));
                    setEndDate(end);
                } else {
                    const end = new Date(date);
                    end.setHours(23, 59, 59);
                    setEndDate(end);
                }
            }
            if (type === 'standard') {
                const end = new Date(date);
                end.setHours(23, 59, 59);
                setEndDate(end);
            }
        }
    };

    const monthDatePicker = (
        <DatePicker
            selected={startDate}
            startDate={startDate}
            onChange={handleOpenModal}
            dateFormat='LLLL yyyy'
            minDate={new Date()}
            locale={ru}
            customInput={<CustomInput handleReload={handleReload} classes={disabled ? [...classes, 'disabled'] : classes} />}
            readOnly={disabled}
            showMonthYearPicker
            showFullMonthYearPicker
        />
    );

    const rangeDatePicker = (
        <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleOpenModal}
            customInput={<CustomInput handleReload={handleReload} start={startDate} end={endDate} classes={disabled ? [...classes, 'disabled'] : classes} rangeDates />}
            readOnly={disabled}
            locale={ru}
            minDate={new Date()}
            selectsRange
        />
    );

    const standardDatePicker = (
        <DatePicker
            selected={startDate}
            startDate={startDate}
            onChange={handleOpenModal}
            dateFormat='dd/MM/yyyy'
            minDate={new Date()}
            locale={ru}
            readOnly={disabled}
            customInput={<CustomInput handleReload={handleReload} classes={disabled ? [...classes, 'disabled'] : classes} />}
        />
    );

    return (
        <>
            {
                type === 'month'
                    ? (dateInitialFrom && dateInitialTo && differenceInCalendarDays(new Date(dateInitialTo), new Date(dateInitialFrom)) > 1 ? monthDatePicker : standardDatePicker)
                    : (type === 'range'
                        ? rangeDatePicker
                        : standardDatePicker)
            }
        </>

    );
};

PreReserveDatePicker.propTypes = {
    dateInitialFrom: PropTypes.string,
    dateInitialTo: PropTypes.string,
    handleChange: PropTypes.func,
    handleReload: PropTypes.func,
    classes: PropTypes.array,
    disabled: PropTypes.bool,
    clear: PropTypes.bool,
    type: PropTypes.oneOf(['range', 'standard', 'month'])
};

export default PreReserveDatePicker;
