import React, { useState, useMemo, useEffect } from 'react';
import InputWithStyle from '../Inputs/InputWithStyle';
import InputPhoneWithStyle from '../Inputs/InputPhoneWithStyle';
import Columns from '../Columns';
import PropTypes from 'prop-types';
import ModalInputWrap from '../ModalInputWrap';

export function handleClose(handleSearchNewClient, setIsOpenSearchClient, setIsOpenSearchPhone) {
    handleSearchNewClient();
    setIsOpenSearchClient(false);
    setIsOpenSearchPhone(false);
}

const AddSearchClientForm = ({
    id,
    carId,
    handlePushNewClient,
    searchInfo,
    placeholderText = 'Имя нового собственника',
    handleSearchNewClient = () => {},
    classesColumns = [],
    classesInputs = []
}) => {
    const initialClient = useMemo(() => ({ statuses: ['owner'], nick: '', phone: '', car_id: carId }), [carId]);
    const [newClient, setNewClient] = useState(initialClient);
    const [isOpenSearchClient, setIsOpenSearchClient] = useState(false);
    const [isOpenSearchPhone, setIsOpenSearchPhone] = useState(false);

    useEffect(() => {
        !isOpenSearchPhone && setIsOpenSearchClient(searchInfo.search.length > 3);
    }, [isOpenSearchPhone, searchInfo.search]);

    useEffect(() => {
        !isOpenSearchClient && setIsOpenSearchPhone(searchInfo.search.length > 3);
    }, [isOpenSearchClient, searchInfo.search]);

    const handleChangeNewClient = (val) => {
        if (Object.keys(val)[0].length === 4) {
            !isOpenSearchClient && handleSearchNewClient();
            !isOpenSearchClient && setIsOpenSearchClient(true);
            isOpenSearchPhone && setIsOpenSearchPhone(false);
        }
        if (Object.keys(val)[0].length === 5) {
            !isOpenSearchPhone && handleSearchNewClient();
            !isOpenSearchPhone && setIsOpenSearchPhone(true);
            isOpenSearchClient && setIsOpenSearchClient(false);
        }
        setNewClient(s => ({ ...s, ...val }));
        handleSearchNewClient({ search: Object.values(val)[0] });
    };

    return (
        <Columns
            classes={classesColumns}
            first={
                <ModalInputWrap
                    isOpen={isOpenSearchClient && !isOpenSearchPhone}
                    initialClient={initialClient}
                    newClient={newClient}
                    setIsOpenSearchClient={setIsOpenSearchClient}
                    setNewClient={setNewClient}
                    handlePushNewClient={handlePushNewClient}
                    handleClose={() => handleClose(handleSearchNewClient, setIsOpenSearchClient, setIsOpenSearchPhone)}
                >
                    <InputWithStyle
                        setValue={(val) => handleChangeNewClient({ nick: val })}
                        placeholderText={placeholderText}
                        keyId={'clientName' + id}
                        value={newClient.nick}
                        id={'clientName' + id}
                        classes={['bigMaxWidth', ...classesInputs, 'stretch', 'font16']}
                        handlerUpdateValue={() => handlePushNewClient(newClient)}
                        autoFocus
                    />
                </ModalInputWrap>
            }
            second={
                <ModalInputWrap
                    isOpen={isOpenSearchPhone && !isOpenSearchClient}
                    initialClient={initialClient}
                    newClient={newClient}
                    setIsOpenSearchClient={setIsOpenSearchClient}
                    setNewClient={setNewClient}
                    handlePushNewClient={handlePushNewClient}
                    handleClose={() => handleClose(handleSearchNewClient, setIsOpenSearchClient, setIsOpenSearchPhone)}
                >
                    <InputPhoneWithStyle
                        setValue={(val) => handleChangeNewClient({ phone: val.trim().replace(/([^0-9+]*)/ig, '') })}
                        placeholderText='Телефон'
                        keyId={'clientPhone' + id}
                        value={newClient.phone}
                        id={'clientPhone' + id}
                        classes={['bigMaxWidth', ...classesInputs, 'stretch']}
                        handlerUpdateValue={() => handlePushNewClient(newClient)}
                    />
                </ModalInputWrap>
            }
        />
    );
};

AddSearchClientForm.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    carId: PropTypes.number,
    handlePushNewClient: PropTypes.func,
    handleSearchNewClient: PropTypes.func,
    searchInfo: PropTypes.object,
    classesColumns: PropTypes.array,
    placeholderText: PropTypes.string,
    classesInputs: PropTypes.array
};

export default AddSearchClientForm;
