import React from 'react';
import style from './PreReserveCardTableHeader.module.scss';
import PropTypes from 'prop-types';

const fields2 = {
    id: '№',
    createdAt: 'Дата/Время',
    action: 'Действие',
    description: 'Описание',
    user: 'ФИО',
    origin: 'Канал'
};

const PreReserveCardTableHeader = ({ fields = fields2 }) => {
    return (
        <div className={style.prereserve + ' ' + style.prereserveHeader}>
            <div className={style.id}>{ fields.id }</div>
            <div className={style.date}>{ fields.createdAt }</div>
            <div className={style.action}>{ fields.action }</div>
            <div className={style.channel}>{ fields.user }</div>
        </div>
    );
};

PreReserveCardTableHeader.propTypes = {
    fields: PropTypes.object
};

export default PreReserveCardTableHeader;
