import React, { useState } from 'react';
import style from './TableProlongation.module.scss';
import PropTypes from 'prop-types';
import { DATA_LABELS_ARRAY } from '../../../../../../constants/labelsTableProlongation';
import { pickChunksHeadersTable } from '../../../../../../utils/pickChunksHeadersTable';
import { OPTIONS_PACKETS } from '../../../../../../constants/packets';
import { Link } from 'react-router-dom';
import { ANGARA_PROLONGATION_FIELDS } from '../../../../../../constants/angaraProlongationFields';
import { format, isBefore, subDays } from 'date-fns';
import arrow from 'assets/arrow-down-grey.png';

const fathersRows = DATA_LABELS_ARRAY.filter(item => item.father === '').map(item => item.label);

const CellDiv = ({ id, mode, item, index, label, packets, isFather, statisticMode, onClick = () => {} }) => {
    const link = `${window.location.pathname}/pre-reserves?` +
    `activity_metric=${ANGARA_PROLONGATION_FIELDS[id]}&from=${item.dateFrom}` +
    `&to=${isBefore(new Date(item.dateTo), new Date()) ? item.dateTo : format(subDays(new Date(), 1), 'yyyy-MM-dd')}` +
    `&packets=${item.packet || OPTIONS_PACKETS.filter(item => packets.includes(item.id)).map(item => item.label)}` +
    `&mode=${statisticMode}`;

    return (
        <div
            key={id}
            className={style.cellDiv + ' ' + (mode === 'row' ? style.dataRows : style.dataColumn) + ' ' + style[item.chunks] +
            ([0].includes(index) ? ' ' + style.headerUp : '') +
            (fathersRows.includes(id) ? ' ' + style.greyBg : '')}
            onClick={onClick}
        >
            {
                index === 0
                    ? label
                    : ANGARA_PROLONGATION_FIELDS[id] === ANGARA_PROLONGATION_FIELDS.cycleServices
                        ? <div className={style.linkA}>{ item[id] }</div>
                        /* : <Link className={style.linkA} to={link}>{ item[id] }{ item[id + 'Percent'] !== undefined ? ' / ' + item[id + 'Percent'] + '%' : '' }</Link> */
                        : <Link className={style.linkA} to={link}>{ item[id] }</Link>
            }
            {
                isFather && item.chunks === 'total' && <img className={style.arrow} src={arrow} alt='arrow' />
            }
        </div>
    );
};

CellDiv.propTypes = {
    id: PropTypes.string,
    item: PropTypes.object,
    index: PropTypes.number,
    mode: PropTypes.string,
    label: PropTypes.node,
    packets: PropTypes.array,
    onClick: PropTypes.func,
    statisticMode: PropTypes.string,
    isFather: PropTypes.bool
};

const TableProlongation = ({ dataTable, packet, packets, statisticMode, mode = 'row' }) => {
    const [rows, setRows] = useState(DATA_LABELS_ARRAY.filter(item => item.father === ''));
    const { chunksLabels } = pickChunksHeadersTable(dataTable);

    const handleOpenSome = (father) => {
        const isOpen = !!rows.find(item => item.father === father);
        if (isOpen) {
            setRows(s => {
                return [...s.filter(item => item.father !== father)];
            });
        } else {
            setRows(s => {
                const indexFather = s.findIndex(item => item.label === father);
                if (~indexFather) {
                    return [
                        ...s.slice(0, indexFather),
                        s[indexFather],
                        ...DATA_LABELS_ARRAY.filter(item => item.father === father),
                        ...s.slice(indexFather + 1)
                    ];
                } else {
                    return s;
                }
            });
        }
    };

    return (
        <div className={style.tableProlongationInAction}>
            <div className={style.headerDataLabel}>
                <div className={style.tableData}>
                    <div className={style.tableDataLabels}>
                        {
                            [
                                {
                                    label: 'title_' + OPTIONS_PACKETS.find(packetLocal => packetLocal.label === packet)?.name || '',
                                    title: OPTIONS_PACKETS.find(packetLocal => packetLocal.label === packet)?.name || ''
                                },
                                ...rows
                            ].map((item, i) => {
                                return (
                                    <div
                                        key={item.label}
                                        className={style.row + ' ' + (mode === 'row' ? style.headerColumn : style.headerRow) +
                                        ([0].includes(i) ? ' ' + style.headerUp : '') +
                                        (fathersRows.includes(item.label) ? ` ${style.greyBg} ${style.flexStart}` : '')}
                                        onClick={fathersRows.includes(item.label) ? () => handleOpenSome(item.label) : () => {}}
                                    >
                                        <div>
                                            { item.title }
                                        </div>
                                        <div>
                                            {
                                                fathersRows.includes(item.label) && <img className={style.arrow} src={arrow} alt='arrow' />
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    {
                        dataTable.map((item, i) => {
                            return (
                                <div key={i} className={style.data}>
                                    {
                                        [{ label: '' }, ...rows].map((val, index) => {
                                            return (
                                                <CellDiv
                                                    key={`${val.label}_${item[val.label]}_${item.chunks}`}
                                                    id={val.label}
                                                    label={chunksLabels[i]}
                                                    index={index}
                                                    item={item}
                                                    mode={mode}
                                                    onClick={fathersRows.includes(val.label) ? () => handleOpenSome(val.label) : () => {}}
                                                    isFather={fathersRows.includes(val.label)}
                                                    packets={packets}
                                                    statisticMode={statisticMode}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};

TableProlongation.propTypes = {
    dataTable: PropTypes.array,
    mode: PropTypes.string,
    packet: PropTypes.string,
    packets: PropTypes.array,
    statisticMode: PropTypes.string
};

export default TableProlongation;
