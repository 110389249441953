import React from 'react';
import { connect } from 'react-redux';
import { picProlongationFormat, setRangeReportProlongationInAction } from '../../../reducers/reportsProlongationInAction';
import PropTypes from 'prop-types';
import FilterRangesTimes from './FilterRangesTimes';

const FilterRangesTimesWrap = ({ id, objectRange, setRangeReportProlongationInAction, isActive, handleSetActive, classes, picProlongationFormat }) => {
    const handleChange = (data) => {
        setRangeReportProlongationInAction(data);
    };

    return (
        <FilterRangesTimes
            id={id}
            objectRange={objectRange}
            handleChangeRange={handleChange}
            isActive={isActive}
            handleSetActive={handleSetActive}
            classes={classes}
            picProlongationFormat={picProlongationFormat}
        />
    );
};

FilterRangesTimesWrap.propTypes = {
    setRangeReportProlongationInAction: PropTypes.func,
    picProlongationFormat: PropTypes.func,
    handleSetActive: PropTypes.func,
    classes: PropTypes.array,
    objectRange: PropTypes.object,
    isActive: PropTypes.bool,
    id: PropTypes.string
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { setRangeReportProlongationInAction, picProlongationFormat })(FilterRangesTimesWrap);
