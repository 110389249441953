import React from 'react';
import style from '../../../routes/SheetCollect/SheetCollectBody/components/Anchors/OilFields/OilChangeType.module.scss';
import PropTypes from 'prop-types';

const InputWithStyle = ({ keyId, id, value, placeholderText, setValue, handlerUpdateValue, autoFocus, disabled, span, classes = [] }) => {
    return (
        <>
            {
                !span
                    ? (
                        <input
                            className={style.inputGray + ' ' + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}
                            key={keyId}
                            id={id}
                            value={value}
                            placeholder={placeholderText}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={handlerUpdateValue}
                            disabled={disabled}
                            autoFocus={autoFocus}
                        />
                    )
                    : (
                        <div className={style.inputKm}>
                            <input
                                className={style.inputGray + ' ' + (classes ? classes.map(item => ' ' + style[item]).join('') : '')}
                                key={keyId}
                                id={id}
                                value={value}
                                placeholder={placeholderText}
                                onChange={(e) => setValue(e.target.value)}
                                onBlur={handlerUpdateValue}
                                disabled={disabled}
                                autoFocus={autoFocus}
                            />
                            <span>{ span }</span>
                        </div>
                    )
            }
        </>
    );
};

InputWithStyle.propTypes = {
    keyId: PropTypes.string,
    span: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    placeholderText: PropTypes.string,
    setValue: PropTypes.func,
    handlerUpdateValue: PropTypes.func,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    classes: PropTypes.array
};

export default InputWithStyle;
