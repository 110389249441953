import React, { useEffect, useState } from 'react';
import style from './IsReShoe.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPostCarTires } from 'actions';
import ButtonsCommon from '../../../../../../components/Buttons/ButtonsCommon';

const IsDoWheelAlignment = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fetchPostCarTires,
    carTiresUpdateError,
    carIsDoWheelAlignmentSuccess
}) => {
    const fieldName = 'is_do_wheel_alignment';
    const [value, setValue] = useState(carDetailsInfo.is_do_wheel_alignment);

    useEffect(() => {
        setValue(carDetailsInfo.is_do_wheel_alignment);
    }, [carDetailsInfo.is_do_wheel_alignment]);

    const handleUpdate = (value, fieldName) => {
        const post = {
            car_id: id,
            [fieldName]: +value
        };

        if (id && !carIsDoWheelAlignmentSuccess) {
            clientIdCollect && fetchPostCarTires(post, clientIdCollect); // ToDo added clientId
        }
    };

    const handleClick = (itBool) => {
        setValue(itBool);
        handleUpdate(itBool, fieldName);
    };

    return (
        <>
            <label className={style.labelTire}>Развал-схождение</label>
            <ButtonsCommon
                key={`key${fieldName}`}
                btnSelected={!!value}
                countButtons={2}
                dataSuccess={carIsDoWheelAlignmentSuccess}
                handleClick={handleClick}
                classes={['btnTires']}
                isError={carTiresUpdateError}
                buttonsName={['Делает', 'Не делает']}
            />
        </>
    );
};

IsDoWheelAlignment.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fetchPostCarTires: PropTypes.func,
    carTiresUpdateError: PropTypes.bool,
    carIsDoWheelAlignmentSuccess: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect, carDetailsInfo, carTiresUpdateError, carIsDoWheelAlignmentSuccess } }) => {
    return {
        carIdCollect,
        clientIdCollect,
        carDetailsInfo,
        carTiresUpdateError,
        carIsDoWheelAlignmentSuccess
    };
};

export default connect(mapStateToProps, { fetchPostCarTires })(IsDoWheelAlignment);
