import React from 'react';
import VisitorsWrap from './VisitorsHeader/VisitorsWrap';
import VisitorsBody from './VisitorsBody';

const Visitors = () => {
    return (
        <div>
            <VisitorsWrap />
            <VisitorsBody />
        </div>
    );
};

export default Visitors;
