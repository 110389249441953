import MessageForForm from '../../../MessageFoForm';
import { format } from 'date-fns';
import checkGreen from '../../../../assets/checkGreen.svg';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonsAction from '../ButtonsAction';

const PreReserveActions = ({ dateCreatePreReserve, handleOpenModal, handleDelete, handleUpdate, packetId }) => {
    return (
        <>
            <MessageForForm
                key='message_prereserve_button'
                message={`Отправлена ${dateCreatePreReserve ? format(new Date(dateCreatePreReserve), 'dd.MM.yyyy') : ''}`}
                icon={checkGreen}
                alt='отправлено'
                classes={['green']}
            />
            <ButtonsAction handleOpenModal={handleOpenModal} handleDelete={handleDelete} handleUpdate={handleUpdate} packetId={packetId} />
        </>
    );
};

PreReserveActions.propTypes = {
    packetId: PropTypes.number,
    handleOpenModal: PropTypes.func,
    dateCreatePreReserve: PropTypes.string,
    handleDelete: PropTypes.func,
    handleUpdate: PropTypes.func
};

export default PreReserveActions;
