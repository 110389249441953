import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styleList from '../ListInspectCollect/ListInspectCollect.module.scss';
import style from './CardRight.module.scss';
import { formatNumber } from '../../utils';

function getNamePhoneClientCar(carItem) {
    const numberCar = `${carItem.license_plate
        ? carItem.license_plate.split(' ').join('').slice(0, 6) : '____'}/${carItem.region_id ? carItem.region_id.length === 1 ? '0' + carItem.region_id : carItem.region_id
        : '__'}`;

    const name = (carItem.clientVisitor
        ? (carItem.clientVisitor?.nick && carItem.clientVisitor?.nick?.length > 22 ? carItem.clientVisitor?.nick?.slice(0, 22) + '...' : carItem.clientVisitor?.nick)
        : 'Имя не указано');

    const phone = (carItem.clientVisitor
        ? formatNumber(carItem?.clientVisitor?.phone)
        : 'Телефона нет');

    let markModel = carItem.model !== null
        ? (carItem.model?.mark !== null ? carItem.model?.mark?.title + ' ' + carItem.model?.title : 'не указан')
        : 'не указан';

    markModel = markModel.length > 19 ? markModel.slice(0, 19) + '...' : markModel;

    const NameSurname = name ? name.trim().split(/\s* \s*/).map(x => x[0].toUpperCase()).join('').slice(0, 2) : 'AB';

    return {
        name: name,
        phone: phone,
        markModel: markModel,
        numberCar: numberCar,
        NameSurname: NameSurname
    };
}

const CardRight = ({ carItem, carIdCollect }) => {
    const { name, phone, markModel, numberCar, NameSurname } = getNamePhoneClientCar(carItem);

    const classItem = (carIdCollect === carItem.id)
        ? `${styleList.cardItem} ${style.listCollectionHover} ${style.select}`
        : `${styleList.cardItem} ${style.listCollectionHover}`;

    const link = `/collect/${carItem.id}`;

    return (
        <Link to={{
            pathname: link,
            state: { clientVisitor: carItem.clientVisitor, reserveOrOrder: carItem.reserve_or_order }
        }}
        >
            <div className={classItem}>
                <div className={style.arrowTopLeft}> </div>
                <div className={style.cardHeaderRight}>
                    <div className={style.round}>{ NameSurname }</div>
                </div>
                <div className={styleList.cardBody}>
                    <div className={styleList.cardP}>
                        <p className={style.textBl}>{ markModel } ({ numberCar }) </p>
                        <p className={style.textGrow}>{ name }</p>
                        <p className={style.textGrow}>{ phone }</p>
                    </div>
                </div>
            </div>
        </Link>
    );
};

CardRight.propTypes = {
    carItem: PropTypes.object,
    carIdCollect: PropTypes.number
};
const mapStateToProps = ({ carDetailsState: { carIdCollect } }) => {
    return {
        carIdCollect
    };
};

export default connect(mapStateToProps)(CardRight);
