import React, { useEffect } from 'react';
import SheetCollectBody from './SheetCollectBody';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { carDetailsUpload, carIdCollectLoaded, clientIdCollectLoaded, resetCarCollectId } from '../../actions';
import { getPreReserves, saveReserveOrOrder } from '../../reducers/preReserve';
import { getProlongationsFlag, updateProlongationsFlag } from '../../reducers/prolongation';
import SheetCollectHeaderContainer from './SheetCollectHeaderContainer';
import LoadingIndicatorBlock from '../../components/LoadingIndicator/LoadingIndicatorBlock';

const SheetCollectContainer = ({
    carIdCollect,
    clientIdCollect,
    carDetailsUpload,
    resetCarCollectId,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    clientIdRaw,
    reserveOrderRaw,
    carIdRaw,
    carIdCollectLoaded,
    getProlongationsFlag,
    preReservesSuccess,
    fetchCarDetailsSuccess,
    updateProlongationsFlag,
    getPreReserves
}) => {
    useEffect(() => {
        carIdRaw && carIdCollectLoaded(carIdRaw);
    }, [carIdCollectLoaded, carIdRaw]);

    useEffect(() => {
        clientIdRaw && clientIdCollectLoaded(clientIdRaw);
    }, [clientIdCollectLoaded, clientIdRaw]);

    useEffect(() => {
        saveReserveOrOrder(reserveOrderRaw);
    }, [saveReserveOrOrder, reserveOrderRaw, carIdCollect, clientIdCollect]);

    useEffect(() => {
        carIdCollect && carDetailsUpload(carIdCollect, clientIdCollect)
            .then(() => {
                getPreReserves({ carId: carIdCollect, clientId: clientIdCollect });
            });
        getProlongationsFlag(carIdCollect, clientIdCollect);
    }, [carIdCollect, clientIdCollect, carDetailsUpload, getProlongationsFlag, getPreReserves]);

    useEffect(() => {
        return () => resetCarCollectId();
    }, [resetCarCollectId]);

    return (
        <>
            <LoadingIndicatorBlock reasonLoader={preReservesSuccess || fetchCarDetailsSuccess} />
            <SheetCollectHeaderContainer />
            <SheetCollectBody updateProlongationsFlag={updateProlongationsFlag} />
        </>
    );
};

SheetCollectContainer.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsUpload: PropTypes.func,
    resetCarCollectId: PropTypes.func,
    saveReserveOrOrder: PropTypes.func,
    clientIdCollectLoaded: PropTypes.func,
    carIdCollectLoaded: PropTypes.func,
    getProlongationsFlag: PropTypes.func,
    updateProlongationsFlag: PropTypes.func,
    getPreReserves: PropTypes.func,
    carIdRaw: PropTypes.number,
    clientIdRaw: PropTypes.number,
    reserveOrderRaw: PropTypes.object,
    preReservesSuccess: PropTypes.bool,
    fetchCarDetailsSuccess: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, clientIdCollect, fetchCarDetailsSuccess }, preReserve: { preReservesSuccess } }) => {
    return {
        carIdCollect,
        clientIdCollect,
        preReservesSuccess,
        fetchCarDetailsSuccess
    };
};

export default connect(mapStateToProps, {
    getProlongationsFlag,
    carDetailsUpload,
    resetCarCollectId,
    clientIdCollectLoaded,
    saveReserveOrOrder,
    carIdCollectLoaded,
    getPreReserves,
    updateProlongationsFlag
})(SheetCollectContainer);
