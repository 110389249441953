import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose, withApiService } from '../../Hoc';
import FieldLabelFormIsObject from '../FieldLabelFormIsObject';
import PropTypes from 'prop-types';

const SetCarDetailsObjectsFields = ({ carId: id, nameForm, apiService, valueField, propCar }) => {
    const initialCarId = useMemo(() => ({
        id: +id
    }), [id]);
    const initialDataCarField = useMemo(() => ({ id: id }), [id]);

    const [carId, setCarId] = useState(initialCarId);

    const [query, setQuery] = useState(initialDataCarField);
    const [valueForm, setValueForm] = useState(valueField || null);

    const [isLoadingField, setIsLoadingField] = useState(true);
    const [isError, setIsError] = useState(false);
    const [fetch, setFetch] = useState(false);

    useEffect(() => {
        let cancelled = false;
        setCarId(id);
        setValueForm(valueField);

        // ToDo Need refactor
        async function fetchData(apiService, query, carId) {
            const result = {};
            try {
                const result = await apiService.updateSVBook(query, carId);
                result[0].id && !cancelled && setIsLoadingField(false);
            } catch (e) {
                // console.log('error', e);
                !cancelled && setIsError(true);
            }

            return result;
        }
        fetch ? fetchData(apiService, query, id) : setIsLoadingField(false);

        return () => { cancelled = true; };
    }, [carId, query, apiService, id, valueField, fetch]);

    const handleUpdate = (e, valueUp) => {
        const newValue = valueUp !== null ? { id: carId, [propCar]: { id: valueUp } } : { id: carId, [propCar]: null };
        setQuery(newValue);
        setFetch(true);
        e.preventDefault();
    };

    if (isError) {
        return <div>Something is wrong</div>;
    }

    if (isLoadingField) {
        return <div>Loading...</div>;
    }

    return (
        <FieldLabelFormIsObject nameForm={nameForm} valueField={valueForm ? valueForm.id : null} key={propCar} propCar={propCar} forUpdateField={handleUpdate} />
    );
};

SetCarDetailsObjectsFields.propTypes = {
    carId: PropTypes.number,
    nameForm: PropTypes.string,
    apiService: PropTypes.object,
    valueField: PropTypes.any,
    propCar: PropTypes.string
};

const mapStateToProps = ({ carDetailsState: { carId, carDetailsInfo } }) => {
    return {
        carId,
        carDetailsInfo
    };
};

export default compose(
    withApiService(),
    connect(mapStateToProps)
)(SetCarDetailsObjectsFields);
