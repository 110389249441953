import DateInputRange from '../../../../components/DateInputRange';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    setRangeReportProlongationInAction
} from '../../../../reducers/reportsProlongationInAction';
import { subDays } from 'date-fns';
import { endOfDateIso } from '../../../../utils';

const DateRangeProlongationReports = ({ prolongationRange, setRangeReportProlongationInAction, handlePickData, classes }) => {
    return (
        <DateInputRange
            timeFrom={prolongationRange.timeFrom}
            timeTo={prolongationRange.timeTo}
            maxDate={endOfDateIso(new Date(subDays(new Date(), 1)))}
            setNewRanges={setRangeReportProlongationInAction}
            handlePickData={handlePickData}
            classes={classes}
        />
    );
};

DateRangeProlongationReports.propTypes = {
    setRangeReportProlongationInAction: PropTypes.func,
    handlePickData: PropTypes.func,
    prolongationRange: PropTypes.object,
    classes: PropTypes.array
};

const mapStateToProps = ({ reportsProlongationInAction: { collectionSheets, prolongationRange } }) => {
    return {
        collectionSheets,
        prolongationRange
    };
};
export default connect(mapStateToProps, { setRangeReportProlongationInAction })(DateRangeProlongationReports);
