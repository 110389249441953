import React from 'react';
import PropTypes from 'prop-types';
import style from './TextAreaForm.module.scss';

const TextAreaForm = ({ handleUpdate, handleOnChange, text, placeholder = 'Другие причины отказа...', classes = [] }) => {
    return (
        <form onSubmit={handleUpdate}>
            <textarea className={style.text + classes.map(item => ' ' + style[item]).join('')} placeholder={placeholder} value={text} onChange={handleOnChange} />
        </form>
    );
};

TextAreaForm.propTypes = {
    handleUpdate: PropTypes.func,
    handleOnChange: PropTypes.func,
    text: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.array
};

export default TextAreaForm;
