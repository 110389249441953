import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CustomSelect } from '../../../../../../../../components/Selects';
import { WEEKDAYS } from '../../../../../../../../constants/WeekDays';
import style from './WeekDayTimePicker.module.scss';
import PureTimeInput from '../../../../../../../../components/TimeInput/PureTimeInput';

const WeekDayTimePicker = ({ weekDayTimeSettings, handleUpdate, weekDayTimeSettingsSuccess }) => {
    const [selectedData, setSelectedData] = useState({ time: 0, weekday: {} });

    useEffect(() => {
        setSelectedData(() => {
            return {
                time: weekDayTimeSettings?.values?.time_start || weekDayTimeSettings?.default?.time_start || 0,
                weekday: WEEKDAYS.find(item => item.value === weekDayTimeSettings?.values?.weekday) || WEEKDAYS.find(item => item.value === weekDayTimeSettings?.default?.weekday) || {}
            };
        });
    }, [weekDayTimeSettings]);

    return (
        <div className={style.weekDayTimePicker}>
            <CustomSelect
                options={WEEKDAYS}
                selectedItem={selectedData?.weekday}
                handleSelectItems={(data) => handleUpdate({ id: weekDayTimeSettings.id, values: { ...(weekDayTimeSettings?.values || weekDayTimeSettings?.default), weekday: data?.value } })}
                handleDeleteChoosingItem={() => console.log(false)}
                key='numberWeekDaysChange'
                disabledItems={[]}
                disableTitle=''
                id='numberWeekDaysChange'
                fieldMain='value'
                classesInput={['withoutMargin', 'marginRight12']}
                classesOptions={['minHeight', 'width183', 'noPadding']}
                classesOption={['blueSelect', 'flexStart']}
            />
            <PureTimeInput
                timeIntervals={60}
                value={selectedData?.time}
                placeholderText='00:00'
                disabled={weekDayTimeSettingsSuccess}
                withArrow
                onClick={(res) => {
                    setSelectedData(s => ({ ...s, time: res }));
                    handleUpdate({ id: weekDayTimeSettings.id, values: { ...(weekDayTimeSettings?.values || weekDayTimeSettings?.default), time_start: res } });
                }}
            />
        </div>
    );
};

WeekDayTimePicker.propTypes = {
    weekDayTimeSettings: PropTypes.object,
    weekDayTimeSettingsSuccess: PropTypes.bool,
    handleUpdate: PropTypes.func
};

export default WeekDayTimePicker;
