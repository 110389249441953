import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React from 'react';

export function pickChunksHeadersTable(dataInput, field = 'chunks', dateFromLabel = 'dateFrom', dateToLabel = 'dateTo') {
    const chunksLabels = dataInput.map((dataItem) => {
        if (dataItem[field] === 'day') {
            return <span>{ format(new Date(dataItem[dateFromLabel]), 'EEEEEE', { locale: ru }) }<br />{ format(new Date(dataItem[dateFromLabel]), 'd') }</span>;
        } else if (dataItem[field] === 'week') {
            return <span>{ format(new Date(dataItem[dateFromLabel]), 'dd/MM') } -<br />{ format(new Date(dataItem[dateToLabel]), 'dd/MM') }</span>;
        } else if (dataItem[field] === 'month') {
            return format(new Date(dataItem[dateFromLabel]), 'MMM', { locale: ru });
        } else if (dataItem[field] === 'year') {
            return format(new Date(dataItem[dateFromLabel]), 'yyyy');
        } else if (dataItem[field] === 'total') {
            // return <span>{ format(new Date(dataItem[dateFromLabel]), 'dd/MM') } -<br />{ format(new Date(dataItem[dateToLabel]), 'dd/MM') }</span>;
            return <span style={{ fontWeight: 600 }}>ИТОГО</span>;
        } else {
            return dataItem;
        }
    });
    return {
        chunksLabels
    };
}
