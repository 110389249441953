import { isEmpty } from './index';

export default function checkChangeSecretaries(dataNew, dataOld) {
    if (dataNew.id && !dataNew.days && dataNew.timeRange) {
        const isChange = dataOld.find(item => (item.id === dataNew.id) && (item.startTime === dataNew.timeRange.startTime) && (item.endTime === dataNew.timeRange.endTime));
        return !!isChange;
    }
    if ((!dataNew.id || typeof dataNew.id === 'string') && !dataNew.days && !dataNew.connections) {
        return true;
    }
    if (dataNew.id && dataNew.days) {
        const oldDays = dataOld.find(item => item.id === dataNew.id);

        for (const key in dataNew.days) {
            (oldDays[key] === !!dataNew.days[key] || oldDays[key] === +dataNew.days[key]) && (delete dataNew.days[key]);
        }
        if (isEmpty(dataNew.days)) {
            return true;
        }
    }
    return false;
}
