import React from 'react';
import style from '../../../SheetCollectBody.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from 'components/ErrorBoundary';
import SetCarClientTiresPreference from 'components/Requests/SetCarClientTiresPreference';
import { SEASON_ID_AUTUMN, SEASON_ID_SPRING, SEASONS } from '../../../../../../constants/Seasons';

const SeasonalPreferences = ({ seasonsAndPeriodsError, seasonsAndPeriods, messageNotice }) => {
    if (seasonsAndPeriodsError) {
        return <ErrorBoundary />;
    }

    if (seasonsAndPeriods.periods && (seasonsAndPeriods.periods.length === 0)) {
        return (
            <div className={`${style.tireSection} ${style.tireSeasons}`}>
                <h3 className={style.labelBtnTire}>Намерения гостя произвести шиномонтаж</h3>
                <div>Нет данных по сезону шиномонтажа</div>
            </div>
        );
    }

    const periodsTemplate = ([
        <SetCarClientTiresPreference
            key='tires_SEASON_ID_SPRING'
            seasonId={SEASON_ID_SPRING}
            isValid={!messageNotice}
            title={seasonsAndPeriods && seasonsAndPeriods.seasons ? (seasonsAndPeriods.seasons.find(item => item.id === SEASON_ID_SPRING) ? seasonsAndPeriods.seasons.find(item => item.id === SEASON_ID_SPRING).settings_title : 'Ошибка сезона') : SEASONS.SPRING.title}
        />,
        <SetCarClientTiresPreference
            key='tires_SEASON_ID_AUTUMN'
            seasonId={SEASON_ID_AUTUMN}
            isValid={!messageNotice}
            title={seasonsAndPeriods && seasonsAndPeriods.seasons ? (seasonsAndPeriods.seasons.find(item => item.id === SEASON_ID_AUTUMN) ? seasonsAndPeriods.seasons.find(item => item.id === SEASON_ID_AUTUMN).settings_title : 'Ошибка сезона') : SEASONS.AUTUMN.title}
        />
    ]);

    return (
        <div className={`${style.tireSection} ${style.tireSeasons}`}>
            <h3 className={style.labelBtnTire + (messageNotice ? ' ' + style.paddingMessage : '')}>Намерения произвести шиномонтаж</h3>
            {
                messageNotice
            }
            <div className={style.pairSeason}>
                {
                    periodsTemplate
                }
                {
                    seasonsAndPeriods && seasonsAndPeriods.periods
                        ? ''
                        : <div>Не указаны периоды сезона</div>
                }
            </div>
        </div>
    );
};

SeasonalPreferences.propTypes = {
    seasonsAndPeriods: PropTypes.object,
    seasonsAndPeriodsError: PropTypes.bool,
    messageNotice: PropTypes.node
};

const mapStateToProps = ({ settingsSeasons: { seasonsAndPeriods, seasonsAndPeriodsError } }) => {
    return {
        seasonsAndPeriods,
        seasonsAndPeriodsError
    };
};

export default connect(mapStateToProps)(SeasonalPreferences);
