import {
    typeBlockInterestReasonLoaded,
    typeCarCollectBlocksLoaded,
    typeCollectBlocksSuccess,
    typeCompaniesInsuranceLoaded,
    typeGetFetchCollectBlocksError,
    typeGetSvHeaderLoaded,
    typeSetReasonsToState,
    typeSetErrorReasons,
    typeReasonsSuccess,
    typeDeleteReasonFromBlock,
    typeDeleteErrorReasonFromBlock,
    typeDeleteReasonFromBlockSuccess,
    typeNewInterestReasonSuccess,
    typeSetErrorNewInterestReason,
    typeSetNewInterestReason,
    typeDeleteReason,
    typeDeleteErrorReason,
    typeDeleteReasonSuccess,
    typeSetNewReason,
    typeNewReasonSuccess,
    typeSetErrorNewReason,
    typeSetUpdateReason,
    typeUpdateBlockSuccess,
    typeErrorUpdateBlock,
    typeSetUpdateBlock
} from '../actionsTypes';

import drop from '../routes/SheetInspections/assets/rain-drop.svg';
import sparkPlugWhite from '../routes/SheetInspections/assets/spark_plug_white.svg';
import chassisStateWhite from '../routes/SheetInspections/assets/chassis_state_white.svg';
import tiresWhite from '../routes/SheetInspections/assets/tires_white.svg';
import accumulatorWhite from '../routes/SheetInspections/assets/accumulator_white.svg';
import securityWhite from '../routes/SheetInspections/assets/security_white.svg';
import bodyWhite from '../routes/SheetInspections/assets/body_white.svg';
import cabinWhite from '../routes/SheetInspections/assets/cabin_white.svg';
import engineWhite from '../routes/SheetInspections/assets/engine_white.svg';
import oilDvs from '../routes/SheetInspections/assets/oil_dvs.svg';
import oilAutomatic from '../routes/SheetInspections/assets/oil_automatic.svg';
import antifreeze from '../routes/SheetInspections/assets/antifreeze.svg';
import oilStop from '../routes/SheetInspections/assets/oil_stop.svg';
import powerSteeringFluid from '../routes/SheetInspections/assets/power_steering_fluid.svg';
import sparkPlug from '../routes/SheetInspections/assets/spark_plug.svg';
import airFilter from '../routes/SheetInspections/assets/air_filter.svg';
import cabinFilter from '../routes/SheetInspections/assets/cabin_filter.svg';
import brakePads from '../routes/SheetInspections/assets/brake_pads.svg';
import chassisState from '../routes/SheetInspections/assets/chassis_state.svg';
import summerTires from '../routes/SheetInspections/assets/summer_tires.svg';
import winterTires from '../routes/SheetInspections/assets/winter_tires.svg';
import accumulatorState from '../routes/SheetInspections/assets/accumulator_state.svg';
import securityState from '../routes/SheetInspections/assets/security_state.svg';
import bodyState from '../routes/SheetInspections/assets/body_state.svg';
import cabinState from '../routes/SheetInspections/assets/cabin_state.svg';
import engineState from '../routes/SheetInspections/assets/engine_state.svg';
import _ from 'lodash';

const templatesOfFrame = (state, action) => {
    let newBlockInterestReason,
        idx,
        blocksNew,
        newCarCollectBlocks;

    if (state === undefined) {
        return {
            menuItemsHeader: [
                { id: 1, title: 'АВТО', url: '/svbooks', isDisabled: false },
                { id: 2, title: 'ОТЧЕТЫ', url: '/reports', isDisabled: false },
                { id: 3, title: 'ГОСТИ', url: '/visitors', isDisabled: false },
                { id: 4, title: 'НАСТРОЙКИ', url: '/settings/users-owners', isDisabled: false }
            ],
            carCollectBlocks: {},
            collectBlocksError: false,
            collectBlocksSuccess: false,
            blockInterestReason: [],
            siHeader: [
                'Жидкости',
                'Расходники',
                'Ходовая часть',
                'Шины и диски',
                'Аккумулятор',
                'Охрана',
                'Кузов',
                'Салон',
                'Двигатель'
            ], // Template
            sheetInspectionsImagesHeader: [drop, sparkPlugWhite, chassisStateWhite, tiresWhite, accumulatorWhite, securityWhite, bodyWhite, cabinWhite, engineWhite], // Template
            nodeFlueArr: [
                [
                    { name: 'oil_dvs', value: 'Масло ДВС', img: oilDvs },
                    { name: 'oil_automatic', value: 'Масло Автомат', img: oilAutomatic },
                    { name: 'antifreeze', value: 'Жидкость охлаждения', img: antifreeze },
                    { name: 'brake_fluid', value: 'Тормозная жидкость', img: oilStop },
                    { name: 'power_steering_fluid', value: 'Жидкость в гидроусилителе', img: powerSteeringFluid }],
                [
                    { name: 'spark_plug', value: 'Свечи', img: sparkPlug },
                    { name: 'air_filter', value: 'Воздушный фильтр', img: airFilter },
                    { name: 'cabin_filter', value: 'Салонный фильтр', img: cabinFilter },
                    { name: 'brake_pads', value: 'Колодки', img: brakePads }],
                [
                    { name: 'chassis_state', value: 'Шасси', img: chassisState }],
                [
                    { name: 'summer_tires', value: 'Летний комплект', img: summerTires },
                    { name: 'winter_tires', value: 'Зимний комплект ', img: winterTires }],
                [
                    { name: 'accumulator_state', value: 'Аккумулятор', img: accumulatorState }],
                [
                    { name: 'security_state', value: 'Охрана', img: securityState }],
                [
                    { name: 'body_state', value: 'Кузов', img: bodyState }],
                [
                    { name: 'cabin_state', value: 'Салон', img: cabinState }],
                [
                    { name: 'engine_state', value: 'Двигатель', img: engineState }]
            ], // Template
            nameColumnTable: [ // Template
                'Узел',
                'Состояние',
                'Рекомендации'
            ],
            optsState: [ // Template
                { id: 1, name: 'five', value: 'Отличное' },
                { id: 2, name: 'four', value: 'Хорошее' },
                { id: 3, name: 'satisfactory', value: 'Удовлетворительное' },
                { id: 4, name: 'one', value: 'Плохое' }
            ],
            optsRecommend: [ // Template
                { id: 5, name: 'change', value: 'Сменить' },
                { id: 6, name: 'notChange', value: 'Нет' },
                { id: 7, name: 'after', value: 'Сменить через 100км' }
            ],
            svHeader: 'Сервисная книжка', // Template
            sBooksHeader: [ // Template
                { title: 'Авто в работе' },
                { title: 'Авто на завтра' },
                { title: 'Найдено' }
            ],
            statusesUser: [ // Template
                { id: 1, status: 'urUser', title: 'Юр. лицо' },
                { id: 2, status: 'fizUser', title: 'Физ. лицо' },
                { id: 3, status: 'fizContact', title: 'Контактное лицо' },
                { id: 4, status: 'fizLpr', title: 'ЛПР' },
                { id: 5, status: 'fizMainUser', title: 'Осн. пользователь' },
                { id: 6, status: 'urContact', title: 'Контактное лицо' }
            ],
            lowNodes: [ // Template
                { idNode: 'node1', nameNode: 'узел 1', hintNode: 'Что-то написано 1' },
                { idNode: 'node2', nameNode: 'узел 2', hintNode: 'Что-то написано 2' },
                { idNode: 'node3', nameNode: 'узел 3', hintNode: 'Что-то написано 3' },
                { idNode: 'node4', nameNode: 'узел 4', hintNode: 'Что-то написано 4' }
            ],
            bodies: [
                { id: 99, label: 'empty', title: '' },
                { id: 1, label: 'sedan', title: 'Седан' },
                { id: 2, label: 'jeep', title: 'Джип' },
                { id: 3, label: 'hatchback', title: 'Хэтчбек' },
                { id: 4, label: 'station_wagon', title: 'Универсал' },
                { id: 5, label: 'minivan', title: 'Минивэн' },
                { id: 6, label: 'coupe', title: 'Купе' },
                { id: 7, label: 'cabriolet', title: 'Открытый' },
                { id: 8, label: 'pickup', title: 'Пикап' }
            ], // Template
            engines: [
                {
                    id: 99,
                    label: 'empty',
                    title: ''
                },
                {
                    id: 1,
                    label: 'diesel',
                    title: 'Дизельный'
                },
                {
                    id: 2,
                    label: 'petrol',
                    title: 'Бензиновый'
                },
                {
                    id: 3,
                    label: 'gas',
                    title: 'Газовый'
                }
            ], // Template
            transmissions: [
                {
                    id: 1,
                    label: 'automatic',
                    title: 'Автомат'
                },
                {
                    id: 2,
                    label: 'mechanics',
                    title: 'Механика'
                }
            ], // Template
            colors: [
                {
                    id: 1,
                    label: 'empty',
                    title: ''
                },
                {
                    id: 2,
                    label: 'black',
                    title: 'Черный'
                },
                {
                    id: 3,
                    label: 'white',
                    title: 'Белый'
                },
                {
                    id: 4,
                    label: 'grey',
                    title: 'Серый'
                },
                {
                    id: 5,
                    label: 'silver',
                    title: 'Серебристый'
                },
                {
                    id: 6,
                    label: 'red',
                    title: 'Красный'
                },
                {
                    id: 7,
                    label: 'burgundy',
                    title: 'Бордовый'
                },
                {
                    id: 8,
                    label: 'darkBlue',
                    title: 'Синий'
                },
                {
                    id: 9,
                    label: 'blue',
                    title: 'Голубой'
                },
                {
                    id: 10,
                    label: 'green',
                    title: 'Зеленый'
                },
                { id: 11, label: 'gold', title: 'Золотой' },
                { id: 12, label: 'beige', title: 'Бежевый' },
                { id: 13, label: 'yellow', title: 'Желтый' },
                { id: 14, label: 'orange', title: 'Оранжевый' },
                { id: 15, label: 'pink', title: 'Розовый' },
                { id: 16, label: 'brown', title: 'Коричневый' },
                { id: 17, label: 'purple', title: 'Фиолетовый' }
            ],
            initialTemplateBlocks: {
                anchors: { title: 'Якоря', label: 'Anchors', color: 'colorTextRed' },
                secretary: { title: 'Секретарь', label: 'secretary', color: 'colorTextRed' },
                clientService: { title: 'Сервис', label: 'clientService', color: 'colorTextRed' },
                settings: { title: 'Настройки', label: 'settings', color: 'colorTextGrey' },
                mileage: { title: 'Пробег', label: 'mileage', color: 'colorTextRed' },
                statuses: { title: 'Статусы', label: 'statuses', color: 'colorTextGreen' }
            },
            textSubHeaders: ['ОСАГО', 'КАСКО', 'Замена масла ДВС', 'Шиномонтаж', 'Удобное время для звонка'],
            optsForInsuranceCompany: [
                { id: 0, title: '', label: 'empty_insurance_center', description: null }
            ],
            textHeaders: ['Настройка услуг', 'Секретарь', 'Сервис', 'Настройки'],
            whenChangeOil: [
                { value: 'it_depends', label: 'Как придется' },
                { value: 'when_i_remember', label: 'Как вспомню' },
                { value: 'when_will_call', label: 'Как пригласят' }
            ],
            blocks: [
                {
                    id: 1,
                    title: 'Лист сбора информации',
                    label: 'sheetCollect',
                    parent_block_id: 0,
                    packet_id: null,
                    block_interest_reasons: [{
                        car_collect_block_id: 1,
                        client_service_interest: { id: 0, title: '', label: '' },
                        client_service_interest_id: 0,
                        created_at: null,
                        id: 0,
                        reason: null,
                        reason_id: 0,
                        updated_at: null
                    }]
                },
                {
                    id: 2,
                    title: 'Якоря',
                    label: 'Anchors',
                    parent_block_id: 1,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 3,
                    title: 'ОСАГО',
                    label: 'osago',
                    parent_block_id: 2,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 4,
                    title: 'КАСКО',
                    label: 'casco',
                    parent_block_id: 2,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 5,
                    title: 'Замена масла в ДВС',
                    label: 'changingEngineOil',
                    parent_block_id: 2,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 6,
                    title: 'Шиномонтаж',
                    label: 'tireService',
                    parent_block_id: 2,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 7,
                    title: 'Хранение шин',
                    label: 'tireStorage',
                    parent_block_id: 2,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 8,
                    title: 'Секретарь',
                    label: 'secretary',
                    parent_block_id: 1,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 9,
                    title: 'Удобное время для звонка',
                    label: 'timeForCall',
                    parent_block_id: 8,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 10,
                    title: 'Сервис',
                    label: 'clientService',
                    parent_block_id: 1,
                    packet_id: null,
                    block_interest_reasons: []
                },
                {
                    id: 11,
                    title: 'Пробег',
                    label: 'mileage',
                    parent_block_id: 1,
                    packet_id: null,
                    block_interest_reasons: []
                }
            ],
            weekDays: [],
            reasons: [],
            errorFetchReasons: false,
            isReasonsSuccess: false,
            errorDeleteReason: false,
            isReasonDeleteSuccess: false,
            isNewInterestReasonSuccess: false,
            errorNewInterestReason: false,
            isDelReasonSuccess: false,
            isDelReasonError: false,
            isReasonSuccess: false,
            errorFetchReason: false,
            updateBlockSuccess: false,
            errorUpdateBlock: false,
            timeRangeMileageSecretary: { startTime: '10:00:00', endTime: '18:00:00' },
            connections: [
                { priority: 1, channel_connection_id: 1, is_checked: true },
                { priority: 2, channel_connection_id: 2, is_checked: true },
                { priority: 3, channel_connection_id: 3, is_checked: true }
            ],
            days: {
                friday: true,
                monday: true,
                saturday: false,
                sunday: false,
                thursday: true,
                tuesday: true,
                wednesday: true
            }
        };
    }

    const { templatesOfFrameState } = state;

    switch (action.type) {
    case typeGetSvHeaderLoaded:
        return {
            ...templatesOfFrameState,
            svHeader: action.payload
        };

    case typeCollectBlocksSuccess:
        return {
            ...templatesOfFrameState,
            collectBlocksSuccess: action.payload
        };

    case typeCarCollectBlocksLoaded:
        return {
            ...templatesOfFrameState,
            carCollectBlocks: action.payload,
            blocks: action.payload.blocks,
            weekDays: action.payload.weekDays
        };

    case typeGetFetchCollectBlocksError:
        return {
            ...templatesOfFrameState,
            collectBlocksError: action.payload
        };

    case typeCompaniesInsuranceLoaded:
        return {
            ...templatesOfFrameState,
            optsForInsuranceCompany: [{ id: 0, title: '', label: 'empty_insurance_center', description: null }, ...action.payload]
        };

    case typeBlockInterestReasonLoaded:
        return {
            ...templatesOfFrameState,
            blockInterestReason: action.payload
        };

    case typeSetReasonsToState:
        return {
            ...templatesOfFrameState,
            reasons: action.payload
        };

    case typeSetErrorReasons:
        return {
            ...templatesOfFrameState,
            errorFetchReasons: action.payload
        };

    case typeReasonsSuccess:
        return {
            ...templatesOfFrameState,
            isReasonsSuccess: action.payload
        };

    case typeDeleteReasonFromBlock:
        idx = templatesOfFrameState.blockInterestReason.findIndex((item) => item.id === action.payload);
        if (idx === -1) {
            return {
                ...templatesOfFrameState
            };
        }

        return {
            ...templatesOfFrameState,
            blockInterestReason: [
                ...templatesOfFrameState.blockInterestReason.slice(0, idx),
                ...templatesOfFrameState.blockInterestReason.slice(idx + 1)
            ]
        };

    case typeDeleteErrorReasonFromBlock:
        return {
            ...templatesOfFrameState,
            errorDeleteReason: action.payload
        };

    case typeDeleteReasonFromBlockSuccess:
        return {
            ...templatesOfFrameState,
            isReasonDeleteSuccess: action.payload
        };

    case typeNewInterestReasonSuccess:
        return {
            ...templatesOfFrameState,
            isNewInterestReasonSuccess: action.payload
        };

    case typeSetErrorNewInterestReason:
        return {
            ...templatesOfFrameState,
            errorNewInterestReason: action.payload
        };

    case typeSetNewInterestReason:
        newBlockInterestReason = _.cloneDeep(templatesOfFrameState.blockInterestReason);
        idx = templatesOfFrameState.blockInterestReason.findIndex((item) => item.id === action.payload.res.id);

        if (idx === -1) {
            return {
                ...templatesOfFrameState,
                blockInterestReason: [
                    ...newBlockInterestReason,
                    action.payload.res
                ]
            };
        }

        // if exist old Criteria with this new params -> we deleted new Criteria with id and set in result old Criteria
        if (action.payload.idPushItem && (action.payload.idPushItem !== action.payload.res.id)) {
            idx = templatesOfFrameState.blockInterestReason.findIndex((item) => item.id === action.payload.idPushItem);
            return {
                ...templatesOfFrameState,
                blockInterestReason: [
                    ...newBlockInterestReason.slice(0, idx),
                    ...newBlockInterestReason.slice(idx + 1)
                ]
            };
        }

        return {
            ...templatesOfFrameState,
            blockInterestReason: [
                ...newBlockInterestReason.slice(0, idx),
                action.payload.res,
                ...newBlockInterestReason.slice(idx + 1)
            ]
        };

    case typeDeleteErrorReason:
        return {
            ...templatesOfFrameState,
            isDelReasonError: action.payload
        };

    case typeDeleteReasonSuccess:
        return {
            ...templatesOfFrameState,
            isDelReasonSuccess: action.payload
        };

    case typeDeleteReason:
        idx = templatesOfFrameState.reasons.findIndex((item) => item.id === action.payload);
        return {
            ...templatesOfFrameState,
            reasons: [
                ...templatesOfFrameState.reasons.slice(0, idx),
                ...templatesOfFrameState.reasons.slice(idx + 1)
            ]
        };

    case typeSetNewReason:
        return {
            ...templatesOfFrameState,
            reasons: [
                ...templatesOfFrameState.reasons,
                action.payload
            ]
        };

    case typeNewReasonSuccess:
        return {
            ...templatesOfFrameState,
            isReasonSuccess: action.payload
        };

    case typeSetErrorNewReason:
        return {
            ...templatesOfFrameState,
            errorFetchReason: action.payload
        };

    case typeSetUpdateReason:
        idx = templatesOfFrameState.reasons.findIndex((item) => item.id === action.payload.id);
        return {
            ...templatesOfFrameState,
            reasons: [
                ...templatesOfFrameState.reasons.slice(0, idx),
                action.payload,
                ...templatesOfFrameState.reasons.slice(idx + 1)
            ]
        };

    case typeErrorUpdateBlock:
        return {
            ...templatesOfFrameState,
            errorUpdateBlock: action.payload
        };

    case typeUpdateBlockSuccess:
        return {
            ...templatesOfFrameState,
            updateBlockSuccess: action.payload
        };

    case typeSetUpdateBlock:
        blocksNew = _.cloneDeep(templatesOfFrameState.blocks);
        newCarCollectBlocks = _.cloneDeep(templatesOfFrameState.carCollectBlocks);
        idx = blocksNew.findIndex((item) => item.id === action.payload.id);

        return {
            ...templatesOfFrameState,
            blocks: [
                ...blocksNew.slice(0, idx),
                action.payload,
                ...blocksNew.slice(idx + 1)
            ],
            carCollectBlocks: {
                ...newCarCollectBlocks,
                blocks: [
                    ...blocksNew.slice(0, idx),
                    action.payload,
                    ...blocksNew.slice(idx + 1)
                ]

            }
        };

    default:
        return templatesOfFrameState;
    }
};

export default templatesOfFrame;
