import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setStatusesCarClient, searchNewClient, postCreateNewClient } from '../../../../../reducers/statusesClientCar';
import StatusesWithValidate from '../../../../../components/StatusesWithValidate';

const StatusesSheetCollectWrap = ({
    carDetailsInfo,
    clientIdCollect,
    statuses,
    setStatusesCarClient,
    postCreateNewClient,
    foundClients,
    searchInfo,
    isLoadSearchClient,
    searchNewClient,
    isCreateNewClientInCarSuccess,
    changeClientStatusesSuccess
}) => {
    return (
        <>
            <StatusesWithValidate
                clientIdCollect={clientIdCollect}
                carDetailsInfo={carDetailsInfo}
                statusesConfig={statuses}
                handleUpdateStatuses={setStatusesCarClient}
                handleAddClient={postCreateNewClient}
                searchNewClient={searchNewClient}
                isLoadSearchClient={isLoadSearchClient}
                searchInfo={searchInfo}
                foundClients={foundClients}
                isLoading={isCreateNewClientInCarSuccess || changeClientStatusesSuccess}
            />
        </>
    );
};

StatusesSheetCollectWrap.propTypes = {
    carDetailsInfo: PropTypes.object,
    postCreateNewClient: PropTypes.func,
    setStatusesCarClient: PropTypes.func,
    clientIdCollect: PropTypes.number,
    statuses: PropTypes.array,
    foundClients: PropTypes.array,
    searchInfo: PropTypes.object,
    isLoadSearchClient: PropTypes.bool,
    isCreateNewClientInCarSuccess: PropTypes.bool,
    changeClientStatusesSuccess: PropTypes.bool,
    searchNewClient: PropTypes.func
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, clientIdCollect }, statusesClientCar: { statuses, foundClients, searchInfo, isLoadSearchClient, isCreateNewClientInCarSuccess, changeClientStatusesSuccess } }) => {
    return {
        carDetailsInfo,
        clientIdCollect,
        statuses,
        foundClients,
        searchInfo,
        isLoadSearchClient,
        isCreateNewClientInCarSuccess,
        changeClientStatusesSuccess
    };
};

export default connect(mapStateToProps, { setStatusesCarClient, searchNewClient, postCreateNewClient })(StatusesSheetCollectWrap);
