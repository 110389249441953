import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import ru from 'date-fns/locale/ru';
import style from './DateInput.module.scss';
import { MONTHS } from '../../constants/Months';
import CustomInput from './CustomDateInput';

const DateInput = ({ handelChange, valueCar, minDate, maxDate, id, withDelButton, handleDelete, classes = [] }) => {
    const today = new Date();
    const [startDate, setStartDate] = useState(valueCar ? (new Date(valueCar)) : null);

    useEffect(() => {
        setStartDate(valueCar ? (new Date(valueCar)) : null);
    }, [valueCar]);

    const years = [
        ...Array.from(Array(21).keys(), x => x + (today.getFullYear() - 10))
    ];

    function onClickPrevNext(e, fn) {
        e.preventDefault();
        fn();
    }

    return (
        <DatePicker
            dateFormat='dd/MM/yyyy'
            locale={ru}
            popperClassName={style.popperClass}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'stretch'
                    }}
                >
                    <button
                        onClick={(e) => onClickPrevNext(e, decreaseMonth)}
                        disabled={prevMonthButtonDisabled}
                        style={{
                            border: 'none',
                            background: 'transparent'
                        }}
                    >
                        { '<' }
                    </button>
                    <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{
                            padding: 5,
                            display: 'flex',
                            justifyContent: 'stretch',
                            flex: '1 1 20%'
                        }}
                    >
                        { years.map(option => (
                            <option key={option} value={option}>
                                { option }
                            </option>
                        )) }
                    </select>

                    <select
                        value={MONTHS[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                            changeMonth(MONTHS.indexOf(value))}
                        style={{
                            padding: 5,
                            display: 'flex',
                            justifyContent: 'stretch',
                            flex: '1 1 20%'
                        }}
                    >
                        { MONTHS.map(option => (
                            <option key={option} value={option}>
                                { option }
                            </option>
                        )) }
                    </select>

                    <button
                        onClick={(e) => onClickPrevNext(e, increaseMonth)}
                        disabled={nextMonthButtonDisabled}
                        style={{
                            border: 'none',
                            background: 'transparent'
                        }}
                    >
                        { '>' }
                    </button>
                </div>
            )}
            placeholderText='дд/мм/гггг'
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomInput propId={id} classes={classes} handleDelete={handleDelete} withDelButton={withDelButton} />}
            onChange={(e) => {
                setStartDate(e);
                handelChange(e);
            }}
        />
    );
};

DateInput.propTypes = {
    handelChange: PropTypes.func,
    handleDelete: PropTypes.func,
    withDelButton: PropTypes.bool,
    valueCar: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    classes: PropTypes.array,
    id: PropTypes.string
};

export default DateInput;
