import React from 'react';
import { GrayButton } from '../Buttons';
import style from './Forbidden.module.scss';
import PropTypes from 'prop-types';

const ForbiddenHTML = ({ text, textButton, handleClick }) => {
    return (
        <div className={style.forbidden}>
            <div className={style.title}>
                Connect Pro <div className={style.pipe} /><span>Auto</span>
            </div>
            <div className={style.blueArea}>
                <div className={style.wrapWhite}>
                    <div className={style.text}>{ text }</div>
                    <GrayButton text={textButton} handlerClick={handleClick} classes={['blueBg']} />
                </div>
            </div>
        </div>
    );
};

ForbiddenHTML.propTypes = {
    text: PropTypes.string,
    textButton: PropTypes.string,
    handleClick: PropTypes.func
};

export default ForbiddenHTML;
