import React from 'react';
import { ButtonClear } from '../Buttons';
import PropTypes from 'prop-types';

import toggleArrow from '../../assets/gradient-toggle-arrow.svg';
import style from './HeaderFoundToggle.module.scss';

const HeaderFoundToggle = ({ title, countNumber, btn, clearFoundCars, isSearch, isFetching, guests, handleToggleOpen, isOpen }) => {
    return (
        <div className={guests ? style.svHeaderRowGuests : style.svHeaderRow} onClick={guests ? () => {} : handleToggleOpen}>
            <div className={style.fontToggle}>
                <div className={(guests ? ` ${style.guests}` : `${style.cars}`)}>
                    {
                        isSearch && !countNumber && !isFetching && !guests
                            ? 'Ничего не найдено'
                            : `${title} (${countNumber})`
                    }
                </div>
                <div className={style.paddingLeft}>
                    {
                        !guests
                            ? <img src={toggleArrow} alt='' className={isOpen ? '' : style.toggleArrowRotate} />
                            : ''
                    }

                </div>
            </div>
            { btn
                ? isSearch
                    ? <div onClick={() => clearFoundCars()}><ButtonClear classes={guests ? ['forGuests'] : []} textBtn='Сбросить результаты поиска' active /></div>
                    : ''
                : '' }
        </div>
    );
};

HeaderFoundToggle.defaultProps = {
    title: 'Найдено',
    countNumber: 0,
    btn: false,
    cars: []
};

HeaderFoundToggle.propTypes = {
    title: PropTypes.string,
    countNumber: PropTypes.number,
    btn: PropTypes.bool,
    isSearch: PropTypes.bool,
    isFetching: PropTypes.bool,
    clearFoundCars: PropTypes.func,
    handleToggleOpen: PropTypes.func,
    guests: PropTypes.bool,
    isOpen: PropTypes.bool
};

export default HeaderFoundToggle;
