import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import style from './SelectWithLabel.module.scss';

const SelectWithLabel = ({ forUpdateField, idName, valueReal, arrOptions, labelName, error, isDisabled }) => {
    const [valueSelect, setValueSelect] = useState(valueReal || 0);

    useEffect(() => {
        setValueSelect(valueReal || 0);
    }, [valueReal]);

    const setNewValueSelect = (e) => {
        e.preventDefault();
        setValueSelect(e.target.value);
        forUpdateField(e, +e.target.value);
    };

    return (
        <form className={style.insurance}>
            <label htmlFor={idName} className={style.labelChoice}>{ labelName }</label>
            <select id={idName} value={valueSelect} onChange={setNewValueSelect} disabled={isDisabled}>
                {
                    error
                        ? <option key={idName + 'SelectWithLabel_error'} value={0}>Ошибка сохранения...</option>
                        : arrOptions.map((item, i) =>
                            <option key={i} value={item.id}>{ item.title }</option>
                        )

                }
            </select>
        </form>
    );
};

SelectWithLabel.defaultProps = {
    labelName: ''
};

SelectWithLabel.propTypes = {
    forUpdateField: PropTypes.func,
    idName: PropTypes.string,
    valueReal: PropTypes.number,
    arrOptions: PropTypes.array,
    labelName: PropTypes.string,
    error: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default SelectWithLabel;
