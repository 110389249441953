import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './OilsIsExactlyLastTime.module.scss';
import { fetchPostCarOil } from 'actions';
import ButtonsCommon from '../../../../../../components/Buttons/ButtonsCommon';

const OilsIsExactlyLastTime = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fieldNamePiece,
    fetchPostCarOil,
    carOilUpdateError,
    carIsExactlyOilLastTimeSuccess
}) => {
    const fieldName = `is_exactly_${fieldNamePiece}_time`;
    const [value, setValue] = useState(carDetailsInfo[fieldName]);

    useEffect(() => {
        setValue(carDetailsInfo[fieldName]);
    }, [carDetailsInfo, fieldName]);

    const handleUpdate = (value, fieldName) => {
        const post = {
            car_id: id,
            [fieldName]: +value
        };

        id && !carIsExactlyOilLastTimeSuccess && fetchPostCarOil(post, clientIdCollect);
    };

    const handleClick = (itBool) => {
        setValue(itBool);
        handleUpdate(itBool, fieldName);
    };

    return (
        <div className={style.btnOilSmall}>
            <span className={style.textBefore}>Дата</span>
            <ButtonsCommon
                key={`key_is_exactly_${fieldNamePiece}_time`}
                btnSelected={!!value}
                countButtons={2}
                dataSuccess={carIsExactlyOilLastTimeSuccess}
                handleClick={handleClick}
                isError={carOilUpdateError}
            />
        </div>
    );
};

OilsIsExactlyLastTime.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldNamePiece: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    carIsExactlyOilLastTimeSuccess: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilUpdateError, carIsExactlyOilLastTimeSuccess, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        carIsExactlyOilLastTimeSuccess,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilsIsExactlyLastTime);
