import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import style from './OilChangeType.module.scss';
import { fetchPostCarOil } from 'actions';

const OilLastMileage = ({
    carIdCollect: id,
    clientIdCollect,
    carDetailsInfo,
    fieldNamePiece,
    fetchPostCarOil,
    carOilUpdateError,
    isValid
}) => {
    const fieldName = `${fieldNamePiece}_mileage`;
    const [value, setValue] = useState(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');

    useEffect(() => {
        setValue(carDetailsInfo[fieldName] ? carDetailsInfo[fieldName] : '');
    }, [carDetailsInfo, fieldName]);

    const handleOnChange = (newValue) => {
        setValue(newValue.replace(/(\D)/g, ''));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleUpdate();
    };

    const handleUpdate = useCallback(() => {
        if (carDetailsInfo[fieldName] !== +value) {
            const post = {
                car_id: id,
                [fieldName]: +value
            };
            id && (+value || value === '') && fetchPostCarOil(post, clientIdCollect);
        }
    }, [value, carDetailsInfo, fieldName, id, fetchPostCarOil, clientIdCollect]);

    useEffect(() => {
        const timeout = setTimeout(handleUpdate, 2000);
        return () => clearTimeout(timeout);
    }, [handleUpdate]);

    if (carOilUpdateError) {
        return <div>Something is wrong</div>;
    }

    return (
        <form
            onSubmit={handleSubmit}
            onBlur={handleSubmit}
            className={style.oilFormField}
        >
            <label>
                <input
                    type='oilChange'
                    id='oilCycleKm'
                    value={value}
                    onChange={(e) => handleOnChange(e.currentTarget.value)}
                    className={style.oilOldKm + (!isValid && !value ? ' ' + style.redBorder : '')}
                />км
            </label>
        </form>
    );
};

OilLastMileage.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldNamePiece: PropTypes.string,
    fetchPostCarOil: PropTypes.func,
    carOilUpdateError: PropTypes.bool,
    isValid: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carDetailsInfo, carIdCollect, carOilUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarOil })(OilLastMileage);
