import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPreReserve } from '../../reducers/preReserve';
import LoadingIndicatorBlock from '../../components/LoadingIndicator/LoadingIndicatorBlock';
import HeaderSticky from '../../components/Header/HeaderSticky';
import PreReserveHeader from './components/PreReserveHeader';
import PreReserveCards from './components/PreReserveCards';
import { fetchCollectBlocks } from '../../actions';
import PreReserveCardTableHeader from '../Reports/ReportsBody/components/PreReserveCardTableHeader';
import PreReserveCardTableBody from '../Reports/ReportsBody/components/PreReserveCardTableBody';

const PreReserveContainer = ({ id, preReserve, getPreReserve, preReservesSuccess, timezone, blocks, fetchCollectBlocks }) => {
    useEffect(() => {
        id && getPreReserve(id);
    }, [id, getPreReserve]);

    useEffect(() => {
        fetchCollectBlocks();
    }, [fetchCollectBlocks]);

    const header2 = [
        <PreReserveCards key='PreReserveCards_prereserve' preReserve={preReserve} timezone={timezone} />,
        <PreReserveCardTableHeader key='PreReserveReportTableHeader_prereserve' />
    ];

    return (
        <div>
            {
                preReservesSuccess
                    ? <LoadingIndicatorBlock reasonLoader={preReservesSuccess} />
                    : ''
            }
            <HeaderSticky
                header1={
                    <PreReserveHeader preReserve={preReserve} timezone={timezone} blocks={blocks} classes={preReserve?.color ? [preReserve?.color?.toLowerCase()] : []} />
                }
                header2={header2}
                classes={['withShadow']}
            />
            {
                preReserve?.events?.length > 0 && (
                    <PreReserveCardTableBody events={preReserve?.events} timezone={timezone} />
                )
            }
        </div>
    );
};

PreReserveContainer.propTypes = {
    id: PropTypes.number,
    preReserve: PropTypes.object,
    preReservesSuccess: PropTypes.bool,
    getPreReserve: PropTypes.func,
    fetchCollectBlocks: PropTypes.func,
    timezone: PropTypes.string,
    blocks: PropTypes.array
};

const mapStateToProps = ({ preReserve: { preReservesSuccess, preReserve }, common: { timezone }, templatesOfFrameState: { blocks } }) => {
    return {
        preReservesSuccess,
        preReserve,
        timezone,
        blocks
    };
};

export default connect(mapStateToProps, {
    getPreReserve,
    fetchCollectBlocks
})(PreReserveContainer);
