import React, { useEffect } from 'react';
import { isEmpty } from '../../../../../../../../utils';
import Text from '../../../../../../../../components/Text';
import PropTypes from 'prop-types';
import WeekDayTimePicker from '../WeekDayTimePicker';
import style from './NotificationWeekTimeBlock.module.scss';

const NotificationWeekTimeBlock = ({ handleGetSettings, weekDayTimeSettings, handleUpdate, weekDayTimeSettingsSuccess }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            weekDayTimeSettings && isEmpty(weekDayTimeSettings) && handleGetSettings();
        }, 5000);
        return () => clearTimeout(timer);
    }, [weekDayTimeSettings, handleGetSettings]);

    return (
        <div className={style.settingsSecretary}>
            <Text text='Удобное время для сбора пробега' />
            <WeekDayTimePicker weekDayTimeSettings={weekDayTimeSettings} handleUpdate={handleUpdate} weekDayTimeSettingsSuccess={weekDayTimeSettingsSuccess} />
        </div>
    );
};

NotificationWeekTimeBlock.propTypes = {
    handleGetSettings: PropTypes.func,
    handleUpdate: PropTypes.func,
    weekDayTimeSettings: PropTypes.object,
    weekDayTimeSettingsSuccess: PropTypes.bool
};

export default NotificationWeekTimeBlock;
