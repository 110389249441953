export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

export const MONTHS_OBJ = MONTHS.map((item, i) => {
    return {
        title: item,
        value: i + 1
    };
});

export const MONTHS_CHANGE_COUNT_OBJ = [...new Array(12).keys()].map(item => {
    return {
        value: (item + 1),
        title: '' + (item + 1)
    };
});
