import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { RowPair } from '../Row';
import Navbar from '../Navbar';
import imgLogout from './assets/logOut.svg';
import style from './Header.module.scss';
import PropTypes from 'prop-types';
import { fetchLogout } from '../../reducers/auth';
import { compose } from '../../Hoc';
import noName from './assets/no-name.svg';
import { connect } from 'react-redux';
import { NameSurname, formatNumber } from 'utils';
import { fetchCompany } from '../../reducers/common';

const Header = ({ fetchLogout, history, entryPoint, partner, fetchCompany }) => {
    const [exitWindow, setExitWindow] = useState(false);

    useEffect(() => {
        partner?.company_id && fetchCompany(partner.company_id, { expand: 'city' });
    }, [fetchCompany, partner]);

    useEffect(() => {
        entryPoint && history.push(entryPoint);
    }, [history, entryPoint]);

    const handlerLogout = () => {
        fetchLogout();
    };

    const { role, owner } = JSON.parse(localStorage.partner);
    const roles = {
        PARTNER_EXTEND: { name: 'Администратор', color: style.blue },
        PARTNER_SIMPLIFIED: { name: 'Менеджер', color: style.green },
        undefined: { name: role, color: '' }
    };
    const currentRole = roles[roles[role] ? role : undefined];
    const { name, phone } = owner;
    const formattedNumber = formatNumber(phone);
    return (
        <header className={style.appHeader}>
            <RowPair
                left={
                    <>
                        <div
                            onClick={() => setExitWindow(!exitWindow)}
                            className={style.avatarContainer}
                        >
                            <div className={style.avatar}>
                                <div className={style.surnameName}>{ name ? NameSurname(name)
                                    : <img src={noName} alt='img' /> }
                                </div>
                            </div>
                            <div className={`${style.semibold}`}>
                                { name || formattedNumber }
                            </div>
                            <span className={style.exitArrow} />
                        </div>
                    </>
                }
                center={
                    <Navbar />
                }
                right={
                    <div className={style.oneLine} />
                }
            />
            <div
                className={`${style.clickArea} ${exitWindow ? style.active : ''}`}
                onClick={() => setExitWindow(false)}
            >
                <div
                    onClick={(e) => e.stopPropagation()}
                    className={`${style.logoutBtnNew} ${exitWindow ? style.active : ''}`}
                >
                    <div>
                        <div
                            className={style.avatarContainerOpened}
                        >
                            <div className={style.avatarSecond}>
                                <div className={style.surnameName}>{ name ? NameSurname(name) : <img src={noName} alt='img' /> }</div>
                            </div>
                            <div className={style.anotherNameContainer}>
                                <div className={`${style.semibold}`}>
                                    { name ||
                                    <div
                                        className={style.phoneLabel}
                                    >
                                        { formattedNumber }
                                    </div> }
                                </div>
                                <div className={`${style.phoneRole}`}>
                                    { name ? (
                                        <div
                                            className={style.phoneLabel}
                                        >
                                            { formattedNumber }
                                        </div>
                                    ) : null }
                                    <div className={`${style.round} ${currentRole.color} `} />
                                    <div className={`${style.role} ${currentRole.color} `}>
                                        { currentRole.name }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link
                        to='/login' onClick={handlerLogout}
                    >
                        <div className={style.exitButton}>
                            <img
                                className={style.logOutPng} src={imgLogout} alt='Выйти'
                            />
                            <div>
                                Выйти из Connect Pro Auto
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

        </header>
    );
};

Header.propTypes = {
    fetchLogout: PropTypes.func,
    entryPoint: PropTypes.string,
    fetchCompany: PropTypes.func,
    partner: PropTypes.object,
    history: PropTypes.object
};

const mapStateToProps = ({ common: { entryPoint }, auth: { partner } }) => {
    return {
        entryPoint,
        partner
    };
};

export default compose(
    connect(mapStateToProps, { fetchLogout, fetchCompany })
)(withRouter(Header));
