import React, { useState } from 'react';
import HeaderFoundToggle from '../HeaderFoundToggle';
import PropTypes from 'prop-types';
import { GrayButton } from '../Buttons';
import style from '../SearchResultCars/SearchResultCars.module.scss';

const ShowWrapSearch = ({
    showMoreFunc,
    title,
    emptyMessage,
    uniqItems,
    totalFoundedItems,
    clearResultFunc,
    classWrap,
    isSearch,
    isFetching,
    items,
    btn,
    children,
    guests,
    classes = [],
    classesButton = []
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleToggleOpen = () => {
        setIsOpen(s => !s);
    };

    const resultSearch = (
        isSearch && !uniqItems.length && !isFetching
            ? <div className={style.notFound}>{ emptyMessage }</div>
            : <div className={style.svCarsList + classes.map(item => ' ' + style[item]).join('')}>{ children }</div>
    );

    const resultSearchVisitor = (
        <div className={style.svCarsList + classes.map(item => ' ' + style[item]).join('')}>{ children }</div>
    );

    return (
        <div className={classWrap}>
            <div className={style.wrapCarsCategory + ' ' + (isOpen ? '' : style.paddingBottom)}>
                <HeaderFoundToggle
                    title={title}
                    countNumber={uniqItems.length}
                    btn={btn}
                    cars={items}
                    isSearch={isSearch}
                    isFetching={isFetching}
                    clearFoundCars={clearResultFunc}
                    guests={guests}
                    handleToggleOpen={handleToggleOpen}
                    isOpen={isOpen}
                />
                {
                    isOpen
                        ? guests ? resultSearchVisitor : resultSearch
                        : ''
                }
            </div>
            {
                uniqItems.length < totalFoundedItems && isOpen
                    ? <div className={style.withButton}><GrayButton text='Показать ещё' handlerClick={() => showMoreFunc(uniqItems.length)} classes={classesButton} /></div>
                    : ''
            }
        </div>
    );
};

ShowWrapSearch.propTypes = {
    showMoreFunc: PropTypes.func,
    title: PropTypes.string,
    emptyMessage: PropTypes.string,
    uniqItems: PropTypes.array,
    totalFoundedItems: PropTypes.number,
    clearResultFunc: PropTypes.func,
    classWrap: PropTypes.string,
    isSearch: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    btn: PropTypes.bool,
    children: PropTypes.node,
    guests: PropTypes.bool,
    classes: PropTypes.arrayOf(PropTypes.oneOf(['carsListVisitor'])),
    classesButton: PropTypes.array
};

export default ShowWrapSearch;
