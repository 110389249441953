import React, { useState, useEffect } from 'react';
import style from '../../../SheetCollectBody.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPostOilMonthsInfo } from 'actions';

// deprecated, used a new component MonthsSelect

const OilMonth = ({
    valueSelect,
    indexId,
    carIdCollect,
    carDetailsInfo,
    fieldName,
    fetchPostOilMonthsInfo,
    carOilMonthsUpdateError,
    clientIdCollect
}) => {
    const [resultMonth, setResultMonth] = useState(valueSelect || 99);

    useEffect(() => {
        setResultMonth(valueSelect || 99);
    }, [carDetailsInfo, fieldName, valueSelect]);

    const handleUpdate = (e, value, fieldName) => {
        e.preventDefault();

        const post = {
            car_id: carIdCollect,
            id: indexId,
            [fieldName]: +value
        };

        carIdCollect && +value && fetchPostOilMonthsInfo(post, clientIdCollect);
    };

    const handleChange = (e) => {
        setResultMonth(e.target.value);
        handleUpdate(e, e.target.value, fieldName);
    };

    if (carOilMonthsUpdateError) {
        return (
            <form key={fieldName} className={style.months}>
                <select
                    value='Error...'
                    placeholder='Месяц'
                    onChange={(e) => handleChange(e)}
                >
                    <option key={0} value={0}>Error...</option>
                </select>
            </form>
        );
    }

    return (
        <form key={fieldName} className={style.months}>
            <select
                value={resultMonth}
                placeholder='Месяц'
                onChange={(e) => handleChange(e)}
            >
                <option key={99} value={99}> </option>
                <option key={1} value={1}>Январь</option>
                <option key={2} value={2}>Февраль</option>
                <option key={3} value={3}>Март</option>
                <option key={4} value={4}>Апрель</option>
                <option key={5} value={5}>Май</option>
                <option key={6} value={6}>Июнь</option>
                <option key={7} value={7}>Июль</option>
                <option key={8} value={8}>Август</option>
                <option key={9} value={9}>Сентябрь</option>
                <option key={10} value={10}>Октябрь</option>
                <option key={11} value={11}>Ноябрь</option>
                <option key={12} value={12}>Декабрь</option>
            </select>
        </form>
    );
};

OilMonth.propTypes = {
    valueSelect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    indexId: PropTypes.number,
    carIdCollect: PropTypes.number,
    carDetailsInfo: PropTypes.object,
    fieldName: PropTypes.string,
    fetchPostOilMonthsInfo: PropTypes.func,
    carOilMonthsUpdateError: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carDetailsInfo, carOilMonthsUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carDetailsInfo,
        carOilMonthsUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostOilMonthsInfo })(OilMonth);
