import React from 'react';
import DateInputPickYear from '../../../../components/DateInputPickYear';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setYearForReportProlongation } from '../../../../reducers/reportsProlongation';

const DateInputPickYearProlongation = ({ setYearForReportProlongation, yearForReport }) => {
    return (
        <DateInputPickYear initialYear={yearForReport} handleSetNewYear={setYearForReportProlongation} />
    );
};

DateInputPickYearProlongation.propTypes = {
    yearForReport: PropTypes.string,
    setYearForReportProlongation: PropTypes.func
};

const mapStateToProps = ({ reportsProlongation: { yearForReport } }) => {
    return {
        yearForReport
    };
};

export default connect(mapStateToProps, { setYearForReportProlongation })(DateInputPickYearProlongation);
