import style from '../CollectInfoReportBody/CollectInfoReportBody.module.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { STATUSES_PREFER } from '../../../../../../constants/StatusesPrefer';

const CollectInfoReportRow = ({ ownerReport }) => {
    const link = `/reports/collection-sheets-report/${ownerReport.id}`;
    return (
        <div className={style.rowOwner} key={ownerReport.id}>
            <div className={style.name}>{ ownerReport.name }</div>
            <div>
                <Link to={{
                    pathname: `${link}/${STATUSES_PREFER.viewed}`
                }}
                >
                    { ownerReport.viewed }
                </Link>
            </div>
            <div>
                <Link to={{
                    pathname: `${link}/${STATUSES_PREFER.allTouched}`
                }}
                >
                    { ownerReport.allTouched }
                </Link>
            </div>
            <div>
                <Link to={{
                    pathname: `${link}/${STATUSES_PREFER.fully}`
                }}
                >
                    { ownerReport.fully }
                </Link>
            </div>
            <div>
                <Link to={{
                    pathname: `${link}/${STATUSES_PREFER.someCollected}`
                }}
                >
                    { ownerReport.someCollected }
                </Link>
            </div>
            <div />
        </div>
    );
};

CollectInfoReportRow.propTypes = {
    ownerReport: PropTypes.object
};

export default CollectInfoReportRow;
