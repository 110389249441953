import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SetInterests from '../SetInterests';
import { fetchPostCarInterests } from '../../actions';

const SetCarInterest = ({
    carIdCollect,
    clientIdCollect,
    id: blockId,
    interestsReasons,
    nameBlock,
    storageTires,
    anchorsNotCollect,
    fetchPostCarInterests,
    carInterestsUpdateError
}) => {
    if (carInterestsUpdateError) {
        return <div>Something is wrong</div>;
    }

    const handleUpdate = (btnId, e, textUp) => {
        const payload = {
            car_id: carIdCollect,
            car_collect_block_id: blockId,
            client_service_interest_id: +btnId,
            text_reason_interest: textUp || null
        };

        carIdCollect && blockId && btnId && fetchPostCarInterests(payload, blockId, clientIdCollect);
    };

    return (
        <SetInterests key={`SetInterests${blockId}`} nameBlock={nameBlock} interestsReasons={interestsReasons} handleUpdateBtnInterest={handleUpdate} blockId={blockId} storageTires={storageTires} anchorsNotCollect={anchorsNotCollect} />
    );
};

SetCarInterest.propTypes = {
    carIdCollect: PropTypes.number,
    clientIdCollect: PropTypes.number,
    anchorsNotCollect: PropTypes.bool,
    storageTires: PropTypes.bool,
    id: PropTypes.number,
    nameBlock: PropTypes.string,
    interestsReasons: PropTypes.object,
    fetchPostCarInterests: PropTypes.func,
    carInterestsUpdateError: PropTypes.bool
};

const mapStateToProps = ({ carDetailsState: { carIdCollect, carInterestsUpdateError, clientIdCollect } }) => {
    return {
        carIdCollect,
        carInterestsUpdateError,
        clientIdCollect
    };
};

export default connect(mapStateToProps, { fetchPostCarInterests })(SetCarInterest);
